import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = ({ breakpoints, colors }) => ({
  wrapper: {
    minHeight: '300px',
    minWidth: '800px',
    width: '95%',
    display: 'flex',
    flexFlow: 'column wrap',
    [breakpoints.down('md')]: {
      minWidth: '300px',
      width: '75%',
    },
    [breakpoints.up('md')]: {
      width: '60%',
    },
    [breakpoints.up('lg')]: {
      width: '45%',
    },
  },
  whiteBox: {
    minHeight: '220px',
    width: '86%',
    background: `${colors.roseWhite}`,
    alignSelf: 'center',
    justifySelf: 'center',
    boxShadow: `0px 4px 4px ${colors.containerShadow}`,
    borderRadius: '6px',
    zIndex: '20',
    display: 'flex',
    flexFlow: 'column wrap',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    [breakpoints.between('sm', 'md')]: {
      width: '76%',
      padding: '30px',
    },
    [breakpoints.up('lg')]: {
      width: '76%',
      padding: '50px',
    },
  },
  orangeBox: {
    minHeight: '140px',
    width: '100%',
    background: colors.cinnabar,
    alignSelf: 'flex-end',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    zIndex: '10',
    marginTop: '-90px',
  },
})

export default withStyles(styles)(
  ({ classes, children, secondaryLink, whiteBoxStyle, wrapperStyle = {} }) => (
    <div className={classes.wrapper} style={wrapperStyle}>
      <div className={classes.whiteBox} style={{ ...whiteBoxStyle }}>
        {children}
      </div>
      <div className={classes.orangeBox}>{secondaryLink}</div>
    </div>
  ),
)
