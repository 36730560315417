import * as React from 'react'
import { useCroods } from 'croods'
// @ts-ignore
import Select from 'components/form/Select'
import validateUserFields from 'auth/validateUserFields'
import { useMixpanel } from 'mixpanel'
import StudentsShare from 'Educators/ManageSchool/ManageStudents/students-share'
import { FilterContext } from 'Educators/filter-context'
import { Educator } from 'types'
import StudentSkeleton from 'Educators/ManageSchool/student-skeleton'

type Props = {
  classroomId?: number
  schoolId: number
}
const ManageStudents = validateUserFields(({ classroomId, schoolId, ...props }: Props) => {
  const mixpanel = useMixpanel()
  const [educator, setEducator] = React.useState<Educator | null>(null)

  const [filters, setFilters] = React.useState({})

  const croodsConfig = classroomId
    ? {
        name: 'students',
        stateId: `class-${classroomId}`,
        path: `/classrooms/${classroomId}/students`,
      }
    : {
        name: 'students',
        stateId: `school-${schoolId}`,
        path: `/schools/${schoolId}/students`,
      }

  const [{ list: students, fetchingList: fetchingStudents }, { fetch: fetchStudents }] =
    useCroods(croodsConfig)
  const [{ list: educators }] = useCroods({
    name: 'educators',
    stateId: schoolId,
  })

  React.useEffect(() => {
    mixpanel.track('View manage students')
  }, []) // eslint-disable-line

  React.useEffect(() => {
    fetchStudents({})(educator ? { school_educator_id: educator.id } : {})
  }, [schoolId, classroomId, educator?.id]) // eslint-disable-line

  if (fetchingStudents && students?.length === 0) {
    return <StudentSkeleton />
  }

  return (
    <FilterContext.Provider
      value={{
        onReset: () => setEducator(null),
        extraFilters: classroomId ? null : (
          <StaffFilter educator={educator} educators={educators} setEducator={setEducator} />
        ),
      }}
    >
      <StudentsShare list={students} {...props} filters={filters} setFilters={setFilters} />
    </FilterContext.Provider>
  )
})

type FilterProps = {
  educators: Educator[]
  educator: Educator | null
  setEducator: (e: Educator | null) => void
}
function StaffFilter({ educator, educators, setEducator }: FilterProps) {
  return (
    <div className="flex flex-col items-stretch">
      <Select
        className="my-4"
        input={{ name: 'staff-filter' }}
        label="School Staff"
        renderValue={(selected: null | Educator) => {
          return selected?.displayName
        }}
        InputLabelProps={{ shrink: true }}
        value={educator}
        onChange={(ev: React.ChangeEvent<HTMLSelectElement>) => {
          const id = ev.target.value
          const selected = educators.find((e) => e.id === Number(id))
          setEducator(selected ?? null)
        }}
        options={educators
          .filter(({ schoolRole }) => schoolRole !== 'owner')
          .map(({ id, displayName }) => ({
            value: id,
            label: displayName,
          }))}
      />
    </div>
  )
}

export default ManageStudents
