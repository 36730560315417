import React from 'react'
import PanelGroup from 'Educators/students/list/PanelGroup'
import { Grid, withStyles } from '@material-ui/core'
import { EmptyText } from 'components/EmptyText'
import map from 'lodash/map'
import { filterStudents } from 'Educators/filters'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(5),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 5),
    },
  },
})

const NeedsFollowUp = withStyles(styles)(
  ({ classes, currentUser, fetchListStudents, allStillNeedsHelp, filters }) => {
    const groupFiltered = map(allStillNeedsHelp, (arrayStudent) => ({
      ...arrayStudent[0],
      countNeedsHelpByCheckIn: arrayStudent.length,
    }))

    const filteredStudents = filterStudents(groupFiltered, filters)

    return (
      <Grid className={classes.root}>
        {filteredStudents.length > 0 ? (
          <PanelGroup
            group={filteredStudents}
            currentUser={currentUser}
            fetchListStudents={fetchListStudents}
          />
        ) : (
          <EmptyText>Yay! No one needs to be followed up with right now!</EmptyText>
        )}
      </Grid>
    )
  },
)

export default NeedsFollowUp
