import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography, Button } from '@material-ui/core'
import { CheckCircleOutline, ErrorOutline } from '@material-ui/icons'

const Result = ({ invites = [], onClose }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    style={{ paddingLeft: 80, paddingRight: 80 }}
  >
    {invites.map(({ message, email, role, success, id }) => (
      <Grid
        item
        key={id}
        container
        direction="row"
        style={{ textAlign: 'left' }}
      >
        {success ? (
          <Success email={email} role={role} />
        ) : (
          <Failure email={email} role={role} message={message} />
        )}
      </Grid>
    ))}

    <Button onClick={onClose} color="primary" style={{ marginTop: 40 }}>
      DONE
    </Button>
  </Grid>
)

const Success = ({ email }) => (
  <>
    <CheckCircleOutline
      titleAccess="success"
      style={{ color: 'rgba(0, 124, 22, 1)', marginRight: 8 }}
    />

    <Grid item>
      <Typography variant="subtitle2" style={{ fontWeight: 500 }}>
        {email}
      </Typography>
    </Grid>
  </>
)

const Failure = ({ email, message }) => (
  <>
    <ErrorOutline
      titleAccess="failure"
      style={{ color: 'rgba(176, 0, 19, 1)', marginRight: 8 }}
    />

    <Grid item>
      <Typography
        color="textSecondary"
        variant="subtitle2"
        style={{
          fontWeight: 500,
          textDecorationLine: 'line-through',
          textAlign: 'left',
        }}
      >
        {email}
      </Typography>
      <Typography color="error">{message}</Typography>
    </Grid>
  </>
)

Result.propTypes = {
  invites: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      success: PropTypes.bool.isRequired,
    }),
  ),
  onClose: PropTypes.func,
}

export default Result
