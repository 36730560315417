import React from 'react'
import FormSubmitButton from 'components/FormSubmitButton'
import { useFlash } from 'seasoned-flash'
import { reduxForm } from 'redux-form'
import { useCroods } from 'croods'

export const DialogFormName = 'students'

function DialogForm({
  method = 'POST',
  name,
  path,
  buttonText,
  successText,
  onSubmit,
  handleSubmit,
  invalid,
  error,
  disabled,
  onClose,
  children,
  propsFetchStudents,
}) {
  const { success } = useFlash()

  const [, { fetch: fetchStudents }] = useCroods({
    name: 'students',
    ...propsFetchStudents,
  })

  const [{ saving, saveError }, { save }] = useCroods({
    name,
    path,
    afterSuccess: () => {
      fetchStudents()()
      onClose()
      success(successText)
    },
  })

  return (
    <form
      className="w-full"
      onSubmit={handleSubmit(onSubmit(save({ method })))}
    >
      <div className="flex flex-col justify-end">
        {children}
        {error && (
          <span className="block px-4 text-sm text-center text-red-600">
            {error}
          </span>
        )}
        {saveError && (
          <span className="block px-4 text-sm text-center text-red-600">
            {saveError}
          </span>
        )}
        <div className="flex flex-col justify-center">
          <FormSubmitButton
            submitting={saving}
            disabled={invalid || disabled}
            buttonText={buttonText}
          />
        </div>
      </div>
    </form>
  )
}

export default reduxForm({ form: DialogFormName })(DialogForm)
