import AT_A_GLANCE from 'atAGlance'
import type { Student } from 'types'
import { spreadTriageGroup } from 'Educators/triageGroups'

type AtAGlanceKey = keyof typeof AT_A_GLANCE
const filterByAtAGlance = (
  checkedIn: Student[],
  by?: typeof AT_A_GLANCE[AtAGlanceKey]['value'],
) => {
  switch (by) {
    case AT_A_GLANCE.URGENT.value:
      return spreadTriageGroup(checkedIn)?.red ?? []
    case AT_A_GLANCE.COULD_BENEFIT.value:
      return spreadTriageGroup(checkedIn)?.yellow ?? []
    case AT_A_GLANCE.GOOD_TO_GO.value:
      return spreadTriageGroup(checkedIn)?.green ?? []

    default:
      return checkedIn
  }
}

export { filterByAtAGlance }
