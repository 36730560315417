import React, { createContext, useCallback, useContext, useState } from 'react'

export const SchoolsContext = createContext()

export const KEY_SCHOOL_ID = 'selectedSchoolId'

const getSchoolIdOfLocalStorage = () => parseInt(localStorage?.getItem?.(KEY_SCHOOL_ID) ?? 0, 10)

export const SchoolsProvider = ({ children }) => {
  const [schools, setSchools] = useState([])
  const [showAllPremiumSchools, setShowAllPremiumSchools] = useState(false)
  const [schoolData, setSchoolData] = useState({})
  const [schoolId, setSchoolId] = useState(getSchoolIdOfLocalStorage)

  const handleSchool = useCallback((item) => {
    if (item) {
      setSchoolId(item.school.id)
      setSchoolData(item)
      if (item.school.id === 'all') {
        return
      }
      localStorage.setItem(KEY_SCHOOL_ID, item.school.id)
    }
  }, [])

  const clearSchoolId = useCallback(() => {
    setSchoolData({})
    setSchoolId(0)
    localStorage.removeItem(KEY_SCHOOL_ID)
  }, [])

  const isSchoolOwner = useCallback(() => schoolData?.role === 'owner', [schoolData.role])

  return (
    <SchoolsContext.Provider
      value={{
        role: schoolData?.role ?? '',
        school: schoolData?.school ?? {},
        schoolId,
        handleSchool,
        clearSchoolId,
        isSchoolOwner,
        showAllPremiumSchools,
        setShowAllPremiumSchools,
        getSchoolIdOfLocalStorage,
        setSchoolId,
        schools,
        setSchools,
      }}
    >
      {children}
    </SchoolsContext.Provider>
  )
}

export const useSchools = () => useContext(SchoolsContext)
