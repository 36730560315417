import React from 'react'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

export default ({
  input,
  meta,
  type = 'text',
  label,
  helperText,
  ...props
}) => {
  const { touched, error } = meta

  return (
    <FormControl
      error={touched && !!error}
      aria-describedby={`${input.name}-text`}
      fullWidth
    >
      <InputLabel htmlFor={input.name}>{label}</InputLabel>
      <Input {...props} {...input} type={type} id={input.name} />
      <FormHelperText id={`${input.name}-text`}>
        {(touched && error) || helperText}
      </FormHelperText>
    </FormControl>
  )
}
