import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import NonReader from 'checkIn/steps/TalkToAnAdultResponse/NonReader'
import { getQSData } from 'QueryStringController'

const SCREEN_NAME = 'talkToAnAdultResponse'

const TalkToAnAdultResponse: RouteComponent = () => {
  const { student } = useCheckIn()

  const { talkToAnAdult } = getQSData()

  const needTalkToAnAdult = talkToAnAdult === 'true'

  if (student) {
    const { nextFunction } = FLOWS['non-reader'][SCREEN_NAME]

    return <NonReader nextFunction={nextFunction} needTalkToAnAdult={needTalkToAnAdult} />
  }

  return null
}

export default TalkToAnAdultResponse
