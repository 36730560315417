import React from 'react'
import { Skeleton } from '@material-ui/lab'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core'
import isObject from 'lodash/isObject'
import { map } from 'lodash'

const SelectList = ({
  label,
  options,
  value,
  onChange,
  loading,
  propsSelect,
  multiple = false,
  showNoneComponent = false,
  error = false,
}) => {
  if (loading)
    return (
      <div data-testid="skeleton" className="w-full">
        <Skeleton variant="text" height={20} />
        <Skeleton variant="text" height={40} />
      </div>
    )

  return (
    <FormControl fullWidth error={error}>
      <InputLabel>{label}</InputLabel>

      <Select
        multiple={multiple}
        value={value}
        onChange={onChange}
        {...propsSelect}
      >
        {showNoneComponent && (
          <MenuItem data-testid="menu-item-none" value="">
            &nbsp;
          </MenuItem>
        )}
        {map(options, (option) => {
          if (isObject(option))
            return (
              option.value !== '' && (
                <MenuItem key={option.value} value={option.value}>
                  <ListItemText
                    disableTypography
                    primary={option.label}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                </MenuItem>
              )
            )

          return (
            option && (
              <MenuItem key={option} value={option}>
                {multiple && (
                  <Checkbox
                    color="primary"
                    inputProps={{ 'aria-label': `checkbox-${option}` }}
                    checked={value.indexOf(option) > -1}
                  />
                )}
                <ListItemText
                  disableTypography
                  primary={option}
                  style={{ marginTop: 0, marginBottom: 0 }}
                />
              </MenuItem>
            )
          )
        })}
      </Select>
      {error && (
        <FormHelperText
          data-testid={`error-${propsSelect?.inputProps?.name ?? ''}`}
        >
          Required field
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default SelectList
