import React from 'react'
import Screen from '../components/screen'
import { SecondaryButton } from '../components/SecondaryButton'

type Props = {
  nextFunction: (feelingNow: string) => void
}

export default ({ nextFunction }: Props) => {
  return (
    <Screen showSummary={false}>
      <p className="text-2xl sm:text-4xl text-white mb-20 mx-auto text-center">{`How are you feeling now?`}</p>
      <div className="flex flex-col justify-items-center justify-evenly w-full max-w-sm gap-4 md:gap-10 mb-auto sm:mb-0">
        <SecondaryButton handleNextFunction={() => nextFunction('better')}>
          {'better'}
        </SecondaryButton>
        <SecondaryButton handleNextFunction={() => nextFunction('same')}>{'same'}</SecondaryButton>
        <SecondaryButton handleNextFunction={() => nextFunction('worse')}>
          {'worse'}
        </SecondaryButton>
      </div>
    </Screen>
  )
}
