import React, { useState, useEffect, useMemo } from 'react'
import { useCroods } from 'croods'
import { useFlash } from 'seasoned-flash'
import { map, pickBy, keys, parseInt } from 'lodash'
import { Button } from '@material-ui/core'
import { MdNotifications as NotificationsIcon } from 'react-icons/md'
import { Checkbox } from 'components/StudentItem'
import { StudentItem } from './StudentItem'
import { checkboxType, filterCheckboxBy, setAllCheckboxTo } from './helper'
import { ReminderCheckInContainer, CheckBoxWrapper, Wrapper } from './styles'
import { paginate } from 'utils'
import { PaginationBar } from 'components/PaginationBar'

export const ReminderCheckIn = ({
  students,
  initialStateSelectedAll = false,
  initialStateSelected = [],
  fetchListStudents,
}) => {
  const [selectedAll, setSelectedAll] = useState(initialStateSelectedAll)
  const [selected, setSelected] = useState(initialStateSelected)
  const [currentPage, setCurrentPage] = useState(1)

  const { success, error } = useFlash()

  const [{ saving }, { save }] = useCroods({
    name: 'sendReminders',
    customPath: '/check_in_reminders',
    afterSuccess: () => {
      setSelected(setAllCheckboxTo(students, checkboxType.UNCHECKED))
      success('Reminder sent')
      fetchListStudents()
    },
    afterFailure: () => {
      error('Failed to send reminder')
    },
  })

  const selectionExists = !filterCheckboxBy(selected, checkboxType.CHECKED).length > 0

  const handleSelect = (id, type) => setSelected((prevState) => ({ ...prevState, [id]: type }))

  const handleSelectAll = () => {
    setSelectedAll((prevState) => {
      const type = prevState ? checkboxType.UNCHECKED : checkboxType.CHECKED

      setSelected(setAllCheckboxTo(students, type))

      return !prevState
    })
  }

  const handleSubmit = (create) =>
    create({
      studentIds: map(keys(pickBy(selected, (v) => v === 'checked')), parseInt),
    })

  useEffect(() => {
    setSelected(setAllCheckboxTo(students, checkboxType.UNCHECKED))
  }, [students])

  useEffect(() => {
    setSelectedAll(!filterCheckboxBy(selected, checkboxType.UNCHECKED).length > 0)
  }, [selected])

  const didntCheckedIn = useMemo(() => paginate(students, currentPage), [currentPage, students])
  const pages = Math.ceil(students.length / 3)

  const handleButtonText = () => {
    const defaultText = 'SEND REMINDER'
    if (selectedAll) return `${defaultText} TO ALL STUDENTS`
    if (filterCheckboxBy(selected, checkboxType.CHECKED).length)
      return `${defaultText} TO SELECTED STUDENTS`
    return defaultText
  }

  return (
    <ReminderCheckInContainer>
      <Wrapper>
        <CheckBoxWrapper>
          <Checkbox checked={selectedAll} onClick={handleSelectAll} />
        </CheckBoxWrapper>
        <Button
          disabled={selectionExists && !saving}
          variant="outlined"
          color="primary"
          endIcon={<NotificationsIcon color={selectionExists ? 'disabled' : 'primary'} />}
          onClick={() => handleSubmit(save())}
        >
          {handleButtonText()}
        </Button>
        {didntCheckedIn.map((student) => (
          <StudentItem
            key={student.id}
            student={student}
            selected={selected[student.id]}
            onClick={handleSelect}
          />
        ))}
      </Wrapper>
      {pages < 2 ? null : (
        <PaginationBar onButtonsClick={setCurrentPage} currentPage={currentPage} pages={pages} />
      )}
    </ReminderCheckInContainer>
  )
}
