import React from 'react'
import Typography from '@material-ui/core/Typography'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'

import { nameConcatenation } from 'Educators/students/helper'

const StudentSharedUsersInfo = ({
  student: { grade, adminsNames, caregiversNames },
  hideGrade = false,
  emptyViewersLabel = 'No viewers yet',
  viewersListPrefix = 'Viewers',
  adminsListPrefix = 'Admins',
}) => (
  <>
    {!hideGrade && grade && (
      <Typography variant="subtitle2" style={{ fontWeight: 400 }}>{`Grade ${grade}`}</Typography>
    )}
    {!isEmpty(adminsNames) && (
      <Typography variant="subtitle1">
        {adminsListPrefix} {nameConcatenation(map(adminsNames, 'name'))}
      </Typography>
    )}
    {!isEmpty(caregiversNames) ? (
      <Typography variant="subtitle1">
        {viewersListPrefix} {nameConcatenation(map(caregiversNames, 'name'))}
      </Typography>
    ) : (
      <Typography variant="subtitle1">{emptyViewersLabel}</Typography>
    )}
  </>
)

export default StudentSharedUsersInfo
