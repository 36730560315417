import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { FormControl, Grid, InputLabel, MenuItem, Select } from '@material-ui/core'
import Screen from 'checkIn/components/teen/Screen'
import { ButtonNext } from 'checkIn/components/teen/ButtonNext'
import { setQSData } from 'QueryStringController'
import SecondaryEmotionButton from 'checkIn/components/teen/SecondaryEmotionButton'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    height: '60vh',
    [theme.breakpoints.down('sm')]: {
      height: 'initial',
    },
  },
  image: {
    display: 'block',
    width: '200px',
    margin: '0 auto',
  },
  typography: {
    marginTop: theme.spacing(5),
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    fontSize: 32,
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: '#FFF5F5',
    textTransform: 'capitalize',
  },
  button: {
    display: 'flex',
    minWidth: 240,
    borderRadius: 50,
    marginBottom: theme.spacing(5),
    borderWidth: 2,
    textTransform: 'capitalize',
    '&:hover': {
      borderWidth: 2,
    },
  },
  select: {
    color: '#ffffff',
    width: '24rem',
    marginBottom: theme.spacing(5),
    '&:before': {
      borderColor: '#ffffff',
    },
    '&:after': {
      borderColor: '#ffffff',
    },
    [theme.breakpoints.down('sm')]: {
      width: '20rem',
    },
  },
  icon: {
    fill: '#ffffff',
  },
  background: {
    color: 'white',
    backgroundColor: '#212121',
  },
}))

const ScreenContent = ({ preferredContacts, initialSelected = '', nextFunction }) => {
  const classes = useStyles()
  const [selected, setSelected] = useState(initialSelected)

  const onClickButton = (preferredContact = null) => {
    if (preferredContact) {
      setQSData({ preferredContact })
    }
    nextFunction()
  }

  const handlePreferredConatact = (event) => {
    setSelected(event.target.value)
  }

  return (
    <Screen
      className="pt-[6vh] h-[100vh]"
      label="Who would you prefer to speak to?"
      title="All of the adults on your account will see your check-in responses. 
          We can’t promise that the person you select will be the one who 
          reaches out to you. However, we do recommend selecting the 
          person you feel most comfortable speaking to."
    >
      <Grid container alignContent="center" className={classes.container}>
        <Grid
          container
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Grid
            item
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <FormControl className={classes.formControl}>
              <InputLabel style={{ color: 'white' }}>Contact</InputLabel>
              <Select
                value={selected}
                onChange={handlePreferredConatact}
                className={classes.select}
                classes={{
                  icon: classes.icon,
                }}
                MenuProps={{
                  classes: {
                    paper: classes.background,
                  },
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {preferredContacts.map(({ id, displayName }) => (
                  <MenuItem key={id} value={id}>
                    {displayName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <ButtonNext
              style={{ marginBottom: '1.25rem' }}
              disabled={selected < 1}
              onClick={() => onClickButton(selected)}
            >
              <span className="font-medium">Confirm</span>
            </ButtonNext>
            <SecondaryEmotionButton onClick={() => onClickButton(null)}>
              No, thanks.
            </SecondaryEmotionButton>
          </Grid>
        </Grid>
      </Grid>
    </Screen>
  )
}

export default ScreenContent
