import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Image from 'components/ResponsiveImage'

const styles = theme => ({
  container: {
    cursor: 'pointer',
    width: '40vw',
    height: '40vw',
    border: '3px double transparent',
    [theme.breakpoints.up('sm')]: {
      width: '150px',
      height: '150px',
    },
  },
  containerSelected: {
    border: '3px double white',
    borderRadius: '50%',
  },
})

const ComponentMoon = ({ option }) => (
  <>
    <Image src="/images/moon.png" alt={''} />
    <span className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center px-6 text-base font-medium text-center text-black">
      {option}
    </span>
  </>
)

export default withStyles(styles)(
  ({
    classes,
    option,
    selected,
    hideUnselected,
    isConfirmationPage,
    handleMoon,
  }) => {
    if (isConfirmationPage)
      return (
        <div
          className={`${classes.container}`}
          style={{ display: hideUnselected && !selected ? 'none' : 'block' }}
        >
          <ComponentMoon option={option} />
        </div>
      )

    return (
      <button
        type="button"
        className={`${selected ? classes.containerSelected : ''} ${
          classes.container
        }`}
        onClick={() => handleMoon(option)}
        style={{ display: hideUnselected && !selected ? 'none' : 'block' }}
      >
        <ComponentMoon option={option} />
      </button>
    )
  }
)
