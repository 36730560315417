import * as React from 'react'

import StudentsList from 'Educators/ManageSchool/ManageStudents/students-list'
import { useGlobal } from 'useGlobal'
import { filterStudents, GlobalFilter } from 'Educators/filters'
import type { Student } from 'types'

type Props = {
  list: Student[]
  filters: GlobalFilter
  setFilters: (t: any) => void
  classroom?: Partial<{
    id: number
    role: 'admin' | 'viewer'
  }>
}
const StudentsShare = ({ list, filters, setFilters, classroom = {} }: Props) => {
  const [, actions] = useGlobal()
  const students = filterStudents(list, filters)
  const isClassroomAdmin = classroom?.role === 'admin'

  React.useEffect(() => {
    actions.clearSelectedStudents()
  }, [filters, students.length, list.length, classroom?.id]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StudentsList
      allowSelection={isClassroomAdmin}
      list={list}
      filters={filters}
      students={students}
      setFilters={setFilters}
    />
  )
}

export default React.memo(StudentsShare)
