import React, { useState } from 'react'
import { useCroods } from 'croods'
import { error } from 'components/Toast'
import { Button, Card2, Column, P, SubTitle } from '../components/styled'
import { EmailInput } from '../components/EmailInput'
import { AdminScreen } from '../components/AdminScreen'
import { A } from '../components/A'
import { ReportResults, Result } from '../components/ReportResults'
import { EducatorSchools } from './EducatorSchools'
import { StudentsList } from './StudentsList'
import { InstructionsModal } from './InstructionsModal'
import Loading from 'components/Loading'

export const DestroyStudents = (props: any) => {
  const [email, setEmail] = useState<string>('')
  const [studentsEmails, setStudentsEmails] = useState<string>('')
  const [result, setResult] = useState<Result | null>(null)
  const [showStudents, setShowStudents] = useState<boolean>(false)
  const [showEducator, setShowEducator] = useState<boolean>(false)
  const [openInstructions, setOpenInstructions] = useState<boolean>(false)
  const [selectedSchool, setSelectedSchool] = useState<any>(null)
  const [{ info: educator, infoError: edError }, { fetch: getEducator }] = useCroods({
    name: 'admin-educator',
    customPath: 'admin/destroy_students/educator',
    afterSuccess: () => setShowEducator(true),
    afterFailure: () => error(edError || ''),
  })

  const [{ info: students = [], fetchingInfo: fetchingStudents }, { fetch: getStudents }] =
    useCroods({
      name: 'admin-school',
      customPath: 'admin/schools/students',
    })

  const [{ destroying }, { destroy }] = useCroods({
    name: 'admin-destroy-students-by-email',
    customPath: 'admin/destroy_students_by_email',
    afterSuccess: ({ data }) => setResult(data),
    afterFailure: ({ response }) => error(response?.data.message),
  })

  function showAllStudents(s: any) {
    setShowStudents(true)
    setSelectedSchool(s)
    getStudents({ operation: 'info' })({ id: s.id })
  }

  return (
    <AdminScreen title="Destroy Students" {...props}>
      <div
        style={{
          alignSelf: 'center',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '2rem',
          width: '100%',
          maxWidth: '1200px',
          margin: '1rem 0',
        }}
      >
        <Column>
          <P>
            To search and remove students from the educator&apos;s email, follow{' '}
            <A name="these steps" onClick={() => setOpenInstructions(true)} />.
          </P>

          <EmailInput label="Educator email address *" value={email} setValue={setEmail} />

          <Button
            disabled={email === ''}
            variant="edit"
            onClick={() => getEducator({ operation: 'info' })({ email })}
          >
            GET EDUCATOR SCHOOLS
          </Button>
        </Column>

        <Column>
          <P>Add the students emails separated by commas (,)</P>
          <EmailInput
            label="Students emails *"
            value={studentsEmails}
            setValue={setStudentsEmails}
          />
          <Button
            disabled={studentsEmails === ''}
            variant="delete"
            onClick={() => destroy()({ emails: studentsEmails })}
          >
            DESTROY STUDENTS ACCOUNTS
          </Button>
        </Column>
      </div>
      <div style={{ alignSelf: 'center', width: '100%', maxWidth: '1200px', margin: '1rem' }}>
        {destroying ? <Loading /> : null}
        {result ? <ReportResults result={result} title="Students destroyed successfully" /> : null}

        {showEducator ? (
          <Card2>
            <SubTitle>Educator Data</SubTitle>
            <P>Name: {educator?.name}</P>
            <P>Email: {educator?.email}</P>
            <P>User id: {educator?.userId}</P>
            <P>Educator id: {educator?.educatorId}</P>
          </Card2>
        ) : null}
      </div>

      {showEducator ? (
        <EducatorSchools educator={educator} showAllStudents={showAllStudents} />
      ) : null}

      {showStudents ? (
        <StudentsList
          selectedSchool={selectedSchool}
          students={students}
          setShowStudents={setShowStudents}
          fetchingStudents={fetchingStudents}
        />
      ) : null}

      <InstructionsModal open={openInstructions} onClose={() => setOpenInstructions(false)} />
    </AdminScreen>
  )
}
