import React, { createContext, useContext, useState } from 'react'

import type { Student } from 'types'
import { getStudentTypeByGrade } from 'grades'

type CheckInContext = {
  student: Student | null
  handleSetStudent: (student: Student) => void
}

const Context = createContext<CheckInContext>({} as CheckInContext)

const CheckInProvider: React.FC = ({ children }) => {
  const [student, setStudent] = useState<Student | null>(null)

  const handleSetStudent = (data: Student) => {
    const { grade } = data
    const studentType = getStudentTypeByGrade(grade)
    setStudent({ ...data, studentType })
  }

  return (
    <Context.Provider
      value={{
        student,
        handleSetStudent,
      }}
    >
      {children}
    </Context.Provider>
  )
}
const useCheckIn = (): CheckInContext => useContext(Context)

export type { CheckInContext }
export { CheckInProvider, useCheckIn }
