import React from 'react'
import { RadioGroup } from '@material-ui/core'

export default ({ input, ...rest }) => (
  <RadioGroup
    {...input}
    {...rest}
    value={input.value}
    onChange={(_, value) => input.onChange(value)}
  />
)
