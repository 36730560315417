import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { SecondaryButton } from '../components/SecondaryButton'
import { navigateWithParams } from 'utils'

type Props = {
  handleNextFunction: () => void
}

const NineToTwelve = ({ handleNextFunction }: Props) => {
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-2xl sm:text-[32px] leading-10 font-normal font-['Roboto'] mb-4 sm:mb-10">
        Which activity sounds good to you right now?
      </div>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() =>
          navigateWithParams('./activities/breathing', { activity: 'breathing' })
        }
      >
        Breathing
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() =>
          navigateWithParams('./activities/let-it-go', { activity: 'let-it-go' })
        }
      >
        let it go
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() =>
          navigateWithParams('./activities/safe-space', { activity: 'safe-space' })
        }
      >
        safe space
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() =>
          navigateWithParams('./activities/the-ok-inventory', { activity: 'the-ok-inventory' })
        }
      >
        the ok inventory
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() =>
          navigateWithParams('./activities/journaling', { activity: 'journaling' })
        }
      >
        journaling
      </SecondaryButton>

      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-auto sm:mt-20"
      >
        finish
      </NextButton>
    </Screen>
  )
}

export default NineToTwelve
