import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0px 0px 5px 1px rgb(37 0 50 / 25%);
  border-radius: 8px;
  width: 100%;
  padding: 1rem;
  margin: 1rem 0;
`
export const LinksContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin: 0.5rem 0 1rem 0;
  width: 100%;
  gap: 1rem;
`

export const Text = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 160%;
  font-weight: 400;
  color: rgba(153, 142, 201, 0.7);
  padding: 0.5rem 0;
`
