import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent, User } from 'types'
import FLOWS from 'checkIn/flows'
import Teen from 'checkIn/steps/Emotion/Teen'
import Kid from 'checkIn/steps/Emotion/Kid'
import NonReader from 'checkIn/steps/Emotion/NonReader'
import { checkInTrack } from 'students/helper'
import { setQSData } from 'QueryStringController'
import { useMixpanel } from 'mixpanel'
import FiveToEight from './FiveToEight'
import { featureEnabled } from 'featureFlags'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import ThreeToFour from './ThreeToFour'

const SCREEN_NAME = 'chooseEmotion'

const Emotion: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, 'Check-in: How are you feeling now?', student)
    }
  }, [student]) // eslint-disable-line

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    const selectEmotionFunction = (feeling: string, user?: User) => {
      setQSData({ feeling })
      nextFunction(window.location.search, user)
    }

    switch (student.studentType) {
      case 'teen':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <FiveToEight selectEmotionFunction={selectEmotionFunction} />
        ) : (
          <Teen selectEmotionFunction={selectEmotionFunction} />
        )
      case 'kid':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <ThreeToFour selectEmotionFunction={selectEmotionFunction} />
        ) : (
          <Kid selectEmotionFunction={selectEmotionFunction} />
        )
      case 'non-reader':
        return <NonReader currentUser={currentUser} selectEmotionFunction={selectEmotionFunction} />
    }
  }

  return null
}
export default Emotion
