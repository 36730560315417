import React from 'react'
import { Redirect } from '@reach/router'
import { Auth } from 'dmcroods-auth'
import { navigate } from '@reach/router'
import { isEducator, welcomePath } from 'students/helper'
import AuthLoading from 'components/AuthLoading'

export const redirectGuest = (uri) => {
  const routes = ['/sign-in', '/sign-up']

  if (routes.indexOf(uri) !== -1) return null

  const next = uri ? `?next=${encodeURIComponent(uri + window.location.search)}` : ''
  return <Redirect to={`/sign-in${next}`} noThrow />
}

export const redirectStudent = (currentUser, uri = '') => {
  const studentRedirects = [
    '/dashboard',
    '/dashboard/profile',
    '/welcome-back',
    '/search-school',
    '/confirm-school',
    '/add-school-info',
  ]

  return studentRedirects.indexOf(uri) !== -1 || uri.includes('cs-dashboard') ? (
    <Redirect to={welcomePath(currentUser.student)} noThrow />
  ) : null
}

export const redirectEducator = (currentUser, uri = '') => {
  if (!uri.includes('cs-dashboard')) return null
  return currentUser.cs ? null : <Redirect to="/dashboard" noThrow />
}

export const redirectNonEducator = (currentUser, uri = '') => {
  if (uri.includes('cs-dashboard')) return <Redirect to="/dashboard" noThrow />
  const redirects = ['/search-school', '/confirm-school', '/add-school-info']
  return redirects.indexOf(uri) !== -1 ? <Redirect to="/dashboard" noThrow /> : null
}

const authorizeGuest = (user, uri) => {
  const routes = ['/sign-in', '/sign-up']
  return routes.indexOf(uri) !== -1
}

const authorizeStudent = (user, uri) => {
  const routes = [
    '/dashboard',
    '/dashboard/profile',
    '/welcome-back',
    '/search-school',
    '/confirm-school',
    '/add-school-info',
  ]
  if (uri.includes('cs-dashboard')) return true
  return routes.indexOf(uri) !== -1
}

const authorizeEducator = (user, uri) => {
  if (uri.includes('cs-dashboard') && !user.cs) return true
  return false
}

const authorizeNonEducator = (user, uri) => {
  if (uri.includes('cs-dashboard')) return true
  const routes = ['/search-school', '/confirm-school', '/add-school-info']
  return routes.indexOf(uri) !== -1
}

const authByRole = (user, uri = '') => {
  if (!user) {
    return authorizeGuest(user, uri)
  }
  if (user.role === 'student') {
    return authorizeStudent(user, uri)
  }
  if (isEducator(user.role)) {
    return authorizeEducator(user, uri)
  }
  if (user.role === 'parent-guardian' || user.role === 'clinician-therapist') {
    return authorizeNonEducator(user, uri)
  }
}

const redirectByRole = (user) => {
  if (!user) {
    return '/sign-in?next=' + window.location.pathname
  }
  if (user.role === 'student') {
    return welcomePath(user.student)
  }
  return '/dashboard'
}

// export default (Component) =>
//   ({ currentUser, uri, ...props }) => {
//     let RedirectComponent = null
//     if (currentUser) {
//       if (currentUser.role === 'student') RedirectComponent = redirectStudent(currentUser, uri)
//       if (isEducator(currentUser.role)) RedirectComponent = redirectEducator(currentUser, uri)
//       if (currentUser.role === 'parent-guardian' || currentUser.role === 'clinician-therapist')
//         RedirectComponent = redirectNonEducator(currentUser, uri)
//     } else {
//       RedirectComponent = redirectGuest(uri)
//     }

//     return RedirectComponent || <Component currentUser={currentUser} uri={uri} {...props} />
//   }

const AuthWrapper =
  (Component) =>
  ({ uri, ...props }) =>
    (
      <Auth
        Component={Component}
        unauthorize={(user) => {
          return authByRole(user, uri)
        }}
        unauthorized={async (user) => {
          await navigate(redirectByRole(user))
        }}
        authorizing={<AuthLoading />}
        {...props}
      ></Auth>
    )

export default AuthWrapper
