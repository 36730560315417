import React from 'react'
import Screen from 'checkIn/components/teen/Screen'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const Teen = ({ nextFunction }: Props) => {
  return (
    <Screen
      label="There is so much to explore!"
      title="Let’s see how much energy <br /> we have for the day."
      contentWrapperClassName="pt-3 sm:pt-[20vh] md:pt-[25vh]"
    >
      <img
        className="absolute bottom-0 left-0 w-auto h-auto max-h-[50vh]"
        src="/images/teen/cadet_on_the_moon_2.png"
        alt="Happy cadet"
      />
      <BackAndNextButtons
        nextButtonOnClick={() => nextFunction(window.location.search)}
      />
    </Screen>
  )
}

export default Teen
