import { useContext } from 'react'
// @ts-ignore
import MixpanelContext from 'react-mixpanel'

export type Mixpanel = {
  alias: (s: string) => void
  identify: (s: string) => void
  init: (s: string, o?: Record<string, any>) => void
  people: { set: (p: Record<string, string>) => void }
  reset: () => void
  track: (s: string, o?: Record<string, any>) => void
}

export const useMixpanel = (): Mixpanel => useContext(MixpanelContext)
