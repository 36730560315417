import React from 'react';
import EmailIcon from '@material-ui/icons/Email';

type Props = {
  className?: string
  onClick: () => void
}
const IconMessage = ({ className = '', onClick }: Props) => (
  <button
    aria-label="icon-message"
    type="button"
    onClick={onClick}
    className={`fixed p-1 rounded-full border-2 border-primary bg-white hover:bg-primary hover:border-white text-primary hover:text-white ${className}`}
  >
    <EmailIcon fontSize="small" />
  </button>
);

export default IconMessage
