import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { TalkToAnAdultTeen } from 'checkIn/steps/TalkToAdult/Teen'
import { TalkToAnAdultKid } from 'checkIn/steps/TalkToAdult/Kid'
import { TalkToAnAdultNonReader } from 'checkIn/steps/TalkToAdult/NonReader'

const SCREEN_NAME = 'talkToAnAdult'

export const TalkToAdult: RouteComponent = () => {
  const { student } = useCheckIn()

  if (!student) return null

  const { studentType } = student

  const OPTIONS = {
    teen: <TalkToAnAdultTeen flow={FLOWS.teen[SCREEN_NAME]} student={student} />,
    kid: <TalkToAnAdultKid flow={FLOWS.kid[SCREEN_NAME]} student={student} />,
    'non-reader': (
      <TalkToAnAdultNonReader flow={FLOWS[studentType][SCREEN_NAME]} student={student} />
    ),
  }

  return OPTIONS[studentType] || null
}
