import React from 'react'
import { Avatar, Grid, Typography } from '@material-ui/core'

import StudentSharedUsersInfo from './StudentSharedUsersInfo'

const StudentCard = ({
  student,
  strikethrough = false,
  isManageStudents = false,
  emptyViewersLabel = 'No viewers yet',
  viewersListPrefix = 'Viewers',
  adminsListPrefix = 'Admins',
}) => (
  <Grid
    container
    alignItems="center"
    style={{ paddingLeft: 30, marginTop: 20, flexWrap: 'nowrap' }}
  >
    <Grid>
      <Avatar style={{ padding: 5 }}>
        <Typography variant="subtitle2">{`${student.firstName[0]} ${student.lastName[0]}`}</Typography>
      </Avatar>
    </Grid>
    <Grid
      data-testid="student-card-text"
      style={{
        textAlign: 'left',
        marginLeft: 20,
        textDecoration: strikethrough ? 'line-through' : 'none',
      }}
    >
      <Grid>
        <Typography>{`${student.firstName} ${student.lastName}`}</Typography>
      </Grid>
      <Grid>
        <Typography variant="subtitle1">{`Grade ${student.grade}`}</Typography>
      </Grid>
      {isManageStudents && (
        <StudentSharedUsersInfo
          student={student}
          hideGrade
          emptyViewersLabel={emptyViewersLabel}
          viewersListPrefix={viewersListPrefix}
          adminsListPrefix={adminsListPrefix}
        />
      )}
    </Grid>
  </Grid>
)

export default StudentCard
