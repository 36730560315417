import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  label: {
    color: theme.colors.mardiGras,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: 'normal',
    letterSpacing: '0.194444px',
    width: '100%',
    margin: '20px 0',
  },
})

const FormLabel = withStyles(styles)(({ classes, children }) => (
  <Typography className={classes.label}>{children}</Typography>
))

export default FormLabel
