import React, { useEffect, useState } from 'react'
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'
import StopRoundedIcon from '@material-ui/icons/StopRounded'

import clsx from 'clsx'
import { useAudiosController } from 'checkIn/AudioContext'
import { endsWith } from 'lodash'

const useAudio = (url) => {
  const [playing, setPlaying] = useState(false)
  const [audioRef, setAudioRef] = useState(null)

  const toggle = () => {
    if (audioRef && url) {
      if (!endsWith(audioRef.src, url)) audioRef.src = url

      audioRef.currentTime = 0
      if (!playing || audioRef.muted) {
        audioRef.muted = false
        audioRef.play()
        setPlaying(true)
      } else {
        stop()
      }
    }
  }

  const stop = () => {
    if (audioRef) {
      audioRef.pause()
      setPlaying(false)
    }
  }

  useEffect(() => {
    if (audioRef && url) {
      audioRef.src = url
      audioRef.load()
    }
  }, [audioRef, url]) // eslint-disable-line

  useEffect(() => {
    if (audioRef) {
      setPlaying(audioRef.autoplay)
      audioRef.addEventListener('ended', () => setPlaying(false))
    }
    return () => {
      if (audioRef) {
        audioRef.removeEventListener('ended', () => setPlaying(false))
      }
    }
  }, [audioRef]) // eslint-disable-line

  return [playing, setPlaying, toggle, setAudioRef, stop]
}

const AudioPlayer = ({
  importAudio,
  children,
  className = '',
  styleButton = {},
  autoplay = false,
}) => {
  const { playingAudio, setPlayingAudio, muted, setMuted } =
    useAudiosController()
  const [playing, setPlaying, toggle, setAudioRef, stop] = useAudio(importAudio)

  useEffect(() => {
    if (playingAudio !== importAudio && playing) {
      stop()
    }
    setMuted(false)
  }, [playingAudio]) // eslint-disable-line

  useEffect(() => {
    if (autoplay) {
      setPlaying(true)
    }
  }, [importAudio]) // eslint-disable-line

  return (
    <div className={clsx('flex', className)}>
      {children}
      <button
        style={{
          width: 40,
          minWidth: 40,
          minHeight: 40,
          height: 40,
          borderRadius: '50%',
          position: 'relative',
          marginLeft: -20,
          marginBottom: -18,
          alignSelf: 'flex-end',
          borderColor: 'white',
          borderStyle: 'solid',
          borderWidth: '1px',
          zIndex: 999,
          ...styleButton,
        }}
        className={clsx(
          'text-white',
          !playing && 'bg-secondary-light hover:bg-secondary',
          playing &&
            'animate-playing bg-primary hover:bg-primary-dark shadow-md',
          !importAudio && 'hidden',
        )}
        type="button"
        onClick={() => {
          playingAudio !== importAudio && setPlayingAudio(importAudio)
          toggle(importAudio)
          if (muted) {
            setMuted(false)
          }
        }}
      >
        <audio
          ref={(c) => setAudioRef(c)}
          autoPlay={autoplay}
          playsInline
          muted={muted}
          src={importAudio}
          type="audio/mpeg"
        />
        {playing ? (
          <StopRoundedIcon data-testid="stop-icon" />
        ) : (
          <PlayArrowRoundedIcon data-testid="play-icon" />
        )}
      </button>
    </div>
  )
}

export default AudioPlayer
