import React from 'react'

import { Column, P, Row, SubTitle } from '../components/styled'

export const DetailedSchoolInfo = ({ school: s }: any) => (
  <>
    <div style={{ display: 'grid', gridTemplateColumns: '2fr 1fr 1fr' }}>
      <Column>
        <SubTitle>
          {s?.name} (Id: {s?.schoolId})
        </SubTitle>
        <P>
          <strong>Address:</strong> {s?.address}
        </P>
        <P>
          <strong>District: </strong>
          {s?.district?.name} (id: {s?.district?.id}, state: {s?.district?.state} )
        </P>
        <P>Reviewed: {s?.reviewed?.toString()}</P>
        <P>Premium: {s?.premium?.toString()}</P>
        <P>Created at: {s?.createdAt}</P>
      </Column>

      <Column>
        <P>Students: {s?.students}</P>
        <P>Grades: {s?.grades?.join(', ')}</P>
        <P>Classrooms: {s?.classrooms}</P>

        <P>Educators total count: {s?.educators?.total}</P>
        <P>Owners: {s?.educators?.ownersType}</P>
        <P>Viewers (Staff): {s?.educators?.viewersType}</P>
      </Column>

      <Column>
        <P>Access requests: {s?.accessRequests}</P>
        <P>Educator invitations: {s?.educatorInvitations}</P>
        <P>Alerts: {s?.alerts}</P>
        <P>Vacations count: {s?.vacations}</P>
      </Column>
    </div>
    <br />
    <Row>
      <Column>
        <SubTitle>Owners Emails:</SubTitle>
        <P>{s?.ownersEmails?.join(', ')}</P>
      </Column>

      <Column>
        <SubTitle>Viewers Emails:</SubTitle>
        <P>{s?.viewersEmails?.join(', ')}</P>
      </Column>
    </Row>
  </>
)
