import React, { useMemo } from 'react'

import Screen from 'checkIn/components/teen/Screen'
import { planetList } from 'checkIn/constants/fiveToEight/planetList'
import Image from 'components/ResponsiveImage'
import { isWidthUp, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

type Props = {
  width: Breakpoint
  selectEmotionFunction: (params: string) => void
}

const FiveToEight = ({ selectEmotionFunction, width }: Props) => {
  const offsets = useMemo(() => {
    return planetList.map(() => {
      return [Math.floor(Math.random() * 80 - 39), Math.floor(Math.random() * 50 - 24)]
    })
  }, [])
  return (
    <Screen label={'Choose 1 Planet:'} title={'How are you feeling now?'}>
      <div className="flex flex-col w-screen md:mt-10 mt-4 text-center pb-8">
        <div className="container grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
          {planetList.map(({ feeling, src, label }, index) => (
            <div key={feeling} className="sm:mt-10">
              <button
                type="button"
                onClick={() => {
                  selectEmotionFunction(feeling)
                }}
                style={{
                  transform: isWidthUp('sm', width)
                    ? `translateX(${offsets[index][0]}px) translateY(${offsets[index][1]}px)`
                    : 'none',
                }}
              >
                <Image src={src} alt={''} className="mb-2.5 max-w-[132px] mx-auto block" />
                <p className="text-xl text-center tracking-wider font-medium text-[#FFF5F5]">
                  {label}
                </p>
              </button>
            </div>
          ))}
        </div>
      </div>
    </Screen>
  )
}

export default withWidth()(FiveToEight)
