import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { setQSData } from 'QueryStringController'
import ThreeToFour from 'checkIn/steps/Emotion/ThreeToFour'

const SCREEN_NAME = 'chooseEmotion'

const Emotion: RouteComponent = () => {
  const { student } = useCheckIn()
  useEffect(() => {
    document.body.style.backgroundImage = `url('/images/moods/threeToFour/default.png')`
  }, [])

  if (student) {
    const { nextFunction } = FLOWS['kid'][SCREEN_NAME]

    const selectEmotionFunction = (feeling: string) => {
      setQSData({ feeling })
      nextFunction(window.location.search)
    }

    return <ThreeToFour selectEmotionFunction={selectEmotionFunction} />
  }

  return null
}
export default Emotion
