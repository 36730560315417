import React from 'react'
import { Container, makeStyles, Grid } from '@material-ui/core'

import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'

import { useSchools } from 'Educators/SchoolsContext'

import NewClassroom from 'Educators/ManageSchool/NewSchoolYear/SetUp/AddClassrooms/newClassroom'
import { navigate } from '@reach/router'
import { User } from 'types'
import NewClassroomWithPartialUpload from 'Educators/ManageSchool/NewSchoolYear/SetUp/AddClassrooms/NewClassroomWithPartialUpload'
import { featureEnabled } from 'featureFlags'
import Breadcrumbs from 'components/Breadcrumbs'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  form: {
    maxWidth: '684px',
    alignSelf: 'center',
    marginTop: '40px',
  },
  card: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(5),
  },
  title: {
    fontSize: '36px',
    letterSpacing: '0.5px',
    // @ts-ignore
    color: theme.colors.mardiGras,
  },
  lowerOpacity: {
    opacity: '50%',
  },
}))

const CreateClassrooms = ({ currentUser }: { currentUser: User }) => {
  const { school } = useSchools()
  const classes = useStyles()

  return (
    <DashboardScreen
      currentUser={currentUser}
      helperCadetCustomLink="https://closegaphelp.zendesk.com/hc/en-us/articles/17627052087323-How-do-I-get-a-Students-CSV-ready-to-be-uploaded-"
    >
      <Container className={classes.root}>
        <Breadcrumbs
          paths={[
            { children: `Manage ${school.name}`, to: '/manage-school?resourceType=classrooms' },
          ]}
        >
          Create New Classroom
        </Breadcrumbs>
        <Grid className={classes.form}>
          {featureEnabled(currentUser, 'partial_upload') ? (
            <NewClassroomWithPartialUpload
              returnToIndexFn={(id: number) => navigate(`/manage-school/classrooms/${id}`)}
              buttonText="Create Classroom"
              requireStudents={false}
            />
          ) : (
            <NewClassroom
              returnToIndexFn={(id: number) => navigate(`/manage-school/classrooms/${id}`)}
              buttonText="Create Classroom"
              requireStudents={false}
            />
          )}
        </Grid>
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(CreateClassrooms)
