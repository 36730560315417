import React, { useState } from 'react'
import type { RouteComponent } from 'types'
import Screen from '../components/screen'

import { navigateWithParams } from 'utils'
import { NextButton } from '../components/NextButton'
import { useCroods } from 'croods'

const Feedback: RouteComponent = ({ studentId }) => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null)
  const [note, setNote] = useState('')
  const charLimit = 600

  const [{ fetchingList }] = useCroods({
    fetchOnMount: true,
    customPath: `students/${studentId}/check_in_feedbacks`,
    name: 'check_in_feedbacks',
    afterSuccess: ({ data }) => {
      if (!data.timeForMeasurement) {
        navigateWithParams('./end')
      }
    },
    after4xx: () => {
      navigateWithParams('./end')
    },
    after5xx: () => {
      navigateWithParams('./end')
    },
  })

  const [{ saving }, { save }] = useCroods({
    name: 'checkInFeedbacks',
    path: `students/${studentId}/check_in_feedbacks`,
  })

  const onClick = () => {
    save({
      afterSuccess: () => {
        navigateWithParams('./end')
      },
    })({
      checkInFeedback: {
        helpfulness: selectedOption,
        note: note,
      },
    })
  }

  if (fetchingList) {
    return (
      <Screen showSummary={false}>
        <></>
      </Screen>
    )
  }

  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl w-full text-center text-gray-100 text-2xl sm:text-[32px] font-normal font-['Roboto'] leading-loose mb-8 sm:mb-16">
        On a scale from 1 (not at all) to 5 (so much), how much does the check-in contribute to your
        well-being?
      </div>
      <div className="flex flex-col sm:flex-row gap-10 mb-8 sm:mb-20">
        {[...Array(5)].map((_, i) => {
          const value = i + 1
          return (
            <button
              key={value}
              className={`text-white h-14 min-w-14 flex items-center  justify-center border-2 font-bold tracking-button uppercase text-xl leading-5  bg-cover bg-no-repeat bg-center rounded-full shadow-sm hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0 data-[selected=true]:bg-gray-100 data-[selected=true]:text-zinc-600 data-[selected=true]:[text-shadow:none]`}
              data-selected={value === selectedOption}
              onClick={() => setSelectedOption(value)}
            >
              {value}
            </button>
          )
        })}
      </div>
      {!!selectedOption && (
        <div className="flex flex-col sm:flex-row gap-10 mb-8 sm:mb-20 max-w-6xl w-full">
          <textarea
            autoFocus={true}
            rows={3}
            className="form-textarea w-full text-2xl bg-transparent p-6 rounded-lg border border-gray-100 text-gray-100 font-normal font-['Roboto'] leading-normal placeholder:text-gray-100/75"
            placeholder="How could it be better?"
            value={note}
            onChange={(event) => {
              if (event.target.value.length < charLimit) {
                setNote(event.target.value)
              }
            }}
          />
        </div>
      )}
      <NextButton disabled={!selectedOption || saving} handleNextFunction={onClick}>
        Confirm
      </NextButton>
    </Screen>
  )
}

export default Feedback
