import React from 'react'
import { Fetch } from 'croods'
import map from 'lodash/map'

import { Notes } from 'Educators/students/list/Comments/Notes'
import { isStudent } from 'students/helper'

type Props = {
  checkInId: number
}

const ListCommentsV2 = ({ checkInId }: Props) => (
  <Fetch
    name="checkInComments"
    path={`/check_in_comments?check_in_id=${checkInId}`}
    stateId={checkInId}
    render={(comments) => {
      return (
        <>
          {map(comments, ({ user, message, createdAt }, index) => {
            const { name, role } = user

            return (
              <Notes
                key={index}
                name={name}
                date={createdAt}
                notes={message}
                isStudent={isStudent(role)}
                highlight={false}
                fromCheckIn={false}
              />
            )
          })}
        </>
      )
    }}
  />
)

export default ListCommentsV2
