import React from 'react'

import { CadetOnTopScreen } from 'components/CadetOnTopScreen'
import FormContainer from 'components/FormContainer'

export default (props) => (
  <CadetOnTopScreen>
    <FormContainer {...props}>{props.children}</FormContainer>
  </CadetOnTopScreen>
)
