import React from 'react'
import { Oval } from 'react-loader-spinner'

export const Loader = () => (
  <div
    style={{
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '0.5rem 0',
    }}
  >
    <Oval
      height={40}
      width={40}
      color="#250032"
      wrapperStyle={{}}
      wrapperClass=""
      visible={true}
      ariaLabel="oval-loading"
      secondaryColor="#250032"
      strokeWidth={8}
      strokeWidthSecondary={8}
    />
  </div>
)
