import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import Teen from 'checkIn/steps/ShareAnythingElse/Teen'
import Kid from 'checkIn/steps/ShareAnythingElse/Kid'

const SCREEN_NAME = 'shareAnythingElse'

const ShareAnythingElse: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    switch (student.studentType) {
      case 'teen':
        return <Teen flow={FLOWS.teen[SCREEN_NAME]} />
      case 'kid':
        return <Kid flow={FLOWS.kid[SCREEN_NAME]} />
    }
  }

  return null
}

export default ShareAnythingElse
