import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
export const PlanetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin-top: 5vh;
  margin-bottom: 10vh;
  @media screen and (max-width: 600px) {
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`
export const Title = styled.h1<{ color?: string }>`
  color: ${({ color }) => (Boolean(color) ? color : '#00ddc1')};
  font-size: 1.25rem;
  font-weight: 500;
  text-align: center;
  padding: 3vh 0;
  @media screen and (max-width: 600px) {
    margin: 0.5rem 0;
    padding: 0;
  }
`

export const Subtitle = styled.h2`
  color: #fff5f5;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  margin: 0;
  padding: 3vh 0;
  @media screen and (max-width: 600px) {
    margin: 0;
    padding: 0.5rem;
  }
`

export const Label = styled.p`
  color: #fff5f5;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0;
  padding: 0;
`

export const Image = styled.img`
  width: 6rem;
  height: auto;
  @media screen and (max-width: 700px) {
    width: 4rem;
  }
`
