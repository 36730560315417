import React from 'react'
import { makeStyles, Typography, Grid, Button } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import AddIcon from '@material-ui/icons/Add'
import TodayIcon from '@material-ui/icons/Today'
import { useSchools } from 'Educators/SchoolsContext'
import { navigate } from '@reach/router'

import InviteEducators from 'Educators/ManageSchool/InviteEducators'

const useStyles = makeStyles((theme) => ({
  mainTitle: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '36px',
    linehHeight: 'normal',
    color: theme.colors.mardiGras,
    maxWidth: 480,
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    float: 'right',
    justifyContent: 'center',
  },
}))

const TitleBar = () => {
  const classes = useStyles()
  const { school, isSchoolOwner } = useSchools()

  return (
    <Grid container>
      <Grid item sm={7}>
        <Typography className={classes.mainTitle} noWrap>
          Manage {school.name}
        </Typography>
      </Grid>
      <Grid item sm={5} className={classes.buttons}>
        {isSchoolOwner() && (
          <nav className="flex flex-col items-end gap-4">
            <InviteEducators />
            <Button
              variant="outlined"
              color="primary"
              endIcon={<PersonAddIcon />}
              onClick={() => navigate('/dashboard/students/new')}
            >
              REGISTER STUDENT(S)
            </Button>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<AddIcon />}
              onClick={() => navigate('/manage-school/classrooms/create')}
            >
              CREATE NEW CLASSROOM
            </Button>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<TodayIcon />}
              onClick={() => navigate('/manage-school/vacations')}
            >
              CALENDAR SETTINGS
            </Button>
          </nav>
        )}
      </Grid>
    </Grid>
  )
}

export default TitleBar
