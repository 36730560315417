import React from 'react'
import { navigate } from '@reach/router'
import { Student } from 'types'
import styled from 'styled-components'
import AdultsOnAccount from './AdultsOnAccount'
import Button from '@material-ui/core/Button'
import rocket from './rocket.png'
import activityIcon from './activityIcon.png'
import { welcomePath } from 'students/helper'
import { isCheckInAvailable } from 'checkIn/helper'
import Title from 'components/FormTitle'

const Header = styled.div`
  margin-top: 2rem;
`

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 2rem;
  margin-bottom: 4rem;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;

  button:first-child {
    margin-bottom: 0.5rem;
  }
`

const ProfileHeader = ({ student }: { student: Student }) => (
  <Header>
    <Title>My profile</Title>
    <Container>
      <AdultsOnAccount adults={student.adultsOnAccount} />
      <ButtonContainer>
        {isCheckInAvailable('student', student.grade, student.checkInAvailable) && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => navigate(welcomePath(student))}
          >
            Ready to check-in{' '}
            <img src={rocket} alt="rocket" style={{ marginLeft: 10, height: 24 }} />
          </Button>
        )}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => navigate(`/activities/${student.id}/choose-activity`)}
        >
          Explore Activities{' '}
          <img src={activityIcon} alt="celebration" style={{ marginLeft: 10, height: 24 }} />
        </Button>
      </ButtonContainer>
    </Container>
  </Header>
)

export default ProfileHeader
