import React from 'react'
import CadetOnBottom from 'checkIn/components/kid/CadetOnBottomScreen'

import { ButtonNext } from 'checkIn/components/ButtonNext'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const Kid = ({ nextFunction }: Props) => {
  return (
    <CadetOnBottom
      title="My name is Cadet."
      text="I’m so excited to explore emotions with you! "
      backgroundImage="url('/images/cadet_on_the_moon_2.png')"
    >
      <ButtonNext onClick={() => nextFunction(window.location.search)}>
        <span className="font-bold">Ready to Go!</span>
      </ButtonNext>
    </CadetOnBottom>
  )
}

export default Kid
