import React from 'react'
import styled from 'styled-components'
import validateUserFields from 'auth/validateUserFields'
import { Menu } from './Menu'
import { Container } from './styled'
import { Toast } from 'components/Toast'

const PageTitle = styled.h1`
  font-size: 2rem;
  width: 100%;
  text-align: center;
  color: #f2f2f2;
  margin-bottom: 1rem;
`

const Main = styled.main`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  max-height: 100vh;
  color: #f2f2f2;
  padding: 0.5rem;
  overflow-y: auto;
  overflow-x: hidden;
`

export const AdminScreen = validateUserFields((props: any) => (
  <Container>
    <Menu />
    <Main>
      <PageTitle>{props.title}</PageTitle>
      {props.children}
    </Main>
    <Toast />
  </Container>
))
