import React, { useEffect } from 'react'
import { Container } from '@material-ui/core'

import { DashboardScreen } from 'components/DashboardScreen'
import Breadcrumbs from 'components/Breadcrumbs'
import validateUserFields from 'auth/validateUserFields'
import isParentFn from 'auth/isParent'

import { useMixpanel } from 'mixpanel'
import { useSchools } from 'Educators/SchoolsContext'
import AddStudent from './New'
import ImportStudents from './Import'
import PartialImportStudents from './PartialImport'

import type { User } from 'types'
import { featureEnabled } from 'featureFlags'

type Props = {
  currentUser: User
}
const NewStudent = ({ currentUser, ...props }: Props) => {
  const mixpanel = useMixpanel()
  const { schoolId, school } = useSchools()
  const isParent = isParentFn(currentUser)
  const childReference = isParent ? 'Kid' : 'New Student'

  useEffect(() => {
    mixpanel.track('View add students')
  }, []) // eslint-disable-line

  return (
    <DashboardScreen currentUser={currentUser}>
      <Container className="flex flex-col">
        <Breadcrumbs
          paths={
            school?.name
              ? [{ children: `Manage ${school.name}`, to: '/manage-school?resourceType=students' }]
              : [{ children: 'Dashboard', to: '/dashboard' }]
          }
        >
          {schoolId ? 'Register Students' : `Add a ${childReference}`}
        </Breadcrumbs>
        {isParent ? (
          <section className="mt-10">
            <AddStudent {...props} />
          </section>
        ) : (
          <section className="pl-10 pb-10 pr-10 mt-10">
            <div className="w-full my-10 text-center">
              {featureEnabled(currentUser, 'partial_upload') ? (
                <PartialImportStudents />
              ) : (
                <ImportStudents />
              )}
            </div>
            <AddStudent {...props} />
          </section>
        )}
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(NewStudent)
