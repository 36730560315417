import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

import Image from 'components/ResponsiveImage'
import StudentTypography from 'components/StudentsTypography'

const useStyles = makeStyles(
  (theme: {
    [x: string]: any
    breakpoints: {
      [x: string]: any
      down: (arg0: string) => any
    }
  }) => ({
    container: {
      cursor: 'pointer',
      width: '40vw',
      height: '40vw',
      border: '3px double transparent',
      [theme.breakpoints.up('sm')]: {
        width: '150px',
        height: '150px',
      },
      padding: '5px',
    },
    containerSelected: {
      border: '1px solid white',
      borderRadius: '50%',
      outline: '1px outset white',
      outlineOffset: '5px',
    },
    label: {
      color: '#534B58',
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 25px',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      fontSize: '18px',
      fontWeight: 600,
    },
  }),
)

const ComponentMoon = ({ option, classNameLabel }: { option: string; classNameLabel: any }) => (
  <>
    <Image
      src="/images/planets/fiveToEight/moon.png"
      alt={option}
      style={{
        filter: 'drop-shadow(0px 0px 16px #93A4AE)',
      }}
    />
    <StudentTypography
      variant="body1"
      className={classNameLabel}
      component={undefined}
      style={undefined}
    >
      {option}
    </StudentTypography>
  </>
)

type MoonProps = {
  option: string
  selected: boolean
  hideUnselected: boolean
  isConfirmationPage: boolean
  handleMoon: (item: string) => void
}

const Moon = ({ option, selected, hideUnselected, isConfirmationPage, handleMoon }: MoonProps) => {
  const classes = useStyles()
  if (isConfirmationPage)
    return (
      <div
        className={`${classes.container}`}
        style={{ display: hideUnselected && !selected ? 'none' : 'block' }}
      >
        <ComponentMoon option={option} classNameLabel={classes.label} />
      </div>
    )

  return (
    <button
      type="button"
      className={`${selected ? classes.containerSelected : ''} ${classes.container}`}
      onClick={() => handleMoon(option)}
      style={{ display: hideUnselected && !selected ? 'none' : 'block' }}
    >
      <ComponentMoon option={option} classNameLabel={classes.label} />
    </button>
  )
}
export default Moon
