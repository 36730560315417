import React from 'react'
import clsx from 'clsx'

type Props = {
  children: React.ReactNode
  onClick: () => void
  className?: string
}
export const ButtonNext: React.FC<Props> = ({
  children,
  onClick,
  className
}: Props) => (
  <button type="button" onClick={onClick} className={clsx("px-5 py-1 tracking-wider text-base font-bold text-[#00DDC1] hover:text-white bg-transparent border-2 border-[#00DDC1] hover:border-transparent hover:bg-[#00DDC1] rounded-full", className)}>
    {children}
  </button>
)

export default ButtonNext
