const screenContent = {
  happy: {
    label: 'You are feeling happy!',
    confirmationTitle: 'We all feel happy sometimes, and that’s awesome!',
    image: '/images/teen/planet_happy.png',
    options: ['Relaxed', 'Awake', 'Light or Bubbly', 'Warm', 'Energetic', 'Heart Flutters'],
  },
  angry: {
    label: 'You are feeling angry.',
    confirmationTitle: 'We all feel angry sometimes, and that’s okay.',
    image: '/images/teen/planet_angry.png',
    options: [
      'Shaky',
      'Head Hurts',
      'Hot or Red',
      'Stomach Hurts',
      'Stiff or Tense',
      'Breathing Hard',
    ],
  },
  sad: {
    label: 'You are feeling sad.',
    confirmationTitle: 'We all feel sad sometimes, and that’s okay.',
    image: '/images/teen/planet_sad.png',
    options: [
      'Stuck or Frozen',
      'Head Hurts',
      'Stomach Hurts',
      'Empty',
      'Tired or Weak',
      'Disconnected',
    ],
  },
  worried: {
    label: 'You are feeling worried.',
    confirmationTitle: 'We all feel worried sometimes, and that’s okay.',
    image: '/images/teen/planet_worried.png',
    options: [
      'Shaky or Fidgety',
      'Sick to my Stomach',
      'Heavy Chest or Heart',
      'Hot or Sweaty',
      'Breathing Fast',
      'Stuck or Frozen',
    ],
  },
  insecure: {
    label: 'You are feeling insecure.',
    confirmationTitle: 'We all feel insecure sometimes, and that’s okay.',
    image: '/images/teen/planet_insecure.png',
    options: [
      'Stuck or Frozen',
      'Heavy Chest or Heart',
      'Breathing Fast',
      'Heart Racing',
      'Disconnected',
      'Shaky or Fidgety',
    ],
  },
  excited: {
    label: 'You are feeling excited!',
    confirmationTitle: 'We all feel excited sometimes, and that’s awesome!',
    image: '/images/teen/planet_excited.png',
    options: ['Relaxed', 'Awake', 'Light or Bubbly', 'Warm', 'Energetic', 'Heart Flutters'],
  },
  relaxed: {
    label: 'You are feeling relaxed!',
    confirmationTitle: 'We all feel relaxed sometimes, and that’s awesome!',
    image: '/images/teen/planet_relaxed.png',
    options: [
      'Relaxed',
      'Clear or Awake',
      'Comfy or at Ease',
      'Warm',
      'Steady or Strong',
      'Calm Breathing',
    ],
  },
  overwhelmed: {
    label: 'You are feeling overwhelmed.',
    confirmationTitle: 'We all feel overwhelmed sometimes, and that’s okay.',
    image: '/images/teen/planet_overwhelmed.png',
    options: [
      'Foggy',
      'Stuck or Frozen',
      'Sick to my Stomach',
      'Breathing Fast',
      'Shaky or Fidgety',
      'Mind Full of Thoughts',
    ],
  },
} as const

export default screenContent
