import React, { useEffect, useState } from 'react'
import { useCroods } from 'croods'
import Loading from 'components/Loading'

import { AdminScreen } from '../components/AdminScreen'
import { P } from '../components/styled'
import { A } from '../components/A'
import { SchoolItem } from './SchoolItem'
import { InstructionsModal } from './InstructionsModal'

export const SchoolReview = (props: any) => {
  const [{ list: schools = [], fetchingList }, { fetch }] = useCroods({
    name: 'schoolsToReview',
    cache: true,
    customPath: `/admin/schools/to_review`,
  })
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    fetch()()
  }, []) // eslint-disable-line

  return (
    <AdminScreen title={`School Registration Review (${schools?.length})`} {...props}>
      <div style={{ alignSelf: 'center', width: '100%', maxWidth: '500px' }}>
        <P>
          The school registration request review process is a critical step in ensuring that only
          eligible schools are granted access to the Closegap app.
        </P>
        <P>
          The schools listed below are those that have not yet been reviewed (approved or
          disapproved).
        </P>
        <P>
          Please read <A onClick={() => setOpen(true)} name="these instructions" /> to review
          schools appropriately.
        </P>
      </div>
      {fetchingList ? <Loading /> : schools?.map((s) => <SchoolItem key={s.schoolId} school={s} />)}

      <InstructionsModal open={open} setOpen={setOpen} />
    </AdminScreen>
  )
}
