import React, { useEffect } from 'react'
import { Grid, Typography, withStyles } from '@material-ui/core'
import { useWindowDimensions } from '@seasonedsoftware/utils'

import withMixpanel from 'mixpanel/withMixpanel'
import { checkInTrack } from 'students/helper'
import cadetImage from './cadet.png'
import backgroundImage from './background.png'

const CadetArea = ({ mixpanel, name, classes }) => {
  const { width } = useWindowDimensions()

  const isDesktop = width >= 600
  const normalizer = normalizeDistance(width)
  const cadetStyle = isDesktop
    ? { marginLeft: normalizer(-142), marginRight: normalizer(60) }
    : {}

  useEffect(() => {
    checkInTrack(mixpanel, 'View My Profile')
  }, []) // eslint-disable-line

  return (
    <Grid container justifyContent="center" className={classes.imageBackground}>
      <img src={cadetImage} alt="Cadet" height="194" style={cadetStyle} />
      <Grid item>
        <Grid container direction="column" className={classes.textContainer}>
          <Typography variant="h1">Hi {name}!</Typography>
          <Typography className={classes.text} align="center">
            Here&apos;s a history of how you&apos;ve been
            {isDesktop ? <br /> : ' '}
            checking in with Closegap so far
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

export function normalizeDistance(width) {
  return (value) => (value / 1440) * width
}

const styles = (theme) => ({
  textContainer: {
    marginTop: 40,
    [theme.breakpoints.only('xs')]: {
      marginTop: -60,
    },
  },
  text: {
    fontSize: 20,
    lineHeight: 'normal',
    color: 'white',
    marginTop: 16,
    [theme.breakpoints.only('xs')]: {
      padding: '0 24px',
    },
  },
  imageBackground: {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'auto 100%',
    backgroundColor: 'white',
    backgroundRepeat: 'no-repeat',
    height: 270,
    [theme.breakpoints.only('xs')]: {
      height: 320,
    },
  },
})

export default withMixpanel(withStyles(styles)(CadetArea))
