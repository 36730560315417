import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import { getQSData, setQSData } from 'QueryStringController'
import NineToTwelve from './NineToTwelve'
import { navigate } from '@reach/router'
import parseCheckIn from 'checkIn/parseCheckIn'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { setLocalStorageCheckIn } from 'students/helper'

const Emotion: RouteComponent = () => {
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'checkIns',
    path: '/check_ins',
  })
  const { cadet } = getQSData()
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()
  const userId = currentUser?.id

  if (student) {
    const nextFunction = () => {
      const newValue = parseCheckIn(userId, student.id)
      if (saving) return
      save({
        afterSuccess: () => {
          setLocalStorageCheckIn('notes', null)
          setLocalStorageCheckIn('goal', null)
          navigate(`./feedback?cadet=${cadet}`, { replace: true })
        },
      })(newValue)
    }
    const selectEmotionFunction = (feelingNow: string) => {
      setQSData({ feelingNow })
      nextFunction()
    }

    return (
      <NineToTwelve nextFunction={selectEmotionFunction} saving={saving} saveError={saveError} />
    )
  }

  return null
}
export default Emotion
