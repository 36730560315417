import clsx from 'clsx'
import React from 'react'

type Props = {
  children: React.ReactNode
  onClick: () => void
  className?: string
  disabled?: boolean
}
export const ButtonNext: React.FC<Props> = ({
  children,
  onClick,
  className,
  disabled,
}: Props) => (
  <button
    type="button"
    disabled={disabled}
    onClick={onClick}
    className={clsx(
      'px-8 py-3 text-3xl md:px-24 md:py-6 md:text-4xl text-white bg-primary rounded-3xl hover:bg-[#a33c2b] disabled:opacity-30 disabled:hover:bg-primary',
      className,
    )}
  >
    {children}
  </button>
)

export default ButtonNext
