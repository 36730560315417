import React from 'react'
// @ts-ignore
import Checkbox from '@material-ui/core/Checkbox'

import { useMixpanel } from 'mixpanel'
import { useGlobal } from 'useGlobal'
import type { Student } from 'types'

type Props = { student: Student }
function ActionComponent({ student }: Props) {
  const mixpanel = useMixpanel()
  const [state, actions] = useGlobal()
  const checked = state.selectedStudents.includes(student.id)

  const onChange = () => {
    mixpanel.track('Student checkbox clicked', {
      'Student Id': student.id,
      checked: !checked,
    })
    actions.toggleStudent(student.id)
  }

  return (
    <Checkbox
      data-testid="muiCheckbox"
      checked={checked}
      color="primary"
      onChange={onChange}
    />
  )
}

export default ActionComponent
