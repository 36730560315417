import React from 'react'

import AudioPlayer from 'checkIn/AudioPlayer'
import { QUESTIONS_PAGE } from 'checkIn/audios'
import { ButtonNext } from 'checkIn/components/ButtonNext'

const CheckInButtonGroup = ({
  leftButtonText,
  handleLeftButton,
  rightButtonText,
  handleRightButton,
  audio,
}) => (
  <div className="flex flex-col items-center justify-center w-screen gap-10 p-10 mt-10 text-center sm:flex-row">
    <AudioPlayer importAudio={QUESTIONS_PAGE[audio.left]}>
      <ButtonNext
        className="w-screen max-w-[240px] md:max-w-sm !px-5 !sm:px-12"
        aria-label="left-button"
        onClick={handleLeftButton}
      >
        <span className="font-medium whitespace-nowrap">{leftButtonText}</span>
      </ButtonNext>
    </AudioPlayer>
    <AudioPlayer importAudio={QUESTIONS_PAGE[audio.right]}>
      <ButtonNext
        className="w-screen max-w-[240px] md:max-w-sm !px-5 !sm:px-12"
        aria-label="right-button"
        onClick={handleRightButton}
      >
        <span className="font-medium whitespace-nowrap">{rightButtonText}</span>
      </ButtonNext>
    </AudioPlayer>
  </div>
)

export default CheckInButtonGroup
