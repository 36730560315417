import React from 'react'
import TextField from '@material-ui/core/TextField'
import { debounce, isEmpty } from 'lodash'
import Autocomplete from '@material-ui/lab/Autocomplete'

const listMinimumPercentage = ['20', '35', '50', '80']

const MinimumPercentage = ({
  disabled,
  label,
  minimumPercentage,
  handleMinimumPercentage,
}) => {
  const [percentage, setPercentage] = React.useState('')
  const delayedRequest = React.useRef(
    debounce((value) => {
      handleMinimumPercentage(value)
    }, 350),
  ).current

  const onChange = (_, value) => {
    if (/^(^$|([1-9]|[1-9][0-9]|100)[%]?)$/.test(value)) {
      setPercentage(value)
      delayedRequest(value)
    }
  }

  React.useEffect(() => {
    isEmpty(minimumPercentage) && setPercentage('')
  }, [minimumPercentage])

  return (
    <div className="w-40">
      <Autocomplete
        freeSolo
        fullWidth
        disableClearable
        disabled={disabled}
        onChange={onChange}
        inputValue={percentage}
        onInputChange={onChange}
        options={listMinimumPercentage.map((option) => option)}
        getOptionLabel={(option) => option.concat('%')}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{ ...params.InputProps, type: 'search' }}
          />
        )}
      />
    </div>
  )
}

export default MinimumPercentage
