const RACE_ETHNICITY = [
  {
    value: 'american_indian_alaska_native',
    label: 'American Indian/Alaska Native',
  },
  { value: 'asian', label: 'Asian' },
  { value: 'black_african_american', label: 'Black/African American' },
  {
    value: 'native_hawaiian_other_pacific_islander',
    label: 'Native Hawaiian/Other Pacific Islander',
  },
  { value: 'white_caucasian', label: 'White/Caucasian' },
  { value: 'hispanic_latinx', label: 'Hispanic/Latinx' },
  { value: 'two_or_more_races', label: 'Two or more races' },
  { value: 'other', label: 'Other' },
]

export const getEthnicity = (ethnicity) =>
  RACE_ETHNICITY.find(({ value }) => value === ethnicity)

export default RACE_ETHNICITY
