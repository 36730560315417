import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Image from 'components/ResponsiveImage'

const styles = (theme) => ({
  container: {
    padding: '20px',
    flex: '1',
    [theme.breakpoints.up('sm')]: {
      minWidth: '100px',
    },
  },
  imageSelected: {
    marginBottom: '10px',
    maxWidth: '132px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  image: {
    marginBottom: '10px',
    maxWidth: '47px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      maxWidth: '92px',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    fontSize: '20px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: theme.colors.textSelectedPlanet,
  },
})

const ImagePlanet = ({ classes, src, alt, label, selected }) => (
  <div className={classes.container}>
    <Image
      src={src}
      alt={alt || ''}
      className={selected ? classes.imageSelected : classes.image}
    />
    {selected && (
      <Typography className={classes.typography}>{label}</Typography>
    )}
  </div>
)

export default withStyles(styles)(ImagePlanet)
