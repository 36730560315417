import React, { memo } from 'react'
import { Container, HiddenCheckbox, StyledCheckbox, Icon } from './styles'

interface IProps {
  checked: boolean
  disabled?: boolean
  onClick: () => void
}

export const Checkbox: React.FC<IProps> = memo(
  ({ checked, disabled = false, onClick, ...props }) => (
    <Container onClick={onClick} {...props}>
      <HiddenCheckbox checked={checked} disabled={disabled} onClick={onClick} onChange={onClick} />
      <StyledCheckbox checked={checked} disabled={disabled}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </Container>
  ),
)
