import React, { useEffect } from 'react'
import { useCroods } from 'croods'
import { Container } from '@material-ui/core'

import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'
import TitleBar from 'Educators/ManageSchool/TitleBar'

import EducatorsList from 'Educators/ManageSchool/ManageEducators/EducatorsList'
import Card from 'components/Card'
import { useSchools } from 'Educators/SchoolsContext'

import ClassroomFilter from 'Educators/ManageSchool/Classroom/ClassroomFilter'
import ManageStudents from 'Educators/ManageSchool/ManageStudents'
import AccessRequests from 'Educators/ManageSchool/AccessRequests'

import type { User } from 'types'
import { featureEnabled } from 'featureFlags'
import { navigate } from '@reach/router'

type Props = { currentUser: User }
const ManageSchool = ({ currentUser }: Props) => {
  const { school, schoolId } = useSchools()

  useEffect(() => {
    if (featureEnabled(currentUser, 'manage_tab_v2')) {
      // get resourceType query param
      const urlParams = new URLSearchParams(window.location.search)
      const resourceType = urlParams.get('resourceType')
      const url = `/v2/schools/${schoolId}/manage${!!resourceType ? '/' + resourceType : ''}`
      navigate(url)
    }
  }, [schoolId, currentUser])

  const [{ list: classrooms, fetchingList: loading }, { fetch: fetchClassroom }] = useCroods({
    cache: window.history.state?.disableClassroomCache ? false : true,
    stateId: schoolId,
    name: 'classrooms',
    customPath: `/schools/${schoolId}/classrooms`,
  })
  useEffect(() => {
    schoolId && fetchClassroom({})()
  }, [schoolId]) // eslint-disable-line

  return (
    <DashboardScreen currentUser={currentUser}>
      <Container className="flex flex-col">
        <TitleBar />
        <Card title={school?.name} isAccentColor>
          <ClassroomFilter classrooms={classrooms} loading={loading} />
          <AccessRequests />
          <Card title="EDUCATORS">
            {/* @ts-ignore */}
            <EducatorsList currentUser={currentUser} />
          </Card>
          <Card title="STUDENTS">
            <ManageStudents uri={undefined} currentUser={currentUser} schoolId={schoolId} />
          </Card>
        </Card>
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(ManageSchool)
