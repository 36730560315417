import React from 'react'
import { Grid, DialogContentText } from '@material-ui/core'

import { Field } from 'redux-form'
import { required } from 'redux-form-validators'

import { EmailAddress } from 'Educators/students/form/Fields'

import NonBoldSelect from './NonBoldSelect'
import UserCard from '../UserCard'

const roles = [
  { label: 'Viewer', value: 'viewer' },
  { label: 'Admin', value: 'admin' },
]

const selfRoles = [...roles, { label: 'Remove', value: 'remove' }]

const footerText = (role) => {
  if (role === 'admin') {
    return 'Admins can view and manage students.'
  }
  return "Viewers will see students on their dashboard and will have access to the student's check-in history."
}

const getSelfRoleFooter = (role) => {
  if (role === 'admin') {
    return 'Keep me as an admin, so I can manage and view this student.'
  }
  if (role === 'viewer') {
    return 'Remove my admin permissions, but keep me as a viewer.'
  }
  return 'Remove me from this student’s account entirely.'
}

const SharingBaseForm = ({ role, selfRole, student, currentUser }) => {
  const footer = footerText(role)
  const selfRoleFooter = getSelfRoleFooter(selfRole)
  const studentBelongsToSchool = Boolean(student.schoolId)
  return (
    <Grid container>
      <Grid item xs={studentBelongsToSchool ? 12 : 9}>
        <EmailAddress label="Invite by email" />
      </Grid>
      {!studentBelongsToSchool && (
        <Grid item xs={3}>
          <Field
            component={NonBoldSelect}
            name="role"
            label={<span>&nbsp;</span>}
            options={roles}
            validate={[required()]}
          />
        </Grid>
      )}
      {footer && (
        <Grid item xs={12}>
          <DialogContentText className="my-5">{footer}</DialogContentText>
        </Grid>
      )}
      {role === 'admin' && (
        <Grid container>
          <Grid item xs={12}>
            <DialogContentText className="my-5 text-base font-bold text-gray-800">
              Would you like to update your own permissions for this student?
            </DialogContentText>
          </Grid>
          <Grid item xs={9}>
            <UserCard self user={{ ...currentUser }} />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={NonBoldSelect}
              name="selfRole"
              label={<span>&nbsp;</span>}
              options={selfRoles}
              validate={[required()]}
            />
          </Grid>
          {selfRoleFooter && (
            <Grid item xs={12}>
              <DialogContentText className="my-5">{selfRoleFooter}</DialogContentText>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  )
}

export default SharingBaseForm
