import React from 'react'
import styled from 'styled-components'
import Screen from 'checkIn/components/teen/Screen'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'
import FUEL from 'fuel'
import { getLocalStorageCheckIn } from 'students/helper'

interface Energy {
  height: string
  filter: string
}

const FuelContainer = styled.div`
  display: flex;
  height: 60vh;
  align-items: flex-end;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  text-align: center;
  background-color: rgba(23, 23, 23, 0.5);
  gap: 0.5rem;
  padding: 1.5rem 1rem;
  border-radius: 1.5rem;
  max-width: 360px;
  width: 60%;
  @media screen and (min-width: 600px) {
    width: 100%;
  }
`

const ButtonContainer = styled.div<{ energy: Energy; color: string }>`
  background-image: ${({ color }) => `linear-gradient(to right, ${color}, #000000 100%)`};
  height: ${({ energy }) => energy.height};
  font-size: 1.25rem;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  @media screen and (min-width: 600px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Button = styled.button<{ energy: Energy }>`
  width: 100%;
  border-radius: 20px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: none;
  color: #e5e5e5;
  text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.2);
  background-image: ${({ energy }) =>
    `linear-gradient(to right, ${energy.filter}, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.9) 100%)`};
  background-size: 200% auto;
  transition: all ease 0.3s;
  :hover {
    background-position: right center;
    text-decoration: none;
  }
  @media screen and (min-width: 600px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const listFuelLink = Object.values(FUEL)

type Props = {
  selectFuelFunction: (value: string) => void
}
const Teen = ({ selectFuelFunction }: Props) => {
  const energy = {
    red: { height: '14%', filter: 'rgba(0, 0, 0, 0.9)' },
    blue: { height: '18%', filter: 'rgba(0, 0, 0, 0.5)' },
    yellow: { height: '28%', filter: 'rgba(255, 255, 255, 0.2)' },
    green: { height: '40%', filter: 'rgba(255, 255, 255, 0.7)' },
  }
  const color = getLocalStorageCheckIn('color') || '#03b2ad'

  return (
    <Screen
      contentWrapperClassName="h-screen"
      label="Pick the color that matches your energy level"
      title="How much energy do you have?"
    >
      <FuelContainer>
        {listFuelLink.map(({ value, label }) => (
          <ButtonContainer key={value} energy={energy[value]} color={color}>
            <Button key={value} energy={energy[value]} onClick={() => selectFuelFunction(value)}>
              {label}
            </Button>
          </ButtonContainer>
        ))}
      </FuelContainer>

      <BackAndNextButtons showBackButton />
    </Screen>
  )
}

export default Teen
