import styled from 'styled-components'

export const Title = styled.p`
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 57.6px */
  letter-spacing: 0.5px;
  max-width: 1324px;
  margin-bottom: 48px;
  @media screen and (max-width: 600px) {
    font-size: 28px;
    margin-bottom: 24px;
  }
`

export const AnswerHintTop = styled.div`
  margin-bottom: 12px;
`

export const AnswerHintBottom = styled.div`
  margin-top: 12px;
`

export const AnswerHint = styled.p<{ color: string }>`
  color: ${({ color }) => color};
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.5px;
`

export const ColorsContainer = styled.div`
  max-width: 408px;
  margin: 2rem 0;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem 6rem;
  border-radius: 16px;
  background: var(--gray-light, #cbcace);
  @media screen and (max-width: 600px) {
    gap: 1rem;
  }
`

export const TeenColorsContainer = styled.div`
  max-width: 408px;
  margin: 2rem 0;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem 6rem;
  border-radius: 16px;
  // background: var(--gray-light, #cbcace);
  @media screen and (max-width: 600px) {
    gap: 1rem;
  }
`

export const ColorButton = styled.button<{
  background: string
  number: number
  selected?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  min-height: 80px;
  background: ${({ background }) => background};
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, ${({ number }) => (5 - number) * 0.15}) 0%,
    rgba(0, 0, 0, ${({ number }) => (5 - number) * 0.15}) 100%
  );
  border-radius: 8px;
  transition: all ease 0.25s;
  :hover {
    filter: brightness(1.2);
  }
  @media screen and (max-width: 600px) {
    min-height: 4rem;
    min-width: 10rem;
  }
  outline: ${({ background, selected }) => `${selected ? '3' : '0'}px solid ${background}`};
  outline-offset: 2px;
`

export const TeenColorButton = styled.button<{
  background: string
  number: number
  selected?: boolean
  unselected?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  min-height: 80px;
  background: ${({ background, unselected }) => (unselected ? 'none' : background)};
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, ${({ number }) => (5 - number) * 0.15}) 0%,
    rgba(0, 0, 0, ${({ number }) => (5 - number) * 0.15}) 100%
  );
  border: ${({ unselected }) => (unselected ? '1px solid #fff' : '0px solid #fff')};
  border-radius: 8px;
  transition: all ease 0.25s;
  :hover {
    filter: brightness(1.2);
  }
  @media screen and (max-width: 600px) {
    min-height: 4rem;
    min-width: 10rem;
  }
`
