import React from 'react'
import { Container, Button } from '@material-ui/core'

import Breadcrumbs from 'components/Breadcrumbs'
import Card from 'components/Card'
import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'
import { useFlash } from 'seasoned-flash'
import { useGlobal } from 'useGlobal'

import StudentsList from 'Educators/ManageSchool/CreateClassrooms/StepStudents/StudentsList'
import { navigate } from '@reach/router'
import { useSchools } from 'Educators/SchoolsContext'
import { filter, includes, isEmpty } from 'lodash'
import { useCroods } from 'croods'
import LinkInvite from 'Educators/ManageSchool/Classroom/LinkInvite'

const AddStudents = ({ currentUser, location, classroomId }) => {
  const [{ selectedStudents }] = useGlobal()
  const { success, error } = useFlash()
  const { school, schoolId } = useSchools()

  const urlRedirect = `/manage-school/classrooms/${classroomId}`

  const [{ list: listStudents, fetchingList: fetchingStudents }] = useCroods({
    name: 'students',
    stateId: `school-${schoolId}`,
    fetchOnMount: true,
    customPath: `/schools/${schoolId}/students?scope=all`,
  })

  const [{ saving }, { save }] = useCroods({
    stateId: `class-${classroomId}-saving`,
    name: 'students',
    customPath: `/classrooms/${classroomId}/add_students`,
    afterSuccess() {
      success('Student added successfully')
      navigate(urlRedirect)
    },
    afterFailure() {
      error("Failed, the students weren't added")
    },
  })

  const classroom = location?.state?.classroom

  React.useEffect(() => {
    if (!classroom) navigate(urlRedirect)
  }, [classroomId]) // eslint-disable-line

  const filteredStudents = React.useMemo(
    () => filter(listStudents, (item) => !includes(item?.classroomIds, parseInt(classroomId, 10))),
    [classroomId, listStudents],
  )

  return (
    <DashboardScreen currentUser={currentUser}>
      <Container className="flex flex-col">
        <Breadcrumbs
          paths={[
            {
              children: `Manage ${school?.name}`,
              to: urlRedirect,
            },
          ]}
        >
          Add Students to Classroom {classroom?.name}
        </Breadcrumbs>
        <Card title="STUDENTS">
          {isEmpty(filteredStudents) ? (
            <LinkInvite
              text="Can't find an student?"
              href="/manage-school"
              alt="invite students"
              textLink="Invite more students to my school!"
            />
          ) : (
            <StudentsList loading={fetchingStudents} students={filteredStudents} />
          )}
        </Card>
        <div className="flex justify-around py-6">
          <Button color="primary" onClick={() => navigate(urlRedirect)}>
            Cancel
          </Button>
          <Button
            disabled={saving || selectedStudents.length === 0}
            variant="contained"
            color="primary"
            onClick={() => {
              save()({ studentsIds: selectedStudents })
            }}
          >
            ADD TO CLASSROOM
          </Button>
        </div>
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(AddStudents)
