import { CadetImage, Container } from 'auth/signUpFlow/SignUpStudent/style'
import { Title } from 'auth/signUpFlow/components/styled'
import HelperCadet from 'Educators/ManageSchool/NewSchoolYear/Components/helperCadet';
import { Layout } from 'auth/signUpFlow/components/Layout';
import validateUserFields from 'auth/validateUserFields'
import PageTitle from 'components/PageTitle';
import { useCroods } from 'croods'
import React from 'react'
import { useScreenSize } from 'screenSize';
// @ts-ignore
import { Helmet } from 'react-helmet'
import Error from 'components/form/Error'

function submitZendeskForm(token:string) {
  const queryString = new URLSearchParams(window.location.search)
  const returnTo = queryString.get('return_to')
  const formUrl = `https://closegaphelp.zendesk.com/access/jwt?return_to=${returnTo ? encodeURIComponent(returnTo) : ''}`
  const form = document.createElement('form')
  form.method = 'POST';
  form.action = formUrl

  const hiddenField = document.createElement('input');
  hiddenField.type = 'hidden';
  hiddenField.name = 'jwt';
  hiddenField.value = token;
  form.appendChild(hiddenField);

  document.body.appendChild(form);
  form.submit();
}

const ZendeskSignIn = () => {
  const { isMobile } = useScreenSize()
  const [{listError}] = useCroods({
    name: 'todos',
    path:"/zendesk/generate_jwt",
    fetchOnMount: true,
    afterSuccess: ({ data }) => {
      submitZendeskForm(data.token)
    }
  })
  const title = listError ? 'Error Authenticating With Zendesk' : 'Authenticating With Zendesk...'
  return (
    <Layout>
      {isMobile ? null : <CadetImage />}
      <PageTitle text="Zendesk Sign-in &mdash; Closegap" />
      <Helmet>
        <meta
          name="description"
          content="Welcome back! Closegap is a child-facing emotional wellness platform that captures how kids feel and helps educators respond. We're so glad you're here!"
        />
      </Helmet>
      <Container>
        <Title style={{ margin: '3rem 0' }}>{title}</Title>
        {listError ? <Error>{listError}</Error> : null}
      </Container> 
      <HelperCadet />
    </Layout>
  )
} 
// export default ZendeskSignIn
export default validateUserFields(ZendeskSignIn)