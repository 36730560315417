import React from 'react'
import EmailIcon from '@material-ui/icons/Email'
import styled from 'styled-components'
import { getLocalStorageCheckIn } from 'students/helper'

type Props = {
  className?: string
  onClick: () => void
}

const IconMessageButton = styled.button`
  position: fixed;
  padding: 0.25rem;
  border-radius: 9999px;
  border-width: 2px;
  border-color: ${({ color }) => color};
  background: black;
  color: ${({ color }) => color};
  :hover {
    background: ${({ color }) => color};
    border-color: black;
    color: black;
  }
`

export const IconMessage = ({ className = '', onClick }: Props) => {
  const color = getLocalStorageCheckIn('color') || '#00ddc1'
  return (
    <IconMessageButton
      data-testid="icon_message"
      color={color}
      onClick={onClick}
      className={className}
    >
      <EmailIcon fontSize="small" />
    </IconMessageButton>
  )
}
