import { useCheckIn } from 'checkIn/CheckInContext'
import React, { useEffect } from 'react'
import { RouteComponent, Student } from 'types'
import Loading from 'components/Loading'
import { setLocalStorageCheckIn } from 'students/helper'

type Props = {}
const Root: RouteComponent<Props> = ({ children }) => {
  const { student, handleSetStudent } = useCheckIn()

  useEffect(() => {
    setLocalStorageCheckIn('color', '#04C8C8')
    document.body.style.minHeight = '100vh'
    document.body.style.backgroundImage = `url('/images/moods/nineToTwelve/neutral.png')`
    // @ts-ignore
    document.body.style.backdropFilter = 'blur(100px)'
    document.body.style.backgroundSize = 'cover'
    document.body.style.backgroundRepeat = 'no-repeat'
    document.body.style.backgroundPosition = 'center'

    const anonymousStudent: Student = {
      id: 0,
      birthdate: '',
      checkInReminderSentAt: null,
      classroomIds: [],
      educationPlan504: false,
      email: '',
      englishLanguageLearner: false,
      ethnicity: null,
      firstName: 'Anonymous',
      freeReducedPriceMeals: false,
      gender: 'M',
      grade: '8',
      individualizedEducationProgram: false,
      lastName: 'Student',
      livingSituation: null,
      middleName: null,
      needsFollowUp: false,
      ownerFullName: null,
      ownerId: 0,
      ownerRole: undefined,
      schoolName: '',
      studentType: 'kid',
    }
    handleSetStudent(anonymousStudent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!student) return <Loading />
  return <div>{children}</div>
}

export default Root
