import React from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import FollowUpAction from 'components/FollowUpAction'

import Time from 'Educators/students/list/emotions/Time'
import StudentResponse from 'Educators/students/list/emotions/StudentResponse'
import StudentNeeds from 'Educators/students/list/emotions/StudentNeeds'
import VerticalLine from 'Educators/students/list/emotions/VerticalLine'
import { Comments } from 'Educators/students/list/Comments'
import { listStudentNeeds } from 'Educators/students/helper'

const styles = ({ spacing }) => ({
  root: {
    flexGrow: 1,
  },
  studentNeeds: {
    marginBottom: spacing(1),
    paddingLeft: spacing(5),
  },
  time: {
    minWidth: 70,
    width: '100%',
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },
})

const Emotions = withStyles(styles)(
  ({ classes, item: checkIn, firstItem, currentUser, student }) => {
    const needs = listStudentNeeds({ checkIn, currentUser })
    return (
      <div className={classes.root}>
        <Grid container spacing={1}>
          <Grid container item xs={12}>
            <Grid item xs={2}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  maxWidth: 128,
                }}
              >
                <Time
                  className={classes.time}
                  createdAt={checkIn.createdAt}
                  style={{ flexWrap: 'nowrap' }}
                />
                <VerticalLine helpScore={checkIn.helpScore} />
              </div>
            </Grid>
            <Grid item xs={9}>
              <StudentResponse
                feeling={checkIn.feeling}
                secondaryEmotions={checkIn.secondaryEmotions}
                bodySensations={checkIn.bodySensations}
              />
            </Grid>
            <Grid item xs={1}>
              <FollowUpAction item={checkIn} currentUser={currentUser} />
            </Grid>
          </Grid>
          {needs?.length > 0 && (
            <Grid container item xs={12}>
              <Grid item xs={3} sm={2} />
              <Grid item xs={8} sm={9}>
                <StudentNeeds needs={needs} className={classes.studentNeeds} />
              </Grid>
            </Grid>
          )}
          <Grid container item xs={12}>
            <Grid item xs={2} />
            <Grid item xs={10} style={{ marginTop: 8 }}>
              <Comments
                initialStateOpen={firstItem}
                student={student}
                currentUser={currentUser}
                checkIn={checkIn}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  },
)

export default Emotions
