import React from 'react'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import {
  Title,
  TeenColorsContainer,
  AnswerHint,
  AnswerHintBottom,
  AnswerHintTop,
  TeenColorButton,
} from '../components'
import { getLocalStorageCheckIn, setLocalStorageCheckIn } from 'students/helper'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const Teen = ({ nextFunction }: Props) => {
  const [selectedOption, setSelectedOption] = React.useState<number | null>(null)
  const color = getLocalStorageCheckIn('color') || '#03b2ad'
  // const colors = ['#00ddc1', '#8537E3', '#FBDE3F', '#80E217', '#FF5576', '#D31919']

  const onClick = (number: number) => {
    setLocalStorageCheckIn('impactQ2', number)
    setSelectedOption(number)
  }

  return (
    <CheckInContainer bottom bg="">
      <Title>On a scale from 1 to 5, how easy is it for you to put your feelings into words?</Title>

      <TeenColorsContainer>
        <AnswerHintTop>
          <AnswerHint color="#FFF">
            Really easy! I can label my feelings and express them when I need to.
          </AnswerHint>
        </AnswerHintTop>
        {[1, 2, 3, 4, 5].reverse().map((number: number) => (
          <TeenColorButton
            key={number}
            background={color}
            number={number}
            selected={selectedOption === number}
            unselected={!!selectedOption && selectedOption !== number}
            onClick={() => onClick(number)}
          >
            {number}
          </TeenColorButton>
        ))}
        <AnswerHintBottom>
          <AnswerHint color="#FFF">
            Really hard. I don't know how to put my feelings into words.
          </AnswerHint>
        </AnswerHintBottom>
      </TeenColorsContainer>
      {selectedOption ? (
        <BackAndNextButtons nextButtonOnClick={() => nextFunction(window.location.search)} />
      ) : null}
    </CheckInContainer>
  )
}

export default Teen
