import React, { useState } from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'

type Props = {
  nextFunction: (intensity: number) => void
  feeling: string
}

export default ({ nextFunction, feeling }: Props) => {
  const [selectedIntensity, setSelectedIntensity] = useState<number>()
  return (
    <Screen
      title={`On a scale from 1 (not at all) to 5 (so much), how ${feeling} are you feeling?`}
      currentStep={2}
      showBackButton={true}
    >
      <div className="flex w-full max-w-5xl gap-4 mb-8  bg-white/10 rounded-full py-2 px-6">
        <div className="w-full bg-[url('/images/nineToTwelve/emotion-intensity-bar-inactive.png')] bg-clip-padding bg-repeat-x bg-left">
          <div
            data-intensity={selectedIntensity}
            className={`
            transition-all duration-300 w-0 data-[intensity='1']:w-[20px] 
            data-[intensity='2']:w-1/4 data-[intensity='3']:w-1/2 data-[intensity='4']:w-3/4 
            data-[intensity='5']:w-full  
            h-10 bg-[url('/images/nineToTwelve/emotion-intensity-bar.png')] bg-clip-content bg-left
           `}
          ></div>
        </div>
      </div>
      <div className="flex flex-row justify-items-center justify-between w-full max-w-5xl gap-4 md:gap-14">
        {[...Array(5)].map((_, i) => {
          const intensity = i + 1
          return (
            <button
              key={intensity}
              data-selected={!!selectedIntensity && intensity <= selectedIntensity}
              className={`text-white h-14 min-w-14 flex items-center  justify-center border-2 font-bold tracking-button uppercase text-xl leading-5  bg-cover bg-no-repeat bg-center rounded-full shadow-sm hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0 data-[selected=true]:bg-gray-100 data-[selected=true]:text-zinc-600 data-[selected=true]:[text-shadow:none]`}
              onClick={() => setSelectedIntensity(intensity)}
            >
              {intensity}
            </button>
          )
        })}
      </div>
      <NextButton
        disabled={!selectedIntensity}
        handleNextFunction={() => nextFunction(selectedIntensity || 1)}
        additionalClasses="mt-20"
      />
    </Screen>
  )
}
