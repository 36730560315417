import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import {
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import clsx from 'clsx'

const styles = (theme) => ({
  root: {
    textAlign: 'center',
  },
  paper: {
    borderRadius: 12,
    textAlign: 'center',
    padding: theme.spacing(5),
    maxWidth: 640,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
      margin: theme.spacing(1),
    },
  },
  title: {
    fontSize: '48px',
    letterSpacing: '0.39px',
    color: theme.colors.mardiGras,
    padding: 0,
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px',
    },
  },
  content: {
    margin: '0 auto',
    padding: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(5),
  },
  alert: {
    fontSize: 24,
  },
  actions: {
    justifyContent: 'center',
  },
  removeScroll: {
    overflowY: 'initial',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
})

const StyledDialog = ({
  classes,
  children,
  open,
  onClose,
  title,
  subtitle,
  description,
  propsTitle,
  disableBackdropClick,
  ...props
}) => (
  <Dialog
    onClose={(ev, reason) => {
      if (disableBackdropClick && reason === 'backdropClick') return
      onClose(ev, reason)
    }}
    open={open}
    className={classes.root}
    PaperProps={{
      className: clsx(classes.paper, classes.removeScroll),
    }}
    {...props}
  >
    {title && (
      <DialogTitle className={classes.title} disableTypography {...propsTitle}>
        {title}
        <IconButton
          aria-label="close-icon"
          className={classes.closeButton}
          onClick={onClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
    )}
    <DialogContent className={clsx(classes.content, classes.removeScroll)}>
      {subtitle && (
        <DialogContentText className={classes.text}>
          {subtitle}
        </DialogContentText>
      )}
      {description && (
        <Typography variant="subtitle2" color="textSecondary">
          {description}
        </Typography>
      )}
      {children}
    </DialogContent>
  </Dialog>
)

export default withStyles(styles)(StyledDialog)
