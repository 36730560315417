import sortBy from 'lodash/sortBy'
import isEmpty from 'lodash/isEmpty'
import reduce from 'lodash/reduce'

import { applyFilters } from 'Educators/students/helper'
import type { CheckIn, Student } from 'types'

export type GlobalFilter = {
  classroom?: number
  energyLevel?: string
  grade?: string
  needs?: string
  studentName?: string
  teacher?: string
}

export const filterStudents = (
  students: Student[],
  filters: GlobalFilter,
  fields = Object.keys(filters || {}),
): Student[] => sortBy(students, ['lastName', 'firstName']).filter(applyFilters(fields, filters))

export const joinStudentsAndCheckins = (checkIns: CheckIn[], students: Student[]) => {
  if (isEmpty(checkIns) && isEmpty(students)) return []

  const studentsPerId: { [id: string]: Student } = reduce(
    students,
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {},
  )

  const studentsWithCheckIn: number[] = []

  const leftJoinCheckInsStudents = checkIns.map((ch) => {
    const student = { ...studentsPerId[ch.studentId] }
    studentsWithCheckIn.push(ch.studentId)
    return {
      ...student,
      latestCheckIn: ch,
    }
  })

  return [
    ...leftJoinCheckInsStudents,
    ...reduce(
      students,
      (acc: any[], student: Student) => {
        if (studentsWithCheckIn.includes(student.id)) return acc
        return [...acc, { ...student, latestCheckIn: null }]
      },
      [],
    ),
  ]
}
