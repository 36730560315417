import React from 'react'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'

import { setLocalStorageCheckIn } from 'students/helper'
import {
  Title,
  ColorsContainer,
  AnswerHint,
  AnswerHintBottom,
  AnswerHintTop,
  ColorButton,
} from '../components'

type Props = {
  nextFunction: (impactMeasurement?: boolean) => Promise<void>
}

const Kid = ({ nextFunction }: Props) => {
  const [selectedOption, setselectedOption] = React.useState<number | null>(null)
  const color = '#6750A3'

  const onClick = (number: number) => {
    setLocalStorageCheckIn('impactQ4', number)
    setselectedOption(number)
  }

  return (
    <CheckInContainer bottom bg="">
      <Title>On a scale from 1 to 5, do you understand your moods and feelings?</Title>

      <ColorsContainer>
        <AnswerHintTop>
          <AnswerHint color="#6750A3">Yes, I understand them almost all of the time.</AnswerHint>
        </AnswerHintTop>

        {[1, 2, 3, 4, 5].reverse().map((number: number) => (
          <ColorButton
            key={number}
            background={color}
            number={number}
            selected={selectedOption === number}
            onClick={() => onClick(number)}
          >
            {number}
          </ColorButton>
        ))}
        <AnswerHintBottom>
          <AnswerHint color="#201637">
            No, it's really hard for me to understand my moods and feelings.
          </AnswerHint>
        </AnswerHintBottom>
      </ColorsContainer>
      {selectedOption ? (
        <div className="absolute bottom-0 w-full">
          <BackAndNextButtons nextButtonText="Next" nextButtonOnClick={() => nextFunction(true)} />
        </div>
      ) : null}
    </CheckInContainer>
  )
}

export default Kid
