import React from 'react'
import { Router } from '@reach/router'

import CheckIn from 'checkIn'
import Welcome from 'checkIn/steps/Welcome'
import Start from 'checkIn/steps/Start'
import Emotion from 'checkIn/steps/Emotion'
import EmotionConfirmation from 'checkIn/steps/EmotionConfirmation'
import SecondaryEmotions from 'checkIn/steps/SecondaryEmotions'
import BodySensations from 'checkIn/steps/BodySensations'
import EnergyLevelIntro from 'checkIn/steps/EnergyLevelIntro'
import EnergyLevel from 'checkIn/steps/EnergyLevel'
import EnergyLevelConfirmation from 'checkIn/steps/EnergyLevelConfirmation'
import ComfortableResult from 'checkIn/steps/ComfortableResult'
import UncomfortableResult from 'checkIn/steps/UncomfortableResult'
import Questions from 'checkIn/steps/Questions'
import ShareAnythingElse from 'checkIn/steps/ShareAnythingElse'
import { TalkToAdult } from 'checkIn/steps/TalkToAdult'
import PreferredContact from 'checkIn/steps/PreferredContact'
import TalkToAnAdultResponse from 'checkIn/steps/TalkToAnAdultResponse'
import ChooseActivity from 'checkIn/steps/ChooseActivity'
import Activities from 'checkIn/steps/Activities'
import HowAreYouFeelingNow from 'checkIn/steps/HowAreYouFeelingNow'
import ThanksForExploring from 'checkIn/steps/ThanksForExploring'
import VacationScreen from 'students/VacationScreen'
import { TeenVacationScreen } from 'students/TeenVacationScreen'
import EmotionIntensity from 'checkIn/steps/EmotionIntensity'
import SelectColor from 'checkIn/steps/SelectColor'
import ImpactQ1 from 'checkIn/steps/Impact/Q1'
import ImpactQ2 from 'checkIn/steps/Impact/Q2'
import ImpactQ3 from 'checkIn/steps/Impact/Q3'
import ImpactQ4 from 'checkIn/steps/Impact/Q4'
import ImpactQ5 from 'checkIn/steps/Impact/Q5'
import ImpactQ6 from 'checkIn/steps/Impact/Q6'

const CheckInRouter = (props: any) => (
  <Router>
    <VacationScreen {...props} path="/on-vacation" />
    <TeenVacationScreen {...props} path="/check-in/:studentId/on-vacation" />
    <CheckIn path="/check-in/:studentId">
      <Welcome path="/welcome" />
      <SelectColor path="/select-color" />
      <Start path="/" />
      <Emotion path="/choose-emotion" />
      <EmotionConfirmation path="/confirm-emotion" />
      <EmotionIntensity path="/emotion-intensity" />
      <SecondaryEmotions path="/secondary-emotions" />
      <BodySensations path="/body-sensations" />
      <EnergyLevelIntro path="/energy-level-intro" />
      <EnergyLevel path="/energy-level" />
      <EnergyLevelConfirmation path="/energy-level-confirmation" />
      <ComfortableResult path="/comfortable-result" />
      <UncomfortableResult path="/uncomfortable-result" />
      <Questions path="/questions/hasEaten" />
      <Questions path="/questions/hasSlept" />
      <Questions path="/questions/hurtOrSick" />
      <Questions path="/questions/okAtHome" />
      <Questions path="/questions/bulliedAtSchool" />
      <Questions path="/questions/feelingStressed" />
      <Questions path="/questions/feelingLonely" />
      <Questions path="/questions/overwhelmedByWorkload" />
      <Questions path="/questions/okInSocialLife" />
      <Questions path="/questions/feelingTired" />
      <Questions path="/questions/feelingWorthless" />
      <ShareAnythingElse path="/share-anything-else" />
      <TalkToAdult path="/talk-to-an-adult" />
      <PreferredContact path="/preferred-contact" />
      <TalkToAnAdultResponse path="/talk-to-an-adult-response" />
      <ChooseActivity path="/choose-activity" />
      <Activities path="/activities/breathing" />
      <Activities path="/activities/shake-it-out" />
      <Activities path="/activities/power-pose" />
      <Activities path="/activities/let-it-go" />
      <Activities path="/activities/lets-make-a-list" />
      <Activities path="/activities/journaling" />
      <Activities path="/activities/the-ok-inventory" />
      <Activities path="/activities/safe-space" />
      <HowAreYouFeelingNow path="/how-are-you-feeling-now" />
      <ThanksForExploring path="/thanks-for-exploring" />
      <ImpactQ1 path="/impactQ1" />
      <ImpactQ2 path="/impactQ2" />
      <ImpactQ3 path="/impactQ3" />
      <ImpactQ4 path="/impactQ4" />
      <ImpactQ5 path="/impactQ5" />
      <ImpactQ6 path="/impactQ6" />
    </CheckIn>
  </Router>
)

export default CheckInRouter
