import { default as fiveToEightPlanetList } from 'checkIn/constants/fiveToEight/planetList'
import { default as threeToFourPlanetList } from 'checkIn/constants/threeToFour/planetList'
import { default as nineToTwelvePlanetList } from 'checkIn/constants/nineToTwelve/planetList'
import { default as nonReaderPlanetListV2} from 'checkIn/constants/nonReader/planetListV2'
import { allNineToTwelveNeeds } from 'checkIn/nineToTwelve/Questions/NineToTwelve'
import { FUELPK2, FUELV2 } from 'fuel'

const baseEmotions = [
  { label: 'Happy', value: 'happy' },
  { label: 'Angry', value: 'angry' },
  { label: 'Sad', value: 'sad' },
  { label: 'Worried', value: 'worried' },
]

const kidEmotions = [
  { label: 'Frustrated', value: 'frustrated' },
  { label: 'Silly', value: 'silly' },
  { label: 'Calm', value: 'calm' },
  { label: 'Tired', value: 'tired' },
]

const nonReaderEmotions = nonReaderPlanetListV2.map(({ feeling, label }) => {
  return {
    value: feeling,
    label,
  }
})

const threeToFourEmotions = threeToFourPlanetList.map(({ feeling, label }) => {
  return {
    value: feeling,
    label,
  }
})

const fiveToEightEmotions = fiveToEightPlanetList.map(({ feeling, label }) => {
  return {
    value: feeling,
    label,
  }
})

const nineToTwelveEmotions = nineToTwelvePlanetList.map(({ feeling, label }) => {
  return {
    value: feeling,
    label,
  }
})

export const emotionOptionsV3 = {
  'non-reader': nonReaderEmotions,
  '3-4': threeToFourEmotions,
  '5-8': fiveToEightEmotions,
  '9-12': nineToTwelveEmotions,
  all: nineToTwelveEmotions,
}

export const emotionOptionsV2 = {
  'non-reader': [...baseEmotions, ...kidEmotions],
  '3-4': threeToFourEmotions,
  '5-8': fiveToEightEmotions,
  '9-12': nineToTwelveEmotions,
  all: nineToTwelveEmotions,
}

export const emotionOptions = {
  'non-reader': [...baseEmotions, ...kidEmotions],
  kid: threeToFourEmotions,
  teen: fiveToEightEmotions,
  '3-4': threeToFourEmotions,
  '5-8': fiveToEightEmotions,
  '9-12': nineToTwelveEmotions,
  all: [...fiveToEightEmotions, { label: 'Worried', value: 'worried' }],
}

export const triageOptions = [
  { label: 'Good To Go', value: 'good_to_go' },
  { label: 'Could Benefit From Support', value: 'could_benefit_from_support' },
  { label: 'Urgent', value: 'urgent' },
]

export const energyLevelOptions = Object.values(FUELV2)

export const nonReaderEnergyLevelOptions = Object.values(FUELPK2)

const baseFollowUpQuestions = [
  {
    value: 'has_eaten',
    label: 'Hungry',
  },
  {
    value: 'hurt_or_sick',
    label: 'Hurt or sick',
  },
  {
    value: 'ok_at_home',
    label: 'Home trouble',
  },
  {
    value: 'bullied_at_school',
    label: 'Peer Issues',
  },
  {
    value: 'has_slept',
    label: "Didn't sleep well",
  },
]

const teenFollowUpQuestions = [
  {
    value: 'feeling_stressed',
    label: 'Stressed',
  },
  {
    value: 'feeling_lonely',
    label: 'Loneliness',
  },
  {
    value: 'overwhelmed_by_workload',
    label: 'Workload',
  },
  {
    value: 'ok_in_social_life',
    label: 'Social Life Stress',
  },
  {
    value: 'feeling_tired',
    label: 'Tired',
  },
  {
    value: 'feeling_worthless',
    label: 'Worthlessness',
  },
]

const capitalize = (text) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

const nineToTwelveFollowUpQuestions = allNineToTwelveNeeds.map((need) => {
  return {
    value: need,
    label: capitalize(need),
  }
})

const fiveToEightFollowUpQuestions = nineToTwelveFollowUpQuestions.filter(({ value }) =>
  [
    'stress',
    'loneliness',
    'low self-worth',
    'hunger',
    'hurt/sick',
    'sleep quality',
    'tired',
    'family issues',
    'social life',
    'peer issues',
    'academic pressure',
  ].includes(value),
)

const threeToFourFollowUpQuestions = nineToTwelveFollowUpQuestions.filter(({ value }) =>
  ['hunger', 'hurt/sick', 'sleep quality', 'family issues', 'peer issues'].includes(value),
)

export const followUpQuestionOptions = {
  'non-reader': [...baseFollowUpQuestions],
  kid: [...baseFollowUpQuestions],
  teen: [...baseFollowUpQuestions, ...teenFollowUpQuestions],
  all: [...baseFollowUpQuestions, ...teenFollowUpQuestions],
  '3-4': [...baseFollowUpQuestions],
  '5-8': [...baseFollowUpQuestions, ...teenFollowUpQuestions],
  '9-12': nineToTwelveFollowUpQuestions,
}

export const followUpQuestionOptionsV2 = {
  'non-reader': threeToFourFollowUpQuestions,
  kid: [...baseFollowUpQuestions],
  teen: [...baseFollowUpQuestions, ...teenFollowUpQuestions],
  all: nineToTwelveFollowUpQuestions,
  '3-4': threeToFourFollowUpQuestions,
  '5-8': fiveToEightFollowUpQuestions,
  '9-12': nineToTwelveFollowUpQuestions,
}

const allValues = [
  ...followUpQuestionOptions.kid,
  ...followUpQuestionOptions.teen,
  ...emotionOptions.kid,
  ...emotionOptions.teen,
  ...energyLevelOptions,
  ...nineToTwelveFollowUpQuestions,
].reduce((acc, current) => {
  acc[current.value] = current.label

  return acc
}, {})

const parseValueToLabel = (answer) => allValues[answer]

export default parseValueToLabel
