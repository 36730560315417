import React from 'react'
import styled from 'styled-components'
import { Link } from './Link'
import { SubTitle } from './styled'

const Styled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 150px;
  max-height: 100vh;
  background: rgba(255, 255, 255, 0.2);
  overflow-y: auto;
  overflow-x: hidden;
`

export const Menu = () => {
  const base = '/cs-dashboard'
  const links = [
    { name: 'Home', path: `${base}` },
    { name: 'Destroy students', path: `${base}/destroy-students` },
    { name: 'Review schools', path: `${base}/review-schools` },
    { name: 'Destroy school', path: `${base}/destroy-school` },
    { name: 'Move students', path: `${base}/move-students` },
    { name: 'Merge schools', path: `${base}/merge-schools` },
    { name: 'Add school owner', path: `${base}/add-school-owner` },
    { name: 'Disconnect school from district', path: `${base}/disconect-school-from-district` },
    { name: 'Update school district', path: `${base}/update-school-district` },
    { name: 'Update user email', path: `${base}/update-user-email` },
    { name: 'Create district', path: `${base}/create-district` },
    { name: 'Update educator data', path: `${base}/update-educator-data` },
  ]

  return (
    <Styled>
      <SubTitle style={{ textAlign: 'center' }}>TASKS</SubTitle>
      {links.map((l) => (
        <Link key={l.name} to={l.path}>
          {l.name}
        </Link>
      ))}
    </Styled>
  )
}
