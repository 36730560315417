import React from 'react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import clsx from 'clsx'

const ButtonAccordion = ({
  children,
  initialState = false,
  className,
  textLess = 'Less options',
  textMore = 'More options',
}) => {
  const [open, setOpen] = React.useState(initialState)

  return (
    <div className={className}>
      <button
        className="self-end button"
        onClick={() => setOpen((prevState) => setOpen(!prevState))}
        type="button"
      >
        {open ? textLess : textMore}
        {open ? (
          <ExpandLessIcon data-testid="less-icon" color="primary" />
        ) : (
          <ExpandMoreIcon data-testid="more-icon" color="primary" />
        )}
      </button>
      <main className={clsx(open ? 'block' : 'hidden')}>{children}</main>
    </div>
  )
}

export default ButtonAccordion
