import React, { useEffect } from 'react'
import {
  Container,
  makeStyles,
  Typography,
  Button,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import { useSchools } from 'Educators/SchoolsContext'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import NewClassroom from './newClassroom'
import NewClassroomWithPartialUpload from './NewClassroomWithPartialUpload'
import AddIcon from '@material-ui/icons/Add'
import { PrimaryButton, SecondaryButton } from '../../Components/buttons'
import HelperCadet from '../../Components/helperCadet'
import { featureEnabled } from 'featureFlags'

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '36px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '46px',
  },
  subTitle: {
    color: '#250032',
    textAlign: 'left',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '24px',
  },
  indexContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '920px',
    padding: 0,
    alignItems: 'center',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  classroomList: {
    marginBottom: '40px',
  },
  button: {
    width: '100%',
    borderRadius: '8px',
    padding: '16px',
  },
  paper: {
    backgroundColor: '#9E95C2',
    height: '212px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '16px',
  },
  classroomName: {
    fontSize: '32px',
    color: '#fff',
    fontWeight: 600,
  },
  userCount: {
    color: '#fff',
  },
  newClassroom: {
    display: 'flex',
    height: '212px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '24px',
  },
  newClassroomButton: {
    width: '100%',
  },
}))

const NewSchoolYearAddClassrooms = ({ nextStepFn }: { nextStepFn: Function }) => {
  const classes = useStyles()
  const { schoolId } = useSchools()
  const [index, setMode] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const [{ currentUser }] = useCurrentUser()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (skipStep: boolean) => {
    setOpen(false)
    if (skipStep) {
      nextStepFn()
    }
  }

  const [{ list: classrooms }, { fetch: fetchClassroom }] = useCroods({
    cache: false,
    stateId: schoolId,
    name: 'classrooms',
    customPath: `/schools/${schoolId}/classrooms`,
    afterSuccess: ({ data }) => {
      if (data.length === 0) {
        setMode(false)
      }
    },
  })
  useEffect(() => {
    schoolId && fetchClassroom({})()
  }, [schoolId, index]) // eslint-disable-line
  const classroomsList = classrooms.map((classroom) => (
    <Grid item xs={3} key={classroom.id}>
      <Paper className={classes.paper}>
        <Typography className={classes.classroomName}>{classroom.name}</Typography>
        <Typography className={classes.userCount}>
          Teachers: <strong>{classroom.educatorsCount}</strong>
          <br />
          Students: <strong>{classroom.studentsCount}</strong>
        </Typography>
      </Paper>
    </Grid>
  ))
  if (index) {
    return (
      <Container className={classes.indexContainer}>
        <HelperCadet customLink="https://closegaphelp.zendesk.com/hc/en-us/articles/17627052087323-How-do-I-get-a-Students-CSV-ready-to-be-uploaded-" />
        <Typography className={classes.heading}>Classrooms</Typography>

        <Grid container spacing={3} className={classes.classroomList}>
          <Grid item xs={3}>
            <button onClick={() => setMode(false)} className={classes.newClassroomButton}>
              <Paper className={classes.newClassroom}>
                <AddIcon
                  style={{ backgroundColor: '#9E95C2', color: '#fff', borderRadius: '9999px' }}
                />
                <Typography>NEW CLASSROOM</Typography>
              </Paper>
            </button>
          </Grid>
          {classroomsList}
        </Grid>
        <div className={classes.container}>
          <PrimaryButton disabled={false} onClick={() => nextStepFn()}>
            SAVE AND CONTINUE
          </PrimaryButton>
          <SecondaryButton disabled={false} onClick={() => handleClickOpen()}>
            Skip this for now.
          </SecondaryButton>
        </div>
        <Dialog
          open={open}
          onClose={() => handleClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
          {classrooms.length < 1 ? (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You haven't added any classrooms yet.
              </DialogContentText>
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
              NO, I WANT TO GO BACK.
            </Button>
            <Button
              onClick={() => handleClose(true)}
              variant="contained"
              color="secondary"
              autoFocus
            >
              I'LL DO THIS MANUALLY.
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  } else {
    return (
      <Container className={classes.container}>
        <HelperCadet customLink="https://closegaphelp.zendesk.com/hc/en-us/articles/17627052087323-How-do-I-get-a-Students-CSV-ready-to-be-uploaded-" />
        <Typography className={classes.heading}>New classroom</Typography>
        {featureEnabled(currentUser, 'partial_upload') ? (
          <NewClassroomWithPartialUpload
            returnToIndexFn={() => setMode(true)}
          ></NewClassroomWithPartialUpload>
        ) : (
          <NewClassroom returnToIndexFn={() => setMode(true)}></NewClassroom>
        )}

        <SecondaryButton disabled={false} onClick={() => handleClickOpen()}>
          Skip this for now.
        </SecondaryButton>
        <Dialog
          open={open}
          onClose={() => handleClose(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
          {classrooms.length < 1 ? (
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                You haven't added any classrooms yet.
              </DialogContentText>
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
              NO, I WANT TO GO BACK.
            </Button>
            <Button
              onClick={() => handleClose(true)}
              variant="contained"
              color="secondary"
              autoFocus
            >
              I'LL DO THIS MANUALLY.
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    )
  }
}

export default NewSchoolYearAddClassrooms
