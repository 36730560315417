import React from 'react'

type Props = {
  onClick: () => void
  selected: boolean
  children: any
  additionalClasses?: string
}

export const SelectableButton = ({ onClick, selected, children, additionalClasses }: Props) => (
  <button
    data-selected={selected}
    className={`
      ${additionalClasses} 
      transition-all text-gray-100 text-base font-bold font-['Roboto'] px-4 h-14 min-w-14 border-2 tracking-[3.20px] uppercase leading-4  
      bg-cover bg-no-repeat bg-center rounded-full shadow-sm 
      hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0
      data-[selected=true]:bg-gray-100 data-[selected=true]:text-zinc-600 data-[selected=true]:[text-shadow:none]
    `}
    onClick={onClick}
  >
    {children}
  </button>
)
