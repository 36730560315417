import React, { useEffect, useState } from 'react'
import { useCroods } from 'croods'

import Loading from 'components/Loading'
import { error, success } from 'components/Toast'
import { Button, P, SchoolCard } from './components/styled'
import { DetailedSchoolInfo } from './components/DetailedSchoolInfo'
import { MetabaseQueries } from './components/MetabaseQueries'
import { NumberInput } from './components/NumberInput'
import { AdminScreen } from './components/AdminScreen'
import { A } from './components/A'
import { Select } from './components/Select'

export const UpdateSchoolDistrict = (props: any) => {
  const [showSchool, setShowSchool] = useState<boolean>(false)
  const [schoolId, setSchoolId] = useState<number | string>('')
  const [district, setDistrict] = useState<number | string>('')
  const [open, setOpen] = useState(false)

  const [{ info: s = [], infoError: schoolError, fetchingInfo }, { fetch: getSchools }] = useCroods(
    {
      name: 'admin-school',
      customPath: 'admin/schools',
      afterSuccess: ({ data }) => {
        if (!(data.length > 0)) return null
        const d = data[0]?.district
        setDistrict(d?.id)
        setShowSchool(true)
      },
      afterFailure: () => error(schoolError || ''),
    },
  )

  const [{ list: districts = [] }, { fetch: getDistricts }] = useCroods({ name: 'district' })

  useEffect(() => {
    if (s && s[0]?.state) {
      getDistricts({ customPath: `/districts?state=${s[0].state}` })()
    }
  }, [s]) // eslint-disable-line

  const [{ saving }, { save }] = useCroods({
    name: 'admin-update-school-district',
    customPath: 'admin/update_school_district',
    afterSuccess: () => success('School district updated successfully'),
    afterFailure: ({ response }) => error(response?.data?.message),
  })

  const updateDistrict = () => save({ method: 'PUT' })({ schoolId, districtId: district })

  return (
    <AdminScreen title="Update school district" {...props}>
      <div style={{ alignSelf: 'center', maxWidth: '600px', width: '100%' }}>
        <P>
          1. Add the school id in the field below. In case you don&apos;t have the school id, use
          one of the <A name="metabase queries" onClick={() => setOpen(true)} /> to find the school
          id.
        </P>
        <NumberInput name="schoolId" label="School Id" value={schoolId} setValue={setSchoolId} />

        <br />
        <P>2. Click on the button below to pull the school information.</P>

        <Button
          variant="edit"
          disabled={schoolId === ''}
          onClick={() => getSchools({ operation: 'info' })({ ids: [schoolId] || '' })}
        >
          Show School Infomation
        </Button>

        {fetchingInfo ? <Loading /> : null}

        {showSchool && s?.length > 0 ? (
          <SchoolCard>{Boolean(s[0]) ? <DetailedSchoolInfo school={s[0]} /> : null}</SchoolCard>
        ) : null}

        {districts.length > 0 ? (
          <>
            <br />
            <P>3. Choose the new district:</P>
            <Select
              defaultValue={district}
              value={district}
              options={districts.map((d) => ({ value: d.id, label: d.name }))}
              onChange={(e) => setDistrict(e.target.value)}
            />
            <Button variant="add" onClick={updateDistrict}>
              Update district
            </Button>
            {saving ? <Loading /> : null}
          </>
        ) : null}
      </div>

      <MetabaseQueries open={open} onClose={() => setOpen(false)} />
    </AdminScreen>
  )
}
