import React, { FC } from 'react'
import styled from 'styled-components'

const ErrorMessageContainer = styled.div`
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid #F9B233;
  background: #FFE9C1;
  margin-bottom: 16px;
`

const ErrorMessageText = styled.div`
color: #4E4856;
font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 24px; /* 150% */
text-align: left;
`

const ErrorMessage: FC = ({ children }) => <ErrorMessageContainer>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M1 21.5H23L12 2.5L1 21.5ZM13 18.5H11V16.5H13V18.5ZM13 14.5H11V10.5H13V14.5Z" fill="#F9B233"/>
  </svg>  
  <ErrorMessageText>
  {children}
  </ErrorMessageText>
</ErrorMessageContainer>

export default ErrorMessage