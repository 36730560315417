import React from 'react'
import { Skeleton } from '@material-ui/lab'
import {
  Typography,
} from '@material-ui/core'
import { Fetch } from 'croods'
import { map } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import { useSchools } from 'Educators/SchoolsContext'
import Card from 'components/Card'

import Alert from 'Educators/students/insights/Alerts/List/Alert'

const List = () => {
  const { schoolId } = useSchools()

  return (
    schoolId && (
      <Fetch
        stateId={schoolId}
        name="insightsAlerts"
        customPath={`/schools/${schoolId}/alerts`}
        render={(alerts) => {
          if (isEmpty(alerts)) {
            return (
              <Typography color="textSecondary">No Alerts Yet</Typography>
            )
          }
          else return (
          <Card
            title="ACTIVE ALERTS"
            propsMain={{
              className: 'm-5 sm:m-10',
            }}
          >
            {map(alerts.reverse(), (alert) => (
              <div key={alert.id} className="mt-10">
                <Alert alert={alert} />
              </div>
            ))}
          </Card>
        )}}
        renderLoading={() => (
          <div data-testid="skeleton" style={{ marginTop: 40 }}>
            <Skeleton variant="rect" height={40} />
            <Skeleton variant="text" height={40} />
          </div>
        )}
      />
    )
  )
}

export default List
