import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;

  @media screen and (max-width: 600px) {
    padding: 0 1rem;
  }
`

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 160%;
  text-align: center;
  color: #250032;
  margin-bottom: 1rem;
`

export const Text = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 160%;
  font-weight: 400;
  color: #353535;
  padding: 0.5rem 0;
`

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3rem;
  letter-spacing: 0.07em;
  color: #fefefe;
  background: ${({ disabled }) => (disabled ? 'rgba(153,142,201,0.3)' : '#998ec9')};
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  width: 100%;
  height: 50px;
  padding: 0.5rem 0;
  :hover {
    background: ${({ disabled }) => (disabled ? 'rgba(153,142,201,0.3)' : '#7b70ab')};
  }
  transition: all 0.2s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const CancelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.3rem;
  letter-spacing: 0.07em;
  color: #fefefe;
  background: #424242;
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  width: 100%;
  height: 50px;
  padding: 0.5rem 0;
  :hover {
    background: #2e2e2e;
  }
  transition: all 0.2s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`
