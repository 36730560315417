import React from 'react'
import Screen from '../components/screen'
import { SecondaryButton } from '../components/SecondaryButton'
import type { RouteComponent } from 'types'
import { navigateWithParams } from 'utils'

const Cadet: RouteComponent = () => {
  return (
    <div className="overflow-hidden">
      <Screen showSummary={false}>
        <div className="flex flex-col gap-20 items-center">
          <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto']">
            Do you want Cadet to accompany you on this journey?
          </div>
          <div className="relative">
            <img className="w-40" src="/images/nineToTwelve/cadet-pose.png" alt="Cadet Waving" />
            <div className="absolute top-1/2 left-1/2 -translate-x-1/2 bg-[url('/images/nineToTwelve/planet.png')] bg-center bg-cover bg-no-repeat bg-origin-border w-screen h-[100vw] rounded-full -z-10"></div>
          </div>
          <div className="flex gap-4 w-full justify-center">
            <SecondaryButton
              disabled={false}
              handleNextFunction={() => {
                navigateWithParams('./select-color', { cadet: false })
              }}
            >
              no, i don't
            </SecondaryButton>
            <SecondaryButton
              handleNextFunction={() => {
                navigateWithParams('./select-color', { cadet: true })
              }}
            >
              yes, i do
            </SecondaryButton>
          </div>
        </div>
      </Screen>
    </div>
  )
}
export default Cadet
