import React from 'react'
import { planetList } from 'checkIn/constants/nineToTwelve/planetList'
import Screen from '../components/screen'

type Props = {
  nextFunction: (feeling: string) => void
}

export default ({ nextFunction }: Props) => {
  return (
    <Screen title="How are you feeling?" currentStep={1}>
      <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 justify-items-center w-full max-w-5xl gap-4 sm:gap-8 md:gap-14">
        {planetList.map(({ feeling, label, src }) => (
          <button
            key={feeling}
            style={{ backgroundImage: `url(${src})` }}
            className={`text-white font-bold tracking-button uppercase text-sm w-full p-4 bg-[url('${src}')] bg-cover bg-no-repeat bg-center rounded-full text-left shadow-sm hover:shadow [text-shadow:_0px_0px_10px_rgb(0_0_0_/_50%)] sm:[text-shadow:_0px_2px_0px_rgb(0_0_0_/_40%)]`}
            onClick={() => nextFunction(feeling)}
          >
            {label}
          </button>
        ))}
      </div>
    </Screen>
  )
}
