import styled from 'styled-components'

export const Panel = styled.div`
  margin-bottom: 3rem;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%),
    0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 0.5rem;
  background-color: #fff;
  // overflow: hidden;
  margin-top: 2.5rem;
`
