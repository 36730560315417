import React from 'react'
import { Avatar, Grid, Typography } from '@material-ui/core'

const UserCard = ({ user, self }) => (
  <Grid
    container
    alignItems="center"
    style={{ paddingLeft: 30, marginTop: 20, flexWrap: 'nowrap' }}
  >
    <Grid>
      <Avatar style={{ padding: 5 }}>
        <Typography variant="subtitle2">{`${user.firstName[0]} ${user.lastName[0]}`}</Typography>
      </Avatar>
    </Grid>
    <Grid
      data-testid="user-card-text"
      style={{
        textAlign: 'left',
        marginLeft: 20,
      }}
    >
      <Grid>
        <Typography>
          {user.firstName}
          {self ? ' (you)' : ` ${user.lastName}`}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
)

export default UserCard
