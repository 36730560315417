import styled from 'styled-components'

export const CheckInContainer = styled.div<{ bg: string; bottom?: boolean }>`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 100vh;
  background: ${({ bg }) => `url('${bg}')`};
  background-size: ${({ bottom }) => (bottom ? 'auto 40%' : 'auto 30%')};
  background-repeat: no-repeat;
  background-position: ${({ bottom }) => (Boolean(bottom) ? 'bottom left' : 'top left')};
  @media screen and (max-width: 600px) {
    height: auto;
    background-size: auto 20%;
    padding: 2rem;
    padding-top: ${({ bottom }) => (bottom ? 'none' : 'calc(10vh + 1rem)')};
  }
`
