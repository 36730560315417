import React from 'react'
import type { RouteComponent } from 'types'

import { navigateWithParams } from 'utils'
import NineToTwelve from './NineToTwelve'
import { useCheckIn } from 'checkIn/CheckInContext'

const Intro: RouteComponent = () => {
  const { student } = useCheckIn()
  if (student)
    return (
      <NineToTwelve
        student={student}
        handleNextFunction={() => navigateWithParams('./select-color')}
        isCheckInAvailable={true}
      ></NineToTwelve>
    )
  return null
}
export default Intro
