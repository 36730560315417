import React from 'react'
import Screen from 'checkIn/components/teen/Screen'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

type Props = {
  nextFunction: (params: string) => Promise<void>
  label: string
  bgImage: string
}

const Teen = ({ nextFunction, label, bgImage }: Props) => {
  return (
    <Screen
      label={label}
      title="Help me understand better by answering a few questions..."
    >
      <img className="h-[35vh] mt-28" src={bgImage} alt="Sad cadet" />
      <BackAndNextButtons
        nextButtonOnClick={() => nextFunction(window.location.search)}
      />
    </Screen>
  )
}

export default Teen
