import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(-12.5),
    minHeight: 150,
    backgroundColor: theme.palette.primary.main,
    marginLeft: theme.spacing(-5),
    marginRight: theme.spacing(-5),
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
  },
})

export default withStyles(styles)((props) => (
  <div className={clsx(props.classes.root, 'print:!hidden')}>
    {props.children}
  </div>
))
