import React from 'react'
import { Button, TableCell, TableRow, withStyles } from '@material-ui/core'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import clsx from 'clsx'

import StudentItem from 'Educators/students/list/StudentItem'

const styles = (theme) => ({
  alignTop: {
    verticalAlign: 'top',
    padding: theme.spacing(0, 0, 3, 0),
  },
  left: {
    textAlign: 'right',
    padding: theme.spacing(3, 0, 3, 0),
  },
})

const Row = withStyles(styles)(({ classes, student }) => (
  <TableRow className="row-to-print">
    <TableCell component="th" scope="row" className={classes.alignTop}>
      <StudentItem
        showAvatar={false}
        isManageStudents
        student={student}
        showAction={false}
        showAdultsInfo={false}
      />
    </TableCell>
    <TableCell className={clsx(classes.alignTop, classes.left)}>
      <Button
        target="_blank"
        href={`/dashboard/students/${student.id}/edit`}
        color="primary"
        endIcon={<OpenInNewIcon color="primary" />}
      >
        VIEW PROFILE
      </Button>
    </TableCell>
  </TableRow>
))

export default Row
