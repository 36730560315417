import React from 'react'
import { useSelector } from 'react-redux'
import get from 'lodash/get'

export const extractUserEmail = (state) => get(state, 'userEmail.email', null)

const withUserEmail = (Component) => (props) => {
  const userEmail = useSelector(extractUserEmail)

  return <Component userEmail={userEmail} {...props} />
}

export default withUserEmail
