export const Achy = 'Achy'
export const Agitated = 'Agitated'
export const Breathless = 'Breathless'
export const Bubbly = 'Bubbly'
export const Buzzy = 'Buzzy'
export const Churning = 'Churning'
export const ClearOrAwake = 'Clear or awake'
export const Clenched = 'Clenched'
export const ConfusedOrFuzzy = 'Confused or fuzzy'
export const Cool = 'Cool'
export const Dull = 'Dull'
export const Electric = 'Electric'
export const Empty = 'Empty'
export const Energized = 'Energized'
export const Floaty = 'Floaty'
export const Flushed = 'Flushed'
export const Fuzzy = 'Fuzzy'
export const HeartFlutters = 'Heart flutters'
export const HotOrBurning = 'Hot or burning'
export const JumbledUp = 'Jumbled up'
// export const Jumbly = 'Jumbly'
export const Knotted = 'Knotted'
export const Light = 'Light'
export const Neutral = 'Neutral'
export const Numb = 'Numb'
export const Prickly = 'Prickly'
export const QuietAndNeutral = 'Quiet and neutral'
export const RelaxedOrAtEase = 'Relaxed or at ease'
export const Shakey = 'Shakey'
export const Sharp = 'Sharp'
// export const Shimmery = 'Shimmery'
export const SolidOrStrong = 'Solid or strong'
export const SpaceyOrZonedOut = 'Spacey or zoned out'
export const SteadyOrStrong = 'Steady or strong'
export const StiffOrTense = 'Stiff or tense'
export const StuckOrFrozen = 'Stuck or frozen'
// export const Suffocating = 'Suffocating'
export const Sweaty = 'Sweaty'
export const Tickley = 'Tickley'
export const Tingly = 'Tingly'
// export const Twitchy = 'Twitchy'
export const Warm = 'Warm'
export const WarmOrRelaxed = 'Warm or relaxed'
export const Weak = 'Weak'
export const Wobbly = 'Wobbly'

export const planetList = [
  {
    feeling: 'angry',
    src: '/images/planets/fiveToEight/angry.png',
    label: 'Angry',
    sensations: [Breathless, HotOrBurning, Shakey, Prickly, StiffOrTense, Sweaty],
  },
  {
    feeling: 'annoyed',
    src: '/images/planets/fiveToEight/annoyed.png',
    label: 'Annoyed',
    sensations: [StuckOrFrozen, Clenched, Dull, Shakey, Prickly, StiffOrTense],
  },
  {
    feeling: 'bored',
    src: '/images/planets/fiveToEight/bored.png',
    label: 'Bored',
    sensations: [QuietAndNeutral, Dull, Empty, Fuzzy, Numb, Agitated],
  },
  {
    feeling: 'calm',
    src: '/images/planets/fiveToEight/calm.png',
    label: 'Calm',
    sensations: [RelaxedOrAtEase, SteadyOrStrong, Floaty, Light, Warm, ClearOrAwake],
  },
  {
    feeling: 'confident',
    src: '/images/planets/fiveToEight/confident.png',
    label: 'Confident',
    sensations: [SolidOrStrong, Bubbly, Cool, Energized, Sharp, Warm],
  },
  {
    feeling: 'content',
    src: '/images/planets/fiveToEight/content.png',
    label: 'Content',
    sensations: [RelaxedOrAtEase, Bubbly, Light, Tickley, Warm, ClearOrAwake],
  },
  {
    feeling: 'excited',
    src: '/images/planets/fiveToEight/excited.png',
    label: 'Excited',
    sensations: [Buzzy, Bubbly, Electric, Energized, Tingly, Warm],
  },
  {
    feeling: 'grateful',
    src: '/images/planets/fiveToEight/grateful.png',
    label: 'Grateful',
    sensations: [RelaxedOrAtEase, SteadyOrStrong, Floaty, Light, Warm, ClearOrAwake],
  },
  {
    feeling: 'happy',
    src: '/images/planets/fiveToEight/happy.png',
    label: 'Happy',
    sensations: [Buzzy, Bubbly, Floaty, Tickley, WarmOrRelaxed, ClearOrAwake],
  },
  {
    feeling: 'hopeful',
    src: '/images/planets/fiveToEight/hopeful.png',
    label: 'Hopeful',
    sensations: [RelaxedOrAtEase, Buzzy, Light, SteadyOrStrong, Warm, ClearOrAwake],
  },
  {
    feeling: 'insecure',
    src: '/images/planets/fiveToEight/insecure.png',
    label: 'Insecure',
    sensations: [StuckOrFrozen, Flushed, Wobbly, Fuzzy, StiffOrTense, Weak],
  },
  {
    feeling: 'loved',
    src: '/images/planets/fiveToEight/loved.png',
    label: 'Loved',
    sensations: [RelaxedOrAtEase, HeartFlutters, Energized, Light, Tingly, Warm],
  },
  {
    feeling: 'meh',
    src: '/images/planets/fiveToEight/meh.png',
    label: 'Meh',
    sensations: [Neutral, Dull, RelaxedOrAtEase, Numb, Empty, SpaceyOrZonedOut],
  },
  {
    feeling: 'overwhelmed',
    src: '/images/planets/fiveToEight/overwhelmed.png',
    label: 'Overwhelmed',
    sensations: [StuckOrFrozen, Breathless, Churning, ConfusedOrFuzzy, JumbledUp, Knotted],
  },
  {
    feeling: 'sad',
    src: '/images/planets/fiveToEight/sad.png',
    label: 'Sad',
    sensations: [Achy, StuckOrFrozen, Empty, Fuzzy, Knotted, Weak],
  },
  {
    feeling: 'scared',
    src: '/images/planets/fiveToEight/scared.png',
    label: 'Scared',
    sensations: [StuckOrFrozen, Breathless, Fuzzy, Shakey, StiffOrTense, Weak],
  },
] as const

export default planetList
