import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { map } from 'lodash'
import { useCroods } from 'croods'
import { useSchools } from 'Educators/SchoolsContext'
import Educator from 'Educators/ManageSchool/educator'

import EditEducatorAccess from './edit/EditEducatorAccess'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

const EducatorsList = ({ currentUser, classroom = {} }) => {
  const classes = useStyles()
  const { schoolId, isSchoolOwner, school } = useSchools()

  const classroomId = classroom?.id ?? ''

  const [
    {
      list: listClassroomEducators,
      fetchingList: loadingClassroomEducators,
      destroying,
      destroyError,
      saving,
      saveError,
    },
    { fetch: fetchEducators, destroy, save },
  ] = useCroods({
    name: 'educators',
    stateId: `class-${classroomId}`,
    path: `/classrooms/${classroomId}/educators`,
  })

  const [
    {
      list: listSchoolEducators,
      fetchingList: loadingSchoolEducators,
      destroying: destroyingSchoolEducators,
      destroyError: destroyErrorSchoolEducators,
      saving: savingSchoolEducators,
      saveError: saveErrorSchoolEducators,
    },
    { fetch: fetchSchoolEducators, destroy: destroySchoolEducators, save: saveSchoolEducators },
  ] = useCroods({
    name: 'educators',
    stateId: schoolId?.toString(),
    path: `/schools/${schoolId}/educators`,
  })

  useEffect(() => {
    classroomId && fetchEducators()()
  }, [classroomId]) // eslint-disable-line

  useEffect(() => {
    schoolId && fetchSchoolEducators()()
  }, [schoolId]) // eslint-disable-line

  if (loadingClassroomEducators || loadingSchoolEducators)
    return (
      <div className={classes.root} data-testid="skeleton">
        <Skeleton variant="text" height={40} style={{ flex: 1 }} />
      </div>
    )

  if (classroomId) {
    return map(
      listSchoolEducators
        .filter((item) => item.schoolRole === 'owner')
        .concat(listClassroomEducators),
      (educator) => {
        const isEditable =
          isSchoolOwner() && educator.schoolRole !== 'owner' && educator.userId !== currentUser?.id
        return (
          <Educator key={educator.id} educator={educator}>
            {isEditable && (
              <EditEducatorAccess
                mode="classroom"
                removingMessage={`${educator.displayName} will no longer have access to ${classroom?.name} Classroom.`}
                educator={educator}
                loading={destroying || saving}
                error={destroyError || saveError}
                destroy={destroy}
                save={save}
              />
            )}
          </Educator>
        )
      },
    )
  }

  return map(listSchoolEducators, (educator) => {
    const isEditable = isSchoolOwner() && educator.userId !== currentUser?.id
    return (
      <Educator key={educator.id} educator={educator}>
        {isEditable && (
          <EditEducatorAccess
            mode="school"
            removingMessage={`${educator.displayName} will no longer have access to ${school?.name}.`}
            educator={educator}
            loading={destroyingSchoolEducators || savingSchoolEducators}
            error={destroyErrorSchoolEducators || saveErrorSchoolEducators}
            destroy={destroySchoolEducators}
            save={saveSchoolEducators}
          />
        )}
      </Educator>
    )
  })
}

export default EducatorsList
