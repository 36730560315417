import styled from 'styled-components'

export const Paragraph = styled.p<{ color?: string }>`
  /* font-family: 'Nunito Sans', sans-serif; */
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 160%;
  color: ${({ color }) => (Boolean(color) ? color : '#fff5f5')};
  max-width: 533px;
  text-align: center;
  margin-bottom: 1.2rem;
  text-shadow: -1px 1px 4px #000;
  @media screen and (max-width: 600px) {
    font-size: 1.1rem;
    margin-bottom: 1rem;
  }
`
