import React, { useState } from 'react'
import { useCroods } from 'croods'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'

import {
  Grid,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from '@material-ui/core'

import Error from './form/Error'

const styles = (theme) => ({
  content: {
    color: theme.colors.steelGray,
    textAlign: 'center',
    padding: 32,
  },
})

const formatPassword = (birthdate) =>
  moment(birthdate, 'YYYY-MM-DD').format('MMDDYY')

const SuccessDialog = withStyles(styles)(
  ({ classes, open, onClose, student }) => (
    <Dialog onClose={onClose} open={open}>
      <div className={classes.content}>
        <Typography
          variant="h4"
          color="secondary"
          style={{
            marginBottom: 32,
            fontWeight: 'bold',
          }}
        >
          Password Reset Successfully!
        </Typography>
        <Typography>
          {student.firstName} {student.lastName}
        </Typography>
        <Typography>{student.email}</Typography>
        <Typography
          variant="h5"
          color="secondary"
          style={{
            marginTop: 60,
            marginBottom: 60,
          }}
        >
          {formatPassword(student.birthdate)}
        </Typography>
        <Typography>This code is their new temporary password.</Typography>
        <Button color="primary" onClick={onClose}>
          Got it! Close this window.
        </Button>
      </div>
    </Dialog>
  ),
)

export default ({ student, buttonText, style = {}, buttonAreaStyle = {} }) => {
  const [showDialog, setShowDialog] = useState(false)

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'resetPassword',
    parentId: student.id,
    path: `/students/${student.id}/reset-password`,
    afterSuccess: () => setShowDialog(true),
  })

  const handleClick = () => save({ method: 'put' })()
  const closeDialog = () => setShowDialog(false)

  if (!student.email) return null

  return (
    <div style={{ marginTop: '20px', flex: 1, ...style }}>
      {showDialog && (
        <SuccessDialog
          student={student}
          open={showDialog}
          onClose={closeDialog}
        />
      )}
      {saveError && <Error>{saveError}</Error>}
      <Grid container justifyContent="center" style={buttonAreaStyle}>
        {(saving && <CircularProgress color="primary" size="30px" />) || (
          <Button color="primary" onClick={handleClick}>
            {buttonText}
          </Button>
        )}
      </Grid>
    </div>
  )
}
