import React from 'react'
import { navigate } from '@reach/router'
import { useCroods } from 'croods'
import { useFlash } from 'seasoned-flash'

import { Typography } from '@material-ui/core'
import Screen from 'auth/Screen'
import Title from 'components/AuthTitle'
import PageTitle from 'components/PageTitle'
import { welcomePath } from 'students/helper'
import ChangePasswordForm from 'students/changePassword/ChangePasswordForm'

export const handleSubmit = (create) => (values) => {
  create({
    ...values,
    changePasswordOnNextLogin: false,
  })
}

const ChangePassword = () => {
  const { success } = useFlash()
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'updatePassword',
    customPath: '/auth',
    method: 'put',
    parseResponse({ data }) {
      return data
    },
    afterSuccess({
      data: {
        message,
        data: { student },
      },
    }) {
      success(message)
      return navigate(welcomePath(student))
    },
  })

  return (
    <Screen>
      <PageTitle text="Change your password" />
      <Title>Create your password</Title>
      <Typography
        variant="subtitle1"
        align="center"
        style={{ marginTop: '-10px', marginBottom: '16px' }}
      >
        To be safe, please choose a password that is no less than 6 characters in length.
      </Typography>

      <ChangePasswordForm
        apiError={saveError}
        create={handleSubmit(save())}
        creating={saving}
      />
    </Screen>
  )
}

export default ChangePassword
