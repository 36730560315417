import moment from 'moment-timezone'
import { compact, chunk, join, find, includes } from 'lodash'
import isParent from 'auth/isParent'

const findStudentName = (item, filters) =>
  `${item.firstName} ${item.lastName}`.toLowerCase().indexOf(filters.studentName.toLowerCase()) !==
  -1

const findEnergyLevel = (item, filters) => {
  const checkin = item.latestCheckIn
  if (!checkin) return false

  return checkin.fuel === filters.energyLevel
}

const findNeeds = (item, filters) => {
  const checkin = item.latestCheckIn
  if (!checkin) return false

  const keyValue = {
    hasEaten: checkin.hasEaten === false,
    hasSlept: checkin.hasSlept === false,
    hurtOrSick: checkin.hurtOrSick === true,
    okAtHome: checkin.okAtHome === false,
    bulliedAtSchool: checkin.bulliedAtSchool === true,
    talkToAdult: checkin.talkToAdult === true,
  }

  return keyValue[filters.needs]
}

export const applyFilters = (fields, filters) => (item) =>
  // test against all fields in filters
  fields.reduce((isOk, field) => {
    switch (field) {
      case 'studentName':
        return isOk && findStudentName(item, filters)

      case 'grade':
        return isOk && item.grade === filters.grade

      case 'energyLevel':
        return isOk && findEnergyLevel(item, filters)

      case 'needs':
        return isOk && findNeeds(item, filters)

      case 'classroom':
        return isOk && includes(item.classroomIds, filters.classroom)

      case 'caregivers':
        if (isOk) {
          if (filters.caregivers && filters.caregivers.length === 0) return true
          return find(item.caregiversNames, ['email', filters.caregivers])
        }
        return false

      default:
        return isOk
    }
  }, true)

export const listStudentNeeds = ({ checkIn, currentUser = {} }) => {
  const answers = {
    talkToAdult: (value) => value && 'Wants to Talk',
    bulliedAtSchool: (value) => value && 'Peer Issues',
    hasEaten: (value) => !value && 'Hungry',
    hasSlept: (value) => !value && 'Needs Sleep',
    hurtOrSick: (value) => value && 'Hurt/Sick',
    okAtHome: (value) => !value && 'Home Trouble',
    feelingStressed: (value) => value && 'Stressed',
    feelingLonely: (value) => value && 'Lonely',
    overwhelmedByWorkload: (value) => value && 'Workload',
    okInSocialLife: (value) => !value && 'Social Life Stress',
    feelingTired: (value) => value && 'Tired',
    feelingWorthless: (value) => value && 'Worthlessness',
  }

  return compact(
    Object.keys(answers)
      .filter((k) => checkIn[k] !== null)
      .map((topic) => answers[topic](checkIn[topic]))
      .filter((message) => !(message === 'Home Trouble' && isParent(currentUser))),
  )
}

export const isToday = (date) =>
  date && moment.utc(date).clone().tz(moment.tz.guess()).isSame(moment(), 'day')

export const nameConcatenation = (list) => {
  if (list.length <= 2) return join(list, ' and ')
  const newList = chunk(list, list.length - 1)
  return `${join(newList[0], ', ')} and ${newList[1]}`
}
