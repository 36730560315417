import { Modal } from 'CsDashboard/components/Modal'
import { P, Title } from 'CsDashboard/components/styled'
import React from 'react'

interface Props {
  open: boolean
  onClose: () => void
}

export const InstructionsModal = ({ open, onClose }: Props) => (
  <Modal open={open} onClose={onClose}>
    <Title>Destroy students steps:</Title>
    <P>1. Add the educator&apos;s email requesting student removal in the email field.</P>
    <P>2. Click on &quot;Get Educator Data&quot; to see all schools that educator owns.</P>
    <P>
      3. Click &quot;Show all students&quot; for the school where the students you want to remove
      are currently located.
    </P>
    <P>4. Select all students you want to remove.</P>
    <P>
      5. Click &quot;Destroy Selected Students&quot; to remove the students and click again on the
      confirmation popup .
    </P>
  </Modal>
)
