import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import { ACTIVITIES_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'

export const ContentByActivity = {
  breathing: {
    title: (
      <>
        <b>Let’s take 3 big belly breaths.</b>
      </>
    ),
    text: (
      <>
        Breathe in for <b>3</b> seconds. <br />
        Hold for <b>3</b> seconds. <br />
        Breathe out for <b>3</b> seconds. <br />
        <p style={{ marginBottom: '0' }}>
          Repeat <b>3</b> times.
        </p>
      </>
    ),
    buttonText: 'Done!',
    imgSrc: '/images/interventions/cadet-1.png',
    audioButton: ACTIVITIES_PAGE['breathing-instructions'],
  },
  'shake-it-out': {
    title: (
      <>
        <b>Let’s shake it out!</b>
      </>
    ),
    text: (
      <>
        First, wiggle your <b>fingers & toes.</b> Then
        <br />
        shake out your <b>hands.</b> Move your <b>shoulders</b>
        <br />
        up & down a few times!
        <br />
        Shake out your <b>feet</b> and wiggle your <b>legs!</b>
        <p style={{ fontWeight: 'bold', marginBottom: 0 }}>Shake it all out!</p>
      </>
    ),
    buttonText: 'Done!',
    imgSrc: '/images/interventions/cadet-2.png',
    audioButton: ACTIVITIES_PAGE['shake-it-out-instructions'],
  },
  'power-pose': {
    title: (
      <>
        <b>Let’s do a Power Pose.</b>
      </>
    ),
    text: (
      <>
        Stand like a <b>starfish</b> for <b>20 seconds</b>:
        <br />
        <p style={{ marginBottom: 0 }}>
          Stretch <b>your arms</b> high in the air.
          <br /> Stand with <b>your legs</b> apart.
          <br />
          Hold <b>your head</b> up high.
          <br /> Breathe deep and smile BIG!
        </p>
      </>
    ),
    buttonText: 'Done!',
    imgSrc: '/images/interventions/cadet-3.png',
    audioButton: ACTIVITIES_PAGE['power-pose-instructions'],
  },
  'let-it-go': {
    title: (
      <>
        <b>Let’s let go of the tension.</b>
      </>
    ),
    text: (
      <>
        Make <b>a fist</b> with your hands and squeeze
        <br />
        for <b>5</b> seconds. Let them go and feel the
        <br />
        difference! Try with your <b>shoulders</b> next.
        <br />
        <b>Repeat 3 times!</b>
        <p style={{ fontSize: '0.7em', marginBottom: 0, marginTop: 24 }}>
          Do any other parts of your body feel tense?
          <br />
          <b>Try with those too!</b>
        </p>
      </>
    ),
    buttonText: 'Done!',
    imgSrc: '/images/interventions/cadet-4.png',
    audioButton: ACTIVITIES_PAGE['let-it-go-instructions'],
  },
}

type Props = {
  nextFunction: (params: string) => void
}

const Kid = ({ nextFunction }: Props) => {
  const activityKey = window.location.pathname.split('/').pop() as keyof typeof ContentByActivity

  const { title, text, buttonText, imgSrc, audioButton } = ContentByActivity[activityKey]

  return (
    <Screen className="mt-0 md:mt-16">
      <div className="py-8 px-4 md:px-8 m-2 text-center text-2xl bg-[#fffafa] md:mt-30 rounded-3xl">
        <div className="text-[#e9573e] mb-8 text-3xl">
          <small className="text-2xl">This might help</small>
          <br />
          <h1>{title}</h1>
        </div>
        <AudioPlayer
          autoplay
          importAudio={audioButton}
          styleButton={{
            alignSelf: 'flex-start',
            marginLeft: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div className="flex-grow">{text}</div>
        </AudioPlayer>
      </div>
      <img className="h-[30vh] my-12" src={imgSrc} alt={activityKey} />

      <BackAndNextButtons
        nextButtonText={buttonText}
        nextButtonOnClick={() => nextFunction(window.location.search)}
        audios={{
          buttonNext: ACTIVITIES_PAGE.done,
        }}
      />
    </Screen>
  )
}

export default Kid
