import React from 'react'

import Students from 'Educators/ManageSchool/ManageStudents/students-list'
import StudentSkeleton from 'Educators/ManageSchool/student-skeleton'
import { useGlobal } from 'useGlobal'
import { filterStudents } from 'Educators/filters'

const StudentsList = ({ students, loading }) => {
  const [filters, setFilters] = React.useState(null)
  const [, actions] = useGlobal()

  React.useEffect(() => {
    actions.clearSelectedStudents()
  }, [filters, students.length]) // eslint-disable-line react-hooks/exhaustive-deps

  if (loading) return <StudentSkeleton />

  return (
    <Students
      list={students}
      students={filterStudents(students, filters)}
      setFilters={setFilters}
    />
  )
}

export default StudentsList
