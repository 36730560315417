import styled from 'styled-components'

export const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e9573e;
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.75;
  text-transform: uppercase;
  user-select: none;
  box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%),
    0px 1px 5px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  padding: 6px 16px;
  width: 100%;
  white-space: nowrap;
  transition: all ease 0.25s;
  &:hover {
    background: rgb(163, 60, 43);
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
      0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
`

export const IconWrapper = styled.span`
  margin-left: 8px;
`
