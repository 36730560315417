import React from 'react'
import { Button, P, SubTitle, Title } from 'CsDashboard/components/styled'
import { Modal } from 'CsDashboard/components/Modal'

interface Props {
  open: boolean
  setOpen: (value: boolean) => void
}

export const InstructionsModal = ({ open, setOpen }: Props) => (
  <Modal open={open} onClose={() => setOpen(false)}>
    <Title>Steps for reviewing schools</Title>
    <div style={{ overflowX: 'hidden', overflowY: 'auto' }}>
      <P>To review schools, please do the following steps for each school listed below:</P>
      <SubTitle>1. Check the educator’s email:</SubTitle>
      <P>
        Is the educator’s email an official school email? Emails with domains such as @gmail.com,
        @yahoo.com, @hotmail.com, etc. are not allowed. In that case you must disapprove the school
        registration and explain the reason in the email to be sent to the educator.
      </P>
      <SubTitle>2. Click on &quot;Search in metabase&quot;:</SubTitle>
      <P>
        - Check if the school already exists, if you find more than one school with the same or
        similar name, check if the address is the same. In case we already have that school
        registered, you must send an email to the educator requesting access to the existing school,
        and then delete the requested school (disapprove).
      </P>
      <P>
        - Double-check by adding only parts of the school name to increase the school search
        results, there are many cases with similar school names already registered. We must avoid
        duplicated school cases.
      </P>
      <SubTitle>3. Click &quot;Search in google&quot;:</SubTitle>
      <P>- Does the school exist?</P>
      <P>- Is the school name correct and well-written?</P>
      <P>
        - Does the school address (including street, city, postal code, and country) matches the
        data input by the educator?
      </P>
      <P>
        - Is the registered school actually a district (school name and/or address)?
        <br />
        <i>
          If so, the request must be disapproved. The school form is for registering single schools,
          in case the user wants to register many schools can do it through the app.
        </i>
      </P>
      <P>
        - If the school doesn’t have the words Primary, Elementary, Middle, High, etc in its name,
        double-check if there isn’t a school with the same name but with other grades range. We want
        to be very cautious in this review, so to avoid school duplications and user confusion when
        choosing their school(s).
      </P>
      <SubTitle>4. Approving a school</SubTitle>
      <P>- Click on &quot;Approve / Edit School&quot;</P>
      <P>- Update any wrong data in the form</P>
      <P>
        - Click on &quot;Update / Approve School&quot; to update the data, make it public and send
        the alert email to the educator
      </P>
      <SubTitle>4. Disapproving a school</SubTitle>
      <P>- Click on &quot;Disapprove / Destroy School&quot;.</P>
      <P>
        - In the textarea field substitute &quot;{`<_complete_here_>`}&quot; text with the reason
        why you are disapproving that school.
      </P>
      <P>- Click on &quot;Delete and Send email&quot;.</P>
    </div>
    <Button variant="edit" onClick={() => setOpen(false)}>
      Close
    </Button>
  </Modal>
)
