import React from 'react'
import CadetOnBottom from 'checkIn/components/nonReader/CadetOnBottomScreen'

import { ButtonNext } from 'checkIn/components/ButtonNext'

import AudioPlayer from 'checkIn/AudioPlayer'
import { START_PAGE } from 'checkIn/audios'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const NonReader = ({ nextFunction }: Props) => {
  return (
    <CadetOnBottom
      title="My name is Cadet."
      text="I’m so excited to explore emotions with you! "
      backgroundImage="url('/images/cadet_on_the_moon_2.png')"
      audio={START_PAGE.autoplay}
    >
      <AudioPlayer importAudio={START_PAGE.buttonGo}>
        <ButtonNext onClick={() => nextFunction(window.location.search)}>
          <span className="font-bold">Ready to Go!</span>
        </ButtonNext>
      </AudioPlayer>
    </CadetOnBottom>
  )
}

export default NonReader
