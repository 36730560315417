import React from 'react'
import { getQSData } from 'QueryStringController'
import styled from 'styled-components'
import { Button } from './Button'
import { FaSchool } from 'react-icons/fa'
import { MdSchool } from 'react-icons/md'
import { RiMentalHealthFill, RiParentFill } from 'react-icons/ri'

const Container = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
  flex-direction: row;
  width: 800px;
  max-width: 100%;

  @media screen and (max-width: 700px) {
    margin-top: 0.5rem;
  }
`

export const LinksSection = () => {
  const { share } = getQSData()
  const buttons = [
    { name: 'School Staff Member', icon: FaSchool, path: 'school-staff' },
    { name: 'Student', icon: MdSchool, path: 'student' },
  ]
  if (share === 'true') {
    buttons.push({
      name: 'Therapist / Mental Health Professional',
      icon: RiMentalHealthFill,
      path: 'therapist',
    })
    buttons.push({ name: 'Parent', icon: RiParentFill, path: 'parent' })
  }
  return (
    <Container>
      {buttons.map((b) => (
        <Button key={b.name} Icon={b.icon} path={b.path} name={b.name} />
      ))}
    </Container>
  )
}
