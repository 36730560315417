import React from 'react'

import Card from 'components/Card'

import {
  energyLevelOptions,
  emotionOptions,
  followUpQuestionOptions,
  followUpQuestionOptionsV2,
  triageOptions,
} from 'Educators/students/edit/alerts/options'
import SelectList from 'Educators/students/insights/Filter/SelectList'
import Filter from 'Educators/students/insights/SurfacingStudents/Filter'
import { useCroods } from 'croods'
import { useCurrentUser } from 'dmcroods-auth'
import { featureEnabled } from 'featureFlags'
import { useSchools } from 'Educators/SchoolsContext'
import { find, identity, isEmpty, map, pickBy, some, filter as lodashFilter } from 'lodash'
import TableStudents from 'Educators/students/insights/TableStudents'

const croodsConfigs = [
  {
    name: 'surfacingStudentsTriage',
    customPath: `/schools/:id/insights/triage/surfacing_students`,
  },
  {
    name: 'surfacingStudentsFuel',
    customPath: `/schools/:id/insights/fuel/surfacing_students`,
  },
  {
    name: 'surfacingStudentsEmotions',
    customPath: `/schools/:id/insights/emotions/surfacing_students`,
  },
  {
    name: 'surfacingStudentsQuestions',
    customPath: `/schools/:id/insights/questions/surfacing_students`,
  },
]

const SurfacingStudents = ({ indexTab, studentType, filter }) => {
  const [{ currentUser }] = useCurrentUser()
  const [categoryValue, setCategoryValue] = React.useState('')
  const [minimumPercentage, setMinimumPercentage] = React.useState('')
  const { schoolId } = useSchools()
  const [
    { info: surfacingStudents, loading: loadingSurfacingStudents },
    { fetch: fetchSurfacingStudents },
  ] = useCroods({
    name: croodsConfigs[indexTab].name,
  })

  const selectOptions = [
    {
      label: 'Triage',
      options: triageOptions,
    },
    {
      label: 'Energy Level',
      options: energyLevelOptions,
    },
    {
      label: 'Emotions',
      options: emotionOptions[studentType],
    },
    {
      label: 'Follow Up Questions',
      options: featureEnabled(currentUser, 'nine_to_twelve_check_in')
        ? followUpQuestionOptionsV2[studentType]
        : followUpQuestionOptions[studentType],
    },
  ]

  React.useEffect(() => {
    setCategoryValue('')
  }, [indexTab])

  React.useEffect(() => {
    categoryValue &&
      minimumPercentage &&
      fetchSurfacingStudents({
        id: schoolId,
        customPath: `${croodsConfigs[indexTab].customPath}?${map(
          pickBy({ ...filter, min_percentage: minimumPercentage }, identity),
          (value, key) => `${key}=${value}`,
        ).join('&')}`,
      })()
  }, [minimumPercentage, filter, schoolId, indexTab, categoryValue]) // eslint-disable-line

  const handleMinimumPercentage = (value) => setMinimumPercentage(value.replace(/[^\d]/, ''))

  const numberOfStudents = surfacingStudents?.students?.[0]?.totalStudents ?? 0
  const percentageOfStudents =
    find(surfacingStudents?.chart, ({ slug }) => slug === categoryValue)?.percentage ?? 0

  const students = surfacingStudents?.students ?? []

  const hideComponent = isEmpty(minimumPercentage) || isEmpty(categoryValue)

  return (
    <Card title="SURFACING STUDENTS">
      <Card className="p-0" propsMain={{ className: 'p-10' }}>
        <Filter
          studentType={studentType}
          filter={filter}
          indexTab={indexTab}
          loading={loadingSurfacingStudents}
          disabled={isEmpty(categoryValue)}
          minimumPercentage={minimumPercentage}
          handleMinimumPercentage={handleMinimumPercentage}
          hideCriteria={hideComponent}
          numberOfStudents={numberOfStudents}
          percentageOfStudents={percentageOfStudents}
        >
          <SelectList
            {...selectOptions[indexTab]}
            value={categoryValue}
            onChange={(event) => {
              const { value } = event.target
              setCategoryValue(value)
            }}
            propsSelect={{
              'data-testid': 'select-category',
            }}
          />
        </Filter>
      </Card>

      {!hideComponent && (
        <TableStudents
          data={lodashFilter(students, ({ details }) =>
            some(details, ({ slug }) => slug === categoryValue),
          )}
        />
      )}
    </Card>
  )
}

export default SurfacingStudents
