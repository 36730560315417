import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: theme.spacing(2),
  },
}))

const TabLabelWithBadge = ({ label, badgeContent }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div style={{ marginRight: 16 }}>{label}</div>

      {!!badgeContent ? (
        <div
          style={{
            display: 'inline-flex',
            position: 'relative',
            flexShrink: '0',
            verticalAlign: 'middle',
          }}
        >
          <div
            style={{
              background: '#e9573e',
              top: '0',
              right: '0',
              transform: 'scale(1) translate(50%, -50%)',
              transformOrigin: '100% 0%',
              color: '#fff',
              height: '20px',
              display: 'flex',
              padding: '0 6px',
              zIndex: '1',
              position: 'absolute',
              flexWrap: 'wrap',
              fontSize: '0.75rem',
              minWidth: '20px',
              boxSizing: 'border-box',
              transition: 'transform 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              alignItems: 'center',
              fontFamily: 'Roboto',
              fontWeight: '500',
              lineHeight: '1',
              alignContent: 'center',
              borderRadius: '10px',
              flexDirection: 'row',
              justifyContent: 'center',
            }}
          >
            {Number(badgeContent) > 9 ? '9+' : badgeContent}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default TabLabelWithBadge
