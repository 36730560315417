import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { setQSData } from 'QueryStringController'
import NonReader from 'checkIn/steps/Emotion/NonReader'
import { MOCK_USER } from '../constants'


const SCREEN_NAME = 'chooseEmotion'

const Emotion: RouteComponent = () => {
  const { student } = useCheckIn()
  

  useEffect(() => {
    document.body.style.backgroundImage = `url('/images/background_pk2_check_in_2024.png')`
    document.body.style.minHeight = '100vh'
    document.body.style.backgroundSize = 'cover'
    document.body.style.backgroundRepeat = 'no-repeat'
    document.body.style.backgroundPosition = 'center'
    //@ts-ignore
    document.body.style.backdropFilter = 'none'
  }, [])
  if (student) {
    const { nextFunction } = FLOWS['non-reader'][SCREEN_NAME]

    const selectEmotionFunction = (feeling: string) => {
      setQSData({ feeling })
      nextFunction(window.location.search, MOCK_USER)
    }

    return <NonReader selectEmotionFunction={selectEmotionFunction} currentUser={MOCK_USER} />
  }

  return null
}
export default Emotion
