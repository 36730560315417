import React, { memo } from 'react'
import { StyledButton, IconWrapper } from './styles'

type Props = {
  title: string
  iconPath?: string
  onClick(): void
}

export const Button = memo(({ title, iconPath, onClick, ...props }: Props) => (
  <StyledButton onClick={onClick} {...props}>
    {title}
    {iconPath && (
      <IconWrapper>
        <img src={iconPath} alt={title} className="h-6" />
      </IconWrapper>
    )}
  </StyledButton>
))
