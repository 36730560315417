import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import planetListV1 from 'checkIn/constants/nonReader/planetList'
import planetListV2 from 'checkIn/constants/nonReader/planetListV2'
import { HOW_ARE_YOU_FEELING_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'
import Image from 'components/ResponsiveImage'
import { User } from 'types'
import { featureEnabled } from 'featureFlags'

type Props = {
  selectEmotionFunction: (params: string, user?: User) => void
  currentUser: User
}

const NonReader = ({ selectEmotionFunction, currentUser }: Props) => {
  const planetList = featureEnabled(currentUser, 'pk_2_check_in_2024') ? planetListV2 : planetListV1
  
  // Adjust column quantity based on how many emotions there are
  const layoutStyles = featureEnabled(currentUser, 'pk_2_check_in_2024') ? "container grid grid-cols-2 gap-2 sm:gap-4 sm:grid-cols-3 md:gap-10 max-w-5xl flex-1" : "container grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4 flex-1"

  return (
    <Screen
      label="Choose 1 Planet:"
      title="How are you feeling now?"
      audios={HOW_ARE_YOU_FEELING_PAGE}
    >
      <div className="flex flex-col items-center w-screen mt-10 text-center">
        <div className={layoutStyles}>
          {planetList.map(({ feeling, src, label }) => (
            <AudioPlayer
              key={feeling}
              className="justify-center gap-3 mt-10"
              importAudio={HOW_ARE_YOU_FEELING_PAGE[feeling]}
              styleButton={{
                alignSelf: 'flex-end',
                marginLeft: -20,
                marginBottom: 4,
              }}
            >
              <button
                type="button"
                onClick={() => {
                  selectEmotionFunction(feeling, currentUser)
                }}
              >
                <Image
                  src={src}
                  alt={''}
                  className="mb-2.5 max-w-[132px] mx-auto block"
                />
                <p className="text-xl text-center tracking-wider font-medium text-[#FFF5F5]">
                  {label}
                </p>
              </button>
            </AudioPlayer>
          ))}
        </div>
      </div>
    </Screen>
  )
}

export default NonReader
