import React, { ChangeEvent, useState } from 'react'
import { useCroods } from 'croods'

import Loading from 'components/Loading'
import { error, success } from 'components/Toast'
import { AdminScreen } from './components/AdminScreen'
import { EmailInput } from './components/EmailInput'
import { Button, Card2, P, Row } from './components/styled'
import { Role } from 'types'
import { TextInput } from './components/TextInput'
import { Select } from './components/Select'
import { educatorRolesList } from 'roles'
import HONORIFIC_TITLES from 'honorificTitles'
import { NumberInput } from './components/NumberInput'

interface User {
  userId: number | null
  firstName: string
  lastName: string
  email: string
  honorificTitle: string | null
  cs: boolean | null
  role: Role | null
}

const INITIAL_STATE = {
  userId: null,
  firstName: '',
  lastName: '',
  email: '',
  honorificTitle: null,
  cs: null,
  role: null,
}

export const UpdateEducatorData = (props: any) => {
  const [email, setEmail] = useState('')
  const [state, setState] = useState<User>(INITIAL_STATE)

  const [{ fetchingInfo }, { fetch }] = useCroods({
    name: 'admin/user_data',
    customPath: 'admin/user_data',
    afterSuccess: ({ data }) => {
      setState({
        userId: data?.id,
        firstName: data?.firstName,
        lastName: data?.lastName,
        email: data?.email,
        honorificTitle: data?.honorificTitle,
        cs: data?.cs,
        role: data?.role,
      })
    },
    afterFailure: ({ response }) => error(response?.data.message),
  })

  const booleanOptions = [
    { value: 'true', label: 'Yes' },
    { value: 'false', label: 'No' },
  ]

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'admin/update-educator',
    customPath: 'admin/update_educator',
    afterSuccess: () => success('Educator updated successfully'),
    afterFailure: ({ response }) => {
      error(response?.data.message)
      saveError && error(saveError)
    },
  })

  function updateEducatorData() {
    save({ method: 'PUT' })({
      userId: state?.userId,
      firstName: state?.firstName,
      lastName: state?.lastName,
      email: state?.email,
      honorificTitle: state?.honorificTitle,
      cs: state?.cs,
      role: state?.role,
    })
  }

  function handleSetUser(e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) {
    const { value, name } = e.target
    name === 'cs' || name === 'firstSchoolAccessPending'
      ? setState({ ...state, [name]: value === 'true' })
      : setState({ ...state, [name]: value })
  }

  return (
    <AdminScreen title="Update Educator Data" {...props}>
      <div style={{ alignSelf: 'center', maxWidth: '600px', width: '100%' }}>
        <P>1. Add the educator&apos;s email</P>
        <EmailInput label="Educator email *" value={email} setValue={setEmail} />
        <Button variant="edit" disabled={email === ''} onClick={() => fetch()({ email })}>
          Get user data
        </Button>

        {fetchingInfo ? <Loading /> : null}

        {state === INITIAL_STATE ? null : (
          <Card2>
            <Row>
              <NumberInput disabled name="id" label="User ID" value={state?.userId || ''} />

              <TextInput
                name="firstName"
                label="First name"
                value={state?.firstName}
                onChange={(e) => handleSetUser(e)}
              />

              <TextInput
                name="lastName"
                label="Last name"
                value={state?.lastName}
                onChange={(e) => handleSetUser(e)}
              />
            </Row>

            <Row>
              <EmailInput label="Email" value={state?.email} onChange={(e) => handleSetUser(e)} />

              <Select
                name="role"
                label="Educator role"
                options={educatorRolesList}
                value={state?.role || ''}
                onChange={(e) => handleSetUser(e)}
              />
            </Row>

            <Row>
              <Select
                name="cs"
                label="Customer Support Member"
                options={booleanOptions}
                value={String(state?.cs)}
                onChange={(e) => handleSetUser(e)}
              />
              <Select
                name="honorificTitle"
                label="Honorific Title"
                options={HONORIFIC_TITLES}
                value={state?.honorificTitle || ''}
                onChange={(e) => handleSetUser(e)}
              />
            </Row>

            <Button variant="add" onClick={updateEducatorData}>
              Update Educator Data
            </Button>

            {saving ? <Loading /> : null}
          </Card2>
        )}
      </div>
    </AdminScreen>
  )
}
