const sendValuesAndToken = (create, props) => (values) => {
  const params = new URLSearchParams(props.location.search)
  create({
    ...values,
    resetPasswordToken: params.get('reset_password_token'),
    changePasswordOnNextLogin: false,
  })
}

export default sendValuesAndToken
