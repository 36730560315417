import React, { useState } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import { setQSData } from 'QueryStringController'
import NineToTwelve from './NineToTwelve'
import { navigate } from '@reach/router'
import { compact, includes, isEmpty, remove } from 'lodash'

const SecondaryEmotions: RouteComponent = () => {
  const { student } = useCheckIn()
  const [selected, setSelected] = useState<Array<string>>([])

  if (student) {
    const nextFunction = (params: string) => navigate(`./share-anything-else${params}`)

    const handleOptionClick = (item: string) => {
      const value = item.toLowerCase()

      if (includes(selected, value)) {
        remove(selected, (element) => element === value)
      } else {
        selected.push(value)
      }
      const compactedSelected = compact(selected)
      setSelected(compactedSelected)
    }

    const submitQuestions = () => {
      if (!isEmpty(selected)) {
        setQSData({ needs: selected.join(',') })
      }
      nextFunction(window.location.search)
    }

    return (
      <NineToTwelve
        handleOptionClick={handleOptionClick}
        nextFunction={submitQuestions}
        selected={selected}
        needsBlocklist={student.needsBlocklist || []}
      />
    )
  }

  return null
}
export default SecondaryEmotions
