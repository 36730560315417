import React, { useState } from 'react'
import { InternalLinkButton } from 'auth/signUpFlow/components/styled'
import { DashboardScreen } from 'components/DashboardScreen'
import { Content, Title, Text } from './style'
import { SearchInput } from './SearchInput'
import validateUserFields from 'auth/validateUserFields'

export const SearchSchool = validateUserFields((props: any) => {
  const [school, setSchool] = useState({})
  return (
    <DashboardScreen {...props} propsHeader={{ propsMenuSchool: { hidden: true } }} minimal={true}>
      <Content>
        <Title>Find your school</Title>

        <Text>Search for your school's name:</Text>

        <SearchInput
          value={school}
          setSchool={setSchool}
          placeholder="Type and select your school..."
        />

        <Text>
          <strong>Pro-tip:</strong> You may need to try different versions of the school name to
          find the correct match.
        </Text>
        <Text>
          Even if you work at a district, please start by registering one school. You can add more
          later!
        </Text>
        <Text>
          If you can't find your school above, adding it to our database is quick and easy! Click
          below to provide your school's details.
        </Text>

        <br />

        <InternalLinkButton to="/add-school-info">{` Add School `}</InternalLinkButton>
      </Content>
    </DashboardScreen>
  )
})
