import React, { useEffect } from 'react'
import { Container } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import Breadcrumbs from 'components/Breadcrumbs'
import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'
import ManageStudents from 'Educators/ManageSchool/ManageStudents'
import RemoveFromClassroomButton from 'Educators/ManageSchool/ManageStudents/remove-from-classroom-button'

import EducatorsList from 'Educators/ManageSchool/ManageEducators/EducatorsList'
import Card from 'components/Card'
import { useSchools } from 'Educators/SchoolsContext'

import ButtonMore from 'Educators/ManageSchool/Classroom/ButtonMore'
import { useCroods } from 'croods'
import { Skeleton } from '@material-ui/lab'
import { find, includes, isEmpty } from 'lodash'
import { navigate } from '@reach/router'

import ClassroomFilter from 'Educators/ManageSchool/Classroom/ClassroomFilter'
import StudentsViewer from 'Educators/ManageSchool/students-viewer'

const isClassroomAdminOrOwner = (classrooms, classroomId) =>
  includes(['admin', 'owner'], findClassroom(classrooms, classroomId)?.role)

const findClassroom = (classrooms, classroomId) =>
  find(classrooms, { id: parseInt(classroomId, 10) })

const Classroom = validateUserFields(({ currentUser, classroomId }) => {
  const { school, schoolId, isSchoolOwner } = useSchools()

  const [{ list: classrooms, fetchingList: loading }, { fetch: fetchClassroom }] = useCroods({
    cache: true,
    stateId: schoolId,
    name: 'classrooms',
    customPath: `/schools/${schoolId}/classrooms`,
    afterSuccess: ({ data }) => {
      if (isEmpty(findClassroom(data, classroomId))) navigate('/manage-school')
    },
  })

  useEffect(() => {
    schoolId && classroomId && fetchClassroom()()
  }, [schoolId]) // eslint-disable-line

  const classroom = findClassroom(classrooms, classroomId)

  return (
    <DashboardScreen
      currentUser={currentUser}
      propsHeader={{ propsMenuSchool: { disabled: true } }}
    >
      <Container className="flex flex-col">
        <Breadcrumbs
          paths={[
            {
              children: `Manage ${school?.name}`,
              to: '/manage-school?resourceType=classrooms',
              replace: true,
            },
          ]}
        >
          {loading ? <Skeleton variant="text" height={40} /> : classroom?.name}
        </Breadcrumbs>
        <Card
          light
          isAccentColor
          title={classroom?.name}
          CardHeaderAction={<ButtonMore classroom={classroom} />}
        >
          <ClassroomFilter classrooms={classrooms} classroomId={classroomId} />
          <Card title="EDUCATORS">
            <EducatorsList currentUser={currentUser} classroom={classroom} />
            {isSchoolOwner() && (
              <div className="flex items-center justify-between mt-10">
                <button
                  className="flex gap-2 button"
                  onClick={() =>
                    navigate(`/manage-school/classrooms/${classroomId}/educators/add`, {
                      state: { classroom },
                    })
                  }
                >
                  <AddIcon /> ADD EDUCATOR
                </button>
              </div>
            )}
          </Card>

          <Card title="STUDENTS">
            {isClassroomAdminOrOwner(classrooms, classroomId) ? (
              <ManageStudents
                classroom={classroom}
                currentUser={currentUser}
                classroomId={classroomId}
                schoolId={schoolId}
              />
            ) : (
              <StudentsViewer classroomId={classroomId} />
            )}
            {isClassroomAdminOrOwner(classrooms, classroomId) && (
              <div className="flex items-center justify-between mt-10">
                <button
                  className="flex gap-2 button"
                  onClick={() =>
                    navigate(`/manage-school/classrooms/${classroomId}/students/add`, {
                      state: { classroom },
                    })
                  }
                >
                  <AddIcon /> ADD STUDENT
                </button>
                <RemoveFromClassroomButton classroom={classroom} />
              </div>
            )}
          </Card>
        </Card>
      </Container>
    </DashboardScreen>
  )
})

export default Classroom
