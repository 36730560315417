import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import { ACTIVITIES_PAGE } from 'checkIn/audios'
import ButtonNext from 'checkIn/components/ButtonNext'
import AudioPlayer from 'checkIn/AudioPlayer'

import { ContentByActivity } from 'checkIn/steps/Activities/NonReader'

export type Activity = keyof typeof ContentByActivity

type Props = {
  selectActivityFunction: (value: Activity) => void
  label: string
  energizedAndComfy: boolean
}

const NonReader = ({
  selectActivityFunction,
  label,
  energizedAndComfy,
}: Props) => {
  const audio = energizedAndComfy
    ? ACTIVITIES_PAGE['choose-intervation']
    : ACTIVITIES_PAGE['in-the-meantime-choose-intervation']

  const buttonTextClassNames = 'w-64 px-2 md:px-4 font-bold md:w-80'

  return (
    <Screen
      label={label}
      title="Which activity sounds good to you right now?"
      audios={{ autoplay: audio }}
    >
      <div className="grid grid-cols-1 gap-8 py-10 md:py-16 md:gap-12 md:grid-cols-2">
        <AudioPlayer importAudio={ACTIVITIES_PAGE.breathing}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('breathing')}
          >
            Breathing!
          </ButtonNext>
        </AudioPlayer>

        <AudioPlayer importAudio={ACTIVITIES_PAGE['let-it-go']}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('let-it-go')}
          >
            Let it go!
          </ButtonNext>
        </AudioPlayer>

        <AudioPlayer importAudio={ACTIVITIES_PAGE['shake-it-out']}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('shake-it-out')}
          >
            Shake it Out!
          </ButtonNext>
        </AudioPlayer>

        <AudioPlayer importAudio={ACTIVITIES_PAGE['power-pose']}>
          <ButtonNext
            className={buttonTextClassNames}
            onClick={() => selectActivityFunction('power-pose')}
          >
            Power Pose
          </ButtonNext>
        </AudioPlayer>
      </div>
    </Screen>
  )
}

export default NonReader
