import React from 'react'

import ScreenContent from 'checkIn/steps/PreferredContact/Teen/ScreenContent'

const PreferredContact = ({ flow }) => {
  const { nextFunction } = flow

  return (
    <ScreenContent
      preferredContacts={[
        { id: 1, displayName: 'Teacher One' },
        { id: 2, displayName: 'Teacher Two' },
      ]}
      nextFunction={nextFunction}
    />
  )
}

export default PreferredContact
