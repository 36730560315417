import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import FUEL from 'fuel'
import { FUEL_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'
import { User } from 'types'

const listFuelLink = Object.values(FUEL)

type Props = {
  selectFuelFunction: (value: string, user?: User) => void
  currentUser?: User
}

const NonReader = ({ selectFuelFunction, currentUser }: Props) => {
  const styleByFuel = {
    green: 'bg-[#ef816f] hover:bg-[#d4655e] border-l-8 border-[#d4655e]',
    yellow: 'bg-[#e9573f] hover:bg-[#c63228] border-l-8 border-[#c63228]',
    blue: 'bg-[#803023] hover:bg-[#6d1b16] border-l-8 border-[#6d1b16]',
    red: 'bg-[#2f110d] hover:bg-[#280a08] border-l-8 border-[#280a08]',
  } as const

  const playerStyleByFuel = {
    green: 'h-[35%] mb-1',
    yellow: 'h-[30%] mb-1',
    blue: 'h-[20%] mb-1',
    red: 'h-[15%]',
  }

  const buttonStyle = (color: string) => styleByFuel[color as keyof typeof styleByFuel]

  const playerStyle = (color: string) => playerStyleByFuel[color as keyof typeof playerStyleByFuel]

  return (
    <Screen
      className="!justify-start h-screen pt-0 md:pt-16"
      label="Pick the color that matches your energy level."
      title="How much fuel is in your spaceship?"
      audios={FUEL_PAGE}
    >
      <div className="z-10 flex h-[60vh] items-end flex-col my-0 mx-auto relative text-center bg-gray-300 border-l-8 border-gray-400 px-4 py-6 rounded-3xl max-w-[360px] w-[60%] sm:w-full">
        {listFuelLink.map(({ value, label }) => (
          <AudioPlayer
            key={value}
            className={`${playerStyle(value)} w-full`}
            styleButton={{
              alignSelf: 'center',
              marginBottom: 0,
            }}
            importAudio={FUEL_PAGE[value as keyof typeof FUEL_PAGE]}
          >
            <button
              key={value}
              type="button"
              className={`${buttonStyle(value)} text-white text-2xl font-bold w-full rounded`}
              onClick={() => selectFuelFunction(value, currentUser)}
            >
              {label}
            </button>
          </AudioPlayer>
        ))}
      </div>

      <div className="relative bottom-0 z-0 w-full mt-5 md:mt-10 md:absolute">
        <BackAndNextButtons
          showBackButton
          hiddenNextButton
          audios={{
            buttonBack: FUEL_PAGE.buttonBack,
          }}
        />
      </div>
    </Screen>
  )
}

export default NonReader
