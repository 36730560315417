import React from 'react'
import Screen from 'checkIn/components/kid/Screen'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'

type Props = {
  nextFunction: (params: string) => Promise<void>
  label: string
  bgImage: string
}

const Kid = ({ nextFunction, label, bgImage }: Props) => {
  return (
    <Screen
      label={label}
      title="Help me understand better by answering a few questions..."
    >
      <img className="h-[35vh] mt-28" src={bgImage} alt="Sad cadet" />

      <div className="absolute bottom-0 w-full">
        <BackAndNextButtons
          nextButtonText="Sure!"
          nextButtonOnClick={() => nextFunction(window.location.search)}
        />
      </div>
    </Screen>
  )
}

export default Kid
