import React from 'react'
import map from 'lodash/map'
import withMixpanel from 'mixpanel/withMixpanel'
import Error from 'components/form/Error'

import ButtonNext from 'checkIn/components/ButtonNext'
import { checkInTrack, setStudentInfo } from 'students/helper'
import Screen from 'checkIn/components/nonReader/Screen'
import { setQSData, getQSData } from 'QueryStringController'
import { ACTIVITIES_CONCLUSION_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'
import Loading from 'components/Loading'
const buttons = [
  {
    src: '/images/feelings/Better.png',
    alt: 'better',
    buttonText: 'Better!',
    value: 'better',
  },
  {
    src: '/images/feelings/Same.png',
    alt: 'same',
    buttonText: 'About the same',
    value: 'same',
  },
  {
    src: '/images/feelings/Worse.png',
    alt: 'worse',
    buttonText: 'Worse',
    value: 'worse',
  },
]

const HowAreYouFeelingNow = ({ mixpanel, flow, userId, studentId, saving, saveError, save }) => {
  const { nextFunction } = flow

  function onClick(buttonText, value) {
    if (saving) return
    const qs = getQSData()
    setStudentInfo({ feelingAfterIntervention: buttonText })
    checkInTrack(mixpanel, `"${buttonText}" clicked`, {
      ...qs,
      feelingAfterIntervention: buttonText,
    })
    setQSData({ feelingNow: value })

    nextFunction(save, userId, studentId)
  }

  return (
    <Screen
      className="h-[100vh] mt-0"
      title={saving ? 'Saving' : 'How are you feeling now?'}
      style={{ justifyContent: 'center' }}
      audios={
        saving
          ? null
          : {
              autoplay: ACTIVITIES_CONCLUSION_PAGE['how-are-you-feeling-now'],
            }
      }
    >
      {saving ? (
        <Loading />
      ) : (
        <div className="px-10 mt-10">
          {map(buttons, ({ value, src, alt, buttonText }) => (
            <AudioPlayer
              key={value}
              styleButton={{ marginLeft: -20, marginBottom: 30 }}
              importAudio={ACTIVITIES_CONCLUSION_PAGE[value]}
            >
              <div className="flex items-center w-full mb-10">
                <img src={src} alt={alt} className="w-10 h-10 mr-5 sm:mr-10 sm:w-20 sm:h-20" />
                <ButtonNext
                  className="w-full !text-base sm:!text-4xl"
                  onClick={() => onClick(buttonText, value)}
                >
                  <span className="font-semibold whitespace-nowrap">{buttonText}</span>
                </ButtonNext>
              </div>
            </AudioPlayer>
          ))}
        </div>
      )}
      {saveError && <Error>{saveError}</Error>}
    </Screen>
  )
}

export default withMixpanel(HowAreYouFeelingNow)
