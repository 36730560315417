import React from 'react'
import { NextButton } from '../components/NextButton'
import Screen from '../components/screen'

export const ContentByActivity = {
  breathing: {
    title: 'Let’s take 5 deep breaths.',
    text: (
      <>
        <p className="mb-6">Breathe in for 5 seconds.</p>
        <p className="mb-6">Hold for 5 seconds.</p>
        <p className="mb-6">Breathe out for 5 seconds.</p>
        <p className="mb-6">Hold for 5 seconds.</p>
        <p className="mb-6">Repeat 5 times.</p>
      </>
    ),
  },
  'safe-space': {
    title: 'Imagine Your Safe Space.',
    text: (
      <>
        <p className="mb-6">Think of a place, real or imaginary, that you feel really safe in.</p>
        <p className="mb-6">What would you see and hear in this safe place?</p>
        <p className="mb-6">What would you feel?</p>
        <p className="mb-6">
          Take some time to visualize your safe space and yourself feeling relaxed and at peace in
          it.
        </p>
      </>
    ),
  },
  'let-it-go': {
    title: 'Let’s let go of the tension.',
    text: (
      <>
        <p className="mb-6">
          Make a fist with your hands and squeeze for 5 seconds. Let them go and feel the
          difference!
        </p>
        <p className="mb-6">Try with your shoulders next.</p>
        <p className="mb-6">Repeat 3 times!</p>
        <p className="mb-6">Do any other parts of your body feel tense?</p>
        <p className="mb-6">Try with those too!</p>
      </>
    ),
  },
  'the-ok-inventory': {
    title: 'The OK Inventory.',
    text: (
      <>
        <p className="mb-6">Think about one thing in your life that is OK right now.</p>
        <p className="mb-6">
          Start with something easy, like your best friend, your chair, your elbows :)
        </p>
        <p className="mb-6">Now write down 5-10 more things that are OK in your life.</p>
        <p className="mb-6">See how long your list can get!</p>
      </>
    ),
  },
  journaling: {
    title: 'Journaling.',
    text: (
      <>
        <p className="mb-6">Let’s do some “stream of consciousness” writing for 3 minutes.</p>
        <p className="mb-6">
          Write down anything and everything - no spell check, no judgement, no stopping!
        </p>
        <p className="mb-6">Just start writing and see where it takes you.</p>
      </>
    ),
  },
}

type Props = {
  handleNextFunction: () => void
}

const NineToTwelve = ({ handleNextFunction }: Props) => {
  const activityKey = window.location.pathname.split('/').pop() as keyof typeof ContentByActivity

  const { title, text } = ContentByActivity[activityKey]
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        Which activity sounds good to you right now?
      </div>

      <div className="bg-white/15 p-6 rounded-2xl max-w-6xl w-full">
        <div className="flex justify-between items-center mb-10">
          <div className="flex-grow-0"></div>
          <p className="flex-grow text-center text-gray-100 text-xs font-bold font-['Roboto'] uppercase tracking-widest">
            {title}
          </p>
        </div>

        <div className="text-gray-100 text-2xl font-light font-['Roboto']">{text}</div>
      </div>

      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-20"
      >
        finish
      </NextButton>
    </Screen>
  )
}

export default NineToTwelve
