import React from 'react'
import { Router, Redirect } from '@reach/router'
import { EducatorDashboard } from 'Educators/Dashboard'
import ManageSchool from 'Educators/ManageSchool'
import NewSchoolYearWelcome from 'Educators/ManageSchool/NewSchoolYear/Welcome'
import NewSchoolYearWelcomeBack from 'Educators/ManageSchool/NewSchoolYear/WelcomeBack'
import NewSchoolYearWorkingOnIt from 'Educators/ManageSchool/NewSchoolYear/WorkingOnIt'
import NewSchoolYearSetUp from 'Educators/ManageSchool/NewSchoolYear/NewSchool'
import ReturningSchoolNewSchoolYearSetUp from 'Educators/ManageSchool/NewSchoolYear/ReturningSchool'
import InsightsSchoolNewSchoolYearSetUp from 'Educators/ManageSchool/NewSchoolYear/InsightsSchool'

import NewSchoolYearChooseSchool from 'Educators/ManageSchool/NewSchoolYear/ChooseSchool'

import CreateClassrooms from 'Educators/ManageSchool/CreateClassrooms/v2'
import EditStudent from 'Educators/students/edit'
import AddStudent from 'Educators/students/new'
import Insights from 'Educators/students/insights'
import InsightsAlerts from 'Educators/students/insights/Alerts'
import EditProfile from 'Educators/profile/edit/Info'
import { SearchSchool } from 'Educators/AddSchool/SearchSchool'
import { ConfirmSchool } from 'Educators/AddSchool/ConfirmSchool'
import { AddSchoolInfo } from 'Educators/AddSchool/AddSchoolInfo'
import ResetPassword from 'auth/forgotPassword/ResetPassword'

import SignOut from 'auth/SignOut'
import Block from 'auth/Block'

import ImportEducators from 'Educators/educators/import'

import WelcomeBack from 'students/WelcomeBack'
import Welcome from 'students/Welcome'

import ChangePassword from 'students/changePassword'
import StudentDashboard from 'students/dashboard'

import RedirectFromWantsToTalkToAdultEmail from 'students/dashboard/RedirectFromWantsToTalkToAdultEmail'
import Classroom from 'Educators/ManageSchool/Classroom'
import AddEducator from 'Educators/ManageSchool/Classroom/AddEducator'
import AddStudents from 'Educators/ManageSchool/Classroom/AddStudents'
import Vacations from 'Educators/ManageSchool/Vacations/V2'
import ZendeskSignIn from 'auth/Zendesk'
import AppV2SignIn from 'auth/APP_V2'
import Discovery from 'Educators/V2/Discovery'
import InsightsV2 from 'Educators/V2/Insights'
import Home from 'Educators/V2/Home'
import Monitoring from 'Educators/V2/Monitoring'
import { CsDashboard } from 'CsDashboard'
import { AddSchoolOwner } from 'CsDashboard/AddSchoolOwner'
import { CreateDistrict } from 'CsDashboard/CreateDistrict'
import { DestroySchool } from 'CsDashboard/DestroySchool'
import { DestroyStudents } from 'CsDashboard/DestroyStudents'
import { DisconectSchoolFromDistrict } from 'CsDashboard/DisconnectSchoolFromDistrict'
import { MergeSchools } from 'CsDashboard/MergeSchools'
import { MoveStudents } from 'CsDashboard/MoveStudents'
import { SchoolReview } from 'CsDashboard/SchoolReview'
import { UpdateEducatorData } from 'CsDashboard/UpdateEducatorData'
import { UpdateSchoolDistrict } from 'CsDashboard/UpdateSchoolDistrict'
import { UpdateUserEmail } from 'CsDashboard/UpdateUserEmail'
import Messages from 'Educators/V2/Messages'
import MessagesShow from 'Educators/V2/MessagesShow'
import StudentMessages from 'students/V2/Messages'
import ManageSchoolV2 from 'Educators/V2/ManageSchool'
import VerifyEmail from 'auth/signUpFlow/VerifyEmail'

const AppRouter = (props) => (
  <Router>
    <VerifyEmail {...props} path="/verify-email" />
    <CsDashboard {...props} path="/cs-dashboard" />
    <DestroyStudents {...props} path="/cs-dashboard/destroy-students" />
    <SchoolReview {...props} path="/cs-dashboard/review-schools" />
    <DestroySchool {...props} path="/cs-dashboard/destroy-school" />
    <MoveStudents {...props} path="/cs-dashboard/move-students" />
    <MergeSchools {...props} path="/cs-dashboard/merge-schools" />
    <AddSchoolOwner {...props} path="/cs-dashboard/add-school-owner" />
    <DisconectSchoolFromDistrict {...props} path="/cs-dashboard/disconect-school-from-district" />
    <UpdateSchoolDistrict {...props} path="/cs-dashboard/update-school-district" />
    <UpdateUserEmail {...props} path="/cs-dashboard/update-user-email" />
    <CreateDistrict {...props} path="/cs-dashboard/create-district" />
    <UpdateEducatorData {...props} path="/cs-dashboard/update-educator-data" />

    <Welcome {...props} path="/welcome" />
    <WelcomeBack {...props} path="/welcome-back" />

    <ResetPassword {...props} path="/new-password" />
    <EditProfile {...props} path="/dashboard/profile" />
    <SearchSchool {...props} path="/search-school" />
    <ConfirmSchool {...props} path="/confirm-school" />
    <AddSchoolInfo {...props} path="/add-school-info" />
    <AddStudent {...props} path="/dashboard/students/new" />
    <EditStudent {...props} path="/dashboard/students/:studentId/edit" />
    <Insights {...props} path="/dashboard/students/insights" />
    <InsightsAlerts {...props} path="/dashboard/students/insights/alerts" />
    <ChangePassword {...props} path="/change-password" />
    <ImportEducators {...props} path="/dashboard/educators/import" />

    <SignOut {...props} path="/sign-out" />

    <ZendeskSignIn {...props} path="/sign-in/zendesk" />
    <AppV2SignIn {...props} path="/sign-in/app-v2" />
    <Discovery {...props} path="/v2/schools/:schoolId/discovery" />
    <InsightsV2 {...props} path="/v2/schools/:schoolId/insights" />
    <Home {...props} path="/v2/schools/:schoolId/home" />
    <Monitoring {...props} path="/v2/schools/:schoolId/monitoring" />
    <Messages {...props} path="/v2/schools/:schoolId/messages" />
    <MessagesShow {...props} path="/v2/schools/:schoolId/messages/:studentId" />
    <ManageSchoolV2 {...props} path="/v2/schools/:schoolId/manage" />
    <ManageSchoolV2 {...props} path="/v2/schools/:schoolId/manage/:resourceType" />
    <Block {...props} path="/blocked" />
    <EducatorDashboard {...props} path="/dashboard" />
    <ManageSchool {...props} path="/manage-school" />
    <Classroom {...props} path="/manage-school/classrooms/:classroomId" />
    <AddEducator {...props} path="/manage-school/classrooms/:classroomId/educators/add" />
    <AddStudents {...props} path="/manage-school/classrooms/:classroomId/students/add" />
    <CreateClassrooms {...props} path="/manage-school/classrooms/create" />
    <Vacations {...props} path="/manage-school/vacations" />
    <NewSchoolYearWelcome {...props} path="/manage-school/new-school-year/welcome" />
    <NewSchoolYearWelcomeBack {...props} path="/manage-school/new-school-year/welcome-back" />
    <NewSchoolYearWorkingOnIt {...props} path="/manage-school/new-school-year/working-on-it" />
    <NewSchoolYearSetUp {...props} path="/manage-school/new-school-year/new-school/set-up" />
    <ReturningSchoolNewSchoolYearSetUp
      {...props}
      path="/manage-school/new-school-year/returning-school/set-up"
    />
    <InsightsSchoolNewSchoolYearSetUp
      {...props}
      path="/manage-school/new-school-year/insights-school/set-up"
    />
    <NewSchoolYearChooseSchool {...props} path="/manage-school/new-school-year/choose-school" />
    <RedirectFromWantsToTalkToAdultEmail
      {...props}
      path="/redirect-to-student-dashboard/:studentId"
    />
    <Redirect from="/" path="/" to="/dashboard" noThrow />
    <StudentDashboard {...props} path="/check-in-history/:studentId" />
    <StudentMessages {...props} path="/student/messages" />
  </Router>
)

export default AppRouter
