import React, { useEffect, useState } from 'react'
import { Modal } from '../components/Modal'
import { Button, Row, SubTitle } from '../components/styled'
import {
  Container,
  InputContainer,
  Label,
  StyledInput,
} from 'auth/signUpFlow/components/Input/style'
import { useCroods } from 'croods'
import { error, success } from 'components/Toast'
import Loading from 'components/Loading'

export const ApproveSchoolModal = ({ school, open, setOpen }: any) => {
  const [name, setName] = useState(school?.name)
  const [street, setStreet] = useState(school?.street)
  const [city, setCity] = useState(school?.city)
  const [state, setState] = useState(school?.state)
  const [postalCode, setPostalCode] = useState(school?.postalCode)
  const [countryCode, setCountryCode] = useState(school?.countryCode)
  const initialText =
    `Hi ${school.educator.name.split(' ')[0]},\n` +
    `Your school "${name}" ("${state}") has been reviewed and approved.\n` +
    'We are so excited for you to join the Closegap family :)\n' +
    'Log in to Closegap to add students and staff!\n' +
    'Thank you!\n'
  const [emailText, setEmailText] = useState(initialText)

  useEffect(() => {
    setEmailText(initialText)
  }, [initialText, name, state])

  const [{ saving }, { save }] = useCroods({
    name: 'admin-approve-school',
    customPath: `admin/approve_school/${school.schoolId}`,
    afterSuccess: () => {
      success('School approved successfully')
      setOpen(false)
    },
    afterFailure: ({ message }) => {
      error(message)
    },
  })

  function approveSchool() {
    save({ method: 'PUT' })({
      name,
      street,
      city,
      state,
      postalCode,
      countryCode,
      emailText,
      email: school.educator.email,
    })
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Row>
        <div>
          <SubTitle>Review school information</SubTitle>

          <Container>
            <InputContainer>
              <StyledInput
                name="name"
                invalid={false}
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Label invalid={false}>School name *</Label>
            </InputContainer>
          </Container>
          <Container>
            <InputContainer>
              <StyledInput
                name="street"
                invalid={false}
                type="text"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
              />
              <Label invalid={false}>Street *</Label>
            </InputContainer>
          </Container>
          <Container>
            <InputContainer>
              <StyledInput
                name="city"
                invalid={false}
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
              />
              <Label invalid={false}>City *</Label>
            </InputContainer>
          </Container>
          <Container>
            <InputContainer>
              <StyledInput
                name="state"
                invalid={false}
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
              />
              <Label invalid={false}>State *</Label>
            </InputContainer>
          </Container>
          <Container>
            <InputContainer>
              <StyledInput
                name="postalCode"
                invalid={false}
                type="text"
                value={postalCode}
                onChange={(e) => setPostalCode(e.target.value)}
              />
              <Label invalid={false}>Postal code *</Label>
            </InputContainer>
          </Container>

          <Container>
            <InputContainer>
              <StyledInput
                name="country_code"
                invalid={false}
                type="text"
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
              />
              <Label invalid={false}>Country Code *</Label>
            </InputContainer>
          </Container>
        </div>

        <div>
          <SubTitle>Notify {school.educator.name} about the school approval</SubTitle>
          <textarea
            style={{
              padding: '0.5rem',
              margin: '0.5rem 0',
              color: '#f6f6f6',
              background: '#404040',
              borderRadius: '0.5rem',
              width: '100%',
            }}
            rows={10}
            name="emailText"
            value={emailText}
            onChange={(e) => setEmailText(e.target.value)}
          />
        </div>
      </Row>

      <Button disabled={saving} variant="edit" onClick={approveSchool}>
        {saving ? <Loading /> : 'UPDATE / APPROVE SCHOOL'}
      </Button>
    </Modal>
  )
}
