import { CadetImage, Container } from 'auth/signUpFlow/SignUpStudent/style'
import { Title } from 'auth/signUpFlow/components/styled'
import HelperCadet from 'Educators/ManageSchool/NewSchoolYear/Components/helperCadet'
import { Layout } from 'auth/signUpFlow/components/Layout'
import validateUserFields from 'auth/validateUserFields'
import PageTitle from 'components/PageTitle'
import { useCroods } from 'croods'
import React from 'react'
import { useScreenSize } from 'screenSize'
// @ts-ignore
import { Helmet } from 'react-helmet'
import Error from 'components/form/Error'
import CircularProgress from '@material-ui/core/CircularProgress'

function submitJWT(token: string) {
  const queryString = new URLSearchParams(window.location.search)
  const returnTo = queryString.get('return_to')
  const formUrl = `${process.env.REACT_APP_API_URL}/app/sign_in_with_jwt?return_to=${
    returnTo ? encodeURIComponent(returnTo) : ''
  }`
  const form = document.createElement('form')
  form.method = 'POST'
  form.action = formUrl

  const hiddenField = document.createElement('input')
  hiddenField.type = 'hidden'
  hiddenField.name = 'jwt'
  hiddenField.value = token
  form.appendChild(hiddenField)

  document.body.appendChild(form)
  form.submit()
}

const AppV2SignIn = () => {
  const { isMobile } = useScreenSize()
  const [{ listError }] = useCroods({
    name: 'todos',
    path: '/app_v2/generate_jwt',
    fetchOnMount: true,
    afterSuccess: ({ data }) => {
      submitJWT(data.token)
    },
  })
  const title = listError ? 'Error Authenticating With App V2' : ''
  return (
    <Layout>
      {isMobile ? null : <CadetImage />}
      <PageTitle text="App V2 Sign-in &mdash; Closegap" />
      <Helmet>
        <meta
          name="description"
          content="Authenticating with App V2. Please wait while we redirect you."
        />
      </Helmet>
      <Container>
        <Title style={{ margin: '3rem 0' }}>{title}</Title>
        {listError ? (
          <Error>{listError}</Error>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <CircularProgress color="secondary" size="100px" />
          </div>
        )}
      </Container>
      <HelperCadet />
    </Layout>
  )
}

export default validateUserFields(AppV2SignIn)
