import React, { useEffect } from 'react'

import Students from 'Educators/students/list/panel/Content'
import StudentSkeleton from 'Educators/ManageSchool/student-skeleton'
import { useSchools } from 'Educators/SchoolsContext'
import { useCroods } from 'croods'

type Props = { classroomId: number }
const StudentsViewer = ({ classroomId }: Props) => {
  const { schoolId } = useSchools()

  const [{ list: students, fetchingList: fetchingStudents }, { fetch: fetchStudents }] = useCroods({
    name: 'students',
    stateId: `class-${classroomId}`,
    path: `/classrooms/${classroomId}/students`,
  })

  useEffect(() => {
    schoolId && fetchStudents({})()
  }, [schoolId]) // eslint-disable-line

  if (fetchingStudents) return <StudentSkeleton />

  return (
    <Students
      isManageStudents
      items={students}
      childReference="Student"
      showAvatar={false}
      studentItemProps={{ showAction: false }}
    />
  )
}

export default StudentsViewer
