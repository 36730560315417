import clsx from 'clsx'
import React from 'react'

type Props = {
    className?: string
}

const FirstCheckInText: React.FC<Props> = ({ className }) => (
    <p className={clsx("text-center mb-10 text-lg max-w-[533px] text-[#616161] font-medium", className)}>
        Before we get started, I want to let you know that your teacher, other staff
        at your school, and your parents may see the information you share here.
    </p>
)

export default FirstCheckInText
