import React from 'react'
// @ts-ignore
import { useSignUp } from 'dmcroods-auth'
import { navigate } from '@reach/router'
import { useForm, SubmitHandler, useWatch, Controller } from 'react-hook-form'
import { useMixpanel } from 'mixpanel'
import { honorificTitles } from 'honorificTitles'
import { ErrorMessage } from 'components/Dropdown/styles'
import { Dropdown } from 'components/Dropdown'
import { Loader } from 'components/Loader'
import { AgreementMessage } from '../components/AgreementMessage'
import { Container, InputContainer, Label, StyledInput } from '../components/Input/style'
import { Button } from '../components/styled'
import { LoadingContainer } from '../SignUpSchoolStaff/style'
import { EmailInput, ValuesProps } from '../components/Input'

const INITIAL_STATE = {
  role: 'clinician-therapist',
  honorificTitle: null,
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  passwordConfirmation: '',
}

export const SignUpForm = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
  } = useForm<ValuesProps>({
    defaultValues: INITIAL_STATE,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const mixpanel = useMixpanel()

  const [{ signingUp, error }, signUp] = useSignUp({
    afterSuccess: ({ data: { data } }: any) => {
      mixpanel.alias(data.email)
      mixpanel.people.set({
        $email: data.email,
      })
      navigate('/welcome')
    },
  })

  const firstName = useWatch({ name: 'firstName', control })
  const lastName = useWatch({ name: 'lastName', control })
  const email = useWatch({ name: 'email', control })
  const password = useWatch({ name: 'password', control })
  const passwordConfirmation = useWatch({ name: 'passwordConfirmation', control })

  const onSubmit: SubmitHandler<ValuesProps> = (data) => signUp(data)

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ display: 'flex', flexDirection: 'column' }}>
      <Controller
        name="honorificTitle"
        control={control}
        render={({ field: { onChange, value } }) => (
          <Dropdown onChange={onChange} value={value} label="Ms." options={honorificTitles} />
        )}
      />

      <Container>
        <InputContainer>
          <StyledInput
            invalid={Boolean(errors.firstName)}
            type="text"
            placeholder="First name *"
            {...register('firstName', {
              required: { value: true, message: 'First name is required' },
              pattern: { value: /^[a-zA-Z'’]+$/i, message: 'Invalid first name' },
            })}
          />
          {Boolean(firstName) && <Label invalid={Boolean(errors.firstName)}>First name *</Label>}
        </InputContainer>
        {Boolean(errors.firstName) ? (
          <ErrorMessage>{errors.firstName?.message}</ErrorMessage>
        ) : null}
      </Container>

      <Container>
        <InputContainer>
          <StyledInput
            invalid={!!errors.lastName}
            type="text"
            placeholder="Last name *"
            {...register('lastName', {
              required: { value: true, message: 'Last name is required' },
              pattern: {
                value: /^[a-zA-Z\s'’ãçóé]+$/i,
                message: 'Invalid last name',
              },
            })}
          />
          {Boolean(lastName) && <Label invalid={Boolean(errors.lastName)}>Last name *</Label>}
        </InputContainer>
        {Boolean(errors.lastName) ? <ErrorMessage>{errors.lastName?.message}</ErrorMessage> : null}
      </Container>

      <EmailInput email={email} errors={errors} register={register} />

      <Container>
        <InputContainer>
          <StyledInput
            invalid={!!errors.password}
            type="password"
            placeholder="Password *"
            {...register('password', {
              required: { value: true, message: 'Password is required' },
              pattern: /^(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
            })}
          />
          {Boolean(password) && <Label invalid={Boolean(errors.password)}>Password *</Label>}
        </InputContainer>
        {Boolean(errors.password) ? (
          <ErrorMessage>
            Must contain at least: one number, one special character, one uppercase and lowercase
            letter, and 8 or more characters
          </ErrorMessage>
        ) : null}
      </Container>

      <Container>
        <InputContainer>
          <StyledInput
            invalid={!!errors.passwordConfirmation}
            type="password"
            placeholder="Password confirmation*"
            {...register('passwordConfirmation', {
              required: { value: true, message: 'Password confirmation is required' },
              validate: (value) => value === password || 'The passwords do not match',
            })}
          />
          {Boolean(passwordConfirmation) && (
            <Label invalid={Boolean(errors.passwordConfirmation)}>Password confirmation *</Label>
          )}
        </InputContainer>
        {Boolean(errors.passwordConfirmation) ? (
          <ErrorMessage>{errors.passwordConfirmation?.message}</ErrorMessage>
        ) : null}
      </Container>

      <AgreementMessage />
      {signingUp ? (
        <LoadingContainer>
          We are preparing your account...
          <Loader />
        </LoadingContainer>
      ) : null}
      {!!error ? <h1 style={{ color: 'red' }}>{error}</h1> : null}

      <Button type="submit" disabled={Object.keys(dirtyFields).length === 0 || !isValid}>
        SIGN UP
      </Button>
    </form>
  )
}
