import React from 'react'
import styled from 'styled-components'
import { VscTriangleRight, VscTriangleDown } from 'react-icons/vsc'

type Variant = 'red' | 'yellow' | 'green' | 'blue' | 'gray' | 'other'

type HeaderProps = {
  title: string
  variant?: Variant
  showFollowUp?: boolean
  open?: boolean
  setOpen?: (value: boolean) => void
}

const colors = {
  red: 'rgb(234,71,59)',
  yellow: 'rgb(249,178,51)',
  green: 'rgb(50,173,69)',
  blue: 'rgb(25,122,207)',
  gray: 'rgba(229,229,229,1)',
  other: 'rgba(255,123,102,1)',
}
const StyledHeader = styled.div<{ bg: Variant; isClickable: boolean }>`
  display: flex;
  padding: 16px;
  align-items: center;
  justify-content: space-between;
  background: ${({ bg }) => colors[bg]};
  font-family: Roboto;
  color: #ffffff;
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  transition: all ease 0.25s;
  :hover {
    filter: ${({ isClickable }) => (isClickable ? 'brightness(0.95)' : 'none')};
  }
`
const Title = styled.span`
  font-size: 0.9rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.4px;
`
const HeaderAction = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
`

export const Header = ({
  title,
  variant = 'other',
  showFollowUp,
  open,
  setOpen = () => null,
}: HeaderProps) => {
  const showCollapseButton = open !== undefined
  return (
    <StyledHeader bg={variant} isClickable={showCollapseButton} onClick={() => setOpen(!open)}>
      {showCollapseButton ? (
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          {open ? <VscTriangleDown size={20} /> : <VscTriangleRight size={20} />}
          <Title>{title}</Title>
        </div>
      ) : (
        <Title>{title}</Title>
      )}
      {showFollowUp && <HeaderAction>Follow Up</HeaderAction>}
    </StyledHeader>
  )
}
