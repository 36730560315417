import React from 'react'
import { CircularProgress } from '@material-ui/core'

export default () => (
  <div
    style={{
      height: '100vh',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size="60px" color="primary" />
  </div>
)
