import React from 'react'
import map from 'lodash/map'

import SecondaryEmotionButton from 'checkIn/components/teen/SecondaryEmotionButton'
import { getLocalStorageCheckIn } from 'students/helper'
import Screen from 'checkIn/components/teen/Screen'
import { setQSData } from 'QueryStringController'

import { RiEmotionHappyLine } from 'react-icons/ri'
import { RiEmotionUnhappyLine } from 'react-icons/ri'
import { RiEmotionNormalLine } from 'react-icons/ri'

const HowAreYouFeelingNow = ({ nextFunction }) => {
  const color = getLocalStorageCheckIn('color') || '#00ddc1'
  const buttons = [
    {
      icon: <RiEmotionHappyLine size={50} color={color} className="mr-4" />,
      buttonText: 'Better!',
      value: 'better',
    },
    {
      icon: <RiEmotionNormalLine size={50} color={color} className="mr-4" />,
      buttonText: 'About the same',
      value: 'same',
    },
    {
      icon: <RiEmotionUnhappyLine size={50} color={color} className="mr-4" />,
      buttonText: 'Worse',
      value: 'worse',
    },
  ]

  function onClick(value) {
    setQSData({ feelingNow: value })
    nextFunction()
  }

  return (
    <Screen
      title={'How are you feeling now?'}
      style={{ justifyContent: 'center' }}
      contentWrapperStyle={{ marginTop: 0, height: '100vh' }}
    >
      <div className="mt-10">
        {map(buttons, ({ value, buttonText, icon }) => (
          <div key={value} className="flex mb-10">
            {icon}
            <SecondaryEmotionButton onClick={() => onClick(value)}>
              <span className="font-semibold">{buttonText}</span>
            </SecondaryEmotionButton>
          </div>
        ))}
      </div>
    </Screen>
  )
}

export default HowAreYouFeelingNow
