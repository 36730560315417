import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import { FUEL_CONCLUSION_PAGE } from 'checkIn/audios'

type Props = {
  nextFunction: (params: string) => Promise<void>
  label: string
  bgImage: string
  audioKey: keyof typeof FUEL_CONCLUSION_PAGE
}

const NonReader = ({ nextFunction, label, audioKey, bgImage }: Props) => {
  return (
    <Screen
      label={label}
      title="Help me understand better by answering a few questions..."
      audios={{ autoplay: FUEL_CONCLUSION_PAGE[audioKey] }}
    >
      <img className="h-[35vh] mt-28" src={bgImage} alt="Sad cadet" />

      <div className="absolute bottom-0 w-full">
        <BackAndNextButtons
          nextButtonText="Sure!"
          audios={{
            buttonNext: FUEL_CONCLUSION_PAGE.buttonNonComfyNext,
          }}
          nextButtonOnClick={() => nextFunction(window.location.search)}
        />
      </div>
    </Screen>
  )
}

export default NonReader
