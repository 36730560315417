import React from 'react'
import { useCroods } from 'croods'
import Form from 'Educators/students/list/Comments/Form'

const NewComment = ({ checkInId, currentUser }) => {
  const [{ saveError, saving }, { save }] = useCroods({
    name: 'checkInComments',
    stateId: checkInId,
  })
  return (
    <Form
      onSubmit={save()}
      submitting={saving}
      submitError={saveError}
      checkInId={checkInId}
      currentUser={currentUser}
    />
  )
}

export default NewComment
