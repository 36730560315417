import React, { useState } from 'react'
import type { RouteComponent } from 'types'
import Screen from '../../components/screen'

import { navigateWithParams } from 'utils'
import { NextButton } from '../../components/NextButton'
import { setLocalStorageCheckIn } from 'students/helper'

const ImpactQ5: RouteComponent = () => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null)

  const onClick = (number: string) => {
    setLocalStorageCheckIn('impactQ5', number)
    setSelectedOption(number)
  }
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl w-full text-center text-gray-100 text-2xl sm:text-[32px] font-normal font-['Roboto'] leading-loose mb-8 sm:mb-16">
        How many adults at school do you feel close to?
      </div>
      <div className="flex flex-col gap-6 mb-8 sm:mb-20 max-w-96 w-full">
        {['None', '1 adult', '2 or 3 adults', '4 or more adults'].map((number: string) => {
          return (
            <button
              key={number}
              className={`text-white h-14 max-w-96 w-full flex items-center  justify-center border-2 font-bold tracking-button uppercase text-xl leading-5  bg-cover bg-no-repeat bg-center rounded-full shadow-sm hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0 data-[selected=true]:bg-gray-100 data-[selected=true]:text-zinc-600 data-[selected=true]:[text-shadow:none]`}
              data-selected={number === selectedOption}
              onClick={() => onClick(number)}
            >
              {number}
            </button>
          )
        })}
      </div>
      <NextButton
        disabled={!selectedOption}
        handleNextFunction={() => navigateWithParams('./impactQ6')}
      ></NextButton>
    </Screen>
  )
}

export default ImpactQ5
