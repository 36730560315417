import React, { useEffect } from 'react'
import { Grid, withStyles } from '@material-ui/core'
import { useWindowDimensions } from '@seasonedsoftware/utils'
import { Fetch } from 'croods'
import withMixpanel from 'mixpanel/withMixpanel'

import Title from 'components/FormTitle'
import CheckinHistory from 'Educators/students/edit/EditTabHistory'
import validateUserFields from 'auth/validateUserFields'
import { navigate } from '@reach/router'
import { isSchoolOnVacation, getPathBasedOnGrade } from 'auth/signIn/vacationHelper'
import { isOlderTeen } from 'students/helper'
import { isCheckInAvailable } from 'checkIn/helper'
import CheckInButton from './CheckInButton'
import FloatingCheckInButton from './FloatingCheckInButton'
import CadetArea from './CadetArea'
import Screen from './Screen'
import { featureEnabled } from 'featureFlags'
import ProfileHeader from './ProfileHeader'

const StudentDashboard = withMixpanel(({ mixpanel, ...props }) => {
  const { currentUser, studentId } = props

  const redirectInVacationCase = () => {
    const onVacation = isSchoolOnVacation(currentUser?.school)
    const olderTeen = isOlderTeen(currentUser?.student?.grade)
    if (!!currentUser?.student && onVacation && !olderTeen) {
      const path = getPathBasedOnGrade(currentUser.student)
      navigate(path)
    } else {
      mixpanel.track('View student profile', { studentId })
    }
  }

  useEffect(() => {
    redirectInVacationCase()
  }, []) // eslint-disable-line

  return (
    currentUser &&
    studentId && (
      <Screen {...props}>
        <Fetch
          cache
          name="students"
          id={studentId}
          render={(student) => (
            <>
              {isCheckInAvailable(currentUser.role, currentUser?.student?.grade) && <FloatingCheckInButton student={student} /> }

              <CadetArea name={student.firstName} />
              <Center>
                {featureEnabled(currentUser, 'standalone_activities') ? 
                  <ProfileHeader student={student} currentUser={currentUser} /> : <Headline student={student} />
                }
                
                <CheckinHistory {...props} student={student} studentId={studentId} />
              </Center>
            </>
          )}
        />
      </Screen>
    )
  )
})

const styles = (theme) => ({
  buttonContainer: {
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(1),
    },
  },
  container: {
    maxWidth: 700,
    [theme.breakpoints.only('xs')]: {
      justifyContent: 'center',
    },
  },
})

const Headline = withStyles(styles)(({ student, classes }) => {
  const { width } = useWindowDimensions()
  const isDesktop = width >= 600

  return (
    <Grid container item xs={12} justifyContent="center">
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        className={classes.container}
      >
        {isDesktop && (
          <Grid item>
            <Title>My Profile</Title>
          </Grid>
        )}
        <Grid item className={classes.buttonContainer}>
        {isCheckInAvailable('student', student.grade) &&  <CheckInButton student={student} /> }
        </Grid>
      </Grid>
    </Grid>
  )
})

const Center = ({ children }) => (
  <Grid
    container
    item
    xs={12}
    justifyContent="center"
    style={{ backgroundColor: 'white', paddingBottom: '50px' }}
  >
    <Grid item xs={11} sm={8}>
      {children}
    </Grid>
  </Grid>
)

export default validateUserFields(StudentDashboard)
