import styled from 'styled-components'
import { MdCheckCircleOutline, MdErrorOutline } from 'react-icons/md'

export const WarningIcon = styled(MdErrorOutline)`
  font-size: 20px;
  color: #b00013; // colors.error
  margin-left: 0.5rem;
`
export const CheckIcon = styled(MdCheckCircleOutline)`
  font-size: 20px;
  color: #32ad45; // colors.success
  margin-left: 0.5rem;
`
export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1rem;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`
export const FirstArea = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 1rem;
  width: 15%;
  min-width: 70px;
  max-width: 125px;
  color: rgba(0, 0, 0, 0.54);
  font-family: Roboto;
  font-size: 0.8rem;
  @media screen and (max-width: 700px) {
    padding-top: 0;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
    justify-content: space-between;
  }
`
export const SecondArea = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const ThirdArea = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 0 1rem;
`
export const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  cursor: pointer;
`
export const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0.5rem;
  align-items: center;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0;
  }
`
