import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from 'checkIn/steps/ComfortableResult/Teen'
import Kid from 'checkIn/steps/ComfortableResult/Kid'
import NonReader from 'checkIn/steps/ComfortableResult/NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { checkInTrack } from 'students/helper'
import { useMixpanel } from 'mixpanel'

const SCREEN_NAME = 'comfortableResult'

const ComfortableResult: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, 'Check-in: Fuel response', student)
    }
  }, [student]) // eslint-disable-line

  if (student) {
    const flow = FLOWS[student.studentType][SCREEN_NAME]

    switch (student.studentType) {
      case 'teen':
        return <Teen flow={flow} />
      case 'kid':
        return <Kid flow={flow} />
      case 'non-reader':
        return <NonReader flow={flow} />
    }
  }

  return null
}

export default ComfortableResult
