import React, { FC } from 'react'
import styled from 'styled-components'

const StyledEmptyText = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  color: #616161; // theme.colors.carbon
  font-style: normal;
  line-height: normal;
  text-align: center;
  letter-spacing: 0.5px;
  margin-top: 30px;
`

const EmptyText: FC = ({ children }) => <StyledEmptyText>{children}</StyledEmptyText>

export { EmptyText }
