import React from 'react'

import Screen from 'checkIn/components/nonReader/Screen'
import { setQSData } from 'QueryStringController'

import CheckInButtonGroup from 'checkIn/steps/Questions/NonReader/CheckInButtonGroup'
import contentByQuestion from 'checkIn/constants/nonReader/contentByQuestion'
import { QUESTIONS_PAGE } from 'checkIn/audios'
import { kebabCase } from 'lodash'

const NonReader = ({ nextFunction, questionKey, school }) => {
  const question = contentByQuestion[questionKey]
  const { image, audio } = question

  return (
    <Screen
      title={question.title}
      className="h-screen mt-10 sm:mt-0"
      audios={{ autoplay: QUESTIONS_PAGE[kebabCase(questionKey)] }}
    >
      <img src={image.src} alt={image.alt} className="mt-10 mb-0 sm:mb-20" />
      <CheckInButtonGroup
        leftButtonText={question.leftButtonText}
        handleLeftButton={() => {
          setQSData({ [questionKey]: true })
          nextFunction(window.location.search, school)
        }}
        rightButtonText={question.rightButtonText}
        handleRightButton={() => {
          setQSData({ [questionKey]: false })
          nextFunction(window.location.search, school)
        }}
        audio={audio}
      />
    </Screen>
  )
}

export default NonReader
