import React from 'react'
import Screen from 'checkIn/components/teen/Screen'
import { setQSData } from 'QueryStringController'

import CheckInButtonGroup from 'checkIn/steps/Questions/Teen/CheckInButtonGroup'
import contentByQuestion from 'checkIn/constants/teen/contentByQuestion'

const Teen = ({ nextFunction, questionKey, school }) => {
  const question = contentByQuestion[questionKey]

  return (
    <Screen
      title={question.title}
      style={{ justifyContent: 'center' }}
      contentWrapperClassName="h-screen !mt-0"
    >
      <CheckInButtonGroup
        leftButtonText={question.leftButtonText}
        handleLeftButton={() => {
          setQSData({ [questionKey]: true })
          nextFunction(window.location.search, school)
        }}
        rightButtonText={question.rightButtonText}
        handleRightButton={() => {
          setQSData({ [questionKey]: false })
          nextFunction(window.location.search, school)
        }}
      />
    </Screen>
  )
}

export default Teen
