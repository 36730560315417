import React from 'react'
import { Box, LinearProgress, Typography } from '@material-ui/core'
import clsx from 'clsx'
import AT_A_GLANCE from 'atAGlance'

const CardInfo = ({ label, value, className, handleClick }) => (
  <button
    type="button"
    onClick={handleClick}
    className={clsx(
      'card p-4 border-t-8 border-opacity-100 transition duration-200 ease-in-out w-30 text-secondary hover:text-white cursor-pointer text-left',
      className,
    )}
  >
    <h4>{label}</h4>
    <h1 className="text-5xl mt-4">{value}</h1>
  </button>
)

const Progress = ({ label, value }) => (
  <div>
    <h4>{label}</h4>
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress data-testid={label} color="secondary" variant="determinate" value={value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {value}%
        </Typography>
      </Box>
    </Box>
  </div>
)

const AtAGlance = ({
  counts,
  selected = AT_A_GLANCE.ALL.value,
  handleClick,
  countFollowUp = 0,
}) => {
  const percentageCheckInsDone = Math.round(
    ((counts[AT_A_GLANCE.ALL.value] - counts[AT_A_GLANCE.DIDNT_CHECK_IN.value]) * 100) /
      counts[AT_A_GLANCE.ALL.value] || 0,
  )

  const percentageFollowUpsDone = Math.round(
    (countFollowUp * 100) /
      (counts[AT_A_GLANCE.URGENT.value] + counts[AT_A_GLANCE.COULD_BENEFIT.value]) || 0,
  )

  return (
    <div className="card p-10 mt-10 mb-10">
      <div className="flex flex-col lg:flex-row gap-10">
        <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-5">
          <CardInfo
            handleClick={handleClick(AT_A_GLANCE.URGENT.value)}
            label={AT_A_GLANCE.URGENT.label}
            value={counts[AT_A_GLANCE.URGENT.value]}
            className={clsx(
              'border-red hover:bg-red-dark hover:border-red-dark',
              selected === AT_A_GLANCE.URGENT.value && 'bg-red !text-white',
            )}
          />
          <CardInfo
            handleClick={handleClick(AT_A_GLANCE.COULD_BENEFIT.value)}
            label={AT_A_GLANCE.COULD_BENEFIT.label}
            value={counts[AT_A_GLANCE.COULD_BENEFIT.value]}
            className={clsx(
              'border-yellow hover:bg-yellow-dark hover:border-yellow-dark',
              selected === AT_A_GLANCE.COULD_BENEFIT.value && 'bg-yellow !text-white',
            )}
          />
          <CardInfo
            handleClick={handleClick(AT_A_GLANCE.GOOD_TO_GO.value)}
            label={AT_A_GLANCE.GOOD_TO_GO.label}
            value={counts[AT_A_GLANCE.GOOD_TO_GO.value]}
            className={clsx(
              'border-green hover:bg-green-dark hover:border-green-dark',
              selected === AT_A_GLANCE.GOOD_TO_GO.value && 'bg-green !text-white',
            )}
          />
          <CardInfo
            handleClick={handleClick(AT_A_GLANCE.DIDNT_CHECK_IN.value)}
            label={AT_A_GLANCE.DIDNT_CHECK_IN.label}
            value={counts[AT_A_GLANCE.DIDNT_CHECK_IN.value]}
            className={clsx(
              'border-tomato hover:bg-tomato-dark hover:border-tomato-dark',
              selected === AT_A_GLANCE.DIDNT_CHECK_IN.value && 'bg-tomato !text-white',
            )}
          />
          <CardInfo
            handleClick={handleClick(AT_A_GLANCE.ALL.value)}
            label={AT_A_GLANCE.ALL.label}
            value={counts[AT_A_GLANCE.ALL.value]}
            className={clsx(
              'border-purple hover:bg-purple-dark hover:border-purple-dark',
              selected === AT_A_GLANCE.ALL.value && 'bg-purple !text-white',
            )}
          />
        </div>

        <div className="flex flex-col flex-auto justify-between h-28">
          <Progress label="Check-ins done" value={percentageCheckInsDone} />
          <Progress label="Follow-ups done" value={percentageFollowUpsDone} />
        </div>
      </div>
    </div>
  )
}

export default AtAGlance
