import React from 'react'
import { Container, InputContainer, StyledInput, Label, ErrorMessage } from './style'
import { FieldErrorsImpl, UseFormRegister } from 'react-hook-form'

export interface ValuesProps {
  role: string | null
  honorificTitle: string | null
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirmation: string
}

interface InputProps {
  label?: string
  placeholder?: string
  email: string
  errors: Partial<FieldErrorsImpl<{ email: string }>>
  register: UseFormRegister<ValuesProps>
}

export const EmailInput = ({
  label = 'Username *',
  placeholder = 'Username *',
  email,
  errors,
  register,
}: InputProps) => {
  return (
    <Container>
      <InputContainer>
        <StyledInput
          invalid={Boolean(errors.email)}
          // type="email"
          placeholder={placeholder}
          {...register('email', {
            required: { value: true, message: 'Username is required' },
            // pattern: {
            //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
            //   message: 'Invalid email',
            // },
          })}
        />
        {Boolean(email) ? <Label invalid={Boolean(errors.email)}>{label}</Label> : null}
      </InputContainer>
      {Boolean(errors.email) ? <ErrorMessage>{errors.email?.message}</ErrorMessage> : null}
    </Container>
  )
}
