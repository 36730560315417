import React from 'react'
import styled from 'styled-components'
import { Comments } from 'Educators/students/list/Comments'
import { CheckIn, Student, User } from 'types'
import moment from 'moment'
import { listStudentNeeds } from '../helper'
import { find, inRange } from 'lodash'
import { TRIAGE_GROUPS } from 'Educators/triageGroups'
import FollowUpAction from 'components/FollowUpAction'
import { isEducator, isParent } from 'students/helper'
import { FUELV2 } from 'fuel'

type Props = {
  firstItem: boolean
  student: Student
  item: CheckIn
  currentUser: User
}

const Container = styled.div`
  margin: 20px 24px;
`

const CheckInTime = styled.p`
  color: #8f8698;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 166%;
  letter-spacing: 0.4px;
  margin-bottom: 8px;
`

const DetailsContainer = styled.div<{ borderColor: string }>`
  border-left: 8px solid ${(p) => p.borderColor};
  padding: 16px;
`

const EmotionsAndFollowUpContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const EmotionsContainer = styled.div`
  display: flex;
  align-items: center;
`

const PrimaryEmotion = styled.p`
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 900;
  line-height: 32px;
  text-transform: capitalize;
`

const SecondaryEmotion = styled.p`
  color: rgba(0, 0, 0, 0.8);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  margin-left: 8px;
`

const BodySensations = styled.div`
  display: flex;
  align-items: center;
`

const BodySensation = styled.p`
  margin-right: 9px;
  color: #757575;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
  &::first-letter {
    text-transform: capitalize;
  }
`

const Needs = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
  > * {
    margin-right: 8px;
  }
`

const Need = styled.p`
  color: #b00013;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.1px;
`

const WantsToTalk = styled.p`
  color: #fff;
  font-feature-settings: 'clig' off, 'liga' off;
  /* subtitle2 */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 157%; /* 22px */
  letter-spacing: 0.1px;
  background-color: #b00013;
  border-radius: 11px;
  padding: 0px 11px;
`

const Details = styled.div`
  > :first-child {
    margin-top: 16px;
  }
`

const Detail = styled.p`
  color: #757575;
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.15px;
`

const DetailEm = styled.span`
  color: rgba(0, 0, 0, 0.87);
  font-feature-settings: 'clig' off, 'liga' off;

  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0.1px;
  margin-left: 8px;
  &::first-letter {
    text-transform: capitalize;
  }
`

const CommentsContainer = styled.div`
  margin-top: 16px;
`

enum Colors {
  green = 'rgb(50,173,69)',
  yellow = 'rgb(249,178,51)',
  red = 'rgb(234,71,59)',
}

const getFuelDisplayText = (text: string): string => {
  return find(FUELV2, (fuel) => fuel.value === text)?.label || 'error'
}

const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

function listStudentNeedsV2(checkIn: CheckIn, currentUser: User): string[] {
  const base = checkIn.talkToAdult ? ['Wants to Talk'] : []
  const needs = checkIn.needs || []
  const filtered = needs.filter((message) => !(message === 'Home Trouble' && isParent(currentUser)))
  const formatted = filtered.map((need) => capitalize(need))
  return base.concat(formatted)
}

export const CheckInDetails = ({ item, currentUser, firstItem, student }: Props) => {
  const needs =
    !!item.needs && item.needs?.length > 0
      ? listStudentNeedsV2(item, currentUser)
      : listStudentNeeds({ checkIn: item, currentUser })
  const color =
    find(TRIAGE_GROUPS, (triage) =>
      inRange(item.helpScore ?? 0, triage.lowerBound, triage.upperBound + 1),
    )?.color || 'green'

  return (
    <Container>
      <CheckInTime>{moment(item.createdAt).format('LT')} - CHECK-IN</CheckInTime>
      <DetailsContainer borderColor={Colors[color as keyof typeof Colors]}>
        <EmotionsAndFollowUpContainer>
          <EmotionsContainer>
            <PrimaryEmotion>{item.feeling}</PrimaryEmotion>
            {item.secondaryEmotions.map((secondary) => (
              <SecondaryEmotion key={secondary}>{secondary}</SecondaryEmotion>
            ))}
          </EmotionsContainer>
          {isEducator(currentUser.role) && <FollowUpAction item={item} currentUser={currentUser} />}
        </EmotionsAndFollowUpContainer>

        <BodySensations>
          {item.bodySensations.map((sensation) => (
            <BodySensation key={sensation}>{sensation.split('-').join(' ')}</BodySensation>
          ))}
        </BodySensations>
        {needs?.length > 0 && (
          <Needs>
            {needs.map((need) =>
              need === 'Wants to Talk' ? (
                <WantsToTalk key={need}>{need}</WantsToTalk>
              ) : (
                <Need key={need}>{need}</Need>
              ),
            )}
          </Needs>
        )}
        <Details>
          {!!item.feelingIntensity && (
            <Detail>
              Emotion intensity <DetailEm>{String(item.feelingIntensity)}</DetailEm>
            </Detail>
          )}
          {!!item.fuel && (
            <Detail>
              Energy level <DetailEm>{capitalize(getFuelDisplayText(item.fuel))}</DetailEm>
            </Detail>
          )}
          {!!item.intervention && (
            <Detail>
              Activity <DetailEm>{capitalize(item.intervention.split('-').join(' '))}</DetailEm>
            </Detail>
          )}
          {!!item.feelingNow && (
            <Detail>
              After check-in <DetailEm>{capitalize(item.feelingNow)}</DetailEm>
            </Detail>
          )}
        </Details>
        <CommentsContainer>
          <Comments
            initialStateOpen={firstItem}
            student={student}
            currentUser={currentUser}
            checkIn={item}
          />
        </CommentsContainer>
      </DetailsContainer>
    </Container>
  )
}
