import React, { useState, useEffect } from 'react'
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Typography,
} from '@material-ui/core'
import map from 'lodash/map'
import isEmpty from 'lodash/isEmpty'
import isArray from 'lodash/isArray'
import some from 'lodash/some'
import { useCroods } from 'croods'
import { useCurrentUser } from 'dmcroods-auth'
import { useFlash } from 'seasoned-flash'
import { featureEnabled } from 'featureFlags'
import {
  emotionOptions,
  emotionOptionsV2,
  emotionOptionsV3,
  energyLevelOptions,
  nonReaderEnergyLevelOptions,
  followUpQuestionOptions,
  followUpQuestionOptionsV2,
} from 'Educators/students/edit/alerts/options'

import { isTeenager, getStudentType } from 'students/helper'
import Error from 'components/form/Error'
import withMixpanel from 'mixpanel/withMixpanel'
import AlertsList from './alerts/List'
import KINDS from 'constants/kinds'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
  content: {
    margin: theme.spacing(3, 0, 4, 0),
  },
  button: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))

const frequencies = [
  {
    value: 1,
    label: 'One time',
  },
  {
    value: 2,
    label: 'Two times in a row',
  },
  {
    value: 3,
    label: 'Three times or more',
  },
]

const getOptionsV1 = (kind, studentType) => {
  if (kind === 'energy_level') return energyLevelOptions
  if (kind === 'emotion') return emotionOptions[studentType]
  if (kind === 'follow_up_question') return followUpQuestionOptions[studentType]
  return []
}

const getOptionsV2 = (kind, studentType) => {
  if (kind === 'energy_level') return energyLevelOptions
  if (kind === 'emotion') return emotionOptionsV2[studentType]
  if (kind === 'follow_up_question') return followUpQuestionOptionsV2[studentType]
  return []
}

const getOptionsV3 = (kind, studentType) => {
  if (kind === 'energy_level') return studentType === 'non-reader' ? nonReaderEnergyLevelOptions : energyLevelOptions
  if (kind === 'emotion') return emotionOptionsV3[studentType]
  if (kind === 'follow_up_question') return followUpQuestionOptionsV2[studentType]
  return []  
}

const Option = ({ ariaLabel, inputLabel, data, value, handleChange, disabled = false }) => (
  <FormControl style={{ width: '100%' }} disabled={disabled}>
    <InputLabel>{inputLabel}</InputLabel>
    <Select
      inputProps={{
        'aria-label': ariaLabel,
      }}
      value={value}
      onChange={(event) => handleChange(event?.target?.value)}
    >
      {map(data, ({ value: option, label }) => (
        <MenuItem key={option} value={option}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
)

const Options = ({ studentType, addValues }) => {
  const [topic, setTopic] = useState('')
  const [option, setOption] = useState('')
  const [frequency, setFrequency] = useState('')
  const [{ currentUser }] = useCurrentUser()

  const disabled = isEmpty(topic)

  const getOptions = () => {
    if (featureEnabled(currentUser, 'nine_to_twelve_check_in') && featureEnabled(currentUser, 'pk_2_check_in_2024')) return getOptionsV3(topic, studentType)
    else if (featureEnabled(currentUser, 'nine_to_twelve_check_in')) return getOptionsV2(topic, studentType)
    else return getOptionsV1(topic, studentType)
  }

  const dataOptions = getOptions() //find(topics, ['value', topic])?.options ?? []

  useEffect(() => {
    addValues({ frequency, option, topic })
  }, [frequency, option, topic]) // eslint-disable-line

  return (
    <Grid container spacing={3}>
      <Grid item xs>
        <Option
          ariaLabel="select-topic"
          value={topic}
          inputLabel="Select a topic"
          handleChange={(value) => {
            setTopic(value)
            setOption('')
          }}
          data={KINDS}
        />
      </Grid>
      <Grid item xs>
        <Option
          ariaLabel="select-option"
          value={option}
          inputLabel="Select an option"
          handleChange={setOption}
          data={isArray(dataOptions) ? dataOptions : dataOptions[studentType]}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs>
        <Option
          ariaLabel="select-frequency"
          value={frequency}
          inputLabel="Choose frequency"
          handleChange={setFrequency}
          disabled={disabled}
          data={frequencies}
        />
      </Grid>
    </Grid>
  )
}

const EditTabAlerts = ({ student, mixpanel }) => {
  const [{ currentUser }] = useCurrentUser()
  const classes = useStyles()
  const { success } = useFlash()
  const [values, setValues] = useState({})

  const { id, grade } = student

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'alerts',
    stateId: id,
    path: `/students/${id}/alerts`,
    afterSuccess: () => success('Your alert has been set successfully!'),
  })

  const handleSetAlert = () => {
    mixpanel.track('Set alert clicked', {
      studentId: id,
      topic: values.topic,
      option: values.option,
      frequency: values.frequency,
    })
    save()({
      kind: values.topic,
      condition: values.option,
      frequency: values.frequency,
    })
  }
  const studentType = featureEnabled(currentUser, 'nine_to_twelve_check_in')
    ? getStudentType(grade)
    : isTeenager(grade)
    ? 'teen'
    : 'kid'
  return (
    <>
      <Paper className={classes.paper}>
        <Typography
          variant="subtitle1"
          color="textSecondary"
          style={{ textTransform: 'uppercase' }}
        >
          Create a new alert
        </Typography>
        <div className={classes.content}>
          <Options studentType={studentType} addValues={setValues} />
          {saveError && <Error>{saveError}</Error>}
        </div>
        <div className={classes.button}>
          <Button
            variant="contained"
            color="primary"
            disabled={saving || some(values, (item) => !item)}
            onClick={handleSetAlert}
          >
            SET ALERT
          </Button>
        </div>
      </Paper>
      <AlertsList student={student} />
    </>
  )
}

export default withMixpanel(EditTabAlerts)
