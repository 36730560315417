import { XMarkIcon } from '@heroicons/react/24/solid'
import { useCroods } from 'croods'
import React, { useEffect, useState } from 'react'
import { CheckIn, User } from 'types'

type Props = {
  checkIn: CheckIn
  currentUser: User
}

const TriageFeedback = ({ checkIn, currentUser }: Props) => {
  const previouslySaved = checkIn.triageReviewedBy?.includes(currentUser.id)
  const [saved, setSaved] = useState(false)
  const [{ saving }, { save }] = useCroods({
    name: 'triageReview',
    path: `check_ins/${checkIn.id}/triage_reviews`,
  })

  const [triageCorrect, setTriageCorrect] = useState<boolean | null>(null)
  const [userTriageValue, setUserTriageValue] = useState<string | null>(null)
  const [userText, setUserText] = useState<string | undefined>()

  function onSubmit() {
    save({
      afterSuccess: () => {
        setSaved(true)
      },
    })({
      triage_review: {
        correct: triageCorrect,
        userValue: userTriageValue,
        userText: userText,
      },
    })
  }

  useEffect(() => {
    if (triageCorrect === true && !saving && !saved) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triageCorrect])

  if (saved || previouslySaved)
    return (
      <div className="w-full px-10 py-2 bg-purple-300 text-purple-950 flex-col justify-center items-start gap-4 inline-flex">
        <div className="flex gap-2 items-center">
          <p className="text-sm font-bold font-['Roboto'] leading-[21px]">
            Thanks for helping us improve Closegap!
          </p>
        </div>
      </div>
    )
  return (
    <div className="w-full px-10 py-2 bg-[#F9F9FB] flex-col justify-center items-start gap-4 inline-flex">
      <div className="flex gap-2 items-center w-full">
        <p className="mr-4">Was this check-in triaged correctly?</p>
        <button
          onClick={() => setTriageCorrect(false)}
          data-selected={triageCorrect === false}
          className="uppercase px-2.5 py-1 rounded border border-red-500 border-opacity-50 text-red-500 text-[13px] font-medium font-['Roboto'] leading-snug tracking-wide data-[selected=true]:bg-red-500 data-[selected=true]:text-gray-50 "
        >
          No
        </button>
        <button
          onClick={() => {
            setTriageCorrect(true)
          }}
          data-selected={triageCorrect === true}
          className="uppercase px-2.5 py-1 rounded border border-red-500 border-opacity-50 text-red-500 text-[13px] font-medium font-['Roboto'] leading-snug tracking-wide data-[selected=true]:bg-red-500 data-[selected=true]:text-gray-50"
        >
          Yes
        </button>
        {triageCorrect !== null && (
          <button
            onClick={() => {
              setTriageCorrect(null)
              setUserTriageValue(null)
              setUserText(undefined)
            }}
            className="ml-auto"
          >
            <XMarkIcon className="text-zinc-600 h-6 w-6" />
          </button>
        )}
      </div>
      {triageCorrect !== null && triageCorrect === false && (
        <div className="w-full flex flex-col gap-2">
          <div className="flex gap-4 items-center">
            <p className="mr-4">How should it have been triaged?</p>
            {checkIn.helpScore < 61 && (
              <button
                onClick={() => setUserTriageValue('urgent')}
                data-selected={userTriageValue === 'urgent'}
                className="uppercase px-2.5 py-1 rounded border border-red-500 border-opacity-50 text-red-500 text-[13px] font-medium font-['Roboto'] leading-snug tracking-wide data-[selected=true]:bg-red-500 data-[selected=true]:text-gray-50 "
              >
                urgent
              </button>
            )}
            {(checkIn.helpScore < 31 || checkIn.helpScore > 60) && (
              <button
                onClick={() => setUserTriageValue('could benefit')}
                data-selected={userTriageValue === 'could benefit'}
                className="uppercase px-2.5 py-1 rounded border border-amber-400 border-opacity-50 text-amber-400 text-[13px] font-medium font-['Roboto'] leading-snug tracking-wide data-[selected=true]:bg-amber-400 data-[selected=true]:text-gray-50 "
              >
                Could benefit
              </button>
            )}
            {checkIn.helpScore > 30 && (
              <button
                onClick={() => setUserTriageValue('good to go')}
                data-selected={userTriageValue === 'good to go'}
                className="uppercase px-2.5 py-1 rounded border border-green-800 border-opacity-50 text-green-800 text-[13px] font-medium font-['Roboto'] leading-snug tracking-wide data-[selected=true]:bg-green-800 data-[selected=true]:text-gray-50"
              >
                Good to go
              </button>
            )}
          </div>
          <hr className="w-full" />
          <p>Would you like to tell us more?</p>
          <textarea
            className="border border-neutral-400 p-2 rounded-lg"
            placeholder="Write here..."
            value={userText}
            onChange={(e) => setUserText(e.target.value)}
          />
          <div>
            <button
              onClick={() => onSubmit()}
              data-disabled={userTriageValue === null || saving}
              disabled={userTriageValue === null || saving}
              className="uppercase px-2.5 py-1 rounded border text-[13px] font-medium font-['Roboto'] leading-snug tracking-wide bg-red-500 text-gray-50 data-[disabled=true]:bg-red-300 "
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default TriageFeedback
