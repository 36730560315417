import { navigate } from '@reach/router'
import { Layout } from 'auth/signUpFlow/components/Layout'
import { CadetImage, Container } from 'auth/signUpFlow/SignUpStudent/style'
import { Title } from 'auth/signUpFlow/components/styled'
import Error from 'components/form/Error'
import PageTitle from 'components/PageTitle'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser, useSignOut } from 'dmcroods-auth'
import React, { useEffect } from 'react'
import { useScreenSize } from 'screenSize'
import { useSchools } from 'Educators/SchoolsContext'
// @ts-ignore
import { Helmet } from 'react-helmet'
import HelperCadet from 'Educators/ManageSchool/NewSchoolYear/Components/helperCadet'
import { redirectRoute } from 'auth/signIn/SignIn'

export default (props: any) => {
  const { isMobile } = useScreenSize()
  const [, setCurrentUser] = useCurrentUser()
  const search = new URLSearchParams(props.location.search)
  const id_token = search.get('code')

  const { clearSchoolId } = useSchools()

  const [, { resetState }] = useCroods({ name: 'auth', stateId: 'signIn' })

  const [, signOut] = useSignOut({
    afterResponse: () => {
      clearSchoolId()
      localStorage.clear()
      save({ method: 'POST' })({ id_token })
    },
  })

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'edlinklogin',
    path: 'auth/edlink',
    afterSuccess: ({ data }) => {
      setCurrentUser(data)
      navigate(redirectRoute(data), { replace: true })
    },
  })

  useEffect(() => {
    if (!saving) {
      const init = async () => {
        sessionStorage.clear()
        await signOut(() => resetState())
      }
      init()
    }
    // eslint-disable-next-line
  }, [id_token])

  const title = saveError ? 'Error logging in' : 'Logging in to Closegap...'

  return (
    <Layout>
      {isMobile ? null : <CadetImage />}
      <PageTitle text="Sign-in &mdash; Closegap" />
      <Helmet>
        <meta
          name="description"
          content="Welcome back! Closegap is a child-facing emotional wellness platform that captures how kids feel and helps educators respond. We're so glad you're here!"
        />
      </Helmet>
      <Container>
        <Title style={{ margin: '3rem 0' }}>{title}</Title>
        {saveError ? <Error>{saveError}</Error> : null}
      </Container>
      <HelperCadet />
    </Layout>
  )
}
