import React from 'react'
import FLOWS from 'checkIn/flows'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import Teen from './Teen'
import Kid from './Kid'

const SCREEN_NAME = 'impactQ3'

const ImpactQ3: RouteComponent = () => {
  const { student } = useCheckIn()

  if (!student) return null

  if (student.studentType === 'teen')
    return <Teen nextFunction={FLOWS.teen[SCREEN_NAME].nextFunction} />

  if (student.studentType === 'kid')
    return <Kid nextFunction={FLOWS.kid[SCREEN_NAME].nextFunction} />

  return null
}

export default ImpactQ3
