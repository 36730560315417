import * as React from 'react'
import { Link } from '@reach/router'
import clsx from 'clsx'

type LinkProps = { to: string; replace?: boolean | undefined } & Omit<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  'href'
>

type Props = {
  children: React.ReactNode
  paths?: Array<Partial<LinkProps>>
} & React.HTMLAttributes<HTMLDivElement>
const Breadcrumbs = ({ children, paths, ...props }: Props) => {
  const divider = (
    <span
      role="separator"
      className="ml-4 mr-4 text-3xl text-opacity-50 text-purple"
    >
      &rsaquo;
    </span>
  )
  return (
    <div {...props} className={clsx('flex flex-wrap', props.className)}>
      {paths?.map((pathProps, idx) => (
        <React.Fragment key={`breadcrumb-item-${idx}`}>
          <h4 className="text-3xl text-opacity-50 whitespace-nowrap text-purple hover:text-purple-dark">
            {pathProps.to ? (
              <Link {...(pathProps as LinkProps)} />
            ) : (
              <span>{pathProps.children}</span>
            )}
          </h4>
          {divider}
        </React.Fragment>
      ))}
      <h4 className="text-3xl text-purple-dark">{children}</h4>
    </div>
  )
}

export default Breadcrumbs
