import React, { useMemo, useState } from 'react'
import { Panel } from 'Educators/students/list/panel'
import { Header } from 'Educators/students/list/panel/Header'
import { StudentItem } from 'Educators/students/list/emotions/StudentItem'
import { paginate } from 'utils'
import { PaginationBar } from 'components/PaginationBar'

interface Props {
  title: string
  studentsCheckIns: any
  currentUser: any
  fetchListStudents: any
  color: 'red' | 'yellow' | 'green'
  isUrgentEmpty?: boolean
}

export const TriageGroupPanel = ({
  title,
  studentsCheckIns,
  currentUser,
  fetchListStudents,
  color,
  isUrgentEmpty = false,
}: Props) => {
  const panelInitialState = {
    red: true,
    yellow: isUrgentEmpty,
    green: false,
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [open, setOpen] = useState(panelInitialState[color])

  const checkInsPageGroup = useMemo(
    () => paginate(studentsCheckIns, currentPage),
    [currentPage, studentsCheckIns],
  )

  const pages = Math.ceil(studentsCheckIns.length / 3)

  return (
    <Panel>
      <Header title={title} variant={color} showFollowUp open={open} setOpen={setOpen} />

      {open ? (
        <>
          <div>
            {checkInsPageGroup.map((item: any) => (
              <StudentItem
                key={`${item.id}-${item?.latestCheckIn?.id}`}
                student={item}
                currentUser={currentUser}
                fetchListStudents={fetchListStudents}
              />
            ))}
          </div>

          {pages < 2 ? null : (
            <PaginationBar
              color={color}
              onButtonsClick={setCurrentPage}
              currentPage={currentPage}
              pages={pages}
            />
          )}
        </>
      ) : null}
    </Panel>
  )
}
