import React, { useEffect } from 'react'
import { useCroods } from 'croods'
import { navigate } from '@reach/router'
import { useSchools } from 'Educators/SchoolsContext'
import { find, map } from 'lodash'
import styled from 'styled-components'

const SchoolItem = styled.div`
  margin-top: 1rem;
`
const Name = styled.p`
  font-size: 0.9;
  font-weight: 500;
  color: #000000de;
`
const Address = styled.p`
  font-size: 0.8rem;
  color: #808080;
`

const Role = styled.p`
  font-size: 0.9rem;
  color: #87768d;
  font-weight: 500;
`

const SchoolsList = ({ schoolId }) => {
  const { handleSchool } = useSchools()

  const [{ list: listEducatorSchools }, { fetch: fetchEducatorSchools }] = useCroods({
    name: 'educatorSchools',
    afterSuccess: ({ data: schools }) => {
      const educatorSchools = find(schools, ['school.id', schoolId])
      if (educatorSchools) {
        handleSchool(educatorSchools)
        navigate('/manage-school')
      }
    },
  })

  useEffect(() => {
    schoolId && fetchEducatorSchools()()
  }, [schoolId]) // eslint-disable-line

  return map(listEducatorSchools, ({ id, school, role }) => (
    <SchoolItem key={id}>
      <Name>{school.name}</Name>
      <Address>{school.address}</Address>
      <Role>{role === 'viewer' ? 'Classroom Level Access' : 'School Level Access'}</Role>
    </SchoolItem>
  ))
}

export default SchoolsList
