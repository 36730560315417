import React, { useLayoutEffect } from 'react'
import { useCroods } from 'croods'

import Loading from 'components/Loading'
import { getStudentTypeByGrade, isTeen } from 'grades'
import { useActivity } from './activityContext'
import type { RouteComponent } from 'types'
import { navigate } from '@reach/router'
import { isNinthGradeOrOlder } from 'students/helper'

type Props = { studentId?: string }
const CheckIn: RouteComponent<Props> = ({ studentId, children }) => {
  const { student, handleSetStudent } = useActivity()

  const [{ fetchingInfo }] = useCroods({
    fetchOnMount: true,
    name: 'students',
    id: studentId,
    afterSuccess: ({ data }) => {
      handleSetStudent(data)
    },
    after4xx: () => {
      navigate('/sign-out?clear=false')
    },
  })

  useLayoutEffect(() => {
    if (student?.grade) {
      if (isTeen(getStudentTypeByGrade(student.grade))) {
        if (isNinthGradeOrOlder(student.grade)) {
          document.body.style.minHeight = '100vh'
          document.body.style.backgroundImage = `url('/images/moods/nineToTwelve/reflective.png')`
          // @ts-ignore
          document.body.style.backdropFilter = 'blur(100px)'
          document.body.style.backgroundSize = 'cover'
          document.body.style.backgroundRepeat = 'no-repeat'
          document.body.style.backgroundPosition = 'center'
        } else {
          document.body.style.backgroundImage = "url('/images/moods/fiveToEight/neutral.png')"
        }
      } else {
        document.body.style.backgroundImage = "url('/images/moods/threeToFour/default.png')"
      }
    }
    return () => {
      document.body.style.backgroundImage = 'none'
    }
  }, [student])

  if (fetchingInfo || !student) return <Loading />

  return <div>{children}</div>
}

export default CheckIn
