import React, { useEffect } from 'react'

import { checkInTrack } from 'students/helper'
import withMixpanel from 'mixpanel/withMixpanel'
import { Fetch } from 'croods'
import ScreenContent from 'checkIn/steps/PreferredContact/Teen/ScreenContent'
import { getQSData } from 'QueryStringController'

const PreferredContact = withMixpanel(({ studentId, mixpanel, flow }) => {
  const { nextFunction } = flow

  useEffect(() => {
    const qs = getQSData()
    checkInTrack(mixpanel, 'Check-in: Who would you prefer to speak to?', {
      ...qs,
      student: studentId,
    })
  }, []) // eslint-disable-line

  return (
    <Fetch
      cache
      name="adults"
      path={`/adults?student_id=${studentId}`}
      render={(data) => (
        <ScreenContent preferredContacts={data} nextFunction={nextFunction} />
      )}
    />
  )
})

export default PreferredContact
