import {
  HeartFlutters,
  Warm,
  SteadyOrStrong,
  ClearOrAwake,
  Empty,
  StuckOrFrozen,
  StiffOrTense,
  Shakey,
  Achy,
  Agitated,
  Breathless,
  Bubbly,
  Buzzy,
  Churning,
  ConfusedOrFuzzy,
  Dull,
  Electric,
  Energized,
  Floaty,
  Fuzzy,
  HotOrBurning,
  JumbledUp,
  Knotted,
  Light,
  Numb,
  Prickly,
  QuietAndNeutral,
  RelaxedOrAtEase,
  Sweaty,
  Tickley,
  Tingly,
  WarmOrRelaxed,
  Weak,
} from '../fiveToEight/planetList'

const planetList = [
  {
    src: '/images/planets/threeToFour/happy.png',
    alt: 'Happy',
    label: 'Happy',
    feeling: 'happy',
    sensations: [Buzzy, Bubbly, Floaty, Tickley, WarmOrRelaxed, ClearOrAwake],
  },
  {
    src: '/images/planets/threeToFour/hopeful.png',
    alt: 'Hopeful',
    label: 'Hopeful',
    feeling: 'hopeful',
    sensations: [RelaxedOrAtEase, Buzzy, Light, SteadyOrStrong, Warm, ClearOrAwake],
  },
  {
    src: '/images/planets/threeToFour/loved.png',
    alt: 'Loved',
    label: 'Loved',
    feeling: 'loved',
    sensations: [RelaxedOrAtEase, HeartFlutters, Energized, Light, Tingly, Warm],
  },
  {
    src: '/images/planets/threeToFour/excited.png',
    alt: 'Excited',
    label: 'Excited',
    feeling: 'excited',
    sensations: [Buzzy, Bubbly, Electric, Energized, Tingly, Warm],
  },
  {
    src: '/images/planets/threeToFour/calm.png',
    alt: 'Calm',
    label: 'Calm',
    feeling: 'calm',
    sensations: [RelaxedOrAtEase, SteadyOrStrong, Floaty, Light, Warm, ClearOrAwake],
  },
  {
    src: '/images/planets/threeToFour/grateful.png',
    alt: 'Grateful',
    label: 'Grateful',
    feeling: 'grateful',
    sensations: [RelaxedOrAtEase, SteadyOrStrong, Floaty, Light, Warm, ClearOrAwake],
  },
  {
    src: '/images/planets/threeToFour/content.png',
    alt: 'Content',
    label: 'Content',
    feeling: 'content',
    sensations: [RelaxedOrAtEase, Bubbly, Light, Tickley, Warm, ClearOrAwake],
  },
  {
    src: '/images/planets/threeToFour/bored.png',
    alt: 'Bored',
    label: 'Bored',
    feeling: 'bored',
    sensations: [QuietAndNeutral, Dull, Empty, Fuzzy, Numb, Agitated],
  },
  {
    src: '/images/planets/threeToFour/sad.png',
    alt: 'Sad',
    label: 'Sad',
    feeling: 'sad',
    sensations: [Achy, StuckOrFrozen, Empty, Fuzzy, Knotted, Weak],
  },
  {
    src: '/images/planets/threeToFour/angry.png',
    alt: 'Angry',
    label: 'Angry',
    feeling: 'angry',
    sensations: [Breathless, HotOrBurning, Shakey, Prickly, StiffOrTense, Sweaty],
  },
  {
    src: '/images/planets/threeToFour/scared.png',
    alt: 'Scared',
    label: 'Scared',
    feeling: 'scared',
    sensations: [StuckOrFrozen, Breathless, Fuzzy, Shakey, StiffOrTense, Weak],
  },
  {
    src: '/images/planets/threeToFour/overwhelmed.png',
    alt: 'Overwhelmed',
    label: 'Overwhelmed',
    feeling: 'overwhelmed',
    sensations: [StuckOrFrozen, Breathless, Churning, ConfusedOrFuzzy, JumbledUp, Knotted],
  },
] as const

export default planetList
