import { navigate } from '@reach/router'
import { setQSData } from 'QueryStringController'

function pluralize(word: string, plural = `${word}s`) {
  return (n: number) => (Math.abs(n) === 1 ? word : plural)
}

function toggleItem<T>(array: T[], item: T) {
  const index = array.indexOf(item)
  if (index === -1) {
    return [...array, item]
  } else {
    return [...array.slice(0, index), ...array.slice(index + 1)]
  }
}

function hexToRGB(hex: string, alpha?: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (Boolean(alpha)) return 'rgba(' + r + ', ' + g + ', ' + b + ', ' + alpha + ')'
  return 'rgb(' + r + ', ' + g + ', ' + b + ')'
}

function lightenColor(color: string, percent: number) {
  let num = parseInt(color.replace('#', ''), 16)
  let amt = Math.round(2.55 * percent)
  const R = (num >> 16) + amt
  const B = ((num >> 8) & 0x00ff) + amt
  const G = (num & 0x0000ff) + amt

  const calculate =
    0x1000000 +
    (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
    (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
    (G < 255 ? (G < 1 ? 0 : G) : 255)

  return '#' + calculate.toString(16).slice(1)
}

function paginate(
  array: [],
  page_number: number | undefined = 1,
  page_size: number | undefined = 3,
) {
  return array.slice((page_number - 1) * page_size, page_number * page_size)
}

export function navigateWithParams(route: string, additionalParams?: { [key: string]: any }): void {
  if (additionalParams) {
    setQSData(additionalParams)
  }
  navigate(`${route}${window.location.search}`)
}

export { pluralize, toggleItem, hexToRGB, lightenColor, paginate }
