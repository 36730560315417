import React, { ReactNode } from 'react'
import styled from 'styled-components'

const Container = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  min-height: 100vh;
  height: auto;
  flex-direction: column;
  align-items: center;
  background: #f2f2f2;
`

const Line = styled.div`
  background: #250032;
  height: 0.5rem;
  width: 100%;
`

interface LayoutProps {
  children: ReactNode
}

export const Layout = ({ children }: LayoutProps) => {
  return (
    <Container>
      <Line />
      {children}
    </Container>
  )
}
