import React from 'react'
import { Container, Link, makeStyles, Typography } from '@material-ui/core'

import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'

import type { User } from 'types'

type Props = { currentUser: User }

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '64px',
    lineHeight: 'normal',
    color: '#6750A3',
    textAlign: 'center',
    marginBottom: '40px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '32px',
  },

  backSoon: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
  },

  container: {
    display: 'flex',
    maxWidth: '1156px',
    padding: '6rem 0',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textColumn: {
    maxWidth: '684px',
  },
  imageColumn: {
    width: '371px',
  },
  cadet: { width: '371px' },
  helpLink: {
    color: '#6750A3',
    fontWeight: 400,
  },
}))

const NewSchoolYearWelcomeBack = ({ currentUser }: Props) => {
  const classes = useStyles()

  return (
    <DashboardScreen
      currentUser={currentUser}
      minimal={true}
      propsHeader={{ propsMenuSchool: { redirectOnChange: true } }}
    >
      <Container className={classes.container}>
        <div className={classes.textColumn}>
          <Typography className={classes.heading} noWrap>
            We're working on it!
          </Typography>
          <Typography className={classes.subTitle}>
            Along with a few folks from your building, we’re putting the finishing touches on your
            school.
            <br />
            In the meantime, you can explore our
            <br />
            <Link
              className={classes.helpLink}
              color="secondary"
              href="https://closegaphelp.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Resource Center and Help Center!
            </Link>
          </Typography>
          <Typography className={classes.backSoon}>
            Your school will be ready shortly.
            <br />
            Come back soon!
          </Typography>
        </div>
        <div className={classes.imageColumn}>
          <img className={classes.cadet} src="/images/cadet-working.png" alt="cadet working" />
        </div>
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(NewSchoolYearWelcomeBack)
