import React from 'react'

import Title from 'components/AuthTitle'
import Screen from '../Screen'
import { RouteComponent } from 'types'

const PasswordResetInstructionsEmail: RouteComponent = () => (
  <Screen>
    <Title>
      Instructions to reset your password have been sent to your email address (or to your teacher,
      if you are a student). Please check your inbox.
    </Title>
  </Screen>
)

export default PasswordResetInstructionsEmail
