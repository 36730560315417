import React from 'react'

import Typography from '@material-ui/core/Typography'

export default ({ variant, component, children, style, className }) => (
  <Typography
    variant={variant || 'body1'}
    component={component}
    style={{
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      lineHeight: '155%',
      textAlign: 'center',
      letterSpacing: '0.5px',
      ...style,
    }}
    className={className}
  >
    {children}
  </Typography>
)
