import styled from 'styled-components'
import { Link } from '@reach/router'

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 160%;
  text-align: center;
  color: #250032;
  margin: 1rem 0;
`

export const SubTitle = styled.h2`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 160%;
  color: #353535;
  margin-bottom: 1rem;
`

export const AlignLeft = styled.div`
  width: 100%;
  justify-content: flex-start;
  text-align: left;
`

export const LinkMessageContainer = styled.div`
  display: flex;
  text-align: center;
  margin: 1rem 0.5rem;
  z-index: 99 !important;
  @media screen and (max-width: 700px) {
    margin: 1rem 0.5rem;
  }
`

export const Text = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 160%;
  font-weight: 400;
  color: #353535;
  padding: 0.5rem 0;
`

export const InternalLink = styled(Link)`
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 160%;
  font-weight: 600;
  color: #7c5789;
  padding: 0.5rem 0;
  :hover {
    color: #250032;
  }
`

export const InternalLinkButton = styled(Link)`
  display: inline-flex;
  height: 40px;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 4px;
  border: 2px solid #6C0FE6;
  color: #6C0FE6;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 100%; 
  :hover {
    color: #8b5cf6;
    border-color: #8b5cf6;
  }
`

export const ExternalLink = styled.a.attrs(() => ({ target: '_blank' }))`
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 160%;
  font-weight: 600;
  color: #7c5789;
  padding: 0.5rem 0;
  :hover {
    color: #250032;
  }
`

export const Button = styled.button<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.3rem;
  letter-spacing: 0.07em;
  color: #fefefe;
  background: ${({ disabled }) => (disabled ? 'rgba(153,142,201,0.3)' : '#998ec9')};
  border-radius: 8px;
  padding: 1rem;
  margin: 2rem 0;
  width: 100%;
  height: 50px;
  padding: 0.5rem 0;
  :hover {
    background: ${({ disabled }) => (disabled ? 'rgba(153,142,201,0.3)' : '#7b70ab')};
  }
  transition: all 0.2s ease-in-out;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`

export const ExternalButton = styled.a.attrs(() => ({ target: '_blank' }))<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  line-height: 1.3rem;
  letter-spacing: 0.07em;
  color: #fefefe;
  background: ${({ disabled }) => (disabled ? 'rgba(153,142,201,0.3)' : '#998ec9')};
  border-radius: 8px;
  padding: 1rem;
  margin: 1rem 0;
  width: 100%;
  height: 50px;
  padding: 0.5rem 0;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
  :hover {
    background: ${({ disabled }) => (disabled ? 'rgba(153,142,201,0.3)' : '#7b70ab')};
  }
  transition: all 0.2s ease-in-out;
`
