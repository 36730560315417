import { User } from '@sentry/react'
import validateUserFields from 'auth/validateUserFields'
import React from 'react'
import Screen from '../dashboard/Screen'
type Props = { currentUser: User; schoolId: string }
const StudentMessages = (props: Props) => {
  return (
    props.currentUser && (
      <Screen {...props}>
        <iframe
          src={`${process.env.REACT_APP_API_URL}/app/students/messages`}
          className="h-max w-full min-h-[calc(100dvh-88px)]"
          title="Home"
        />
      </Screen>
    )
  )
}

export default validateUserFields(StudentMessages)
