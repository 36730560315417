import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  /* height: 50px; */
  margin: 0.5rem 0;
  font-family: Inter;
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  color: #9a9a9a;
`

export const Button = styled.div<{ isOpen: boolean; isSelected: boolean; invalid: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 50px;
  padding: 0.5rem 1rem;
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  box-shadow: ${({ invalid }) =>
    invalid ? '0px 0px 5px 1px #da001a' : '0px 0px 5px 1px rgba(37, 0, 50, 0.25)'};
  background: #fefefe;
  color: ${({ isSelected }) => (isSelected ? '#353535' : 'inherit')};
  :hover {
    box-shadow: ${({ invalid }) =>
      invalid ? '0px 0px 5px 1px #da001a' : '0px 0px 5px 1px rgba(37, 0, 50, 0.5)'};
  }
`

export const DropdownContent = styled.div<{ required: boolean }>`
  position: absolute;
  width: 100%;
  text-align: left;
  background: #f5eafd;
  box-shadow: 0px 2px 5px 1px rgba(37, 0, 50, 0.25);
  border-radius: 0 0 8px 8px;
  z-index: 999 !important;
  overflow-y: auto;
  max-height: 80vh;
  border-top: solid 1px rgba(37, 0, 50, 0.1);
`

export const Options = styled.div<{ value: string }>`
  padding: 0.5rem 1rem;
  text-decoration: none;
  border-bottom: 1px solid rgba(153, 142, 201, 0.1);
  :hover {
    background: rgba(37, 0, 50, 0.8);
    color: #fefefe;
  }
  cursor: pointer;
`

export const Label = styled.label<{ invalid: boolean }>`
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 1rem;
  font-size: 0.7rem;
  margin-top: -0.3rem;
  color: ${({ invalid }) => (invalid ? '#da001a' : '#9a9a9a')};

  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
`

export const ErrorMessage = styled.p`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.9rem;
  color: #da001a;
  min-height: 0.5rem;
`

export const InputContainer = styled.div<{ isOpen: boolean; invalid: boolean }>`
  position: relative;
  font-family: Inter;
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  color: #353535;
  background: #fefefe;
  box-shadow: ${({ invalid }) =>
    invalid ? '0px 0px 5px 1px #da001a' : '0px 0px 5px 1px rgba(37, 0, 50, 0.25)'};
  padding: 0 1rem;
  :hover {
    box-shadow: ${({ invalid }) =>
      invalid ? '0px 0px 5px 1px #da001a' : '0px 0px 5px 1px rgba(37, 0, 50, 0.5)'};
  }
  :focus {
    box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 1);
  }
`

export const Input = styled.input`
  border: none;
  width: 100%;
  outline-color: transparent;
`
