const FREQUENCIES = [
  {
    value: 1,
    label: 'One time',
  },
  {
    value: 3,
    label: 'Three times in a row',
  },
  {
    value: 5,
    label: 'Five times or more in a row',
  },
]

export const getFrequency = (frequency) =>
  FREQUENCIES.find(({ value }) => value === frequency)

export default FREQUENCIES
