import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import Kid from './Kid'

const SCREEN_NAME = 'shareAnythingElse'

const ShareAnythingElse: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    return <Kid flow={FLOWS.kid[SCREEN_NAME]} />
  }

  return null
}

export default ShareAnythingElse
