import React from 'react'
import PanelGroup from 'Educators/students/list/PanelGroup'
import { Grid, withStyles, Card, CardHeader, CardContent } from '@material-ui/core'
import orderBy from 'lodash/orderBy'

import moment from 'moment'

import { EmptyText } from 'components/EmptyText'
import isEmpty from 'lodash/isEmpty'
import { filterStudents } from 'Educators/filters'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(5),
  },
  cardHeader: {
    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
  },

  cardHeaderTitle: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '24px',
    letterSpacing: 0.4,
  },

  cardContent: {
    padding: theme.spacing(5, 4),
  },
})

const DateCard = withStyles(styles)(({ title, children, classes }) => (
  <Card className={classes.root}>
    <CardHeader
      title={title}
      classes={{ title: classes.cardHeaderTitle, root: classes.cardHeader }}
      titleTypographyProps={{ color: 'textSecondary', component: 'h2' }}
    />
    <CardContent classes={{ root: classes.cardContent }}>{children}</CardContent>
  </Card>
))

const groupByDate = (students) => {
  const groupedCheckins = {}

  students.forEach((student) => {
    if (!!student?.latestCheckIn?.followedUpId) {
      const key = moment(student?.latestCheckIn?.createdAt).local().format('MM/DD/YYYY')

      groupedCheckins[key] = [...(groupedCheckins[key] || []), student]
    }
  })

  return groupedCheckins
}

const CompletedFollowUps = withStyles(styles)(
  ({ classes, currentUser, fetchListStudents, studentsWithAllCheckIns, filters }) => {
    const filteredStudents = filterStudents(studentsWithAllCheckIns, filters)

    const groupedCheckins = groupByDate(filteredStudents)

    return (
      <Grid className={classes.root}>
        {isEmpty(groupedCheckins) ? (
          <EmptyText>There are no completed follow-ups yet</EmptyText>
        ) : (
          orderBy(Object.keys(groupedCheckins), (key) => moment(key), 'desc').map((key) => (
            <DateCard key={key} title={key}>
              <PanelGroup
                group={groupedCheckins[key]}
                currentUser={currentUser}
                fetchListStudents={fetchListStudents}
              />
            </DateCard>
          ))
        )}
      </Grid>
    )
  },
)

export default CompletedFollowUps
