import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import { Link } from '@reach/router'

import ExternalLink from 'components/ExternalLink'

const styles = (theme) => ({
  top: {
    background: theme.colors.mardiGras,
    height: 115,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  bottom: {
    background: theme.colors.cinnabar,
    height: '65px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  link: {
    color: theme.colors.white,
    fontSize: '16px',
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },
  orangeLink: {
    color: theme.colors.cinnabar,
    textTransform: 'uppercase',
    margin: '10px',
    textAlign: 'center',
  },
  divider: {
    color: theme.colors.white,
    margin: '0 8px',
  },
})

const Footer = withStyles(styles)(({ currentUser, classes, className, minimal }) => {
  const isCurrentUserAnAdult =
    currentUser && currentUser?.role !== undefined && currentUser?.role !== 'student'

  const dashboardLink = () => {
    if (isCurrentUserAnAdult) {
      return (
        <a href="/dashboard/profile" className={`${classes.link} ${classes.orangeLink}`}>
          My Account
        </a>
      )
    }
    return null
  }

  const topFooter = () => {
    return (
      <>
        {dashboardLink()}
        <ExternalLink
          className={`${classes.link} ${classes.orangeLink}`}
          href="https://www.closegap.org"
        >
          About Closegap
        </ExternalLink>
        <ExternalLink
          className={`${classes.link} ${classes.orangeLink}`}
          href="https://www.closegap.org/contact"
        >
          Contact Us
        </ExternalLink>
        <Link to="/sign-out?clear=true" className={`${classes.link} ${classes.orangeLink}`}>
          Log Out
        </Link>
      </>
    )
  }

  if (minimal) {
    return <></>
  }
  return (
    <div className={className}>
      <div className={classes.top}>{topFooter()}</div>
      <div className={classes.bottom}>
        <Typography>
          <ExternalLink className={classes.link} href="https://www.closegap.org/user-agreement">
            User Agreement
          </ExternalLink>
          <span className={classes.divider}>|</span>
          <ExternalLink className={classes.link} href="https://www.closegap.org/privacypolicy">
            Privacy Policy
          </ExternalLink>
        </Typography>
      </div>
    </div>
  )
})

export { Footer }
