import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import Emotions from 'Educators/students/list/emotions'

const styles = ({ palette, spacing, colors }) => ({
  card: {
    margin: spacing(2, 0),
  },
  header: {
    backgroundColor: palette.grey[100],
  },
  headerAction: {
    marginTop: spacing(1),
    marginRight: spacing(1),
    marginBottom: 0,
    marginLeft: 0,
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.194444px',
    width: '100%',
    color: colors.mardiGras,
  },
})

const CardHistory = withStyles(styles)(({ currentUser, classes, title, items, student, index }) => (
  <Card className={classes.card}>
    <CardHeader
      title={title}
      action={
        <Typography className={classes.headerAction} color="textSecondary">
          Follow Up
        </Typography>
      }
      classes={{ root: classes.header, title: classes.title }}
    />

    <CardContent style={{ paddingBottom: 0 }}>
      {items.map((item, emotionIndex) => (
        <Emotions
          firstItem={index === 0 && emotionIndex === 0}
          student={student}
          key={item.id}
          item={item}
          currentUser={currentUser}
        />
      ))}
    </CardContent>
  </Card>
))

export default CardHistory
