import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData } from 'QueryStringController'
import threeToFourPlanetList from 'checkIn/constants/threeToFour/planetList'
import ThreeToFour from 'checkIn/steps/EmotionConfirmation/ThreeToFour'

const SCREEN_NAME = 'confirmEmotion'

const EmotionConfirmation: RouteComponent = () => {
  const { student } = useCheckIn()

  const { feeling } = getQSData()

  if (student) {
    const { nextFunction } = FLOWS['kid'][SCREEN_NAME]

    return (
      <ThreeToFour
        feeling={feeling as typeof threeToFourPlanetList[number]['feeling']}
        nextFunction={nextFunction}
      />
    )
  }

  return null
}

export default EmotionConfirmation
