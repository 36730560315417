import React from 'react'
import type { RouteComponent } from 'types'

import { navigateWithParams } from 'utils'
import NineToTwelve from './NineToTwelve'
import { Fetch } from 'croods'

const Emotion: RouteComponent = ({ studentId }) => {
  return (
    <Fetch
      cache
      name="adults"
      path={`/adults?student_id=${studentId}`}
      render={(data) => (
        <NineToTwelve
          contacts={data}
          handleNextFunction={(preferredContactId?: number) =>
            navigateWithParams(
              './thanks-for-sharing',
              preferredContactId ? { preferredContact: preferredContactId } : {},
            )
          }
        ></NineToTwelve>
      )}
    />
  )
}
export default Emotion
