const LIVING_SITUATION = [
  { value: '', label: 'Empty if none to report' },
  {
    value: 'homeless',
    label: 'Homeless',
  },
  { value: 'foster_care', label: 'Foster Care' },
]

export const getLivingSituation = (livingSituation) =>
  LIVING_SITUATION.find(({ value }) => value === livingSituation)

export default LIVING_SITUATION
