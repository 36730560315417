import React from 'react'
import Screen from 'checkIn/components/teen/Screen'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'
import {
  FuelButton,
  FuelButtonMobile,
  FuelContainer,
  FuelContainerMobile,
  listFuelLink,
} from '../EnergyLevel/TeenV2'
import Image from 'components/ResponsiveImage'
import { isWidthUp, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

const contentByFuel = {
  green: {
    label: 'You picked Full!',
    title: 'Are you full of energy and ready for the rest of day? ',
  },
  yellow: {
    label: 'You picked OK!',
    title: 'Are you not totally full but OK for the rest of the day? ',
  },
  blue: {
    label: 'You picked Low!',
    title: 'Are you low in energy and not ready for the rest of the day? ',
  },
  red: {
    label: 'You picked Empty!',
    title: 'Are you out of energy and not ready for the rest of the day? ',
  },
  orange: {
    label: 'You picked Way too much!',
    title: 'Do you have way too much energy? ',
  },
}

type Props = {
  width: Breakpoint
  nextFunction: (value: string) => void
  value: keyof typeof contentByFuel
}

const Teen = ({ nextFunction, value, width }: Props) => {
  const { label, title } = contentByFuel[value]
  return (
    <Screen label={label} title={title}>
      {isWidthUp('md', width) ? (
        <FuelContainer>
          {listFuelLink.map(({ value: fvalue, label, image, bsColor }) => (
            <FuelButton key={fvalue} disabled bsColor={bsColor} transparent={fvalue !== value}>
              <Image src={`/images/energyLevels/5to8/${image}`} alt={''} />
              {label}
            </FuelButton>
          ))}
        </FuelContainer>
      ) : (
        <FuelContainerMobile>
          {listFuelLink.map(({ value: fvalue, label, image, bsColor }) => (
            <FuelButtonMobile
              key={fvalue}
              disabled
              bsColor={bsColor}
              transparent={fvalue !== value}
            >
              <Image src={`/images/energyLevels/5to8/mobile/${image}`} alt={''} />
              {label}
            </FuelButtonMobile>
          ))}
        </FuelContainerMobile>
      )}

      <BackAndNextButtons
        showBackButton
        nextButtonOnClick={() => nextFunction(window.location.search)}
      />
    </Screen>
  )
}

export default withWidth()(Teen)
