import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
})

export default withStyles(styles)(({ classes, active, payload }) => {
  if (active) {
    const item = payload[0].payload
    return (
      <Paper className={classes.root}>
        <Typography>
          Indicated feeling {item.feeling.toLowerCase()} in {item.ocurrences} of{' '}
          {item.totalCheckIns} check-ins
        </Typography>
      </Paper>
    )
  }

  return null
})
