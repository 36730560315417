import React from 'react'
import NineToTwelve from 'checkIn/nineToTwelve/Activity/Standalone'
import Teen from 'checkIn/steps/Activities/Teen'
import Kid from 'checkIn/steps/Activities/Kid'
import NonReader from 'checkIn/steps/Activities/NonReader'
import type { RouteComponent } from 'types'
import { navigate } from '@reach/router'
import { useActivity } from 'activities/activityContext'
import { isNinthGradeOrOlder } from 'students/helper'

const Activities: RouteComponent = () => {
  const { student } = useActivity()

  if (student) {
    const nextFunction = () => {
      const params = window.location.search
      navigate(`/activities/${student.id}/how-are-you-feeling-now${params}`)
    }

    switch (student.studentType) {
      case 'teen':
        return isNinthGradeOrOlder(student.grade) ? (
          <NineToTwelve handleNextFunction={nextFunction} />
        ) : (
          <Teen nextFunction={nextFunction} />
        )
      case 'kid':
        return <Kid nextFunction={nextFunction} />
      case 'non-reader':
        return <NonReader nextFunction={nextFunction} />
    }
  }

  return null
}

export default Activities
