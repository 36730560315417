import React from 'react'

import { ButtonNext } from 'checkIn/components/teen/ButtonNext'

const CheckInButtonGroup = ({
  leftButtonText,
  handleLeftButton,
  rightButtonText,
  handleRightButton,
}) => (
  <div className="flex flex-col items-center justify-center w-full gap-10 p-10 mt-10 text-center sm:flex-row">
    <ButtonNext
      className="w-full max-w-sm"
      aria-label="left-button"
      onClick={handleLeftButton}
    >
      <span className="font-medium whitespace-nowrap">{leftButtonText}</span>
    </ButtonNext>
    <ButtonNext
      className="w-full max-w-sm"
      aria-label="right-button"
      onClick={handleRightButton}
    >
      <span className="font-medium whitespace-nowrap">{rightButtonText}</span>
    </ButtonNext>
  </div>
)

export default CheckInButtonGroup
