import React, { useState } from 'react'
import planetList from 'checkIn/constants/threeToFour/planetList'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'
import { Subtitle } from './styles'
import Screen from 'checkIn/components/kid/Screen'
import { IntensitySelector } from './IntensitySelector'
import styled from 'styled-components'

export const Label = styled.p`
  color: #fff5f5;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.6px;
  margin: 0;
  padding: 0;
  // text-transform: uppercase;
`

const PlanetContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  margin-top: 5vh;
  margin-bottom: 8vh;
  @media screen and (max-width: 600px) {
    flex-direction: row;
    gap: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 1.5rem;
  }
`

const Image = styled.img`
  width: 8rem;
  height: auto;
  @media screen and (max-width: 700px) {
    width: 6rem;
  }
`

type Props = {
  feeling: string
  selectEmotionIntensity: (params: number | null) => void
}

const ThreeToFour = ({ feeling, selectEmotionIntensity }: Props) => {
  const [intensity, setIntensity] = useState<number | null>(null)

  const { src, label } = planetList.filter((planet) => planet.feeling === feeling)[0]
  return (
    <Screen
      label={`You picked Planet ${label}.`}
      title={`On a scale from 1 to 5,
      <br />
      how much are you feeling this emotion?`}
    >
      <PlanetContainer>
        <Image src={src} alt={''} />
        <Label>{label}</Label>
      </PlanetContainer>

      <Subtitle style={{ marginTop: '-1.5rem', padding: '0', marginBottom: '1rem' }}>
        {/* invisible char so that the spacing doesn't jump around */}
        {Boolean(intensity) ? `You picked ${intensity}!` : '‎'}
      </Subtitle>

      <IntensitySelector isKid setIntensity={setIntensity} intensity={intensity} />

      <BackAndNextButtons
        showBackButton
        hiddenNextButton={!Boolean(intensity)}
        nextButtonOnClick={() => selectEmotionIntensity(intensity)}
      />
    </Screen>
  )
}

export default ThreeToFour
