import React from 'react'
import { RiMentalHealthFill } from 'react-icons/ri'
import { BsFillInfoCircleFill } from 'react-icons/bs'

import { useScreenSize } from 'screenSize'
import { Layout } from '../components/Layout'
import { LogInHereMessage } from '../components/LogInHereMessage'
import { InternalLink, Title } from '../components/styled'
import { SignUpForm } from './SignUpForm'
import {
  CadetImage,
  IconContainer,
  Container,
  BackLinkContainer,
  InfoContainer,
  InfoText,
} from './style'
import { RouteComponent } from 'types'

export const SignUpTherapist: RouteComponent = () => {
  const { isMobile } = useScreenSize()

  return (
    <Layout>
      {isMobile ? null : <CadetImage />}
      <IconContainer>
        <RiMentalHealthFill size={isMobile ? 30 : 100} color="#998ec9" />
      </IconContainer>

      <Container>
        <BackLinkContainer>
          <InternalLink to="/sign-up">{`<-- Back`}</InternalLink>
        </BackLinkContainer>

        <Title>Create a Therapist/Mental Health Professional Account</Title>

        <InfoContainer>
          <BsFillInfoCircleFill size={25} color="#353535" />
          <InfoText>
            This account type is for mental health clinicians that work outside of school buildings
          </InfoText>
        </InfoContainer>

        <LogInHereMessage />

        <SignUpForm />
      </Container>
    </Layout>
  )
}
