import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import Error from './form/Error'

export default ({
  submitting = false,
  reduxFormError = '',
  submitError = '',
  disabled = false,
  buttonText,
  style = {},
}) => (
  <div
    style={{
      textAlign: submitting ? 'center' : 'right',
      marginTop: '20px',
      ...style,
    }}
  >
    {reduxFormError && <Error>{reduxFormError}</Error>}
    {submitError && <Error>{submitError}</Error>}
    {(submitting && <CircularProgress color="primary" size="60px" />) || (
      <Button color="primary" type="submit" disabled={disabled}>
        {buttonText}
      </Button>
    )}
  </div>
)
