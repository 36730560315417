import React, { createContext, useContext, useState } from 'react'

export const AudioContext = createContext()

export const AudioProvider = ({ children }) => {
  const [playingAudio, setPlayingAudio] = useState(null)
  const [muted, setMuted] = useState(true)

  return (
    <AudioContext.Provider
      value={{
        playingAudio,
        setPlayingAudio,
        muted,
        setMuted,
      }}
    >
      {children}
    </AudioContext.Provider>
  )
}

export const useAudiosController = () => useContext(AudioContext)
