import React from 'react'
import omit from 'lodash/omit'
import { valueToFuel } from './transform'
import { lightenColor } from 'utils'

const colors = {
  orange: lightenColor('#e9573e', 30),
  green: lightenColor('#e9573e', 15),
  yellow: '#e9573e',
  blue: lightenColor('#e9573e', -30),
  red: lightenColor('#e9573e', -60),
}

const labels = {
  orange: ['Way too', 'much!'],
  green: ['Full and', 'ready!'],
  yellow: ['OK'],
  blue: ['Low'],
  red: ['Empty'],
}

export default ({
  width,
  payload,

  ...props
}) => {
  const fuel = valueToFuel(payload.value)
  return (
    <>
      {width !== 'xs' && (
        <text {...omit(props, ['visibleTicksCount', 'verticalAnchor'])} y={payload.coordinate + 15}>
          {(labels[fuel] || []).map((part, idx) => (
            <tspan key={idx} x="50" dy="1.2em" style={{ fontSize: 13, fill: colors[fuel] }}>
              {part}
            </tspan>
          ))}
        </text>
      )}
      {fuel && (
        <rect
          x={width === 'xs' ? 4 : 64}
          width="10"
          height={(props.height / Object.keys(labels).length) * 1.0}
          y={payload.coordinate}
          style={{ fill: colors[fuel] }}
        />
      )}
    </>
  )
}
