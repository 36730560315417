import * as React from 'react'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import Typography from '@material-ui/core/Typography'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { roles } from 'roles'

import type { Adult, Student } from 'types'

type Props = {
  adults?: Student['adultsOnAccount']
}
function AdultsOnAccount({ adults }: Props) {
  const [open, setOpen] = React.useState(false)

  const everyAdult = map(flatten(Object.values(adults ?? {})), 'id')

  if (!adults || everyAdult.length === 0) {
    return (
      <></>
    )
  }

  return (
    <div className="flex flex-col items-center sm:items-start">
      <div
        className="flex items-center w-56 mb-4 transition-all cursor-pointer"
        onClick={() => setOpen(!open)}
        aria-hidden="true"
      >
        <Typography variant="button" color="primary" className="hover:drop-shadow">
          ADULTS ON ACCOUNT
        </Typography>
        {open ? (
          <ExpandLess className="w-6 h-6 text-primary" />
        ) : (
          <ExpandMore className="w-6 h-6 text-primary" />
        )}
      </div>

      {open && (
        <div className="flex flex-col gap-4">
            {adults.schoolOwners?.map((adult) => (
              <AdultItem key={adult.id} adult={adult} />
            ))}
            {adults.classroomsViewersAndAdmins?.map((adult) => (
              <AdultItem key={adult.id} adult={adult} />
            ))}
            {adults.sharedWith?.map((adult) => (
              <AdultItem key={adult.id} adult={adult}>
              </AdultItem>
            ))}
        </div>
      )}
    </div>
  )
}

function AdultItem({ adult, children }: { adult: Adult; children?: React.ReactNode }) {
  return (
    <div className="flex flex-nowrap">
      <p className="flex gap-1 items-center mr-2 !leading-relaxed text-gray-700 whitespace-nowrap">
        {adult?.displayName}{' '}
        <span className="!leading-relaxed whitespace-nowrap text-purple-light">
          ({roles[adult.role!] ?? adult?.role})
        </span>
        {children}
      </p>
    </div>
  )
}

export default AdultsOnAccount
