import styled from 'styled-components'

export const Link = styled.p`
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 160%;
  color: #a3a3a3;
  max-width: 533px;
  text-align: center;
  margin: 0;
  cursor: pointer;
  text-shadow: -1px 1px 4px #000;
  :hover {
    color: #fefefe;
  }
  @media screen and (max-width: 600px) {
    font-size: 1.1rem;
  }
`
