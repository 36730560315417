import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { SecondaryButton } from '../components/SecondaryButton'
import { navigateWithParams } from 'utils'

type Props = {
  handleNextFunction: () => void
}

const NegativeSummary = ({ handleNextFunction }: Props) => {
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-2xl sm:text-[32px] leading-10 font-normal font-['Roboto'] mb-4 sm:mb-10">
        It sounds like you're having a pretty rough day. I'm sorry to hear that. We all feel down
        sometimes. Remember, you don't have to handle it all on your own.
      </div>
      <div className="max-w-6xl text-center text-gray-100 text-xl sm:text-2xl font-light font-['Roboto'] mb-4 sm:mb-0">
        Do you want to talk to someone?
      </div>

      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-auto sm:mt-20"
      >
        yes, please
      </NextButton>
      <SecondaryButton
        handleNextFunction={() => {
          navigateWithParams('./thanks-for-sharing', { talkToAnAdult: false })
        }}
        additionalClasses="mt-4"
      >
        No, thanks
      </SecondaryButton>
    </Screen>
  )
}

export default NegativeSummary
