import React from 'react'
import { Grid } from '@material-ui/core'

import { Footer } from 'components/dashboard/Footer'
import Header from 'components/dashboard/Header'
import { featureEnabled } from 'featureFlags'
import StudentHeader from 'components/dashboard/StudentHeader'

export default ({ children, ...props }) => (
  <div>
    {featureEnabled(props.currentUser, 'message_center') ? (
      <StudentHeader {...props} />
    ) : (
      <Header {...props} />
    )}

    <Grid container>{children}</Grid>
    <Footer {...props} />
  </div>
)
