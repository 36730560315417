import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { TalkToAnAdultNonReader } from './NonReader'

const SCREEN_NAME = 'talkToAnAdult'

export const TalkToAdult: RouteComponent = () => {
  const { student } = useCheckIn()

  if (!student) return null

  return <TalkToAnAdultNonReader flow={FLOWS['non-reader'][SCREEN_NAME]} />
}
