import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import { FUEL_CONFIRMATION_PAGE, FUEL_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'

const contentByFuel = {
  green: {
    label: 'You picked Full and Ready!',
    title: 'Is your spaceship full of energy and ready for the rest of the day?',
    description: 'Full and Ready!',
    playerStyle: 'h-[100%] mb-1',
    buttonStyle: 'bg-[#ef816f] border-l-8 border-[#d4655e] pt-10',
  },
  yellow: {
    label: 'You picked OK!',
    title: 'Is your spaceship not totally full but OK for the rest of the day?',
    description: 'OK',
    playerStyle: 'h-[65%] mb-1',
    buttonStyle: 'bg-[#e9573f] border-l-8 border-[#c63228] pt-10',
  },
  blue: {
    label: 'You picked Low!',
    title: 'Is your spaceship low in energy and not ready for the rest of the day?',
    description: 'Low',
    playerStyle: 'h-[35%] mb-1',
    buttonStyle: 'bg-[#803023] border-l-8 border-[#6d1b16] pt-10',
  },
  red: {
    label: 'You picked Empty!',
    title: 'Is your ship out of energy and not ready for the rest of the day?',
    description: 'Empty',

    playerStyle: 'h-[15%]',
    buttonStyle: 'bg-[#2f110d] border-l-8 border-[#280a08] items-center',
  },
}

type Props = {
  nextFunction: (value: string) => void
  value: keyof typeof contentByFuel
}

const NonReader = ({ nextFunction, value }: Props) => {
  const { label, title, description, playerStyle, buttonStyle } = contentByFuel[value]

  return (
    <Screen
      className="!justify-start h-screen pt-0 md:pt-16"
      label={label}
      title={title}
      audios={{
        autoplay: FUEL_CONFIRMATION_PAGE[value],
        ...FUEL_CONFIRMATION_PAGE,
      }}
    >
      <div className="z-10 flex h-[60vh] items-end flex-col-reverse my-0 mx-auto relative text-center bg-gray-300 border-l-8 border-gray-400 px-4 py-6 rounded-3xl max-w-[360px] w-[60%] sm:w-full">
        <AudioPlayer
          className={`${playerStyle} w-full`}
          styleButton={{
            alignSelf: 'center',
            marginBottom: 0,
          }}
          importAudio={FUEL_PAGE[value as keyof typeof FUEL_PAGE]}
        >
          <div
            key={value}
            className={`${buttonStyle} text-white text-2xl font-bold w-full rounded flex justify-center  px-5`}
          >
            {description}
          </div>
        </AudioPlayer>
      </div>

      <div className="relative bottom-0 z-0 w-full mt-5 md:mt-10 md:absolute">
        <BackAndNextButtons
          backButtonText="No."
          nextButtonText="Yes."
          showBackButton
          nextButtonOnClick={() => nextFunction(window.location.search)}
          audios={{
            buttonBack: FUEL_CONFIRMATION_PAGE.buttonBack,
            buttonNext: FUEL_CONFIRMATION_PAGE.buttonNext,
          }}
        />
      </div>
    </Screen>
  )
}

export default NonReader
