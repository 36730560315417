export const MOCK_USER = {
    acceptedTerms: true,
    birthdate: '',
    changePasswordOnNextLogin: false,
    createdAt: new Date(),
    displayName: '',
    email: '',
    grade: 'PK',
    firstName: 'Cadet',
    firstSchoolAccessPending: false,
    honorificTitle: '',
    id: -1,
    lastName: '',
    phoneNumber: null,
    school: null,
    student: null,
    studentId: -1,
    featureFlags: ['pk_2_check_in_2024']
  }