import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'

type Props = {
  handleNextFunction: () => void
}

const NineToTwelve = ({ handleNextFunction }: Props) => {
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-2xl sm:text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        Thanks for sharing! No one feels their best all of the time.
      </div>
      <div className="max-w-6xl text-center text-gray-100 text-xl sm:text-2xl font-light font-['Roboto'] mb-4 sm:mb-0">
        Feel free to pick an activity if something sparks your interest!
      </div>
      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-auto sm:mt-20"
      >
        explore activities
      </NextButton>
    </Screen>
  )
}

export default NineToTwelve
