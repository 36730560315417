import React from 'react'

type Props = {
  disabled?: boolean
  handleNextFunction: () => void
  additionalClasses?: string
  children?: any
}

export const NextButton = ({
  disabled = false,
  handleNextFunction,
  additionalClasses,
  children,
}: Props) => (
  <button
    disabled={disabled}
    onClick={handleNextFunction}
    className={`transition-all ${additionalClasses} shadow-md bg-gradient-to-br from-[#04C8C8] to-[#108A93] text-gray-100  font-bold h-12 max-w-96 w-full rounded-full uppercase text-lg disabled:bg-teal-200 disabled:text-gray-100 disabled:opacity-40 hover:shadow-[inset_4px_8px_40px_0px_rgba(255,255,255,0.25)]`}
  >
    {children || 'Next'}
  </button>
)
