import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'

const ConfirmationDialog = ({
  isOpen,
  close,
  confirm,
  title,
  cancelText,
  confirmText,
}) => (
  <Dialog open={isOpen} onClose={close} aria-labelledby="Are you sure?">
    <DialogTitle>{title}</DialogTitle>
    <DialogActions>
      <Button onClick={close} color="primary" autoFocus aria-label="cancel">
        {cancelText}
      </Button>
      <Button
        aria-label="confirm"
        onClick={() => {
          close()
          confirm()
        }}
        color="primary"
      >
        {confirmText}
      </Button>
    </DialogActions>
  </Dialog>
)

export default ConfirmationDialog
