import React from 'react'
import type { RouteComponent } from 'types'

import { navigateWithParams } from 'utils'
import NineToTwelve from './NineToTwelve'

const Emotion: RouteComponent = () => {
  return (
    <NineToTwelve
      contacts={[
        { id: 1, displayName: 'Teacher One' },
        { id: 2, displayName: 'Teacher Two' },
      ]}
      handleNextFunction={(preferredContactId?: number) =>
        navigateWithParams(
          './thanks-for-sharing',
          preferredContactId ? { preferredContact: preferredContactId } : {},
        )
      }
    ></NineToTwelve>
  )
}
export default Emotion
