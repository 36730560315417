import React from 'react'

import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import withMixpanel from 'mixpanel/withMixpanel'

import Screen from 'checkIn/components/kid/Screen'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'

import SecondaryButton from 'checkIn/components/SecondaryButton'
import { checkInTrack, setStudentInfo } from 'students/helper'
import IconMessage from 'checkIn/components/kid/IconMessage'
import FUEL from 'fuel'
import signOutTeacher from 'checkIn/steps/ThanksForExploring/signOutTeacher'
import { isStudent } from 'students/helper'

const Kid = withMixpanel(({ mixpanel, energyLevel, flow, student, currentUser }) => {
  const dispatch = useDispatch()
  const { nextFunction } = flow

  const { hasUnseenMessages = false } = student

  const content = {
    adult: {
      green: {
        label: `That's great, ${student.firstName}!`,
        title: 'Return Closegap to an adult.<br>I’ll be here if you need me.',
        imgSrc: '/images/happy_cadet_left_shadow.png',
        imgAlt: 'Happy cadet',
      },
      otherFuel: {
        label: 'Thanks for exploring your feelings with me.',
        title: 'That’s all for now. Return Closegap to an adult.',
        imgSrc: '/images/cool_down_cadet.png',
        imgAlt: 'Cool down cadet',
      },
    },
    student: {
      green: {
        label: `That's great, ${student.firstName}!`,
        title: 'Good luck today.<br>I’ll be here if you need me.',
        imgSrc: '/images/happy_cadet_left_shadow.png',
        imgAlt: 'Happy cadet',
      },
      otherFuel: {
        label: 'Thanks for exploring your feelings with me.',
        title: 'That’s all for now. I’ll be here if you need me!',
        imgSrc: '/images/cool_down_cadet.png',
        imgAlt: 'Cool down cadet',
      },
    },
  }

  const userContent = isStudent(currentUser.role) ? content.student : content.adult

  const finalContent =
    energyLevel === FUEL.FULL_AND_READY.value ? userContent.green : userContent.otherFuel

  const showStudentComponents = isStudent(currentUser.role)

  const handleMyProfileButton = () => {
    checkInTrack(mixpanel, 'Go to My Profile Clicked')
    navigate(`/check-in-history/${student.id}`)
    setStudentInfo({ studentId: student.id, grade: student.grade })
  }

  const handleLogout = () => {
    checkInTrack(mixpanel, 'Log Out Clicked')
    sessionStorage.clear()
    navigate('/sign-out?clear=true')
  }

  return (
    <Screen label={finalContent.label} title={finalContent.title}>
      <img className="my-20" src={finalContent.imgSrc} alt={finalContent.imgAlt} />
      {!showStudentComponents && (
        <div className="flex w-screen mb-10">
          <BackAndNextButtons
            nextButtonText="Done!"
            nextButtonOnClick={signOutTeacher(dispatch, currentUser, nextFunction)}
          />
        </div>
      )}
      {showStudentComponents && (
        <div className="flex flex-col items-center">
          {hasUnseenMessages && (
            <IconMessage
              className="mt-[-16px] ml-[150px] sm:ml-[240px] !bg-[#250032] hover:!bg-primary hover:!border-[#250032] "
              onClick={handleMyProfileButton}
            />
          )}
          <SecondaryButton className="mb-5" onClick={handleMyProfileButton}>
            <span className="flex text-base font-medium xs:text-2xl sm:text-3xl">
              Go to My Profile
            </span>
          </SecondaryButton>
          <SecondaryButton onClick={handleLogout} style={{ padding: '10px 40px' }}>
            Log Out
          </SecondaryButton>
        </div>
      )}
    </Screen>
  )
})

export default Kid
