import React from 'react'
import { useCroods } from 'croods'

import clsx from 'clsx'
import Loading from 'components/Loading'
import Error from 'components/form/Error'
import { find } from 'lodash'
import { getAccessLevel } from 'roles'

const SelectAccess = ({ accessId, schoolId, role }) => {

  const [, { fetch: fetchSchoolEducators }] = useCroods({
    name: 'educators',
    stateId: schoolId?.toString(),
    path: `/schools/${schoolId}/educators`,
  })

  const [{ saveError, list }, { save: saveAccessRequests }] = useCroods({
    id: accessId,
    stateId: schoolId,
    name: 'accessRequests',
    path: `/schools/${schoolId}/access_requests`,
    afterSuccess: ({ data }) => {
      data?.status === 'accepted' && fetchSchoolEducators()()
    },
  })

  const accessRequest = find(list, ({ id }) => id === accessId)

  if (saveError) {
    return <Error>{saveError}</Error>
  }

  return (
    <div className="flex items-center justify-end flex-auto max-w-xs">
      {accessRequest?.saving && <Loading />
      }     
        <button
          type="button"
          disabled={accessRequest?.saving}
          className={clsx(
            'flex items-end ml-6',
            'text-gray-400',
          )}
          onClick={() => saveAccessRequests()({ access: "dismiss" })}
        >
          <p className="text-base font-medium">DISMISS</p>
        </button>
        <button
          type="button"
          disabled={accessRequest?.saving}
          className={clsx(
            'flex items-end ml-6',
            'text-primary',
          )}
          onClick={() => saveAccessRequests()({ access: getAccessLevel(role) })}
        >
          <p className="text-base font-medium">APPROVE</p>
        </button>
    </div>
  )
}

export default SelectAccess
