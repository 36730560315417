import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import Teen from 'checkIn/steps/EnergyLevelConfirmation/Teen'
import Kid from 'checkIn/steps/EnergyLevelConfirmation/Kid'
import NonReader from 'checkIn/steps/EnergyLevelConfirmation/NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData } from 'QueryStringController'
import { checkInTrack } from 'students/helper'
import { useMixpanel } from 'mixpanel'
import { featureEnabled } from 'featureFlags'
import TeenV2 from './TeenV2'
import ThreeToFour from './ThreeToFour'

const SCREEN_NAME = 'energyLevelConfirmation'

type EnergyLevels = 'green' | 'yellow' | 'blue' | 'red'

const EnergyLevelConfirmation: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  const value = getQSData().energyLevel as EnergyLevels

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, `Check in: You picked ${value}!`, student)
    }
  }, [student]) // eslint-disable-line

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    switch (student.studentType) {
      case 'teen':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <TeenV2 nextFunction={nextFunction} value={value} />
        ) : (
          <Teen nextFunction={nextFunction} value={value} />
        )
      case 'kid':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <ThreeToFour nextFunction={nextFunction} value={value} />
        ) : (
          <Kid nextFunction={nextFunction} value={value} />
        )
      case 'non-reader':
        return <NonReader nextFunction={nextFunction} value={value} />
    }
  }

  return null
}

export default EnergyLevelConfirmation
