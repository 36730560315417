import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import { Bar, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, LabelList } from 'recharts'
import maxBy from 'lodash/maxBy'
import round from 'lodash/round'
import map from 'lodash/map'
import templateLodash from 'lodash/template'

export const CustomTooltip = ({ active, payload, template }) => {
  if (active && payload && payload.length) {
    const compiled = templateLodash(template)
    return (
      <Paper
        style={{
          padding: 8,
          width: 120,
          borderRadius: 4,
        }}
      >
        <Typography>
          {compiled({
            value: payload[0].value,
            name: payload[0].payload.name,
          })}
        </Typography>
      </Paper>
    )
  }

  return null
}

const Chart = ({
  list,
  isAnimationActive = true,
  listFill,
  propsBarChart,
  propsXAxis,
  propsTooltip,
}) => {
  const data = list.map((item, index) => ({
    ...item,
    fill: listFill[index],
  }))

  const CustomTopLabel = ({ x, y, width, fill, value, rotate }) => (
    <text
      x={x}
      y={y}
      dy={-16}
      dx={width / 2 - 14}
      fontSize="14"
      fontFamily="Roboto"
      fontWeight="400"
      fill={fill}
      rotate={rotate}
    >
      {value}%
    </text>
  )

  const CustomTick = ({
    x,
    y,
    index,
    payload,
    className,
    height,
    orientation,
    stroke,
    textAnchor,
    type,
    width,
  }) => (
    <text
      x={x}
      y={y}
      dy={16}
      fontSize="14"
      fontFamily="Roboto"
      fontWeight="400"
      fill={listFill[index]}
      className={className}
      height={height}
      orientation={orientation}
      stroke={stroke}
      textAnchor={textAnchor}
      type={type}
      width={width}
    >
      {payload?.value}
    </text>
  )

  const maxPercentage =
    data &&
    data.length > 0 &&
    round(
      maxBy(
        map(data, (item) => ({
          ...item,
          percentage: parseFloat(item.percentage),
        })),
        'percentage',
      ).percentage,
    )
  const maxPercentageValue = maxPercentage === 0 ? 50 : maxPercentage

  return (
    <div style={{ display: 'flex', alignSelf: 'center' }}>
      <BarChart data={data} margin={{ top: 40 }} {...propsBarChart}>
        <CartesianGrid vertical={false} horizontal={false} />
        <XAxis dataKey="name" tickLine={false} tick={<CustomTick />} {...propsXAxis} />
        <YAxis ticks={[maxPercentageValue]} unit="%" domain={[0, 'dataMax']} />
        {propsTooltip && (
          <Tooltip content={<CustomTooltip {...propsTooltip} />} cursor={{ fill: 'transparent' }} />
        )}
        <Bar dataKey="percentage" fill="fill" isAnimationActive={isAnimationActive} width={30}>
          <LabelList dataKey="percentage" content={<CustomTopLabel />} />
        </Bar>
      </BarChart>
    </div>
  )
}

export default Chart
