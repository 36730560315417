import React from 'react'
import type { RouteComponent } from 'types'

import { navigateWithParams } from 'utils'
import NineToTwelve from './NineToTwelve'

const Emotion: RouteComponent = () => {
  return (
    <NineToTwelve handleNextFunction={() => navigateWithParams('../feeling-now')}></NineToTwelve>
  )
}
export default Emotion
