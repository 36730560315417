import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import styled from 'styled-components'
import ButtonNext from './ButtonNext'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  max-height: 80vh;
  padding: 3rem;
  @media screen and (max-width: 600px) {
    padding: 1rem;
    max-width: 100%;
    max-height: 100vh;
  }
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-height: 50vh;
  scroll-behavior: smooth;
  overflow-y: auto;
  padding: 0 1rem;
  margin-bottom: 1rem;
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    background: rgba(148, 148, 148, 0.3);
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(148, 148, 148, 0.4);
    border-radius: 100px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(148, 148, 148, 1);
  }
`

export const Title = styled.h1`
  font-size: 2rem;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  color: #353535;
  line-height: 160%;
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`

const Paragraph = styled.div`
  font-size: 1rem;
  font-weight: 400;
  line-height: 160%;
  color: #353535;
  margin-bottom: 1rem;
`

interface Props {
  open: boolean
  onClose: () => void
}

export const Modal = ({ open, onClose }: Props) => {
  return (
    <Dialog onClose={onClose} open={open}>
      <Container>
        <Title>Why are check-ins important?</Title>

        <TextContainer>
          <Paragraph>
            Daily check-ins play a big part in overall well-being and provide a moment to reflect
            and understand yourself better. They allow you to get in touch with your emotions, cope
            with challenging thoughts or feelings, be more focused throughout the day, and ask for
            help if you need it.
          </Paragraph>

          <Paragraph>
            When your feelings are comfortable and positive, you can review the day and see why.
            This helps you learn about your self-care needs and what works best for you. If you
            experience uncomfortable emotions that feel challenging and negative, the check-in gives
            you a moment to acknowledge them and center yourself. In fact, the act of labeling
            emotions can actually reduce your physiological response to them. Cool, right? At the
            end of the check-in, you can choose from evidence-based activities that help you
            regulate uncomfortable feelings.
          </Paragraph>

          <Paragraph>
            Check-ins also give you an easy way to ask for help. Sometimes, it’s hard to share when
            things aren’t going right. We think it might be our fault, or we get embarrassed and
            don’t feel like talking. It’s not always easy to verbalize what’s happening in our
            lives, but we want you to know that the staff at your school care for you, and if you
            ever need their help, you can ask for it.
          </Paragraph>

          <Paragraph>
            That’s it for now. We’re so glad you’re here! Let’s start a check-in!
          </Paragraph>
        </TextContainer>

        <ButtonNext onClick={() => onClose()}>Ok, thanks!</ButtonNext>
      </Container>
    </Dialog>
  )
}
