import { Button, CircularProgress, makeStyles } from '@material-ui/core'
import React from 'react'

type ButtonProps = {
  children: React.ReactNode
  onClick: Function
  disabled: boolean
  loading?: boolean
}

const useStyles = makeStyles(() => ({
  primaryButton: {
    width: '100%',
    marginBottom: '8px',
    borderRadius: '8px',
    padding: '16px',
    fontSize: '24px',
    backgroundColor: '#6750A3',
    '&:hover': {
      backgroundColor: '#5d4893',
    },
    '&:focus': {
      backgroundColor: '#524082',
    },
  },
  secondaryButton: {
    width: '100%',
    marginBottom: '8px',
    borderRadius: '8px',
    color: '#6750A3',
    padding: '16px',
    fontSize: '24px',
    '&:hover': {
      color: '#5d4893',
    },
    '&:focus': {
      color: '#524082',
    },
  },
}))

export function PrimaryButton({ children, onClick, disabled, loading }: ButtonProps) {
  const classes = useStyles()
  return (
    <Button
      variant="contained"
      color="secondary"
      className={classes.primaryButton}
      disabled={disabled}
      onClick={() => onClick()}
    >
      {loading ? <CircularProgress color="secondary" /> : children}
    </Button>
  )
}

export function SecondaryButton({ children, onClick, disabled, loading }: ButtonProps) {
  const classes = useStyles()
  return (
    <Button
      color="secondary"
      className={classes.secondaryButton}
      disabled={disabled}
      onClick={() => onClick()}
    >
      {loading ? <CircularProgress color="secondary" /> : children}
    </Button>
  )
}
