import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import NonReader from './NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'

const Activities: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const { nextFunction } = FLOWS['non-reader'].activities
    return <NonReader nextFunction={nextFunction} />
  }

  return null
}

export default Activities
