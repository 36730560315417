import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from 'checkIn/steps/UncomfortableResult/Teen'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData } from 'QueryStringController'

const SCREEN_NAME = 'uncomfortableResult'

const UncomfortableResult: RouteComponent = () => {
  const { student } = useCheckIn()

  const { energyLevel } = getQSData()

  const [label, bgImage] = ['green', 'yellow', 'orange'].includes(energyLevel)
    ? ([
        'Thanks for sharing how you are doing with me!',
        '/images/neutral_cadet.png',
        'nonComfyButWithEnergy',
      ] as const)
    : (['I’m so sorry to hear that.', '/images/angsty_cadet.png', 'nonComfy'] as const)

  if (student) {
    const { nextFunction } = FLOWS['teen'][SCREEN_NAME]

    return <Teen nextFunction={nextFunction} label={label} bgImage={bgImage} />
  }

  return null
}

export default UncomfortableResult
