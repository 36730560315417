import React from 'react'
import Typography from '@material-ui/core/Typography'
import { Link } from '@reach/router'

export default ({ to, label }) => (
  <Link to={to}>
    <Typography
      style={{
        color: 'white',
        fontSize: '16px',
        marginBottom: '13px',
      }}
    >
      {label}
    </Typography>
  </Link>
)
