import pick from 'lodash/pick'

const allowList = [
  'id',
  'firstName',
  'middleName',
  'lastName',
  'birthdate',
  'gender',
  'ethnicity',
  'englishLanguageLearner',
  'individualizedEducationProgram',
  'educationPlan504',
  'freeReducedPriceMeals',
  'grade',
  'livingSituation',
  'email',
]

export default (update) => (params) => {
  const paramsToUpdate = pick(params, allowList)
  update(paramsToUpdate)
}
