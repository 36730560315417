import React, { useEffect, useMemo } from 'react'
import type { RouteComponent } from 'types'

import { navigateWithParams } from 'utils'
import NineToTwelve from './NineToTwelve'
import { useCheckIn } from 'checkIn/CheckInContext'
import { isOlderTeen, isStudent, isWeekendToday, resetCheckInIdempotencyKey } from 'students/helper'
import { getQSData } from 'QueryStringController'
import { navigate } from '@reach/router'
import { isWithinSchoolOpeningHours } from 'checkIn/helper'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { featureEnabled } from 'featureFlags'

const Intro: RouteComponent = () => {
  const { student } = useCheckIn()
  const { checkInAvailable } = student || {}
  const [{ currentUser }] = useCurrentUser()

  const outsideSchoolHours = useMemo(
    () => isWeekendToday() || !isWithinSchoolOpeningHours(checkInAvailable),
    [checkInAvailable],
  )

  const checkInUnavailable: boolean =
    isStudent(currentUser.role) && outsideSchoolHours && !isOlderTeen(student?.grade)

  const showMessages = featureEnabled(currentUser, 'message_center')

  useEffect(() => {
    resetCheckInIdempotencyKey()
  }, [student]) // eslint-disable-line

  const nextFunction = () => {
    const { impactMeasurement } = getQSData()
    impactMeasurement === 'true' ? navigateWithParams(`./impactQ1`) : navigate('./cadet')
  }
  if (student)
    return (
      <NineToTwelve
        student={student}
        handleNextFunction={() => nextFunction()}
        isCheckInAvailable={!checkInUnavailable}
        showMessages={showMessages}
      ></NineToTwelve>
    )
  return null
}
export default Intro
