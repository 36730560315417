import React from 'react'
import type { RouteComponent } from 'types'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import Teen from 'checkIn/steps/HowAreYouFeelingNow/Teen'
import Kid from 'checkIn/steps/HowAreYouFeelingNow/Kid'
import NonReader from 'checkIn/steps/HowAreYouFeelingNow/NonReader'
import { useActivity } from 'activities/activityContext'
import { navigate } from '@reach/router'
import { getQSData, setQSData } from 'QueryStringController'
import { isNinthGradeOrOlder } from 'students/helper'
import NineToTwelve from 'checkIn/nineToTwelve/FeelingNow/NineToTwelve'

const HowAreYouFeelingNow: RouteComponent = () => {
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'activitySessions',
    path: '/activity_sessions',
  })

  const { student } = useActivity()
  const [{ currentUser }] = useCurrentUser()
  const userId = currentUser?.id

  const flow = {
    nextFunction: (
      saveActivitySession: (options: any) => (value: any) => void,
      _userId: number,
      studentId: number,
    ) => {
      const newValue = {
        studentId,
        ...getQSData(),
      }
      saveActivitySession({
        afterSuccess: () => {
          navigate(`/check-in-history/${studentId}`, { replace: true })
        },
      })(newValue)
    },
  }

  if (student && userId) {
    switch (student.studentType) {
      case 'teen':
        return isNinthGradeOrOlder(student.grade) ? (
          <NineToTwelve
            nextFunction={(feelingNow: string) => {
              setQSData({ feelingNow: feelingNow })
              flow.nextFunction(save, currentUser.id, student.id)
            }}
            saving={saving}
            saveError={saveError}
          />
        ) : (
          <Teen
            flow={flow}
            studentId={student.id}
            userId={userId}
            saving={saving}
            saveError={saveError}
            save={save}
          />
        )
      case 'kid':
        return (
          <Kid
            flow={flow}
            studentId={student.id}
            userId={userId}
            saving={saving}
            saveError={saveError}
            save={save}
          />
        )
      case 'non-reader':
        return (
          <NonReader
            flow={flow}
            studentId={student.id}
            userId={userId}
            saving={saving}
            saveError={saveError}
            save={save}
          />
        )
    }
  }

  return null
}

export default HowAreYouFeelingNow
