import React, { useState } from 'react'
import { useCroods } from 'croods'
import { error, success } from 'components/Toast'
import { NumberInput } from './components/NumberInput'
import { DetailedSchoolInfo } from './components/DetailedSchoolInfo'
import { AdminScreen } from './components/AdminScreen'
import { Button, P, Row, SchoolCard, Title } from './components/styled'
import { MetabaseQueries } from './components/MetabaseQueries'
import { A } from './components/A'

export const DisconectSchoolFromDistrict = (props: any) => {
  const [schoolId, setSchoolId] = useState<number | string>('')
  const [showSchool, setShowSchool] = useState<boolean>(false)
  const [open, setOpen] = useState(false)

  const [{ info: s = [], infoError: schoolError }, { fetch: getSchools }] = useCroods({
    name: 'admin-school',
    customPath: 'admin/schools',
    afterSuccess: ({ data }) => (data.length > 0 ? setShowSchool(true) : null),
    afterFailure: () => error(schoolError || ''),
  })
  const [, { save }] = useCroods({
    name: 'admin-remove-district-from-school',
    customPath: 'admin/remove_district_from_school',
    afterSuccess: ({ data }) => success(data.message),
    afterFailure: ({ response }) => error(response?.data.message),
  })

  return (
    <AdminScreen title="Disconnect School from District" {...props}>
      <div style={{ alignSelf: 'center', maxWidth: '600px', width: '100%' }}>
        <P>Add the id of the school you want to disconnect from the district</P>
        <P>
          In case you don&apos;t have the school id, use one of the{' '}
          <A name="metabase queries" onClick={() => setOpen(true)} /> to find the school id.
        </P>
        <NumberInput label="School Id" name="schoolId" value={schoolId} setValue={setSchoolId} />

        <Button
          disabled={schoolId === ''}
          variant="edit"
          onClick={() => getSchools({ operation: 'info' })({ ids: [schoolId] || '' })}
        >
          Show School Data
        </Button>

        {showSchool && s?.length > 0 ? (
          <div style={{ maxWidth: '1000px', marginTop: '3rem', alignSelf: 'center' }}>
            <Title>School Data</Title>
            <SchoolCard key={s[0]?.schoolId}>
              {Boolean(s[0]) ? <DetailedSchoolInfo school={s[0]} /> : null}
              <Row>
                <Button variant="delete" onClick={() => save()({ schoolId })}>
                  DISCONNECT SCHOOL FROM DISTRICT
                </Button>
              </Row>
            </SchoolCard>
          </div>
        ) : null}
      </div>
      <MetabaseQueries open={open} onClose={() => setOpen(false)} />
    </AdminScreen>
  )
}
