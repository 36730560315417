import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import TeenBodySensations from 'checkIn/steps/BodySensations/TeenBodySensations'
import KidBodySensations from 'checkIn/steps/BodySensations/KidBodySensations'
import NonReaderBodySensations from 'checkIn/steps/BodySensations/NonReaderBodySensations'
import { setQSData } from 'QueryStringController'
import FiveToEightBodySensations from './FiveToEightBodySensations'
import { featureEnabled } from 'featureFlags'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import ThreeToFourBodySensations from './ThreeToFourBodySensations'

const SCREEN_NAME = 'bodySensations'

const BodySensations: RouteComponent = () => {
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()
  const [selectedSensations, setSelectedSensations] = React.useState([])

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    const handleNextFunction = () => {
      if (selectedSensations?.length > 0)
        setQSData({ bodySensations: selectedSensations.join(',') })

      nextFunction(window.location.search, currentUser)
    }

    switch (student.studentType) {
      case 'teen':
        if (featureEnabled(currentUser, 'new_teen_check_in'))
          return (
            <FiveToEightBodySensations
              handleNextFunction={handleNextFunction}
              selectedSensations={selectedSensations}
              setSelectedSensations={setSelectedSensations}
            />
          )
        return (
          <TeenBodySensations
            handleNextFunction={handleNextFunction}
            selectedSensations={selectedSensations}
            setSelectedSensations={setSelectedSensations}
          />
        )
      case 'kid':
        if (featureEnabled(currentUser, 'new_teen_check_in')) {
          return (
            <ThreeToFourBodySensations
              handleNextFunction={handleNextFunction}
              selectedSensations={selectedSensations}
              setSelectedSensations={setSelectedSensations}
            />
          )
        }
        return (
          <KidBodySensations
            handleNextFunction={handleNextFunction}
            selectedSensations={selectedSensations}
            setSelectedSensations={setSelectedSensations}
          />
        )
      case 'non-reader':
        return (
          <NonReaderBodySensations
            handleNextFunction={handleNextFunction}
            selectedSensations={selectedSensations}
            setSelectedSensations={setSelectedSensations}
          />
        )
    }
  }

  return null
}

export default BodySensations
