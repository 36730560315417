import React, { useState } from 'react'
import { reduxForm } from 'redux-form'
import { CircularProgress, TextField } from '@material-ui/core'
import { MdSend } from 'react-icons/md'
import styled from 'styled-components'

import Error from 'components/form/Error'
import { Row } from 'components/StudentItem'

const Button = styled.button`
  color: #e9573e;
  flex: 0 0 auto;
  padding: 12px;
  font-size: 1.5rem;
  &:disabled {
    color: rgba(0, 0, 0, 0.26);
  }
`

const Form = reduxForm({ form: 'comments' })(
  ({
    onSubmit,
    submitting,
    error: reduxFormError,
    submitError,
    invalid,
    handleSubmit,
    initialState = '',
    checkInId,
    currentUser,
  }) => {
    const [message, setMessage] = useState(initialState)

    const handleChange = (event) => setMessage(event.target.value)

    const injectSubmit = (create, props) => (data) => {
      const { email } = currentUser
      create({ ...data, ...props, checkInId, email })
      setMessage('')
    }

    const disabled = invalid || message.length < 1

    return (
      <form onSubmit={handleSubmit(injectSubmit(onSubmit, { message }))}>
        <Row>
          <TextField
            label="Add a message"
            fullWidth
            multiline
            maxRows={5}
            value={message}
            onChange={handleChange}
            inputProps={{
              'aria-label': 'input-comment',
            }}
          />
          {reduxFormError && <Error>{reduxFormError}</Error>}
          {submitError && <Error>{submitError}</Error>}
          {(submitting && <CircularProgress color="primary" size="60px" />) || (
            <Button type="submit" disabled={disabled}>
              <MdSend />
            </Button>
          )}
        </Row>
      </form>
    )
  },
)

export default Form
