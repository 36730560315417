import React from 'react'
import { EmailShareButton, EmailIcon } from 'react-share'
import { Container, Text, LinksContainer } from './style'

export const ShareClosegap = () => {
  const shareUrl = 'https://www.closegap.org'
  const title = 'Can we start using Closegap?'
  const body =
    'Hey there! \n' +
    "Please check out Closegap! It's an emotional wellness platform for K - 12 schools that combines social - emotional learning and a daily check -in. \n" +
    "Here's the website (https://www.closegap.org). \n" +
    'Thank you! \n'

  return (
    <Container>
      <Text>Share with:</Text>
      <LinksContainer>
        <EmailShareButton url={shareUrl} subject={title} body={body}>
          <EmailIcon size={35} round />
        </EmailShareButton>
      </LinksContainer>
    </Container>
  )
}
