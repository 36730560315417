import React from 'react'
import { Grid } from '@material-ui/core'

import Button from 'components/Button'
import { welcomePath } from 'students/helper'
import { navigate } from '@reach/router'
import rocket from './rocket.png'

const CheckInButton = ({ student }) => (
  <div className="fixed z-50 flex h-24 right-4 bottom-4 w-[250px]">
    <img src="/images/happy_cadet.png" alt="Happy cadet" />
    <Button
      style={{
        width: 'auto',
        height: 'auto',
        padding: '12px 18px',
      }}
      textProps={{
        style: {
          fontSize: 18,
        },
      }}
      onClick={() => navigate(welcomePath(student))}
    >
      <Grid container alignItems="center" justifyContent="center">
        <div style={{ textTransform: 'none', lineHeight: 'normal' }}>
          Start
          <br />
          Check-in
        </div>
        <img src={rocket} alt="rocket" height="30" style={{ marginLeft: 10 }} />
      </Grid>
    </Button>
  </div>
)

export default CheckInButton
