import { Button, Column, ItemCard, SubTitle, Title } from 'CsDashboard/components/styled'
import React from 'react'

type School = {
  id: string | number
  name: string
  address: string
}

interface Props {
  showAllStudents: (s: School) => void
  educator: { schools: School[] }
}

export const EducatorSchools = ({ educator, showAllStudents }: Props) => (
  <div style={{ alignSelf: 'center', width: '100%', maxWidth: '1200px' }}>
    <Title>Educator schools</Title>
    {educator?.schools?.map((s: School) => (
      <ItemCard key={s.id}>
        <Column>
          <SubTitle>
            {s.name} (id: {s.id})
          </SubTitle>
          {s.address}
        </Column>

        <Button variant="edit" onClick={() => showAllStudents(s)}>
          Show all students
        </Button>
      </ItemCard>
    ))}
  </div>
)
