import map from 'lodash/map'
import moment from 'moment'
import districtByUSAState from 'components/SchoolForm/districtByUSAState'
import { usaStates } from 'components/SchoolForm/usaStates'

export function fromStringArrayToOptionArray(arr = []) {
  return map(arr, (v) => ({
    label: v,
    value: v,
  }))
}

export const countryIsUSA = (countryCode) => countryCode?.toLowerCase() === 'us'

export const getStateLabel = (statekey) =>
  usaStates.filter((s) => s.value === statekey.toUpperCase())[0]?.label

export const getDistricts = (state, countryCode) =>
  (countryIsUSA(countryCode) &&
    state &&
    fromStringArrayToOptionArray(districtByUSAState[getStateLabel(state)])) ||
  []

export const objectToEncodedQueryString = (data) => `?${new URLSearchParams(data).toString()}`

export const queryStringToObject = (queryString) => {
  const entries = [...new URLSearchParams(queryString).entries()]
  const aux = {}
  entries.map(([key, value]) => (aux[key] = value)) // eslint-disable-line
  return aux
}

export const dateInXMonthsFromNow = (monthQuantity) =>
  moment().add(monthQuantity, 'months').toDate()
