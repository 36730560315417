import React, { useRef, useState } from 'react'
import { SearchContainer, Input, Icon, DropdownContent, Option, Label } from './style'
import { debounce } from 'lodash'
import { useCroods } from 'croods'
import { navigate } from '@reach/router'

export const SearchInput = (props) => {
  const [open, setOpen] = useState(false)

  const [{ list: schools, fetchingList: fetchingSchools }, { fetch: fetchSchools }] = useCroods({
    name: 'schools',
  })

  const delayedRequest = useRef(
    debounce(async (written) => {
      if (written && written.length >= 3) {
        setOpen(true)
        fetchSchools({
          customPath: `/schools?search=${encodeURIComponent(written)}`,
        })()
      } else {
        setOpen(false)
      }
    }, 500),
  ).current

  const handleOnChange = (e) => {
    props.setSchool(e.target.value)
    delayedRequest(e.target.value)
  }

  const onClick = (school) => {
    props.setSchool(`${school.id} ${school.name}`)
    setOpen(false)
    navigate('/confirm-school', { state: school })
  }

  return (
    <SearchContainer>
      <Input isOpen={open} placeholder={props.placeholder} type="text" onChange={handleOnChange} />
      {/* {fetchingSchools ? <Loader /> : } */}
      <Icon color="#9a9a9a" size={22} />

      {Boolean(Object.keys(props.value).length) ? <Label>{props.placeholder}</Label> : null}
      {open && !fetchingSchools && !(schools.length === 0) ? (
        <DropdownContent>
          {schools.map((school) => (
            <Option key={school.id} type="submit" onClick={() => onClick(school)}>
              {school.name}
              <p style={{ fontSize: '0.8rem' }}>{school.address}</p>
            </Option>
          ))}
        </DropdownContent>
      ) : null}
    </SearchContainer>
  )
}
