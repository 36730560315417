import React from 'react'
import type { RouteComponent } from 'types'

import NineToTwelve from './NineToTwelve'
import { navigate } from '@reach/router'

const Emotion: RouteComponent = () => {
  return <NineToTwelve handleNextFunction={() => navigate('/demo')}></NineToTwelve>
}
export default Emotion
