import React from 'react'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'

import Screen from 'checkIn/components/kid/Screen'
import ButtonNext from 'checkIn/components/ButtonNext'

const styles = (theme) => ({
  balloon: {
    background: theme.colors.roseWhite,
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '20px',
    alignItems: 'center',
    borderRadius: '42px',
    display: 'flex',
    width: '320px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '40px',
      width: '560px',
    },
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      padding: '0 30px',
    },
  },
  title: {
    color: theme.colors.cinnabar,
    textAlign: 'center',
    marginTop: '10px',
    marginBottom: '10px',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: 1.2,
    [theme.breakpoints.up('sm')]: {
      marginTop: '20px',
      marginBottom: '20px',
      fontSize: '24px',
    },
  },
  text: {
    fontSize: '18px',
    textAlign: 'center',
    color: theme.colors.steelGray,
    marginBottom: '20px',
    paddingLeft: '10px',
    paddingRight: '10px',
    [theme.breakpoints.up('sm')]: {
      marginBottom: '28px',
      fontSize: '22px',
    },

    '& small': {
      fontSize: '0.8em',
      display: 'inline-block',
    },
  },
  image: {
    height: '140px',
    [theme.breakpoints.up('sm')]: {
      height: '220px',
    },
    [theme.breakpoints.up('md')]: {
      height: '240px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '280px',
    },
  },
})

export default withStyles(styles)(
  ({ classes, nextFunction, needTalkToAnAdult }) => {
    const content = needTalkToAnAdult
      ? {
          title: 'Help is on the way!',
          text: 'No one feels their best all the time. All that matters is that you try.',
          buttonText: 'Okay, Thanks!',
          imgSrc: '/images/friends-supporting-cadet.png',
        }
      : {
          title: 'Don’t forget that you can always talk to an adult!',
          text: 'No one feels their best all the time. All that matters is that you try.',
          buttonText: 'Sure!',
          imgSrc: '/images/cool_down_cadet.png',
        }

    return (
      <Screen className="h-[100vh] mt-0">
        <div className={classes.balloon}>
          <Typography className={classes.title}>
            <span style={{ fontSize: '0.9rem' }}>Got it.</span>
            <br />
            <h1>{content.title}</h1>
          </Typography>
          <Typography className={classes.text} component="div">
            {content.text}
          </Typography>
          <ButtonNext
            className="w-[60%] mb-5 whitespace-nowrap px-2 sm:px-5"
            onClick={nextFunction}
          >
            <p className="text-base font-semibold sm:text-4xl">
              {content.buttonText}
            </p>
          </ButtonNext>
        </div>
        <img
          src={content.imgSrc}
          alt={content.title}
          className={classes.image}
        />
      </Screen>
    )
  },
)
