import React, { useEffect, useMemo, useState } from 'react'
import { navigate } from '@reach/router'

import { useMixpanel } from 'mixpanel'
import SecondaryEmotionButton from 'checkIn/components/teen/SecondaryEmotionButton'
import { ButtonNext } from 'checkIn/components/teen/ButtonNext'
import { IconMessage } from 'checkIn/components/teen/IconMessage'
import { isWithinSchoolOpeningHours } from 'checkIn/helper'
import { checkInTrack, getLocalStorageCheckIn, isWeekendToday, isOlderTeen } from 'students/helper'
import type { RouteComponent, Student } from 'types'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import { Title } from 'checkIn/components/teen/Title'
import { Paragraph } from 'checkIn/components/teen/Paragraph'
import { Subtitle } from 'checkIn/components/teen/Subtitle'
import { BsFillInfoCircleFill } from 'react-icons/bs'
import { Modal } from 'checkIn/components/teen/Modal'
import { Link } from 'checkIn/components/teen/Link'

type Props = {
  nextFunction: (params: string) => void
  student: Student
  isStudent?: boolean
  showActivities: boolean
}

const WelcomeTeen: RouteComponent<Props> = ({
  nextFunction,
  student,
  isStudent,
  showActivities,
}) => {
  const mixpanel = useMixpanel()
  const { hasUnseenMessages, id, grade, firstName, latestCheckIn, checkInAvailable } = student
  const studentData = { studentId: id, grade }
  const [open, setOpen] = useState(false)

  useEffect(() => {
    document.body.style.backgroundImage = `url('/images/moods/fiveToEight/neutral.png')`
  }, [])

  const outsideSchoolHours = useMemo(
    () => isWeekendToday() || !isWithinSchoolOpeningHours(checkInAvailable),
    [checkInAvailable],
  )

  const handleMyProfileButton = (qs: string = '') => {
    checkInTrack(mixpanel, 'View My Profile Clicked', studentData)
    navigate(`/check-in-history/${id}${qs}`)
  }

  const handleActivitiesButton = () => {
    checkInTrack(mixpanel, 'Activities Clicked', studentData)
    navigate(`/activities/${id}/choose-activity`)
  }

  const color = getLocalStorageCheckIn('color') || '#00ddc1'

  return (
    <CheckInContainer bg="/images/teen/cadet_upside_on_moon.png">
      <Title>{`It's great to see you, ${firstName}!`}</Title>

      {isStudent && outsideSchoolHours && !isOlderTeen(grade) && (
        <>
          <Paragraph>
            It's after school hours or the weekend! Feel free to view your profile, but you won't be
            able to check in until the next school day. We love you!
          </Paragraph>
          <div
            className="mt-10"
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              alignItems: 'center',
              marginBottom: '3rem',
            }}
          >
            <div style={{ position: 'relative' }}>
              {hasUnseenMessages && (
                <IconMessage onClick={() => handleMyProfileButton('?hasUnseenMessage=true')} />
              )}
              <ButtonNext onClick={() => handleMyProfileButton()}>View My Profile</ButtonNext>
            </div>
            {showActivities && (
              <ButtonNext onClick={() => handleActivitiesButton()} className="mt-4">
                <span className="font-bold">Explore Activities</span>
              </ButtonNext>
            )}
          </div>
        </>
      )}

      {(!isStudent || !outsideSchoolHours || isOlderTeen(grade)) && (
        <>
          {!latestCheckIn ? (
            <Paragraph>
              Before we get started, I want to let you know that your teacher, other staff at your
              school, and your parents may see the information you share here.
            </Paragraph>
          ) : null}
          {outsideSchoolHours && isStudent && isOlderTeen(grade) && (
            <Paragraph>
              It's after school hours or the weekend! Feel free to complete a check-in and view your
              profile, but please keep in mind, an educator won't be able to speak with you until
              the next school day. We love you!
            </Paragraph>
          )}

          <Subtitle color={color} style={{ margin: '1rem 0px 0px 0px' }}>
            Are you ready to check in?
          </Subtitle>

          <div style={{ display: 'flex', gap: '8px', alignItems: 'center', marginBottom: '3rem' }}>
            <BsFillInfoCircleFill size={20} color="#A3A3A3" />
            <Link onClick={() => setOpen(true)}>Why are check-ins important?</Link>
          </div>
          <Modal open={open} onClose={() => setOpen(false)} />

          <ButtonNext
            style={{ marginTop: '1rem' }}
            onClick={() => {
              checkInTrack(mixpanel, "Let's Start Clicked", studentData)
              nextFunction(window.location.search)
            }}
          >
            Let's Start!
          </ButtonNext>

          {isStudent && (
            <div
              className="mt-10"
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '0px',
                alignItems: 'center',
                marginBottom: '3rem',
              }}
            >
              <>
                {hasUnseenMessages && (
                  <IconMessage
                    className="mt-[-16px] ml-[150px]"
                    onClick={() => handleMyProfileButton('?hasUnseenMessage=true')}
                  />
                )}
                <SecondaryEmotionButton
                  style={{ width: 'auto' }}
                  onClick={() => handleMyProfileButton()}
                >
                  View My Profile
                </SecondaryEmotionButton>
              </>
              <SecondaryEmotionButton
                style={{ width: 'auto' }}
                onClick={() => handleActivitiesButton()}
                className="mt-4"
              >
                Explore Activities
              </SecondaryEmotionButton>
            </div>
          )}
        </>
      )}
    </CheckInContainer>
  )
}

export { WelcomeTeen }
