import createStore, { Store } from 'create-store'
import { toggleItem } from 'utils'

type State = {
  selectedStudents: number[]
}
const initialState: State = {
  selectedStudents: [],
}

const useGlobal = createStore(
  {
    addStudents: (store: Store, students: number[]) => {
      store.setState({
        selectedStudents: Array.from(
          new Set([...store.state.selectedStudents, ...students]),
        ),
      })
    },
    toggleStudent: (store: Store, id: number) => {
      store.setState({
        selectedStudents: toggleItem(store.state.selectedStudents, id),
      })
    },
    clearSelectedStudents: (store: Store) => {
      store.setState({
        selectedStudents: [],
      })
    },
  },
  initialState,
)

export { useGlobal }
