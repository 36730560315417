import React from 'react'
import { CalendarSettings } from 'Educators/ManageSchool/NewSchoolYear/SetUp/CalendarSettings'
import validateUserFields from 'auth/validateUserFields'
import { DashboardScreen } from 'components/DashboardScreen'
import { Container, makeStyles, Grid } from '@material-ui/core'

import type { User } from 'types'
import { useSchools } from 'Educators/SchoolsContext'
import Breadcrumbs from 'components/Breadcrumbs'

type Props = { currentUser: User }

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: '36px',
    letterSpacing: '0.5px',
    // @ts-ignore
    color: theme.colors.mardiGras,
  },
  lowerOpacity: {
    opacity: '50%',
  },
}))

const VacationSettingsV2 = ({ currentUser }: Props) => {
  const classes = useStyles()
  const { school } = useSchools()

  return (
    <DashboardScreen currentUser={currentUser}>
      <Container className={classes.root}>
        <Breadcrumbs
          paths={[
            { children: `Manage ${school.name}`, to: '/manage-school?resourceType=educators' },
          ]}
        >
          Calendar Settings
        </Breadcrumbs>
        <Grid style={{ alignItems: 'center', marginTop: '2em' }}>
          {!!school && !!school.id && (
            <CalendarSettings mode={'settings'} nextStepFn={() => {}} school={school} />
          )}
        </Grid>
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(VacationSettingsV2)
