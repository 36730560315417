import React from 'react'
import { navigate } from '@reach/router'
import SchoolsList from 'Educators/profile/edit/SchoolsList'

const MySchools = () => {
  const [schoolId] = React.useState(0)

  return (
    <div className="flex flex-col w-full">
      <p className="text-base font-medium text-secondary">My Schools</p>
      <div className="flex flex-col mb-6">
        <SchoolsList schoolId={schoolId} />
      </div>
      <button type="button" className="button" onClick={() => navigate('/search-school')}>
        ADD SCHOOL
      </button>
    </div>
  )
}

export default MySchools
