import React from 'react'
import { Tooltip } from '@material-ui/core'
import { navigate } from '@reach/router'

import { useScreenSize } from 'screenSize'
import FollowUpAction from 'components/FollowUpAction'
import DateTimeFormat from 'Educators/students/list/DateTimeFormat'
import { Comments } from 'Educators/students/list/Comments'
import { CheckInAnswers } from './CheckInAnswers'
import { Divider, Grade, Name, Row } from 'components/StudentItem'
import {
  Container,
  SecondArea,
  NameWrapper,
  StudentInfo,
  FirstArea,
  ThirdArea,
  WarningIcon,
  CheckIcon,
} from './styles'
import TriageFeedback from './TriageFeedback'
import { featureEnabled } from 'featureFlags'

export const StudentItem = ({ student, currentUser, fetchListStudents }) => {
  const { isMobile } = useScreenSize()
  const { firstName, lastName, grade, latestCheckIn } = student

  const IconNeedsHelp = ({ hasPositiveAfterNeedsHelp }) => (
    <Tooltip
      title={
        hasPositiveAfterNeedsHelp
          ? 'This student has since had a comfortable check-in. Please review their profile to decide how to respond.'
          : 'This student has multiple uncomfortable check-ins without follow-ups. Please review their profile to decide how to respond.'
      }
    >
      <div>{hasPositiveAfterNeedsHelp ? <CheckIcon /> : <WarningIcon />}</div>
    </Tooltip>
  )

  const onClickEdit = () => navigate(`/dashboard/students/${student.id}/edit`)

  return (
    <>
      <Container>
        <FirstArea>
          <DateTimeFormat date={latestCheckIn.createdAt} />
          {isMobile && (
            <FollowUpAction
              item={latestCheckIn}
              currentUser={currentUser}
              fetchListStudents={fetchListStudents}
            />
          )}
        </FirstArea>

        <SecondArea>
          <StudentInfo data-testid="info-student" onClick={onClickEdit}>
            <NameWrapper>
              <Name>
                {firstName} {lastName}
              </Name>

              <Row>
                <Grade>Grade {grade}</Grade>

                {(student.countNeedsHelpByCheckIn > 1 || student.hasPositiveAfterNeedsHelp) && (
                  <IconNeedsHelp hasPositiveAfterNeedsHelp={student.hasPositiveAfterNeedsHelp} />
                )}
              </Row>
            </NameWrapper>
            <CheckInAnswers data={latestCheckIn} />
          </StudentInfo>

          <div style={{ paddingLeft: '1rem' }}>
            <Comments student={student} currentUser={currentUser} checkIn={latestCheckIn} />
          </div>
        </SecondArea>

        {!isMobile && (
          <ThirdArea>
            <FollowUpAction
              item={latestCheckIn}
              currentUser={currentUser}
              fetchListStudents={fetchListStudents}
            />
          </ThirdArea>
        )}
      </Container>
      {featureEnabled(currentUser, 'help_score_v2') && (
        <TriageFeedback checkIn={latestCheckIn} currentUser={currentUser} />
      )}
      <Divider />
    </>
  )
}
