import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import { FUELPK2 } from 'fuel'
import { FUEL_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'
import Image from 'components/ResponsiveImage'
import { User } from 'types'
import { isWidthUp, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
// importing from ThreeToFour since they are already exported there, but this is a bit messy, definitely want to refactor this in the future
import {
  FuelButton,
  FuelButtonMobile,
  FuelContainerNoPaddingY,
  FuelContainerMobile,
} from './ThreeToFour'

const listFuelLink = Object.values(FUELPK2)

type Props = {
  selectFuelFunction: (value: string, user?: User) => void
  currentUser?: User
  width: Breakpoint
}

const NonReaderV2 = ({ selectFuelFunction, width, currentUser }: Props) => {

  return (
    <Screen
      className="!justify-start h-screen pt-0"
      label="Pick the color that matches your energy level."
      title="How much fuel is in your spaceship?"
      audios={FUEL_PAGE}
    >
      {isWidthUp('md', width) ? (
        <FuelContainerNoPaddingY className='pt-0'>
        {listFuelLink.map(({ value, label, image, bsColor }) => (
            <AudioPlayer
            key={value}
            className='relative'
            styleButton={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translate(0%, calc(-50% - 64px))'
            }}
            importAudio={FUEL_PAGE[value as keyof typeof FUEL_PAGE]}
          >
            <FuelButton key={value} onClick={() => selectFuelFunction(value, currentUser)} bsColor={bsColor}>
                <Image src={`/images/energyLevels/3to4/${image}`} alt={''} />
                <span className="mt-4">{label}</span>
            </FuelButton>
          </AudioPlayer>
        ))}
      </FuelContainerNoPaddingY>
      ) : (
        <FuelContainerMobile>
          {listFuelLink.map(({ value, label, image, bsColor }) => (
              <AudioPlayer
              key={value}
              className='relative'
              styleButton={{
                position: 'absolute',
                right: -20,
                top: '50%',
                transform: 'translate(0%, calc(-50% - 16px))'

              }}
              importAudio={FUEL_PAGE[value as keyof typeof FUEL_PAGE]}
              >
                <FuelButtonMobile
                  key={value}
                  onClick={() => selectFuelFunction(value, currentUser)}
                  bsColor={bsColor}
                >
                  <Image src={`/images/energyLevels/3to4/mobile/${image}`} alt={''} />
                  {label}
                </FuelButtonMobile>
            </AudioPlayer>
          ))}
        </FuelContainerMobile>
      )}

      <div className="relative bottom-0 z-0 w-full mt-5 md:mt-10 md:absolute">
        <BackAndNextButtons
          showBackButton
          hiddenNextButton
          audios={{
            buttonBack: FUEL_PAGE.buttonBack,
          }}
        />
      </div>
    </Screen>
  )
}

export default withWidth()(NonReaderV2)
