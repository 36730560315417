import React from 'react'
import styled from 'styled-components'
import Screen from 'checkIn/components/teen/Screen'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'
import { getLocalStorageCheckIn } from 'students/helper'

interface StyleProps {
  height: string
  filter: string
  color: string
}

const FuelContainer = styled.div`
  display: flex;
  height: 60vh;
  justify-content: flex-end;
  flex-direction: column;
  margin: 0 auto;
  position: relative;
  text-align: center;
  background-color: rgba(23, 23, 23, 0.5);
  gap: 0.5rem;
  padding: 1.5rem 1rem;
  border-radius: 1.5rem;
  max-width: 360px;
  width: 60%;
  @media screen and (min-width: 600px) {
    width: 100%;
  }
`

const EnergyContainer = styled.div<{ energy: StyleProps }>`
  background-image: ${({ energy }) => `linear-gradient(to bottom, ${energy.color}, #000000 150%)`};
  height: ${({ energy }) => energy.height};
  font-size: 1.25rem;
  width: 100%;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;

  @media screen and (min-width: 600px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const Energy = styled.div<{ energy: StyleProps }>`
  width: 100%;
  border-radius: 20px;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  border: none;
  color: #e5e5e5;
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
  background-image: ${({ energy }) =>
    `linear-gradient(to bottom, ${energy.filter}, rgba(0, 0, 0, 0.5) 60%, rgba(0, 0, 0, 0.9) 100%)`};
  text-decoration: none;
  @media screen and (min-width: 600px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

const contentByFuel = {
  green: {
    label: 'You picked Full and Ready!',
    title: 'Are you full of energy and ready for the rest of day? ',
    description: 'Full and Ready!',
    height: '100%',
    filter: 'rgba(255, 255, 255, 0.8)',
  },
  yellow: {
    label: 'You picked OK!',
    title: 'Are you not totally full but OK for the rest of the day? ',
    description: 'OK',
    height: '65%',
    filter: 'rgba(255, 255, 255, 0.2)',
  },
  blue: {
    label: 'You picked Low!',
    title: 'Are you low in energy and not ready for the rest of the day? ',
    description: 'Low',
    height: '35%',
    filter: 'rgba(0, 0, 0, 0.5)',
  },
  red: {
    label: 'You picked Empty!',
    title: 'Are you out of energy and not ready for the rest of the day? ',
    description: 'Empty',
    height: '15%',
    filter: 'rgba(0, 0, 0, 0.9)',
  },
}

type Props = {
  nextFunction: (value: string) => void
  value: keyof typeof contentByFuel
}

const Teen = ({ nextFunction, value }: Props) => {
  const { label, title, description, height, filter } = contentByFuel[value]
  const energy = { height, filter, color: getLocalStorageCheckIn('color') || '#03b2ad' }
  return (
    <Screen contentWrapperClassName="h-screen" label={label} title={title}>
      <FuelContainer>
        <EnergyContainer energy={energy}>
          <Energy energy={energy}>{description}</Energy>
        </EnergyContainer>
      </FuelContainer>

      <BackAndNextButtons
        showBackButton
        nextButtonOnClick={() => nextFunction(window.location.search)}
      />
    </Screen>
  )
}

export default Teen
