import React, { useEffect } from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'

import CircularElements, { CircularItem } from 'checkIn/components/CircularElements'

import Image from 'components/ResponsiveImage'
import Screen from 'checkIn/components/nonReader/Screen'
import {
  BODY_SENSATIONS_PAGE,
  CONFIRM_BODY_SENSATION_PAGE,
  HOW_ARE_YOU_FEELING_PAGE,
} from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'

import bodySensationsListV1 from 'checkIn/constants/nonReader/bodySensationsList'
import bodySensationsListV2 from 'checkIn/constants/nonReader/bodySensationsListV2'
import FeelingContent from 'checkIn/steps/BodySensations/NonReaderBodySensations/FeelingContent'

import { getQSData } from 'QueryStringController'
import { xor } from 'lodash'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import { featureEnabled } from 'featureFlags'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '80vw',
    height: '60vh',
    [theme.breakpoints.down('xs')]: {
      width: '45vw',
      height: 'auto',
    },
  },
  image: {
    display: 'block',
    width: '200px',
    height: '200px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
})

const NonReaderBodySensations = ({
  width,
  classes,
  handleNextFunction,
  selectedSensations,
  setSelectedSensations,
  currentUser,
}) => {
  const [isConfirmationPage, setIsConfirmationPage] = React.useState(false)

  const { feeling, bodySensations } = getQSData()

  const bodySensationsList = featureEnabled(currentUser, 'pk_2_check_in_2024') ? bodySensationsListV2 : bodySensationsListV1
  const startDeg = featureEnabled(currentUser, 'pk_2_check_in_2024') ? 0 : -20

  const feelingContent = bodySensationsList[feeling] || {
    label: '',
    options: [],
  }

  const moonStyle = !isWidthUp('sm', width)
    ? {
        position: 'relative',
        transform: null,
        top: 0,
        left: 0,
      }
    : null

  const handleMoon = (item) => {
    setSelectedSensations((prevState) => xor(prevState, [item]))
  }

  useEffect(() => {
    if (bodySensations) {
      setSelectedSensations(bodySensations.split(','))
    }
  }, [bodySensations]) // eslint-disable-line

  const screenAudios = isConfirmationPage ? CONFIRM_BODY_SENSATION_PAGE : BODY_SENSATIONS_PAGE

  const label =
    selectedSensations?.length > 0
      ? 'Pick as many body sensations as you want!'
      : feelingContent.label

  const title = isConfirmationPage
    ? feelingContent.confirmationTitle
    : 'How does that feel in your body?'

  return (
    <Screen
      label={isConfirmationPage ? 'Thanks for sharing your feelings.' : label}
      title={title}
      audios={{ autoplay: screenAudios[feeling], ...screenAudios }}
    >
      <CircularElements
        startDeg={startDeg}
        endDeg={240}
        distance="220px"
        className={classes.container}
        style={{ position: 'relative' }}
      >
        <FeelingContent
          isConfirmationPage={isConfirmationPage}
          feelingContent={feelingContent}
          selectedSensations={selectedSensations}
          moonStyle={moonStyle}
          handleMoon={handleMoon}
        />
        <CircularItem selected>
          <AudioPlayer
            className="hidden md:flex"
            styleButton={{ marginLeft: -50, marginBottom: 0 }}
            importAudio={HOW_ARE_YOU_FEELING_PAGE[feeling]}
          >
            <div className={classes.image}>
              <Image src={feelingContent.image} alt={feeling} />
            </div>
          </AudioPlayer>
        </CircularItem>
      </CircularElements>
      <BackAndNextButtons
        showBackButton
        nextButtonOnClick={() => {
          if (featureEnabled(currentUser, 'pk_2_check_in_2024')) handleNextFunction()
          else 
          isConfirmationPage
            ? handleNextFunction()
            : setIsConfirmationPage(true)
        }}
        audios={{
          buttonNext: screenAudios.buttonNext,
          buttonBack: screenAudios.buttonBack,
        }}
      />
    </Screen>
  )
}

export default withStyles(styles)(withWidth()(NonReaderBodySensations))
