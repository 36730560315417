import React from 'react'
import { Menu, MenuItem, makeStyles, IconButton, Button } from '@material-ui/core'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useCroods } from 'croods'
import { useFlash } from 'seasoned-flash'
import { navigate } from '@reach/router'

import { useSchools } from 'Educators/SchoolsContext'
import StyledDialog from 'components/StyledDialog'

const useStyles = makeStyles((theme) => ({
  buttonMore: {
    color: theme.colors.white,
  },
}))

const ButtonMore = ({ classroom }) => {
  const classes = useStyles()
  const { success, error } = useFlash()
  const [anchorEl, setAnchorEl] = React.useState(false)
  const [open, setOpen] = React.useState(false)
  const { school, schoolId } = useSchools()

  const [, { destroy }] = useCroods({
    id: classroom?.id ?? 0,
    stateId: schoolId,
    name: 'classrooms',
    path: `/schools/${schoolId}/classrooms`,
    afterSuccess: () => {
      success('Classroom deleted successfully')
      navigate('/manage-school')
    },
    afterFailure: () => {
      error('Failed to delete the classroom')
    },
  })

  const isMenuOpen = Boolean(anchorEl)

  const onClick = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton aria-label="menu" className={classes.buttonMore} onClick={handleMenuOpen}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        keepMounted
        open={isMenuOpen}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={() => {
            onClick()
            handleMenuClose()
          }}
        >
          Delete
        </MenuItem>
      </Menu>
      <StyledDialog
        open={open}
        onClose={onClose}
        scroll="body"
        title="Delete classroom"
        subtitle="When you delete a classroom, all educators and students will be removed from it but will remain a part of the school."
        description={`Are you sure you want to delete Classroom ${classroom?.name ?? ''} from ${
          school?.name ?? ''
        }?`}
        propsTitle={{ style: { color: '#260032' } }}
      >
        <Button color="primary" style={{ marginTop: 40 }} onClick={() => destroy()()}>
          DELETE
        </Button>
      </StyledDialog>
    </>
  )
}

export default ButtonMore
