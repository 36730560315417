import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;

  @media screen and (max-width: 600px) {
    padding: 0 1rem;
  }
`

export const SwitchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 1.9rem;
  line-height: 160%;
  text-align: center;
  color: #250032;
  margin: 1rem 0;
`

export const Text = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-size: 1rem;
  line-height: 160%;
  font-weight: 400;
  color: #353535;
  padding: 0.5rem 0;
`
