export default {
  happy: {
    label: 'You are feeling happy!',
    confirmationTitle: 'We all feel happy sometimes, and that’s awesome!',
    image: '/images/planet_happy.png',
    options: ['Relaxed', 'Awake', 'Light or Bubbly', 'Warm', 'Energetic', 'Heart Flutters'],
  },
  angry: {
    label: 'You are feeling angry.',
    confirmationTitle: 'We all feel angry sometimes, and that’s okay.',
    image: '/images/planet_angry.png',
    options: [
      'Shaky',
      'Head Hurts',
      'Hot or Red',
      'Stomach Hurts',
      'Stiff or Tense',
      'Breathing Hard',
    ],
  },
  sad: {
    label: 'You are feeling sad.',
    confirmationTitle: 'We all feel sad sometimes, and that’s okay.',
    image: '/images/planet_sad.png',
    options: [
      'Stuck or Frozen',
      'Head Hurts',
      'Stomach Hurts',
      'Empty',
      'Tired or Weak',
      'Disconnected',
    ],
  },
  worried: {
    label: 'You are feeling worried.',
    confirmationTitle: 'We all feel worried sometimes, and that’s okay.',
    image: '/images/planet_upset.png',
    options: [
      'Shaky or Fidgety',
      'Sick to my Stomach',
      'Heavy Chest or Heart',
      'Hot or Sweaty',
      'Breathing Fast',
      'Stuck or Frozen',
    ],
  },
  frustrated: {
    label: 'You are feeling frustrated.',
    confirmationTitle: 'We all feel frustrated sometimes, and that’s okay.',
    image: '/images/planet_frustrated.png',
    options: [
      'Stiff or Tense',
      'Head Hurts',
      'Shaky',
      'Hot or Red',
      'Breathing Hard',
      'Stuck or Frozen',
    ],
  },
  silly: {
    label: 'You are feeling silly!',
    confirmationTitle: 'We all feel silly sometimes, and that’s awesome.',
    image: '/images/planet_silly.png',
    options: [
      'Relaxed',
      'Comfy or at Ease',
      'Light or Bubbly',
      'Warm',
      'Energetic',
      'Heart Flutters',
    ],
  },
  calm: {
    label: 'You are feeling calm!',
    confirmationTitle: 'We all feel calm sometimes, and that’s awesome!',
    image: '/images/planet_good.png',
    options: [
      'Relaxed',
      'Clear or Awake',
      'Comfy or at Ease',
      'Warm',
      'Steady or Strong',
      'Calm Breathing',
    ],
  },
  tired: {
    label: 'You are feeling tired.',
    confirmationTitle: 'We all feel tired sometimes, and that’s okay.',
    image: '/images/planet_tired.png',
    options: [
      'Sleepy',
      'Fuzzy or Unclear',
      'Light or Empty',
      'Heavy or Full',
      'Spacey or Zoned Out',
      'Nothing or Numb',
    ],
  },
} as const
