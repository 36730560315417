import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { isStudent } from 'students/helper'
import { getQSData } from 'QueryStringController'

type Props = {
  handleNextFunction: () => void
  role: any
}

const NineToTwelve = ({ handleNextFunction, role }: Props) => {
  const { cadet } = getQSData()

  return (
    <Screen showSummary={false}>
      <div
        className="max-w-6xl text-center text-teal-200
text-xs font-bold uppercase font-['Roboto'] mb-4"
      >
        that's all!
      </div>
      <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        {isStudent(role)
          ? 'Thanks for sharing and exploring your emotions!'
          : "That's all for now. Return Closegap to an adult!"}
      </div>
      {cadet === 'true' && (
        <div className="relative mt-10">
          <img className="" src="/images/nineToTwelve/cadet-flag.png" alt="" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 bg-[url('/images/ninetotwelve/planet.png')] bg-center bg-cover bg-no-repeat bg-origin-border w-screen h-[100vw] rounded-full -z-10"></div>
        </div>
      )}
      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-20"
      >
        {isStudent(role) ? 'GO TO MY PROFILE' : 'DONE'}
      </NextButton>
    </Screen>
  )
}

export default NineToTwelve
