import * as React from 'react'
import { Typography } from '@material-ui/core'
import { roles } from 'roles'

import { Educator } from 'types'

const schoolRoles = {
  owner: 'School Level Access',
  viewer: 'Classroom Level Access',
} as const

type Props = {
  children?: React.ReactNode
  educator: Educator
  showEmail?: boolean
}

const EducatorUi = ({ educator, showEmail, children }: Props) => {
  const access =
    schoolRoles[educator.schoolRole] || schoolRoles.viewer
  return (
    <div className="flex mt-6">
      <div className="flex-grow">
        <Typography variant="subtitle2">{educator.displayName}</Typography>
        <Typography variant="body2">{access}</Typography>
        {showEmail && (
          <Typography variant="subtitle1">{educator.email}</Typography>
        )}
        <Typography variant="subtitle1">{roles[educator.role]}</Typography>
      </div>
      {children}
    </div>
  )
}

export default EducatorUi
