import WelcomePageAutoplay from 'assets/check-ins/non-reader/SarahSwitek_01_Introduction_1.mp3'
import WelcomePageCTA from 'assets/check-ins/non-reader/SarahSwitek_01_Introduction_2.mp3'
import WelcomePageProfile from 'assets/check-ins/non-reader/SarahSwitek_01_Introduction_3.mp3'
import WelcomePageWeekend from 'assets/check-ins/non-reader/SarahSwitek_01_Introduction_7.mp3'
import WelcomePageFirstCheckIn from 'assets/check-ins/non-reader/SarahSwitek_01_Introduction_8.mp3'
import StartPageCTA from 'assets/check-ins/non-reader/SarahSwitek_01_Introduction_4.mp3'
import StartPageAutoplay from 'assets/check-ins/non-reader/SarahSwitek_01_Introduction_5.mp3'

import FellingsPageAutoplay from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_9.mp3'
import OptionSilly from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_2.mp3'
import OptionFrustrated from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_3.mp3'
import OptionsWorried from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_4.mp3'
import OptionsSad from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_5.mp3'
import OptionsAngry from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_6.mp3'
import OptionsHappy from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_7.mp3'
import OptionsCalm from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_8.mp3'
import OptionsTired from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_1.mp3'
import OptionsScared from 'assets/check-ins/non-reader/SarahSwitek_02_HowAreYouFeeling_10.mp3'

import ConfirmationNo from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_1.mp3'
import ConfirmationYes from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_2.mp3'
import ConfirmationHappy from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_3.mp3'
import ConfirmationAngry from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_4.mp3'
import ConfirmationSad from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_5.mp3'
import ConfirmationTired from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_6.mp3'
import ConfirmationWorried from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_7.mp3'
import ConfirmationFrustrated from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_8.mp3'
import ConfirmationSilly from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_9.mp3'
import ConfirmationCalm from 'assets/check-ins/non-reader/SarahSwitek_03_HowAreYouFeeling-Confirmation_10.mp3'

import OptionsSleepy from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_1.mp3'
import BodySensationsSad from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_2.mp3'
import OptionsAwake from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_3.mp3'
import OptionsHeartFlutters from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_4.mp3'
import BodySensationsHappy from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_5.mp3'
import BodySensationsAngry from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_6.mp3'
import OptionsHeadHurts from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_7.mp3'
import OptionsStomachHurts from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_8.mp3'
import OptionsEmpty from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_9.mp3'
import OptionsTiredOrWeek from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_10.mp3'
import OptionsDisconnected from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_11.mp3'
import OptionsShakyOrFidgety from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_12.mp3'
import OptionsSickToMyStomach from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_13.mp3'
import OptionsHeavyChestOrHeart from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_14.mp3'
import OptionsHotOrSweaty from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_15.mp3'
import OptionsBreathingFast from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_16.mp3'
import BodySensationsWorried from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_17.mp3'
import OptionsStiffOrTense from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_18.mp3'
import OptionsHotOrRed from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_19.mp3'
import OptionsBreathingHard from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_20.mp3'
import OptionsStuckOrFrozen from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_21.mp3'
import BodySensationsFrustrated from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_22.mp3'
import OptionsClearOrAwake from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_23.mp3'
import OptionsSteadyOrStrong from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_24.mp3'
import BodySensationsCalm from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_25.mp3'
import OptionsCalmBreating from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_26.mp3'
import OptionsRelaxed from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_27.mp3'
import OptionsComfyOrAtEase from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_28.mp3'
import OptionsLightOrBubbly from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_29.mp3'
import OptionsWarm from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_30.mp3'
import OptionsEnergetic from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_31.mp3'
import BodySensationsSilly from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_32.mp3'
import OptionsShaky from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_33.mp3'
import OptionsFuzzyOrUnclear from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_34.mp3'
import OptionsLightOrEmpty from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_35.mp3'
import OptionsHeavyOrFull from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_36.mp3'
import OptionsSpaceyOrZonedOut from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_37.mp3'
import OptionsNothingOrNumb from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_38.mp3'
import BodySensationsNext from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_39.mp3'
import BodySensationsBack from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_40.mp3'
import BodySensationsTired from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_41.mp3'
import BodySensationsScared from 'assets/check-ins/non-reader/SarahSwitek_04_BodySensations_42.mp3'

import BodySensationConfirmationTired from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_1.mp3'
import BodySensationConfirmationCalm from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_2.mp3'
import BodySensationConfirmationSilly from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_3.mp3'
import BodySensationConfirmationWorried from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_4.mp3'
import BodySensationConfirmationFrustrated from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_5.mp3'
import BodySensationConfirmationSad from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_6.mp3'
import BodySensationConfirmationAngry from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_7.mp3'
import BodySensationConfirmationHappy from 'assets/check-ins/non-reader/SarahSwitek_05_BodySensations-Confirmation_8.mp3'

import EnergyLevelIntroduction from 'assets/check-ins/non-reader/SarahSwitek_06_EnergyLevel-Introduction_1.mp3'
import EnergyLevelAutoplay from 'assets/check-ins/non-reader/SarahSwitek_07_EnergyLevel_5.mp3'
import EnergyLevelFullAndReady from 'assets/check-ins/non-reader/SarahSwitek_07_EnergyLevel_4.mp3'
import EnergyLevelOk from 'assets/check-ins/non-reader/SarahSwitek_07_EnergyLevel_3.mp3'
import EnergyLevelLow from 'assets/check-ins/non-reader/SarahSwitek_07_EnergyLevel_2.mp3'
import EnergyLevelEmpty from 'assets/check-ins/non-reader/SarahSwitek_07_EnergyLevel_1.mp3'

import EnergyLevelConfirmationFullAndReady from 'assets/check-ins/non-reader/SarahSwitek_08_EnergyLevel-Confirmation_4.mp3'
import EnergyLevelConfirmationOk from 'assets/check-ins/non-reader/SarahSwitek_08_EnergyLevel-Confirmation_3.mp3'
import EnergyLevelConfirmationLow from 'assets/check-ins/non-reader/SarahSwitek_08_EnergyLevel-Confirmation_2.mp3'
import EnergyLevelConfirmationEmpty from 'assets/check-ins/non-reader/SarahSwitek_08_EnergyLevel-Confirmation_1.mp3'

import EnergyLevelIsComfy from 'assets/check-ins/non-reader/SarahSwitek_09_EnergyLevel-Conclusion_1.mp3'
import EnergyLevelNonComfy from 'assets/check-ins/non-reader/SarahSwitek_09_EnergyLevel-Conclusion_2.mp3'
import EnergyLevelNonComfyButWithEnergy from 'assets/check-ins/non-reader/SarahSwitek_09_EnergyLevel-Conclusion_3.mp3'

import QuestionsHasEaten from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_14.mp3'
import QuestionsYesIDid from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_9.mp3'
import QuestionsNoIDidnt from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_8.mp3'
import QuestionsHasSlept from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_1.mp3'
import QuestionsHurtOrSick from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_13.mp3'
import QuestionsYesIAm from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_7.mp3'
import QuestionsNoImNot from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_6.mp3'
import QuestionsOkAtHome from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_12.mp3'
import QuestionsYesItsOk from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_5.mp3'
import QuestionsNoItsNotOk from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_4.mp3'
import QuestionsBulliedAtSchool from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_11.mp3'
import QuestionsTalkToAnAdult from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_10.mp3'
import QuestionsYesPlease from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_3.mp3'
import QuestionsNoTanks from 'assets/check-ins/non-reader/SarahSwitek_10_Questions_2.mp3'

import QuestionsConclusionResponseYes from 'assets/check-ins/non-reader/SarahSwitek_11_Questions-Conclusion_1.mp3'
import QuestionsConclusionOkThanks from 'assets/check-ins/non-reader/SarahSwitek_11_Questions-Conclusion_2.mp3'
import QuestionsConclusionResponseNo from 'assets/check-ins/non-reader/SarahSwitek_11_Questions-Conclusion_3.mp3'
import QuestionsConclusionSure from 'assets/check-ins/non-reader/SarahSwitek_11_Questions-Conclusion_4.mp3'

import ActivitiesChooseIntervention from 'assets/check-ins/non-reader/SarahSwitek_12_Activities-List_6.mp3'
import ActivitiesInTheMeantimeChooseIntervention from 'assets/check-ins/non-reader/SarahSwitek_12_Activities-List_5.mp3'
import ActivitiesBreathing from 'assets/check-ins/non-reader/SarahSwitek_12_Activities-List_4.mp3'
import ActivitiesBreathingInstructions from 'assets/check-ins/non-reader/SarahSwitek_13_Activities-Instructions_5.mp3'
import ActivitiesLetItGo from 'assets/check-ins/non-reader/SarahSwitek_12_Activities-List_1.mp3'
import ActivitiesLetItGoInstructions from 'assets/check-ins/non-reader/SarahSwitek_13_Activities-Instructions_2.mp3'
import ActivitiesSchakeItOut from 'assets/check-ins/non-reader/SarahSwitek_12_Activities-List_3.mp3'
import ActivitiesSchakeItOutInstructions from 'assets/check-ins/non-reader/SarahSwitek_13_Activities-Instructions_4.mp3'
import ActivitiesPowerPose from 'assets/check-ins/non-reader/SarahSwitek_12_Activities-List_2.mp3'
import ActivitiesPowerPoseInstructions from 'assets/check-ins/non-reader/SarahSwitek_13_Activities-Instructions_3.mp3'
import ActivitiesDone from 'assets/check-ins/non-reader/SarahSwitek_13_Activities-Instructions_1.mp3'

import ActivitiesConclusionHowAreYouFeelingNow from 'assets/check-ins/non-reader/SarahSwitek_14_ActivitiesConclusion_2.mp3'
import ActivitiesConclusionBetter from 'assets/check-ins/non-reader/SarahSwitek_14_ActivitiesConclusion_3.mp3'
import ActivitiesConclusionAboutTheSame from 'assets/check-ins/non-reader/SarahSwitek_14_ActivitiesConclusion_1.mp3'
import ActivitiesConclusionWorse from 'assets/check-ins/non-reader/SarahSwitek_14_ActivitiesConclusion_4.mp3'

import EndOfCheckInAdultOtherFuel from 'assets/check-ins/non-reader/SarahSwitek_15_ThatsAll_1.mp3'
import EndOfCheckInStudentOtherFuel from 'assets/check-ins/non-reader/SarahSwitek_15_ThatsAll_2.mp3'
import EndOfCheckInAdultGreen from 'assets/check-ins/non-reader/SarahSwitek_15_ThatsAll_3.mp3'
import EndOfCheckInStudentGreen from 'assets/check-ins/non-reader/SarahSwitek_15_ThatsAll_4.mp3'
import EndOfCheckInGoToMyProfile from 'assets/check-ins/non-reader/SarahSwitek_15_ThatsAll_5.mp3'
import EndOfCheckInLogout from 'assets/check-ins/non-reader/SarahSwitek_15_ThatsAll_6.mp3'

export const WELCOME_PAGE = {
  autoplay: WelcomePageAutoplay,
  buttonStart: WelcomePageCTA,
  firstCheckIn: WelcomePageFirstCheckIn,
  weekend: WelcomePageWeekend,
  buttonProfile: WelcomePageProfile,
}

export const START_PAGE = {
  autoplay: StartPageAutoplay,
  buttonGo: StartPageCTA,
}

export const HOW_ARE_YOU_FEELING_PAGE = {
  autoplay: FellingsPageAutoplay,
  happy: OptionsHappy,
  angry: OptionsAngry,
  sad: OptionsSad,
  worried: OptionsWorried,
  frustrated: OptionFrustrated,
  silly: OptionSilly,
  calm: OptionsCalm,
  tired: OptionsTired,
  scared: OptionsScared,
}

export const CONFIRM_HOW_ARE_YOU_FEELING_PAGE = {
  happy: ConfirmationHappy,
  angry: ConfirmationAngry,
  sad: ConfirmationSad,
  worried: ConfirmationWorried,
  frustrated: ConfirmationFrustrated,
  silly: ConfirmationSilly,
  calm: ConfirmationCalm,
  tired: ConfirmationTired,
  buttonNext: ConfirmationYes,
  buttonBack: ConfirmationNo,
}

export const BODY_SENSATIONS_PAGE = {
  happy: BodySensationsHappy,
  angry: BodySensationsAngry,
  sad: BodySensationsSad,
  worried: BodySensationsWorried,
  frustrated: BodySensationsFrustrated,
  silly: BodySensationsSilly,
  calm: BodySensationsCalm,
  tired: BodySensationsTired,
  scared: BodySensationsScared,
  // options
  sleepy: OptionsSleepy,
  awake: OptionsAwake,
  'heart-flutters': OptionsHeartFlutters,
  'head-hurts': OptionsHeadHurts,
  'stomach-hurts': OptionsStomachHurts,
  empty: OptionsEmpty,
  'tired-or-weak': OptionsTiredOrWeek,
  disconnected: OptionsDisconnected,
  'shaky-or-fidgety': OptionsShakyOrFidgety,
  'sick-to-my-stomach': OptionsSickToMyStomach,
  'heavy-chest-or-heart': OptionsHeavyChestOrHeart,
  'hot-or-sweaty': OptionsHotOrSweaty,
  'breathing-fast': OptionsBreathingFast,
  'stiff-or-tense': OptionsStiffOrTense,
  'hot-or-red': OptionsHotOrRed,
  'breathing-hard': OptionsBreathingHard,
  'stuck-or-frozen': OptionsStuckOrFrozen,
  'clear-or-awake': OptionsClearOrAwake,
  'steady-or-strong': OptionsSteadyOrStrong,
  'calm-breathing': OptionsCalmBreating,
  relaxed: OptionsRelaxed,
  'comfy-or-at-ease': OptionsComfyOrAtEase,
  'light-or-bubbly': OptionsLightOrBubbly,
  warm: OptionsWarm,
  energetic: OptionsEnergetic,
  shaky: OptionsShaky,
  'fuzzy-or-unclear': OptionsFuzzyOrUnclear,
  'light-or-empty': OptionsLightOrEmpty,
  'heavy-or-full': OptionsHeavyOrFull,
  'spacey-or-zoned-out': OptionsSpaceyOrZonedOut,
  'nothing-or-numb': OptionsNothingOrNumb,
  // buttons
  buttonNext: BodySensationsNext,
  buttonBack: BodySensationsBack,
}

export const CONFIRM_BODY_SENSATION_PAGE = {
  tired: BodySensationConfirmationTired,
  calm: BodySensationConfirmationCalm,
  silly: BodySensationConfirmationSilly,
  worried: BodySensationConfirmationWorried,
  frustrated: BodySensationConfirmationFrustrated,
  sad: BodySensationConfirmationSad,
  angry: BodySensationConfirmationAngry,
  happy: BodySensationConfirmationHappy,
  buttonNext: BodySensationsNext,
  buttonBack: BodySensationsBack,
}

export const FUEL_INTRO_PAGE = {
  autoplay: EnergyLevelIntroduction,
  buttonNext: BodySensationsNext,
  buttonBack: BodySensationsBack,
}

export const FUEL_PAGE = {
  autoplay: EnergyLevelAutoplay,
  green: EnergyLevelFullAndReady,
  yellow: EnergyLevelOk,
  blue: EnergyLevelLow,
  red: EnergyLevelEmpty,
  buttonBack: BodySensationsBack,
}

export const FUEL_CONFIRMATION_PAGE = {
  green: EnergyLevelConfirmationFullAndReady,
  yellow: EnergyLevelConfirmationOk,
  blue: EnergyLevelConfirmationLow,
  red: EnergyLevelConfirmationEmpty,
  buttonNext: ConfirmationYes,
  buttonBack: ConfirmationNo,
}

export const FUEL_CONCLUSION_PAGE = {
  isComfy: EnergyLevelIsComfy,
  nonComfy: EnergyLevelNonComfy,
  nonComfyButWithEnergy: EnergyLevelNonComfyButWithEnergy,
  buttonIsComfyNext: EnergyLevelOk,
  buttonNonComfyNext: QuestionsConclusionSure,
}

export const QUESTIONS_PAGE = {
  'has-eaten': QuestionsHasEaten,
  'yes-i-did': QuestionsYesIDid,
  'no-i-didnt': QuestionsNoIDidnt,
  'has-slept': QuestionsHasSlept,
  'yes-i-am': QuestionsYesIAm,
  'no-im-not': QuestionsNoImNot,
  'hurt-or-sick': QuestionsHurtOrSick,
  'ok-at-home': QuestionsOkAtHome,
  'yes-its-ok': QuestionsYesItsOk,
  'no-its-not-ok': QuestionsNoItsNotOk,
  'bullied-at-school': QuestionsBulliedAtSchool,
  'talk-to-an-adult': QuestionsTalkToAnAdult,
  'yes-please': QuestionsYesPlease,
  'no-thanks': QuestionsNoTanks,
  yes: ConfirmationYes,
  no: ConfirmationNo,
}

export const QUESTIONS_CONCLUSION_PAGE = {
  'response-yes': QuestionsConclusionResponseYes,
  'ok-thanks': QuestionsConclusionOkThanks,
  'response-no': QuestionsConclusionResponseNo,
  sure: QuestionsConclusionSure,
}

export const ACTIVITIES_PAGE = {
  'choose-intervation': ActivitiesChooseIntervention,
  'in-the-meantime-choose-intervation': ActivitiesInTheMeantimeChooseIntervention,
  breathing: ActivitiesBreathing,
  'breathing-instructions': ActivitiesBreathingInstructions,
  'let-it-go': ActivitiesLetItGo,
  'let-it-go-instructions': ActivitiesLetItGoInstructions,
  'shake-it-out': ActivitiesSchakeItOut,
  'shake-it-out-instructions': ActivitiesSchakeItOutInstructions,
  'power-pose': ActivitiesPowerPose,
  'power-pose-instructions': ActivitiesPowerPoseInstructions,
  done: ActivitiesDone,
}

export const ACTIVITIES_CONCLUSION_PAGE = {
  'how-are-you-feeling-now': ActivitiesConclusionHowAreYouFeelingNow,
  better: ActivitiesConclusionBetter,
  same: ActivitiesConclusionAboutTheSame,
  worse: ActivitiesConclusionWorse,
}

export const END_OF_CHECK_IN_PAGE = {
  educatorGreenAutoplay: EndOfCheckInAdultGreen,
  educatorOtherAutoplay: EndOfCheckInAdultOtherFuel,
  studentGreenAutoplay: EndOfCheckInStudentGreen,
  studentOtherAutoplay: EndOfCheckInStudentOtherFuel,
  buttonNext: ActivitiesDone,
  goToMyProfile: EndOfCheckInGoToMyProfile,
  logout: EndOfCheckInLogout,
}
