import React from 'react'
import { Field } from 'redux-form'
import { required, email, date } from 'redux-form-validators'
import { FormControlLabel, Radio, Typography } from '@material-ui/core'

import Input from 'components/form/Input'
import MaskedTextField from 'components/MaskedTextField'
import Select from 'components/form/Select'
import RadioGroup from 'components/form/RadioGroup'

import LIVING_SITUATION from 'livingSituation'
import GRADES from 'grades'
import GENDER from 'gender'
import RACE_ETHNICITY from 'raceEthnicity'

export const FirstName = ({ disabled }) => (
  <Field
    component={Input}
    name="firstName"
    label="First Name*"
    type="text"
    disabled={disabled}
    validate={[required()]}
  />
)

export const MiddleName = ({ disabled }) => (
  <Field component={Input} disabled={disabled} name="middleName" label="Middle Name" type="text" />
)

export const LastName = ({ disabled }) => (
  <Field
    component={Input}
    name="lastName"
    label="Last Name*"
    type="text"
    disabled={disabled}
    validate={[required()]}
  />
)

export const Birthdate = ({ disabled }) => (
  <Field
    disabled={disabled}
    name="birthdate"
    label="Birthdate*"
    component={MaskedTextField}
    props={{
      mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/],
    }}
    validate={[required(), date({ format: 'mm/dd/yyyy' })]}
  />
)

export const Grade = ({ disabled }) => (
  <Field
    component={Select}
    disabled={disabled}
    name="grade"
    label="Grade*"
    validate={[required()]}
    options={GRADES}
  />
)

export const Gender = ({ disabled }) => (
  <Field
    component={Select}
    disabled={disabled}
    name="gender"
    label="Gender*"
    validate={[required()]}
    options={GENDER}
  />
)

export const RaceEthnicity = ({ disabled }) => (
  <Field
    component={Select}
    disabled={disabled}
    name="ethnicity"
    label="Race/Ethnicity"
    validate={[]}
    options={RACE_ETHNICITY}
  />
)

export const EmailAddress = ({ disabled = false, label = 'Email Address*', isRequired = true }) => (
  <Field
    component={Input}
    disabled={disabled}
    name="email"
    label={label}
    type="email"
    validate={isRequired ? [required(), email()] : []}
  />
)

export const EnglishLanguageLearner = () => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography color="textSecondary" style={{ marginRight: 16 }}>
      ELL (English Language Learner)
    </Typography>
    <Field
      name="englishLanguageLearner"
      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
      component={RadioGroup}
    >
      <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
      <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
    </Field>
  </div>
)

export const IndividualizedEducationProgram = () => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography color="textSecondary" style={{ marginRight: 16 }}>
      IEP (Individualized Education Program)
    </Typography>
    <Field
      name="individualizedEducationProgram"
      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
      component={RadioGroup}
    >
      <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
      <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
    </Field>
  </div>
)

export const EducationalPlan = () => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography color="textSecondary" style={{ marginRight: 16 }}>
      504 Plan
    </Typography>
    <Field
      name="educationPlan504"
      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
      component={RadioGroup}
    >
      <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
      <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
    </Field>
  </div>
)

export const FreeReducedPriceMeals = () => (
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <Typography color="textSecondary" style={{ marginRight: 16 }}>
      Free/Reduced Price Meals
    </Typography>
    <Field
      name="freeReducedPriceMeals"
      style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap' }}
      component={RadioGroup}
    >
      <FormControlLabel value="no" control={<Radio color="primary" />} label="No" />
      <FormControlLabel value="yes" control={<Radio color="primary" />} label="Yes" />
    </Field>
  </div>
)

export const LivingSituation = ({ disabled }) => (
  <Field
    component={Select}
    disabled={disabled}
    name="livingSituation"
    label="Living Situation"
    options={LIVING_SITUATION}
  />
)
