import React from 'react'
import { Router } from '@reach/router'

import Emotion from './Emotion'
import EmotionConfirmation from './EmotionConfirmation'
import BodySensations from './BodySensations'
import EnergyLevelIntro from './EnergyLevelIntro'
import EnergyLevel from './EnergyLevel'
import EnergyLevelConfirmation from './EnergyLevelConfirmation'
import ComfortableResult from './ComfortableResult'
import UncomfortableResult from './UncomfortableResult'
import Questions from './Questions'
import ShareAnythingElse from './ShareAnythingElse'
import { TalkToAdult } from './TalkToAdult'
import TalkToAnAdultResponse from './TalkToAnAdultResponse'
import ChooseActivity from './ChooseActivity'
import Activities from './Activities'
import HowAreYouFeelingNow from './HowAreYouFeelingNow'
import ThanksForExploring from './ThanksForExploring'
import EmotionIntensity from './EmotionIntensity'
import { RouteComponent } from 'types'

const CheckInRouter: RouteComponent = () => (
  <Router>
    <Emotion path="choose-emotion" />
    <EmotionConfirmation path="confirm-emotion" />
    <EmotionIntensity path="emotion-intensity" />
    <BodySensations path="body-sensations" />
    <EnergyLevelIntro path="energy-level-intro" />
    <EnergyLevel path="energy-level" />
    <EnergyLevelConfirmation path="energy-level-confirmation" />
    <ComfortableResult path="comfortable-result" />
    <UncomfortableResult path="uncomfortable-result" />
    <Questions path="questions/hasEaten" />
    <Questions path="questions/hasSlept" />
    <Questions path="questions/hurtOrSick" />
    <Questions path="questions/okAtHome" />
    <Questions path="questions/bulliedAtSchool" />
    <Questions path="questions/feelingStressed" />
    <Questions path="questions/feelingLonely" />
    <Questions path="questions/overwhelmedByWorkload" />
    <Questions path="questions/okInSocialLife" />
    <Questions path="questions/feelingTired" />
    <Questions path="questions/feelingWorthless" />
    <ShareAnythingElse path="share-anything-else" />
    <TalkToAdult path="talk-to-an-adult" />
    <TalkToAnAdultResponse path="talk-to-an-adult-response" />
    <ChooseActivity path="choose-activity" />
    <Activities path="activities/breathing" />
    <Activities path="activities/shake-it-out" />
    <Activities path="activities/power-pose" />
    <Activities path="activities/let-it-go" />
    <Activities path="activities/lets-make-a-list" />
    <Activities path="activities/journaling" />
    <Activities path="activities/the-ok-inventory" />
    <Activities path="activities/safe-space" />
    <HowAreYouFeelingNow path="how-are-you-feeling-now" />
    <ThanksForExploring path="thanks-for-exploring" />
  </Router>
)

export default CheckInRouter
