import React from 'react'
import { useCroods } from 'croods'
import { useCurrentUser } from 'dmcroods-auth'

import MySchools from 'Educators/profile/edit/MySchools'
import moment from 'moment'
import Form from '../form/Form'
import filterParams from './filterParams'
import { success } from 'components/Toast'

export default ({ user }) => {
  const [, setCurrentUser] = useCurrentUser()
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'users',
    path: `/auth`,
    afterSuccess: (res) => {
      if (Boolean(res)) {
        setCurrentUser({ ...res.data.data }, true)
        success('User data updated successfully')
      }
    },
  })

  return (
    <Form
      onSubmit={filterParams(save({ method: 'put' }))}
      submitting={saving}
      submitError={saveError}
      initialValues={{
        ...user,
        birthdate: user?.birthdate ? moment(user.birthdate).format('MM/DD/YYYY') : '',
      }}
      title={user.displayName}
    >
      <MySchools />
    </Form>
  )
}
