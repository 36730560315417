import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'

import { ActivitySession, CheckIn, Student, User } from 'types'
import { ActivitySessionDetails } from './ActivitySessionDetails'
import { CheckInDetails } from './CheckInDetails'
import { isEducator } from 'students/helper'

const useStyles = makeStyles((theme) => ({
  card: {
    margin: theme.spacing(2, 0),
    overflow: 'visible',
  },
  header: {
    backgroundColor: theme.palette.grey[100],
  },
  headerAction: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: 0,
    marginLeft: 0,
  },
  title: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    letterSpacing: '0.194444px',
    width: '100%',
    // @ts-ignore
    color: theme.colors.mardiGras,
  },
}))

type Props = {
  currentUser: User
  student: Student
  index: number
  title: string
  items: (CheckIn | ActivitySession)[]
}

const CardHistoryV2 = ({ currentUser, title, items, student, index }: Props) => {
  const classes = useStyles()
  return (
    <Card className={classes.card}>
      <CardHeader
        title={title}
        action={
          isEducator(currentUser.role) ? (
            <Typography className={classes.headerAction} color="textSecondary">
              Follow Up
            </Typography>
          ) : null
        }
        classes={{ root: classes.header, title: classes.title }}
      />

      <CardContent>
        {items.map((item, emotionIndex) => {
          return item.type === 'ActivitySession' ? (
            <ActivitySessionDetails key={item.id} activitySession={item as ActivitySession} />
          ) : (
            <CheckInDetails
              firstItem={index === 0 && emotionIndex === 0}
              student={student}
              key={item.id}
              item={item as CheckIn}
              currentUser={currentUser}
            />
          )
        })}
      </CardContent>
    </Card>
  )
}

export default CardHistoryV2
