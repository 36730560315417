import React from 'react'
import { Fetch } from 'croods'
import map from 'lodash/map'

import Chart from 'Educators/students/insights/Chart'
import Loading from 'components/Loading'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { useScreenSize } from 'screenSize'

export const Triage = ({ filter, schoolId }) => {
  const { isMobile } = useScreenSize()
  return filter ? (
    <Fetch
      name="triageChart"
      path={`/schools/${schoolId}/insights/triage/chart?${map(
        pickBy(filter, identity),
        (value, key) => `${key}=${value}`,
      ).join('&')}`}
      renderLoading={Loading}
      render={(chart) => (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          className="div-chart-table-to-print"
        >
          {chart && (
            <Chart
              list={chart}
              listFill={['#32ad45', '#f9b233', '#b00013']}
              propsBarChart={{
                className: 'triage-chart-to-print',
                width: isMobile ? 320 : 600,
                height: 350,
              }}
            />
          )}
        </div>
      )}
    />
  ) : null
}
