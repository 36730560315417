import React from 'react'
import { Router } from '@reach/router'

import Activities from 'activities'
import Activity from 'checkIn/steps/Activities/standalone'
import ChooseActivity from 'checkIn/steps/ChooseActivity/standalone'
import HowAreYouFeelingNow from 'checkIn/steps/HowAreYouFeelingNow/standalone'
import ThanksForExploring from 'checkIn/steps/ThanksForExploring/standalone'

export const ActivityRouter = () => (
  <Router>
    <Activities path="/activities/:studentId">
      <ChooseActivity path="/choose-activity" />
      <Activity path="/:activityId" />
      <HowAreYouFeelingNow path="/how-are-you-feeling-now" />
      <ThanksForExploring path="/thanks-for-exploring" />
    </Activities>
  </Router>
)

