import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { TalkToAnAdultKid } from './Kid'

const SCREEN_NAME = 'talkToAnAdult'

export const TalkToAdult: RouteComponent = () => {
  const { student } = useCheckIn()

  if (!student) return null

  return <TalkToAnAdultKid flow={FLOWS.kid[SCREEN_NAME]} />
}
