const FUEL = {
  FULL_AND_READY: { value: 'green', label: 'Full and Ready!' },
  OK: { value: 'yellow', label: 'OK' },
  LOW: { value: 'blue', label: 'Low' },
  EMPTY: { value: 'red', label: 'Empty' },
} as const

export const FUELV2 = {
  EMPTY: {
    value: 'red',
    nineToTwelveLabel: 'Drained',
    label: 'Empty',
    image: 'empty.png',
    bsColor: '#014940',
    bsColor3to4: '#8F0A00',
  },
  LOW: {
    value: 'blue',
    nineToTwelveLabel: 'Low',
    label: 'Low',
    image: 'low.png',
    bsColor: '#02A490',
    bsColor3to4: '#E9573F',
  },
  OK: {
    value: 'yellow',
    nineToTwelveLabel: 'Steady',
    label: 'OK',
    image: 'ok.png',
    bsColor: '#01D9BE',
    bsColor3to4: '#F9B233',
  },
  FULL_AND_READY: {
    value: 'green',
    nineToTwelveLabel: 'Full',
    label: 'Full',
    image: 'full.png',
    bsColor: '#B6F5ED',
    bsColor3to4: '#31AD45',
  },
  WAY_TOO_MUCH: {
    value: 'orange',
    nineToTwelveLabel: 'Overdrive',
    label: 'Way too much!',
    image: 'way_too_much.png',
    bsColor: '#B6F5ED',
    bsColor3to4: '#175FD2',
  },
}

export const FUELPK2 = {
  EMPTY: {
    value: 'red',
    label: 'Empty',
    image: 'empty.png',
    bsColor: '#8F0A00',
  },
  LOW: {
    value: 'blue',
    label: 'Low',
    image: 'low.png',
    bsColor: '#E9573F',
  },
  OK: {
    value: 'yellow',
    label: 'Just OK',
    image: 'ok.png',
    bsColor: '#F9B233',
  },
  FULL_AND_READY: {
    value: 'green',
    label: 'Full and ready',
    image: 'full.png',
    bsColor: '#31AD45',
  },
}

export default FUEL
