import React, { useEffect, useState } from 'react'
import { Title, Subtitle } from 'checkIn/components/teen/Title'
import { setLocalStorageCheckIn } from 'students/helper'
import styled from 'styled-components'

type Props = {
  nextFunction: (params: string) => void
}

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  padding: 6rem;
  text-align: center;
`

const MoodsContainer = styled.div`
  position: absolute;
  // width: 85%;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  // margin: 2rem 0;
  display: flex;
  gap: 3rem;
  justify-content: space-around;
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    // position: unset;
    // top: unset;
    // left: unset;
    // transform: unset;
    gap: 2rem;
  }

  &.selected {
    gap: 0;
    transition: all linear 0.5s;
  }
`

const MoodButton = styled.button<{ background: string }>`
  max-height: 266px;
  max-width: 266px;
  height: 25vw;
  width: 25vw;
  background-image: ${({ background }) => `url(/images/moods/fiveToEight/${background}.png)`};
  transition: all ease 0.25s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 50% 50% 0px 0px;

  :hover {
    outline: 2px solid rgba(255, 255, 255, 0.4);
    outline-offset: 0.4rem;
  }
  @media screen and (max-width: 600px) {
    border-radius: 1rem;
    height: 25vw;
    width: 80vw;
  }

  &.selected {
    // width: 100vw;
    // height: 100vh;
    // border-radius: 0;
    animation: expand;
    animation-duration: 0.75s;
    // transition: all ease-in 0.5s;
    animation-delay: 0.25s;
    animation-composition: replace;
    :hover {
      outline: none;
      outline-offset: none;
    }
  }

  @keyframes expand {
    0% {
    }

    100% {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      max-width: 100vw;
      border-radius: 0;
    }
  }

  &.not-selected {
    width: 0;
    height: 0;
    transition: all linear 0.25s;
  }
`

const FrontCloudRow = styled.div`
  margin: 0 auto;
  pointer-events: none;
  display: flex;
  position: absolute;
  top: calc(50vh + 100px);
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  justify-content: space-around;
  // width: 90vw;
  gap: 3rem;

  z-index: 100;

  @media screen and (max-width: 600px) {
    display: none;
  }

  img {
    // margin: 0 -30%;
    filter: blur(10px);
    max-width: 30vw;

    &.fade-out {
      width: 0;
      height: 0;
      transition: all linear 0.25s;
    }
  }
`

const BackCloudRow = styled.div`
  margin: 0 auto;
  pointer-events: none;
  display: flex;
  position: absolute;
  top: calc(50vh - 5vh);
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  justify-content: space-around;
  // width: 90vw;
  z-index: -100;
  align-items: flex-start;
  gap: 3rem;

  @media screen and (max-width: 600px) {
    display: none;
  }

  img {
    filter: blur(10px);
    max-width: 30vw;

    &.fade-out {
      width: 0;
      height: 0;
      transition: all linear 0.25s;
    }
  }
`

const getClassName = (mood: string, selectedMood: string | undefined): string => {
  if (selectedMood === undefined) return ''
  if (mood === selectedMood) return 'selected'
  return 'not-selected'
}

const Teen = ({ nextFunction }: Props) => {
  const [selected, setSelected] = useState<string>()
  const moods = ['high', 'medium', 'low']

  useEffect(() => {
    document.body.style.backgroundImage = `url('/images/moods/fiveToEight/neutral.png')`
  }, [])

  const onClick = (mood: string) => {
    setSelected(mood)
    setTimeout(() => {
      setLocalStorageCheckIn('mood', mood)
      setLocalStorageCheckIn('color', '#04C8C8')
      document.body.style.minHeight = '100vh'
      document.body.style.backgroundImage = `url('/images/moods/fiveToEight/${mood}.png')`
      document.body.style.backgroundSize = 'cover'
      document.body.style.backgroundRepeat = 'no-repeat'
      document.body.style.backgroundPosition = 'center'
      nextFunction(window.location.search)
    }, 1000)
  }

  return (
    <PageContainer>
      <Title className={!!selected ? 'fade-out' : ''}>What’s your vibe today?</Title>
      <Subtitle className={!!selected ? 'fade-out hidden sm:block' : 'hidden sm:block'}>
        Select one of the options below!
      </Subtitle>
      <BackCloudRow>
        <img
          src="/images/moods/fiveToEight/cloudnb1.png"
          alt=""
          className={!!selected ? 'fade-out mt-20 ml-12' : 'mt-20 ml-12'}
        />
        <img
          src="/images/moods/fiveToEight/cloudnb2.png"
          alt=""
          className={!!selected ? 'fade-out' : ''}
        />
      </BackCloudRow>
      <MoodsContainer className={!!selected ? 'selected' : ''}>
        {moods.map((mood: string) => (
          <MoodButton
            key={mood}
            className={getClassName(mood, selected)}
            background={mood}
            onClick={() => onClick(mood)}
          />
        ))}
      </MoodsContainer>
      <FrontCloudRow>
        <img
          src="/images/moods/fiveToEight/cloudnb3.png"
          alt=""
          className={!!selected ? 'fade-out' : ''}
        />
        <img
          src="/images/moods/fiveToEight/cloudnb4.png"
          alt=""
          className={!!selected ? 'fade-out' : ''}
        />
        <img
          src="/images/moods/fiveToEight/cloudnb5.png"
          alt=""
          className={!!selected ? 'fade-out' : ''}
        />
      </FrontCloudRow>
    </PageContainer>
  )
}

export default Teen
