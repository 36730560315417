import React from 'react'
import { IconType } from 'react-icons'
import styled from 'styled-components'
import { Link } from '@reach/router'

const StyledButton = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 160%;
  color: #353535;
  background: #fefefe;
  box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 0.25);
  border-radius: 24px;

  width: 170px;
  max-width: 170px;
  padding: 1rem 0.5rem;
  :hover {
    box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 0.5);
    background: #f5eff5;
  }
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;

  @media screen and (max-width: 700px) {
    padding: 0.5rem 0;
  }
`

const Arrow = styled.p`
  position: inherit;
  bottom: 0;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #998ec9;
`

interface ButtonProps {
  name: string
  path: string
  Icon: IconType
}

export const Button = ({ name, path, Icon }: ButtonProps) => (
  <StyledButton to={`/sign-up/${path}`}>
    <Icon size={75} color="#998ec9" />
    <div style={{ margin: '0.5rem 0' }}>{name}</div>
    <Arrow>{`-->`}</Arrow>
  </StyledButton>
)
