import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import StudentsTypography from 'components/StudentsTypography'

const styles = (theme) => ({
  text: {
    color: theme.colors.white,
    fontSize: '1.8rem',
    marginBottom: '1.5rem',
    padding: '0 70px',
    lineHeight: '160%',
    [theme.breakpoints.down('xs')]: {
      fontSize: '24px',
      marginBottom: '20px',
      padding: '0 15px',
    },
  },
})

export default withStyles(styles)(({ variant, component, children, style, classes }) => (
  <StudentsTypography
    variant={variant || 'h1'}
    className={classes.text}
    style={{ ...style }}
    component={component}
  >
    <div dangerouslySetInnerHTML={{ __html: children }} />
  </StudentsTypography>
))
