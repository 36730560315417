import { createTheme } from '@material-ui/core/styles'

export const colors = {
  primary: '#e9573e',
  black: '#000000',
  white: '#ffffff',
  carbon: '#616161',
  carbonDark: '#353535',
  trolleyGray: '#808080',
  dimGray: '#557766',
  steelGray: '#231D31',
  roseWhite: '#fffafa',
  mystic: '#DCE6EB',
  cinnabar: '#e9573e',
  cinnabarLight: '#FF896B',
  mardiGras: '#250032',
  bluebell: '#998EC9',
  bluebellDark: '#87768d',
  red: '#DA001A',
  redDark: '#B00013',
  purple: '#6750A3',
  borderLight: '#D8D8D8',
  textPlanet: '#FFF5F5',
  textSelectedPlanet: '#FFF5F5',
  warningZoneBackground: 'rgba(196, 196, 196, 0.07)',
  containerShadow: 'rgba(0, 0, 0, 0.25)',
  success: '#32AD45',
  error: '#B00013',
  select: {
    menuItem: {
      background: '#DCE6EB',
    },
  },
  fuel: {
    background: '#c4c4c4',
    shadow: '#a7a7a7',
    full: {
      background: '#31ad45',
      shadow: '#2c822c',
      hover: '#0aa410',
    },
    ok: {
      background: '#f9b233',
      shadow: '#cb9f14',
      hover: '#dfa900',
    },
    low: {
      background: '#0f7dff',
      shadow: '#0052b3',
      hover: '#211fdf',
    },
    empty: {
      background: '#ea473b',
      shadow: '#c63228',
      hover: '#c2100b',
    },
  },
}

const theme = createTheme({
  palette: {
    background: {
      default: colors.mardiGras,
    },
    primary: {
      main: colors.cinnabar,
      light: colors.cinnabarLight,
    },
    secondary: {
      main: colors.mardiGras,
    },
    tertiary: {
      main: colors.purple,
    },
    error: {
      main: colors.error,
    },
  },
  typography: {
    fontFamily: 'Roboto',
    h1: {
      fontSize: 36,
      color: 'white',
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: 'normal',
      textAlign: 'center',
      letterSpacing: '0.5px',
    },
    h2: {
      fontSize: 24,
      color: colors.cinnabar,
      fontStyle: 'normal',
      fontWeight: 'bold',
      lineHeight: 'normal',
      textAlign: 'center',
      letterSpacing: '0.5px',
    },
    subtitle1: {
      fontWeight: 300,
      fontSize: '14px',
      letterSpacing: '0.5px',
      color: colors.carbon,
    },
    subtitle2: {
      color: colors.carbonDark,
    },
    body1: {
      fontWeight: 400,
    },
  },
  colors,
  checkinButton: {
    width: '375px',
    height: '85px',
    padding: '6px 10px',
    borderRadius: '29px',
  },
  checkinSecondaryButton: {
    borderRadius: '11px',
  },
  overrides: {
    MuiSnackbarContent: {
      root: {
        color: colors.white,
      },
    },
  },
})

export default theme
