import React from 'react'
import { IconButton } from '@material-ui/core'
import { Delete as DeleteIcon } from '@material-ui/icons'
import { useFlash } from 'seasoned-flash'
import { useCroods } from 'croods'

import Dialog from 'components/ConfirmationDialog'
import withMixpanel from 'mixpanel/withMixpanel'

const DeleteButton = ({ studentId, alertId, mixpanel }) => {
  const [dialogIsOpen, setDialogOpen] = React.useState(false)
  const { success, error } = useFlash()

  const [, { destroy }] = useCroods({
    stateId: studentId,
    id: alertId,
    name: 'alerts',
    path: `/students/${studentId}/alerts/`,
    afterSuccess: () => {
      success('The alert has been deleted successfully')
    },
    afterFailure: () => {
      error('Failed to delete the alert')
    },
  })

  const handleDeleteAlert = () => {
    mixpanel.track('Student alert deleted', { studentId, alertId })
    destroy()()
  }

  return (
    <>
      <Dialog
        isOpen={dialogIsOpen}
        confirm={handleDeleteAlert}
        close={() => setDialogOpen(false)}
        title="This alert will be deleted. Are you sure?"
        cancelText="Cancel"
        confirmText="Confirm"
      />
      <IconButton
        aria-label="Delete alert"
        color="primary"
        onClick={() => setDialogOpen(true)}
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}

export default withMixpanel(DeleteButton)
