import { ICheckInPropEntry, INeedValues, INeeds_Normalizer, ICheckInData } from '../types'

const NEEDS_NORMALIZER: INeeds_Normalizer = {
  talkToAdult: true,
  bulliedAtSchool: true,
  hasEaten: false,
  hasSlept: false,
  hurtOrSick: true,
  okAtHome: false,
  feelingStressed: true,
  feelingLonely: true,
  overwhelmedByWorkload: true,
  okInSocialLife: false,
  feelingTired: true,
  feelingWorthless: true,
} as const

const NEEDS_VALUES: INeedValues = {
  talkToAdult: 'Wants to Talk',
  bulliedAtSchool: 'Peer Issues',
  hasEaten: 'Hungry',
  hasSlept: 'Needs Sleep',
  hurtOrSick: 'Hurt/Sick',
  okAtHome: 'Home Trouble',
  feelingStressed: 'Stressed',
  feelingLonely: 'Lonely',
  overwhelmedByWorkload: 'Workload',
  okInSocialLife: 'Social Life Stress',
  feelingTired: 'Tired',
  feelingWorthless: 'Worthlessness',
} as const

const isNeedProperty = (propKey: string) => Object.keys(NEEDS_NORMALIZER).includes(propKey)

const shouldDisplay = (prop: ICheckInPropEntry) => prop[1] === NEEDS_NORMALIZER[prop[0]]

export const getNeeds = (checkInData: ICheckInData): string[] | 0 => {
  if (checkInData.needs && checkInData.needs.length > 0) {
    return listStudentNeedsV2(checkInData)
  }
  const needs: string[] = []
  Object.entries(checkInData).forEach((p: ICheckInPropEntry) => {
    isNeedProperty(p[0]) && shouldDisplay(p) && needs.push(NEEDS_VALUES[p[0]])
  })
  return needs.length && needs
}

const capitalize = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

function listStudentNeedsV2(checkIn: ICheckInData): string[] {
  const base = checkIn.talkToAdult ? ['Wants to Talk'] : []
  const needs = checkIn.needs || []
  const formatted = needs.map((need) => capitalize(need))
  return base.concat(formatted)
}
