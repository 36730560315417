import { createSvgIcon } from '@material-ui/core'
import { Menu } from '@headlessui/react'
import { Link } from '@reach/router'
import React, { useEffect, useState } from 'react'
import { User } from 'types'

const ClosegapLogo = () => (
  <svg width="154" height="24" viewBox="0 0 154 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Logo" clipPath="url(#clip0_1856_1230)">
      <path
        id="Vector"
        d="M60.6981 18.4359C62.0507 20.0326 64.2536 21.5341 67.2433 21.5341C71.5555 21.5341 72.7808 19.1085 72.7808 17.2877C72.7808 14.2846 69.9484 13.4557 67.0223 12.6573C63.7179 11.7299 60.1926 10.7719 60.1926 6.84482C60.1926 3.36273 63.276 1.16138 67.0826 1.16138C70.1995 1.16138 72.5565 2.27904 74.1936 4.16446L72.9984 5.50634C71.4885 3.65489 69.3492 2.82259 66.9889 2.82259C64.1866 2.82259 62.0808 4.44982 62.0808 6.7497C62.0808 9.27378 64.6922 10.0076 67.5547 10.8059C70.9227 11.7639 74.6657 12.851 74.6657 17.1925C74.6657 19.9069 72.8712 23.1953 67.2065 23.1953C63.7447 23.1953 61.1333 21.7889 59.5297 19.8118L60.6947 18.4393L60.6981 18.4359Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_2"
        d="M78.9845 1.51123H92.2959V3.17244H80.779V11.0606H92.0749V12.7218H80.779V21.1502H92.2959V22.8114H78.9845V1.51123Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_3"
        d="M106.582 1.16138C110.043 1.16138 112.561 2.5678 114.479 4.80313L113.127 5.7917C111.647 4.0048 109.257 2.82259 106.582 2.82259C101.64 2.82259 97.8334 6.624 97.8334 12.1783C97.8334 17.7327 101.64 21.5647 106.582 21.5647C109.384 21.5647 111.587 20.1583 112.748 18.9795V13.9007H104.787V12.2395H114.543V19.6793C112.655 21.7855 109.886 23.2225 106.582 23.2225C100.666 23.2225 95.9452 18.7518 95.9452 12.175C95.9452 5.59806 100.666 1.16138 106.582 1.16138Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_4"
        d="M132.2 17.573H121.028L118.919 22.8114H116.903L125.497 1.51123H127.73L136.321 22.808H134.306L132.197 17.5696L132.2 17.573ZM121.594 15.9118H131.631L126.596 3.29813L121.594 15.9118Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_5"
        d="M139.405 1.51123H147.272C151.3 1.51123 153.597 4.38522 153.597 7.73821C153.597 11.0912 151.267 13.9652 147.272 13.9652H141.199V22.8114H139.405V1.51123ZM147.082 3.17244H141.196V12.304H147.082C149.85 12.304 151.708 10.4186 151.708 7.73821C151.708 5.05786 149.85 3.17244 147.082 3.17244Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_6"
        d="M0 12.1783C0 5.63203 4.69046 1.16138 10.5728 1.16138C13.9709 1.16138 16.4886 2.66292 18.2831 4.92882L16.7732 5.91739C15.4507 4.06594 13.154 2.81919 10.5728 2.81919C5.69484 2.81919 1.88824 6.6206 1.88824 12.175C1.88824 17.7293 5.69484 21.5307 10.5728 21.5307C13.154 21.5307 15.4507 20.284 16.7732 18.4325L18.2831 19.3905C16.425 21.721 13.9709 23.1919 10.5728 23.1919C4.69046 23.1953 0 18.7247 0 12.1783Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_7"
        d="M21.9357 1.51123H23.7302V21.1502H33.8945V22.8114H21.9357V1.51123Z"
        fill="#F3F2F8"
      />
      <path
        id="Vector_8"
        d="M55.5823 5.27298C53.8677 3.08719 51.4257 1.6278 48.7067 1.16295L48.292 1.09216L48.1796 2.57702L48.5207 2.63769C50.8617 3.05407 52.9621 4.32411 54.4352 6.21417C54.9766 6.90882 55.4152 7.66625 55.746 8.46412C53.9443 9.06462 52.3073 9.86388 50.8753 10.8434C49.4493 11.8185 48.2119 12.9811 47.1971 14.2986C45.5733 16.4069 44.5278 18.8978 44.1441 21.5499C42.1962 21.0899 40.4304 20.0287 39.0921 18.4958C37.5059 16.6786 36.6588 14.3512 36.7069 11.943C36.7532 9.61978 37.6364 7.38971 39.1937 5.66285C40.045 4.71921 41.065 3.95969 42.1885 3.41813C42.5471 3.84183 43.0732 4.12185 43.671 4.15044C44.8147 4.20519 45.786 3.32119 45.8407 2.17564C45.896 1.03044 45.0131 0.057161 43.8698 0.00241183C42.7261 -0.0523373 41.7547 0.83167 41.7001 1.97722C41.6997 1.98733 41.7001 1.99709 41.6994 2.00721C40.3576 2.62898 39.1394 3.51821 38.1252 4.63307C36.3086 6.6302 35.2784 9.21596 35.2244 11.9133C35.1687 14.6937 36.147 17.3795 37.9787 19.476C39.7274 21.4777 42.1085 22.7815 44.7116 23.17V23.1752L45.0441 23.2147L45.0608 23.2167C45.0712 23.2181 45.0817 23.2195 45.0925 23.2206L45.5103 23.268L45.5308 22.2344C45.817 19.6542 46.789 17.2264 48.355 15.1934C50.2274 12.7621 52.8664 10.9679 56.2021 9.85586C56.4433 10.8386 56.5307 11.8614 56.4527 12.8897C56.2957 14.9625 55.4967 16.8899 54.1413 18.4633C53.437 19.2811 52.6171 19.9656 51.7095 20.4999C51.3544 20.1222 50.8586 19.876 50.3002 19.8492C49.1565 19.7944 48.1851 20.6784 48.1305 21.824C48.0758 22.9695 48.9584 23.9424 50.1021 23.9972C51.2457 24.0519 52.2171 23.1679 52.2717 22.0224C52.2738 21.9756 52.2742 21.9293 52.2731 21.8832C52.363 21.8327 52.4524 21.7811 52.5412 21.7277C53.5411 21.1269 54.438 20.3771 55.2067 19.4987C56.8061 17.6717 57.7482 15.4253 57.9317 13.0017C58.1423 10.2171 57.3081 7.47236 55.582 5.27228L55.5823 5.27298Z"
        fill="#F3F2F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1856_1230">
        <rect width="153.6" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

const MiniLogo = createSvgIcon(
  <svg width="24" height="24" viewBox="34 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="closegap-mini" clipPath="url(#clip0_1856_1230)">
      <path
        id="Vector_9_mini"
        d="M55.5823 5.27298C53.8677 3.08719 51.4257 1.6278 48.7067 1.16295L48.292 1.09216L48.1796 2.57702L48.5207 2.63769C50.8617 3.05407 52.9621 4.32411 54.4352 6.21417C54.9766 6.90882 55.4152 7.66625 55.746 8.46412C53.9443 9.06462 52.3073 9.86388 50.8753 10.8434C49.4493 11.8185 48.2119 12.9811 47.1971 14.2986C45.5733 16.4069 44.5278 18.8978 44.1441 21.5499C42.1962 21.0899 40.4304 20.0287 39.0921 18.4958C37.5059 16.6786 36.6588 14.3512 36.7069 11.943C36.7532 9.61978 37.6364 7.38971 39.1937 5.66285C40.045 4.71921 41.065 3.95969 42.1885 3.41813C42.5471 3.84183 43.0732 4.12185 43.671 4.15044C44.8147 4.20519 45.786 3.32119 45.8407 2.17564C45.896 1.03044 45.0131 0.057161 43.8698 0.00241183C42.7261 -0.0523373 41.7547 0.83167 41.7001 1.97722C41.6997 1.98733 41.7001 1.99709 41.6994 2.00721C40.3576 2.62898 39.1394 3.51821 38.1252 4.63307C36.3086 6.6302 35.2784 9.21596 35.2244 11.9133C35.1687 14.6937 36.147 17.3795 37.9787 19.476C39.7274 21.4777 42.1085 22.7815 44.7116 23.17V23.1752L45.0441 23.2147L45.0608 23.2167C45.0712 23.2181 45.0817 23.2195 45.0925 23.2206L45.5103 23.268L45.5308 22.2344C45.817 19.6542 46.789 17.2264 48.355 15.1934C50.2274 12.7621 52.8664 10.9679 56.2021 9.85586C56.4433 10.8386 56.5307 11.8614 56.4527 12.8897C56.2957 14.9625 55.4967 16.8899 54.1413 18.4633C53.437 19.2811 52.6171 19.9656 51.7095 20.4999C51.3544 20.1222 50.8586 19.876 50.3002 19.8492C49.1565 19.7944 48.1851 20.6784 48.1305 21.824C48.0758 22.9695 48.9584 23.9424 50.1021 23.9972C51.2457 24.0519 52.2171 23.1679 52.2717 22.0224C52.2738 21.9756 52.2742 21.9293 52.2731 21.8832C52.363 21.8327 52.4524 21.7811 52.5412 21.7277C53.5411 21.1269 54.438 20.3771 55.2067 19.4987C56.8061 17.6717 57.7482 15.4253 57.9317 13.0017C58.1423 10.2171 57.3081 7.47236 55.582 5.27228L55.5823 5.27298Z"
        fill="#F3F2F8"
      />
    </g>
    <defs>
      <clipPath id="clip0_1856_1230">
        <rect width="153.6" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>,
  'MiniLogo',
)

const ProfileIcon = createSvgIcon(
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="person">
      <path
        id="Vector"
        d="M12 12.9093C14.21 12.9093 16 11.1193 16 8.9093C16 6.6993 14.21 4.9093 12 4.9093C9.79 4.9093 8 6.6993 8 8.9093C8 11.1193 9.79 12.9093 12 12.9093ZM12 14.9093C9.33 14.9093 4 16.2493 4 18.9093V19.9093C4 20.4593 4.45 20.9093 5 20.9093H19C19.55 20.9093 20 20.4593 20 19.9093V18.9093C20 16.2493 14.67 14.9093 12 14.9093Z"
        fill="#F3F2F8"
      />
    </g>
  </svg>,
  'Profile',
)

const MessagesIcon = createSvgIcon(
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="message">
      <path
        id="Vector"
        d="M20 2H4C2.9 2 2.01 2.9 2.01 4L2 22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2ZM17 14H7C6.45 14 6 13.55 6 13C6 12.45 6.45 12 7 12H17C17.55 12 18 12.45 18 13C18 13.55 17.55 14 17 14ZM17 11H7C6.45 11 6 10.55 6 10C6 9.45 6.45 9 7 9H17C17.55 9 18 9.45 18 10C18 10.55 17.55 11 17 11ZM17 8H7C6.45 8 6 7.55 6 7C6 6.45 6.45 6 7 6H17C17.55 6 18 6.45 18 7C18 7.55 17.55 8 17 8Z"
        fill="currentColor"
      />
    </g>
  </svg>,
  'Messages',
)

type Props = {
  currentUser: User
}

const StudentHeader = ({ currentUser }: Props) => {
  const [notificationsVisible, setNotificationsVisible] = useState(false)
  useEffect(() => {
    const handler = (event: { data: any }) => {
      const data = event.data
      if (data.message === 'notifications_badge_controller:connect') {
        setNotificationsVisible(true)
      }
    }

    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once
  return (
    <div className="w-full px-6 py-4 bg-purple-950 shadow items-center inline-flex">
      <Link to={`/check-in/${currentUser.studentId}/welcome`}>
        <div className="hidden sm:block">
          <ClosegapLogo />
        </div>
        <div className=" sm:hidden">
          <MiniLogo />
        </div>
        <span className="hidden">Home</span>
      </Link>
      <div className="flex items-center gap-6 p-2 ml-auto relative">
        <Link to="/student/messages" className="group flex gap-4 items-center">
          <div className="relative">
            <MessagesIcon className="h-5 w-5 text-gray-200 group-aria-[current=page]:text-gray-100 group-hover:text-gray-50" />
            <iframe
              src={`${process.env.REACT_APP_API_URL}/app/notifications`}
              className="absolute min-w-0 min-x-0 w-fit h-fit max-w-20 top-[-10px] left-[13px] pointer-events-none"
              title="Notifications"
              style={{ visibility: notificationsVisible ? 'visible' : 'hidden' }}
            />
          </div>
          <span className="text-gray-200 text-lg font-normal group-aria-[current=page]:text-gray-100 group-aria-[current=page]:font-medium group-hover:text-gray-50 leading-snug">
            Messages
          </span>
        </Link>
        <Menu>
          <Menu.Button className="p-2 rounded-full bg-[#4D1093]">
            <ProfileIcon />
            <span className="hidden">Open Profile Menu</span>
          </Menu.Button>
          <Menu.Items className="absolute bg-white top-full left-full -translate-x-full flex flex-col w-40 z-10 rounded-lg shadow divide-y">
            <Menu.Item>
              <Link
                className="p-2 text-center text-lg font-normal"
                to={`/check-in-history/${currentUser.studentId}`}
              >
                My profile
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link
                className="p-2 text-center text-lg font-normal text-[#FB4B7A]"
                to="/sign-out?clear=true"
              >
                Log out
              </Link>
            </Menu.Item>
          </Menu.Items>
        </Menu>
      </div>
    </div>
  )
}

export default StudentHeader
