import React from 'react'
import AddIcon from '@material-ui/icons/Add'
import StyledDialog from 'components/StyledDialog'
import { TextField, makeStyles } from '@material-ui/core'
import Date from 'Educators/ManageSchool/Vacations/Date'
import { useSchools } from 'Educators/SchoolsContext'
// @ts-ignore
import { useFlash } from 'seasoned-flash'
import Error from 'components/form/Error'
import { useCroods } from 'croods'
import { isEmpty, some } from 'lodash'
import Loading from 'components/Loading'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'

const useStyles = makeStyles(() => ({
  button: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginTop: '16px',
  },
}))

const AddVacation = () => {
  const classes = useStyles()
  const { schoolId } = useSchools()
  const { success } = useFlash()
  const [open, setOpen] = React.useState(false)
  const [title, setTitle] = React.useState('')
  const [from, setFrom] = React.useState<MaterialUiPickersDate | null>(null)
  const [to, setTo] = React.useState<MaterialUiPickersDate | null>(null)

  const onClose = () => setOpen(false)

  const [{ saving, saveError }, { save }] = useCroods({
    stateId: schoolId,
    name: 'vacations',
    customPath: `/schools/${schoolId}/school_vacations`,
    afterSuccess() {
      success('Vacation successfully added!')
      onClose()
    },
  })

  const handleClick = () => {
    if (from !== null && to !== null && title !== '') {
      save()({
        name: title,
        startsAt: from.format('YYYY-MM-DD'),
        endsAt: to.format('YYYY-MM-DD'),
      })
    }
  }

  const disabled =
    saving ||
    some(
      {
        title,
        from: from?.isValid() ? from : null,
        to: to?.isValid() ? to : null,
      },
      isEmpty,
    )
  //||
  // from?.diff(to, 'days') > 0

  return (
    <div>
      <button className={classes.button} onClick={() => setOpen(true)} type="button">
        <AddIcon className="mr-2" />
        ADD NEW VACATION
      </button>
      <StyledDialog
        open={open}
        onClose={onClose}
        scroll="body"
        title="Add new vacation"
        disableBackdropClick={saving}
        disableEscapeKeyDown={saving}
      >
        <div>
          <TextField
            className="w-full"
            label="Title*"
            helperText="(e.g. Winter Break)"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            inputProps={{
              'aria-label': 'title',
            }}
          />
          <div className="flex mt-6">
            <div className="mr-5">
              <Date
                label="From*"
                value={from}
                onChange={(date: MaterialUiPickersDate) => setFrom(date)}
                ariaLabel="date-from"
              />
            </div>
            <div className="ml-5">
              <Date
                label="To*"
                value={to}
                onChange={(date: MaterialUiPickersDate) => setTo(date)}
                disabled={!from}
                minDate={from}
                ariaLabel="date-to"
              />
            </div>
          </div>
          <div className="flex justify-center w-full mt-12">
            {saveError && <Error>{saveError}</Error>}
            {saving ? (
              <Loading />
            ) : (
              <button type="button" disabled={disabled} className="button" onClick={handleClick}>
                ADD VACATION
              </button>
            )}
          </div>
        </div>
      </StyledDialog>
    </div>
  )
}

export default AddVacation
