import pick from 'lodash/pick'

const whitelist = [
  'id',
  'honorificTitle',
  'firstName',
  'lastName',
  'role',
  'phoneNumber',
  'email',
  'password',
  'birthdate',
]

export default (update) => (params) => {
  const paramsToUpdate = pick(params, whitelist)
  update(paramsToUpdate)
}
