const KINDS = [
  {
    value: 'emotion',
    label: 'Emotion',
  },
  {
    value: 'energy_level',
    label: 'Energy Level',
  },
  {
    value: 'follow_up_question',
    label: 'Follow-Up Questions',
  },
]

export const getKind = (kind) => KINDS.find(({ value }) => value === kind)

export default KINDS
