import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'

import { PrimaryButton, SecondaryButton } from '../../Components/buttons'

import { navigate } from '@reach/router'

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '16px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '40px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  button: {
    width: '100%',
  },
  mailtoLink: {
    textDecorationLine: 'underline',
  },
  resourcesLink: {
    fontWeight: 500,
  },
}))

type Props = {
  insights?: boolean | undefined
  chooseSchool?: boolean | undefined
}

const CompletedSetUp = ({ insights, chooseSchool = true }: Props) => {
  const classes = useStyles()

  const onClick = () => {
    window.location.replace('/dashboard')
  }

  const onClickAnotherSchool = () => {
    navigate('/manage-school/new-school-year/choose-school')
  }

  const insightsContent = (
    <>
      <Typography className={classes.subTitle}>
        We're working with folks at your school to get you up and running. There's nothing more to
        do at this point!
      </Typography>
      <Typography className={classes.subTitle}>
        You can check out our{' '}
        <a
          href="https://closegaphelp.zendesk.com/hc/en-us/categories/17458637728155-Resource-Center"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.resourcesLink}
        >
          Resource Center
        </a>{' '}
        in the meantime!
      </Typography>
    </>
  )

  const essentialsContent = (
    <>
      <Typography className={classes.subTitle}>
        We're going to have a great school year! Remember, if you need any assistance, please reach
        out to{' '}
        <a href="mailto:info@closegap.org" className={classes.mailtoLink}>
          info@closegap.org
        </a>
        . We're happy to help!
      </Typography>
      <Typography className={classes.subTitle}>
        Want to get the most out of Closegap? Check out our{' '}
        <a
          href="https://closegaphelp.zendesk.com/hc/en-us/categories/17458637728155-Resource-Center"
          target="_blank"
          rel="noopener noreferrer"
          className={classes.resourcesLink}
        >
          Resource Center
        </a>
        !
      </Typography>
    </>
  )

  return (
    <Container className={classes.container}>
      <Typography className={classes.heading} noWrap>
        {insights ? "You're almost set!" : "Yay! You're all set!"}
      </Typography>
      {insights ? insightsContent : essentialsContent}

      {!insights && (
        <PrimaryButton disabled={false} onClick={() => onClick()}>
          GO TO DASHBOARD
        </PrimaryButton>
      )}
      {chooseSchool && (
        <SecondaryButton disabled={false} onClick={() => onClickAnotherSchool()}>
          SET UP ANOTHER SCHOOL
        </SecondaryButton>
      )}
    </Container>
  )
}

export default CompletedSetUp
