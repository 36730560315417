import React, { useState } from 'react'
import { useCroods } from 'croods'

import Loading from 'components/Loading'
import { error, success } from 'components/Toast'
import { AdminScreen } from './components/AdminScreen'
import { EmailInput } from './components/EmailInput'
import { Button, P } from './components/styled'

export const UpdateUserEmail = (props: any) => {
  const [email, setEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')

  const [{ saving }, { save }] = useCroods({
    name: 'admin-update-email',
    customPath: 'admin/update_email',
    afterSuccess: () => success("User's email update successfully"),
    afterFailure: ({ response }) => error(response?.data?.message),
  })

  return (
    <AdminScreen title="Update user email" {...props}>
      <div style={{ alignSelf: 'center', maxWidth: '600px', width: '100%' }}>
        <P>1. Add the current email of the user in the field below</P>
        <EmailInput label="Current user email *" value={email} setValue={setEmail} />
        <P>2. Add the new email of the user in the field below</P>
        <EmailInput label="New user email *" value={newEmail} setValue={setNewEmail} />
        <Button
          variant="edit"
          disabled={email === '' || newEmail === '' || email === newEmail}
          onClick={() => save({ method: 'PUT' })({ email, newEmail })}
        >
          Change user email
        </Button>
        {saving ? <Loading /> : null}
      </div>
    </AdminScreen>
  )
}
