import React from 'react'
import { navigate } from '@reach/router'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import get from 'lodash/get'

import withMixpanel from 'mixpanel/withMixpanel'
import FollowUpAction from 'components/FollowUpAction'
import { welcomePath } from 'students/helper'
import { listStudentNeeds } from 'Educators/students/helper'
import DateTimeFormat from 'Educators/students/list/DateTimeFormat'

const styles = ({ colors, breakpoints, ...theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
    marginTop: theme.spacing(3),
  },
  avatar: {
    background: colors.carbon,
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '20px',
    marginRight: '25px',
    color: colors.white,
    cursor: 'pointer',
    marginTop: 5,
  },
  contentWrapper: {
    flexGrow: 1,
    cursor: 'pointer',
    paddingRight: '5px',
    [breakpoints.down('sm')]: {
      paddingLeft: 25,
    },
  },
  studentName: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    letterSpacing: '0.194444px',
    color: colors.carbonDark,
    display: 'inline',
  },
  studentInfo: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: 'normal',
    letterSpacing: '0.194444px',
    color: colors.trolleyGray,
  },
  studentShared: {
    fontSize: '10px',
  },
  checkInCount: {
    display: 'inline',
    marginLeft: 10,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '12px',
    lineHeight: 'normal',
    letterSpacing: '0.194444px',
    color: colors.trolleyGray,
  },
  action: {
    cursor: 'pointer',
  },
  mainContentContainer: {
    [breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  timeAvatarContainer: {
    alignItems: 'center',
    flexWrap: 'nowrap',
    [breakpoints.only('xs')]: {
      flexDirection: 'row-reverse',
      justifyContent: 'flex-end',
      marginBottom: '8px',
    },
  },
  datetimeBox: {
    fontSize: 12,
    color: colors.carbon,
    marginLeft: 8,
    marginRight: 27,
    minWidth: 50,
    [breakpoints.only('xs')]: {
      minWidth: 'none',
      margin: 0,
    },
  },
  growOnSmallScreen: {
    [breakpoints.only('xs')]: {
      flexGrow: 0,
    },
  },
})

const Info = ({ classes, student, admins, isShared }) => (
  <>
    {admins && <Typography className={classes.studentInfo}>{`Grade ${student.grade}`}</Typography>}
    <Typography className={classes.studentInfo}>{`Grade ${student.grade}`}</Typography>
    {isShared && (
      <Typography className={classes.studentShared} color="textSecondary">
        {`Shared by ${student.ownerFullName}`}
      </Typography>
    )}
  </>
)

const Action = ({
  showFollowUp,
  fetchListStudents,
  currentUser,
  checkIn,
  student,
  onClickCheckin,
  ActionComponent,
}) =>
  showFollowUp ? (
    <FollowUpAction
      fetchListStudents={fetchListStudents}
      currentUser={currentUser}
      item={checkIn}
    />
  ) : (
    <ActionComponent student={student} onClick={onClickCheckin} />
  )

const StudentItem = withStyles(styles)(
  ({
    classes,
    student,
    currentUser = {},
    showFollowUp,
    ActionComponent,
    showAvatar = true,
    showCheckInDetails,
    fetchListStudents,
    admins,
    isManageStudents = false,
    showAction = true,
    mixpanel,
  }) => {
    const isShared = student.ownerId !== currentUser.id

    const initials = `${student.firstName[0]} ${student.lastName[0]}`

    const checkIn = student.latestCheckIn

    const onClickEdit = () => {
      mixpanel.track('Student Name Clicked', { studentId: student.id })
      navigate(`/dashboard/students/${student.id}/edit`)
    }

    const onClickCheckin = () => {
      mixpanel.track('Checking through educator Dash clicked', {
        studentId: student.id,
      })
      navigate(welcomePath(student))
    }

    return (
      <Grid container className={classes.container} justifyContent="flex-start">
        <Grid item className={classes.growOnSmallScreen}>
          <Grid container className={classes.timeAvatarContainer}>
            {showCheckInDetails && (
              <Grid item>
                <TimeOrDate
                  data-testid="TimeOrDate"
                  className={classes.datetimeBox}
                  onClick={onClickEdit}
                  datetime={get(checkIn, 'createdAt')}
                />
              </Grid>
            )}
            {showAvatar && (
              <Grid item onClick={onClickEdit} className={classes.avatar}>
                {initials}
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item className={classes.contentWrapper}>
          <Grid container className={classes.mainContentContainer} style={{ flexWrap: 'nowrap' }}>
            <Grid item>
              <Grid container direction="column" onClick={onClickEdit}>
                <Typography variant="subtitle2" className={classes.studentName}>
                  {`${student.firstName} ${student.lastName}`}
                </Typography>
                {isManageStudents && student.grade ? (
                  <Typography variant="subtitle2" style={{ fontWeight: 400 }}>
                    {`Grade ${student.grade}`}
                  </Typography>
                ) : (
                  <Info classes={classes} student={student} admins={admins} isShared={isShared} />
                )}
              </Grid>
            </Grid>
            {showCheckInDetails && (
              <Grid item>
                <Grid container>
                  <StudentNeeds checkIn={checkIn} currentUser={currentUser} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {showAction && (
          <Action
            showFollowUp={showFollowUp}
            fetchListStudents={fetchListStudents}
            currentUser={currentUser}
            checkIn={checkIn}
            student={student}
            onClickCheckin={onClickCheckin}
            ActionComponent={ActionComponent}
          />
        )}
      </Grid>
    )
  },
)

function StudentNeeds({ checkIn, currentUser }) {
  if (!checkIn) return null

  const studentNeeds = listStudentNeeds({ checkIn, currentUser })

  return (
    <>
      {studentNeeds.map((message) => (
        <Typography key={message} style={{ color: 'red', padding: '4px 5px', fontSize: 10 }}>
          {message}
        </Typography>
      ))}
    </>
  )
}

function TimeOrDate({ datetime, className, ...props }) {
  if (!datetime) return null
  return (
    <div {...props} className={className}>
      <DateTimeFormat date={datetime} />
    </div>
  )
}

StudentItem.defaultProps = {
  ActionComponent: ({ onClick }) => (
    <div style={{ cursor: 'pointer' }}>
      <Tooltip title="Check-in">
        <img
          onClick={onClick}
          alt="student action"
          src="/images/orange-rocket.png"
          height="32px"
          width="32px"
        />
      </Tooltip>
    </div>
  ),
}

export default withMixpanel(StudentItem)
