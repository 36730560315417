import styled from 'styled-components'
import { MdEmail } from 'react-icons/md'

export const MessagesButton = styled.button`
  display: flex;
  font-family: Roboto;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  font-weight: 400;
  align-items: center;
  color: #e9573e;
`

export const EmailIcon = styled(MdEmail)`
  position: absolute;
  top: calc(50% - 14px);
  left: -2.5rem;
  background: #e9573e;
  width: 28px;
  height: 28px;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
`

export const NotesContainer = styled.div<{ isStudent: boolean; highlight: boolean }>`
  position: relative;
  background: ${({ isStudent }) => (isStudent ? 'rgba(233, 87, 63, 0.08)' : 'rgba(0, 0, 0, 0.03)')};
  outline: ${({ highlight }) => (highlight ? 'solid #e9573e 2px' : 'none')};
  padding: 0.5rem;
  margin-bottom: 1rem;
`

export const Text = styled.div<{ dark: boolean }>`
  font-weight: ${({ dark }) => (dark ? '500' : '400')};
  font-size: 10px;
  margin-right: 0.5rem;
  color: #808080;
`

export const Message = styled.div`
  font-size: 14px;
  font-weight: 400;
  margin-top: 0.5rem;
  color: #353535;
`
