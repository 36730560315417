import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import moment from 'moment'

const Time = ({ createdAt, style, ...props }) => (
  <Grid
    {...props}
    style={{ alignSelf: 'center', textAlign: 'center', ...style }}
  >
    <Typography variant="caption" color="textSecondary" noWrap>
      {moment(createdAt).format('LT')}
    </Typography>
  </Grid>
)

export default Time
