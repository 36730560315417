import React from 'react'
import { navigate } from '@reach/router'
import { useResetPassword } from 'dmcroods-auth'
import { useFlash } from 'seasoned-flash'

import Title from 'components/AuthTitle'
import ResetPasswordForm from 'auth/forgotPassword/ResetPasswordForm'
import Screen from '../Screen'
import sendValuesAndToken from './sendValuesAndToken'

export default (props) => {
  const { success } = useFlash()
  const [{ reseting, error }, reset] = useResetPassword({
    location: typeof window === 'undefined' ? { search: '' } : window.location,
    afterSuccess: ({ data: { message } }) => {
      success(message)
      return navigate('/')
    },
  })

  return (
    <Screen>
      <Title>Reset password</Title>
      <ResetPasswordForm
        apiError={error}
        create={sendValuesAndToken(reset, props)}
        creating={reseting}
      />
    </Screen>
  )
}
