import React from 'react'

import Form from '../form/Form'
import filterParams from './filterParams'
import parseInfo from './parseInfo'

export default ({ currentUser, info, update, updating, error }) => (
  <div className="p-4">
    <Form
      onSave={filterParams(update)}
      submitting={updating}
      submitError={error}
      initialValues={parseInfo(info)}
      student={info}
      currentUser={currentUser}
      title={`Edit ${info.firstName}’s Account`}
      isEdit
    />
  </div>
)
