import React from 'react'
import map from 'lodash/map'
import withMixpanel from 'mixpanel/withMixpanel'
import Error from 'components/form/Error'

import SecondaryEmotionButton from 'checkIn/components/teen/SecondaryEmotionButton'
import { checkInTrack, getLocalStorageCheckIn, setStudentInfo } from 'students/helper'
import Screen from 'checkIn/components/teen/Screen'
import { setQSData, getQSData } from 'QueryStringController'
import Loading from 'components/Loading'

import { RiEmotionHappyLine } from 'react-icons/ri'
import { RiEmotionUnhappyLine } from 'react-icons/ri'
import { RiEmotionNormalLine } from 'react-icons/ri'

const HowAreYouFeelingNow = ({ mixpanel, flow, userId, studentId, saving, saveError, save }) => {
  const { nextFunction } = flow
  const color = getLocalStorageCheckIn('color') || '#00ddc1'
  const buttons = [
    {
      icon: <RiEmotionHappyLine size={50} color={color} className="mr-4" />,
      buttonText: 'Better!',
      value: 'better',
    },
    {
      icon: <RiEmotionNormalLine size={50} color={color} className="mr-4" />,
      buttonText: 'About the same',
      value: 'same',
    },
    {
      icon: <RiEmotionUnhappyLine size={50} color={color} className="mr-4" />,
      buttonText: 'Worse',
      value: 'worse',
    },
  ]

  function onClick(buttonText, value) {
    if (saving) return
    const qs = getQSData()
    setStudentInfo({ feelingAfterIntervention: buttonText })
    checkInTrack(mixpanel, `"${buttonText}" clicked`, {
      ...qs,
      feelingAfterIntervention: buttonText,
    })
    setQSData({ feelingNow: value })
    nextFunction(save, userId, studentId)
  }

  return (
    <Screen
      title={saving ? 'Saving' : 'How are you feeling now?'}
      style={{ justifyContent: 'center' }}
      contentWrapperStyle={{ marginTop: 0, height: '100vh' }}
    >
      {saving ? (
        <Loading />
      ) : (
        <div className="mt-10">
          {map(buttons, ({ value, buttonText, icon }) => (
            <div key={value} className="flex mb-10">
              {icon}
              <SecondaryEmotionButton onClick={() => onClick(buttonText, value)}>
                <span className="font-semibold">{buttonText}</span>
              </SecondaryEmotionButton>
            </div>
          ))}
        </div>
      )}
      {saveError && <Error>{saveError}</Error>}
    </Screen>
  )
}

export default withMixpanel(HowAreYouFeelingNow)
