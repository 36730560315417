import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useCroods } from 'croods'
// @ts-ignore
import { useFlash } from 'seasoned-flash'
// @ts-ignore
import { getFormValues } from 'redux-form'

import PersonAddIcon from '@material-ui/icons/PersonAdd'

import StyledDialog from 'components/StyledDialog'

import DialogForm from 'Educators/ManageSchool/ManageStudents/DialogForm'
import SharingBaseForm from 'Educators/ManageSchool/ManageStudents/sharing/SharingBaseForm'

import { useMixpanel } from 'mixpanel'
import type { Student, User } from 'types'

const INITIAL_VALUES = { role: 'viewer', selfRole: 'admin', email: '' }

type Props = {
  student: Student
  currentUser: User
  email?: string | null
  role?: string
  selfRole?: string
}
const ButtonShare = ({ student, ...props }: Props) => {
  const mixpanel = useMixpanel()
  const { success } = useFlash()
  const [open, setOpen] = useState(false)

  const [, { fetch }] = useCroods({ name: 'students', id: student.id })
  const [, { save }] = useCroods({
    name: 'studentsShare',
    path: '/shared_students',
  })

  const handleClick = () => {
    mixpanel.track('Share link clicked')
    setOpen(true)
  }

  const handleClose = () => {
    mixpanel.track('Share Student Modal Closed')
    setOpen(false)
  }

  function handleSubmit() {
    return async (values: typeof INITIAL_VALUES) => {
      const { email, role, selfRole } = values
      if (await save({})({ email, role, selfRole, studentsIds: [student.id] })) {
        fetch({ id: student.id })()
        handleClose()
        success('Shared successfully')
      }
      mixpanel.track('Students shared', {
        'Recipient email': email,
        'Student Id': student.id,
        'Recipient role': role,
        'Current user role': selfRole,
      })
    }
  }

  if (!student.abilities?.update) return null

  return (
    <>
      <button
        className="flex items-center gap-2 py-2 text-xs font-medium text-gray-400 uppercase rounded whitespace-nowrap"
        onClick={handleClick}
      >
        <PersonAddIcon className="!text-lg text-primary" /> Share
      </button>
      <StyledDialog open={open} onClose={handleClose} title="Share Student">
        <DialogForm
          name="studentsShare"
          buttonText="SHARE"
          onSubmit={handleSubmit}
          onClose={handleClose}
          initialValues={INITIAL_VALUES}
        >
          <SharingBaseForm {...(props as Required<Props>)} student={student} />
        </DialogForm>
      </StyledDialog>
    </>
  )
}

const mapState = (state: object) => {
  const values: Record<string, string | null> = getFormValues('students')(state) || {}
  return {
    email: values.email || INITIAL_VALUES.email,
    role: values.role || INITIAL_VALUES.role,
    selfRole: values.selfRole || INITIAL_VALUES.selfRole,
  }
}

export default connect(mapState)(ButtonShare)
