import React, { InputHTMLAttributes } from 'react'
import { InputContainer, Label, StyledInput } from './styled'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
  setValue?: (num: number) => void
}

export const NumberInput = ({ label, setValue, ...rest }: Props) => (
  <InputContainer>
    <Label>{label} *</Label>
    <StyledInput
      type="number"
      onChange={setValue ? (e) => setValue(Number(e.target.value)) : rest.onChange}
      {...rest}
    />
  </InputContainer>
)
