import React from 'react'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import { Title } from 'checkIn/components/teen/Title'
import { Paragraph } from 'checkIn/components/teen/Paragraph'
import { setLocalStorageCheckIn } from 'students/helper'
import styled from 'styled-components'

type Props = {
  nextFunction: (params: string) => void
}

const ColorsContainer = styled.div`
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem 6rem;
  @media screen and (max-width: 600px) {
    gap: 3rem;
  }
`

const ColorButton = styled.button<{ background: string }>`
  display: flex;
  min-height: 4rem;
  min-width: 12rem;
  background: ${({ background }) => background};
  border-radius: 50px;
  transition: all ease 0.25s;
  :hover {
    filter: brightness(1.2);
    outline: ${({ background }) => `2px solid ${background}`};
    outline-offset: 0.4rem;
  }
  @media screen and (max-width: 600px) {
    min-height: 4rem;
    min-width: 10rem;
  }
`

const Teen = ({ nextFunction }: Props) => {
  const colors = ['#00ddc1', '#8537E3', '#FBDE3F', '#80E217', '#FF5576', '#D31919']

  const onClick = (color: string) => {
    setLocalStorageCheckIn('color', color)
    nextFunction(window.location.search)
  }

  return (
    <CheckInContainer bottom bg="/images/teen/cadet_with_glasses_on_moon.png">
      <Title>What’s your vibe today?</Title>

      <Paragraph>Select one of the colors below!</Paragraph>
      <ColorsContainer>
        {colors.map((color: string) => (
          <ColorButton key={color} background={color} onClick={() => onClick(color)} />
        ))}
      </ColorsContainer>
    </CheckInContainer>
  )
}

export default Teen
