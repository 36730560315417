import moment from 'moment'

const parseYesNo = (value) => (value ? 'yes' : 'no')

export default (info) => ({
  ...info,
  birthdate: moment(info.birthdate).format('MM/DD/YYYY'),
  educationPlan504: parseYesNo(info.educationPlan504),
  individualizedEducationProgram: parseYesNo(
    info.individualizedEducationProgram,
  ),
  englishLanguageLearner: parseYesNo(info.englishLanguageLearner),
  freeReducedPriceMeals: parseYesNo(info.freeReducedPriceMeals),
})
