import React, { useEffect } from 'react'
import { Typography } from '@material-ui/core'
import withMixpanel from 'mixpanel/withMixpanel'

import CadetOnBottom from 'components/CadetOnBottomScreen'
import { ButtonNext } from 'components/ButtonNext'

const Welcome = ({ currentUser, mixpanel }) => {
  useEffect(() => {
    mixpanel.track('View First Welcome Page')
  }, []) // eslint-disable-line

  return (
    <CadetOnBottom
      title={`Great, you’re all set${currentUser ? ` ${currentUser.firstName}` : ''}!`}
      text="Let’s check out your dashboard!"
    >
      <ButtonNext nextURL="/dashboard">
        <Typography variant="h1">Continue</Typography>
      </ButtonNext>
    </CadetOnBottom>
  )
}

export default withMixpanel(Welcome)
