// Make sure this data reflects the database data for emotions

const comfortablePrimaryEmotions = [
  'happy',
  'content',
  'calm',
  'excited',
  'grateful',
  'loved',
  'hopeful',
  'confident',
  'proud',
  'silly',
  'relaxed',
  'proud',
]

export function isComfy(emotion) {
  return comfortablePrimaryEmotions.includes(emotion)
}

export default isComfy
