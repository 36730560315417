import React, { useEffect, useState } from 'react'
import { setLocalStorageCheckIn } from 'students/helper'
import styled from 'styled-components'
import Screen from '../components/screen'
import { setQSData } from 'QueryStringController'

type Props = {
  nextFunction: (params: string) => void
}

const MoodsContainer = styled.div`
  position: absolute;
  // width: 85%;
  top: 50vh;
  left: 50vw;
  transform: translateX(-50%) translateY(-50%);
  display: flex;
  gap: 1rem;
  justify-content: space-around;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    // position: unset;
    // top: unset;
    // left: unset;
    // transform: unset;
    gap: 1rem;
  }

  &.selected {
    gap: 0;
    transition: all linear 0.5s;
  }
`

const MoodButtonOuter = styled.div<{ background: string }>`
  overflow: hidden;
  border-radius: 16px;
  :hover {
    outline: 2px solid rgba(255, 255, 255, 0.4);
    outline-offset: 0.4rem;
  }

  &.selected {
    animation: expandOuter;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-composition: replace;
    :hover {
      outline: none;
      outline-offset: none;
    }
  }

  @keyframes expandOuter {
    0% {
    }

    100% {
      border-radius: 0;
    }
  }
`

const MoodButtonInner = styled.div`
  backdrop-filter: blur(100px);
  filter: blur(0px);
`

const MoodButton = styled.button<{ background: string }>`
  max-height: 544px;
  max-width: 200px;
  height: 40vh;
  width: 15vw;
  // padding: 16px;

  transition: all ease 0.5s;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 16px;
  overflow: hidden;

  background-image: ${({ background }) => `url(/images/moods/nineToTwelve/${background}.png)`};

  :hover {
    outline: 2px solid rgba(255, 255, 255, 0.4);
    outline-offset: 0.4rem;
  }
  @media screen and (max-width: 800px) {
    border-radius: 1rem;
    height: 52px;
    width: 80vw;
    max-width: 80vw;
  }

  &.selected {
    animation: expand;
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-composition: replace;
    :hover {
      outline: none;
      outline-offset: none;
    }
  }

  @keyframes expand {
    0% {
    }

    100% {
      width: 100vw;
      height: 100vh;
      max-height: 100vh;
      max-width: 100vw;
      border-radius: 0;
    }
  }

  &.not-selected {
    width: 0;
    height: 0;
    // padding: 0;
    transition: all linear 0.5s;
  }
`

const getClassName = (mood: string, selectedMood: string | undefined): string => {
  if (selectedMood === undefined) return ''
  if (mood === selectedMood) return 'selected'
  return 'not-selected'
}

const NineToTwelve = ({ nextFunction }: Props) => {
  const [selected, setSelected] = useState<string>()
  const moods = [
    { text: 'Quiet Day', image: 'quiet' },
    { text: 'Reflective Mood', image: 'reflective' },
    { text: 'Steady State', image: 'steady' },
    { text: 'Softly Positive', image: 'positive' },
    { text: 'Vibrantly Upbeat', image: 'upbeat' },
  ]

  useEffect(() => {
    document.body.style.backgroundImage = `url('/images/moods/nineToTwelve/neutral.png')`
    // @ts-ignore
    document.body.style.backdropFilter = 'blur(100px)'
  }, [])

  const onClick = (mood: string) => {
    setSelected(mood)
    setTimeout(() => {
      setQSData({ vibe: mood })
      setLocalStorageCheckIn('mood', mood)
      setLocalStorageCheckIn('color', '#04C8C8')
      document.body.style.minHeight = '100vh'
      document.body.style.backgroundImage = `url('/images/moods/nineToTwelve/${mood}.png')`
      document.body.style.backgroundSize = 'cover'
      document.body.style.backgroundRepeat = 'no-repeat'
      document.body.style.backgroundPosition = 'center'
      nextFunction(window.location.search)
    }, 1500)
  }

  return (
    <Screen title="Choose your vibe for today’s check-in" currentStep={0} showSummary={false}>
      <MoodsContainer className={!!selected ? 'selected' : ''}>
        {moods.map((mood: { text: string; image: string }) => (
          <MoodButtonOuter
            key={mood.image}
            background={mood.image}
            className={`${getClassName(mood.image, selected)} `}
          >
            <MoodButton
              key={mood.image}
              className={`${getClassName(mood.image, selected)} `}
              background={mood.image}
              onClick={() => onClick(mood.image)}
            >
              <MoodButtonInner className="flex items-end content-start h-full w-full p-4">
                <p className={`text-white text-left uppercase font-semibold tracking-widest `}>
                  {mood.text}
                </p>
              </MoodButtonInner>
            </MoodButton>
          </MoodButtonOuter>
        ))}
      </MoodsContainer>
    </Screen>
  )
}

export default NineToTwelve
