import React, { useState } from 'react'
import { Button, P, SubTitle, Title, SchoolCard, Row } from './components/styled'
import { useCroods } from 'croods'
import { error, success } from 'components/Toast'
import { Modal } from './components/Modal'
import Loading from 'components/Loading'
import { AdminScreen } from 'CsDashboard/components/AdminScreen'
import { NumberInput } from 'CsDashboard/components/NumberInput'
import { DetailedSchoolInfo } from './components/DetailedSchoolInfo'
import { A } from './components/A'
import { MetabaseQueries } from './components/MetabaseQueries'

export const DestroySchool = (props: any) => {
  const [showSchool, setShowSchool] = useState<boolean>(false)
  const [openConfirm, setOpenConfirm] = useState<boolean>(false)
  const [openMetabase, setOpenMetabase] = useState<boolean>(false)
  const [schoolId, setSchoolId] = useState<number | string>('')
  const [{ info: s = [], infoError: schoolError }, { fetch: getSchools }] = useCroods({
    name: 'admin-school',
    customPath: 'admin/schools',
    afterSuccess: ({ data }) => (data.length > 0 ? setShowSchool(true) : null),
    afterFailure: () => error(schoolError || ''),
  })

  const [{ destroying }, { destroy: destroySchool }] = useCroods({
    name: 'admin-destroy-school',
    customPath: 'admin/destroy_school',
    afterSuccess: () => {
      setOpenConfirm(false)
      setShowSchool(false)
      setSchoolId('')
      success('School destroyed successfully')
    },
    afterFailure: ({ message }) => {
      error(message)
    },
  })

  return (
    <AdminScreen title="Destroy School" {...props}>
      <div style={{ maxWidth: '600px', alignSelf: 'center' }}>
        <P>To delete a school you need its ID.</P>
        <P>
          In case you don&apos;t have the school id, use one of the{' '}
          <A name="metabase queries" onClick={() => setOpenMetabase(true)} /> to find the school id.
        </P>

        <SubTitle>Add the school ID</SubTitle>
        <NumberInput name="schoolId" label="School Id" value={schoolId} setValue={setSchoolId} />
        <Button
          disabled={schoolId === ''}
          variant="edit"
          onClick={() => getSchools({ operation: 'info' })({ ids: [schoolId] || '' })}
        >
          Get School Data
        </Button>
      </div>

      {showSchool && s?.length > 0 ? (
        <div style={{ maxWidth: '1000px', marginTop: '3rem', alignSelf: 'center' }}>
          <Title>School Data</Title>
          <SchoolCard key={s[0]?.schoolId}>
            {Boolean(s[0]) ? <DetailedSchoolInfo school={s[0]} /> : null}
            <Row>
              <Button variant="delete" onClick={() => setOpenConfirm(true)}>
                DELETE SCHOOL
              </Button>
            </Row>
          </SchoolCard>
          <Modal open={openConfirm} onClose={() => setOpenConfirm(false)}>
            <Title>Attention!!!</Title>
            <P>You are about to completely destroy a school!</P>
            <P>- All school&apos;s students the students account will be destroyed</P>
            <P>- All classrooms will be destroyed</P>
            <P>- All educators will be disconnected from the school</P>
            <SubTitle>This action CAN NOT be undone</SubTitle>
            {destroying ? <Loading /> : null}
            <Button variant="delete" onClick={() => destroySchool()({ id: schoolId })}>
              YES, DESTROY <strong>&quot;{s[0]?.name.toUpperCase()}&quot;</strong> SCHOOL
            </Button>
          </Modal>
        </div>
      ) : null}

      <MetabaseQueries open={openMetabase} onClose={() => setOpenMetabase(false)} />
    </AdminScreen>
  )
}
