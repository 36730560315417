import React from 'react'
import {
  Container,
  makeStyles,
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'

import { useSchools } from 'Educators/SchoolsContext'
import { success, error } from 'components/Toast'
import { useCroods } from 'croods'
import { PrimaryButton, SecondaryButton } from '../../Components/buttons'
import HelperCadet from '../../Components/helperCadet'

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '16px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '80px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  button: {
    width: '100%',
  },
}))

const NewSchoolYearClearClassrooms = ({ nextStepFn }: { nextStepFn: Function }) => {
  const classes = useStyles()
  const { school } = useSchools()
  const [{ saving }, { save }] = useCroods({
    name: 'destroyAllClassrooms',
    customPath: `schools/${school.id}/classrooms/destroy_all`,
    afterResponse({ status }) {
      if (status === 200) {
        success('Classrooms removed')
        nextStepFn()
      } else {
        error('Error')
      }
    },
  })
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (skipStep: boolean) => {
    setOpen(false)
    if (skipStep) {
      nextStepFn()
    }
  }

  return (
    <Container className={classes.container}>
      <HelperCadet></HelperCadet>
      <Typography className={classes.heading}>
        Do you want to remove the classrooms from last school year?{' '}
      </Typography>
      <Typography className={classes.subTitle}>We don't recommend doing it manually.</Typography>

      <PrimaryButton disabled={saving} loading={saving} onClick={() => save({ method: 'PUT' })()}>
        YES, PLEASE
      </PrimaryButton>
      <SecondaryButton disabled={saving} onClick={handleClickOpen}>
        Nope, I want to do it manually, one by one.
      </SecondaryButton>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you do not do this now, you'll have to do this manually later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            NO, I WANT TO GO BACK.
          </Button>
          <Button onClick={() => handleClose(true)} variant="contained" color="secondary" autoFocus>
            I'LL DO THIS MANUALLY.
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default NewSchoolYearClearClassrooms
