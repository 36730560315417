import { getQSData } from 'QueryStringController'
import React, { Fragment, useState } from 'react'
import { FUELV2 } from 'fuel'
import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'
import { navigate } from '@reach/router'

import { Transition, Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'

function getFuelLabel(fuelValue: string): string | undefined {
  return Object.values(FUELV2).find((fuel) => fuel.value === fuelValue)?.nineToTwelveLabel
}

type Props = {
  title?: string
  children: any
  currentStep?: number
  showSummary?: boolean
  showBackButton?: boolean
  navButton?: any
  footer?: any
}

export default ({
  title,
  children,
  currentStep,
  showSummary = true,
  showBackButton = false,
  navButton,
  footer,
}: Props) => {
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }
  const { feeling, feeling_intensity, energyLevel } = getQSData()
  return (
    <div className="p-4 pt-2 flex flex-col min-h-screen">
      <nav className="flex items-center justify-between pb-2">
        <img src="/images/logos/nineToTwelveCheckInLogo.svg" alt="" className="h-full" />
        <button onClick={openModal}>
          <img
            src="/images/icons/person.svg"
            alt=""
            className="h-full p-2 rounded-full bg-white/15"
          />
        </button>
        <ConfirmExitModal isOpen={isOpen} closeModal={closeModal} />
      </nav>
      <main className="shadow-[0px_0px_20px_200px_rgba(255,255,255,0.15)] flex flex-col items-center h-full flex-grow rounded-3xl p-4 gap-4">
        <div
          data-visible={showBackButton || currentStep !== undefined}
          className="flex justify-between items-center w-full data-[visible=true]:h-8 z-10"
        >
          <div data-visible={showBackButton} className="min-w-8 data-[visible=true]:h-8">
            {showBackButton && (
              <button onClick={() => navigate(-1)}>
                <ArrowLeftCircleIcon className="h-8 w-8 text-gray-100"></ArrowLeftCircleIcon>
              </button>
            )}
          </div>
          {currentStep !== undefined && (
            <div className="max-w-fit p-2 bg-white bg-opacity-[15%] rounded-full flex gap-2 shadow-sm">
              {[...Array(6)].map((_, step) => {
                if (step < currentStep)
                  return <div key={step} className="bg-cyan-500 h-2 w-2 rounded-full"></div>
                if (step > currentStep)
                  return (
                    <div
                      key={step}
                      className="h-2 w-2 rounded-full border-[1px] border-white"
                    ></div>
                  )
                return <div key={step} className="bg-white h-2 w-2 rounded-full"></div>
              })}
            </div>
          )}
          <div data-visible={!!navButton} className="min-w-8 data-[visible=true]:h-8">
            {navButton}
          </div>
        </div>
        {title && (
          <p className="text-2xl sm:text-4xl text-white sm:pt-4 mx-auto text-center z-10">
            {title}
          </p>
        )}
        <div className="flex flex-col flex-grow w-full items-center justify-center">{children}</div>
        {showSummary && (
          <div className="hidden sm:flex gap-2 w-full ">
            <div className=" py-2 px-4 bg-white bg-opacity-[15%] rounded-full shadow-sm">
              <p className="text-gray-100 text-base font-normal font-['Roboto'] uppercase leading-snug tracking-[3.20px]">
                FEELING <span className="uppercase font-extrabold pl-2">{feeling || '???'}</span>
              </p>
            </div>
            <div className=" py-2 px-4 bg-white bg-opacity-[15%] rounded-full shadow-sm ">
              <p className="text-gray-100 text-base font-normal font-['Roboto'] uppercase leading-snug tracking-[3.20px]">
                INTENSITY{' '}
                <span className="uppercase font-extrabold pl-2">
                  {[...Array(5)].map((_, i) => {
                    if (i < parseInt(feeling_intensity)) {
                      return <span key={i}>I</span>
                    }
                    return (
                      <span key={i} className="opacity-15">
                        I
                      </span>
                    )
                  })}
                </span>
              </p>
            </div>
            <div className="flex items-center gap-4 ml-auto py-2 px-4 bg-white bg-opacity-[15%] rounded-full shadow-sm ">
              <img src="/images/energyLevels/9to12/electric_bolt.svg" alt="" />
              {energyLevel && (
                <p className="text-gray-100 text-base font-normal font-['Roboto'] uppercase leading-snug tracking-[3.20px]">
                  {getFuelLabel(energyLevel)}
                </p>
              )}
            </div>
          </div>
        )}
        {footer}
      </main>
    </div>
  )
}

const ConfirmExitModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-left text-black text-2xl font-medium font-['Roboto'] leading-[28.80px] mb-8"
                >
                  Confirm exit
                </Dialog.Title>
                <div className="mt-2 text-zinc-500 text-lg font-normal font-['Roboto'] leading-snug">
                  <p className="mb-4">
                    If you leave now, all your progress will be lost, and you'll need to start over
                    from the beginning next time.
                  </p>
                </div>
                <div className="flex flex-col sm:flex-row gap-4 mt-10">
                  <button
                    onClick={closeModal}
                    className="
                      transition-all  text-zinc-600 border-zinc-600 text-base font-bold font-['Roboto'] 
                      px-4 h-12 w-full border-2 tracking-[3.20px] uppercase leading-4  bg-cover bg-no-repeat 
                      bg-center rounded-full shadow-sm hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] 
                      hover:bg-gradient-to-r hover:from-zinc-600/20 hover:to_zinc-600/0
  "
                  >
                    CONTINUE
                  </button>
                  <button
                    onClick={() => navigate('/')}
                    className="transition-all shadow-md bg-gradient-to-br from-[#04C8C8] to-[#108A93] text-gray-100  font-bold h-12 w-full rounded-full uppercase text-lg disabled:bg-teal-200 disabled:text-gray-100 disabled:opacity-40 hover:shadow-[inset_4px_8px_40px_0px_rgba(255,255,255,0.25)]"
                  >
                    EXIT
                  </button>
                </div>
                <button onClick={closeModal} className="absolute top-4 right-4">
                  <XMarkIcon className="text-zinc-600 h-6 w-6" />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
