import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { navigate } from '@reach/router'
import { useCurrentUser, useSignIn } from 'dmcroods-auth'
import PageTitle from 'components/PageTitle'
import { handleStudentRoutes } from './vacationHelper'
import { isStudent, isEducator, isTherapist, isParent } from 'students/helper'
import { Layout } from 'auth/signUpFlow/components/Layout'
import { CadetImage, Container } from 'auth/signUpFlow/SignUpStudent/style'
import { useScreenSize } from 'screenSize'
import { Title } from 'auth/signUpFlow/components/styled'
import { SignUpMessage } from 'auth/signUpFlow/components/SignUpMessage'
import { useSchools } from 'Educators/SchoolsContext'
import { useMixpanel } from 'mixpanel'
import Form from './Form'

export const redirectRoute = (user, nextRoute) => {
  if (isEducator(user.role)) return nextRoute || '/dashboard'
  if (user.changePasswordOnNextLogin) return '/change-password'
  if (isStudent(user.role)) return handleStudentRoutes(user, nextRoute)
  if (isParent(user.role)) return nextRoute || '/welcome-back'
  if (isTherapist(user.role)) return nextRoute || '/welcome-back'
}

const SignInComponent = (props) => {
  const mixpanel = useMixpanel()
  const [userEmail] = useState(props?.location?.state?.userEmail || '')
  const { isMobile } = useScreenSize()
  const { clearSchoolId } = useSchools()
  const [{ currentUser }] = useCurrentUser()
  const [{ signingIn, error }, signIn] = useSignIn({
    afterSuccess: ({ data: { data } }) => {
      trackMixpanel(data)
      if (!userEmail) {
        clearSchoolId()
      }
    },
  })
  const queryString = new URLSearchParams(window.location.search)
  const nextQueryParam = queryString.get('next')

  useEffect(() => {
    if (currentUser) {
      const queryString = new URLSearchParams(window.location.search)
      const nextRoute = redirectRoute(currentUser, queryString.get('next'))
      navigate(nextRoute, { replace: true })
    }
  }, [currentUser])

  function trackMixpanel(user) {
    mixpanel.identify(user.email)
    const userData = {
      $email: user.email,
      $phone: user.phoneNumber,
      $name: `${user.firstName} ${user.lastName}`,
      $first_name: user.firstName,
      $last_name: user.lastName,
      role: user.role,
      student_id: user.studentId,
    }
    mixpanel.people.set(userData)
    mixpanel.track('Sign In', { currentUser: userData })
  }

  const title = props?.location?.state?.userEmail
    ? 'Please Re-Enter Your Password'
    : 'Log in to Closegap'

  return (
    <Layout>
      {isMobile ? null : <CadetImage />}
      <PageTitle text="Sign-in &mdash; Closegap" />
      <Helmet>
        <meta
          name="description"
          content="Welcome back! Closegap is a child-facing emotional wellness platform that captures how kids feel and helps educators respond. We're so glad you're here!"
        />
      </Helmet>
      <Container>
        <Title style={{ margin: '3rem 0' }}>{title}</Title>
        <Form
          apiError={error}
          create={signIn}
          creating={signingIn}
          userEmail={userEmail || ''}
          nextRoute={nextQueryParam}
        />
        <SignUpMessage />
      </Container>
    </Layout>
  )
}

export default SignInComponent
