import React, { useEffect } from 'react'

import Screen from 'checkIn/components/nonReader/Screen'
import CadetImage from 'components/CadetImage'
import withMixpanel from 'mixpanel/withMixpanel'
import { checkInTrack, isWeekendToday } from 'students/helper'
import ButtonNext from 'checkIn/components/ButtonNext'
import { setQSData } from 'QueryStringController'
import { QUESTIONS_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'

export const TalkToAnAdultNonReader = withMixpanel(({ mixpanel, flow, ...props }) => {
  const { nextFunction } = flow

  useEffect(() => {
    checkInTrack(mixpanel, 'Check-in: Talk to an adult', props)
  }, []) // eslint-disable-line

  return (
    <Screen
      label="I can go ask for help."
      title="Do you want to talk to an adult?"
      audios={{ autoplay: QUESTIONS_PAGE['talk-to-an-adult'] }}
    >
      {isWeekendToday() && (
        <p className="max-w-lg px-5 text-base font-medium leading-7 text-center text-white">
          Please keep in mind that an educator won&apos;t be able to speak with you until the next
          school day. Would you like to let them know you want to talk then?
        </p>
      )}
      <CadetImage
        className="!sticky my-10 !h-60 sm:!h-auto sm:my-20"
        src="/images/cool_down_cadet.png"
        alt="Sad cadet"
      />
      <div className="flex justify-evenly items-center w-[70vw] flex-col sm:flex-row gap-10">
        <AudioPlayer importAudio={QUESTIONS_PAGE['yes-please']}>
          <ButtonNext
            className="w-full max-w-sm whitespace-nowrap"
            onClick={() => {
              setQSData({ talkToAnAdult: true })
              nextFunction()
            }}
          >
            <span className="font-medium">Yes, please!</span>
          </ButtonNext>
        </AudioPlayer>

        <AudioPlayer importAudio={QUESTIONS_PAGE['no-thanks']}>
          <ButtonNext
            className="w-full max-w-sm whitespace-nowrap"
            onClick={() => {
              setQSData({ talkToAnAdult: false })
              nextFunction()
            }}
          >
            <span className="font-medium">No, thanks.</span>
          </ButtonNext>
        </AudioPlayer>
      </div>
    </Screen>
  )
})
