import React from 'react'
import Screen from '../components/screen'
import { SecondaryButton } from '../components/SecondaryButton'
import { navigateWithParams } from 'utils'

const NineToTwelve = () => {
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        Which activity sounds good to you right now?
      </div>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() => navigateWithParams('./breathing', { activity: 'breathing' })}
      >
        Breathing
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() => navigateWithParams('./let-it-go', { activity: 'let-it-go' })}
      >
        let it go
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() => navigateWithParams('./safe-space', { activity: 'safe-space' })}
      >
        safe space
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() =>
          navigateWithParams('./the-ok-inventory', { activity: 'the-ok-inventory' })
        }
      >
        the ok inventory
      </SecondaryButton>

      <SecondaryButton
        additionalClasses="mb-6"
        handleNextFunction={() => navigateWithParams('./journaling', { activity: 'journaling' })}
      >
        journaling
      </SecondaryButton>
    </Screen>
  )
}

export default NineToTwelve
