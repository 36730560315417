import React from 'react'
import moment from 'moment'

import { Row } from 'components/StudentItem'
import { NotesContainer, Text, Message, EmailIcon } from './styles'

const formatDateTime = (date) => moment(date).format('MM/DD/YYYY h:mmA')

export const Notes = ({ name, date, notes, isStudent, highlight = false, fromCheckIn }) => (
  <NotesContainer highlight={highlight} isStudent={isStudent}>
    {highlight && <EmailIcon size={20} className="text-white" />}
    <Row>
      <Text dark>{name}</Text>
      <Text>{formatDateTime(date)}</Text>
      {fromCheckIn && <Text>From check-in</Text>}
    </Row>
    <Message dangerouslySetInnerHTML={{ __html: notes.replace(/\n/g, '<br />') }} />
  </NotesContainer>
)
