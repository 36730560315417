import React, { memo, useMemo, useState } from 'react'
import { MdExpandLess, MdExpandMore } from 'react-icons/md'
import get from 'lodash/get'

import { Notes } from 'Educators/students/list/Comments/Notes'
import isParent from 'auth/isParent'
import { isEducator, isStudent } from 'students/helper'
import ListComments from 'Educators/students/list/Comments/ListComments'
import type { CheckIn, Student, User } from 'types'
import { MessagesButton } from './styles'
import { featureEnabled } from 'featureFlags'
import { Link } from '@reach/router'
import { MessagesIcon } from 'components/DashboardScreenV3'
import ListCommentsV2 from './ListCommentsV2'

type Props = {
  student: Student
  initialStateOpen?: boolean
  currentUser: User
  checkIn: CheckIn
}

export const Comments = memo(
  ({ student, initialStateOpen = false, currentUser, checkIn }: Props) => {
    const [open, setOpen] = useState(initialStateOpen)
    const { firstName, lastName, lastSawProfileAt, schoolId } = student

    const canSeeMessages = useMemo(
      () =>
        isStudent(currentUser.role) ||
        (schoolId ? isEducator(currentUser.role) : !isParent(currentUser)),
      [currentUser, schoolId],
    )

    if (!canSeeMessages) return null

    if (featureEnabled(currentUser, 'message_center') && !isStudent(currentUser.role)) {
      return (
        <div className="mb-4">
          {get(checkIn, 'notes') && (
            <Notes
              name={`${firstName} ${lastName}`}
              date={checkIn.createdAt}
              notes={checkIn.notes}
              isStudent
              fromCheckIn
            />
          )}

          <ListCommentsV2 checkInId={checkIn.id} />

          <Link
            to={`/v2/schools/${student.schoolId}/messages/${student.id}`}
            className="my-4 text-red-500 hover:text-red-600"
          >
            <MessagesIcon /> {checkIn.notes ? 'Reply' : 'Send a message'}
          </Link>
        </div>
      )
    }

    return (
      <div className="mb-4">
        {get(checkIn, 'notes') && (
          <Notes
            name={`${firstName} ${lastName}`}
            date={checkIn.createdAt}
            notes={checkIn.notes}
            isStudent
            fromCheckIn
          />
        )}

        <MessagesButton onClick={() => setOpen((prevState) => !prevState)}>
          Messages{' '}
          {open ? (
            <MdExpandLess data-testid="expand-less" size={24} />
          ) : (
            <MdExpandMore data-testid="expand-more" size={24} />
          )}
        </MessagesButton>

        {open && (
          <ListComments
            checkInId={checkIn.id}
            currentUser={currentUser}
            lastSawProfileAt={lastSawProfileAt}
          />
        )}
      </div>
    )
  },
)
