import React, { useEffect } from 'react'
import { Container } from '@material-ui/core'

import Breadcrumbs from 'components/Breadcrumbs'
import validateUserFields from 'auth/validateUserFields'
import { DashboardScreen } from 'components/DashboardScreen'

import Card from 'components/Card'
import Options from 'Educators/students/insights/Alerts/Options'
import List from 'Educators/students/insights/Alerts/List'
import { useSchools } from 'Educators/SchoolsContext'

const Insights = validateUserFields(({ ...props }) => {
  const { school } = useSchools()
  const { studentType = 'all', filter } = props?.location?.state ?? {}

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <DashboardScreen {...props} propsFooter={{ className: 'hide-to-print' }}>
      <Container>
        <div className="content-to-print">
          <Breadcrumbs
            paths={[
              {
                children: 'Discovery',
                to: `/v2/schools/${school.id}/discovery`,
                replace: true,
              },
            ]}
          >
            Alert Settings
          </Breadcrumbs>
          <Card
            className="mt-20"
            title="SET NEW ALERT"
            propsMain={{
              className: 'm-5 sm:m-10',
            }}
          >
            <Options
              initialStudentType={studentType}
              initialState={filter}
              openAccordion={!!studentType}
            />
          </Card>
          <List />
        </div>
      </Container>
    </DashboardScreen>
  )
})

export default Insights
