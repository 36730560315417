import React from 'react'

import Label from 'components/Label'

export default ({ children, style = {} }) => (
  <Label
    color="primary.dark"
    style={{
      padding: '0',
      marginBottom: '25px',
      fontWeight: 'normal',
      ...style,
    }}
  >
    {children}
  </Label>
)
