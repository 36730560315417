import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'

const styles = (theme) => ({
  screen: {
    backgroundPosition: 'bottom 0 left 0',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    justifyContent: 'center',
    flexFlow: 'row wrap',
    alignItems: 'center',
    height: '100vh',
    display: 'flex',
    width: '100vw',
    backgroundSize: '40% auto',
    [theme.breakpoints.down('sm')]: {
      backgroundSize: '100%',
    },
  },
  balloon: {
    boxShadow: `inset 12px 0px 0px 0px ${theme.colors.mystic}`,
    background: theme.colors.roseWhite,
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    borderRadius: 6,
    display: 'flex',
    padding: '56px 70px',
    left: 50,
    [theme.breakpoints.down('sm')]: {
      left: 80,
      bottom: 79,
      maxWidth: 300,
    },
  },
  arrow: {
    position: 'absolute',
    bottom: 3,
    left: -14,
    borderTop: '15px solid transparent',
    borderBottom: '15px solid transparent',
    borderRight: `15px solid ${theme.colors.roseWhite}`,
  },
  title: {
    color: theme.colors.carbon,
    textAlign: 'center',
    marginBottom: 16,
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 18,
    },
  },
  text: {
    fontSize: 36,
    textAlign: 'center',
    color: theme.colors.steelGray,
    marginBottom: 40,
    paddingLeft: 10,
    paddingRight: 10,
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
  },
})

export default withWidth()(
  withStyles(styles)(
    ({
      classes,
      title,
      text,
      width,
      children,
      backgroundImage = "url('/images/cadet_on_the_moon.png')",
      backgroundImageMobile = "url('/images/cadet_on_the_moon_mobile.png')",
    }) => (
      <div
        className={classes.screen}
        style={{
          backgroundImage: isWidthUp('sm', width)
            ? backgroundImage
            : backgroundImageMobile,
        }}
      >
        <div className={classes.balloon}>
          <div className={classes.arrow} />
          <Typography className={classes.title}>{title}</Typography>
          <Typography className={classes.text}>{text}</Typography>
          {children}
        </div>
      </div>
    ),
  ),
)
