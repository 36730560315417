import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData } from 'QueryStringController'
import TeenV2 from '../../../checkIn/steps/EnergyLevelConfirmation/TeenV2'

const SCREEN_NAME = 'energyLevelConfirmation'

type EnergyLevels = 'green' | 'yellow' | 'blue' | 'red'

const EnergyLevelConfirmation: RouteComponent = () => {
  const { student } = useCheckIn()

  const value = getQSData().energyLevel as EnergyLevels

  if (student) {
    const { nextFunction } = FLOWS['teen'][SCREEN_NAME]

    return <TeenV2 nextFunction={nextFunction} value={value} />
  }

  return null
}

export default EnergyLevelConfirmation
