import React from 'react'

import Header from 'components/dashboard/Header'
import { Footer } from 'components/dashboard/Footer'
import { User } from 'types'
import HelperCadet from 'Educators/ManageSchool/NewSchoolYear/Components/helperCadet'
import { featureEnabled } from 'featureFlags'
import { DashboardScreenV3 } from './DashboardScreenV3'

interface IDashboardScreen {
  [x: string]: any
  children: any
  currentUser: User
  propsFooter?: {
    className?: string | undefined
  }
  propsHeader?: {
    propsMenuSchool: {
      disabled?: boolean | undefined
      hidden?: boolean | undefined
      shoAllPremiumSchools?: boolean | undefined
      redirectOnChange?: boolean | undefined
    }
  }
  minimal?: boolean | undefined
  helperCadetCustomLink?: string | undefined
  hideHelperCadet?: boolean | undefined
}

const DashboardScreen = ({ children, propsFooter, propsHeader, ...props }: IDashboardScreen) => {
  if (featureEnabled(props.currentUser, 'new_wrapper')) {
    return (
      <DashboardScreenV3 {...props} propsHeader={propsHeader}>
        <div className="bg-[#F2F1F8] p-4 w-full flex flex-col items-center">{children}</div>
      </DashboardScreenV3>
    )
  }
  return (
    <>
      <Header {...props} {...propsHeader} />
      <div className="flex justify-center items-center py-12 min-h-[280px] bg-gray-50 screen-to-print">
        {children}
      </div>
      <Footer {...props} {...propsFooter} />
      {!props.hideHelperCadet && <HelperCadet customLink={props.helperCadetCustomLink} />}
    </>
  )
}

export { DashboardScreen }
