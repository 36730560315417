import React from 'react'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import { Title } from '../components'
import { getLocalStorageCheckIn, setLocalStorageCheckIn } from 'students/helper'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'
import styled from 'styled-components'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const ButtonContainer = styled.div`
  max-width: 468px;
  width: 100%;
  margin: 2rem 0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem 6rem;
  border-radius: 16px;
  @media screen and (max-width: 600px) {
    gap: 1rem;
  }
`

const ColorButton = styled.button<{ background: string; selected: boolean; unselected?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 24px;
  font-weight: 500;
  min-height: 80px;
  background: ${({ background, unselected }) => (unselected ? 'none' : background)};
  border: ${({ unselected }) => (unselected ? '1px solid #fff' : '0px solid #fff')};
  border-radius: 8px;
  transition: all ease 0.25s;
  :hover {
    filter: brightness(1.2);
  }
  @media screen and (max-width: 600px) {
    min-height: 4rem;
    min-width: 10rem;
  }
`

const Teen = ({ nextFunction }: Props) => {
  const [selectedOption, setSelectedOption] = React.useState<string | null>(null)
  const color = getLocalStorageCheckIn('color') || '#03b2ad'
  // const colors = ['#00ddc1', '#8537E3', '#FBDE3F', '#80E217', '#FF5576', '#D31919']

  const onClick = (number: string) => {
    setLocalStorageCheckIn('impactQ5', number)
    setSelectedOption(number)
  }

  return (
    <CheckInContainer bottom bg="">
      <Title>How many adults at school do you feel close to?</Title>

      <ButtonContainer>
        {['None', '1 adult', '2 or 3 adults', '4 or more adults'].map((number: string) => (
          <ColorButton
            key={number}
            background={color}
            selected={selectedOption === number}
            unselected={!!selectedOption && selectedOption !== number}
            onClick={() => onClick(number)}
          >
            {number}
          </ColorButton>
        ))}
      </ButtonContainer>
      {selectedOption ? (
        <BackAndNextButtons nextButtonOnClick={() => nextFunction(window.location.search)} />
      ) : null}
    </CheckInContainer>
  )
}

export default Teen
