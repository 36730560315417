import React from 'react'

import { DashboardScreen } from 'components/DashboardScreen'
import FormContainer from 'components/FormContainer'
import validateUserFields from 'auth/validateUserFields'

import New from 'Educators/profile/edit/New'
import Destroy from 'components/destroy'
import { useDeleteAccount } from 'dmcroods-auth'
import { navigate } from '@reach/router'
import { isEducator } from 'students/helper'

export default validateUserFields((props) => {
  const renderButtonText = () => 'Delete My Account'

  const { currentUser } = props

  const [{ deleting, error }, deleteAccount] = useDeleteAccount({
    afterSuccess: () => {
      navigate('/sign-out?clear=true')
    },
  })

  return (
    <DashboardScreen {...props} propsHeader={{ propsMenuSchool: { hidden: true } }}>
      <FormContainer
        whiteBoxStyle={{ paddingTop: '0' }}
        secondaryLink={
          <Destroy
            dialogTitle="Are you sure you want to delete your account?"
            dialogSubtitle={
              isEducator(currentUser.role)
                ? 'If you are the only School Level Access user at your school(s) and you choose to delete your account, the school(s) will be deleted, and all of the associated data, including student accounts.'
                : ''
            }
            renderButtonText={renderButtonText}
            destroyError={error}
            destroy={() => deleteAccount}
            destroying={deleting}
          />
        }
      >
        <New user={currentUser} />
      </FormContainer>
    </DashboardScreen>
  )
})
