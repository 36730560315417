import React, { memo, useMemo } from 'react'
import { navigate } from '@reach/router'

import { useMixpanel } from 'mixpanel'
import { checkboxType } from 'Educators/students/list/panel/ReminderCheckIn/helper'
import { welcomePath } from 'students/helper'
import { Checkbox, Name, Grade, Row, Button, Divider } from 'components/StudentItem'
import { ButtonWrapper, StudentItemContainer, NameWrapper, CheckBoxWrapper } from './styles'

export const StudentItem = memo(({ student, selected, onClick }) => {
  const mixpanel = useMixpanel()

  const onClickEdit = () => {
    mixpanel.track('Student Name Clicked', { studentId: student.id })
    return navigate(`/dashboard/students/${student.id}/edit`)
  }

  const onClickCheckin = () => {
    mixpanel.track('Checking through educator Dash clicked', { studentId: student.id })
    return navigate(welcomePath(student))
  }

  const isIndeterminate = useMemo(() => selected === checkboxType.INDETERMINATE, [selected])

  const checkIsSelected = useMemo(
    () => () => {
      switch (selected) {
        case checkboxType.CHECKED:
        case checkboxType.INDETERMINATE:
          return true
        default:
          return false
      }
    },
    [selected],
  )

  const handleOnClick = () =>
    !isIndeterminate &&
    onClick(
      student.id,
      selected === checkboxType.UNCHECKED ? checkboxType.CHECKED : checkboxType.UNCHECKED,
    )

  return (
    <>
      <StudentItemContainer>
        <Row>
          <CheckBoxWrapper>
            <Checkbox
              checked={checkIsSelected()}
              disabled={isIndeterminate}
              onClick={handleOnClick}
            />
          </CheckBoxWrapper>
          <NameWrapper onClick={onClickEdit}>
            <Name>{`${student.firstName} ${student.lastName}`}</Name>
            <Grade>Grade {student.grade}</Grade>
          </NameWrapper>
        </Row>

        <ButtonWrapper>
          <Button onClick={onClickCheckin} title="CHECK IN" iconPath="/images/white-rocket.png" />
        </ButtonWrapper>
      </StudentItemContainer>
      <Divider />
    </>
  )
})
