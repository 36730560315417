import React from 'react'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { TeenThanks } from 'checkIn/steps/ThanksForExploring/Teen'
import Kid from 'checkIn/steps/ThanksForExploring/Kid'
import NonReader from 'checkIn/steps/ThanksForExploring/NonReader'
import { useActivity } from 'activities/activityContext'

const SCREEN_NAME = 'thanksForExploring'

const ThanksForExploring: RouteComponent = () => {
  const { student } = useActivity()
  const [{ currentUser }] = useCurrentUser()

  const energyLevel = 'green'

  if (student && currentUser) {
    const flow = FLOWS[student.studentType][SCREEN_NAME]

    switch (student.studentType) {
      case 'teen':
        return (
          <TeenThanks
            flow={flow}
            energyLevel={energyLevel}
            student={student}
            currentUser={currentUser}
          />
        )
      case 'kid':
        return (
          <Kid flow={flow} energyLevel={energyLevel} student={student} currentUser={currentUser} />
        )
      case 'non-reader':
        return (
          <NonReader
            flow={flow}
            energyLevel={energyLevel}
            student={student}
            currentUser={currentUser}
          />
        )
    }
  }

  return null
}

export default ThanksForExploring
