import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'

import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'

import type { School, User } from 'types'
import { Toast } from 'components/Toast'
import { navigate } from '@reach/router'
import { SecondaryButton } from '../Components/buttons'
import { useSchools } from 'Educators/SchoolsContext'
import { useCroods } from 'croods'

type Props = { currentUser: User }

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '16px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  schoolSelectionItem: {
    marginBottom: '10px',
    alignItems: 'center',
    display: 'flex',
    padding: '10px',
    border: '2px solid #6750A3',
    borderRadius: '8px',
    fontSize: '24px',
    color: '#6750A3',
    fontWeight: 700,
  },
  chosenSchoolSelectionItem: {
    marginBottom: '10px',
    alignItems: 'center',
    display: 'flex',
    padding: '10px',
    border: '2px solid #6750A3',
    borderRadius: '8px',
    backgroundColor: '#6750A3',
    boxShadow: '0px 4px 8px 0px rgba(123, 111, 171, 0.25)',
    '& > p': {
      fontSize: '24px',
      color: '#fff',
      fontWeight: 700,
    },
  },
  schoolSelectionItemText: {
    lineHeight: '24px',
    marginLeft: '10px',
  },
  schoolSelector: {
    marginTop: '70px',
  },
}))

type SchoolWithRole = {
  school: School
  id: number
  role: string
}

const ChooseSchool = ({ currentUser }: Props) => {
  const { schoolId, schools, setSchoolId, role } = useSchools()
  const classes = useStyles()
  const [{ list: classrooms, fetchingList: loading }, { fetch: fetchClassroom }] = useCroods({
    cache: false,
    stateId: schoolId,
    name: 'classrooms',
    customPath: `/schools/${schoolId}/classrooms`,
  })
  const [{ list: students = [], fetchingList: fetchingStudents }, { fetch: fetchStudents }] =
    useCroods({
      name: 'students',
    })
  React.useEffect(() => {
    if (schoolId) {
      fetchClassroom({})()
      fetchStudents({ customPath: `schools/${schoolId}/students` })()
    }
    // eslint-disable-next-line
  }, [schoolId])

  const navigateToNextStep = () => {
    if (role === 'owner') {
      if (schools.find((schl: SchoolWithRole) => schl.school.id === schoolId).school.premium) {
        navigate('/manage-school/new-school-year/insights-school/set-up')
      } else {
        navigate(
          `/manage-school/new-school-year/${
            classrooms.length > 0 || students.length > 0 ? 'returning-school' : 'new-school'
          }/set-up`,
        )
      }
    } else {
      navigate('/manage-school/new-school-year/working-on-it')
    }
  }

  return (
    <DashboardScreen currentUser={currentUser} minimal={true}>
      <Container className={classes.container}>
        <Typography className={classes.heading}>
          Which school do you want to set up first?
        </Typography>
        <div className={classes.schoolSelector}>
          {schools.map((school: SchoolWithRole) => (
            <div
              className={
                schoolId === school.school.id
                  ? classes.chosenSchoolSelectionItem
                  : classes.schoolSelectionItem
              }
              key={school.id}
              onClick={() => {
                setSchoolId(school.school.id)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="41"
                viewBox="0 0 40 41"
                fill="none"
              >
                <path
                  d="M20 12.5L30.6667 17.8333V39.1667H9.33333V17.8333L20 12.5ZM20 12.5V0.5M0 39.1667H40M4 39.1667V23.1667H9.33333M36 39.1667V23.1667H30.6667M17.3333 39.1667V31.1667H22.6667V39.1667M20 1.83333H28V7.16667H20M20 25.8333C19.2928 25.8333 18.6145 25.5524 18.1144 25.0523C17.6143 24.5522 17.3333 23.8739 17.3333 23.1667C17.3333 22.4594 17.6143 21.7811 18.1144 21.281C18.6145 20.781 19.2928 20.5 20 20.5C20.7072 20.5 21.3855 20.781 21.8856 21.281C22.3857 21.7811 22.6667 22.4594 22.6667 23.1667C22.6667 23.8739 22.3857 24.5522 21.8856 25.0523C21.3855 25.5524 20.7072 25.8333 20 25.8333Z"
                  stroke={schoolId === school.school.id ? 'white' : '#6750A3'}
                  stroke-width="2"
                />
              </svg>
              <p className={classes.schoolSelectionItemText}>{school.school.name}</p>
            </div>
          ))}
        </div>
        <SecondaryButton
          disabled={!schoolId || loading || fetchingStudents}
          onClick={() => {
            navigateToNextStep()
          }}
        >
          NEXT
        </SecondaryButton>
      </Container>
      <Toast />
    </DashboardScreen>
  )
}

export default validateUserFields(ChooseSchool)
