import React from 'react'
import { map, some } from 'lodash'

import { CircularItem } from 'checkIn/components/CircularElements'
import Moon from 'checkIn/steps/BodySensations/ThreeToFourBodySensations/Moon'
import planetList from 'checkIn/constants/threeToFour/planetList'

type Props = {
  feelingContent: typeof planetList[number]
  selectedSensations: string[]
  moonStyle:
    | {
        position: string
        transform: null
        top: number
        left: number
      }
    | undefined
  isConfirmationPage: boolean
  handleMoon: (item: string) => void
  startDeg: number
  endDeg: number
  distance: string
}

const FeelingContent = ({
  feelingContent,
  selectedSensations,
  moonStyle,
  isConfirmationPage,
  handleMoon,
  ...props
}: Props) => {
  const elements = map(feelingContent?.sensations, (option, index) => {
    const selected = some(selectedSensations, (item) => item.toLowerCase() === option.toLowerCase())

    const { startDeg, endDeg, distance } = props

    return (
      <CircularItem
        key={index}
        style={moonStyle}
        length={feelingContent?.sensations?.length}
        startDeg={startDeg}
        endDeg={endDeg}
        distance={distance}
        index={index}
        selected={false}
      >
        <Moon
          key={index}
          handleMoon={handleMoon}
          hideUnselected={isConfirmationPage}
          selected={selected}
          option={option}
          isConfirmationPage={isConfirmationPage}
        />
      </CircularItem>
    )
  })

  return <div>{elements}</div>
}

export default FeelingContent
