import React, { useEffect } from 'react'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { TeenThanks } from 'checkIn/steps/ThanksForExploring/Teen'
import Kid from 'checkIn/steps/ThanksForExploring/Kid'
import NonReader from 'checkIn/steps/ThanksForExploring/NonReader'
import { checkInTrack } from 'students/helper'
import { getQSData } from 'QueryStringController'
import { useMixpanel } from 'mixpanel'

const SCREEN_NAME = 'thanksForExploring'

const ThanksForExploring: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  const { energyLevel } = getQSData()

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, 'Check-in: End of check-in', student)
    }
  }, []) // eslint-disable-line

  if (student && currentUser) {
    const flow = FLOWS[student.studentType][SCREEN_NAME]

    switch (student.studentType) {
      case 'teen':
        return (
          <TeenThanks
            flow={flow}
            energyLevel={energyLevel}
            student={student}
            currentUser={currentUser}
          />
        )
      case 'kid':
        return (
          <Kid flow={flow} energyLevel={energyLevel} student={student} currentUser={currentUser} />
        )
      case 'non-reader':
        return (
          <NonReader
            flow={flow}
            energyLevel={energyLevel}
            student={student}
            currentUser={currentUser}
          />
        )
    }
  }

  return null
}

export default ThanksForExploring
