import React, { useState } from 'react'
import remove from 'lodash/remove'
import includes from 'lodash/includes'
import upperFirst from 'lodash/upperFirst'

import Screen from 'checkIn/components/teen/Screen'

import type { RouteComponent } from 'types'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

import FLOWS from 'checkIn/flows'
import { compact, isEmpty } from 'lodash'
import { getQSData, setQSData } from 'QueryStringController'
import FiveToEight from '../../../checkIn/steps/SecondaryEmotions/FiveToEight'

const SecondaryEmotions: RouteComponent = () => {
  const data = getQSData()

  const [selected, setSelected] = useState<Array<string>>([])

  const handleOptionClick = (item: string) => {
    const value = item.toLowerCase()

    if (includes(selected, value)) {
      remove(selected, (element) => element === value)
    } else {
      selected.push(value)
    }
    const compactedSelected = compact(selected)
    setSelected(compactedSelected)
  }

  const { nextFunction } = FLOWS.teen.secondaryEmotions

  const handleNextFunction = () => {
    if (!isEmpty(selected)) {
      setQSData({ secondaryEmotions: selected.join(',') })
    }
    nextFunction(window.location.search)
  }

  const feeling = data.feeling

  return (
    <Screen
      label={`Thanks for sharing. Here are some emotions that relate to feeling ${upperFirst(
        feeling,
      )}.`}
      title="Are you feeling any of these emotions?"
    >
      <FiveToEight feeling={feeling} selected={selected} handleOptionClick={handleOptionClick} />

      <BackAndNextButtons nextButtonOnClick={handleNextFunction} showBackButton />
    </Screen>
  )
}

export default SecondaryEmotions
