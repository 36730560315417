import React, { useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller, useWatch } from 'react-hook-form'
import { useCroods } from 'croods'
import { navigate } from '@reach/router'
// @ts-ignore
import { useFlash } from 'seasoned-flash'
import { Title, Text, Button, InternalLink } from 'auth/signUpFlow/components/styled'
import {
  Container,
  ErrorMessage,
  InputContainer,
  Label,
  StyledInput,
} from 'auth/signUpFlow/components/Input/style'
import listCountries from 'auth/signUpStep/listCountries'
import { BackLinkContainer } from 'auth/signUpFlow/SignUpStudent/style'
import { LoadingContainer } from 'auth/signUpFlow/SignUpSchoolStaff/style'
import { DashboardScreen } from 'components/DashboardScreen'
import { DropdownCountries } from 'components/Dropdown/DropdownCountries'
import { DropdownStates } from 'components/Dropdown/DropdownStates'
import { usaStates } from 'components/SchoolForm/usaStates'
import { Loader } from 'components/Loader'
import { Content } from './style'
import { DropdownEntries } from 'components/Dropdown/DropdownEntries'
import { District } from 'types'
import validateUserFields from 'auth/validateUserFields'

interface SchoolProps {
  countryCode: string | null
  state: string | null
  district: District | null
  name: string
  street: string
  city: string
  postalCode: string
  ncesSchoolId: string | null
}

const INITIAL_STATE = {
  countryCode: 'US',
  state: '',
  districtName: '',
  name: '',
  street: '',
  city: '',
  postalCode: '',
  ncesSchoolId: null,
}

export const AddSchoolInfo = validateUserFields((props: any) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
    setValue,
  } = useForm<SchoolProps>({
    defaultValues: INITIAL_STATE,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })

  const { success } = useFlash()

  const [errorMessage, setErrorMessage] = useState('')

  const [{ saving }, { save }] = useCroods({
    name: 'schoolsList',
    customPath: '/schools',
    afterSuccess: ({ data }) => {
      navigate(`/v2/schools/${data.id}/home`)
      success('School successfully created!')
    },
    afterFailure: ({ response }) => {
      setErrorMessage(response?.data?.message ?? '')
    },
  })

  const onSubmit: SubmitHandler<SchoolProps> = (data) => save()({ ...data })
  const countryCode = useWatch({ name: 'countryCode', control, defaultValue: 'US' })
  const state = useWatch({ name: 'state', control })
  const name = useWatch({ name: 'name', control })
  const street = useWatch({ name: 'street', control })
  const city = useWatch({ name: 'city', control })
  const postalCode = useWatch({ name: 'postalCode', control })
  const district = useWatch({ name: 'district', control })
  const ncesSchoolId = useWatch({ name: 'ncesSchoolId', control })

  const [{ list: districts = [], fetchingList }, { fetch }] = useCroods({
    name: 'district',
    customPath: `/districts?state=${state}`,
  })

  function clearCountryDependencies() {
    setValue('state', null)
  }
  function clearStateDependencies() {
    setValue('district', null)
  }
  function clearDistrictDependencies() {
    setValue('ncesSchoolId', null)
  }
  function clearSchoolDependencies() {
    setValue('name', '')
    setValue('city', '')
    setValue('street', '')
    setValue('postalCode', '')
  }

  useEffect(() => {
    clearCountryDependencies()
  }, [countryCode]) // eslint-disable-line

  useEffect(() => {
    clearStateDependencies()
    if (countryCode === 'US' && Boolean(state)) fetch()()
  }, [state]) // eslint-disable-line

  useEffect(() => {
    clearDistrictDependencies()
    if (countryCode === 'US' && Boolean(state) && Boolean(district)) fetchNcesSchools()()
  }, [district]) // eslint-disable-line

  useEffect(() => {
    if (ncesSchoolId == null) clearSchoolDependencies()
  }, [ncesSchoolId]) // eslint-disable-line

  type NcesSchool = {
    id: string
    name: string
    postalCode: string
    city: string
    street: string
    districtId: number
    schoolId: number
    schoolIdNces: string
    createdAt: string
    updatedAt: string
  }
  const [
    { list: ncesSchools = [], fetchingList: fetchingNcesSchools },
    { fetch: fetchNcesSchools },
  ] = useCroods<NcesSchool>({
    name: 'ncesSchools',
    customPath: `/nces_schools?district_id=${district}`,
  })

  return (
    <DashboardScreen {...props} propsHeader={{ propsMenuSchool: { hidden: true } }} minimal={true}>
      <Content>
        <Title>Please Enter Your School Information</Title>
        <Text style={{ marginBottom: '1rem' }}>
          Please enter complete and accurate information for the fields below!
        </Text>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="countryCode"
            control={control}
            rules={{ required: { value: true, message: 'Country is required' } }}
            render={({ field: { onChange, value } }) => (
              <DropdownCountries
                label="Country"
                options={listCountries}
                value={value}
                onChange={onChange}
                error={errors.countryCode}
                required
              />
            )}
          />

          {countryCode === 'US' ? (
            <Controller
              name="state"
              control={control}
              rules={{ required: { value: true, message: 'State is required' } }}
              render={({ field: { onChange, value } }) => (
                <DropdownStates
                  label="State"
                  options={usaStates}
                  value={value}
                  onChange={onChange}
                  error={errors.state}
                  required
                />
              )}
            />
          ) : (
            <Container>
              <InputContainer>
                <StyledInput
                  invalid={Boolean(errors.state)}
                  type="text"
                  placeholder="State *"
                  {...register('state', {
                    required: { value: true, message: 'State is required' },
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/i,
                      message: 'No whitespace at the beginning and end are allowed',
                    },
                  })}
                />
                {Boolean(state) && <Label invalid={Boolean(errors.state)}>State *</Label>}
              </InputContainer>
              {Boolean(errors.state) ? <ErrorMessage>{errors.state?.message}</ErrorMessage> : null}
            </Container>
          )}

          {countryCode === 'US' && Boolean(state) ? (
            <Controller
              name="district"
              control={control}
              render={({ field: { onChange, value } }) => {
                return fetchingList ? (
                  <Loader />
                ) : (
                  <DropdownEntries
                    entries={districts}
                    value={value?.id}
                    onChange={onChange}
                    error={errors.district}
                    displayValue="District"
                  />
                )
              }}
            />
          ) : null}

          {countryCode === 'US' && Boolean(district) && ncesSchools.length > 0 ? (
            <Controller
              name="ncesSchoolId"
              control={control}
              rules={{ required: { value: false, message: 'School is required' } }}
              render={({ field: { onChange, value } }) =>
                fetchingNcesSchools ? (
                  <Loader />
                ) : (
                  <DropdownEntries
                    entries={ncesSchools}
                    value={Number(value)}
                    onChange={(id) => {
                      if (id === null) return onChange(null)

                      const ncesSchool = ncesSchools.find(({ id: ncesId }) => id === ncesId)
                      if (ncesSchool) {
                        setValue('city', ncesSchool.city)
                        setValue('street', ncesSchool.street)
                        setValue('postalCode', ncesSchool.postalCode)
                        return onChange(id)
                      }
                    }}
                    error={errors.ncesSchoolId}
                    displayValue="School name"
                    onKeyDown={({ key }) => {
                      if (['Backspace', 'Delete'].includes(key)) {
                        setValue('ncesSchoolId', null)
                      }
                    }}
                    onChangeDisplayValue={(name) => setValue('name', name)}
                  />
                )
              }
            />
          ) : (
            <Container>
              <InputContainer>
                <StyledInput
                  invalid={Boolean(errors.name)}
                  type="text"
                  placeholder="School name *"
                  {...register('name', {
                    required: { value: true, message: 'School name is required' },
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/i,
                      message: 'No whitespace at the beginning and end are allowed',
                    },
                  })}
                />
                {Boolean(name) && <Label invalid={Boolean(errors.name)}>School name *</Label>}
              </InputContainer>
              {Boolean(errors.name) ? <ErrorMessage>{errors.name?.message}</ErrorMessage> : null}
            </Container>
          )}

          <Container>
            <InputContainer>
              <StyledInput
                invalid={Boolean(errors.street)}
                disabled={Boolean(ncesSchoolId)}
                type="text"
                placeholder="School address *"
                {...register('street', {
                  required: { value: true, message: 'School address is required' },
                  pattern: {
                    value: /^[^\s]+(\s+[^\s]+)*$/i,
                    message: 'No whitespace at the beginning and end are allowed',
                  },
                })}
              />
              {Boolean(street) && <Label invalid={Boolean(errors.street)}>School street *</Label>}
            </InputContainer>
            {Boolean(errors.street) ? <ErrorMessage>{errors.street?.message}</ErrorMessage> : null}
          </Container>

          <div style={{ display: 'flex', gap: '1rem', width: '100%' }}>
            <Container>
              <InputContainer>
                <StyledInput
                  invalid={Boolean(errors.city)}
                  disabled={Boolean(ncesSchoolId)}
                  type="text"
                  placeholder="City *"
                  {...register('city', {
                    required: { value: true, message: 'City is required' },
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/i,
                      message: 'No whitespace at the beginning and end are allowed',
                    },
                  })}
                />
                {Boolean(city) && <Label invalid={Boolean(errors.city)}>City *</Label>}
              </InputContainer>
              {Boolean(errors.city) ? <ErrorMessage>{errors.city?.message}</ErrorMessage> : null}
            </Container>

            <Container>
              <InputContainer>
                <StyledInput
                  invalid={Boolean(errors.postalCode)}
                  disabled={Boolean(ncesSchoolId)}
                  type="text"
                  placeholder="Postal code *"
                  {...register('postalCode', {
                    required: { value: true, message: 'Postal code is required' },
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/i,
                      message: 'No whitespace at the beginning and end are allowed',
                    },
                  })}
                />
                {Boolean(postalCode) && (
                  <Label invalid={Boolean(errors.postalCode)}>Postal code *</Label>
                )}
              </InputContainer>
              {Boolean(errors.postalCode) ? (
                <ErrorMessage>{errors.postalCode?.message}</ErrorMessage>
              ) : null}
            </Container>
          </div>

          {saving ? (
            <LoadingContainer>
              Requesting your school registration...
              <Loader />
            </LoadingContainer>
          ) : null}

          {Boolean(errorMessage) ? <h1 style={{ color: 'red' }}>{errorMessage}</h1> : null}

          <Button type="submit" disabled={Object.keys(dirtyFields).length === 0 || !isValid}>
            REQUEST SCHOOL REGISTRATION
          </Button>
        </form>

        <BackLinkContainer>
          <InternalLink to="/search-school">{`<-- Back to School Search`}</InternalLink>
        </BackLinkContainer>
      </Content>
    </DashboardScreen>
  )
})
