import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { setQSData } from 'QueryStringController'
import TeenV2 from '../../../checkIn/steps/EnergyLevel/TeenV2'

const SCREEN_NAME = 'energyLevel'

const EnergyLevel: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const { nextFunction } = FLOWS['teen'][SCREEN_NAME]

    const selectFuelFunction = (value: string) => {
      setQSData({ energyLevel: value })
      nextFunction(window.location.search)
    }

    return <TeenV2 selectFuelFunction={selectFuelFunction} />
  }

  return null
}

export default EnergyLevel
