import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { MuiThemeProvider } from '@material-ui/core/styles'

import { CroodsProvider } from 'croods'
import { AuthProvider, authHeaders, saveHeaders } from 'dmcroods-auth'

import { MixpanelProvider } from 'react-mixpanel'
import mixpanel from 'mixpanel-browser'
import { Provider } from 'react-redux'
import FlashMessage from 'seasoned-flash'
import snakeCase from 'lodash/snakeCase'

import Loading from 'components/Loading'
// import AuthLoading from 'components/AuthLoading'
import PageTitle from 'components/PageTitle'

import store from 'store/store'
import theme from 'theme'
import Router from 'Router'
import CheckInRouter from 'CheckInRouter'

import { SchoolsProvider } from 'Educators/SchoolsContext'
import { AudioProvider } from 'checkIn/AudioContext'
import { CheckInProvider } from 'checkIn/CheckInContext'
import { ActivityProvider } from 'activities/activityContext'
import { ActivityRouter } from 'activities/router'
import DemoRouter from 'demo/Router'
import NineToTwelveCheckInRouter from 'checkIn/nineToTwelve/Router'
import UnauthenticatedRouter from 'UnauthenticatedRouter'
import { Toast } from 'components/Toast'

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  batch_requests: true,
  cookie_domain: window.location.host,
  ignore_dnt: true,
})

const App = (props) => (
  <MixpanelProvider mixpanel={mixpanel}>
    <MuiThemeProvider theme={theme}>
      <FlashMessage position={{ vertical: 'bottom', horizontal: 'left' }}>
        <CssBaseline />
        <Provider store={store}>
          <CroodsProvider
            baseUrl={process.env.REACT_APP_API_URL}
            renderLoading={() => <Loading />}
            urlParser={snakeCase}
            headers={authHeaders}
            handleResponseHeaders={saveHeaders}
          >
            <SchoolsProvider>
              <AudioProvider>
                <AuthProvider>
                  <CheckInProvider>
                    <ActivityProvider>
                      <Toast />
                      <PageTitle text="Closegap" />
                      <UnauthenticatedRouter {...props} />
                      <Router {...props} />
                      <CheckInRouter {...props} />
                      <DemoRouter {...props} />
                      <NineToTwelveCheckInRouter {...props} />

                      <ActivityRouter {...props} />
                    </ActivityProvider>
                  </CheckInProvider>
                </AuthProvider>
              </AudioProvider>
            </SchoolsProvider>
          </CroodsProvider>
        </Provider>
      </FlashMessage>
    </MuiThemeProvider>
  </MixpanelProvider>
)

export default App
