import React, { CSSProperties } from 'react'
import { getLocalStorageCheckIn } from 'students/helper'
import styled from 'styled-components'
import { lightenColor } from 'utils'

type Props = {
  children: React.ReactNode
  onClick: () => void
  variant?: 'contained' | 'outlined'
  style?: CSSProperties
  className?: string
}

const Button = styled.button<{ contained: boolean; color: string; dark: string }>`
  width: 240px;
  text-transform: capitalize;
  padding: 0.375rem 1.25rem;
  letter-spacing: 0.05em;
  font-size: 1rem;
  line-height: 1.5rem;
  border-radius: 9999px;
  white-space: nowrap;
  color: ${({ contained, color }) => (contained ? 'black' : color)};
  border-color: ${({ contained, color, dark }) => (contained ? color : dark)};
  border-width: 2px;
  font-weight: ${({ contained }) => (contained ? '400' : '700')};
  background-color: ${({ contained, color }) => (contained ? color : 'transparent')};
  :hover {
    background-color: ${({ contained, dark }) => (contained ? dark : 'transparent')};
    border-color: ${({ contained, color, dark }) => (contained ? dark : color)};
  }
`

export const SecondaryEmotionButton: React.FC<Props> = ({
  children,
  onClick,
  variant = 'outlined',
  className = '',
  style,
}: Props) => {
  const color = lightenColor(getLocalStorageCheckIn('color') || '#00ddc1', 10)
  const dark = lightenColor(color, -20)

  return (
    <Button
      type="button"
      onClick={onClick}
      contained={variant === 'contained'}
      color={color}
      dark={dark}
      style={style}
      className={className}
    >
      {children}
    </Button>
  )
}

export default SecondaryEmotionButton
