import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Image from 'components/ResponsiveImage'
import StudentTypography from 'components/StudentsTypography'

const styles = theme => ({
  container: {
    cursor: 'pointer',
    width: '40vw',
    height: '40vw',
    border: '3px double transparent',
    [theme.breakpoints.up('sm')]: {
      width: '150px',
      height: '150px',
    },
  },
  containerSelected: {
    border: '3px double white',
    borderRadius: '50%',
  },
  label: {
    color: theme.colors.white,
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 25px',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    [theme.breakpoints.only('xs')]: {
      fontSize: '12px',
    },
  },
})

const ComponentMoon = ({ option, classNameLabel }) => (
  <>
    <Image src="/images/teen/moon.png" alt={option} />
    <StudentTypography variant="body1" className={classNameLabel}>
      {option}
    </StudentTypography>
  </>
)

export default withStyles(styles)(
  ({
    classes,
    option,
    selected,
    hideUnselected,
    isConfirmationPage,
    handleMoon,
  }) => {
    if (isConfirmationPage)
      return (
        <div
          className={`${classes.container}`}
          style={{ display: hideUnselected && !selected ? 'none' : 'block' }}
        >
          <ComponentMoon option={option} classNameLabel={classes.label} />
        </div>
      )

    return (
      <button
        type="button"
        className={`${selected ? classes.containerSelected : ''} ${
          classes.container
        }`}
        onClick={() => handleMoon(option)}
        style={{ display: hideUnselected && !selected ? 'none' : 'block' }}
      >
        <ComponentMoon option={option} classNameLabel={classes.label} />
      </button>
    )
  }
)
