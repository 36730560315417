import * as React from 'react'
import { useCroods } from 'croods'
import CloseIcon from '@material-ui/icons/Close'

import StyledDialog from 'components/StyledDialog'
import StudentCard from 'Educators/ManageSchool/ManageStudents/StudentCard'
import DialogForm from 'Educators/ManageSchool/ManageStudents/DialogForm'
import { useMixpanel } from 'mixpanel'
import { useGlobal } from 'useGlobal'
import { pluralize } from 'utils'

type Props = {
  classroom: {
    id: number
    name: string
    role: 'admin' | 'viewer'
  }
}
const ButtonRemoveFromClassroom = ({ classroom }: Props) => {
  const [{ selectedStudents }] = useGlobal()
  const [{ list: students }, { fetch }] = useCroods({
    name: 'students',
    stateId: `class-${classroom.id}`,
    path: `/classrooms/${classroom.id}/students`,
  })
  const mixpanel = useMixpanel()
  const [open, setOpen] = React.useState(false)

  const disabled = !selectedStudents.length

  const onClick = () => {
    mixpanel.track('Remove from classroom link clicked')
    setOpen(true)
  }

  const onClose = () => {
    mixpanel.track('Remove from classroom Modal Closed')
    setOpen(false)
  }

  const onSubmit = (create: (t: Record<string, unknown>) => Promise<void>) => async () => {
    await create({ studentsIds: selectedStudents })
    mixpanel.track('Students removed', { 'Students Ids': selectedStudents })
    fetch({})()
  }

  const count = selectedStudents.length

  return (
    <>
      <button
        className="flex gap-2 p-2 py-1 uppercase border border-current rounded button"
        disabled={disabled}
        onClick={onClick}
      >
        Remove from classroom <CloseIcon className="!text-base" />
      </button>
      <StyledDialog
        open={open}
        onClose={onClose}
        scroll="body"
        title="Remove from classroom"
        subtitle="The student will be removed from this classroom but will remain a part of the school."
        description={`Are you sure you want to remove
        ${pluralize('this student', 'these students')(count)} from classroom ${classroom.name}?`}
      >
        <DialogForm
          method="PUT"
          name="removeFromClassroom"
          path={`/classrooms/${classroom.id}/remove_students`}
          buttonText="REMOVE"
          successText="Students removed successfully"
          disabled={disabled}
          onSubmit={onSubmit}
          onClose={onClose}
          propsFetchStudents={{
            name: 'classroomStudents',
            stateId: classroom.id.toString(),
            path: `/classrooms/${classroom.id}/students`,
          }}
        />
        {selectedStudents.map((id) => (
          <StudentCard key={id} student={students.find((student) => student.id === id)} />
        ))}
      </StyledDialog>
    </>
  )
}

export default ButtonRemoveFromClassroom
