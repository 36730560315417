import React from 'react'

import { navigate } from '@reach/router'
import { useFlash } from 'seasoned-flash'
import { Paper, Typography } from '@material-ui/core'

import { colors } from 'theme'
import Form from 'Educators/students/form/Form'
import { useCroods } from 'croods'
import { useSchools } from 'Educators/SchoolsContext'
import { snakeCase } from 'lodash'

export default (props) => {
  const { schoolId } = useSchools()
  const { success } = useFlash()

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'students',
    afterSuccess: () => {
      success('Created student successfully')
      navigate(schoolId ? '/manage-school?resourceType=students' : '/dashboard')
    },
    parseParams: (value) => (value !== 'educationPlan504' ? snakeCase(value) : 'education_plan504'),
  })

  return (
    <Paper style={{ maxWidth: '100%', padding: '20px' }}>
      <Typography variant="h6" style={{ color: colors.dimGray }}>
        Add an Individual
      </Typography>
      <Form
        {...props}
        onSave={save({
          customPath: schoolId ? `schools/${schoolId}/students` : '/students',
        })}
        submitting={saving}
        submitError={saveError}
        initialValues={{
          englishLanguageLearner: 'no',
          individualizedEducationProgram: 'no',
          educationPlan504: 'no',
          freeReducedPriceMeals: 'no',
        }}
      />
    </Paper>
  )
}
