import styled from 'styled-components'

export const Wrapper = styled.div`
  padding: 0 2.5rem 0 2.5rem;
  @media screen and (max-width: 700px) {
    padding: 0 0.5rem 0 0.5rem;
  }
`

export const ReminderCheckInContainer = styled.div`
  padding-top: 2.5rem;
  @media screen and (max-width: 700px) {
    padding-top: 0.5rem;
  }
`

export const StudentItemContainer = styled.div`
  display: flex;
  margin-top: 24px;
  padding-bottom: 8px;
  align-items: center;
  justify-content: space-between;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const NameWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`
export const CheckBoxWrapper = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
  @media screen and (max-width: 700px) {
    margin-right: 8px;
  }
`
export const ButtonWrapper = styled.div`
  margin-left: 8px;
  @media screen and (max-width: 700px) {
    width: 100%;
    margin-left: 0;
    margin-top: 1rem;
  }
`
