import React, { useState } from 'react'
import { useCroods } from 'croods'
import { error, success } from 'components/Toast'
import Loading from 'components/Loading'
import { EducatorInfo } from './EducatorInfo'
import { Modal } from '../components/Modal'
import { SchoolInfo } from '../components/SchoolInfo'
import { Button, SubTitle } from '../components/styled'

export const DisapproveSchoolModal = ({ open, setOpen, school }: any) => {
  const [emailText, setEmailText] = useState(
    `Hi ${school.educator.name.split(' ')[0]},\n` +
      `Thank you for submitting your request to register "${school.name}" school in our app.\n` +
      'However, we regret to inform you that your request has been disapproved.\n' +
      'Upon review of your application, we have found that ...\n' +
      '<_complete_here_>\n\n' +
      'However, please note that you are welcome to register with your official school email and submit your school registration request from that account.\n' +
      'We would be happy for you and your school to join us!\n' +
      'Thank you!\n',
  )

  const [{ saving: disapproving }, { save: disapprove }] = useCroods({
    name: 'disapproveSchool',
    customPath: `/admin/schools/disapprove`,
    afterSuccess: () => {
      success('School disapprove and removed Successfully!')
      setOpen(false)
    },
    afterFailure: ({ response }: any) => {
      error(response?.data?.message ?? '')
    },
  })

  function disapproveSchool(schoolId: number, userId: number) {
    disapprove()({ userId, schoolId, emailText })
  }

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <SchoolInfo school={school} />
      <br />
      <EducatorInfo school={school} />
      <br />

      <SubTitle>
        Send email to educator explaining the reason why the school is being disapproved
      </SubTitle>
      <textarea
        style={{
          padding: '0.5rem',
          margin: '0.5rem 0',
          color: '#f6f6f6',
          background: '#404040',
          borderRadius: '0.5rem',
          width: '100%',
        }}
        rows={10}
        name="emailText"
        value={emailText}
        onChange={(e) => setEmailText(e.target.value)}
      />
      <Button
        variant="delete"
        onClick={() => disapproveSchool(school.schoolId, school.educator.userId)}
      >
        {disapproving ? <Loading /> : 'DELETE SCHOOL AND SEND EMAIL'}
      </Button>
    </Modal>
  )
}
