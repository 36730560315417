import NON_READERS from './nonReaders'
import KIDS from './kids'
import TEENS from './teens'

const FLOWS = {
  'non-reader': NON_READERS,
  kid: KIDS,
  teen: TEENS,
} as const

export default FLOWS
