import { navigate } from '@reach/router'

const getQSData = (): Record<string, string> => {
  const url = new URL(window.location.href)
  return Object.fromEntries(url.searchParams)
}

const setQSData = (newData: Record<string, any>) => {
  const url = new URL(window.location.href)
  const currentQSData = getQSData()
  const updatedData = { ...currentQSData, ...newData }
  const updatedQS = new URLSearchParams(updatedData).toString()

  navigate(`${url.pathname}?${updatedQS}`)
}

export { getQSData, setQSData }
