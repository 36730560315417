import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import FLOWS from 'checkIn/flows'
import Teen from 'checkIn/steps/HowAreYouFeelingNow/Teen'
import Kid from 'checkIn/steps/HowAreYouFeelingNow/Kid'
import NonReader from 'checkIn/steps/HowAreYouFeelingNow/NonReader'

const SCREEN_NAME = 'howAreYouFeelingNow'

const HowAreYouFeelingNow: RouteComponent = () => {
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'checkIns',
    path: '/check_ins',
  })

  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()
  const userId = currentUser?.id

  if (student && userId) {
    switch (student.studentType) {
      case 'teen':
        return (
          <Teen
            flow={FLOWS.teen[SCREEN_NAME]}
            studentId={student.id}
            userId={userId}
            saving={saving}
            saveError={saveError}
            save={save}
          />
        )
      case 'kid':
        return (
          <Kid
            flow={FLOWS.kid[SCREEN_NAME]}
            studentId={student.id}
            userId={userId}
            saving={saving}
            saveError={saveError}
            save={save}
          />
        )
      case 'non-reader':
        return (
          <NonReader
            flow={FLOWS['non-reader'][SCREEN_NAME]}
            studentId={student.id}
            userId={userId}
            saving={saving}
            saveError={saveError}
            save={save}
          />
        )
    }
  }

  return null
}

export default HowAreYouFeelingNow
