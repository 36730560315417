import React, { ReactNode } from 'react'
import styled, { CSSProperties } from 'styled-components'

interface Props {
  value: 'red' | 'blue' | 'yellow' | 'green'
  children: ReactNode
  style?: CSSProperties
  onClick: (value: string) => void
}

const ButtonContainer = styled.button<{ height: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height};
  margin-bottom: 0.25rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.4);
  :hover {
    filter: brightness(0.8) contrast(1.2);
  }
`

export const BackgroundColor = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 0.25rem;
  padding: 1.25rem;
  background: #e9573f;
  border-color: #c63228;
  border-left-width: 8px;
`

export const DarkFilter = styled.div<{ filter: string }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ filter }) => filter};
  border-radius: 0.25rem;
  height: 100%;
  width: 100%;
`

const ENERGY = {
  red: { height: '15%', darkness: 'rgba(0, 0, 0, 0.8)' },
  blue: { height: '20%', darkness: 'rgba(0, 0, 0, 0.45)' },
  yellow: { height: '30%', darkness: 'none' },
  green: { height: '35%', darkness: 'rgba(255, 255, 255, 0.25)' },
} as const

export const EnergyButton = ({ value, onClick, children, style }: Props) => (
  <ButtonContainer height={ENERGY[value].height} onClick={() => onClick(value)} style={style}>
    <BackgroundColor />
    <DarkFilter filter={ENERGY[value].darkness}>{children}</DarkFilter>
  </ButtonContainer>
)
