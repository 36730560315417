import compact from 'lodash/compact'
import get from 'lodash/get'
import map from 'lodash/map'
import parseInt from 'lodash/parseInt'
import reduce from 'lodash/reduce'
import moment from 'moment'

export const checkboxType = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  INDETERMINATE: 'indeterminate',
}

const checkIndeterminate = (date) => {
  const now = moment()
  const checkDate = moment(date).local()
  const hours = now.diff(checkDate, 'hours')
  return hours >= 0 && hours < 3
}

export const setAllCheckboxTo = (students, to) =>
  reduce(
    students,
    (result, item) => {
      const isIndeterminate =
        get(item, 'checkInReminderSentAt') &&
        checkIndeterminate(item.checkInReminderSentAt)
      return {
        ...result,
        [item.id]: isIndeterminate ? checkboxType.INDETERMINATE : to,
      }
    },
    {},
  )

export const filterCheckboxBy = (items, by) =>
  compact(map(items, (item, key) => item === by && parseInt(key)))
