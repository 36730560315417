import React from 'react'
import { METABASE } from '../constants'
import { Title } from './styled'
import { A } from './A'
import { Modal } from './Modal'

interface Props {
  open: boolean
  onClose: () => void
}

export const MetabaseQueries = ({ open, onClose }: Props) => {
  const links = [
    { name: 'Search Schools by City and Country', path: '265' },
    { name: 'Search Schools by Name and State', path: '231' },
    { name: 'Search Schools by Name', path: '213' },
    { name: 'Search Schools by State and Postal code', path: '227' },
    { name: 'Duplicated Schools (by name, city, state, country_code)', path: '223' },
    { name: 'Duplicated Schools (by State and Postalcode)', path: '228' },
  ]

  return (
    <Modal open={open} onClose={onClose}>
      <Title>Useful Metabase Queries</Title>
      {links.map((l) => (
        <p key={l.path}>
          <A href={`${METABASE}/${l.path}`} name={`- ${l.name}`} />
        </p>
      ))}
    </Modal>
  )
}
