import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from 'checkIn/steps/UncomfortableResult/Teen'
import Kid from 'checkIn/steps/UncomfortableResult/Kid'
import NonReader from 'checkIn/steps/UncomfortableResult/NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { checkInTrack } from 'students/helper'
import { getQSData } from 'QueryStringController'
import { useMixpanel } from 'mixpanel'

const SCREEN_NAME = 'uncomfortableResult'

const UncomfortableResult: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()

  const { energyLevel } = getQSData()

  const [label, bgImage, audioKey] = ['green', 'yellow'].includes(energyLevel)
    ? ([
        'Thanks for sharing how you are doing with me!',
        '/images/neutral_cadet.png',
        'nonComfyButWithEnergy',
      ] as const)
    : ([
        'I’m so sorry to hear that.',
        '/images/angsty_cadet.png',
        'nonComfy',
      ] as const)

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, 'Check-in: Fuel response', student)
    }
  }, [student]) // eslint-disable-line

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    switch (student.studentType) {
      case 'teen':
        return (
          <Teen nextFunction={nextFunction} label={label} bgImage={bgImage} />
        )
      case 'kid':
        return (
          <Kid nextFunction={nextFunction} label={label} bgImage={bgImage} />
        )
      case 'non-reader':
        return (
          <NonReader
            nextFunction={nextFunction}
            label={label}
            audioKey={audioKey}
            bgImage={bgImage}
          />
        )
    }
  }

  return null
}

export default UncomfortableResult
