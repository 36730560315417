import React, { ChangeEvent, useState } from 'react'
import { ButtonNext } from 'checkIn/components/teen/ButtonNext'
import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'
import { Title } from 'checkIn/components/teen/Title'
import { Paragraph } from 'checkIn/components/teen/Paragraph'
import { makeStyles, TextField } from '@material-ui/core'
import { setLocalStorageCheckIn } from 'students/helper'
import styled from 'styled-components'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const useStyles = makeStyles(() => ({
  cssUnderline: {
    color: 'white',
    borderBottom: '1px solid white',
  },
  cssFocused: {
    '&:after': {
      borderBottomColor: 'white',
    },
  },
  cssLabel: {
    left: 'initial',
    color: 'white',
    '&$cssFocused': {
      color: 'white',
    },
  },
}))

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 2rem;
`

const CharacterCounter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  left: initial;
  color: white;
`

export const TeenStart = ({ nextFunction }: Props) => {
  const characterLimit = 100
  const [goal, setGoal] = useState<string>('')
  const classes = useStyles()

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    e.target.value.toString().length <= characterLimit ? setGoal(e.target.value) : null

  const onClickButton = () => {
    setLocalStorageCheckIn('goal', goal)
    nextFunction(window.location.search)
  }

  return (
    <CheckInContainer bottom bg="/images/teen/cadet_with_note_on_moon.png">
      <Title>Do you have a goal for the day?</Title>
      <Content>
        <Paragraph style={{ textAlign: 'left', margin: '2rem 0' }}>
          In your own words, write down what you’d like to work on today. No one else will see this.
          It’s just for you!
        </Paragraph>
        <TextField
          autoFocus
          id="text-here"
          label="Text here"
          multiline
          rowsMax="5"
          value={goal}
          onChange={onChange}
          className="w-full"
          InputLabelProps={{ classes: { root: classes.cssLabel, focused: classes.cssFocused } }}
          InputProps={{
            classes: { focused: classes.cssFocused, underline: classes.cssUnderline },
            style: { fontSize: '1.1rem', lineHeight: '160%' },
          }}
        />
        <CharacterCounter>
          {String(goal).length} / {characterLimit}
        </CharacterCounter>
      </Content>

      <ButtonNext onClick={() => onClickButton()}>I’m done!</ButtonNext>
    </CheckInContainer>
  )
}
