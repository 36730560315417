import React, { useState } from 'react'
import { useCroods } from 'croods'
import { error, success } from 'components/Toast'
import Loading from 'components/Loading'
import { AdminScreen } from './components/AdminScreen'
import { TextInput } from './components/TextInput'
import { usaStates } from 'components/SchoolForm/usaStates'
import { Select } from './components/Select'
import { Button, Card2, P } from './components/styled'

export const CreateDistrict = (props: any) => {
  const [name, setName] = useState<string>('')
  const [state, setState] = useState<string>('')

  const [{ saving }, { save }] = useCroods({
    name: 'admin-create-district',
    customPath: 'admin/create_district',
    afterSuccess: () => success('District created successfully'),
    afterFailure: ({ response }) => error(response?.data.message),
  })

  return (
    <AdminScreen title="Create district" {...props}>
      <Card2 style={{ alignSelf: 'center', width: '100%', maxWidth: '600px' }}>
        <P>1. Write the district name in the field below:</P>
        <TextInput label="District name *" value={name} onChange={(e) => setName(e.target.value)} />
        <br />

        <P>2. Select the district state:</P>
        <Select
          options={usaStates}
          defaultValue={state}
          value={state}
          onChange={(e) => setState(e.target.value)}
        />
        <br />

        <P>3. Click on the button below to create the new district:</P>
        <Button
          variant="add"
          disabled={name === '' || state === ''}
          onClick={() => save()({ name, state })}
        >
          CREATE DISTRICT
        </Button>

        {saving ? <Loading /> : null}
      </Card2>
    </AdminScreen>
  )
}
