import React from 'react'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { Typography, createSvgIcon } from '@material-ui/core'
import { Fetch } from 'croods'
import { find, map } from 'lodash'
import { useSchools } from 'Educators/SchoolsContext'
import { Link, navigate } from '@reach/router'
import { Menu } from '@headlessui/react'

const SchoolIcon = createSvgIcon(
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="location_city">
      <path
        id="Vector"
        d="M15.6 12.4093V6.4093L12.6 3.4093L9.60001 6.4093V8.4093H3.60001V22.4093H21.6V12.4093H15.6ZM7.60001 20.4093H5.60001V18.4093H7.60001V20.4093ZM7.60001 16.4093H5.60001V14.4093H7.60001V16.4093ZM7.60001 12.4093H5.60001V10.4093H7.60001V12.4093ZM13.6 20.4093H11.6V18.4093H13.6V20.4093ZM13.6 16.4093H11.6V14.4093H13.6V16.4093ZM13.6 12.4093H11.6V10.4093H13.6V12.4093ZM13.6 8.4093H11.6V6.4093H13.6V8.4093ZM19.6 20.4093H17.6V18.4093H19.6V20.4093ZM19.6 16.4093H17.6V14.4093H19.6V16.4093Z"
        fill="#F3F2F8"
      />
    </g>
  </svg>,
  'SchoolIcon',
)

const getSchoolName = (id: number | string | undefined, schools: any) => {
  if (id === 'all') return 'All Premium Schools'
  const name = schools.find((item: any) => item.school.id === id)?.school.name
  return name || 'Select School'
}

type Props = {
  disabled?: boolean | undefined
  hidden?: boolean | undefined
  shoAllPremiumSchools?: boolean | undefined
  redirectOnChange?: boolean | undefined
  navigateOnChange?: boolean | undefined
  navigateOnChangeFn?: ((school: any) => string) | undefined
  schoolIdFromURL?: string | undefined
}

const MenuSchool = ({
  disabled = false,
  hidden = false,
  redirectOnChange = false,
  shoAllPremiumSchools = false,
  navigateOnChange = false,
  schoolIdFromURL,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigateOnChangeFn = (_schoolId: number) => {
    return '/dashboard'
  },
}: Props) => {
  const { schoolId, handleSchool, showAllPremiumSchools, getSchoolIdOfLocalStorage, setSchools } =
    useSchools()
  const [{ currentUser }] = useCurrentUser()

  const handleChange = (_: any, { props: { school } }: { props: { school: { school: any } } }) => {
    handleSchool(school)
    console.log('handleSchool(school)', school)
    if (!school.school.reviewed || school.school.rejected) {
      return navigate('/v2/schools/' + school.school.id + '/home')
    }
    if (redirectOnChange) {
      return navigate('/dashboard')
    }
    if (navigateOnChange) {
      return navigate(navigateOnChangeFn(school.school.id))
    }
  }

  const itemAllPremiumSchools = {
    id: 0,
    role: '',
    school: {
      id: 'all',
      name: 'All Premium Schools',
      premium: true,
      rejected: false,
      demo: false,
      reviewed: true,
    },
  }

  return (
    <Fetch
      name="educatorSchools"
      renderLoading={() => null}
      renderError={() => null}
      render={(schools) => {
        const path = window.location.pathname
        if (
          path === '/search-school' ||
          path === '/confirm-school' ||
          path === '/register-school' ||
          path === '/add-school-info'
        )
          return null

        if (hidden) return null

        if (!currentUser?.firstSchoolAccessPending && !schoolId && schools?.length === 0)
          return (
            <Typography className="text-gray-100 text-2xl font-bold">
              School registration in process
            </Typography>
          )

        if (currentUser?.firstSchoolAccessPending && !schoolId)
          return (
            <Typography className="text-gray-100 text-2xl font-bold">
              Still waiting to hear back from your school
            </Typography>
          )

        if (!(schools?.length > 0)) return null

        return (
          <div className="flex items-center max-w-[60vw]">
            <span className="text-white px-4 hidden sm:block">•</span>
            <div className="pl-4 sm:pl-0 relative">
              <Menu>
                <Menu.Button
                  className="text-gray-100 text-2xl font-bold flex items-center gap-2 "
                  disabled={disabled}
                >
                  <SchoolIcon />{' '}
                  <span className="overflow-hidden text-ellipsis text-nowrap max-w-[50vw]">
                    {getSchoolName(schoolId, schools)}
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-5 h-5 rotate-180"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.47 6.47a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 1 1-1.06 1.06L10 8.06l-3.72 3.72a.75.75 0 0 1-1.06-1.06l4.25-4.25Z"
                      clipRule="evenodd"
                    />
                  </svg>
                </Menu.Button>
                <Menu.Items className="absolute bg-white top-full left-0 flex flex-col w-auto z-10 rounded-lg shadow divide-y min-w-full">
                  {(showAllPremiumSchools || shoAllPremiumSchools) && (
                    <Menu.Item key={itemAllPremiumSchools.school.id}>
                      <button
                        key={itemAllPremiumSchools.school.id}
                        onClick={(e) =>
                          handleChange(e, { props: { school: itemAllPremiumSchools } })
                        }
                        className="text-zinc-600 text-lg font-normal py-2 px-4  whitespace-nowrap text-left"
                        // school={itemAllPremiumSchools}
                        // component="li"
                      >
                        <b>{itemAllPremiumSchools.school.name}</b>
                      </button>
                    </Menu.Item>
                  )}
                  {map(schools, (item: any) => (
                    <Menu.Item key={item.school.id}>
                      <button
                        key={item.school.id}
                        onClick={(e) => handleChange(e, { props: { school: item } })}
                        className="text-zinc-600 text-lg font-normal py-2 px-4 whitespace-nowra text-left"
                      >
                        {item.school.name}
                      </button>
                    </Menu.Item>
                  ))}

                  <Menu.Item key="add-another-school">
                    <Link
                      className="py-2 px-4 text-lg font-normal text-[#6C0FE6] whitespace-nowrap"
                      to="/search-school"
                    >
                      Add another school
                    </Link>
                  </Menu.Item>
                </Menu.Items>
              </Menu>
            </div>
          </div>
        )
      }}
      afterSuccess={({ data: schools }) => {
        setSchools(schools)
        const localStorageSchoolId = getSchoolIdOfLocalStorage()
        const activeSchoolId = schoolIdFromURL || schoolId

        const defaultSchool: any | undefined =
          find(schools, ['school.id', localStorageSchoolId]) || schools[0]

        if (!activeSchoolId) {
          handleSchool(defaultSchool)
          return
        }

        if (shoAllPremiumSchools !== true && activeSchoolId === 'all') {
          handleSchool(defaultSchool)
          navigate(navigateOnChangeFn(defaultSchool.school.id))
          return
        }

        if (shoAllPremiumSchools === true && activeSchoolId === 'all') {
          handleSchool(itemAllPremiumSchools)
          return
        }

        const educatorSchool = find(schools, (schl) => {
          // eslint-disable-next-line eqeqeq
          return schl.school.id == activeSchoolId
        })

        handleSchool(educatorSchool || defaultSchool)
      }}
    />
  )
}

export default MenuSchool
