import React from 'react'
import groupBy from 'lodash/groupBy'
import moment from 'moment'
import { Fetch } from 'croods'
import { withStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Hidden from '@material-ui/core/Hidden'
import Typography from '@material-ui/core/Typography'
import Chart from 'components/chart'
import EmotionsChart from 'components/EmotionsChart'
import { getCalendarTime } from 'components/chart/transform'
import { isEducator, isStudent, isPremium, isSchoolPremium } from 'students/helper'
import FollowUpChart from 'components/FollowUpChart'
import { useSchools } from 'Educators/SchoolsContext'
import CardHistory from './CardHistory'
import CardHistoryV2 from './CardHistoryV2'
import MenuFilter from './FilterMenu'
import { getQSData } from 'QueryStringController'
import { featureEnabled } from 'featureFlags'

const styles = (theme) => ({
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  button: {
    color: theme.palette.grey[700],
  },
  activeButton: {
    color: theme.palette.primary.main,
  },
})

export const getTitle = (day) => getCalendarTime(day, 'fullTime')

const checkIsPremium = (currentUser, student, school) => {
  const { role } = currentUser
  if (isStudent(role)) return isPremium(currentUser)

  if (isEducator(role)) return isSchoolPremium(school) && student.schoolId === school.id

  return isPremium(currentUser) && student.schoolId === school.id
}

const buttons = [
  { label: 'Overall', value: null },
  { label: 'Today', value: 'today' },
  { label: 'This Week', value: 'week' },
  { label: 'This Month', value: 'month' },
  { label: '3 Months', value: '3-months' },
  { label: '6 Months', value: '6-months' },
  { label: '12 Months', value: '12-months' },
]

const filter = (list, byTime, query) => {
  const itemsFiltered = list.filter((item) => {
    if (byTime) {
      const { from, to } = query
      return moment(item.createdAt).isBetween(from, to)
    }

    return true
  })

  const checkInsFiltered = itemsFiltered.filter((item) => {
    return item.type !== 'ActivitySession'
  })

  return [
    checkInsFiltered,
    groupBy(checkInsFiltered, (item) => moment(item.createdAt).format('YYYY-MM-DD')),
    groupBy(itemsFiltered, (item) => moment(item.createdAt).format('YYYY-MM-DD')),
  ]
}

const CheckinHistory = ({ query, timeFilter, handleTimeFilter, ...props }) => {
  const { school } = useSchools()
  const checkInHistoryRef = React.useRef(null)
  const { hasUnseenMessage = 'false' } = getQSData()
  const [dataList, checkinsByDate, extendedCheckinsByDate] = filter(props.items, timeFilter, query)

  React.useEffect(() => {
    if (hasUnseenMessage?.toLowerCase() === 'true') checkInHistoryRef.current.scrollIntoView()
  }, [hasUnseenMessage])

  const renderButton = ({ label, value }) => {
    const { button, activeButton } = props.classes
    const btnClass = value === timeFilter ? activeButton : button
    return (
      <Button
        key={value}
        fullWidth
        aria-label={label}
        size="small"
        className={btnClass}
        onClick={() => {
          handleTimeFilter(value)
        }}
      >
        {label}
      </Button>
    )
  }

  return (
    <>
      <Hidden smUp>
        <MenuFilter buttons={buttons} selected={timeFilter} onChange={handleTimeFilter} />
      </Hidden>
      <Hidden xsDown>
        <div className={props.classes.buttonGroup}>{buttons.map((item) => renderButton(item))}</div>
      </Hidden>
      {!!dataList.length && (
        <>
          <Chart timeFilter={timeFilter} data={dataList} />
          {checkIsPremium(props.currentUser, props.student, school) && (
            <Fetch
              stateId={props.studentId}
              name="emotionsChart"
              path={`/students/${props.studentId}/emotions_chart`}
              query={query}
              render={(items) => <EmotionsChart data={items} />}
              renderLoading={() => null}
            />
          )}
          {checkIsPremium(props.currentUser, props.student, school) && (
            <Fetch
              stateId={props.studentId}
              name="followUpChart"
              path={`/students/${props.studentId}/questions_chart`}
              query={query}
              render={(items) => <FollowUpChart data={items} />}
              renderLoading={() => null}
            />
          )}
        </>
      )}

      <div id="check-in-history" ref={checkInHistoryRef}>
        { featureEnabled(props.currentUser, 'standalone_activities') ? (
          Object.keys(extendedCheckinsByDate).map((key, index) => (
          <CardHistoryV2
            key={key}
            index={index}
            student={props.student}
            title={getTitle(key)}
            items={extendedCheckinsByDate[key]}
            currentUser={props.currentUser}
          />
        ))) : (
          Object.keys(checkinsByDate).map((key, index) => (
          <CardHistory
            key={key}
            index={index}
            student={props.student}
            title={getTitle(key)}
            items={checkinsByDate[key]}
            currentUser={props.currentUser}
          />
        )))}
      </div>

      {!Object.keys(checkinsByDate).length && (
        <Typography align="center" variant="h5" color="textSecondary">
          No check-ins yet
        </Typography>
      )}
    </>
  )
}

const EditTabHistory = withStyles(styles)((props) => (
  <Fetch
    stateId={props.studentId}
    name="checkIns"
    path={`/students/${props.studentId}/check_ins`}
    render={(items) => <CheckinHistory {...props} items={items || []} />}
    renderLoading={() => (
      <div style={{ margin: 50, textAlign: 'center' }}>
        <CircularProgress size="60px" color="primary" />
      </div>
    )}
  />
))

export default EditTabHistory
