import React from 'react'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import downloadFile from 'downloadFile'
import clsx from 'clsx'
// @ts-ignore
import { useFlash } from 'seasoned-flash'

type Props = {
  url: string
  className: string
  disabled?: boolean
}

const ButtonCSVExport = ({ url, disabled, className }: Props) => {
  const { error } = useFlash()
  const linkRef = React.useRef<HTMLAnchorElement>(null)
  const [uri, setUri] = React.useState('#')

  const handleCSVDownload = async (event: React.MouseEvent) => {
    if (disabled) {
      event.preventDefault()
      return
    }
    if (uri !== (event.target as HTMLAnchorElement).href) {
      event.preventDefault()
      try {
        const blob = await downloadFile(url, 'Error on an export CSV file')
        const blobURL = URL.createObjectURL(blob)
        setUri(blobURL)
      } catch (err) {
        error('Error on an export CSV file')
      }
    }
  }

  React.useEffect(() => {
    if (linkRef.current && uri !== '#') {
      linkRef.current.click()
    }
  }, [uri])

  React.useEffect(
    () => () => {
      URL.revokeObjectURL(uri)
    },
    [], // eslint-disable-line
  )

  React.useEffect(() => {
    setUri('#')
  }, [url]) // eslint-disable-line

  return (
    <a
      className={clsx(
        className,
        disabled &&
          'text-gray-400 bg-gray-200 cursor-default hover:bg-gray-200',
      )}
      ref={linkRef}
      download="checkins.csv"
      onClick={handleCSVDownload}
      href={uri}
    >
      Export Check-ins{' '}
      <SaveAltIcon fontSize="small" style={{ marginLeft: 8 }} />
    </a>
  )
}

export default ButtonCSVExport
