import React from 'react'

import Screen from 'checkIn/components/kid/Screen'
import planetList from 'checkIn/constants/threeToFour/planetList'
import Image from 'components/ResponsiveImage'

type Props = {
  selectEmotionFunction: (params: string) => void
}

const ThreeToFour = ({ selectEmotionFunction }: Props) => {
  return (
    <Screen label="Choose 1 Planet:" title="How are you feeling now?">
      <div className="flex flex-col w-screen md:mt-10 text-center">
        <div className="container grid grid-cols-2 gap-1 sm:grid-cols-3 md:grid-cols-6">
          {planetList.map(({ feeling, src, label }) => (
            <div key={feeling} className="mt-4 md:mt-10">
              <button
                type="button"
                onClick={() => {
                  selectEmotionFunction(feeling)
                }}
              >
                <Image src={src} alt={''} className="max-w-[132px] mx-auto block" />
                <p className="text-xl text-center tracking-wider font-medium text-[#FFF5F5]">
                  {label}
                </p>
              </button>
            </div>
          ))}
        </div>
      </div>
    </Screen>
  )
}

export default ThreeToFour
