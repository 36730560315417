import React from 'react'
import Select from 'components/form/Select'

const NonBoldSelect = ({ input, meta, options, ...props }) => (
  <Select
    input={input}
    meta={meta}
    options={options}
    listItemProps={{
      disableTypography: true,
    }}
    {...props}
  />
)
export default NonBoldSelect
