import React from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import CircularElements, {
  CircularItem,
} from 'checkIn/components/CircularElements'
import ImagePlanet from 'checkIn/components/teen/ImagePlanet'
import planetList from 'checkIn/constants/kid/planetList'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'
import Screen from 'checkIn/components/kid/Screen'
import { capitalize, lowerCase } from 'lodash'

type Props = {
  width: Breakpoint
  feeling: typeof planetList[number]['feeling']
  nextFunction: (params: string) => void
}

const Kid = ({ width, feeling, nextFunction }: Props) => {
  const elementsOptions = {
    startDeg: 135,
    endDeg: 450,
    distance: '120px',
  }
  if (isWidthUp('md', width)) {
    elementsOptions.startDeg = 0
    elementsOptions.endDeg = 360
    elementsOptions.distance = '180px'
  }

  return (
    <Screen
      label={`You picked Planet ${capitalize(lowerCase(feeling))}.`}
      title={`Are you feeling ${capitalize(lowerCase(feeling))}?`}
    >
      <CircularElements
        {...elementsOptions}
        style={{
          width: '100vw',
          height: '60vh',
        }}
      >
        {planetList.map((planet, idx) => {
          const selected = planet.feeling === feeling
          return (
            <CircularItem
              key={idx}
              selected={selected}
              style={{ opacity: selected ? 1 : 0.5 }}
            >
              <ImagePlanet
                src={planet.src}
                alt={''}
                label={planet.label}
                selected={selected}
              />
            </CircularItem>
          )
        })}
      </CircularElements>

      <BackAndNextButtons
        showBackButton
        backButtonText="No."
        nextButtonText="Yes!"
        nextButtonOnClick={() => nextFunction(window.location.search)}
      />
    </Screen>
  )
}

export default withWidth()(Kid)
