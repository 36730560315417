import React from 'react'
import FLOWS from 'checkIn/flows'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import Teen from './Teen'
import FiveToEight from './FiveToEight'
import { featureEnabled } from 'featureFlags'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { getQSData } from 'QueryStringController'
import { navigate } from '@reach/router'

const SCREEN_NAME = 'selectColor'

const SelectColor: RouteComponent = () => {
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  if (!student) return null

  if (featureEnabled(currentUser, 'new_teen_check_in'))
    return (
      <FiveToEight
        nextFunction={(params: string) => {
          const { impactMeasurement } = getQSData()
          impactMeasurement === 'true'
            ? navigate(`./impactQ1${params}`)
            : navigate('./choose-emotion')
        }}
      />
    )

  if (student.studentType === 'teen')
    return <Teen nextFunction={FLOWS.teen[SCREEN_NAME].nextFunction} />

  return null
}

export default SelectColor
