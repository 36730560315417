import React from 'react'

import { Paper, Typography } from '@material-ui/core'

import { colors } from 'theme'
import { useCroods } from 'croods'
import { useSchools } from 'Educators/SchoolsContext'

import values from 'lodash/values'
import compact from 'lodash/compact'

import Form from 'Educators/educators/import/Form'
import Result from 'Educators/educators/import/Result'

const STEPS = {
  INVITE: 'INVITE',
  RESULT: 'RESULT',
}

export default () => {
  const { schoolId } = useSchools()

  const [step, setStep] = React.useState(STEPS.INVITE)
  const isInvite = step === STEPS.INVITE

  const [, { fetch: fetchEducators }] = useCroods({
    stateId: schoolId,
    name: 'schoolEducators',
    customPath: `/schools/${schoolId}/educators`,
  })

  const [{ info, saving, saveError }, { save, resetState }] = useCroods({
    name: 'inviteEducators',
    customPath: `/schools/${schoolId}/educators/invite`,
    afterSuccess: () => {
      fetchEducators()()
      setStep(STEPS.RESULT)
    },
  })

  const handleClose = () => {
    resetState()
    setStep(STEPS.INVITE)
  }

  return (
    <Paper style={{ maxWidth: '100%', padding: '20px' }}>
      <Typography variant="h6" style={{ color: colors.dimGray }}>
        Add an Individual
      </Typography>
      <Typography style={{ marginTop: 16, marginBottom: 16 }}>Educator Information</Typography>
      {isInvite ? (
        <Form
          onSubmit={(formValues) => save()(formValues)}
          submitting={saving}
          submitError={saveError}
        />
      ) : (
        <Result invites={compact(values(info || {}))} onClose={handleClose} />
      )}
    </Paper>
  )
}
