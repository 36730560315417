import React, { useMemo } from 'react'
import isEmpty from 'lodash/isEmpty'
import { spreadTriageGroup } from 'Educators/triageGroups'
import { TriageGroupPanel } from './TriageGroupPanel'

const PanelGroup = ({ group, currentUser, fetchListStudents }) => {
  const groups = useMemo(() => spreadTriageGroup(group), [group])

  if (isEmpty(group)) return null

  const isUrgentEmpty = isEmpty(groups['red'])
  const isCouldBenefitEmpty = isEmpty(groups['yellow'])
  const isGoodToGoEmpty = isEmpty(groups['green'])

  return (
    <>
      {isUrgentEmpty ? null : (
        <TriageGroupPanel
          title="URGENT"
          color="red"
          currentUser={currentUser}
          studentsCheckIns={groups['red']}
          fetchListStudents={fetchListStudents}
        />
      )}

      {isCouldBenefitEmpty ? null : (
        <TriageGroupPanel
          title="COULD BENEFIT FROM SUPPORT"
          color="yellow"
          currentUser={currentUser}
          studentsCheckIns={groups['yellow']}
          fetchListStudents={fetchListStudents}
          isUrgentEmpty={isUrgentEmpty}
        />
      )}

      {isGoodToGoEmpty ? null : (
        <TriageGroupPanel
          title="GOOD TO GO"
          color="green"
          currentUser={currentUser}
          studentsCheckIns={groups['green']}
          fetchListStudents={fetchListStudents}
        />
      )}
    </>
  )
}

export default PanelGroup
