import React, { useState } from 'react'
// @ts-ignore
import { useFlash } from 'seasoned-flash'
import { useCroods } from 'croods'

import ClearIcon from '@material-ui/icons/Clear'
import StyledDialog from 'components/StyledDialog'
import { useMixpanel } from 'mixpanel'
import { pluralize } from 'utils'
import { nameConcatenation } from 'Educators/students/helper'

import type { Adult, Student, User } from 'types'
import SubmitButton from 'components/submit-button'
import { roles } from 'roles'

type Props = {
  student: Student
  currentUser: User
  adults?: Adult[]
}
const ButtonUnshare = ({ student, adults }: Props) => {
  const mixpanel = useMixpanel()
  const [open, setOpen] = useState(false)

  const { success } = useFlash()

  const sharedWith = adults ?? student.adultsOnAccount?.sharedWith ?? []
  const count = sharedWith.length

  const [, { setInfo }] = useCroods({ name: 'students' })
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'studentsUnshare',
    path: '/shared_students',
  })

  const handleClick = () => {
    mixpanel.track('Unshare link clicked')
    setOpen(true)
  }

  const handleClose = () => {
    mixpanel.track('Unshare Student Modal Closed')
    setOpen(false)
  }

  async function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault()

    const studentsIds = [student.id]
    const adultIds = sharedWith.map(({ id }) => id)
    const destroy = save({ method: 'DELETE' as any })
    if (await destroy({ studentsIds, adultIds })) {
      const sharedWith =
        student.adultsOnAccount?.sharedWith?.filter(({ id }) => !adultIds.includes(id)) ?? []

      const adultsOnAccount = { ...student.adultsOnAccount, sharedWith }
      setInfo({ adultsOnAccount }, true)

      success('Unshared successfully')
      handleClose()
    }
    mixpanel.track('', { 'Students Ids': studentsIds })
  }

  if (!count || !student.abilities?.update) return null

  return (
    <>
      <button
        type="button"
        className="inline-flex text-white rounded bg-primary"
        onClick={handleClick}
      >
        <span className="sr-only">Unshare</span>
        <ClearIcon className="!w-4 !h-4" aria-hidden="true" />
      </button>
      <StyledDialog
        open={open}
        onClose={handleClose}
        scroll="body"
        title="Unshare Student"
        subtitle="When you unshare a student with someone,
        they will no longer see the student on their dashboard
        or have access to any of the student’s data."
        description={`Are you sure you want to unshare this student with ${pluralize(
          `this person`,
          `these ${count} people`,
        )(count)}?`}
      >
        <form onSubmit={handleSubmit} className="flex flex-col gap-2 text-left">
          <span className="mt-4 text-base text-center text-gray-800">
            {nameConcatenation(
              sharedWith?.map((contact) => {
                const role = contact.role ? roles[contact.role] : null
                return `${contact.displayName}${role ? ` (${role})` : ''}`
              }),
            )}
          </span>
          {saveError && (
            <span className="block px-4 mt-5 text-sm text-center text-red-600">{saveError}</span>
          )}
          <SubmitButton submitting={saving} className="self-center mt-5">
            UNSHARE
          </SubmitButton>
        </form>
      </StyledDialog>
    </>
  )
}

export default ButtonUnshare
