import styled from 'styled-components'
// export const colors = {
//   background: '#121212',
//   menuBar: '#181818',
//   topGradient: '#404040',
//   bottomGradient: '#282828',
//   primaryText: '#f6f6f6',
//   secondaryText: '#b3b3b3',
// }

export const Container = styled.div`
  display: flex;
  background: #121212;
  min-height: 100vh;
`
export const Header = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  color: #b3b3b3;
  line-height: 160%;
  margin-bottom: 1rem;
`
export const Title = styled.h2`
  font-size: 1.5rem;
  color: #b3b3b3;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  line-height: 160%;
  margin-bottom: 0.5rem;
`
export const SubTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: #b3b3b3;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  line-height: 160%;
`
export const P = styled.p`
  font-size: 0.9rem;
  max-width: 700px;
  color: #b3b3b3;
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
  line-height: 160%;
  margin-bottom: 0.4rem;
`
export const Card = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 3fr;
  background: rgba(0, 0, 0, 0.5);
  gap: 1rem;
  margin: 1rem;
  padding: 1rem;
  border-radius: 0.5rem;
`
export const Card2 = styled.div`
  background: rgba(0, 0, 0, 0.5);
  border-radius: 0.4rem;
  margin: 1rem 0;
  padding: 1rem;
`

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #181818;
  padding: 1rem;
  overflow-y: auto;
  overflow-x: hidden;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  min-width: 600px;
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 1rem;
  line-height: 160%;
`
export const Button = styled.button<{
  variant?: 'delete' | 'add' | 'edit' | 'selected'
}>`
  font-size: 1rem;
  margin: 0.5rem 0;
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  color: #f6f6f6;
  background-color: ${({ variant }) => {
    if (variant === 'selected') return '#404040'
    if (variant === 'delete') return '#b00013'
    if (variant === 'add') return '#32ad45'
    if (variant === 'edit') return '#0052b3'
    return '#282828'
  }};
  width: 100%;
  max-width: 3000px;
  :disabled {
    opacity: 0.2;
  }
  :hover {
    opacity: ${({ disabled }) => (disabled ? '0.2' : '0.7')};
  }
`

export const InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin: 0.5rem 0;
  border-radius: 8px;
  :hover {
    box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 0.5);
  }
`

export const StyledInput = styled.input<{ invalid?: boolean }>`
  display: flex;
  height: 50px;
  width: 100%;
  background: #fefefe;
  border: none;
  box-shadow: ${({ invalid }) =>
    invalid ? '0px 0px 5px 1px #da001a' : '0px 0px 5px 1px rgba(37, 0, 50, 0.25)'};
  border-radius: 8px;
  padding: 0 1rem;
  border-radius: 8px;
  color: ${({ disabled }) => (disabled ? '#9a9a9a' : '#353535')};
  outline-color: transparent;
  :focus + label {
    font-size: 0.7rem;
    margin-top: -2rem;
  }
  :focus {
    box-shadow: ${({ invalid }) =>
      invalid ? '0px 0px 5px 1px #da001a' : '0px 0px 5px 1px rgba(37, 0, 50, 1)'};
  }
`
export const Label = styled.label<{ invalid?: boolean }>`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 1rem;
  font-size: 0.7rem;
  margin-top: -2rem;
  color: ${({ invalid }) => (invalid ? '#da001a' : '#9a9a9a')};
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
`
export const ItemCard = styled.div<{ variant?: 'selected' | 'none' }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: #f6f6f6;
  background-color: ${({ variant }) => {
    if (variant === 'selected') return '#181818'
    return '#282828'
  }};
  max-width: 100%;
`
export const SchoolCard = styled.div`
  max-width: 100%;
  margin: 0.5rem 0;
  gap: 1rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  color: #f6f6f6;
  background-color: #282828;
  width: 100%;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  max-width: 100%;
`
export const Row = styled.div`
  display: flex;
  gap: 1rem;
  max-width: 100%;
`
