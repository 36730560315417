import React from 'react'
import { Router } from '@reach/router'
import SelectColor from './SelectColor'

import { RouteComponent } from 'types'
import Emotion from './Emotion'
import EmotionIntensity from './EmotionIntensity'
import SecondaryEmotions from './SecondaryEmotions'
import BodySensations from './BodySensations'
import EnergyLevel from './EnergyLevel'
import Questions from './Questions'
import FeelingNow from './FeelingNow'
import Summary from './Summary'
import ShareAnythingElse from './ShareAnythingElse'
import PreferredContact from './PreferredContact'
import ThanksForSharing from './ThanksForSharing'
import Activities from './Activities'
import Activity from './Activity'
import End from './End'
import Intro from './Intro'
import ImpactQ1 from './Impact/Q1'
import ImpactQ2 from './Impact/Q2'
import ImpactQ3 from './Impact/Q3'
import ImpactQ4 from './Impact/Q4'
import ImpactQ5 from './Impact/Q5'
import ImpactQ6 from './Impact/Q6'
import NineToTwelveCheckIn from '.'
import Cadet from './Cadet'
import Feedback from './Feedback'

const NineToTwelveCheckInRouter: RouteComponent = () => (
  <Router>
    <NineToTwelveCheckIn path="nineToTwelve/check-in/:studentId/">
      <Intro path="/" />
      <Cadet path="cadet" />
      <SelectColor path="select-color" />
      <Emotion path="choose-emotion" />
      <EmotionIntensity path="emotion-intensity" />
      <SecondaryEmotions path="secondary-emotions" />
      <BodySensations path="body-sensations" />
      <EnergyLevel path="energy-level" />
      <Summary path="summary" />
      {/* Uncomfy Branch */}
      <Questions path="questions" />
      <ShareAnythingElse path="share-anything-else" />
      <PreferredContact path="preferred-contact" />
      <ThanksForSharing path="thanks-for-sharing" />
      {/* Wrap up */}
      <Activities path="activities" />
      <Activity path="activities/:activity_id" />
      <FeelingNow path="feeling-now" />
      <Feedback path="feedback" />
      <End path="end" />
      {/* Impact Measurements */}
      <ImpactQ1 path="impactQ1" />
      <ImpactQ2 path="impactQ2" />
      <ImpactQ3 path="impactQ3" />
      <ImpactQ4 path="impactQ4" />
      <ImpactQ5 path="impactQ5" />
      <ImpactQ6 path="impactQ6" />
    </NineToTwelveCheckIn>
  </Router>
)

export default NineToTwelveCheckInRouter
