import React from 'react'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import Error from 'components/form/Error'

export default ({ apiError, creating, isDisabled, title, style }) => (
  <div
    style={{
      textAlign: creating ? 'center' : 'right',
      marginTop: '20px',
    }}
  >
    {apiError && <Error>{apiError}</Error>}
    {creating && <CircularProgress color="primary" size="40px" />}
    <Button
      type="submit"
      color="primary"
      disabled={isDisabled}
      style={{ float: 'right', marginTop: '20px', ...style }}
    >
      {title}
    </Button>
  </div>
)
