import React, { useMemo } from 'react'
import { Typography, withWidth, isWidthDown, withStyles } from '@material-ui/core'
import { navigate } from '@reach/router'

const styles = (theme) => ({
  screen: {
    backgroundRepeat: 'no-repeat',
    backgroundSize: '70% auto',
    backgroundPosition: 'bottom 0 left 0',
    backgroundAttachment: 'fixed',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: '10%',
    flexFlow: 'row wrap',
    [theme.breakpoints.down(1400)]: {
      backgroundSize: '80% auto',
    },
    [theme.breakpoints.down(1200)]: {
      backgroundSize: '90% auto',
    },
    [theme.breakpoints.down(1000)]: {
      backgroundSize: '100% auto',
    },
    [theme.breakpoints.down(600)]: {
      alignItems: 'flex-start',
      paddingBottom: '0px',
      backgroundSize: '100% auto',
    },
  },
  balloon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: `inset 12px 0px 0px 0px ${theme.colors.mystic}`,
    background: theme.colors.roseWhite,
    flexDirection: 'column',
    borderRadius: '8px',
    padding: '56px 70px',
    left: 50,
    [theme.breakpoints.down(1400)]: {
      padding: '2rem',
    },
    [theme.breakpoints.down(1200)]: {
      maxWidth: '500px',
    },
    [theme.breakpoints.down(1000)]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.down(900)]: {
      width: '50%',
      marginLeft: '-30px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '1rem',
      width: '100vw',
      margin: 10,
      marginTop: 'calc(10vh)',
    },
  },
  cadetArea: {
    display: 'flex',
    height: '30px',
    width: '40%',
    [theme.breakpoints.down(900)]: {
      width: '50%',
    },
  },
  title: {
    color: theme.colors.carbon,
    textAlign: 'center',
    marginBottom: 16,
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.up('sm')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(600)]: {
      marginBottom: 10,
    },
  },
  subtitle: {
    fontSize: 36,
    textAlign: 'center',
    color: theme.colors.steelGray,
    marginBottom: 20,
    maxWidth: 500,
    [theme.breakpoints.up('sm')]: {
      fontSize: 32,
    },
    [theme.breakpoints.down(900)]: {
      fontSize: 26,
    },
    [theme.breakpoints.down(600)]: {
      marginBottom: 10,
    },
  },
  text: {
    textAlign: 'center',
    marginBottom: 40,
    fontSize: 24,
    maxWidth: 556,
    color: theme.colors.carbon,
    fontWeight: 500,
    lineHeight: '170%',
    [theme.breakpoints.up('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down(900)]: {
      fontSize: 16,
    },
    [theme.breakpoints.down(600)]: {
      fontSize: 15,
      marginBottom: 10,
    },
  },
  button: {
    ...theme.checkinButton,
    color: theme.colors.white,
    fontSize: 32,
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    border: 'none',
    cursor: 'pointer',
    transition: 'opacity 0.25s',
    '&:hover': {
      opacity: '0.8',
    },
    [theme.breakpoints.down(1200)]: {
      maxWidth: '300px',
      fontSize: 26,
    },
    [theme.breakpoints.down(900)]: {
      maxWidth: '250px',
      maxHeight: '50px',
      fontSize: 20,
    },
  },
})

const VacationScreen = withWidth()(
  withStyles(styles)(({ classes, width, currentUser }) => {
    const isMobile = useMemo(() => isWidthDown('xs', width), [width])

    const handleLogout = () => {
      sessionStorage.clear()
      navigate('/sign-out?clear=true')
    }

    return (
      <div
        className={classes.screen}
        style={{
          backgroundImage: isMobile
            ? "url('/images/cadet_on_vacation_mobile.png')"
            : "url('/images/cadet_on_vacation_desktop.png')",
        }}
      >
        {!isMobile && <div className={classes.cadetArea} />}

        <div className={classes.balloon}>
          <Typography className={classes.title}>
            {currentUser?.firstName ? `Hi, ${currentUser.firstName}!` : 'Hi!'}
          </Typography>
          <Typography className={classes.subtitle}>You’re on school break!</Typography>
          <Typography className={classes.text}>
            You can’t check-in until school starts again, but don’t forget that you can always
            journal, do a power pose, or take some long, deep breaths.
          </Typography>

          <button type="button" className={classes.button} onClick={handleLogout}>
            Okay, log out!
          </button>
        </div>
      </div>
    )
  }),
)

export default VacationScreen
