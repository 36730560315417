import React, { useState } from 'react'
import Screen from '../components/screen'
import { setLocalStorageCheckIn } from 'students/helper'

type Props = {
  handleNextFunction: () => void
}

const ComposeNote = ({ handleNextFunction }: Props) => {
  const [note, setNote] = useState('')
  const charLimit = 600
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-2xl sm:text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        Do you want to share anything else?
      </div>
      <div className="max-w-6xl w-full sm:bg-white/15 rounded-2xl shadow flex-col justify-center items-center gap-6 inline-flex sm:p-6">
        <textarea
          rows={6}
          className="mb-4 sm:mb-0 form-textarea w-full text-2xl bg-white/15 sm:bg-transparent p-6 rounded-lg sm:border sm:border-gray-100 text-gray-100 font-normal font-['Roboto'] leading-normal placeholder:text-gray-100/75"
          placeholder="Type here..."
          value={note}
          onChange={(event) => {
            if (event.target.value.length < charLimit) {
              setNote(event.target.value)
            }
          }}
        />
        <div className="hidden sm:flex justify-between w-full">
          <button
            onClick={handleNextFunction}
            className="transition-all px-6 py-2 rounded-[200px] border-2 border-gray-100 text-gray-100 text-lg font-bold font-['Roboto'] uppercase tracking-widest hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0"
          >
            skip
          </button>
          <button
            onClick={() => {
              setLocalStorageCheckIn('notes', note)
              handleNextFunction()
            }}
            disabled={note.length < 1}
            className="shadow-md bg-cyan-500 text-gray-100  font-bold h-12 px-6 rounded-full uppercase text-lg disabled:bg-teal-200 disabled:text-gray-100 disabled:opacity-40 hover:bg-cyan-500 hover:shadow-[inset_4px_8px_40px_0px_rgba(255,255,255,0.5)]"
          >
            send
          </button>
        </div>
      </div>
      <div className="sm:hidden flex flex-col justify-between w-full mt-auto gap-4">
        <button
          onClick={handleNextFunction}
          className="transition-all px-6 py-2 rounded-[200px] border-2 border-gray-100 text-gray-100 text-lg font-bold font-['Roboto'] uppercase tracking-widest hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0"
        >
          skip
        </button>
        <button
          onClick={() => {
            setLocalStorageCheckIn('notes', note)
            handleNextFunction()
          }}
          disabled={note.length < 1}
          className="shadow-md bg-cyan-500 text-gray-100  font-bold h-12 px-6 rounded-full uppercase text-lg disabled:bg-teal-200 disabled:text-gray-100 disabled:opacity-40 hover:bg-cyan-500 hover:shadow-[inset_4px_8px_40px_0px_rgba(255,255,255,0.5)]"
        >
          send
        </button>
      </div>
    </Screen>
  )
}

export default ComposeNote
