import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from 'checkIn/steps/ChooseActivity/Teen'
import Kid from 'checkIn/steps/ChooseActivity/Kid'
import NonReader from 'checkIn/steps/ChooseActivity/NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData, setQSData } from 'QueryStringController'
import { checkInTrack } from 'students/helper'
import isComfy from 'emotions'
import { useMixpanel } from 'mixpanel'

const SCREEN_NAME = 'chooseActivity'

const ChooseActivity: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()

  const { energyLevel, feeling } = getQSData()

  const comfy = isComfy(feeling)

  const energizedAndComfy = ['green', 'yellow'].includes(energyLevel) && comfy

  const label = energizedAndComfy ? 'Choose an activity:' : 'In the meantime, choose an activity:'

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, label, student)
    }
  }, [student]) // eslint-disable-line

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    const selectActivityFunction = (value: string) => {
      setQSData({ activity: value })
      nextFunction(window.location.search, value)
    }

    switch (student.studentType) {
      case 'teen':
        return <Teen selectActivityFunction={selectActivityFunction} label={label} />
      case 'kid':
        return <Kid selectActivityFunction={selectActivityFunction} label={label} />
      case 'non-reader':
        return (
          <NonReader
            energizedAndComfy={energizedAndComfy}
            selectActivityFunction={selectActivityFunction}
            label={label}
          />
        )
    }
  }

  return null
}

export default ChooseActivity
