import React from 'react'
import { Paper, Typography } from '@material-ui/core'
import templateLodash from 'lodash/template'

const CustomTooltip = ({ active, payload, template }) => {
  if (active && payload?.length) {
    const compiled = templateLodash(template)
    return (
      <Paper
        style={{
          padding: 8,
          width: 120,
          borderRadius: 4,
        }}
      >
        <Typography aria-label="custom-tooltip">
          {compiled({
            value: payload[0].payload.questionTotal,
            name: payload[0].payload.name,
            total: payload[0].payload.total,
          })}
        </Typography>
      </Paper>
    )
  }

  return null
}

export default CustomTooltip
