// Export roles as an object with the keys as value and the object's value as label

export const schoolStaffRoles = {
  teacher: 'Teacher',
  'school-social-worker': 'School Social Worker',
  'school-administrator': 'School Administrator',
  'school-counselor': 'School Counselor',
  'school-psychologist': 'School Psychologist',
  'district-school-psychologist': 'District School Psychologist',
  'school-support-staff': 'School Support Staff',
  'district-administrator': 'District Administrator',
  'technology-administrator': 'Technology Administrator',
  'school-nurse': 'School Nurse',
  'sped-teacher': 'SPED Teacher',
} as { [x: string]: string }

export const educatorRoles = {
  teacher: 'Teacher',
  'school-social-worker': 'School Social Worker',
  'school-administrator': 'School Administrator',
  'school-counselor': 'School Counselor',
  'school-psychologist': 'School Psychologist',
  'district-school-psychologist': 'District School Psychologist',
  'school-support-staff': 'School Support Staff',
  'district-administrator': 'District Administrator',
  'technology-administrator': 'Technology Administrator',
  'school-nurse': 'School Nurse',
  'sped-teacher': 'SPED Teacher',
  'sel-leader': 'SEL Leader',
}

export const nonEducatorRoles = {
  'parent-guardian': 'Parent/Guardian',
  'clinician-therapist': 'Clinician/Therapist',
}

export const studentRoles = { student: 'Student' }

export const roles = {
  ...schoolStaffRoles,
  ...educatorRoles,
  ...nonEducatorRoles,
  ...studentRoles,
} as const

// Export roles as list of objects with label and value

export const studentRolesList = [{ value: 'student', label: 'Student' }]

export const therapistRoleList = [{ value: 'clinician-therapist', label: 'Clinician/Therapist' }]

export const parentRoleList = [{ value: 'parent-guardian', label: 'Parent/Guardian' }]

export const educatorRolesList = [
  { value: 'teacher', label: 'Teacher' },
  { value: 'school-social-worker', label: 'School Social Worker' },
  { value: 'school-administrator', label: 'School Administrator' },
  { value: 'school-counselor', label: 'School Counselor' },
  { value: 'school-psychologist', label: 'School Psychologist' },
  { value: 'district-school-psychologist', label: 'District School Psychologist' },
  { value: 'school-support-staff', label: 'School Support Staff' },
  { value: 'district-administrator', label: 'District Administrator' },
  { value: 'technology-administrator', label: 'Technology Administrator' },
  { value: 'school-nurse', label: 'School Nurse' },
  { value: 'sped-teacher', label: 'SPED Teacher' },
]

export const schoolLevelAccessRoles = [
  'school-social-worker',
  'school-administrator',
  'school-counselor',
  'school-psychologist',
  'district-school-psychologist',
  'school-support-staff',
  'district-administrator',
  'technology-administrator',
  'school-nurse'
]

export const classroomLevelAccessRoles = [
  'teacher',
  'sped-teacher'
]

export function getAccessLevel(role: string) {
  if (schoolLevelAccessRoles.indexOf(role) !== -1) {
    return 'owner'
  } else if (classroomLevelAccessRoles.indexOf(role) !== -1) {
    return 'viewer'
  }
}
