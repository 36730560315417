import React from 'react'
import Title from 'components/Title'
import styled from 'styled-components'
import { getLocalStorageCheckIn } from 'students/helper'
import { lightenColor } from 'utils'

type Props = {
  children?: React.ReactNode
  label?: string
  title?: string
  style?: object
  contentWrapperStyle?: object
  contentWrapperClassName?: string
}

const Label = styled.h2<{ color: string }>`
  color: ${({ color }) => color};
  margin-top: 1rem;
  margin-bottom: 1.75rem;
  padding-left: 4rem;
  padding-right: 4rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  font-weight: 500;
  text-align: center;
`

export default ({
  children,
  label,
  title,
  style = {},
  contentWrapperStyle = {},
  contentWrapperClassName = '',
}: Props) => {
  const color = lightenColor(getLocalStorageCheckIn('color') || '#00ddc1', 10)
  return (
    <main
      style={{
        ...style,
      }}
    >
      <div
        className={contentWrapperClassName}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '6vh',
          ...contentWrapperStyle,
        }}
      >
        {label && <Label color={color}>{label}</Label>}
        {title && <Title style={{ fontWeight: 400, maxWidth: 1008 }}>{title}</Title>}
        {children}
      </div>
    </main>
  )
}
