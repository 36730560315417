import React from 'react'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'
import withMixpanel from 'mixpanel/withMixpanel'
import Screen from 'checkIn/components/teen/Screen'
import CadetImage from 'checkIn/components/teen/CadetImage'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'
import { checkInTrack, isStudent, setStudentInfo } from 'students/helper'
import { IconMessage } from 'checkIn/components/teen/IconMessage'
import FUEL from 'fuel'
import signOutTeacher from 'checkIn/steps/ThanksForExploring/signOutTeacher'
import SecondaryEmotionButton from 'checkIn/components/teen/SecondaryEmotionButton'

const content = {
  adult: {
    green: {
      label: 'Thanks for exploring your feelings with me.',
      title: 'That’s all for now. Return Closegap to an adult!',
      imgSrc: '/images/cool_down_cadet.png',
      imgAlt: 'Happy cadet',
    },
    otherFuel: {
      label: 'Thanks for exploring your feelings with me.',
      title: 'That’s all for now. Return Closegap to an adult!',
      imgSrc: '/images/cool_down_cadet.png',
      imgAlt: 'Cool down cadet',
    },
  },
  student: {
    green: {
      label: 'Thanks for exploring your feelings with me.',
      title: 'That’s all for now. I’ll be here if you need me!',
      imgSrc: '/images/cool_down_cadet.png',
      imgAlt: 'Happy cadet',
    },
    otherFuel: {
      label: 'Thanks for exploring your feelings with me.',
      title: 'That’s all for now.',
      imgSrc: '/images/cool_down_cadet.png',
      imgAlt: 'Cool down cadet',
    },
  },
}

export const TeenThanks = withMixpanel(({ mixpanel, energyLevel, flow, student, currentUser }) => {
  const dispatch = useDispatch()
  const { nextFunction } = flow

  const { hasUnseenMessages = false } = student

  const userContent = isStudent(currentUser.role) ? content.student : content.adult

  const finalContent =
    energyLevel === FUEL.FULL_AND_READY.value ? userContent?.green : userContent?.otherFuel

  const showStudentComponents = isStudent(currentUser.role)

  const handleMyProfileButton = () => {
    checkInTrack(mixpanel, 'Go to My Profile Clicked')
    navigate(`/check-in-history/${student.id}`)
    setStudentInfo({ studentId: student.id, grade: student.grade })
  }

  const handleLogout = () => {
    checkInTrack(mixpanel, 'Log Out Clicked')
    sessionStorage.clear()
    navigate('/sign-out?clear=true')
  }

  return (
    <Screen
      label={finalContent.label}
      title={finalContent.title}
      contentWrapperStyle={{ height: '90vh', justifyContent: 'initial' }}
    >
      {!showStudentComponents && (
        <BackAndNextButtons
          nextButtonOnClick={signOutTeacher(dispatch, currentUser, nextFunction)}
        />
      )}
      <CadetImage
        src={finalContent.imgSrc}
        alt={finalContent.imgAlt}
        style={{ position: 'initial', margin: '1rem 0px' }}
      />
      {showStudentComponents && (
        <div className="flex flex-col items-center">
          {hasUnseenMessages && (
            <IconMessage
              className="mt-[-16px] ml-[150px] sm:ml-[240px]"
              onClick={handleMyProfileButton}
            />
          )}
          <SecondaryEmotionButton
            className="mb-5"
            style={{ width: 'auto' }}
            onClick={handleMyProfileButton}
          >
            <span className="flex text-base font-medium xs:text-2xl sm:text-3xl">
              Go to My Profile
            </span>
          </SecondaryEmotionButton>
          <SecondaryEmotionButton onClick={handleLogout} style={{ width: 'auto' }}>
            Log Out
          </SecondaryEmotionButton>
        </div>
      )}
    </Screen>
  )
})
