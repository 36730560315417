import React, { useState } from 'react'
import { useCroods } from 'croods'
import Loading from 'components/Loading'
import { error, success } from 'components/Toast'
import { Modal } from '../components/Modal'
import { Button, ItemCard, P, SubTitle, Title } from '../components/styled'

export const StudentsList = ({
  selectedSchool,
  setShowStudents,
  students,
  fetchingStudents,
}: any) => {
  const [studentsIds, setStudentsIds] = useState<number[]>([])
  const [openConfirmation, setOpenConfirmation] = useState<boolean>(false)

  const [{ destroying, destroyError }, { destroy: destroyStudents }] = useCroods({
    name: 'admin-destroy-students',
    customPath: 'admin/destroy_students',
    afterSuccess: () => {
      setOpenConfirmation(false)
      setShowStudents(false)
      setStudentsIds([])
      success('Students destroyed successfully')
    },
    afterFailure: () => {
      error(destroyError || '')
    },
  })

  const selectStudent = (id: number) => {
    if (studentsIds.includes(id)) {
      setStudentsIds((c) => c.filter((ids) => ids !== id))
    } else {
      setStudentsIds([...studentsIds, id])
    }
  }

  return (
    <div style={{ alignSelf: 'center', width: '100%', maxWidth: '1200px', margin: '3rem 0' }}>
      <Title>{selectedSchool?.name}&apos; students</Title>

      <div style={{ display: 'flex', gap: '1rem' }}>
        <Button disabled={studentsIds.length === 0} onClick={() => setStudentsIds([])}>
          Unselect all ({studentsIds.length}) students
        </Button>
        <Button
          disabled={studentsIds.length === students?.length}
          variant="edit"
          onClick={() => setStudentsIds(students.map((s: any) => s.id))}
        >
          Select all students
        </Button>
        <Button
          disabled={studentsIds.length === 0}
          variant="delete"
          onClick={() => setOpenConfirmation(true)}
        >
          Destroy Selected Students ({studentsIds.length})
        </Button>
      </div>

      {fetchingStudents ? (
        <Loading />
      ) : (
        students?.map((s: any) => (
          <ItemCard key={s.id} variant={studentsIds.includes(s.id) ? 'selected' : 'none'}>
            <div>
              <P>
                <strong>{s.name}</strong> (Id: {s.id})
              </P>
              <P>
                Grade: {s?.grade} | Gender: {s?.gender}
              </P>
              <P>Email: {s?.email}</P>
            </div>
            <div>
              <Button
                variant={studentsIds.includes(s.id) ? 'selected' : 'add'}
                onClick={() => selectStudent(s.id)}
              >
                {studentsIds.includes(s.id) ? 'Unselect' : 'Select'}
              </Button>
            </div>
          </ItemCard>
        ))
      )}

      <Modal open={openConfirmation} onClose={() => setOpenConfirmation(false)}>
        <Title>Attention!!!</Title>
        <P>You are about to completely destroy {studentsIds.length} student(s)!</P>
        <P>Make sure you are removing the correct student(s)!</P>
        <SubTitle>This action CAN NOT be undone</SubTitle>
        {destroying ? <Loading /> : null}
        <Button variant="delete" onClick={() => destroyStudents()({ ids: studentsIds })}>
          YES, DESTROY THE {studentsIds.length} SELECTED STUDENT(S)
        </Button>
      </Modal>
    </div>
  )
}
