import { isWidthUp, withWidth } from '@material-ui/core'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import React, { useMemo } from 'react'
import styled from 'styled-components'

export const comfortableSecondaryEmotions = [
  'pleased',
  'satisfied',
  'playful',
  'comfortable',
  'inspired',
  'overjoyed',
  'amazed',
  'trusting',
  'ecstatic',
  'thankful',
  'curious',
  'independent',
  'free',
  'peaceful',
  'optimistic',
  'courageous',
  'passionate',
  'proud',
  'cherished',
  'accepted',
  'focused',
  'successful',
]

export const uncomfortableSecondaryEmotions = [
  'uncomfortable',
  'grumpy',
  'guilty',
  'worried',
  'worthless',
  'cautious',
  'envious',
  'helpless',
  'frustrated',
  'ashamed',
  'anxious',
  'resentful',
  'hopeless',
  'exposed',
  'lonely',
  'confused',
  'enraged',
  'powerless',
  'disappointed',
  'defensive',
  'stressed',
  'uncertain',
]

const comfortablePrimaryEmotions = [
  'happy',
  'content',
  'calm',
  'excited',
  'grateful',
  'loved',
  'hopeful',
  'confident',
  'proud',
]

export const neutralSecondaryEmotions = [
  'apathetic',
  'ashamed',
  'bored',
  'chill',
  'comfortable',
  'complacent',
  'content',
  'down',
  'grumpy',
  'indifferent',
  'neutral',
  'normal',
  'peaceful',
  'peeved',
  'pleased',
  'relieved',
  'rested',
  'serene',
  'thoughtful',
  'uncertain',
  'uncomfortable',
  'worried',
]

const neutralPrimaryEmotions = ['bored', 'meh']

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 860px;
  gap: 50px;
`

const SecondaryEmotionButton = styled.button<{ selected: boolean }>`
  min-width: 100px;
  height: 100px;
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  background-image: ${({ selected }) =>
    selected ? `url(/images/planets/fiveToEight/secondary.png)` : `none`};
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  color: #fff;
`

function getSecondaryEmotions(feeling: string) {
  if (comfortablePrimaryEmotions.includes(feeling)) {
    return comfortableSecondaryEmotions
  }
  if (neutralPrimaryEmotions.includes(feeling)) {
    return neutralSecondaryEmotions
  }
  return uncomfortableSecondaryEmotions
}

type Props = {
  width: Breakpoint
  feeling: string
  selected: Array<string>
  handleOptionClick: (item: string) => void
}

const FiveToEight = ({ feeling, handleOptionClick, selected, width }: Props) => {
  const secondayEmotions = getSecondaryEmotions(feeling)
  const offsets = useMemo(() => {
    return secondayEmotions.map(() => {
      return [Math.floor(Math.random() * 30 - 14), Math.floor(Math.random() * 30 - 14)]
    })
  }, [secondayEmotions])
  return (
    <Container className="mt-6 px-6 lg:px-0">
      {secondayEmotions
        .filter((se) => se !== feeling)
        .map((emotion, index) => (
          <div key={emotion}>
            <SecondaryEmotionButton
              type="button"
              onClick={() => {
                handleOptionClick(emotion)
              }}
              selected={selected.includes(emotion)}
              style={{
                transform: isWidthUp('sm', width)
                  ? `translateX(${offsets[index][0]}px) translateY(${offsets[index][1]}px)`
                  : 'none',
              }}
            >
              {emotion}
            </SecondaryEmotionButton>
          </div>
        ))}
    </Container>
  )
}

export default withWidth()(FiveToEight)
