import React from 'react'
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount'
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive'
import PersonIcon from '@material-ui/icons/Person'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import { useFlash } from 'seasoned-flash'
import { Button, Checkbox, FormControlLabel } from '@material-ui/core'
import { useCroods } from 'croods'
import { useCurrentUser } from 'dmcroods-auth'
import { identity, map, pickBy, snakeCase } from 'lodash'
import { featureEnabled } from 'featureFlags'

import SelectList from 'Educators/students/insights/Filter/SelectList'
import { useSchools } from 'Educators/SchoolsContext'
import { getGradesByStudentType } from 'grades'
import LIVING_SITUATION from 'livingSituation'
import RACE_ETHNICITY from 'raceEthnicity'
import ButtonAccordion from 'Educators/students/insights/ButtonAccordion'
import {
  emotionOptions,
  emotionOptionsV2,
  emotionOptionsV3,
  energyLevelOptions,
  nonReaderEnergyLevelOptions,
  followUpQuestionOptions,
  followUpQuestionOptionsV2,
} from 'Educators/students/edit/alerts/options'
import Loading from 'components/Loading'
import Error from 'components/form/Error'
import FREQUENCIES from 'constants/frequencies'
import KINDS from 'constants/kinds'
import { STUDENT_OPTIONS, STUDENT_OPTIONS_V2 } from 'Educators/students/insights/studentTypes'

const VALUE_ALL = { value: '', label: <em>None</em> }

const getGrades = (studentType) => getGradesByStudentType(studentType).map(({ value }) => value)

const getOptions = (kind, studentType) => {
  if (kind === 'energy_level') return energyLevelOptions
  if (kind === 'emotion') return emotionOptions[studentType]
  if (kind === 'follow_up_question') return followUpQuestionOptions[studentType]
  return []
}

const getOptionsV2 = (kind, studentType) => {
  if (kind === 'energy_level') return energyLevelOptions
  if (kind === 'emotion') return emotionOptionsV2[studentType]
  if (kind === 'follow_up_question') return followUpQuestionOptionsV2[studentType]
  return []
}

const getOptionsV3 = (kind, studentType) => {
  if (kind === 'energy_level') return studentType === 'non-reader' ? nonReaderEnergyLevelOptions : energyLevelOptions
  if (kind === 'emotion') return emotionOptionsV3[studentType]
  if (kind === 'follow_up_question') return followUpQuestionOptionsV2[studentType]
  return []  
}

const defaultValues = {
  grades: getGrades('all'),
  classroomId: '',
  englishLanguageLearner: false,
  educationPlan504: false,
  individualizedEducationProgram: false,
  freeReducedPriceMeals: false,
  ethnicity: '',
  livingSituation: '',
  kind: '',
  condition: '',
  frequency: '',
}

const Options = ({
  initialStudentType = 'all',
  initialState = defaultValues,
  openAccordion = false,
}) => {
  const [{ currentUser }] = useCurrentUser()
  const { schoolId } = useSchools()
  const { success } = useFlash()

  const [needValidation, setNeedValidation] = React.useState(false)
  const [filter, setFilter] = React.useState(initialState)
  const [studentType, setStudentType] = React.useState(initialStudentType)

  const [{ list: classrooms, fetchingList: loadingClassrooms }] = useCroods({
    cache: true,
    fetchOnMount: true,
    stateId: schoolId,
    name: 'classrooms',
    customPath: `/schools/${schoolId}/classrooms`,
  })

  const [{ saveError, saving }, { save }] = useCroods({
    name: 'insightsAlerts',
    stateId: schoolId,
    customPath: `/schools/${schoolId}/alerts`,
    afterSuccess: () => {
      success('Alert successfully added!')
    },
    parseParams: (value) => (value !== 'educationPlan504' ? snakeCase(value) : 'education_plan504'),
  })

  const getConditions = () => {
    if (featureEnabled(currentUser, 'nine_to_twelve_check_in') && featureEnabled(currentUser, 'pk_2_check_in_2024')) return getOptionsV3(filter.kind, studentType)
    else if (featureEnabled(currentUser, 'nine_to_twelve_check_in')) return getOptionsV2(filter.kind, studentType)
    else return getOptions(filter.kind, studentType)
  }

  const conditions = getConditions()

  const setValue = (name, value) =>
    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }))

  const onChange = (event) => {
    const { name, value } = event.target
    setValue(name, value)
  }

  const onClick = () => {
    const { grades, kind, condition, frequency } = filter
    if (grades?.length > 0 && kind && condition && frequency) {
      setNeedValidation(false)
      save({ method: 'POST' })(pickBy(filter, identity))
      return
    }
    setNeedValidation(true)
  }

  React.useEffect(() => {
    setValue('grades', getGrades(studentType))
  }, [studentType])

  React.useEffect(() => {
    setFilter(initialState)
  }, [initialState])

  return (
    <div>
      <div>
        <h1 className="flex items-center text-sm font-medium text-gray-500">
          <SupervisorAccountIcon style={{ height: 20, marginRight: 8 }} />
          SELECT STUDENT GROUP
        </h1>
        <div className="flex flex-col items-end gap-5 mt-4 sm:flex-row md:gap-10">
          <SelectList
            label="Check-in*"
            options={
              featureEnabled(currentUser, 'nine_to_twelve_check_in')
                ? STUDENT_OPTIONS_V2
                : STUDENT_OPTIONS
            }
            value={studentType}
            onChange={(event) => setStudentType(event.target.value)}
          />
          <SelectList
            multiple
            label="Grades*"
            options={getGrades(studentType)}
            value={filter.grades}
            onChange={onChange}
            propsSelect={{
              renderValue: (selected) => selected.join(', '),
              'data-testid': 'select-grades',
              inputProps: {
                id: 'grades',
                name: 'grades',
              },
            }}
            error={needValidation && filter?.grades?.length < 1}
          />
          <SelectList
            label="Classroom"
            options={[
              VALUE_ALL,
              ...map(classrooms, ({ id, name }) => ({
                label: name,
                value: id,
              })),
            ]}
            propsSelect={{
              'data-testid': 'select-classroom-id',
              inputProps: {
                id: 'classroomId',
                name: 'classroomId',
              },
            }}
            loading={loadingClassrooms}
            value={filter.classroomId}
            onChange={onChange}
            showNoneComponent
          />
        </div>
      </div>
      <div className="mt-10">
        <h1 className="flex items-center text-sm font-medium text-gray-500">
          <NotificationsActiveIcon style={{ height: 20, marginRight: 8 }} />
          NOTIFY STAFF WHEN
        </h1>
        <div className="flex flex-col items-end gap-5 mt-4 sm:flex-row md:gap-10">
          <SelectList
            label="Select a topic*"
            options={KINDS}
            value={filter.kind}
            onChange={onChange}
            propsSelect={{
              'data-testid': 'select-kind',
              inputProps: {
                id: 'kind',
                name: 'kind',
              },
            }}
            error={needValidation && !filter.kind}
          />
          <SelectList
            label="Select an option*"
            options={conditions}
            value={filter.condition}
            onChange={onChange}
            propsSelect={{
              'data-testid': 'select-condition',
              disabled: conditions?.length < 1,
              inputProps: {
                id: 'condition',
                name: 'condition',
              },
            }}
            error={needValidation && !filter.condition}
          />
          <SelectList
            label="Choose frequency*"
            options={FREQUENCIES}
            value={filter.frequency}
            onChange={onChange}
            propsSelect={{
              'data-testid': 'select-frequency',
              inputProps: {
                id: 'frequency',
                name: 'frequency',
              },
            }}
            error={needValidation && !filter.frequency}
          />
        </div>
      </div>

      <ButtonAccordion className="flex flex-col mt-8" initialState={openAccordion}>
        <div className="mt-4 mb-4">
          <h1 className="flex items-center text-sm font-medium text-gray-500">
            <PersonIcon style={{ height: 20, marginRight: 8 }} />
            STUDENT DETAILS
          </h1>
          <div className="grid items-end grid-cols-1 gap-5 mt-4 sm:grid-cols-2 md:grid-cols-3 md:gap-10">
            <FormControlLabel
              className="w-full"
              control={
                <Checkbox
                  checked={filter.englishLanguageLearner}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.englishLanguageLearner)
                  }}
                  name="englishLanguageLearner"
                  color="primary"
                />
              }
              label="ELL (English Language Learner)"
            />
            <FormControlLabel
              className="w-full"
              control={
                <Checkbox
                  checked={filter.individualizedEducationProgram}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.individualizedEducationProgram)
                  }}
                  name="individualizedEducationProgram"
                  color="primary"
                />
              }
              label="IEP (Individualized Education Program)"
            />
            <div className="order-last md:order-none">
              <SelectList
                label="Race/Ethnicity"
                options={[VALUE_ALL, ...RACE_ETHNICITY]}
                value={filter.ethnicity}
                onChange={onChange}
                propsSelect={{
                  'data-testid': 'select-race-ethnicity',
                  inputProps: {
                    id: 'ethnicity',
                    name: 'ethnicity',
                  },
                }}
                showNoneComponent
              />
            </div>
            <FormControlLabel
              className="w-full"
              control={
                <Checkbox
                  checked={filter.educationPlan504}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.educationPlan504)
                  }}
                  name="educationPlan504"
                  color="primary"
                />
              }
              label="504 Plan"
            />
            <FormControlLabel
              className="w-full"
              control={
                <Checkbox
                  checked={filter.freeReducedPriceMeals}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.freeReducedPriceMeals)
                  }}
                  name="freeReducedPriceMeals"
                  color="primary"
                />
              }
              label="Free / Reduced Price Meals"
            />
            <div className="order-last md:order-none">
              <SelectList
                label="Living Situation"
                options={[VALUE_ALL, ...LIVING_SITUATION]}
                value={filter.livingSituation}
                onChange={onChange}
                propsSelect={{
                  'data-testid': 'select-living-situation',
                  inputProps: {
                    id: 'livingSituation',
                    name: 'livingSituation',
                  },
                }}
                showNoneComponent
              />
            </div>
          </div>
        </div>
      </ButtonAccordion>
      {saveError && <Error>{saveError}</Error>}
      <div className="flex justify-end mt-5">
        {saving ? (
          <Loading />
        ) : (
          <Button variant="contained" color="primary" endIcon={<AddAlertIcon />} onClick={onClick}>
            SET ALERT
          </Button>
        )}
      </div>
    </div>
  )
}

export default Options
