import React from 'react'
import { navigate } from '@reach/router'

import Button from 'components/Button'

export const ButtonNext = ({
  nextURL,
  buttonText = 'Continue',
  handleMixpanel,
  ...props
}) => (
  <Button
    onClick={() => {
      if (handleMixpanel) handleMixpanel()
      navigate(nextURL)
    }}
    {...props}
  >
    {props.children || buttonText}
  </Button>
)

export default ButtonNext
