import React from 'react'
import PropTypes from 'prop-types'
import { Select, MenuItem } from '@material-ui/core'
import capitalize from 'lodash/capitalize'
import values from 'lodash/values'

import { colors } from 'theme'

const SCHOOL_OPTIONS = {
  owner: { label: 'School Level Access', value: 'owner' },
  viewer: { label: 'Classroom Level Access', value: 'viewer' },
}

const ActionUpdateSelect = ({
  value,
  onChange,
  mode = 'school',
}) => {
  const options =
    mode === 'school'
      ? values(SCHOOL_OPTIONS)
      : []

  return (
    <Select
      id="action"
      label="action"
      value={value}
      onChange={onChange}
      style={{
        marginLeft: 24,
        minWidth: 171,
        [value === 'remove' ? 'color' : '']: colors.redDark,
      }}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}

      <MenuItem value="remove" style={{ color: colors.redDark }}>
        Delete from {capitalize(mode)}
      </MenuItem>
    </Select>
  )
}

ActionUpdateSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  initialValue: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(['school', 'classroom']),
}

export default ActionUpdateSelect
