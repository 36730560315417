import styled from 'styled-components'
export { Checkbox } from './Checkbox'
export { Button } from './Button'

export const Name = styled.p`
  font-size: 1.5rem;
  color: #353535; // theme.colors.carbonDark
  font-style: normal;
  font-weight: 700;
  line-height: 160%;
`
export const Grade = styled.p`
  text-transform: capitalize;
  font-size: 12px;
  color: #808080; //theme.colors.trolleyGray
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  margin-left: 1rem;
  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
`
export const Divider = styled.hr`
  border-top: 1px solid #eee;
  // margin-top: 1rem;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
`
