import React from 'react'
import FLOWS from 'checkIn/flows'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import NineToTwelve from './NineToTwelve'

const SelectColor: RouteComponent = () => {
  const { student } = useCheckIn()

  if (!student) return null

  return <NineToTwelve nextFunction={FLOWS.teen['start'].nextFunction} />
}

export default SelectColor
