import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
`

export const Needs = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  color: #808080; // trolleyGray
  @media screen and (max-width: 700px) {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }
`

export const Answer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem; // theme.spacing(1)
  color: #808080; // trolleyGray
`

export const Text = styled.p`
  color: #353535; // carbonDark
  font-weight: 500;
  margin-left: 0.5rem;
`
