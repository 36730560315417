import { navigate } from '@reach/router'

import { getQSData } from 'QueryStringController'
import { isComfy } from 'emotions'
import parseCheckIn from 'checkIn/parseCheckIn'
import { School, User } from 'types'
import { isWeekendToday } from 'students/helper'
import { isWithinSchoolOpeningHours } from 'checkIn/helper'
import { isSchoolOnVacation } from 'auth/signIn/vacationHelper'
import { featureEnabled } from 'featureFlags'

export default {
  welcome: { nextFunction: (_params: string,  user?: User) => {
    featureEnabled(user, 'pk_2_check_in_2024') ?  navigate('./choose-emotion') : navigate('.') }
  },

  start: { nextFunction: () => navigate('./choose-emotion') },

  chooseEmotion: { nextFunction: (params: string, user?: User) => {
    featureEnabled(user, 'pk_2_check_in_2024') ?  navigate(`./body-sensations${params}`) : navigate(`./confirm-emotion${params}`) } },

  confirmEmotion: { nextFunction: (params: string) => navigate(`./body-sensations${params}`) },

  bodySensations: { nextFunction: (params: string, user?: User) => {
    featureEnabled(user, 'pk_2_check_in_2024') ? navigate(`./energy-level${params}`) : navigate(`./energy-level-intro${params}`)} 
  },

  energyLevelIntro: { nextFunction: (params: string) => navigate(`./energy-level${params}`) },


  energyLevel: {
    nextFunction: (params: string, user?: User) => {
      if (featureEnabled(user, 'pk_2_check_in_2024')) {
        const { energyLevel, feeling } = getQSData()
        const isOkOrFullAndEmotionsAreComfy =
          ['green', 'yellow'].includes(energyLevel) && isComfy(feeling)
        const path = isOkOrFullAndEmotionsAreComfy ? 'comfortable-result' : 'uncomfortable-result'
        return navigate(`./${path}${params}`)
      }
      else navigate(`./energy-level-confirmation${params}`)},
  },

  energyLevelConfirmation: {
    nextFunction: (params: string) => {
      const { energyLevel, feeling } = getQSData()
      const isOkOrFullAndEmotionsAreComfy =
        ['green', 'yellow'].includes(energyLevel) && isComfy(feeling)
      const path = isOkOrFullAndEmotionsAreComfy ? 'comfortable-result' : 'uncomfortable-result'
      return navigate(`./${path}${params}`)
    },
  },

  comfortableResult: { nextFunction: (params: string) => navigate(`./choose-activity${params}`) },

  uncomfortableResult: {
    nextFunction: (params: string) => navigate(`./questions/hasEaten${params}`),
  },

  hasEaten: { nextFunction: (params: string) => navigate(`./hasSlept${params}`) },

  hasSlept: { nextFunction: (params: string) => navigate(`./hurtOrSick${params}`) },

  hurtOrSick: { nextFunction: (params: string) => navigate(`./okAtHome${params}`) },

  okAtHome: { nextFunction: (params: string) => navigate(`./bulliedAtSchool${params}`) },

  bulliedAtSchool: {
    nextFunction: (params: string, school?: School) => {
      const skipTalkToAdultStepPath = `./../talk-to-an-adult-response${params}`
      if (isWeekendToday()) return navigate(skipTalkToAdultStepPath)
      if (!isWithinSchoolOpeningHours()) return navigate(skipTalkToAdultStepPath)
      if (school && isSchoolOnVacation(school)) return navigate(skipTalkToAdultStepPath)
      return navigate(`./../talk-to-an-adult${params}`)
    },
  },

  talkToAnAdult: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./talk-to-an-adult-response${params}`)
    },
  },

  talkToAnAdultResponse: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./choose-activity${params}`)
    },
  },

  chooseActivity: {
    nextFunction: (params: string, activityKey: string) =>
      navigate(`./activities/${activityKey}${params}`),
  },

  activities: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./../how-are-you-feeling-now${params}`)
    },
  },

  howAreYouFeelingNow: {
    nextFunction: (
      saveCheckIn: (options: any) => (value: any) => void,
      userId: number,
      studentId: number,
    ) => {
      const newValue = parseCheckIn(userId, studentId)
      saveCheckIn({
        afterSuccess: () => navigate('./thanks-for-exploring', { replace: true }),
      })(newValue)
    },
  },

  thanksForExploring: { nextFunction: () => navigate('/sign-out') },
} as const
