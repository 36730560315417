import React from 'react'

type Props = {
  disabled?: boolean
  handleNextFunction: () => void
  additionalClasses?: string
  children?: any
}

export const SecondaryButton = ({
  disabled = false,
  handleNextFunction,
  additionalClasses,
  children,
}: Props) => (
  <button
    disabled={disabled}
    onClick={handleNextFunction}
    className={`transition-all ${additionalClasses} text-gray-100 text-base font-bold font-['Roboto'] px-4 h-12 max-w-96 w-full border-2 tracking-[3.20px] uppercase leading-4  
    bg-cover bg-no-repeat bg-center rounded-full shadow-sm 
    hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0
  `}
  >
    {children || 'Skip'}
  </button>
)
