import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData } from 'QueryStringController'
import NonReaderPlanetList from 'checkIn/constants/nonReader/planetList'
import NonReader from 'checkIn/steps/EmotionConfirmation/NonReader'

const SCREEN_NAME = 'confirmEmotion'

const EmotionConfirmation: RouteComponent = () => {
  const { student } = useCheckIn()

  const { feeling } = getQSData()
  if (student) {
    const { nextFunction } = FLOWS['non-reader'][SCREEN_NAME]

    return (
      <NonReader
        feeling={feeling as typeof NonReaderPlanetList[number]['feeling']}
        nextFunction={nextFunction}
      />
    )
  }

  return null
}

export default EmotionConfirmation
