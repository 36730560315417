import React from 'react'
import styled from 'styled-components'
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from 'react-icons/md'

const Wrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  background: ${({ color }) => color};
  color: #ffffff;
  padding: 0.8rem;
  width: 100%;
  font-family: Roboto;
  font-weight: 500;
  font-size: 1rem;
`

const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  padding: 0.4rem 1rem;
  border-radius: 0.5rem;
  transition: all ease 0.25s;
  :hover {
    background: rgba(0, 0, 0, 0.1);
  }
  :disabled {
    visibility: hidden;
  }
`

interface Props {
  currentPage: number
  pages: number
  color?: 'red' | 'yellow' | 'green' | 'other'
  onButtonsClick(num: number): void
}

const colors = {
  red: 'rgb(234,71,59)',
  yellow: 'rgb(249,178,51)',
  green: 'rgb(50,173,69)',
  other: 'rgba(255,123,102,1)',
}

export const PaginationBar = ({ currentPage, pages, color = 'other', onButtonsClick }: Props) => (
  <Wrapper color={colors[color]}>
    <Button onClick={() => onButtonsClick(currentPage - 1)} disabled={currentPage === 1}>
      <MdKeyboardArrowLeft color="#ffffff" size={20} />
      PREVIOUS
    </Button>
    {currentPage} of {pages}
    <Button onClick={() => onButtonsClick(currentPage + 1)} disabled={currentPage === pages}>
      NEXT
      <MdKeyboardArrowRight color="#ffffff" size={20} />
    </Button>
  </Wrapper>
)
