import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { setQSData } from 'QueryStringController'
import ThreeToFourBodySensations from './ThreeToFourBodySensations'
const SCREEN_NAME = 'bodySensations'

const BodySensations: RouteComponent = () => {
  const { student } = useCheckIn()
  const [selectedSensations, setSelectedSensations] = React.useState<string[]>([])

  if (student) {
    const { nextFunction } = FLOWS['kid'][SCREEN_NAME]

    const handleNextFunction = () => {
      if (selectedSensations?.length > 0)
        setQSData({ bodySensations: selectedSensations.join(',') })

      nextFunction(window.location.search)
    }

    return (
      <ThreeToFourBodySensations
        handleNextFunction={handleNextFunction}
        selectedSensations={selectedSensations}
        setSelectedSensations={setSelectedSensations}
      />
    )
  }

  return null
}

export default BodySensations
