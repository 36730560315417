import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { setQSData } from 'QueryStringController'
import ThreeToFour from 'checkIn/steps/EnergyLevel/ThreeToFour'

const SCREEN_NAME = 'energyLevel'

const EnergyLevel: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const { nextFunction } = FLOWS['kid'][SCREEN_NAME]

    const selectFuelFunction = (value: string) => {
      setQSData({ energyLevel: value })
      nextFunction(window.location.search)
    }

    return <ThreeToFour selectFuelFunction={selectFuelFunction} />
  }

  return null
}

export default EnergyLevel
