import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { checkInTrack } from 'students/helper'
import { useMixpanel } from 'mixpanel'
import { getQSData, setQSData } from 'QueryStringController'
import { featureEnabled } from 'featureFlags'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import FiveToEight from './FiveToEight'
import ThreeToFour from './ThreeToFour'
import ThreeToFourV2 from './ThreeToFourV2'
import FiveToEightV2 from './FiveToEightV2'

const SCREEN_NAME = 'emotionIntensity'

const EmotionIntensity: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  const { feeling } = getQSData()

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, 'Check-in: Confirm How are you feeling now?', student)
    }
  }, [student]) // eslint-disable-line

  if (!student || student.studentType === 'non-reader') return null

  const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

  const selectEmotionIntensity = (feeling_intensity: number | null) => {
    if (!feeling_intensity) return null
    setQSData({ feeling_intensity })
    nextFunction(window.location.search)
  }

  switch (student.studentType) {
    case 'teen':
      return featureEnabled(currentUser, 'emotion_intensity_v2') ? (
        <FiveToEightV2 selectEmotionIntensity={selectEmotionIntensity} feeling={feeling} />
      ) : (
        <FiveToEight selectEmotionIntensity={selectEmotionIntensity} feeling={feeling} />
      )
    case 'kid':
      return featureEnabled(currentUser, 'emotion_intensity_v2') ? (
        <ThreeToFourV2 selectEmotionIntensity={selectEmotionIntensity} feeling={feeling} />
      ) : (
        <ThreeToFour selectEmotionIntensity={selectEmotionIntensity} feeling={feeling} />
      )
  }

  return null
}

export default EmotionIntensity
