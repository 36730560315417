import { navigate } from '@reach/router'
import { isSchoolOnVacation } from 'auth/signIn/vacationHelper'
import { get } from 'lodash'
import { setQSData, getQSData } from 'QueryStringController'
import { isComfy } from 'emotions'
import parseCheckIn from 'checkIn/parseCheckIn'
import { isWeekendToday, setLocalStorageCheckIn } from 'students/helper'
import { isWithinSchoolOpeningHours } from 'checkIn/helper'
import {  User } from 'types'

export default {
  welcome: { nextFunction: (params: string,  _user?: User) => navigate(`./select-color${params}`) },

  selectColor: {
    nextFunction: (params: string) => {
      const { impactMeasurement } = getQSData()
      impactMeasurement === 'true' ? navigate(`./impactQ1${params}`) : navigate('.')
    },
  },

  start: { nextFunction: () => navigate('./choose-emotion') },

  chooseEmotion: { nextFunction: (params: string) => navigate(`./confirm-emotion${params}`) },

  confirmEmotion: { nextFunction: (params: string) => navigate(`./emotion-intensity${params}`) },

  emotionIntensity: { nextFunction: (params: string) => navigate(`./secondary-emotions${params}`) },

  secondaryEmotions: { nextFunction: (params: string) => navigate(`./body-sensations${params}`) },

  bodySensations: { nextFunction: (params: string) => navigate(`./energy-level-intro${params}`) },

  energyLevelIntro: { nextFunction: (params: string) => navigate(`./energy-level${params}`) },

  energyLevel: {
    nextFunction: (params: string) => navigate(`./energy-level-confirmation${params}`),
  },

  energyLevelConfirmation: {
    nextFunction: (params: string) => {
      const { energyLevel, feeling } = getQSData()
      const isOkOrFullAndEmotionsAreComfy =
        ['green', 'yellow'].includes(energyLevel) && isComfy(feeling)
      const path = isOkOrFullAndEmotionsAreComfy ? 'comfortable-result' : 'uncomfortable-result'
      return navigate(`./${path}${params}`)
    },
  },

  comfortableResult: { nextFunction: (params: string) => navigate(`./choose-activity${params}`) },

  uncomfortableResult: {
    nextFunction: (params: string) => navigate(`./questions/hasEaten${params}`),
  },

  hasEaten: { nextFunction: (params: string) => navigate(`./hasSlept${params}`) },

  hasSlept: { nextFunction: (params: string) => navigate(`./hurtOrSick${params}`) },

  hurtOrSick: { nextFunction: (params: string) => navigate(`./okAtHome${params}`) },

  okAtHome: { nextFunction: (params: string) => navigate(`./bulliedAtSchool${params}`) },

  bulliedAtSchool: { nextFunction: (params: string) => navigate(`./feelingStressed${params}`) },

  feelingStressed: { nextFunction: (params: string) => navigate(`./feelingLonely${params}`) },

  feelingLonely: { nextFunction: (params: string) => navigate(`./overwhelmedByWorkload${params}`) },

  overwhelmedByWorkload: {
    nextFunction: (params: string) => navigate(`./okInSocialLife${params}`),
  },

  okInSocialLife: {
    nextFunction: (params: string) => {
      const { energyLevel } = getQSData()

      energyLevel === 'orange'
        ? navigate(`./feelingWorthless${params}`)
        : navigate(`./feelingTired${params}`)
    },
  },

  feelingTired: { nextFunction: (params: string) => navigate(`./feelingWorthless${params}`) },

  feelingWorthless: {
    nextFunction: (
      params: string,
      school?: {
        vacationStartsAt: string
        vacationEndsAt: string
      },
    ) => {
      const skipNotesAndTalkToAdultStepsPath = `./../talk-to-an-adult-response${params}`
      if (isWeekendToday()) return navigate(skipNotesAndTalkToAdultStepsPath)
      if (!isWithinSchoolOpeningHours()) return navigate(skipNotesAndTalkToAdultStepsPath)
      if (school && isSchoolOnVacation(school)) return navigate(skipNotesAndTalkToAdultStepsPath)
      return navigate(`./../share-anything-else${params}`)
    },
  },

  shareAnythingElse: { nextFunction: (params: string) => navigate(`./talk-to-an-adult${params}`) },

  talkToAnAdult: {
    nextFunction: (preferredContacts: { id?: number }[]) => {
      const params = window.location.search
      if (preferredContacts?.length > 1) return navigate(`./preferred-contact${params}`)
      const preferredContact = get(preferredContacts?.[0], 'id', null)
      if (preferredContact) setQSData({ preferredContact: String(preferredContact) })
      return navigate(`./talk-to-an-adult-response${params}`)
    },
  },

  preferredContact: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./talk-to-an-adult-response${params}`)
    },
  },

  talkToAnAdultResponse: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./choose-activity${params}`)
    },
  },

  chooseActivity: {
    nextFunction: (params: string, activityKey: string) =>
      navigate(`./activities/${activityKey}${params}`),
  },

  activities: {
    nextFunction: () => {
      const params = window.location.search
      navigate(`./../how-are-you-feeling-now${params}`)
    },
  },

  howAreYouFeelingNow: {
    nextFunction: (
      saveCheckIn: (options: any) => (value: any) => void,
      userId: number,
      studentId: number,
    ) => {
      const newValue = parseCheckIn(userId, studentId)
      saveCheckIn({
        afterSuccess: () => {
          setLocalStorageCheckIn('notes', null)
          setLocalStorageCheckIn('goal', null)
          navigate('./thanks-for-exploring', { replace: true })
        },
      })(newValue)
    },
  },

  thanksForExploring: { nextFunction: () => navigate('/sign-out') },

  impactQ1: { nextFunction: () => navigate('./impactQ2') },
  impactQ2: { nextFunction: () => navigate('./impactQ3') },
  impactQ3: { nextFunction: () => navigate('./impactQ4') },
  impactQ4: { nextFunction: () => navigate('./impactQ5') },
  impactQ5: { nextFunction: () => navigate('./impactQ6') },
  impactQ6: { nextFunction: () => navigate('./choose-emotion') },
} as const
