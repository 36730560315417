import React, { useEffect } from 'react'
import { useCurrentUser, useSignOut } from 'dmcroods-auth'
import { navigate } from '@reach/router'

import Loading from 'components/AuthLoading'
import withMixpanel from 'mixpanel/withMixpanel'
import { useFlash } from 'seasoned-flash'
import { useCroods } from 'croods'
import { useSchools } from 'Educators/SchoolsContext'

export default withMixpanel(({ mixpanel }) => {
  const { success } = useFlash()
  const [{ currentUser }] = useCurrentUser()
  const { clearSchoolId } = useSchools()

  const [, { resetState }] = useCroods({ name: 'auth', stateId: 'signIn' })

  const [{ signingOut }, signOut] = useSignOut({
    afterSuccess: () => {
      success('You are now signed out!')
    },
  })

  const clearUser = new URLSearchParams(window.location.search).get('clear')
  const userEmail = currentUser?.email

  useEffect(() => {
    const init = async () => {
      document.body.style.backgroundImage = 'none'
      sessionStorage.clear()
      await signOut(() => resetState())
      if (clearUser) {
        clearSchoolId()
        localStorage.clear()
        mixpanel.track('Sign Out')
        mixpanel.reset()
      }
      navigate('/sign-in', {
        state: { userEmail: !clearUser && userEmail },
      })
    }

    init()
  }, [clearUser]) // eslint-disable-line

  if (signingOut) return <Loading />

  return null
})
