import moment from 'moment'
import { isTeenager, setStudentInfo, welcomePath } from 'students/helper'

const isTodayOnVacationPeriod = (starts, ends) => moment().isBetween(starts, ends, 'day', '[]')

const isInSchoolYear = (yearStartDate = '2023-09-01', yearEndDate = '2024-06-28') => {
  const today = moment()
  if (moment(yearStartDate).isBefore(yearEndDate)) {
    return today.isBetween(yearStartDate, yearEndDate, 'day', '[]')
  }
  if (moment(yearStartDate).isAfter(yearEndDate)) {
    return today.isAfter(yearStartDate)
  }
}

export function isSchoolOnVacation(school) {
  if (!school) return false
  const { yearStartDate, yearEndDate } = school
  if (!isInSchoolYear(yearStartDate, yearEndDate)) return true
  const hasVacation = school?.vacationEndsAt && school?.vacationStartsAt
  if (!hasVacation) return false
  return isTodayOnVacationPeriod(school.vacationStartsAt, school.vacationEndsAt)
}

export function getPathBasedOnGrade({ id, grade }) {
  return isTeenager(grade) ? `/check-in/${id}/on-vacation` : '/on-vacation'
}

export const handleStudentRoutes = (studentUser, nextRoute = '') => {
  if (!studentUser?.student) return '/sign-out?clear=true'

  const { student, school } = studentUser
  setStudentInfo({ studentId: student.id, grade: student.grade })

  const isOnVacation = isSchoolOnVacation(school)

  if (isOnVacation) return getPathBasedOnGrade(student)

  return nextRoute || welcomePath(student)
}

const helper = {
  isSchoolOnVacation,
  getPathBasedOnGrade,
  handleStudentRoutes,
}

export default helper
