import React from 'react'
import FLOWS from 'checkIn/flows'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FiveToEight from './FiveToEight'

const SelectColor: RouteComponent = () => {
  const { student } = useCheckIn()

  if (!student) return null

  return <FiveToEight nextFunction={FLOWS.teen['start'].nextFunction} />
}

export default SelectColor
