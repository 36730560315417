import React, { InputHTMLAttributes } from 'react'
import { InputContainer, Label, StyledInput } from './styled'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label: string
}

export const TextInput = ({ label, ...rest }: Props) => (
  <InputContainer>
    <Label>{label}</Label>
    <StyledInput type="text" {...rest} />
  </InputContainer>
)
