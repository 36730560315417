import React from 'react'
import { remove } from 'lodash'

/**
 * Generic component to align elements around the selected one
 *
 * @example
 * <CircularElements startDeg={-45} endDeg={290} distance="9em">
 *  <CircularElements.Item selected>
 *    {childContent}
 *  </CircularElements.Item>
 *  <CircularElements.Item>
 *    {childContent}
 *  </CircularElements.Item>
 * </CircularElements>
 */
const CircularElements = ({
  style = {},
  className = '',
  startDeg = 0,
  endDeg = 0,
  distance = '0',
  children,
}) => {
  const childrenArray = React.Children.toArray(children)
  const selectedElement = remove(childrenArray, (child) => child.props.selected)
  const { length } = childrenArray

  const childrenWithProps = childrenArray.map((child, index) => {
    const newProps = {
      index,
      length,
      startDeg,
      endDeg,
      distance,
    }

    return React.cloneElement(child, newProps)
  })

  return (
    <div className={className} style={{ position: 'relative', ...style }}>
      {selectedElement}
      {childrenWithProps}
    </div>
  )
}

/**
 * Child element to generate the circular elements
 */
export const CircularItem = ({
  style = {},
  index = 0,
  length = 0,
  startDeg = 0,
  endDeg = 0,
  distance = '0',
  selected,
  children,
}) => {
  const itemStyle = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transformStyle: 'preserve-3d',
    transform: 'translate(-50%, -50%)',
    ...style,
  }

  if (selected) {
    return <div style={itemStyle}>{children}</div>
  }

  const getItemDegree = (index, length, startDeg, endDeg) => {
    const deltaDeg = endDeg - startDeg
    const deltaIndex = index / length

    return deltaDeg * deltaIndex + startDeg
  }

  const degrees = getItemDegree(index, length, startDeg, endDeg)
  itemStyle.transform = `${
    itemStyle.transform
  } rotate(${degrees}deg) translate(${distance}) rotate(${degrees * -1}deg) `

  return (
    <div key={index} style={itemStyle}>
      {children}
    </div>
  )
}

CircularElements.Item = CircularItem

export default CircularElements
