import React from 'react'

import { DashboardScreenV2 } from 'components/DashboardScreenV2'
import validateUserFields from 'auth/validateUserFields'

import type { User } from 'types'

type Props = { currentUser: User; schoolId: string }
const VerifyEmail = ({ currentUser, schoolId }: Props) => {
  const queryString = new URLSearchParams(window.location.search)
  const sid = queryString.get('sid')
  return (
    <DashboardScreenV2
      currentUser={currentUser}
      minimal={true}
      propsHeader={{
        propsMenuSchool: {
          hidden: true,
          shoAllPremiumSchools: false,
          navigateOnChange: true,
          schoolIdFromURL: schoolId,
          navigateOnChangeFn(school) {
            return `/v2/schools/${school}/home`
          },
        },
      }}
    >
      <iframe
        src={`${process.env.REACT_APP_API_URL}/app/identity/email_verification?sid=${sid}`}
        className="w-full  max-h-[calc(100vh-92px)] min-h-[calc(100vh-92px)]"
        title="Verify Email"
      />
    </DashboardScreenV2>
  )
}

export default validateUserFields(VerifyEmail)
