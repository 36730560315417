import React, { useMemo, useEffect, useState } from 'react'
import { useCroods } from 'croods'
import { navigate } from '@reach/router'
import { useMixpanel } from 'mixpanel'
import { capitalize } from 'lodash'
import { Tabs, Tab, Button } from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import validateUserFields from 'auth/validateUserFields'
import CheckIfIsParent from 'auth/isParent'
import Filters from 'Educators/students/list/Filters'
import NeedsFollowUp from 'Educators/students/list/tabs/NeedsFollowUp'
import CompletedFollowUps from 'Educators/students/list/tabs/CompletedFollowUps'
import TabLabelWithBadge from 'Educators/students/list/TabLabelWithBadge'
import { groupByCheckIn } from 'Educators/groupByCheckIn'
import { useSchools } from 'Educators/SchoolsContext'
import { joinStudentsAndCheckins } from 'Educators/filters'
import AuthLoading from 'components/AuthLoading'
import { DashboardScreen } from 'components/DashboardScreen'
import { isEducator } from 'students/helper'
import { TodayCheckIn } from '../students/list/tabs/TodayCheckIn'

export const handleChangeFilters = (onChange) => (data) => onChange(data)
export const handleClose = (setDrawer) => () => setDrawer(false)
export const handleOpen = (setDrawer) => () => setDrawer(true)

const tab = {
  todayCheckIns: "Today's Check-ins",
  needsFollowUp: 'Needs Follow-up',
  completedFollowUps: 'Completed Follow-ups',
}

export const EducatorDashboard = validateUserFields(
  ({ initialStateCountNeedsFollowUp = 0, currentUser, ...props }) => {
    const mixpanel = useMixpanel()
    const { schoolId } = useSchools()
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [filters, setFilters] = useState(null)
    const [currentTab, setCurrentTab] = useState('todayCheckIns')
    const [countNeedsFollowUp, setCountNeedsFollowUp] = useState(initialStateCountNeedsFollowUp)

    const [{ list: students = [], fetchingList: fetchingStudents }, { fetch: fetchStudents }] =
      useCroods({
        name: 'students',
      })

    const [{ list: checkIns = [], fetchingList: fetchingCheckIns }, { fetch: fetchCheckIns }] =
      useCroods({
        name: 'checkIns',
      })

    const [{ list: classrooms = [] }, { fetch: fetchClassroom }] = useCroods({
      cache: true,
      stateId: schoolId,
      name: 'classrooms',
      customPath: `/schools/${schoolId}/classrooms`,
    })

    const fetchStudentAndCheckInList = () => {
      const educatorUser = isEducator(currentUser.role)
      if (educatorUser && !schoolId) return null
      if (educatorUser) {
        schoolId && fetchStudents({ customPath: `schools/${schoolId}/students` })()
        schoolId && fetchCheckIns({ customPath: `schools/${schoolId}/check_ins` })()
      } else {
        fetchStudents({ customPath: 'students' })()
        fetchCheckIns({ customPath: 'check_ins' })()
      }
    }

    const allStudentsAndCheckIns = joinStudentsAndCheckins(checkIns, students)

    const isParent = CheckIfIsParent(currentUser)
    const childReference = isParent ? 'KID' : 'STUDENT'

    const handleTabChange = (value) => {
      mixpanel.track(`${tab[value]} Clicked`)
      setCurrentTab(value)
    }

    useEffect(() => {
      if (schoolId) {
        navigate(`/v2/schools/${schoolId}/home`)
      }
    }, [schoolId]) // eslint-disable-line

    useEffect(() => {
      if (isEducator(currentUser.role) && schoolId) {
        fetchStudentAndCheckInList()
        fetchClassroom()()
      } else {
        schoolId || fetchStudentAndCheckInList()
      }
    }, [schoolId]) // eslint-disable-line

    useEffect(() => {
      mixpanel.track('View Dashboard')
    }, []) // eslint-disable-line

    const allStillNeedsHelp = useMemo(
      () => groupByCheckIn(allStudentsAndCheckIns),
      [allStudentsAndCheckIns],
    )

    useEffect(() => {
      setCountNeedsFollowUp(allStillNeedsHelp?.length ?? 0)
    }, [allStillNeedsHelp])

    return (
      <DashboardScreen {...props} currentUser={currentUser}>
        <div
          style={{
            width: '80vw',
            minWidth: '300px',
            position: 'relative',
            margin: '0 auto',
          }}
        >
          <div className="flex flex-wrap items-start justify-between mb-6">
            <h2 className="text-4xl text-secondary">{`My ${capitalize(childReference)}s`}</h2>
            <div className="flex flex-col items-end flex-grow gap-4 text-primary">
              {isParent ? (
                <Button
                  variant="outlined"
                  color="primary"
                  endIcon={<PersonAddIcon />}
                  onClick={() => navigate('/dashboard/students/new')}
                >
                  Add Child
                </Button>
              ) : null}

              {fetchingCheckIns ? null : (
                <Filters
                  showCheckinFields
                  isDashboard
                  isOpen={drawerOpen}
                  onClose={handleClose(setDrawerOpen)}
                  onOpen={handleOpen(setDrawerOpen)}
                  childReference={childReference}
                  filters={filters}
                  list={students}
                  onChange={handleChangeFilters(setFilters)}
                  classrooms={classrooms.map(({ id, name }) => ({
                    label: name,
                    value: id,
                  }))}
                />
              )}
            </div>
          </div>
          <Tabs
            value={currentTab}
            style={{
              borderBottomWidth: 0.5,
              borderBottomStyle: 'solid',
              borderBottomColor: 'rgba(0, 0, 0, 0.38)',
            }}
            onChange={(_, value) => handleTabChange(value)}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab label="TODAY'S CHECK-INS" value="todayCheckIns" />
            <Tab
              value="needsFollowUp"
              label={
                <TabLabelWithBadge label="STILL NEEDS HELP" badgeContent={countNeedsFollowUp} />
              }
            />
            <Tab label="COMPLETED FOLLOW-UPS" value="completedFollowUps" />
          </Tabs>
          {allStudentsAndCheckIns.length === 0 && (fetchingStudents || fetchingCheckIns) ? (
            <AuthLoading />
          ) : (
            <>
              {currentTab === 'todayCheckIns' && (
                <TodayCheckIn
                  students={allStudentsAndCheckIns}
                  filters={filters}
                  childReference={childReference}
                  currentUser={currentUser}
                  fetchListStudents={fetchStudentAndCheckInList}
                />
              )}

              {fetchingCheckIns ? (
                <AuthLoading />
              ) : (
                <>
                  {currentTab === 'needsFollowUp' && (
                    <NeedsFollowUp
                      allStillNeedsHelp={allStillNeedsHelp}
                      filters={filters}
                      currentUser={currentUser}
                      fetchListStudents={fetchStudentAndCheckInList}
                    />
                  )}

                  {currentTab === 'completedFollowUps' && (
                    <CompletedFollowUps
                      studentsWithAllCheckIns={allStudentsAndCheckIns}
                      filters={filters}
                      currentUser={currentUser}
                      fetchListStudents={fetchStudentAndCheckInList}
                    />
                  )}
                </>
              )}
            </>
          )}
        </div>
      </DashboardScreen>
    )
  },
)
