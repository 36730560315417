import React from 'react'
import { Button } from '@material-ui/core'
import { Email as EmailIcon } from '@material-ui/icons'

import { navigate } from '@reach/router'

const InviteEducators = () => (
  <Button
    variant="outlined"
    color="primary"
    endIcon={<EmailIcon />}
    onClick={() => navigate('/dashboard/educators/import')}
  >
    REGISTER EDUCATOR(S)
  </Button>
)

export default InviteEducators
