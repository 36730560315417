import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const styles = (theme) => ({
  image: {
    position: 'fixed',
    left: 0,
    bottom: 0,
    height: 'auto',
    [theme.breakpoints.down('md')]: {
      height: '50vh',
    },
    [theme.breakpoints.down('sm')]: {
      height: '40vh',
    },
    [theme.breakpoints.down('xs')]: {
      height: '30vh',
    },
  },
})

const cadetImage = ({ classes, src, alt, style, className }) => (
  <img
    className={clsx([classes.image, className])}
    src={src}
    alt={alt}
    style={style}
  />
)

export default withStyles(styles)(cadetImage)
