import React, { useState } from 'react'
import type { RouteComponent } from 'types'
import Screen from '../../components/screen'

import { navigateWithParams } from 'utils'
import { NextButton } from '../../components/NextButton'
import { setLocalStorageCheckIn } from 'students/helper'

const ImpactQ4: RouteComponent = () => {
  const [selectedOption, setSelectedOption] = useState<number | null>(null)

  const onClick = (number: number) => {
    setLocalStorageCheckIn('impactQ4', number)
    setSelectedOption(number)
  }
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl w-full text-center text-gray-100 text-2xl sm:text-[32px] font-normal font-['Roboto'] leading-loose mb-8 sm:mb-16">
        On a scale from 1 (really hard) to 5 (very easy), how it is to you understand your moods and
        feelings?
      </div>
      <div className="flex flex-col sm:flex-row gap-10 mb-8 sm:mb-20">
        {[...Array(5)].map((_, i) => {
          const value = i + 1
          return (
            <button
              key={value}
              className={`text-white h-14 min-w-14 flex items-center  justify-center border-2 font-bold tracking-button uppercase text-xl leading-5  bg-cover bg-no-repeat bg-center rounded-full shadow-sm hover:shadow [text-shadow:_0_1px_0_rgb(0_0_0_/_40%)] hover:bg-gradient-to-r hover:from-white/20 hover:to_white/0 data-[selected=true]:bg-gray-100 data-[selected=true]:text-zinc-600 data-[selected=true]:[text-shadow:none]`}
              data-selected={value === selectedOption}
              onClick={() => onClick(value)}
            >
              {value}
            </button>
          )
        })}
      </div>
      <NextButton
        disabled={!selectedOption}
        handleNextFunction={() => navigateWithParams('./impactQ5')}
      ></NextButton>
    </Screen>
  )
}

export default ImpactQ4
