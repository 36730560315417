import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import NineToTwelve from './NineToTwelve'
import { navigateWithParams } from 'utils'
import isComfy from 'emotions'
import { getQSData } from 'QueryStringController'

const BodySensations: RouteComponent = () => {
  const { student } = useCheckIn()

  const nextFunction = (fuel: string) => {
    const { feeling } = getQSData()
    const positiveResult = ['green', 'yellow'].includes(fuel) && isComfy(feeling)
    const route = positiveResult ? './summary' : './questions'
    navigateWithParams(route, { energyLevel: fuel })
  }

  if (student) {
    return <NineToTwelve handleNextFunction={nextFunction} />
  }

  return null
}

export default BodySensations
