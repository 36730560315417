import React from 'react'
import Teen from 'checkIn/steps/ChooseActivity/Teen'
import Kid from 'checkIn/steps/ChooseActivity/Kid'
import NonReader from 'checkIn/steps/ChooseActivity/NonReader'
import type { RouteComponent } from 'types'
import { setQSData } from 'QueryStringController'

import { navigate } from '@reach/router'
import { useActivity } from 'activities/activityContext'
import { isNinthGradeOrOlder } from 'students/helper'
import NineToTwelve from 'checkIn/nineToTwelve/Activities/Standalone'

const ChooseActivity: RouteComponent = () => {
  const { student } = useActivity()

  const label = 'Choose an activity:'

  if (student) {
    const selectActivityFunction = (value: string) => {
      setQSData({ activity: value })
      const params = window.location.search
      navigate(`/activities/${student.id}/${value}${params}`)
    }

    switch (student.studentType) {
      case 'teen':
        return isNinthGradeOrOlder(student.grade) ? (
          <NineToTwelve />
        ) : (
          <Teen selectActivityFunction={selectActivityFunction} label={label} />
        )
      case 'kid':
        return <Kid selectActivityFunction={selectActivityFunction} label={label} />
      case 'non-reader':
        return (
          <NonReader
            energizedAndComfy={true}
            selectActivityFunction={selectActivityFunction}
            label={label}
          />
        )
    }
  }

  return null
}

export default ChooseActivity
