import React from 'react'
import { AdminScreen } from './components/AdminScreen'
import { P } from './components/styled'

export const CsDashboard = (props: any) => (
  <AdminScreen title="Closegap ADMIN (CS Dashboard)" {...props}>
    <P style={{ alignSelf: 'center' }}>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi et magna justo. Curabitur
      fermentum convallis nisi, eget convallis est vehicula eget. Quisque ultricies suscipit
      iaculis. Nulla augue sapien, viverra at velit feugiat, elementum iaculis neque. Pellentesque
      sit amet diam id magna auctor scelerisque. Suspendisse molestie pretium lorem non ornare. Duis
      odio quam, bibendum ut metus et, tincidunt fermentum diam. Morbi non tortor felis. Orci varius
      natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Suspendisse gravida
      porttitor elit, sed interdum purus porttitor vitae. Maecenas lectus metus, consectetur at
      ultricies at, condimentum blandit arcu. Donec quis enim nunc. Nunc ut felis non elit iaculis
      sagittis.
    </P>
  </AdminScreen>
)
