import React from 'react'
import { navigate } from '@reach/router'

import MinimumPercentage from 'Educators/students/insights/SurfacingStudents/MinimumPercentage'
import { Skeleton } from '@material-ui/lab'

const Filter = ({
  studentType,
  filter,
  loading,
  disabled,
  children,
  numberOfStudents,
  percentageOfStudents,
  minimumPercentage,
  handleMinimumPercentage,
  hideCriteria,
  indexTab,
}) => (
  <div className="flex flex-col items-baseline gap-10 text-gray-500 md:gap-24 md:flex-row">
    <div className="flex-auto">
      <p className="mb-1.5">Given the filters above, show me students who indicated</p>
      <div className="flex flex-col items-start gap-5 md:items-center md:justify-evenly md:gap-10 md:flex-row">
        {children}
        <p className="self-start md:self-end">over</p>
        <MinimumPercentage
          label="%"
          disabled={disabled}
          minimumPercentage={minimumPercentage}
          handleMinimumPercentage={handleMinimumPercentage}
        />
        <p className="self-start md:self-end md:whitespace-nowrap">of the time.</p>
      </div>
    </div>
    <div className="self-start flex-1 md:self-end">
      {!loading && !hideCriteria && (
        <div>
          <div>
            <p className="text-5xl text-secondary">{percentageOfStudents}%</p>
            <p className="text-sm text-secondary">
              of your {numberOfStudents} students match the criteria
            </p>
          </div>

          {indexTab === 0 ? null : (
            <div className="mt-8">
              <div>
                <button
                  type="button"
                  className="button"
                  onClick={() => {
                    const alertsFilter = {
                      grades: filter.grades,
                      classroomId: filter.classroom || '',
                      englishLanguageLearner: filter.english_language_learner,
                      educationPlan504: filter.education_plan504,
                      individualizedEducationProgram: filter.individualized_education_program,
                      freeReducedPriceMeals: filter.free_reduced_price_meals,
                      ethnicity: filter.ethnicity || '',
                      livingSituation: filter.living_situation || '',
                      kind: '',
                      condition: '',
                      frequency: '',
                    }

                    navigate('/dashboard/students/insights/alerts', {
                      state: {
                        studentType,
                        filter: alertsFilter,
                      },
                    })
                  }}
                >
                  Want to set an alert?
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {loading && (
        <div data-testid="skeleton">
          <Skeleton variant="rect" height={50} />
          <Skeleton variant="text" height={30} />
        </div>
      )}
    </div>
  </div>
)

export default Filter
