import React from 'react'
import { Layout } from '../components/Layout'
import { LogInHereMessage } from '../components/LogInHereMessage'
import { SubTitle } from '../components/styled'
import { LinksSection } from './LinksSection'
import { BottomLine, Content, FooterImage, Title, ImageContainer } from './style'
import { RouteComponent } from 'types'

export const GetStarted: RouteComponent = () => (
  <Layout>
    <Title>Welcome to Closegap</Title>

    <Content>
      <SubTitle>Get started as a...</SubTitle>
      <LinksSection />
      <LogInHereMessage />
    </Content>

    <ImageContainer>
      <FooterImage />
      <BottomLine />
    </ImageContainer>
  </Layout>
)
