import React from 'react'
import clsx from 'clsx'

const Card = ({
  isAccentColor = false,
  light = false,
  title,
  children,
  CardHeaderAction = null,
  className = '',
  propsMain = {},
}) => (
  <div className={clsx('card mt-10', className)}>
    {title && (
      <header
        className={clsx(
          'flex justify-between h-14 py-4 px-10 items-center',
          isAccentColor ? 'bg-purple' : 'bg-gray-200',
          light && 'bg-opacity-50',
        )}
      >
        <h6
          className={clsx(
            'text-sm whitespace-nowrap truncate',
            isAccentColor
              ? 'font-semibold text-white'
              : 'font-medium text-gray-500',
          )}
        >
          {title}
        </h6>
        {CardHeaderAction}
      </header>
    )}
    <main className="p-10 pt-0" {...propsMain}>
      {children}
    </main>
  </div>
)

export default Card
