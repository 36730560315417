import React, { useEffect, useState } from 'react'
// @ts-ignore
import { useForm, SubmitHandler, useWatch } from 'react-hook-form'
// @ts-ignore
import { reduxForm } from 'redux-form'
import { ErrorMessage } from 'components/Dropdown/styles'
import {
  Container,
  InputContainer,
  Label,
  StyledInput,
} from 'auth/signUpFlow/components/Input/style'
import { Loader } from 'components/Loader'
import { Button, InternalLink } from 'auth/signUpFlow/components/styled'
import { LoadingContainer } from 'auth/signUpFlow/SignUpSchoolStaff/style'
import GoogleLogin from 'react-google-login'
import Error from 'components/form/Error'
import { navigate } from '@reach/router'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import withUserEmail from './withUserEmail'
import withMixpanel from 'mixpanel/withMixpanel'
import { EmailInput, ValuesProps } from 'auth/signUpFlow/components/Input'
import { redirectRoute } from './SignIn'

export const injectUsername = (create: any, userEmail: any) => (data: any) => {
  create(
    userEmail
      ? {
          email: userEmail,
          ...data,
        }
      : data,
  )
}

const Form = ({ mixpanel, userEmail, create, creating, apiError, nextRoute }: any) => {
  const INITIAL_STATE = { email: userEmail, password: '' }
  const [, setCurrentUser] = useCurrentUser()
  const [{ saving, saveError }, { save }] = useCroods({
    name: 'login',
    path: 'auth/google',
    afterSuccess: ({ data }) => {
      setCurrentUser(data)
      navigate(redirectRoute(data, nextRoute), { replace: true })
    },
  })

  const [googleFailure, setGoogleFailure] = useState(null)

  const onGoogleSuccess = (response: any) => {
    const { id_token } = response.tokenObj // eslint-disable-line
    save({ method: 'POST' })({ id_token })
  }

  const clearAnyPreviousGoogleError = () => setGoogleFailure(null)

  const onGoogleFailure = (res: any) => setGoogleFailure(res.error)

  const clientId = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || ''

  useEffect(() => {
    if (userEmail) {
      mixpanel.identify(userEmail)
      mixpanel.track('View Re-Enter Password')
    } else {
      mixpanel.track('View Sign In')
    }
  }, [mixpanel, userEmail])

  const {
    register,
    handleSubmit,
    control,
    formState: { errors, dirtyFields, isValid },
  } = useForm<ValuesProps>({
    defaultValues: INITIAL_STATE,
    mode: 'onChange',
    reValidateMode: 'onChange',
  })
  const email = useWatch({ name: 'email', control })
  const password = useWatch({ name: 'password', control })

  const onSubmit: SubmitHandler<ValuesProps> = (data) => injectUsername(create, userEmail)(data)
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
    >
      {!userEmail && <EmailInput email={email} errors={errors} register={register} />}

      <Container>
        <InputContainer>
          <StyledInput
            invalid={!!errors.password}
            type="password"
            placeholder="Password *"
            {...register('password', {
              required: { value: true, message: 'Password is required' },
            })}
          />
          {Boolean(password) && <Label invalid={Boolean(errors.password)}>Password *</Label>}
        </InputContainer>
        {Boolean(errors.password) ? <ErrorMessage>{errors.password?.message}</ErrorMessage> : null}
      </Container>

      <InternalLink to="/forgot-password">Forgot your password?</InternalLink>

      {creating ? (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      ) : null}

      {Boolean(apiError) ? <Error>{apiError}</Error> : null}

      <Button type="submit" disabled={Object.keys(dirtyFields).length === 0 || !isValid}>
        LOG IN
      </Button>

      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          fontFamily: 'Inter',
          fontStyle: 'normal',
          fontWeight: '600',
          fontSize: '1.5rem',
          textAlign: 'center',
          color: '#A1A1A1',
        }}
      >
        <hr
          style={{
            height: '0px',
            width: '100%',
            border: '2px solid #D3D3D3',
            borderRadius: '8px',
          }}
        />
        or
        <hr
          style={{
            height: '0px',
            width: '100%',
            border: '2px solid #D3D3D3',
            borderRadius: '8px',
          }}
        />
      </div>

      <div style={{ width: '100%', margin: '1rem 0' }} />

      <GoogleLogin
        style={{ width: '100%' }}
        theme="dark"
        clientId={clientId}
        buttonText="Sign-in with Google"
        onRequest={clearAnyPreviousGoogleError}
        onSuccess={onGoogleSuccess}
        onFailure={onGoogleFailure}
      />

      {saving ? (
        <LoadingContainer>
          <Loader />
        </LoadingContainer>
      ) : null}
      {saveError ? <Error>{saveError}</Error> : null}

      {Boolean(googleFailure) ? (
        <Error>
          Google error:
          <br />
          {/* @ts-ignore */}
          <span style={{ fontSize: '1.2rem' }}>{googleFailure?.replace(/_/g, ' ')}</span>
        </Error>
      ) : null}
    </form>
  )
}

export default reduxForm({
  form: 'signIn',
})(withMixpanel(withUserEmail(Form)))
