import { ToastContainer } from 'react-toastify'
import styled from 'styled-components'

import { colors } from 'theme'
import 'react-toastify/dist/ReactToastify.css'

export const StyledToast = styled(ToastContainer)`
  text-align: left;
  .Toastify__toast--info {
  }
  .Toastify__toast--success {
    background: ${() => colors.success};
  }
  .Toastify__toast--warning {
    background: lightyellow;
    color: ${() => colors.carbonDark};
  }
  .Toastify__toast--error {
    background: #da001a;
  }
`
