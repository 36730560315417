import React from 'react'
import { reduxForm } from 'redux-form'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import Label from 'components/FormLabel'
import SubmitButton from 'components/FormSubmitButton'
import ResetPasswordButton from 'components/ResetPasswordButton'
import isParent from 'auth/isParent'
import moment from 'moment'
import withMixpanel from 'mixpanel/withMixpanel'
import { useSchools } from 'Educators/SchoolsContext'
import {
  FirstName,
  MiddleName,
  LastName,
  Birthdate,
  Gender,
  RaceEthnicity,
  Grade,
  EmailAddress,
  EnglishLanguageLearner,
  IndividualizedEducationProgram,
  EducationalPlan,
  FreeReducedPriceMeals,
  LivingSituation,
} from './Fields'

const isYes = (value) => value === 'yes'

const injectionSubmit = (create, mixpanel) => (values) => {
  const fieldsData = {
    ...values,
    englishLanguageLearner: isYes(values.englishLanguageLearner),
    individualizedEducationProgram: isYes(values.individualizedEducationProgram),
    educationPlan504: isYes(values.educationPlan504),
    freeReducedPriceMeals: isYes(values.freeReducedPriceMeals),
    birthdate: moment(new Date(values.birthdate)).utc().format(),
  }
  mixpanel && mixpanel.track('Individual Student Added', { ...fieldsData })
  create(fieldsData)
}

const StudentForm = withMixpanel(
  ({
    submitting,
    submitError,
    invalid,
    isEdit,
    currentUser = {},
    initialValues,
    student,
    error,
    onSave,
    mixpanel,
    handleSubmit,
  }) => {
    const { schoolId, isSchoolOwner } = useSchools()

    const isOwner = isEdit && isSchoolOwner() && schoolId === initialValues?.schoolId

    const isShared = isEdit && !isOwner

    const childReference = isParent(currentUser) ? 'Kid' : 'Student'

    return (
      <>
        <Label>
          {childReference} Information
          {isShared && (
            <Typography color="textSecondary" variant="caption">
              {' '}
              Only school owners can edit this {childReference.toLowerCase()}’s details
            </Typography>
          )}
        </Label>
        <form onSubmit={handleSubmit(injectionSubmit(onSave, mixpanel))}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12} md={6}>
              <FirstName disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <MiddleName disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LastName disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Birthdate disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Gender disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <RaceEthnicity disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <EnglishLanguageLearner disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <IndividualizedEducationProgram disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <EducationalPlan disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <FreeReducedPriceMeals disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grade disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LivingSituation disabled={isShared} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <EmailAddress disabled={isShared} />
            </Grid>
          </Grid>

          {!isShared && (
            <Grid container justifyContent="flex-end" style={{ marginBottom: 8 }}>
              {isEdit && (
                <ResetPasswordButton
                  student={student}
                  buttonText="RESET PASSWORD"
                  buttonAreaStyle={{ maxWidth: '140px' }}
                />
              )}

              <SubmitButton
                submitting={submitting}
                reduxFormError={error}
                submitError={submitError}
                disabled={invalid}
                buttonText={isEdit ? 'SAVE CHANGES' : `ADD ${childReference.toUpperCase()}`}
              />
            </Grid>
          )}
        </form>
      </>
    )
  },
)

export default reduxForm({ form: 'students', enableReinitialize: true })(StudentForm)
