import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import map from 'lodash/map'
import capitalize from 'lodash/capitalize'
import lowerCase from 'lodash/lowerCase'

const styles = ({ spacing }) => ({
  grid: {
    marginBottom: spacing(1),
  },
  feeling: {
    fontSize: 24,
    color: '#353535',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '28px',
  },
  feel: {
    fontSize: 12,
    color: '#808080',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
    marginRight: spacing(2),
  },
  secondaryEmotions: {
    textTransform: 'capitalize',
    fontSize: 14,
    color: '#4e4e4e',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '16px',
    marginLeft: spacing(2),
  },
  separator: {
    paddingLeft: spacing(5),
  },
})

const StudentResponse = withStyles(styles)(
  ({ classes, feeling, secondaryEmotions, bodySensations, ...props }) => (
    <Grid className={classes.separator} {...props}>
      <Grid container alignItems="center" className={classes.grid}>
        <Typography className={classes.feeling}>
          {capitalize(lowerCase(feeling))}
        </Typography>
        {map(secondaryEmotions, (emotion) => (
          <Typography key={emotion} className={classes.secondaryEmotions}>
            {capitalize(lowerCase(emotion))}
          </Typography>
        ))}
      </Grid>
      <Grid container alignItems="center" className={classes.grid}>
        {map(bodySensations, (sensation) => (
          <Typography key={sensation} className={classes.feel}>
            {capitalize(lowerCase(sensation))}
          </Typography>
        ))}
      </Grid>
    </Grid>
  ),
)

export default StudentResponse
