import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'

import { useSchools } from 'Educators/SchoolsContext'
import { error } from 'components/Toast'
import { useCroods } from 'croods'
import { PrimaryButton, SecondaryButton } from '../../Components/buttons'
import HelperCadet from '../../Components/helperCadet'
import { navigate } from '@reach/router'

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '16px',
  },
  soloHeading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '40px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '40px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  button: {
    width: '100%',
  },
}))

const NewSchoolYearClearClassrooms = ({ nextStepFn }: { nextStepFn: Function }) => {
  const classes = useStyles()
  const { school } = useSchools()
  const [chosenAutomatic, setChosenAutomatic] = React.useState(false)
  const [{ saving }, { save }] = useCroods({
    name: 'scheduleAutomaticRosterCall',
    customPath: `schools/${school.id}/setup_edlink`,
    afterResponse({ status }) {
      if (status === 200) {
        setChosenAutomatic(true)
      } else {
        error('Error')
      }
    },
  })

  const handleManualProcess = () => {
    navigate('/manage-school/new-school-year/returning-school/set-up')
  }

  return (
    <Container className={classes.container}>
      <HelperCadet></HelperCadet>
      {chosenAutomatic ? (
        <>
          <Typography className={classes.heading}>
            Great, we're so excited to get started!
          </Typography>
          <Typography className={classes.subTitle}>
            A member of our team will reach out to schedule a meeting to set up the automatic
            process.
            <br />
            <br />
            In the meantime, let's set your calendar for the school year ahead.
          </Typography>

          <PrimaryButton disabled={saving} loading={saving} onClick={() => nextStepFn()}>
            Continue
          </PrimaryButton>
        </>
      ) : (
        <>
          <Typography className={classes.soloHeading}>
            How would you like to roster your school or district
          </Typography>

          <PrimaryButton
            disabled={saving}
            loading={saving}
            onClick={() => save({ method: 'PUT' })()}
          >
            AUTOMATIC ROSTER
          </PrimaryButton>
          <SecondaryButton disabled={saving} onClick={handleManualProcess}>
            Use the .csv template
          </SecondaryButton>
        </>
      )}
    </Container>
  )
}

export default NewSchoolYearClearClassrooms
