import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { PieChart, Pie, Cell, Tooltip } from 'recharts'
import map from 'lodash/map'

import filter from 'lodash/filter'
import TooltipContent from './Tooltip'

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
    fontSize: '14px',
  },
})

const renderCustomizedLabelLine = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  startAngle,
  endAngle,
}) => {
  const RADIAN = Math.PI / 180
  const diffAngle = endAngle - startAngle
  const radius = innerRadius + (outerRadius - innerRadius)
  let path = ''
  // eslint-disable-next-line
  for (let i = 0; i < (360 - diffAngle) / 35; i++) {
    path += `${cx + (radius + i) * Math.cos(-midAngle * RADIAN)},${
      cy + (radius + i * i) * Math.sin(-midAngle * RADIAN)
    } `
  }
  return <polyline points={path} stroke="#808080" fill="none" />
}

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  color,
  startAngle,
  endAngle,
  feeling,
  percentage,
}) => {
  const RADIAN = Math.PI / 180
  const diffAngle = endAngle - startAngle
  const delta = (360 - diffAngle) / 35
  const radius = innerRadius + (outerRadius - innerRadius)
  const x = cx + (radius + delta) * Math.cos(-midAngle * RADIAN)
  const y = cy + (radius + delta * delta) * Math.sin(-midAngle * RADIAN)
  return (
    <text
      x={x}
      y={y}
      fill={color}
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      fontWeight="normal"
    >
      {`${feeling} (${percentage}%)`}
    </text>
  )
}

const EmotionsChart = withStyles(styles)(({ classes, data, width }) => {
  const list = filter(data, (item) => item.percentage > 0)
  return (
    <>
      <Typography className={classes.title} color="textSecondary">
        EMOTIONS
      </Typography>
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div style={{ display: 'flex', alignSelf: 'center' }}>
          <PieChart width={isWidthUp('md', width) ? 700 : 375} height={500}>
            <Pie
              data={list}
              labelLine={renderCustomizedLabelLine}
              label={renderCustomizedLabel}
              outerRadius={isWidthUp('md', width) ? 120 : 80}
              fill="#8884d8"
              dataKey="percentage"
              isAnimationActive={false}
              minAngle={15}
            >
              {map(list, (entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.kind === 'confortable' ? '#9B94B9' : '#250032'}
                />
              ))}
            </Pie>
            <Tooltip content={<TooltipContent />} />
          </PieChart>
        </div>
      </div>
    </>
  )
})

export default withWidth()(EmotionsChart)
