import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core'
import Row from 'Educators/students/insights/TableStudents/Row'
import isEmpty from 'lodash/isEmpty'
import map from 'lodash/map'
import clsx from 'clsx'

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  table: {
    width: '100%',
  },
  label: {
    color: theme.colors.trolleyGray,
    fontWeight: 400,
  },
  columnName: {
    padding: theme.spacing(4, 0, 4, 0),
  },
})

const TableStudents = withStyles(styles)(({ classes, data }) => (
  <div className={classes.root}>
    <Table className={clsx(classes.table, 'page-break-to-print')}>
      <TableHead>
        <TableRow>
          <TableCell component="th" scope="row" className={classes.columnName}>
            <Typography className={classes.label} variant="subtitle1">
              STUDENTS
            </Typography>
          </TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {map(data, ({ student }) => (
          <Row key={student.id} student={student} />
        ))}
      </TableBody>
    </Table>
    {isEmpty(data) && (
      <div className="pt-8">
        <Typography className={classes.label} variant="subtitle1" align="center">
          There’s no result for this search
        </Typography>
      </div>
    )}
  </div>
))

export default TableStudents
