import { kebabCase, map, mapKeys, mapValues } from 'lodash'
import { getQSData } from 'QueryStringController'
import { getCheckInIdempotencyKey, getLocalStorageCheckIn } from 'students/helper'

type RenameKey = {
  energyLevel: string
  activity: string
  talkToAnAdult: string
  preferredContact: string
}
const renameKey = {
  energyLevel: 'fuel',
  activity: 'intervention',
  talkToAnAdult: 'talkToAdult',
  preferredContact: 'preferredContactId',
} as RenameKey

const renameAndParseData = (data: object): Record<string, any> => {
  const newData = mapValues(data, (item) => {
    if (item === 'true') return true
    if (item === 'false') return false
    const num = parseInt(item)
    return Boolean(num) ? num : item
  })

  return mapKeys(newData, (_, key: string) => {
    const item = renameKey?.[key as keyof RenameKey]
    return item ? item : key
  })
}

const parseCheckIn = (userId: number, studentId: number) => {
  const qs = getQSData()
  const notes = getLocalStorageCheckIn('notes')
  const goal = getLocalStorageCheckIn('goal')
  const idempotencyKey = getCheckInIdempotencyKey()

  const newValues = renameAndParseData(qs)

  delete newValues.vibe

  return {
    ...newValues,
    needs: newValues.needs?.split(',') ?? [],
    preferredContactId: parseInt(newValues?.preferredContactId) ?? null,
    bodySensations: map(newValues?.bodySensations?.split(','), kebabCase) ?? [],
    secondaryEmotions: newValues?.secondaryEmotions?.split(',') ?? [],
    notes,
    goal,
    userId,
    studentId,
    minutesOffset: new Date().getTimezoneOffset(),
    idempotencyKey,
  }
}

export default parseCheckIn
