import React from 'react'
import Screen from 'checkIn/components/teen/Screen'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

type Props = {
  flow: { nextFunction: (params: string) => Promise<void> }
}

const Teen = (props: Props) => {
  const { nextFunction } = props.flow

  const handleNextFunction = () => {
    nextFunction(window.location.search)
  }

  return (
    <Screen
      label="That's great"
      title="That's great to hear! I'm glad you're doing well"
    >
      <img
        className="h-[35vh] mt-28"
        src="/images/happy_cadet_left_shadow.png"
        alt="Happy cadet"
      />
      <BackAndNextButtons nextButtonOnClick={handleNextFunction} />
    </Screen>
  )
}

export default Teen
