const planetList = [
  {
    src: '/images/planet_happy.png',
    alt: 'Happy',
    label: 'Happy',
    feeling: 'happy',
  },
  {
    src: '/images/planet_angry.png',
    alt: 'Angry',
    label: 'Angry',
    feeling: 'angry',
  },
  {
    src: '/images/planet_sad.png',
    alt: 'Sad',
    label: 'Sad',
    feeling: 'sad',
  },
  {
    src: '/images/planet_upset.png',
    alt: 'Worried',
    label: 'Worried',
    feeling: 'worried',
  },
  {
    src: '/images/planet_frustrated.png',
    alt: 'Frustrated',
    label: 'Frustrated',
    feeling: 'frustrated',
  },
  {
    src: '/images/planet_silly.png',
    alt: 'Silly',
    label: 'Silly',
    feeling: 'silly',
  },
  {
    src: '/images/planet_good.png',
    alt: 'Calm',
    label: 'Calm',
    feeling: 'calm',
  },
  {
    src: '/images/planet_tired.png',
    alt: 'Tired',
    label: 'Tired',
    feeling: 'tired',
  },
] as const

export default planetList
