import React from 'react'
import Screen from 'checkIn/components/teen/Screen'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

export const ContentByActivity = {
  breathing: {
    title: 'Let’s take 5 deep breaths.',
    text: (
      <>
        Breathe in for 5 seconds.
        <br />
        Hold for 5 seconds.
        <br />
        Breathe out for 5 seconds.
        <br />
        Hold for 5 seconds.
        <br />
        <p style={{ marginBottom: '0' }}>Repeat 5 times.</p>
      </>
    ),
  },
  'safe-space': {
    title: 'Imagine Your Safe Space.',
    text: (
      <>
        Think of a place, real or imaginary,
        <br />
        that you feel really safe in.
        <br />
        What would you see and hear in this safe place?
        <br />
        What would you feel?
        <br />
        Take some time to visualize your safe space
        <br />
        and yourself feeling relaxed and at peace in it.
      </>
    ),
  },
  'power-pose': {
    title: 'Let’s do a Power Pose.',
    text: (
      <>
        <p style={{ marginBottom: 0 }}>Stand like a starfish for 20 seconds:</p>
        Stretch your arms high in the air.
        <br />
        Stand with your legs apart.
        <br />
        Hold your head up high.
        <br />
        Breathe deep and smile!
      </>
    ),
  },
  'let-it-go': {
    title: 'Let’s let go of the tension.',
    text: (
      <>
        Make <b>a fist</b> with your hands and squeeze
        <br />
        for <b>5</b> seconds. Let them go and feel the
        <br />
        difference! Try with your <b>shoulders</b> next.
        <br />
        <b>Repeat 3 times!</b>
        <br />
        <small>
          Do any other parts of your body feel tense?
          <br />
          <b>Try with those too!</b>
        </small>
      </>
    ),
  },
  'the-ok-inventory': {
    title: 'The OK Inventory.',
    text: (
      <>
        <p style={{ marginBottom: 0 }}>
          Think about one thing in your life
          <br />
          that is <b>OK</b> right now.
        </p>
        <small>
          Start with something easy, like your best
          <br />
          friend, your chair, your elbows :)
        </small>
        <br /> <br />
        Now write down <b>5-10</b> more
        <br />
        things that are OK in your life.
        <br />
        <b>See how long your list can get!</b>
      </>
    ),
  },
  journaling: {
    title: 'Journaling.',
    text: (
      <>
        Let’s do some “stream of
        <br /> consciousness” writing for <b>3</b> minutes.
        <br /> <br />
        <small>
          Write down anything and everything -<br />
          <b>no spell check, no judgement, no stopping!</b>
        </small>
        <br /> <br />
        Just start writing and see where
        <br />
        it takes you.
      </>
    ),
  },
}

type Props = {
  nextFunction: (params: string) => void
}

const Teen = ({ nextFunction }: Props) => {
  const activityKey = window.location.pathname.split('/').pop() as keyof typeof ContentByActivity

  const { title, text } = ContentByActivity[activityKey]

  return (
    <Screen label="This might help" title={title} contentWrapperClassName="h-screen !mt-0">
      <div className="flex items-center justify-center">
        <div className="text-center">
          <div className="text-base !leading-10 tracking-wider text-white md:text-lg">{text}</div>
        </div>

        <BackAndNextButtons nextButtonOnClick={() => nextFunction(window.location.search)} />
      </div>
    </Screen>
  )
}

export default Teen
