import React from 'react'
//@ts-ignore
import { Helmet } from 'react-helmet'
//@ts-ignore
import { useForgotPassword } from 'dmcroods-auth'
import { navigate } from '@reach/router'

import Title from 'components/AuthTitle'
import ForgotPasswordForm from 'auth/forgotPassword/ForgotPasswordForm'
import Screen from '../Screen'
import { RouteComponent } from 'types'

const ResetPassword: RouteComponent = () => {
  const [{ sending, error }, send] = useForgotPassword({
    afterSuccess: () => navigate('/email-instructions'),
    redirectUrl: `${process.env.REACT_APP_WEB_URL}/new-password`,
  })

  return (
    <Screen>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <Title>Reset Your Password</Title>

      <ForgotPasswordForm apiError={error} create={send} creating={sending} />
    </Screen>
  )
}

export default ResetPassword
