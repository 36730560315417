import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { SecondaryButton } from '../components/SecondaryButton'
import { navigate } from '@reach/router'

type Props = {
  handleNextFunction: () => void
}

const NineToTwelve = ({ handleNextFunction }: Props) => {
  return (
    <Screen showSummary={false}>
      <div
        className="max-w-6xl text-center text-teal-200
text-xs font-bold uppercase font-['Roboto'] mb-4"
      >
        Thanks for trying out the Closegap Demo
      </div>
      <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        Would you like to try another check-in?
      </div>

      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-auto sm:mt-20"
      >
        Start Over
      </NextButton>
      <SecondaryButton
        additionalClasses="mt-4"
        disabled={false}
        handleNextFunction={() => navigate('/')}
      >
        Exit Demo
      </SecondaryButton>
    </Screen>
  )
}

export default NineToTwelve
