import React, { useState } from 'react'
import type { RouteComponent } from 'types'
import Screen from '../../components/screen'

import { navigateWithParams } from 'utils'
import { NextButton } from '../../components/NextButton'

const ImpactQ6: RouteComponent = () => {
  const [note, setNote] = useState('')
  const charLimit = 600
  const onClick = () => {
    // TODO: Save impact measurement
    navigateWithParams('./select-color')
  }
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl w-full text-center text-gray-100 text-2xl sm:text-[32px] font-normal font-['Roboto'] leading-loose">
        What would make you feel closer to the adults at your school?
      </div>
      <div className="max-w-5xl w-full text-center text-neutral-300 text-lg font-normal font-['Roboto'] leading-snug  mb-8 sm:mb-16">
        Share any thoughts that you have. We can't wait to hear what you think!
      </div>
      <div className="flex flex-col sm:flex-row gap-10 mb-8 sm:mb-20 max-w-6xl w-full">
        <textarea
          autoFocus={true}
          rows={3}
          className="form-textarea w-full text-2xl bg-transparent p-6 rounded-lg border border-gray-100 text-gray-100 font-normal font-['Roboto'] leading-normal placeholder:text-gray-100/75"
          placeholder="Type here..."
          value={note}
          onChange={(event) => {
            if (event.target.value.length < charLimit) {
              setNote(event.target.value)
            }
          }}
        />
      </div>
      <NextButton handleNextFunction={onClick}></NextButton>
    </Screen>
  )
}

export default ImpactQ6
