import React from 'react'
import { Fetch } from 'croods'
import map from 'lodash/map'

import Loading from 'components/Loading'

import { Notes } from 'Educators/students/list/Comments/Notes'
import { isEducator, isStudent } from 'students/helper'
import NewComment from './NewComment'

const ListComments = ({ checkInId, currentUser, lastSawProfileAt }) => (
  <Fetch
    name="checkInComments"
    path={`/check_in_comments?check_in_id=${checkInId}`}
    stateId={checkInId}
    renderLoading={Loading}
    renderEmpty={() => (
      <>
        <p className="my-4 text-gray-500">There are no messages yet</p>

        {isEducator(currentUser.role) && (
          <NewComment checkInId={checkInId} currentUser={currentUser} />
        )}
      </>
    )}
    render={(comments) => {
      const showInput =
        isEducator(currentUser.role) || (isStudent(currentUser.role) && comments.length > 0)

      return (
        <>
          {map(comments, ({ user, message, createdAt }, index) => {
            const { name, role, email } = user

            const highlight =
              isStudent(currentUser.role) &&
              currentUser.email !== email &&
              lastSawProfileAt &&
              new Date(createdAt) > new Date(lastSawProfileAt)

            return (
              <Notes
                key={index}
                name={name}
                date={createdAt}
                notes={message}
                isStudent={isStudent(role)}
                highlight={highlight}
              />
            )
          })}
          {showInput && <NewComment checkInId={checkInId} currentUser={currentUser} />}
        </>
      )
    }}
  />
)

export default ListComments
