import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import { setQSData } from 'QueryStringController'
import NineToTwelveBodySensations from './NineToTwelve'
import { navigate } from '@reach/router'

const BodySensations: RouteComponent = () => {
  const { student } = useCheckIn()
  const [selectedSensations, setSelectedSensations] = React.useState<string[]>([])

  if (student) {
    const nextFunction = (params: string) => navigate(`./energy-level${params}`)

    const handleNextFunction = () => {
      if (selectedSensations?.length > 0)
        setQSData({ bodySensations: selectedSensations.join(',') })

      nextFunction(window.location.search)
    }

    return (
      <NineToTwelveBodySensations
        handleNextFunction={handleNextFunction}
        selectedSensations={selectedSensations}
        setSelectedSensations={setSelectedSensations}
      />
    )
  }

  return null
}

export default BodySensations
