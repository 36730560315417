import React from 'react'
import { reduxForm } from 'redux-form'
import Grid from '@material-ui/core/Grid'

import MyDashboardLink from 'components/MyDashboardLink'
import Title from 'components/FormTitle'
import Label from 'components/FormLabel'
import SubmitButton from 'components/FormSubmitButton'

import Fields from './Fields'

const profileForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    submitting,
    error: reduxFormError,
    submitError,
    invalid,
    title,
    initialValues,
  } = props

  return (
    <>
      <MyDashboardLink origin="Adult Profile" />
      <Title>{title}</Title>
      <Label>Account Information</Label>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Fields userRole={initialValues?.role} />
        </Grid>

        <SubmitButton
          submitting={submitting}
          reduxFormError={reduxFormError}
          submitError={submitError}
          disabled={invalid}
          buttonText="SAVE CHANGES"
        />
      </form>
      {props.children}
    </>
  )
}

export default reduxForm({ form: 'profile' })(profileForm)
