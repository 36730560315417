import React, { useState, useEffect } from 'react'
import { Button, Container, Tab, Tabs } from '@material-ui/core'
import moment from 'moment'
import NotificationsIcon from '@material-ui/icons/Notifications'
import PrintIcon from '@material-ui/icons/Print'

import validateUserFields from 'auth/validateUserFields'
import { DashboardScreen } from 'components/DashboardScreen'
import Title from 'components/FormTitle'
import Filter from 'Educators/students/insights/Filter'
import { EnergyLevel } from './EnergyLevel'
import { Triage } from './Triage'
import FollowUpQuestions from 'Educators/students/insights/FollowUpQuestions'
import Emotions from 'Educators/students/insights/Emotions'

import './print.css'
import withMixpanel from 'mixpanel/withMixpanel'
import Card from 'components/Card'
import { navigate } from '@reach/router'
import { useSchools } from 'Educators/SchoolsContext'
import { isSchoolPremium } from 'students/helper'

import SurfacingStudents from 'Educators/students/insights/SurfacingStudents'

import ButtonCSVExport from 'Educators/students/ButtonCSVExport'
import { filter, identity, map, pickBy } from 'lodash'
import { nameConcatenation } from 'Educators/students/helper'

const SchoolList = ({ schools }) => (
  <p className="text-base text-gray-500 mt-7">{nameConcatenation(map(schools, 'school.name'))}</p>
)

const Insights = validateUserFields(({ mixpanel, ...props }) => {
  const { school, schoolId, isSchoolOwner, showPremiumSchools, setShowAllPremiumSchools, schools } =
    useSchools()
  const handlePrint = () => {
    window.print()
    mixpanel.track('Insights: Print Clicked')
  }

  const [studentType, setStudentType] = useState('all')
  const [state, setState] = useState({
    indexTab: 0,
    filter: null,
  })

  const premiumSchools = filter(schools, 'school.premium')

  const handleChange = (_, indexTab) => {
    const tab = ['Triage', 'Energy Level', 'Emotions', 'Follow Up Questions']
    mixpanel.track(`${tab[indexTab]} Tab Clicked`, {
      from: state.filter.from,
      to: state.filter.to,
      grades: state.filter.grades,
      classroom: state.filter.classroom,
      englishLanguageLearner: state.filter.englishLanguageLearner,
      educationPlan504: state.filter.educationPlan504,
      individualizedEducationProgram: state.filter.individualizedEducationProgram,
      freeReducedPriceMeals: state.filter.freeReducedPriceMeals,
      ethnicity: state.filter.ethnicity,
      livingSituation: state.filter.livingSituation,
    })

    setState((prevState) => ({ ...prevState, indexTab }))
  }

  const handleFilter = ({
    to,
    from,
    grades,
    classroom,
    englishLanguageLearner,
    educationPlan504,
    individualizedEducationProgram,
    freeReducedPriceMeals,
    ethnicity,
    livingSituation,
  }) => {
    if (moment(from).isValid())
      setState((prevState) => ({
        ...prevState,
        filter: {
          from,
          to,
          grades,
          classroom,
          english_language_learner: englishLanguageLearner,
          education_plan504: educationPlan504,
          individualized_education_program: individualizedEducationProgram,
          free_reduced_price_meals: freeReducedPriceMeals,
          ethnicity,
          living_situation: livingSituation,
        },
      }))
  }

  useEffect(() => {
    mixpanel.track('View Insights')
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!isSchoolPremium(school)) navigate('/dashboard')
  }, [school])

  useEffect(() => {
    premiumSchools.length > 1 && setShowAllPremiumSchools(true)
    return () => {
      setShowAllPremiumSchools(false)
    }
  }, [showPremiumSchools, premiumSchools]) // eslint-disable-line

  return (
    <DashboardScreen
      {...props}
      propsHeader={{ propsMenuSchool: { showAllPremiumSchools: true } }}
      propsFooter={{ className: 'hide-to-print' }}
    >
      <Container>
        <div className="content-to-print">
          <div className="mb-16">
            <div className="flex justify-between ">
              <div className="flex-1">
                <Title className="mb-0">
                  Insights{' '}
                  <span className="text-sm font-semibold text-primary">
                    {schoolId === 'all' ? '(DISTRICT VIEW)' : '(SCHOOL VIEW)'}
                  </span>
                </Title>
                {schoolId === 'all' && <SchoolList schools={premiumSchools} />}
              </div>
              <div className="flex flex-col items-end gap-5 hide-to-print">
                {isSchoolOwner() && (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    endIcon={<NotificationsIcon />}
                    onClick={() => navigate('/dashboard/students/insights/alerts')}
                  >
                    ALERT SETTINGS
                  </Button>
                )}

                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  endIcon={<PrintIcon />}
                  onClick={handlePrint}
                >
                  PRINT
                </Button>
              </div>
            </div>
          </div>
          <Card title="FILTERS" propsMain={{ className: 'p-0' }}>
            <div className="pt-4 pb-4 pl-10 pr-10">
              <Filter
                studentType={studentType}
                setStudentType={setStudentType}
                handleFilter={handleFilter}
                className="filter-to-print"
              />
            </div>
          </Card>
          <Card title="VIEWING CHECK-INS" className="page-break-to-print">
            <div className="m-6">
              <Tabs
                value={state.indexTab}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                onChange={handleChange}
                classes={{
                  indicator: 'indicator-to-print',
                }}
              >
                <Tab aria-label="triage" label="TRIAGE" />
                <Tab aria-label="energy-level" label="ENERGY LEVEL" />
                <Tab aria-label="emotions" label="EMOTIONS" />
                <Tab aria-label="follow-up-questions" label="FOLLOW UP QUESTIONS" />
              </Tabs>
              <div className="flex flex-col items-center justify-center mt-10 print:!mt-0 page-break-to-print">
                {state.indexTab === 0 && <Triage filter={state.filter} schoolId={schoolId} />}
                {state.indexTab === 1 && <EnergyLevel filter={state.filter} schoolId={schoolId} />}
                {state.indexTab === 2 && <Emotions filter={state.filter} schoolId={schoolId} />}
                {state.indexTab === 3 && (
                  <FollowUpQuestions filter={state.filter} schoolId={schoolId} />
                )}
              </div>
              <div className="flex flex-col items-end mt-10">
                <ButtonCSVExport
                  className="button-contained"
                  url={`${process.env.REACT_APP_API_URL}/schools/${schoolId}/export-check-ins?${map(
                    pickBy(state.filter, identity),
                    (value, key) => `${key}=${value}`,
                  ).join('&')}`}
                />
              </div>
            </div>
            <div className="print:block">
              <SurfacingStudents
                indexTab={state.indexTab}
                studentType={studentType}
                filter={state.filter}
              />
            </div>
          </Card>
        </div>
      </Container>
    </DashboardScreen>
  )
})

export default withMixpanel(Insights)
