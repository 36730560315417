import React, { useState } from 'react'
import { useCroods } from 'croods'
import Loading from 'components/Loading'
import { error } from 'components/Toast'
import { AdminScreen } from './components/AdminScreen'
import { EmailInput } from './components/EmailInput'
import { NumberInput } from './components/NumberInput'
import { Button, P } from './components/styled'
import { A } from './components/A'
import { MetabaseQueries } from './components/MetabaseQueries'
import { ReportResults, Result } from './components/ReportResults'

export const MoveStudents = (props: any) => {
  const [emails, setEmails] = useState<string>('')
  const [schoolId, setSchoolId] = useState<number | string>('')
  const [result, setResult] = useState<Result | null>(null)
  const [open, setOpen] = useState<boolean>(false)

  const [{ saving: isMoving }, { save: moveStudents }] = useCroods({
    name: 'admin-move-school-students',
    customPath: 'admin/move_school_students',
    afterSuccess: ({ data }) => setResult(data),
    afterFailure: ({ response }) => error(response?.data.message),
  })

  return (
    <AdminScreen title="Move students to another school" {...props}>
      <div style={{ width: '100%', maxWidth: '600px', alignSelf: 'center' }}>
        <P>1. Add the students emails separated by commas in the field below:</P>
        <EmailInput label="Students emails *" value={emails} setValue={setEmails} />

        <P>
          2. Add the new school id (target school) in the field below. In case you don&apos;t have
          the school id, use one of the <A name="metabase queries" onClick={() => setOpen(true)} />{' '}
          to find the school id.
        </P>
        <NumberInput
          name="schoolId"
          label="New School ID (Target school) *"
          value={schoolId}
          setValue={setSchoolId}
        />

        {isMoving ? <Loading /> : null}

        <Button
          disabled={emails === '' || schoolId === '' || isMoving}
          variant="edit"
          onClick={() => moveStudents()({ emails, schoolId })}
        >
          MOVE STUDENTS TO TARGET SCHOOL
        </Button>

        {Boolean(result) ? (
          <ReportResults title="Students moved successfully:" result={result} />
        ) : null}
      </div>
      <MetabaseQueries open={open} onClose={() => setOpen(false)} />
    </AdminScreen>
  )
}
