import React from 'react'
import { required } from 'redux-form-validators'
import { Field, reduxForm } from 'redux-form'

import Input from 'components/form/Input'
import { Grid } from '@material-ui/core'
import SignUpSubmit from 'auth/signUpStep/SignUpSubmit'

const SignUpForm = (props) => {
  const { create, creating, apiError } = props
  const isDisabled = props.invalid || creating

  return (
    <form onSubmit={props.handleSubmit(create, props)} style={{ width: '90%' }}>
      <Grid container>
        <Grid item style={{ flex: 1 }}>
          <Field
            label="Password"
            type="password"
            name="password"
            component={Input}
            validate={[required()]}
            style={{ marginBottom: 16 }}
          />
          <Field
            label="Password Confirmation"
            type="password"
            name="passwordConfirmation"
            component={Input}
            validate={[required()]}
            style={{ marginBottom: 16 }}
          />
        </Grid>
      </Grid>

      <SignUpSubmit
        apiError={apiError}
        creating={creating}
        isDisabled={isDisabled}
        title="NEXT"
      />
    </form>
  )
}

export default reduxForm({
  form: 'signUp',
})(SignUpForm)
