import moment from 'moment'
import { filter, orderBy, reduce, map, isEmpty, reject } from 'lodash'

import { TRIAGE_GROUPS } from 'Educators/triageGroups'

const notGoodToGo = (checkIn) =>
  (checkIn?.latestCheckIn?.helpScore || 0) >= TRIAGE_GROUPS[1].lowerBound

// check if the student received support
const needsFollowUp = (checkIns) => {
  const latestUnconfortableCheckIn = checkIns && checkIns.find((checkIn) => notGoodToGo(checkIn))

  return latestUnconfortableCheckIn?.latestCheckIn?.followedUpId === null
}

// sort by "create at" for the last check-in stay on top
const sortByCreatedAt = (checkIns) => orderBy(checkIns, ['latestCheckIn.createdAt'], ['desc'])

const conditionsOfStillNeedsHelp = (item) => {
  const notCreatedToday =
    moment(item?.latestCheckIn?.createdAt).local().format('YYYY-MM-DD') !==
    moment().local().format('YYYY-MM-DD')

  return notCreatedToday && notGoodToGo(item)
}

const filterCheckIn = (checkIns) =>
  needsFollowUp(checkIns) ? filter(checkIns, conditionsOfStillNeedsHelp) : []

// group all check-ins by student id
const groupByStudent = (students) =>
  reduce(
    sortByCreatedAt(students),
    (result, value) => {
      const objectOfStudents = { ...result }
      if (!objectOfStudents?.[value.id]) {
        objectOfStudents[value.id] = []
      }
      objectOfStudents[value.id].push(value)
      return objectOfStudents
    },
    {},
  )

export const addPositiveAfterNeedsHelpProp = (students) =>
  map(groupByStudent(students), (item) =>
    map(item, (student) => ({
      ...student,
      hasPositiveAfterNeedsHelp:
        item && item.length > 0 && item[0].latestCheckIn.helpScore < TRIAGE_GROUPS[1].lowerBound,
    })),
  )

export const groupByCheckIn = (students) => {
  const parsedStudents = addPositiveAfterNeedsHelpProp(reject(students, (s) => !s.latestCheckIn))

  const groupedCheckIns = map(parsedStudents, filterCheckIn)

  return reject(groupedCheckIns, isEmpty)
}
