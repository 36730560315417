import React from 'react'
import { Dialog } from '@material-ui/core'
import { DialogContainer, TextContainer } from './styled'

interface Props {
  children: React.ReactNode
  open: boolean
  onClose: () => void
}

export const Modal = ({ children, open, onClose }: Props) => (
  <Dialog open={open} onClose={() => onClose()} style={{ overflowY: 'auto', overflowX: 'hidden' }}>
    <DialogContainer>
      <TextContainer>{children}</TextContainer>
    </DialogContainer>
  </Dialog>
)
