import React from 'react'
import { withStyles } from '@material-ui/core/styles'

const styles = (theme) => ({
  screen: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: "url('/images/cadet-on-top.png')",
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundSize: '100% auto',
    paddingTop: '95px',
    marginBottom: '30px',
    [theme.breakpoints.up('sm')]: {
      paddingTop: '170px',
    },
  },
})

const CadetOnTopScreen = withStyles(styles)(({ classes, children }) => (
  <div className={classes.screen}>{children}</div>
))

export { CadetOnTopScreen }
