import React, { useMemo } from 'react'
import { navigate } from '@reach/router'

import SecondaryButton from 'checkIn/components/SecondaryButton'
import { checkInTrack, isWeekendToday } from 'students/helper'
import CadetOnBottom from 'checkIn/components/nonReader/CadetOnBottomScreen'
import { ButtonNext } from 'checkIn/components/ButtonNext'
import AudioPlayer from 'checkIn/AudioPlayer'

import { WELCOME_PAGE } from 'checkIn/audios'
import type { RouteComponent, Student } from 'types'

import { WeekendText } from 'checkIn/components/WeekendText'
import FirstCheckInText from 'checkIn/components/FirstCheckInText'
import { useMixpanel } from 'mixpanel'
import { isWithinSchoolOpeningHours } from 'checkIn/helper'
import { User } from 'types'

type Props = {
  nextFunction: (params: string, user?: User) => void
  student: Student
  isStudent?: boolean
  currentUser: User
}

const WelcomeNonReader: RouteComponent<Props> = ({
  nextFunction,
  student,
  isStudent,
  currentUser,
}) => {
  const mixpanel = useMixpanel()
  const { checkInAvailable } = student
  const studentData = { studentId: student.id, grade: student.grade }

  const outsideSchoolHours = useMemo(
    () => isWeekendToday() || !isWithinSchoolOpeningHours(checkInAvailable),
    [checkInAvailable],
  )

  const handleMyProfileButton = () => {
    checkInTrack(mixpanel, 'View My Profile Clicked', studentData)
    navigate(`/check-in-history/${student.id}`)
  }

  const handleNextButton = () => {
    checkInTrack(mixpanel, "Let's Start Clicked", studentData)
    nextFunction(window.location.search, currentUser)
  }

  return (
    <CadetOnBottom
      title="Welcome to Closegap"
      text={`It's great to see you, ${student.firstName}!`}
      audio={WELCOME_PAGE.autoplay}
    >
      {outsideSchoolHours && isStudent ? (
        <>
          <WeekendText />
          <AudioPlayer importAudio={WELCOME_PAGE.buttonProfile}>
            <ButtonNext onClick={handleMyProfileButton}>
              <span className="font-bold">View My Profile</span>
            </ButtonNext>
          </AudioPlayer>
        </>
      ) : (
        <>
          {!student.latestCheckIn && <FirstCheckInText />}
          <AudioPlayer importAudio={WELCOME_PAGE.buttonStart}>
            <ButtonNext onClick={handleNextButton}>
              <span className="font-bold">Let's Start!</span>
            </ButtonNext>
          </AudioPlayer>

          {isStudent && (
            <AudioPlayer
              styleButton={{
                marginBottom: 0,
                width: 30,
                height: 30,
                minHeight: 30,
                minWidth: 30,
                marginLeft: -14,
              }}
              importAudio={WELCOME_PAGE.buttonProfile}
            >
              <SecondaryButton className="mt-5" onClick={handleMyProfileButton}>
                View My Profile
              </SecondaryButton>
            </AudioPlayer>
          )}
        </>
      )}
    </CadetOnBottom>
  )
}

export { WelcomeNonReader }
