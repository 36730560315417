import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import { navigate } from '@reach/router'

type Props = {
  dialogTitle: string
  dialogSubtitle?: string
  renderButtonText: () => string
  destroy: () => () => Promise<void>
  destroying: boolean
  destroyError?: string
}
const Destroy = ({
  dialogTitle,
  dialogSubtitle,
  renderButtonText,
  destroy,
  destroying,
  destroyError,
}: Props) => {
  const [dialogIsOpen, setDialogOpen] = useState(false)
  const close = () => setDialogOpen(false)

  return (
    <>
      <Dialog open={dialogIsOpen} onClose={close} aria-labelledby="Are you sure?">
        <DialogTitle>{dialogTitle}</DialogTitle>
        {dialogSubtitle && (
          <DialogContent>
            <DialogContentText>{dialogSubtitle}</DialogContentText>
            {destroying && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  fontSize: '1rem',
                  color: '#998EC9',
                  lineHeight: '170%',
                  textAlign: 'center',
                }}
              >
                <CircularProgress className="!h-6 !w-6 mb-4" />
                This will take some time.
                <br />
                Please wait until you are redirected.
              </div>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button
            onClick={close}
            disabled={destroying}
            color="primary"
            autoFocus
            aria-label="cancel"
          >
            Cancel
          </Button>
          <Button
            aria-label="confirm"
            onClick={async () => {
              await destroy()()
              navigate('/dashboard')
              close()
            }}
            disabled={destroying}
            color="primary"
          >
            Yes, delete.
          </Button>
        </DialogActions>
      </Dialog>
      <div className="pt-24 text-center">
        {destroying ? (
          <CircularProgress className="!h-6 !w-6 !text-white mb-4" />
        ) : (
          renderButtonText && (
            <button
              className="my-2.5 p-2 hover:bg-black/10 transition rounded text-white"
              onClick={() => setDialogOpen(true)}
              disabled={destroying}
            >
              {renderButtonText()}
            </button>
          )
        )}
        {destroyError && <p className="mb-2.5">{destroyError}</p>}
      </div>
    </>
  )
}

export default Destroy
