import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'

import Loading from 'components/Loading'
import { getStudentTypeByGrade, isKid, isTeen, isNonReader } from 'grades'
import { useCheckIn } from './CheckInContext'
import type { RouteComponent } from 'types'
import { navigate } from '@reach/router'
import { isSchoolOnVacation, getPathBasedOnGrade } from 'auth/signIn/vacationHelper'
import {
  getLocalStorageCheckIn,
  isNinthGradeOrOlder,
  isOlderTeen,
  setLocalStorageCheckIn,
} from 'students/helper'
import { featureEnabled } from 'featureFlags'

type Props = { studentId?: string }
const CheckIn: RouteComponent<Props> = ({ studentId, children }) => {
  const { student, handleSetStudent } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  const [{ fetchingInfo }] = useCroods({
    fetchOnMount: true,
    name: 'students',
    id: studentId,
    afterSuccess: ({ data }) => {
      handleSetStudent(data)
    },
    after4xx: () => {
      navigate('/sign-out?clear=false')
    },
  })

  const handleVacationRedirects = useCallback(() => {
    // Enable check-in if the student is logged through adult account
    if (!currentUser?.student) return undefined

    // Enable check-in if the student grade is 11-HEd
    if (isOlderTeen(currentUser.student.grade)) return undefined

    const { student: studentData, school } = currentUser
    const isOnVacation = isSchoolOnVacation(school)
    if (!isOnVacation) return undefined

    const redirectPath = getPathBasedOnGrade(studentData)
    return navigate(redirectPath)
  }, [currentUser])

  const handleNineToTwelveRedirect = useCallback(() => {
    if (
      !!student &&
      featureEnabled(currentUser, 'nine_to_twelve_check_in') &&
      isNinthGradeOrOlder(student.grade)
    ) {
      navigate(`/nineToTwelve/check-in/${student.id}/`)
    }
  }, [student, currentUser])

  useEffect(() => {
    handleVacationRedirects()
  }, [handleVacationRedirects])

  useEffect(() => {
    handleNineToTwelveRedirect()
  }, [handleNineToTwelveRedirect])

  useLayoutEffect(() => {
    if (student?.grade) {
      if (isTeen(getStudentTypeByGrade(student.grade))) {
        if (featureEnabled(currentUser, 'new_teen_check_in')) {
          setLocalStorageCheckIn('color', '#04C8C8')
          const mood = getLocalStorageCheckIn('mood') || 'neutral'
          document.body.style.minHeight = '100vh'
          document.body.style.backgroundImage = `url('/images/moods/fiveToEight/${mood}.png')`
          document.body.style.backgroundSize = 'cover'
          document.body.style.backgroundRepeat = 'no-repeat'
          document.body.style.backgroundPosition = 'center'
        } else {
          document.body.style.backgroundImage = "url('/images/teen/background.png')"
        }
      } 
      else if (
        isKid(getStudentTypeByGrade(student.grade)) &&
        featureEnabled(currentUser, 'new_teen_check_in')
      ){
          document.body.style.minHeight = '100vh'
          document.body.style.backgroundImage = `url('/images/moods/threeToFour/default.png')`
          document.body.style.backgroundSize = 'cover'
          document.body.style.backgroundRepeat = 'no-repeat'
          document.body.style.backgroundPosition = 'center'
        } 
      else if (isNonReader(getStudentTypeByGrade(student.grade)) &&
        featureEnabled(currentUser, 'pk_2_check_in_2024')){
          document.body.style.minHeight = '100vh'
          document.body.style.backgroundSize = 'cover'
          document.body.style.backgroundRepeat = 'no-repeat'
          document.body.style.backgroundPosition = 'center'
          document.body.style.backgroundImage = `url('/images/background_pk2_check_in_2024.png')`
        } 
      else {
          document.body.style.backgroundImage = 'none'
      }
    }
    return () => {
      document.body.style.backgroundImage = 'none'
    }
  }, [student, currentUser])

  if (fetchingInfo || !student) return <Loading />

  return <div>{children}</div>
}

export default CheckIn
