import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import DeleteIcon from '@material-ui/icons/Delete'
import { Button } from '@material-ui/core'
import { useCroods } from 'croods'
import { useFlash } from 'seasoned-flash'

import { useSchools } from 'Educators/SchoolsContext'
import StyledDialog from 'components/StyledDialog'
import Error from 'components/form/Error'

const ButtonDelete = ({ initialState = false, alertId }) => {
  const { schoolId } = useSchools()
  const { success, error } = useFlash()
  const [open, setOpen] = useState(initialState)

  const [{ destroyError, destroying }, { destroy }] = useCroods({
    name: 'insightsAlerts',
    stateId: schoolId,
    id: alertId,
    path: `/schools/${schoolId}/alerts`,
    afterSuccess: () => {
      setOpen(false)
      success('Alert deleted successfully')
    },
    afterFailure: () => {
      error('Failed to delete the alert')
    },
  })

  const onClick = () => {
    setOpen(true)
  }
  const onClose = () => {
    setOpen(false)
  }

  const handleDelete = () => {
    destroy()(alertId)
  }

  return (
    <>
      <IconButton aria-label="delete" label="Delete" disabled={destroying} onClick={onClick}>
        <DeleteIcon color="primary" />
      </IconButton>
      <StyledDialog
        fullWidth
        open={open}
        onClose={onClose}
        title="Delete Alert"
        subtitle="The alert will be permanently deleted."
        description="Are you sure you want to delete this alert?"
      >
        <div className="mt-4">
          {destroyError && <Error>{destroyError}</Error>}
          <Button color="primary" disabled={destroying} onClick={handleDelete}>
            DELETE
          </Button>
        </div>
      </StyledDialog>
    </>
  )
}

export default ButtonDelete
