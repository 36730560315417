import React from 'react'
import { Card2, P, SubTitle, Title } from './styled'

export type Result = {
  success: string | null
  emailsNotFound: string | null
  notStudentsEmails: string | null
}

interface Props {
  result: Result | null
  title: string
}

export const ReportResults = ({ result, title }: Props) => (
  <Card2>
    <Title>Results</Title>
    <SubTitle>{title}</SubTitle>
    <P>{result?.success || '0'}</P>

    <br />

    <SubTitle>Emails not found:</SubTitle>
    <P>{result?.emailsNotFound || '0'}</P>

    <br />

    <SubTitle>Not students emails:</SubTitle>
    <P>{result?.notStudentsEmails || '0'}</P>
  </Card2>
)
