import React from 'react'

import StudentItem from 'Educators/students/list/StudentItem'
import isEmpty from 'lodash/isEmpty'
import { EmptyText } from 'components/EmptyText'

const Content = ({
  items,
  showFollowUp = false,
  currentUser = {},
  showCheckInDetails = false,
  showAvatar = false,
  studentItemProps = {},
  childReference = '',
  fetchListStudents = undefined,
  admins = false,
  isManageStudents = false,
}) => (
  <>
    {isEmpty(items) ? (
      <EmptyText>No {childReference.toLowerCase()}s yet</EmptyText>
    ) : (
      items.map((item) => (
        <StudentItem
          key={item.id}
          student={item}
          aria-label="studentItem"
          showFollowUp={showFollowUp}
          currentUser={currentUser}
          showAvatar={showAvatar}
          showCheckInDetails={showCheckInDetails}
          fetchListStudents={fetchListStudents}
          admins={admins}
          isManageStudents={isManageStudents}
          {...studentItemProps}
        />
      ))
    )}
  </>
)

export default Content
