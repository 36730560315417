import * as React from 'react'
import { Field, reduxForm } from 'redux-form'
import { map } from 'lodash'
import reduce from 'lodash/reduce'
import { IoMdClose } from 'react-icons/io'
import { SwipeableDrawer, Grid, Button, Typography } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import styled from 'styled-components'
import { useMixpanel } from 'mixpanel'
import { FilterContext } from 'Educators/filter-context'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import { hexToRGB } from 'utils'

const EscKeyCode = 27

const checkinLabels = {
  fuel: {
    red: 'Red - Empty',
    blue: 'Blue - Low Energy',
    yellow: 'Yellow - OK',
    green: 'Green - Ready!',
  },
}

const reduceChoices = (result, item) => {
  const newResult = { ...result }

  if (item.grade) {
    newResult.grades[item.grade] = { label: item.grade, value: item.grade }
  }

  if (item.latestCheckIn) {
    const checkin = item.latestCheckIn

    newResult.energyLevel[checkin.fuel] = {
      label: checkinLabels.fuel[checkin.fuel],
      value: checkin.fuel,
    }
  }

  return newResult
}

export const extractChoices = (list) => {
  const setMap = reduce(list, reduceChoices, {
    grades: {},
    energyLevel: {},
  })

  return {
    grades: Object.values(setMap.grades),
    energyLevel: Object.values(setMap.energyLevel),
  }
}

export const handleFilter = (mixpanel, onChange, onClose) => (data) => {
  onChange(data)
  mixpanel.track('Filtered options', {
    'Filtered By Energy Level': data.energyLevel,
    'Filtered By Needs': data.needs,
    'Filtered By Student Name': data.studentName,
    'Filtered By Grade': data.grade,
    'Filtered By Share With': data.caregivers,
  })
  onClose()
}

export const handleEsc = (onClose) => (event) => event.keyCode === EscKeyCode && onClose()

export const gradeOrdering = (a, b) => {
  const order = {
    PK: 0,
    K: 1,
    '1': 2,
    '2': 3,
    '3': 4,
    '4': 5,
    '5': 6,
    '6': 7,
    '7': 8,
    '8': 9,
    '9': 10,
    '10': 11,
    '11': 12,
    '12': 13,
    HEd: 14,
  }

  return order[a.value] - order[b.value]
}

const needsOptions = [
  { label: 'Wants to Talk', value: 'talkToAdult' },
  { label: 'Hungry', value: 'hasEaten' },
  { label: 'Needs Sleep', value: 'hasSlept' },
  { label: 'Reported Hurt or Sick', value: 'hurtOrSick' },
  { label: 'Reported Home Trouble', value: 'okAtHome' },
  { label: 'Reported Peer Issues', value: 'bulliedAtSchool' },
]

const FilterSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 2rem;
`
const FilterTitles = styled.div`
  display: flex;
  color: #e9573e;
  justify-content: ${({ hasSelection }) => (hasSelection ? 'space-between' : 'flex-end')};
  align-items: center;
`

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`

const ClearOptionButton = styled.button`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  line-height: normal;
  font-size: 0.9rem;
  color: #616161;
  padding: 0.25rem 0.5rem 0.25rem 1rem;
  border-radius: 999px;
  box-shadow: ${() => `0px 0px 2px 1px ${hexToRGB('#616161', 0.34)}`};
`

const labels = {
  grade: 'Grade',
  needs: 'Needs & Self-Reports',
  studentName: 'Student name',
  classroom: 'Classroom',
}

const Filter = ({
  title = 'FILTER STUDENTS',
  isDashboard = false,
  reset,
  list,
  isOpen,
  onOpen,
  onClose,
  handleSubmit,
  filters,
  childReference = 'STUDENTS',
  onChange,
  showCheckinFields,
  classrooms = [],
  change,
}) => {
  const { extraFilters, onReset } = React.useContext(FilterContext)
  const { energyLevel, grades } = extractChoices(list)
  const mixpanel = useMixpanel()

  const studentFields = [
    {
      name: 'grade',
      label: 'Grade',
      options: grades.sort(gradeOrdering),
    },
    {
      name: 'classroom',
      label: 'Classroom',
      options: classrooms,
    },
  ]

  const checkinFields = [
    {
      name: 'needs',
      label: 'Needs & Self-Reports',
      options: needsOptions,
    },
    {
      name: 'energyLevel',
      label: 'Energy Level',
      options: energyLevel,
    },
  ]

  const selectFields = showCheckinFields ? [...checkinFields, ...studentFields] : studentFields

  const handleClear = () => {
    mixpanel.track('Filter Students Clear Clicked')
    reset()
    onReset && onReset()
  }

  const handleRemoveIndividualFilter = (key) => {
    const aux = filters
    delete aux[key]
    onChange({ ...aux })
    change(key, '')
  }

  const handleFilterStudentClicked = () => {
    mixpanel.track('Filter Student Clicked')
    onOpen()
  }

  const filtersKeys = Boolean(filters) ? Object.keys(filters) : []

  const hasFilter = Boolean(filtersKeys?.length)

  return (
    <>
      {onOpen && (
        <FilterSection>
          {isDashboard || hasFilter ? (
            <FilterTitles hasSelection={hasFilter}>
              {hasFilter ? <h2>Active filters:</h2> : null}
              <button
                type="button"
                className="flex items-center gap-2 text-base uppercase cursor-pointer text-primary"
                onClick={handleFilterStudentClicked}
              >
                <p>{hasFilter ? `EDIT FILTER` : `Filter ${childReference.toUpperCase()}s`}</p>
                <SearchIcon className="w-4 h-4" />
              </button>
            </FilterTitles>
          ) : null}

          {hasFilter ? (
            <Row>
              {map(filtersKeys, (key) =>
                key ? (
                  <ClearOptionButton key={key} onClick={() => handleRemoveIndividualFilter(key)}>
                    {labels[key]}
                    <IoMdClose size={20} color="#E9573E" />
                  </ClearOptionButton>
                ) : null,
              )}
            </Row>
          ) : null}
        </FilterSection>
      )}
      <SwipeableDrawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        onOpen={onOpen}
        disableSwipeToOpen={true}
      >
        <div tabIndex={0} onKeyDown={handleEsc(onClose)} className="w-80">
          <form onSubmit={handleSubmit(handleFilter(mixpanel, onChange, onClose))}>
            <Grid container alignItems="stretch" direction="column">
              <div className="px-6 pt-10 pb-2 border-b border-gray-300">
                <Typography color="textSecondary">{title}</Typography>
              </div>

              <div className="p-6">
                <div>
                  <Field
                    component={Input}
                    name="studentName"
                    label="Student Name"
                    type="text"
                    className="mb-4"
                  />
                </div>

                {selectFields?.map(
                  (field) =>
                    !!field.options.length && (
                      <div className="flex flex-col items-stretch" key={field.name}>
                        <Field component={Select} {...field} className="mb-4" />
                      </div>
                    ),
                )}
                {extraFilters}

                <div className="flex mt-4">
                  <Button fullWidth color="primary" onClick={handleClear}>
                    Clear
                  </Button>
                  <Button color="primary" type="submit" fullWidth>
                    FILTER
                  </Button>
                </div>
              </div>
            </Grid>
          </form>
        </div>
      </SwipeableDrawer>
    </>
  )
}

const Filters = reduxForm({ form: 'filter-students' })(Filter)

export default Filters
