import React from 'react'
// @ts-ignore
import { useCheckIn } from 'checkIn/CheckInContext'
import Kid from './Kid'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { navigate } from '@reach/router'

const Questions: RouteComponent = () => {
  const { student } = useCheckIn()

  const questionKey = window.location.pathname.split('/').pop()

  if (student) {
    let nextFunction
    if (questionKey === 'bulliedAtSchool') {
      nextFunction = () => navigate(`./../share-anything-else${window.location.search}`)
    } else {
      // @ts-ignore
      nextFunction = FLOWS['kid'][questionKey || ''].nextFunction
    }

    return <Kid nextFunction={nextFunction} questionKey={questionKey} school={undefined} />
  }

  return null
}

export default Questions
