import React from 'react'
import Title from 'components/Title'
import AudioPlayer from 'checkIn/AudioPlayer'
import clsx from 'clsx'

type Props = {
  children?: React.ReactNode
  label?: string
  title?: string
  className?: string
  audios?: { autoplay: unknown }
}

export default ({ children, label, title, className, audios }: Props) => (
  <main className={clsx('flex flex-col justify-center items-center pt-[6vh]', className)}>
    {label && <h2 className="px-16 text-2xl font-medium text-center text-primary mb-7">{label}</h2>}
    {audios && title ? (
      <AudioPlayer
        className="gap-2"
        autoplay
        importAudio={audios.autoplay}
        styleButton={{ alignSelf: 'flex-start', marginRight: 16 }}
      >
        {title && <Title style={{ flex: 1 }}>{title}</Title>}
      </AudioPlayer>
    ) : (
      title && <Title>{title}</Title>
    )}
    {children}
  </main>
)
