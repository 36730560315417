import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { VscTriangleDown } from 'react-icons/vsc'
import { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form'
import {
  Wrapper,
  DropdownContent,
  Options,
  Input,
  Label,
  ErrorMessage,
  InputContainer,
} from './styles'

type Entry = { name: string; id: number | string }
type Entries = Entry[]

interface Props {
  entries: Entries
  value: number | undefined
  onChange: (id: Entry['id'] | null) => void
  error: Merge<FieldError, FieldErrorsImpl<{ id: number; name: string }>> | undefined
  displayValue?: string
  onChangeDisplayValue?: (name: string) => void
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>
}

export const DropdownEntries = ({
  entries,
  onChange,
  error,
  displayValue,
  onChangeDisplayValue,
  onKeyDown,
}: Props) => {
  const [written, setWritten] = useState<string | undefined>(undefined)
  const [searchedDistricts, setSearchedDistricts] = useState<Entries>(entries)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const [displayed, setDisplayed] = useState(displayValue ?? '')
  const wrapper = useRef(null)

  const autoScroll = useCallback(
    // @ts-ignore
    () => isOpen && wrapper.current.scrollIntoView({ behavior: 'smooth' }),
    [isOpen],
  )

  useEffect(() => {
    autoScroll()
  }, [autoScroll, isOpen])

  const onClick = () => {
    if (entries.length > 0) setIsOpen(!isOpen)
  }

  const handleSelectOption = (entry: Entry) => {
    setWritten('')
    onChangeDisplayValue?.('')
    setIsSelected(true)
    setDisplayed(entry.name)
    onChange(entry.id)
  }

  useEffect(() => {
    if (!Boolean(written)) {
      setSearchedDistricts(entries)
    } else {
      const result = entries.filter((entry: Entry) =>
        entry.name.toLowerCase().includes(written?.toLowerCase() || '') ? entry : false,
      )
      setSearchedDistricts(result)
    }
  }, [written]) // eslint-disable-line

  const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
    setWritten(event.target.value)
    onChangeDisplayValue?.(event.target.value)
    setIsOpen(searchedDistricts.length > 0)
    onChange(null)
    setIsSelected(false)
  }

  return (
    <Wrapper onClick={onClick} ref={wrapper}>
      <InputContainer isOpen={isOpen} invalid={Boolean(error)}>
        <Input
          placeholder={displayed}
          value={written}
          type="text"
          onChange={handleOnChange}
          onKeyDown={(evt) => {
            if (['Backspace', 'Delete'].includes(evt.key)) {
              if(isSelected){
                setDisplayed(displayValue ?? '')
                setIsSelected(false)
                setIsOpen(false)
              }
            }
            return onKeyDown?.(evt)
          }}
        />
        {isOpen ? (
          <MdClose size={25} color="#9a9a9a" />
        ) : (
          <VscTriangleDown size={25} color="#9a9a9a" />
        )}
      </InputContainer>
      {isSelected || Boolean(written) ? (
        <Label invalid={Boolean(error)}>{displayValue}</Label>
      ) : null}
      {isOpen && (
        <DropdownContent required={true}>
          {searchedDistricts.map((entry: Entry) => {
            // debugger
            return (
              <Options key={entry.id} value={entry.name} onClick={() => handleSelectOption(entry)}>
                {entry.name}
              </Options>
            )
          })}
        </DropdownContent>
      )}
      {Boolean(error) ? <ErrorMessage>{error?.message}</ErrorMessage> : null}
    </Wrapper>
  )
}
