import { authHeaders } from 'dmcroods-auth'

const getDownloadURL = async (url, errorMessage = 'Download failed', headers = authHeaders()) => {
  const response = await window.fetch(url, {
    headers,
  })

  if (!response.ok) {
    throw new Error(errorMessage)
  }

  return response.blob()
}

export default getDownloadURL
