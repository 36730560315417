import React, { useState } from 'react'
import FilterListIcon from '@material-ui/icons/FilterList'
import Filters from 'Educators/students/list/Filters'
import { useMixpanel } from 'mixpanel'
import type { Student } from 'types'
import { GlobalFilter } from 'Educators/filters'
import styled from 'styled-components'

const handleChangeFilters = (onChange: (t: any) => void) => (data: unknown) => onChange(data)

const FilterSection = styled.div`
  display: flex;
  width: 100%;
  cursor: pointer;
`

type Props = {
  title: string
  list: Student[]
  filters: GlobalFilter
  setFilters: (t: any) => void
  children?: React.ReactNode
}

const Filter = ({ title, list, filters, setFilters, children }: Props) => {
  const mixpanel = useMixpanel()
  const [open, setOpen] = useState(false)

  function handleClose() {
    setOpen(false)
  }

  function handleOpen() {
    mixpanel.track('Filter Students in Manage page clicked')
    setOpen(true)
  }

  return (
    <div className="flex justify-between pt-8">
      <FilterSection>
        <p
          className="flex items-center gap-2 text-sm font-medium text-primary whitespace-nowrap"
          onClick={handleOpen}
        >
          <FilterListIcon /> {title}
        </p>
        <Filters
          title={title}
          filters={filters}
          isOpen={open}
          onClose={handleClose}
          onOpen={handleOpen}
          list={list}
          onChange={handleChangeFilters(setFilters)}
        />
      </FilterSection>
      {children}
    </div>
  )
}

export default Filter
