import React, { useState } from 'react'
import {
  Container,
  makeStyles,
  Typography,
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import Loading from 'components/Loading'
// @ts-ignore
import Uploods from 'uploods'

import { useSchools } from 'Educators/SchoolsContext'

import { success } from 'components/Toast'
import { useCroods } from 'croods'
import { useMixpanel } from 'mixpanel'
import { handleChange } from 'Educators/students/new/Import'
import styled from 'styled-components'
import { PrimaryButton, SecondaryButton } from '../../Components/buttons'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import HelperCadet from '../../Components/helperCadet'
import ErrorMessage from 'components/ErrorMessage'

const Link = styled.a`
  color: #e9573e;
  text-decoration: none;
`

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '16px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '40px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  icon: {
    marginBottom: '10px',
  },
  button: {
    width: '100%',
  },
  saveIcon: {
    marginRight: '10px',
  },
  fileContainer: {
    backgroundColor: '#fff',
    padding: '24px',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '210px',
  },
  fileItem: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '16px',
  },
  fileImg: {
    margin: '6px',
  },
  fileImgBox: {
    border: '1px solid rgba(0, 0, 0, 0.20)',
    borderRadius: '4px',
  },
  fileDetails: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    margin: '0 8px 0 8px',
    paddingBottom: '5px',
    '& > progress': {
      width: '100%',
      height: '8px',
      '&::-webkit-progress-bar': {
        border: '0px',

        borderRadius: '10px',
      },
      '&::-webkit-progress-value': {
        border: '0px',
        backgroundColor: '#6750A3',
        borderRadius: '10px',
      },
    },
    '& > progress[value]::-webkit-progress-value': {
      border: '0px',
      backgroundColor: '#6750A3',
      borderRadius: '10px',
    },
  },
  fileActions: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  primaryButton: {
    marginTop: '16px',
  }
}))

type File = {
  id: string
  name: string
  parsed: string
  path: string
  size: number
  type: string
}

const NewSchoolYearRosterStaff = ({ nextStepFn }: { nextStepFn: Function }) => {
  const mixpanel = useMixpanel()
  const classes = useStyles()
  const { schoolId } = useSchools()
  const [data, setData] = useState<File | null>(null)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [{ saving }, { save }] = useCroods({
    name: 'educatorsImport',
    customPath: `schools/${schoolId}/educators/import`,
    afterResponse({ data }) {
      const { message, success: importedSuccessfuly } = data
      if (importedSuccessfuly) {
        success(message)
        nextStepFn()
      } else {
        mixpanel.track('CSV Upload API Error')
        setErrorMessage(message)
      }
    },
  })
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (skipStep: boolean) => {
    setOpen(false)
    if (skipStep) {
      nextStepFn()
    }
  }

  const onChange = (files: File[]) => {
    setErrorMessage(null)
    setData(files[files.length - 1])
  }

  const submit = () => {
    handleChange({
      create: save(),
      error: setErrorMessage,
      mixpanel,
    })([data])
  }

  const renderDropZone = () => {
    if (data !== null) {
      return (
        <div className={classes.fileContainer}>
          <div className={classes.fileItem}>
            <div>
              <div className={classes.fileImgBox}>
                <img className={classes.fileImg} src="/images/file_upload.png" alt="file upload" />
              </div>
            </div>
            <div className={classes.fileDetails}>
              <p>{data.name}</p>
              <p>{data.size}B</p>
              <progress max="100" value="100"></progress>
            </div>
            <div className={classes.fileActions}>
              <button onClick={() => setData(null)}>X</button>
              <p>100%</p>
            </div>
          </div>
          {!!errorMessage && (
            <ErrorMessage>{errorMessage}</ErrorMessage>
          )}
        </div>
      )
    } else {
      return (
        <div
          id="uploods-wrapper"
          data-testid="addEducator_import_dragAndDropArea"
          onClick={() => mixpanel.track('Drag and Drop Area Clicked')}
          onDragEnter={() => mixpanel.track('Drag and Drop Area Dragged')}
        >
          <Uploods
            overwrite={true}
            containerStyle={{
              marginBottom: '2rem',
              boxSizing: 'border-box',
              padding: '0px',
            }}
            inputStyle={{
              minHeight: '210px',
              padding: '24px',
            }}
            text={
              <Grid container direction="column" alignItems="center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="99"
                  viewBox="0 0 100 99"
                  fill="none"
                >
                  <path
                    d="M75.6787 36.9902C75.6787 36.9802 75.6687 36.9602 75.6687 36.9502C75.6087 36.7902 75.5088 36.6502 75.3988 36.5202C75.3888 36.5102 75.3887 36.5002 75.3787 36.4902L57.1787 16.9702C56.8988 16.6702 56.4988 16.4902 56.0788 16.4902H28.7987C26.2787 16.4902 24.2188 18.5402 24.2188 21.0702V78.9202C24.2188 81.4402 26.2687 83.5002 28.7987 83.5002H71.1987C73.7187 83.5002 75.7787 81.4502 75.7787 78.9202V37.5202C75.7787 37.3302 75.7388 37.1602 75.6787 36.9902ZM57.5287 21.7502L70.8288 36.0102H59.1087C58.2388 36.0102 57.5287 35.3002 57.5287 34.4302V21.7502ZM72.7787 78.9202C72.7787 79.7902 72.0687 80.5002 71.1987 80.5002H28.7987C27.9288 80.5002 27.2188 79.7902 27.2188 78.9202V21.0802C27.2188 20.2102 27.9288 19.5002 28.7987 19.5002H54.5287V34.4402C54.5287 36.9602 56.5788 39.0202 59.1087 39.0202H72.7787V78.9202Z"
                    fill="#250032"
                  />
                  <path
                    d="M49.9991 47.46C49.9891 47.46 49.9691 47.46 49.9591 47.46H39.0391C38.2091 47.46 37.5391 48.13 37.5391 48.96V70.2C37.5391 71.03 38.2091 71.7 39.0391 71.7H60.9491C61.7791 71.7 62.4491 71.03 62.4491 70.2V48.97C62.4491 48.14 61.7791 47.47 60.9491 47.47H50.0391C50.0291 47.47 50.0091 47.46 49.9991 47.46ZM48.4991 68.71H40.5391V64.63H48.4991V68.71ZM48.4991 61.63H40.5391V57.55H48.4991V61.63ZM48.4991 54.55H40.5391V50.47H48.4991V54.55ZM51.4991 50.47H59.4591V54.55H51.4991V50.47ZM51.4991 57.55H59.4591V61.63H51.4991V57.55ZM51.4991 64.63H59.4591V68.71H51.4991V64.63Z"
                    fill="#250032"
                  />
                </svg>
                <Typography align="center" variant="h6" color="secondary">
                  Drag and drop here
                </Typography>
                <Typography align="center" variant="body2">
                  or select the CSV file to upload
                </Typography>
              </Grid>
            }
            hideList
            onChange={onChange}
            accept={['text/csv']}
          />
        </div>
      )
    }
  }

  return (
    <Container className={classes.container}>
      <HelperCadet customLink="https://closegaphelp.zendesk.com/hc/en-us/articles/17627012014619--How-do-I-get-an-Educators-CSV-ready-to-be-uploaded-" />
      <Typography className={classes.heading}>Let's roster your school staff!</Typography>
      <Typography className={classes.subTitle}>
        <Link
          data-testid="addEducator_import_csvTemplate"
          onClick={() => mixpanel.track('Download XLSX Template Link Clicked')}
          download
          href="/educators_template.xlsx"
        >
          <Button variant="outlined" color="secondary">
            <SaveAltIcon className={classes.saveIcon} />
            Download our template here
          </Button>
        </Link>
      </Typography>
     
      {saving ? <Loading /> : renderDropZone()}
      <div className={classes.primaryButton}>
        <PrimaryButton disabled={saving || !data} loading={saving} onClick={() => submit()} >
          SAVE AND CONTINUE
        </PrimaryButton>
      </div>
      <SecondaryButton disabled={saving || !!data} onClick={handleClickOpen}>
        Skip this for now
      </SecondaryButton>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {' Are you sure you want to do this later?'}
        </DialogTitle>
        <DialogContent></DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            NO, TAKE ME BACK.
          </Button>
          <Button onClick={() => handleClose(true)} variant="contained" color="secondary" autoFocus>
            I'LL DO THIS LATER.
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default NewSchoolYearRosterStaff
