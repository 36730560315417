import React, { useMemo, useState } from 'react'
import moment from 'moment'
import { groupBy, orderBy, uniqBy, omit, filter } from 'lodash'

import AT_A_GLANCE from 'atAGlance'
import isParent from 'auth/isParent'
import { EmptyText } from 'components/EmptyText'
import { isEducator, isSchoolPremium } from 'students/helper'
import { filterStudents } from 'Educators/filters'
import { spreadTriageGroup } from 'Educators/triageGroups'
import { useSchools } from 'Educators/SchoolsContext'
import { isToday } from 'Educators/students/helper'
import { Panel, Content, ReminderCheckIn, Header } from 'Educators/students/list/panel'
import { filterByAtAGlance } from './filters'
import AtAGlance from './AtAGlance'
import ToggleSwitch from '../ToggleSwitch'
import PanelGroup from '../../PanelGroup'

const countFollowUp = (list) => filter(list, (s) => s?.latestCheckIn?.followedUpId)?.length ?? 0

export const TodayCheckIn = ({ students, currentUser, filters = {}, fetchListStudents }) => {
  const { school } = useSchools()
  const [filterAtAGlance, setFilterAtAGlance] = useState(AT_A_GLANCE.ALL.value)
  const [hideCompletedFollowUps, setHideCompletedFollowUps] = useState(false)
  const [showOnlyCheckInWithMessages, setShowOnlyCheckInWithMessages] = useState(false)
  const [openDidntCheckIn, setOpenDidntCheckIn] = useState(true)

  const uniqStudents = uniqBy(
    orderBy(students, (s) => moment(s?.latestCheckIn?.createdAt).local(), 'desc'),
    'id',
  )

  const { checkedIn = [], notCheckedIn = [] } = groupBy(uniqStudents, (student) =>
    isToday(student.latestCheckIn?.createdAt) ? 'checkedIn' : 'notCheckedIn',
  )

  const filteredCheckedIn = useMemo(() => filterStudents(checkedIn, filters), [checkedIn, filters])

  const filteredNotCheckedIn = useMemo(
    () =>
      filterStudents(
        notCheckedIn,
        filters,
        Object.keys(omit(filters, ['needs', 'energyLevel']) || {}),
      ),
    [filters, notCheckedIn],
  )
  const withoutCheckInsBeforeFilter = checkedIn.length === 0
  const withoutCheckInsAfterFilter = filteredCheckedIn.length === 0
  const isEducatorOrParent = isEducator(currentUser.role) || isParent(currentUser)

  const groups = useMemo(() => spreadTriageGroup(filteredCheckedIn), [filteredCheckedIn])

  const isPremiumEducatorInPremiumSchool = isEducator(currentUser.role) && isSchoolPremium(school)

  const showNotCheckedIn =
    (filteredNotCheckedIn.length > 0 && filterAtAGlance === AT_A_GLANCE.ALL.value) ||
    filterAtAGlance === AT_A_GLANCE.DIDNT_CHECK_IN.value

  const showPanelGroup = filterAtAGlance !== AT_A_GLANCE.DIDNT_CHECK_IN.value

  const filteredCheckedInByAtAGlance = useMemo(
    () => filterByAtAGlance(filteredCheckedIn, filterAtAGlance),
    [filterAtAGlance, filteredCheckedIn],
  )

  const filteredCompletedFollowUpsStudents = hideCompletedFollowUps
    ? filter(filteredCheckedInByAtAGlance, (s) => !s?.latestCheckIn?.followedUpId)
    : filteredCheckedInByAtAGlance

  const filterCheckInWithMessage = showOnlyCheckInWithMessages
    ? filter(filteredCompletedFollowUpsStudents, (s) => s?.latestCheckIn?.notes)
    : filteredCompletedFollowUpsStudents

  return (
    <>
      {isPremiumEducatorInPremiumSchool && (
        <AtAGlance
          counts={{
            [AT_A_GLANCE.URGENT.value]: groups?.red?.length ?? 0,
            [AT_A_GLANCE.COULD_BENEFIT.value]: groups?.yellow?.length ?? 0,
            [AT_A_GLANCE.GOOD_TO_GO.value]: groups?.green?.length ?? 0,
            [AT_A_GLANCE.DIDNT_CHECK_IN.value]: filteredNotCheckedIn?.length ?? 0,
            [AT_A_GLANCE.ALL.value]:
              (filteredCheckedIn?.length ?? 0) + (filteredNotCheckedIn?.length ?? 0),
          }}
          selected={filterAtAGlance}
          handleClick={(atAGlanceValue) => () => {
            setFilterAtAGlance(atAGlanceValue)
          }}
          countFollowUp={countFollowUp(groups?.red) + countFollowUp(groups?.yellow)}
        />
      )}

      {!withoutCheckInsBeforeFilter && (
        <div className="flex flex-col items-center justify-center w-full gap-5 mb-10 md:gap-10 md:flex-row">
          <ToggleSwitch
            value={showOnlyCheckInWithMessages}
            setValue={setShowOnlyCheckInWithMessages}
            label="Show only check-ins with messages"
            name="show-only-check-in-with-messages"
            id="show-only-check-in-with-messages"
          />
          <ToggleSwitch
            value={hideCompletedFollowUps}
            setValue={setHideCompletedFollowUps}
            label="Hide completed follow-ups"
            name="show-completed-followups"
            id="show-completed-followups"
          />
        </div>
      )}

      {withoutCheckInsAfterFilter && <EmptyText>No check-ins yet</EmptyText>}

      {showPanelGroup && (
        <div data-testid="panel-group">
          <PanelGroup
            group={filterCheckInWithMessage}
            currentUser={currentUser}
            fetchListStudents={fetchListStudents}
          />
        </div>
      )}

      {showNotCheckedIn && (
        <Panel>
          <Header
            title="Didn't Check In"
            variant="other"
            open={openDidntCheckIn}
            setOpen={setOpenDidntCheckIn}
          />
          {!openDidntCheckIn ? null : isEducatorOrParent ? (
            <ReminderCheckIn
              students={filteredNotCheckedIn}
              fetchListStudents={fetchListStudents}
            />
          ) : (
            <div style={{ padding: 40 }}>
              <Content items={filteredNotCheckedIn} currentUser={currentUser} showCheckInDetails />
            </div>
          )}
        </Panel>
      )}
    </>
  )
}
