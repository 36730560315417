import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'

import Chart from './Chart'

const styles = (theme) => ({
  root: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
})

export default withStyles(styles)((props) => (
  <Paper className={clsx(props.classes.root, 'row-to-print')}>
    <Chart data={props.data} />
  </Paper>
))
