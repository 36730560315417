import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import { getQSData } from 'QueryStringController'
import Positive from './Positive'
import Negative from './Negative'
import isComfy from 'emotions'
import { navigateWithParams } from 'utils'

const Emotion: RouteComponent = () => {
  const { student } = useCheckIn()
  const { energyLevel, feeling } = getQSData()
  const positiveResult = ['green', 'yellow'].includes(energyLevel) && isComfy(feeling)

  if (student) {
    return positiveResult ? (
      <Positive handleNextFunction={() => navigateWithParams('./activities')} />
    ) : (
      <Negative
        handleNextFunction={() =>
          navigateWithParams('./preferred-contact', { talkToAnAdult: true })
        }
      />
    )
  }

  return null
}
export default Emotion
