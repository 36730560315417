import React from 'react'
import type { RouteComponent } from 'types'

import { navigateWithParams } from 'utils'
import ComposeNote from './ComposeNote'

const Emotion: RouteComponent = () => {
  return <ComposeNote handleNextFunction={() => navigateWithParams('./summary')}></ComposeNote>
}
export default Emotion
