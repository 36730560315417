import React, { useMemo, useState } from 'react'
import { useCroods } from 'croods'
import { isEmpty } from 'lodash'
import Loading from 'components/Loading'
import { error, success } from 'components/Toast'
import { AdminScreen } from './components/AdminScreen'
import { DetailedSchoolInfo } from './components/DetailedSchoolInfo'
import { NumberInput } from './components/NumberInput'
import { Title, Button, SchoolCard, Row, P } from './components/styled'
import { MetabaseQueries } from './components/MetabaseQueries'
import { A } from './components/A'
import { Modal } from './components/Modal'

export const MergeSchools = (props: any) => {
  const [targetSchoolId, setTargetSchoolId] = useState<number | string>('')
  const [duplicatedSchoolId, setDuplicatedSchoolId] = useState<number | string>('')
  const [open, setOpen] = useState(false)
  const [openInstructions, setOpenInstructions] = useState(false)

  const [{ info: schools = [], infoError: schoolError }, { fetch: getSchools }] = useCroods({
    name: 'admin-school',
    customPath: 'admin/schools',
    afterFailure: () => error(schoolError || ''),
  })

  const [{ saving, saveError }, { save }] = useCroods({
    name: 'admin-merge_school',
    customPath: 'admin/merge_schools',
    afterSuccess: () => success('Schools have being merged sucessfully'),
    afterFailure: () => error(saveError || ''),
  })

  const disabled = useMemo(
    () =>
      !Boolean(targetSchoolId) ||
      !Boolean(duplicatedSchoolId) ||
      targetSchoolId === duplicatedSchoolId,
    [duplicatedSchoolId, targetSchoolId],
  )

  return (
    <AdminScreen title="Merge duplicated Schools" {...props}>
      <div style={{ alignSelf: 'center', width: '100%', maxWidth: '1000px' }}>
        <P>
          To merge duplicate schools, do the follow{' '}
          <A name="these instructions" onClick={() => setOpenInstructions(true)} />.
        </P>
        <P>
          In case you don&apos;t have the school id, use one of the{' '}
          <A name="metabase queries" onClick={() => setOpen(true)} /> to find the school id.
        </P>
        <br />
        <Title>Add the target and duplicated schools ids</Title>
        <Row>
          <NumberInput
            name="targetSchoolId"
            label="Target School ID"
            value={targetSchoolId}
            setValue={setTargetSchoolId}
          />
          <NumberInput
            name="duplicatedSchoolId"
            label="Duplicated School ID"
            value={duplicatedSchoolId}
            setValue={setDuplicatedSchoolId}
          />
        </Row>

        <Button
          disabled={disabled}
          variant="edit"
          onClick={() =>
            getSchools({ operation: 'info' })({ ids: [targetSchoolId, duplicatedSchoolId] })
          }
        >
          Show Schools
        </Button>

        <Button
          disabled={isEmpty(schools) || disabled}
          variant="add"
          onClick={() => save()({ targetSchoolId, duplicatedSchoolId })}
        >
          Merge Schools
        </Button>

        {saving ? <Loading /> : null}

        {schools?.length > 0
          ? schools?.map((s: any) => (
              <SchoolCard key={s?.schoolId}>
                <Title>{s.schoolId === targetSchoolId ? 'Target' : 'Duplicated'} School</Title>
                <DetailedSchoolInfo school={s} />
              </SchoolCard>
            ))
          : null}
      </div>
      <MetabaseQueries open={open} onClose={() => setOpen(false)} />
      <Modal open={openInstructions} onClose={() => setOpenInstructions(false)}>
        <Title>Merge schools steps:</Title>
        <P>1. Use one of the metabase queries&apos; links to search for the schools&apos; ids.</P>
        <P>
          2. Copy the target school id (the school you want to keep) and paste it in the target
          school id field.
        </P>
        <P>
          3. Copy the id of the duplicated school (the one with the teachers, students, classrooms,
          etc that you want to migrate to the target school and that will be deleted after
          migration).
        </P>
        <P>
          4. Click on &quot;Show Schools&quot; and check carefully the schools data to make sure you
          are merging the correct schools.
        </P>
        <P>5. Click &quot;Merge Schools&quot; to merge the schools into the target one.</P>
      </Modal>
    </AdminScreen>
  )
}
