import React, { useEffect, useState } from 'react'
import remove from 'lodash/remove'
import includes from 'lodash/includes'
import upperFirst from 'lodash/upperFirst'

import { checkInTrack } from 'students/helper'
import Screen from 'checkIn/components/teen/Screen'

import screenContent from 'checkIn/bodySensationsScreenContent'
import type { RouteComponent } from 'types'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

import FLOWS from 'checkIn/flows'
import { compact, isEmpty } from 'lodash'
import { getQSData, setQSData } from 'QueryStringController'
import { useMixpanel } from 'mixpanel'
import Teen from './Teen'
import { featureEnabled } from 'featureFlags'
import FiveToEight from './FiveToEight'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'

const SecondaryEmotions: RouteComponent = (props) => {
  const mixpanel = useMixpanel()
  const data = getQSData()
  const [{ currentUser }] = useCurrentUser()

  const [selected, setSelected] = useState<Array<string>>([])

  const handleOptionClick = (item: string) => {
    const value = item.toLowerCase()

    if (includes(selected, value)) {
      remove(selected, (element) => element === value)
    } else if (selected.length < 2 || featureEnabled(currentUser, 'new_teen_check_in')) {
      selected.push(value)
    }
    const compactedSelected = compact(selected)
    setSelected(compactedSelected)
  }

  useEffect(() => {
    checkInTrack(mixpanel, 'Check-in: Are you feeling any of these emotions?', {
      ...props,
      feeling: data.feeling,
    })
  }, [data.feeling, mixpanel, props])

  const { nextFunction } = FLOWS.teen.secondaryEmotions

  const handleNextFunction = () => {
    if (!isEmpty(selected)) {
      setQSData({ secondaryEmotions: selected.join(',') })
    }
    nextFunction(window.location.search)
  }

  const feeling = data.feeling

  return (
    <Screen
      label={`Thanks for sharing. Here are some emotions that relate to feeling ${upperFirst(
        feeling,
      )}.`}
      title={`Are you feeling any of these emotions?${
        featureEnabled(currentUser, 'new_teen_check_in')
          ? ''
          : '<br />Choose up to two, or click next.'
      }`}
    >
      {featureEnabled(currentUser, 'new_teen_check_in') ? (
        <FiveToEight feeling={feeling} selected={selected} handleOptionClick={handleOptionClick} />
      ) : (
        <Teen
          feeling={feeling as keyof typeof screenContent}
          selected={selected}
          handleOptionClick={handleOptionClick}
        />
      )}

      <BackAndNextButtons nextButtonOnClick={handleNextFunction} showBackButton />
    </Screen>
  )
}

export default SecondaryEmotions
