import React, { useState } from 'react'

import { Card, Button, P, Column } from '../components/styled'
import { SchoolInfo } from '../components/SchoolInfo'
import { DisapproveSchoolModal } from './DisapproveSchoolModal'
import { EducatorInfo } from './EducatorInfo'
import { ApproveSchoolModal } from './ApproveSchoolModal'
import { GOOGLE, METABASE } from '../constants'
import { A } from '../components/A'

export const SchoolItem = ({ school: s }: any) => {
  const [openDisapprove, setOpenDisapprove] = useState(false)
  const [openApprove, setOpenApprove] = useState(false)
  const queryString = `?school_name=${encodeURI(s.name)}&state_code=${encodeURI(s.state)}`
  const metabaseUrl = `${METABASE}/231${queryString}`
  const googleUrl = GOOGLE + [s.name, s.street, s.city, s.state].map((p) => encodeURI(p)).join('+')
  return (
    <Card>
      <SchoolInfo school={s} />
      <EducatorInfo school={s} />
      <Column>
        <P>
          <A href={metabaseUrl} name="Search in Metabase" />
        </P>
        <P>
          <A href={googleUrl} name="Search in Google" />
        </P>
        <Button variant="add" onClick={() => setOpenApprove(true)}>
          APPROVE / EDIT SCHOOL
        </Button>
        <Button variant="delete" onClick={() => setOpenDisapprove(true)}>
          DISAPPROVE / DESTROY SCHOOL
        </Button>
      </Column>
      <DisapproveSchoolModal school={s} open={openDisapprove} setOpen={setOpenDisapprove} />
      <ApproveSchoolModal school={s} open={openApprove} Approve setOpen={setOpenApprove} />
    </Card>
  )
}
