import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 80,
    marginBottom: 40,
  },
  link: {
    color: theme.colors.cinnabar,
    textDecoration: 'none',
  },
}))

const LinkInviteEducator = ({ text, textLink, href, alt }) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Typography align="center">
        {text}{' '}
        <a href={href} alt={alt} className={classes.link}>
          {textLink}
        </a>
      </Typography>
    </div>
  )
}

export default LinkInviteEducator
