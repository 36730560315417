import React from 'react'

import { Switch, FormControlLabel } from '@material-ui/core'

const ToggleSwitch = ({ value, setValue, label, switchProps }) => (
  <div className="whitespace-nowrap">
    <FormControlLabel
      onChange={(event) => {
        const { checked } = event.target
        setValue(checked)
      }}
      control={<Switch checked={value} color="primary" {...switchProps} />}
      label={<p className="text-gray-500">{label}</p>}
      labelPlacement="start"
    />
  </div>
)

export default ToggleSwitch
