import React, { useEffect } from 'react'
import { useCroods } from 'croods'

import Card from 'components/Card'
import Educator from 'Educators/ManageSchool/educator'
import { filter, map } from 'lodash'
import { useSchools } from 'Educators/SchoolsContext'
import SelectAccess from 'Educators/ManageSchool/AccessRequests/SelectAccess'
import { getAccessLevel } from 'roles'

const AccessRequests = () => {
  const { schoolId } = useSchools()
  const [{ list }, { fetch: fetchAccessRequests }] = useCroods({
    stateId: schoolId,
    name: 'accessRequests',
    customPath: `/schools/${schoolId}/access_requests`,
  })

  const accessRequestsList = filter(list, ({ status }) => status === 'pending')

  useEffect(() => {
    schoolId && fetchAccessRequests()()
  }, [schoolId]) // eslint-disable-line

  return accessRequestsList?.length > 0 ? (
    <Card title="ACCESS REQUESTS">
      {map(accessRequestsList, ({ id, educator }) => (
        <Educator key={id} educator={Object.assign(educator, {schoolRole: getAccessLevel(educator.role)})} showEmail>
          <SelectAccess accessId={id} schoolId={schoolId} role={educator.role} />
        </Educator>
      ))}
    </Card>
  ) : null
}

export default AccessRequests
