import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { getQSData } from 'QueryStringController'

type Props = {
  handleNextFunction: () => void
}

const PositiveSummary = ({ handleNextFunction }: Props) => {
  const { cadet } = getQSData()
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        {`It sounds like you're having a great day! Love that for you!`}
      </div>
      <div className="max-w-6xl text-center text-gray-100 text-2xl font-light font-['Roboto']">
        Feel free to pick an activity if something sparks your interest!
      </div>
      {cadet === 'true' && (
        <div className="relative mt-10">
          <img className="" src="/images/nineToTwelve/cadet-sofa.png" alt="Cadet on sofa" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 bg-[url('/images/ninetotwelve/planet.png')] bg-center bg-cover bg-no-repeat bg-origin-border w-screen h-[100vw] rounded-full -z-10"></div>
        </div>
      )}
      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-20"
      >
        EXPLORE ACTIVITIES
      </NextButton>
    </Screen>
  )
}

export default PositiveSummary
