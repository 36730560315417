import React, { useEffect } from 'react'
import { Container, makeStyles, Grid, Card } from '@material-ui/core'

import { DashboardScreen } from 'components/DashboardScreen'
import Breadcrumbs from 'components/Breadcrumbs'
import validateUserFields from 'auth/validateUserFields'

import withMixpanel from 'mixpanel/withMixpanel'
import { useSchools } from 'Educators/SchoolsContext'
import Add from 'Educators/educators/import/Add'
import Import from 'Educators/educators/import/importV2'
import { navigate } from '@reach/router'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    marginTop: '2rem',
    marginBottom: '2rem',
    padding: '2rem',
  },
  title: {
    fontSize: '36px',
    letterSpacing: '0.5px',
    color: theme.colors.mardiGras,
  },
  lowerOpacity: {
    opacity: '50%',
  },
  infoText: {
    fontSize: '1.2rem',
    color: theme.colors.mardiGras,
    opacity: '65%',
  },
}))

export default withMixpanel(
  validateUserFields(({ mixpanel, currentUser, ...props }) => {
    const { school } = useSchools()
    const classes = useStyles()

    useEffect(() => {
      mixpanel.track('View add students')
    }, []) // eslint-disable-line

    return (
      <DashboardScreen
        currentUser={currentUser}
        helperCadetCustomLink="https://closegaphelp.zendesk.com/hc/en-us/articles/17627012014619--How-do-I-get-an-Educators-CSV-ready-to-be-uploaded-"
      >
        <Container className={classes.root}>
          <Breadcrumbs
            paths={[
              { children: `Manage ${school.name}`, to: '/manage-school?resourceType=educators' },
            ]}
          >
            Register Educators
          </Breadcrumbs>
          <Grid>
            <Card className={classes.card}>
              <Import nextStepFn={() => navigate('/manage-school')} />
            </Card>
            {/* <Card className={classes.card}> */}
            <Add {...props} />
            {/* </Card> */}
          </Grid>
        </Container>
      </DashboardScreen>
    )
  }),
)
