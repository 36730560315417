import React from 'react'
import { FaSchool } from 'react-icons/fa'

import { useScreenSize } from 'screenSize'
import { Layout } from '../components/Layout'
import { LogInHereMessage } from '../components/LogInHereMessage'
import { InternalLink, Title } from '../components/styled'
import { Form } from './Form'
import { CadetImage, IconContainer, Container, BackLinkContainer } from './style'
import { RouteComponent } from 'types'

export const SignUpSchoolStaff: RouteComponent = () => {
  const { isMobile } = useScreenSize()
  return (
    <Layout>
      {isMobile ? null : <CadetImage />}
      <IconContainer>
        <FaSchool size={isMobile ? 30 : 100} color="#998ec9" />
      </IconContainer>

      <Container>
        <BackLinkContainer>
          <InternalLink to="/sign-up">{`<-- Back`}</InternalLink>
        </BackLinkContainer>

        <Title>Create a School Staff Account</Title>

        <LogInHereMessage />

        <Form />
      </Container>
    </Layout>
  )
}
