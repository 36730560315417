import React, { useLayoutEffect } from 'react'
import { navigate } from '@reach/router'
import { Typography, makeStyles } from '@material-ui/core'

import { ButtonNext } from 'checkIn/components/teen/ButtonNext'
import SecondaryButton from 'checkIn/components/teen/SecondaryButton'
import { isOlderTeen } from 'students/helper'

const useStyles = makeStyles((theme: any) => ({
  screen: {
    backgroundPosition: 'bottom 0 left 0',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundSize: '40% auto',
    display: 'flex',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down(1000)]: {
      paddingTop: '0px',
      backgroundSize: '35% auto',
      alignItems: 'flex-start',
    },
    [theme.breakpoints.down(900)]: {
      backgroundSize: '35% auto',
    },
    [theme.breakpoints.down(800)]: {
      backgroundSize: '50% auto',
    },
    [theme.breakpoints.down(700)]: {
      backgroundSize: '40% auto',
    },
    [theme.breakpoints.down(500)]: {
      backgroundSize: '50% auto',
    },
    [theme.breakpoints.down('xs')]: {
      backgroundSize: '70% auto',
    },
  },
  balloon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    maxWidth: 500,
    marginTop: '-10rem',
    marginLeft: 500,
    [theme.breakpoints.down(1000)]: {
      marginTop: 0,
      marginLeft: 0,
    },
  },
  title: {
    textAlign: 'center',
    color: theme.colors.white,
    marginBottom: 20,
    fontWeight: 500,
    fontSize: 14,
    [theme.breakpoints.down('sm')]: {
      marginTop: '1rem',
      marginBottom: '1rem',
      fontSize: 18,
    },
  },
  subtitle: {
    textAlign: 'center',
    fontSize: 36,
    color: theme.colors.white,
    marginBottom: 40,
    maxWidth: 500,
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '1rem',
      fontSize: 22,
    },
  },
  text: {
    textAlign: 'center',
    marginBottom: 40,
    fontSize: 18,
    color: theme.colors.white,
    fontWeight: 500,
    lineHeight: '160%',
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
      marginBottom: '2rem',
    },
  },
}))

export const TeenVacationScreen = ({ currentUser }: any) => {
  const classes = useStyles()
  const student = currentUser?.student
  const olderTeen = isOlderTeen(student?.grade)

  // useEffect(() => {
  //   if (!currentUser) navigate('/sign-out')
  // }, []) // eslint-disable-line

  useLayoutEffect(() => {
    document.body.style.backgroundImage = "url('/images/teen/background.png')"
  }, [])

  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/sign-out?clear=true')
  }

  const handleNext = () => navigate('.')

  return (
    <div
      className={classes.screen}
      style={{
        backgroundImage: olderTeen
          ? "url('/images/teen/cadet_on_the_moon.png')"
          : "url('/images/teen/cadet_on_vacation.png')",
      }}
    >
      <div className={classes.balloon}>
        <Typography className={classes.title}>{`Hi, ${student?.firstName}!`}</Typography>

        <Typography className={classes.subtitle}>You’re on school break!</Typography>
        {olderTeen ? (
          <>
            <Typography className={classes.text}>
              I’m so glad you’re here! You can do a check-in but you won’t be able to request to
              speak to an adult.
            </Typography>
            <ButtonNext style={{ marginBottom: 20 }} onClick={handleNext}>
              Let’s Start!
            </ButtonNext>
            <SecondaryButton onClick={() => navigate(`/check-in-history/${student.id}`)}>
              View My Profile
            </SecondaryButton>
          </>
        ) : (
          <>
            <Typography className={classes.text}>
              You can’t check-in until school starts again, but don’t forget that you can always
              journal, do a power pose, or take some long, deep breaths.
            </Typography>
            <ButtonNext style={{ marginBottom: 20 }} onClick={handleLogout}>
              Okay, log out!
            </ButtonNext>
          </>
        )}
      </div>
    </div>
  )
}
