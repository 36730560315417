import styled from 'styled-components'
import { GoSearch } from 'react-icons/go'

export const SearchContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0.5rem 0;
  font-family: Inter;
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  opacity: ${({ disabled }) => (disabled ? '0.5' : 'inherit')};
`

export const Label = styled.label`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 0 1rem;
  margin-top: -4px;
  font-size: 0.7rem;
  color: #9a9a9a;
  transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
`

export const Icon = styled(GoSearch)`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
`

export const Input = styled.input`
  position: relative;
  font-family: Inter;
  font-weight: normal;
  font-size: 1rem;
  line-height: 160%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: ${({ isOpen }) => (isOpen ? '8px 8px 0 0' : '8px')};
  background: #fefefe;
  border: none;
  box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 0.25);
  padding: 0 1rem;
  color: #353535;
  outline-color: transparent;
  :hover {
    box-shadow: ${({ disabled }) =>
      disabled ? '0px 0px 5px 1px rgba(37, 0, 50, 0.25)' : '0px 0px 5px 1px rgba(37, 0, 50, 0.5)'};
  }
  :focus {
    /* box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 1); */
  }
`

export const DropdownContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  text-align: left;
  background: #f5eafd;
  box-shadow: 0px 2px 5px 1px rgba(37, 0, 50, 0.25);
  border-radius: 0 0 8px 8px;
  z-index: 999 !important;
  overflow-y: auto;
  max-height: 80vh;
  border-top: solid 1px rgba(37, 0, 50, 0.1);
`

export const Option = styled.button`
  width: 100%;
  padding: 0.5rem 1rem;
  text-decoration: none;
  text-align: left;
  border-bottom: 1px solid rgba(153, 142, 201, 0.1);
  :hover {
    background: rgba(37, 0, 50, 0.8);
    color: #fefefe;
  }
  cursor: pointer;
`
