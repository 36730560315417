import React, { useEffect, Fragment, useState } from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { Student } from 'types'
import { InformationCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { Transition, Dialog } from '@headlessui/react'
import { SecondaryButton } from '../components/SecondaryButton'
import { navigate } from '@reach/router'

type Props = {
  handleNextFunction: () => void
  student: Student
  isCheckInAvailable: boolean
  showMessages: boolean
}

const NineToTwelve = ({ handleNextFunction, student, isCheckInAvailable, showMessages }: Props) => {
  let [isOpen, setIsOpen] = useState(false)
  const [notificationsVisible, setNotificationsVisible] = useState(false)
  useEffect(() => {
    const handler = (event: { data: any }) => {
      const data = event.data
      if (data.message === 'notifications_badge_controller:connect') {
        setNotificationsVisible(true)
      }
    }

    window.addEventListener('message', handler)

    // clean up
    return () => window.removeEventListener('message', handler)
  }, []) // empty array => run only once

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  useEffect(() => {
    document.body.style.backgroundImage = `url('/images/moods/nineToTwelve/neutral.png')`
    // @ts-ignore
    document.body.style.backdropFilter = 'blur(65px)'
  }, [])

  return (
    <Screen
      showSummary={false}
      navButton={
        <button className="flex items-center" onClick={() => openModal()}>
          {isCheckInAvailable && (
            <div className="py-1 px-2 bg-white/15 text-gray-100 rounded text-base mr-2 ">
              Why are check-ins important?
            </div>
          )}
          <InformationCircleIcon className="h-6 w-6 text-gray-100"></InformationCircleIcon>
        </button>
      }
      footer={
        <div className="w-full p-4  bg-white/15 rounded-lg backdrop-blur-2xl flex-col justify-center items-start gap-1 inline-flex">
          <div className="self-stretch justify-between items-start inline-flex">
            <p className="text-center text-teal-200 text-xs font-bold font-['Roboto'] uppercase tracking-widest">
              shared data heads-up!
            </p>
          </div>
          <p className="self-stretch text-gray-100 text-lg font-normal font-['Roboto'] leading-snug">
            The information you share here will be shared with staff at your school and may be
            shared with your teachers and parents.
          </p>
        </div>
      }
    >
      <div
        className="max-w-6xl text-center text-teal-200
text-xs font-bold uppercase font-['Roboto'] mb-4"
      >
        {`GOOD TO SEE YOU, ${student.firstName}!`}
      </div>
      {isCheckInAvailable ? (
        <>
          <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto']">
            Are you ready to check in?
          </div>

          <NextButton
            disabled={false}
            handleNextFunction={handleNextFunction}
            additionalClasses="mt-20"
          >
            START MY CHECK-IN
          </NextButton>
          {showMessages && (
            <SecondaryButton
              additionalClasses="mt-4"
              handleNextFunction={() => navigate(`/student/messages`)}
            >
              <span className="relative">
                Messages{' '}
                <iframe
                  src={`${process.env.REACT_APP_API_URL}/app/notifications`}
                  className="inline absolute min-w-0 min-x-0 w-fit h-fit top-[-4px] max-w-20 pointer-events-none"
                  title="Notifications"
                  style={{ visibility: notificationsVisible ? 'visible' : 'hidden' }}
                />
              </span>
            </SecondaryButton>
          )}
          <SecondaryButton
            additionalClasses="mt-4"
            handleNextFunction={() => navigate(`/activities/${student.id}/choose-activity`)}
          >
            Explore Activities
          </SecondaryButton>
        </>
      ) : (
        <>
          <div className="max-w-4xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto']">
            <p className="mb-4">It's after school hours or the weekend!</p>
            <p className="mb-4">
              {' '}
              Feel free to view your profile or complete an activity, but you won't be able to check
              in until the next school day.
            </p>
            <p className="mb-4"> We love you!</p>
          </div>

          <NextButton
            disabled={false}
            handleNextFunction={() =>
              navigate(
                `/check-in-history/${student.id}?hasUnseenMessage=${student?.hasUnseenMessages}`,
              )
            }
            additionalClasses="mt-20"
          >
            GO TO MY PROFILE
          </NextButton>
          {showMessages && (
            <SecondaryButton
              additionalClasses="mt-4"
              handleNextFunction={() => navigate(`/student/messages`)}
            >
              Messages
            </SecondaryButton>
          )}
          <SecondaryButton
            additionalClasses="mt-4"
            handleNextFunction={() => navigate(`/activities/${student.id}/choose-activity`)}
          >
            Explore Activities
          </SecondaryButton>
        </>
      )}

      <WhyModal isOpen={isOpen} closeModal={closeModal} />
    </Screen>
  )
}

export default NineToTwelve

const WhyModal = ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-10 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-center text-black text-2xl font-medium font-['Roboto'] leading-[28.80px] mb-8"
                >
                  Why are check-ins important?
                </Dialog.Title>
                <div className="mt-2 text-zinc-500 text-lg font-normal font-['Roboto'] leading-snug">
                  <p className="mb-4">
                    Daily check-ins are a big deal when it comes to your overall well-being. They
                    help you reflect, understand yourself better, and navigate life with more focus
                    and ease.
                  </p>
                  <p className="mb-4">
                    When you’re feeling good, a check-in lets you look back and figure out what’s
                    making you feel that way. You can create your own recipe for self-care success!
                    When things aren’t great and you’re feeling uncomfortable emotions, the check-in
                    is your go-to to acknowledge them and to clearly see what might be impacting you
                    in a way that's not very supportive. Simply labeling your emotions can decrease
                    the intensity of them. Pretty cool, right? Plus, at the end of the check-in, you
                    can pick from activities that are proven to help you feel better.
                  </p>
                  <p className="mb-4">
                    Now, here’s the best part – check-ins also make asking for help a breeze. We
                    know it’s not always easy to open up when things aren’t going smoothly. But
                    guess what? Your school staff genuinely care about you, and they’re here to lend
                    a hand whenever you need it. No judgment, just support.
                  </p>
                  <p className="mb-4">
                    That’s the scoop for now! We’re thrilled to have you here. So, let’s kickstart a
                    check-in and make today awesome!
                  </p>
                </div>
                <button onClick={closeModal} className="absolute top-4 right-4">
                  <XMarkIcon className="text-zinc-600 h-6 w-6" />
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
}
