import React from 'react'
import { map, some } from 'lodash'
import clsx from 'clsx'
import slugify from 'slugify'

import { CircularItem } from 'checkIn/components/CircularElements'
import Moon from 'checkIn/steps/BodySensations/KidBodySensations/Moon'
import AudioPlayer from 'checkIn/AudioPlayer'
import { BODY_SENSATIONS_PAGE } from 'checkIn/audios'

const FeelingContent = ({
  feelingContent,
  selectedSensations,
  moonStyle,
  isConfirmationPage,
  handleMoon,
  ...props
}) =>
  map(feelingContent?.options, (option, index) => {
    const slug = slugify(option, { lower: true })
    const selected = some(
      selectedSensations,
      (item) => item.toLowerCase() === option.toLowerCase(),
    )

    const { startDeg, endDeg, distance } = props

    return (
      <CircularItem
        key={index}
        style={moonStyle}
        length={feelingContent?.options?.length}
        startDeg={startDeg}
        endDeg={endDeg}
        distance={distance}
        index={index}
      >
        <AudioPlayer
          className={clsx(isConfirmationPage && !selected && 'hidden')}
          styleButton={{ marginLeft: -50, marginBottom: 20 }}
          importAudio={BODY_SENSATIONS_PAGE[slug]}
        >
          <Moon
            key={index}
            handleMoon={handleMoon}
            hideUnselected={isConfirmationPage}
            selected={selected}
            option={option}
            isConfirmationPage={isConfirmationPage}
          />
        </AudioPlayer>
      </CircularItem>
    )
  })

export default FeelingContent
