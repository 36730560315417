import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Checkbox } from '@material-ui/core'
import { isEmpty, map, reduce } from 'lodash'

import Educator from 'Educators/ManageSchool/educator'

const useStyles = makeStyles(() => ({
  row: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const createInitialState = (educators) =>
  reduce(
    educators,
    (result, { educatorId, schoolRole }) => {
      const newResult = { ...result }
      const isOwner = schoolRole === 'owner'
      newResult[educatorId] = {
        newRole: isOwner ? 'owner' : 'viewer',
        checked: isOwner,
        schoolRole,
      }
      return newResult
    },
    [],
  )

const EducatorList = ({ listEducators, handleClassroom }) => {
  const classes = useStyles()
  const [educators, setEducators] = React.useState({})

  const handleEducatorRole = (educatorId, newRole) =>
    setEducators((prevState) => ({
      ...prevState,
      [educatorId]: { ...prevState[educatorId], newRole },
    }))

  const handleEducatorCheck = (educatorId, checked) =>
    setEducators((prevState) => ({
      ...prevState,
      [educatorId]: { ...prevState[educatorId], checked },
    }))

  React.useEffect(() => {
    setEducators(createInitialState(listEducators))
  }, [listEducators])

  React.useEffect(() => {
    if (!isEmpty(educators)) {
      handleClassroom({
        educators: reduce(
          educators,
          (result, { newRole: role, schoolRole, checked }, educatorId) => {
            const isOwner = schoolRole === 'owner'

            if (isOwner || !checked) return result

            return [...result, { educatorId: parseInt(educatorId, 10), role }]
          },
          [],
        ),
      })
    }
  }, [educators]) // eslint-disable-line

  return map(listEducators, (educator) => {
    const isOwner = educator.schoolRole === 'owner'

    return (
      <div key={educator.id} className={classes.row}>
        <Grid style={{ flex: 1 }}>
          <Educator educator={educator} />
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: 208,
          }}
        >
          <Checkbox
            color="primary"
            disabled={isOwner}
            checked={isOwner ? true : educators?.[educator.educatorId]?.checked ?? false}
            onChange={() => {
              handleEducatorCheck(educator.educatorId, !educators?.[educator.educatorId]?.checked)
              handleEducatorRole(educator.educatorId, 'admin')
            }}
          />
        </Grid>
      </div>
    )
  })
}

export default EducatorList
