import React from 'react'
import moment from 'moment-timezone'

const DateTime = ({ date, time }) => (
  <>
    {date && <div>{date}</div>}
    {time && <div>{time}</div>}
  </>
)

const isToday = (date) => date.isSame(moment(), 'day')

const isThisWeek = (date) => date.isSameOrAfter(moment().subtract(6, 'days'), 'day')

const DateTimeFormat = ({ date }) => {
  const newDate = moment.utc(date).clone().tz(moment.tz.guess())

  const time = newDate.format('LT')

  if (isToday(newDate)) return <DateTime time={time} />

  if (isThisWeek(newDate)) return <DateTime date={newDate.format('dddd')} time={time} />

  return <DateTime date={newDate.format('MM/DD/YYYY')} time={time} />
}

export default DateTimeFormat
