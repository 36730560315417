import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { VscTriangleDown } from 'react-icons/vsc'
import { Wrapper, DropdownContent, Options, Button, Label, ErrorMessage } from './styles'

type DropDownProps = {
  label: string
  value: string | null
  options: { [x: string]: string }
  invalid?: boolean
  required?: boolean
  errorMessage?: string
  onChange: (e: any) => void
}

export const Dropdown = ({
  label,
  options,
  onChange,
  invalid = false,
  required = false,
  errorMessage = '',
}: DropDownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const displayedLabel = required ? label + ' *' : label
  const [displayed, setDisplayed] = useState(displayedLabel)
  const wrapper = useRef(null)

  const autoScroll = useCallback(
    // @ts-ignore
    () => isOpen && wrapper.current.scrollIntoView({ behavior: 'smooth' }),
    [isOpen],
  )

  const onClick = () => setIsOpen(!isOpen)

  const handleSelectOption = (key: string) => {
    if (options[key] === 'None') {
      setDisplayed('None selected')
      setIsSelected(false)
      onChange(null)
    } else {
      setDisplayed(options[key])
      setIsSelected(true)
      onChange(key)
    }
  }

  useEffect(() => {
    autoScroll()
  }, [autoScroll, isOpen])

  return (
    <Wrapper onClick={onClick} ref={wrapper}>
      <Button isOpen={isOpen} isSelected={isSelected} invalid={invalid}>
        {displayed}
        {isOpen ? (
          <MdClose size={25} color="#9a9a9a" />
        ) : (
          <VscTriangleDown size={25} color="#9a9a9a" />
        )}
      </Button>
      {isSelected ? <Label invalid={invalid}>{displayedLabel}</Label> : null}
      {isOpen && (
        <DropdownContent required={required}>
          {Object.keys(options).map((key, index) => {
            return (
              <Options key={index} value={key} onClick={() => handleSelectOption(key)}>
                {options[key]}
              </Options>
            )
          })}
        </DropdownContent>
      )}
      {invalid ? <ErrorMessage>{errorMessage}</ErrorMessage> : null}
    </Wrapper>
  )
}
