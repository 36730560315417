import React, { InputHTMLAttributes } from 'react'
import { InputContainer, Label, StyledInput } from './styled'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  label?: string
  setValue?: (email: string) => void
}

export const EmailInput = ({ label, setValue, ...rest }: Props) => (
  <InputContainer>
    <Label>{label || 'Email *'}</Label>
    <StyledInput
      type="email"
      name="email"
      onChange={setValue ? (e) => setValue(e.target.value) : rest.onChange}
      {...rest}
    />
  </InputContainer>
)
