import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import Teen from './Teen'

const SCREEN_NAME = 'preferredContact'

const PreferredContact: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    return <Teen flow={FLOWS.teen[SCREEN_NAME]} />
  }

  return null
}

export default PreferredContact
