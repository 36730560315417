import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import Teen from 'checkIn/steps/TalkToAnAdultResponse/Teen'
import Kid from 'checkIn/steps/TalkToAnAdultResponse/Kid'
import NonReader from 'checkIn/steps/TalkToAnAdultResponse/NonReader'
import { getQSData } from 'QueryStringController'

const SCREEN_NAME = 'talkToAnAdultResponse'

const TalkToAnAdultResponse: RouteComponent = () => {
  const { student } = useCheckIn()

  const { talkToAnAdult } = getQSData()

  const needTalkToAnAdult = talkToAnAdult === 'true'

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    switch (student.studentType) {
      case 'teen':
        return (
          <Teen
            nextFunction={nextFunction}
            needTalkToAnAdult={needTalkToAnAdult}
          />
        )
      case 'kid':
        return (
          <Kid
            nextFunction={nextFunction}
            needTalkToAnAdult={needTalkToAnAdult}
          />
        )
      case 'non-reader':
        return (
          <NonReader
            nextFunction={nextFunction}
            needTalkToAnAdult={needTalkToAnAdult}
          />
        )
    }
  }

  return null
}

export default TalkToAnAdultResponse
