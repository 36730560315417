import React from 'react'
import Image from 'components/ResponsiveImage'
import includes from 'lodash/includes'
import screenContent from 'checkIn/bodySensationsScreenContent'
import SecondaryEmotionButton from 'checkIn/components/teen/SecondaryEmotionButton'

const listOfEmotions = {
  happy: ['overjoyed', 'optimistic', 'thankful', 'proud', 'loved'],
  angry: ['defensive', 'enraged', 'resentful', 'frustrated', 'annoyed'],
  sad: ['lonely', 'disappointed', 'hopeless', 'worthless', 'disconnected'],
  worried: ['cautious', 'anxious', 'fearful', 'bewildered', 'uneasy'],
  insecure: ['timid', 'uncomfortable', 'hesitant', 'ashamed', 'exposed'],
  excited: ['ecstatic', 'amazed', 'joyful', 'inspired', 'passionate'],
  relaxed: ['calm', 'pleased', 'trusting', 'comfortable', 'satisfied'],
  overwhelmed: ['panicked', 'powerless', 'vulnerable', 'restless', 'stressed'],
}

type Props = {
  feeling: keyof typeof screenContent
  selected: Array<string>
  handleOptionClick: (item: string) => void
}

const Teen = ({ feeling, selected, handleOptionClick }: Props) => {
  const imageSrc = screenContent[feeling].image
  const secondaryEmotions = listOfEmotions[feeling]
  return (
    <div className="flex justify-center w-screen my-8">
      <div className="flex flex-col md:flex-row w-[600px] justify-between items-center">
        <div className="w-[200px]">
          <Image src={imageSrc} alt={feeling} />
          <p className="mt-10 text-4xl text-center text-[#FFF5F5] capitalize">{feeling}</p>
        </div>

        <div className="mt-10">
          {secondaryEmotions.map((item) => (
            <div key={item} className="mb-8">
              <SecondaryEmotionButton
                aria-label={`button-${item}`}
                onClick={() => handleOptionClick(item)}
                variant={includes(selected, item.toLowerCase()) ? 'contained' : 'outlined'}
              >
                {item}
              </SecondaryEmotionButton>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Teen
