import React from 'react'
import { Container, makeStyles } from '@material-ui/core'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'

import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'
import Edlink from 'Educators/ManageSchool/NewSchoolYear/SetUp/Edlink'
import NewSchoolYearCalendarSettings from 'Educators/ManageSchool/NewSchoolYear/SetUp/CalendarSettings'
import CompletedSetUp from 'Educators/ManageSchool/NewSchoolYear/SetUp/CompletedSetUp'
import type { School, User } from 'types'
import { Toast } from 'components/Toast'
import { useSchools } from 'Educators/SchoolsContext'
import { useLocalStorage } from 'useLocalStorage'

type Props = { currentUser: User }

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 'column',
    maxWidth: '1156px',
    padding: 0,
  },
  root: {
    width: '100%',
  },
  instructions: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepper: {
    background: 'none',
    marginBottom: '72px',
  },
}))

function getSteps() {
  return ['Roster staff', 'Calendar settings']
}

function getStepContent(stepIndex: number, nextStepFn: Function) {
  switch (stepIndex) {
    case 0:
      return <Edlink nextStepFn={nextStepFn} />
    case 1:
      return <NewSchoolYearCalendarSettings nextStepFn={nextStepFn} />
    default:
      return 'Unknown stepIndex'
  }
}

const InsightsNewSchoolYearSetUp = ({ currentUser }: Props) => {
  const classes = useStyles()
  const { schoolId, school, schools }: { schoolId: number; school: School; schools: any[] } =
    useSchools()
  const [activeStep, setActiveStep] = useLocalStorage(
    `onboardingStepInsightsSchool:${schoolId}`,
    '0',
  )
  const steps = getSteps()

  const handleNext = () => {
    setActiveStep((prevActiveStep: string) => `${parseInt(prevActiveStep) + 1}`)
  }

  const getActiveStep = (): number => {
    if (school.completedOnboarding) {
      return getSteps().length
    } else {
      return parseInt(activeStep)
    }
  }

  return (
    <DashboardScreen
      currentUser={currentUser}
      minimal={true}
      propsHeader={{ propsMenuSchool: { disabled: true } }}
      hideHelperCadet
    >
      <Container className={classes.container}>
        <div className={classes.root}>
          <Stepper activeStep={getActiveStep()} alternativeLabel className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {getActiveStep() === steps.length || school.completedOnboarding ? (
              <CompletedSetUp insights={!school.fromEdlink} chooseSchool={schools.length > 1} />
            ) : (
              <div className={classes.instructions}>
                {getStepContent(getActiveStep(), handleNext)}
              </div>
            )}
          </div>
        </div>
      </Container>
      <Toast />
    </DashboardScreen>
  )
}

export default validateUserFields(InsightsNewSchoolYearSetUp)
