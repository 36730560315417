import React from 'react'
import type { RouteComponent } from 'types'

import NineToTwelve from './NineToTwelve'
import { navigate } from '@reach/router'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import { isStudent } from 'students/helper'
import signOutTeacher from 'checkIn/steps/ThanksForExploring/signOutTeacher'
import { useDispatch } from 'react-redux'

const Emotion: RouteComponent = ({ studentId }) => {
  const [{ currentUser }] = useCurrentUser()
  const dispatch = useDispatch()
  return (
    <NineToTwelve
      role={currentUser.role}
      handleNextFunction={() => {
        if (isStudent(currentUser.role)) {
          navigate(`/check-in-history/${studentId}`)
        } else {
          signOutTeacher(dispatch, currentUser, () => navigate('/sign-out'))()
        }
      }}
    ></NineToTwelve>
  )
}
export default Emotion
