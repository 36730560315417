import React from 'react'
import { ActivitySession } from 'types'
import moment from 'moment'
import styled from 'styled-components'

type Props = {
  activitySession: ActivitySession
}

const ActivitySessionContainer = styled.div`
  margin: 20px 24px;
`

const DetailsContainer = styled.div`
  border-left: 8px solid #6753A0;
  padding: 16px;
`

const ActivitySessionTime = styled.p`
color: #8F8698;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 700;
line-height: 166%;
letter-spacing: 0.4px;
margin-bottom: 8px;
`

const ActivitySessionName = styled.p`
color: rgba(0, 0, 0, 0.87);
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 24px;
font-style: normal;
font-weight: 900;
line-height: 133.4%;
text-transform: capitalize;
`

const ActivitySessionFeelingNow = styled.p`
color: #757575;
font-feature-settings: 'clig' off, 'liga' off;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 20px;
letter-spacing: 0.15px;
`

const FeelingNowValue = styled.span`
color: rgba(0, 0, 0, 0.87);
font-weight: 500;
letter-spacing: 0.1px;
text-transform: capitalize;
`

export const ActivitySessionDetails = ({activitySession}:Props) => {
  return (
    <ActivitySessionContainer>
      <ActivitySessionTime>{moment(activitySession.createdAt).format('LT')} - ACTIVITY</ActivitySessionTime>
      <DetailsContainer>
        <ActivitySessionName>{activitySession.activityName}</ActivitySessionName>
        <ActivitySessionFeelingNow>After activity <FeelingNowValue>{activitySession.feelingNow}</FeelingNowValue></ActivitySessionFeelingNow>
      </DetailsContainer>
    </ActivitySessionContainer>
  )
}