import React, { useEffect } from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { makeStyles } from '@material-ui/core/styles'

import CircularElements, { CircularItem } from 'checkIn/components/CircularElements'

import Image from 'components/ResponsiveImage'
import Screen from 'checkIn/components/teen/Screen'
import planetList from 'checkIn/constants/fiveToEight/planetList'
import FeelingContent from 'checkIn/steps/BodySensations/FiveToEightBodySensations/FeelingContent'

import { getQSData } from 'QueryStringController'
import { xor } from 'lodash'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'
import isComfy from 'emotions'

const useStyles = makeStyles((theme: { breakpoints: { down: (arg0: string) => any } }) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '80vw',
    height: '60vh',
    [theme.breakpoints.down('xs')]: {
      width: '45vw',
      height: 'auto',
    },
  },
  image: {
    display: 'block',
    width: '200px',
    height: '200px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  feelingLabel: {
    color: '#F3F2F8',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '1.6px',
    textTransform: 'uppercase',
  },
}))

type Props = {
  width: Breakpoint
  handleNextFunction: () => void
  selectedSensations: string[]
  setSelectedSensations: (params: string[]) => void
}

const FiveToEightBodySensations = ({
  width,
  handleNextFunction,
  selectedSensations,
  setSelectedSensations,
}: Props) => {
  const classes = useStyles()
  const [isConfirmationPage, setIsConfirmationPage] = React.useState(false)

  const { feeling, bodySensations } = getQSData()

  const feelingContent = planetList.find(
    (planet) => planet.feeling === (feeling as typeof planetList[number]['feeling']),
  )

  const moonStyle = !isWidthUp('sm', width)
    ? {
        position: 'relative',
        transform: null,
        top: 0,
        left: 0,
      }
    : undefined
  const showNextButton = selectedSensations.length > 0

  const handleMoon = (item: string) => {
    setSelectedSensations(xor(selectedSensations, [item]))
  }

  useEffect(() => {
    if (bodySensations) {
      setSelectedSensations(bodySensations.split(','))
    }
  }, [bodySensations]) // eslint-disable-line

  const label =
    selectedSensations?.length > 0
      ? 'Pick as many body sensations as you want!'
      : `You are feeling ${feelingContent?.label}`

  const title = isConfirmationPage
    ? `We all feel ${feeling} sometimes, and that's ${isComfy(feeling) ? 'awesome' : 'okay'}.`
    : 'How does that feel physically?'

  return (
    <Screen label={isConfirmationPage ? 'Thanks for sharing your feelings.' : label} title={title}>
      <CircularElements
        startDeg={-20}
        endDeg={240}
        distance={isWidthUp('md', width) ? '280px' : '220px'}
        className={classes.container}
        style={{ position: 'relative' }}
      >
        <FeelingContent
          startDeg={-20}
          endDeg={240}
          distance="280px"
          isConfirmationPage={isConfirmationPage}
          feelingContent={feelingContent || planetList[0]}
          selectedSensations={selectedSensations}
          moonStyle={moonStyle}
          handleMoon={handleMoon}
        />
        <CircularItem selected>
          <div className={classes.image}>
            <Image src={feelingContent?.src} alt={feeling} />
            <p className={classes.feelingLabel}>{feelingContent?.label}</p>
          </div>
        </CircularItem>
      </CircularElements>
      <BackAndNextButtons
        showBackButton
        hiddenNextButton={!showNextButton}
        nextButtonOnClick={() => {
          isConfirmationPage ? handleNextFunction() : setIsConfirmationPage(true)
        }}
      />
    </Screen>
  )
}

export default withWidth()(FiveToEightBodySensations)
