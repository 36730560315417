import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
// @ts-ignore
import NonReader from './NonReader'
import { navigate } from '@reach/router'

const HowAreYouFeelingNow: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    return <NonReader nextFunction={() => navigate('thanks-for-exploring')} />
  }

  return null
}

export default HowAreYouFeelingNow
