import React from 'react'
import { Fetch } from 'croods'
import map from 'lodash/map'

import Chart from 'Educators/students/insights/Chart'
import Loading from 'components/Loading'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'
import { lightenColor } from 'utils'
import { useScreenSize } from 'screenSize'

export const EnergyLevel = ({ filter, schoolId }) => {
  const { isMobile } = useScreenSize()
  return filter ? (
    <Fetch
      name="energyLevelChart"
      path={`/schools/${schoolId}/insights/fuel/chart?${map(
        pickBy(filter, identity),
        (value, key) => `${key}=${value}`,
      ).join('&')}`}
      renderLoading={Loading}
      render={(chart) => (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          className="div-chart-table-to-print"
        >
          {chart && (
            <Chart
              list={chart}
              listFill={[
                lightenColor('#e9573e', 30),
                lightenColor('#e9573e', 15),
                '#e9573e',
                lightenColor('#e9573e', -30),
                lightenColor('#e9573e', -60),
              ]}
              propsBarChart={{
                className: 'energy-level-chart-to-print',
                width: isMobile ? 320 : 700,
                height: 350,
              }}
            />
          )}
        </div>
      )}
    />
  ) : null
}
