import React from 'react'
import { Grid, makeStyles, Typography } from '@material-ui/core'

import Destroy from './Destroy'
import parseValueToLabel from './options'

const AlertItem = ({ student, alert }) => {
  const classes = useStyles()

  return (
    <Grid item component="li" className={classes.item}>
      <div>
        <Typography
          style={{
            fontSize: '0.5rem',
            color: 'rgba(128, 128, 128, 1)',
          }}
        >
          Created by {alert.createdBy}
        </Typography>
        <Typography>{alertToString({ alert, student })}</Typography>
      </div>

      {alert.abilities.destroy && (
        <Destroy studentId={student.id} alertId={alert.id} />
      )}
    </Grid>
  )
}

const alertToString = ({ alert, student }) => {
  const start = `Notify me when ${student.firstName}`

  let condition

  switch (alert.kind) {
    case 'emotion':
      condition = `reports feeling ${parseValueToLabel(alert.condition)}`
      break
    case 'energy_level':
      condition = `reports ${parseValueToLabel(alert.condition)} energy level`
      break
    default:
      condition = `reports ${parseValueToLabel(alert.condition)}`
      break
  }

  const frequencies = {
    1: 'at least once',
    2: 'two times in a row',
    3: 'three times in a row',
  }

  const frequency = frequencies[alert.frequency]

  return `${start} ${condition} ${frequency}`
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    background:
      'linear-gradient(0deg, rgba(0, 0, 0, 0.08), rgba(0, 0, 0, 0.08)), #FFFFFF',
    padding: theme.spacing(1),
    borderRadius: 4,
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #FFFFFF',
    },
  },
}))

export default AlertItem
