import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'

import ExternalLink from 'components/ExternalLink'
import { timeFilterRange } from 'components/chart/transform'

import { isEducator, isSchoolPremium } from 'students/helper'
import withMixpanel from 'mixpanel/withMixpanel'
import Destroy from 'components/destroy'
import { useCroods } from 'croods'
import { navigate } from '@reach/router'
import { useSchools } from 'Educators/SchoolsContext'
import EditHeader from './EditHeader'
import EditBottom from './EditBottom'
import EditTabHistory from './EditTabHistory'
import EditTabAccount from './EditTabAccount'
import EditTabAlerts from './EditTabAlerts'

import './print.css'

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    zIndex: 1,
    minHeight: 180,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      marginLeft: theme.spacing(-5),
      marginRight: theme.spacing(-5),
    },
  },
  tabs: {
    borderBottomWidth: 0.5,
    borderBottomStyle: 'solid',
    borderBottomColor: theme.palette.text.disabled,
  },
  root: {
    alignSelf: 'flex-start',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  secondaryArea: {
    paddingTop: theme.spacing(11),
    textAlign: 'center',
  },
  secondaryLabel: {
    display: 'block',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textTransform: 'none',
    textDecoration: 'none',
    color: theme.palette.common.white,
  },
}))

const tab = {
  HISTORY: 'History',
  ALERTS: 'Alerts',
  ACCOUNT: 'Account',
}

const BottomAccountTab = ({ studentId }) => {
  const [{ info, destroying, destroyError }, { destroy }] = useCroods({
    id: studentId,
    name: 'students',
    afterSuccess: () => navigate('/dashboard'),
  })

  return (
    <Destroy
      dialogTitle="Are you sure you want to delete this student?"
      renderButtonText={() => `Delete ${info?.firstName}'s Account?`}
      destroying={destroying}
      destroy={destroy}
      destroyError={destroyError}
    />
  )
}

const BottomHistoryTab = ({ classes }) => (
  <div className={classes.secondaryArea}>
    <ExternalLink className={classes.secondaryLabel} href="https://www.closegap.org/contact">
      Contact Closegap
    </ExternalLink>
  </div>
)

const EditStudent = ({ initialState = 'HISTORY', editProps, ...props }) => {
  const { school, setSchoolId } = useSchools()
  const { info: student } = editProps
  const { currentUser, studentId, mixpanel } = props

  const classes = useStyles()
  const [currentTab, setCurrentTab] = useState(initialState)
  const [timeFilter, setTimeFilter] = useState(null)

  const handleTabChange = () => (_event, value) => {
    mixpanel.track(`${tab[value]} Tab Clicked`, { studentId })
    setCurrentTab(value)
  }

  const handleTimeFilter = (value) => setTimeFilter(value)

  const showAlertsTab =
    isEducator(currentUser?.role) && isSchoolPremium(school) && student.schoolId === school.id

  const filterRange = timeFilter && timeFilterRange(timeFilter)
  const query = {
    from: filterRange?.start ?? '',
    to: filterRange?.end ?? '',
  }

  useEffect(() => {
    if (student?.schoolId) setSchoolId(student.schoolId)
  }, [student]) // eslint-disable-line

  const isSchoolOwner = student?.adultsOnAccount?.schoolOwners?.some(
    (schoolOwner) => schoolOwner.id === currentUser?.id,
  )

  const isStudentOwnerOrSchoolOwner = currentUser?.id === student?.ownerId || isSchoolOwner

  return (
    <div className="w-10/12 pl-10 pr-10">
      <EditHeader
        currentUser={currentUser}
        query={query}
        student={student}
        currentTab={currentTab}
      />

      <Paper className={classes.container}>
        <Tabs
          value={currentTab}
          className={classes.tabs}
          onChange={handleTabChange()}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="HISTORY" value="HISTORY" />
          {showAlertsTab && <Tab label="ALERTS" value="ALERTS" />}
          <Tab label="ACCOUNT" value="ACCOUNT" />
        </Tabs>

        {currentTab === 'HISTORY' && (
          <EditTabHistory
            query={query}
            timeFilter={timeFilter}
            handleTimeFilter={handleTimeFilter}
            currentUser={currentUser}
            student={student}
            studentId={studentId}
          />
        )}

        {currentTab === 'ACCOUNT' && <EditTabAccount currentUser={currentUser} {...editProps} />}

        {currentTab === 'ALERTS' && <EditTabAlerts student={student} />}
      </Paper>
      <EditBottom>
        {currentTab === 'HISTORY' && <BottomHistoryTab classes={classes} />}
        {currentTab === 'ACCOUNT' && isStudentOwnerOrSchoolOwner && (
          <BottomAccountTab studentId={student?.id} />
        )}
      </EditBottom>
      <Helmet>
        <script>
          {school &&
            school.demo &&
            `try
          {(function (g, u, i, d, e, s) {
            g[e] = g[e] || []
            var f = u.getElementsByTagName(i)[0]
            var k = u.createElement(i)
            k.async = true
            k.src = 'https://static.userguiding.com/media/user-guiding-' + s + '-embedded.js'
            f.parentNode.insertBefore(k, f)
            if (g[d]) return
            var ug = (g[d] = { q: [] })
            ug.c = function (n) {
              return function () {
                ug.q.push([n, arguments])
              }
            }
            var m = [
              'previewGuide',
              'finishPreview',
              'track',
              'identify',
              'hideChecklist',
              'launchChecklist',
            ]
            for (var j = 0; j < m.length; j += 1) {
              ug[m[j]] = ug.c(m[j])
            }
          })(window, document, 'script', 'userGuiding', 'userGuidingLayer', '226423632ID');}
        catch(e){console.log(e)}`}
        </script>
      </Helmet>
    </div>
  )
}

export default withMixpanel(EditStudent)
