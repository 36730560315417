import React from 'react'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'

import Filter from 'Educators/students/list/panel/filter'
import SelectAll from 'Educators/ManageSchool/ManageStudents/select-all'
import Content from 'Educators/students/list/panel/Content'
import type { Student } from 'types'

import ActionComponent from './action-component'
import { GlobalFilter } from 'Educators/filters'

type Props = {
  list: Student[]
  students: Student[]
  filters: GlobalFilter
  allowSelection?: boolean
  setFilters: (t: any) => void
}
const StudentsList = ({ list, students, filters, allowSelection = true, setFilters }: Props) => {
  const [{ currentUser }] = useCurrentUser()

  return (
    <>
      <Filter title="FILTER STUDENTS" filters={filters} list={list} setFilters={setFilters}>
        {allowSelection && <SelectAll students={students} />}
      </Filter>
      <Content
        isManageStudents
        items={students}
        currentUser={currentUser}
        childReference="Student"
        studentItemProps={allowSelection ? { ActionComponent } : { ActionComponent: () => null }}
        showAvatar={false}
      />
    </>
  )
}

export default StudentsList
