import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import clsx from 'clsx'

import Chart from 'components/FollowUpChart/Chart'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(3),
  },
}))

const FollowUpChart = ({ data }) => {
  const classes = useStyles()

  return (
    <Paper className={clsx(classes.root, 'row-to-print')}>
      <Chart
        data={data}
        propsTooltip={{
          template:
            'Indicated <%= name %> in <%= value %> of <%= total %> check-ins',
        }}
      />
    </Paper>
  )
}

export default FollowUpChart
