import React, { AnchorHTMLAttributes } from 'react'
import styled from 'styled-components'

interface Props extends AnchorHTMLAttributes<HTMLAnchorElement> {
  name: string
}
const StyledAnchor = styled.a`
  color: #d7c9ff;
  transition: all ease 0.25s;
  &:hover {
    color: #b19cd7;
  }
`

export const A = (props: Props) => (
  <StyledAnchor {...props} target="_blank" rel="noopener noreferrer">
    {props.name}
  </StyledAnchor>
)
