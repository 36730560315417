import React from 'react'
import CadetOnBottom from 'components/CadetOnBottomScreen'
import { ButtonNext } from 'components/ButtonNext'
import { Typography } from '@material-ui/core'

const WelcomeBack = () => (
  <CadetOnBottom
    title="Welcome Back to School"
    text="Let’s see how your students are doing!"
  >
    <ButtonNext nextURL="/dashboard">
      <Typography variant="h1" component="span">
        Continue
      </Typography>
    </ButtonNext>
  </CadetOnBottom>
)

export default WelcomeBack
