import React, { ReactNode } from 'react'
import { Link as RouterLink } from '@reach/router'
import styled from 'styled-components'

type Props = {
  to: string
  children: ReactNode
}

const Styled = styled.div<{ selected?: boolean }>`
  display: flex;
  color: #f2f2f2;
  font-size: 0.9rem;
  padding: 0.4rem 0.4rem;
  align-items: center;
  background: ${({ selected }) => (selected ? 'rgba(0,0,0,0.5)' : 'inherit')};
  :hover {
    background: rgba(0, 0, 0, 0.5);
  }
`

export const Link = ({ to, children }: Props) => (
  <RouterLink to={to}>
    <Styled selected={window.location.pathname === to}>{children}</Styled>
  </RouterLink>
)
