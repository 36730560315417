import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { SelectableButton } from '../components/SelectableButton'
import { XMarkIcon } from '@heroicons/react/20/solid'

export const comfortableSecondaryEmotions = [
  'accepted',
  'amazed',
  'cherished',
  'comfortable',
  'courageous',
  'curious',
  'ecstatic',
  'focused',
  'free',
  'independent',
  'inspired',
  'optimistic',
  'overjoyed',
  'passionate',
  'peaceful',
  'playful',
  'pleased',
  'proud',
  'satisfied',
  'successful',
  'thankful',
  'trusting',
]

export const uncomfortableSecondaryEmotions = [
  'anxious',
  'ashamed',
  'cautious',
  'confused',
  'defensive',
  'disappointed',
  'enraged',
  'envious',
  'exposed',
  'frustrated',
  'grumpy',
  'guilty',
  'helpless',
  'hopeless',
  'lonely',
  'powerless',
  'resentful',
  'stressed',
  'uncertain',
  'uncomfortable',
  'worried',
  'worthless',
]

const comfortablePrimaryEmotions = [
  'happy',
  'content',
  'calm',
  'excited',
  'grateful',
  'loved',
  'hopeful',
  'confident',
  'proud',
]

export const neutralSecondaryEmotions = [
  'apathetic',
  'ashamed',
  'bored',
  'chill',
  'comfortable',
  'complacent',
  'content',
  'down',
  'grumpy',
  'indifferent',
  'neutral',
  'normal',
  'peaceful',
  'peeved',
  'pleased',
  'relieved',
  'rested',
  'serene',
  'thoughtful',
  'uncertain',
  'uncomfortable',
  'worried',
]

const neutralPrimaryEmotions = ['bored', 'meh']

function getSecondaryEmotions(feeling: string) {
  if (comfortablePrimaryEmotions.includes(feeling)) {
    return comfortableSecondaryEmotions
  }
  if (neutralPrimaryEmotions.includes(feeling)) {
    return neutralSecondaryEmotions
  }
  return uncomfortableSecondaryEmotions
}

type Props = {
  handleOptionClick: (item: string) => void
  nextFunction: () => void
  feeling: string
  selected: Array<string>
}

export default ({ handleOptionClick, nextFunction, feeling, selected }: Props) => {
  const secondayEmotions = getSecondaryEmotions(feeling)
  return (
    <Screen
      title={`Here are emotions related to feeling ${feeling}.
      Are you feeling any of these?`}
      currentStep={3}
      showBackButton={true}
    >
      <div className="flex flex-wrap w-full max-w-[75rem] gap-4 justify-between sm:justify-center items-center">
        {secondayEmotions
          .filter((se) => se !== feeling)
          .map((emotion, index) => (
            <div key={index} className="relative grow sm:grow-0">
              <SelectableButton
                selected={selected.includes(emotion)}
                onClick={() => handleOptionClick(emotion)}
                additionalClasses=" w-full sm:w-auto"
              >
                {emotion}
              </SelectableButton>
              {selected.includes(emotion) && (
                <button
                  onClick={() => handleOptionClick(emotion)}
                  className="absolute top-0 right-0 translate-x-[25%] translate-y-[-35%] shadow-lg bg-gray-100 rounded-full h-6 w-6 "
                >
                  <XMarkIcon className="text-zinc-600 h-5 w-5 mx-auto" />
                </button>
              )}
            </div>
          ))}
      </div>
      <NextButton disabled={false} handleNextFunction={nextFunction} additionalClasses="mt-20" />
    </Screen>
  )
}
