import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  button: {
    width: '85px',
    height: '45px',
    borderRadius: '10px',
    '& span': {
      maxHeight: 35,
    },

    [theme.breakpoints.up('md')]: {
      borderRadius: '29px',
      width: '118px',
      height: '155px',

      '& span': {
        flexDirection: 'column',
        maxHeight: 'none',
      },
    },
  },
})

const ArrowButton = ({ classes, onClick, children, ...props }) => (
  <Button
    variant="contained"
    onClick={onClick}
    color="primary"
    className={classes.button}
    {...props}
  >
    {children}
  </Button>
)

export default withStyles(styles)(ArrowButton)
