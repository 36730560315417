import React from 'react'

import Screen from 'checkIn/components/nonReader/Screen'
import CadetImage from 'components/CadetImage'
import ButtonNext from 'checkIn/components/ButtonNext'
import { setQSData } from 'QueryStringController'
import { QUESTIONS_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'

export const TalkToAnAdultNonReader = ({ flow }) => {
  const { nextFunction } = flow

  return (
    <Screen
      label="I can go ask for help."
      title="Do you want to talk to an adult?"
      audios={{ autoplay: QUESTIONS_PAGE['talk-to-an-adult'] }}
    >
      <CadetImage
        className="!sticky my-10 !h-60 sm:!h-auto sm:my-20"
        src="/images/cool_down_cadet.png"
        alt="Sad cadet"
      />
      <div className="flex justify-evenly items-center w-[70vw] flex-col sm:flex-row gap-10">
        <AudioPlayer importAudio={QUESTIONS_PAGE['yes-please']}>
          <ButtonNext
            className="w-full max-w-sm whitespace-nowrap"
            onClick={() => {
              setQSData({ talkToAnAdult: true })
              nextFunction()
            }}
          >
            <span className="font-medium">Yes, please!</span>
          </ButtonNext>
        </AudioPlayer>

        <AudioPlayer importAudio={QUESTIONS_PAGE['no-thanks']}>
          <ButtonNext
            className="w-full max-w-sm whitespace-nowrap"
            onClick={() => {
              setQSData({ talkToAnAdult: false })
              nextFunction()
            }}
          >
            <span className="font-medium">No, thanks.</span>
          </ButtonNext>
        </AudioPlayer>
      </div>
    </Screen>
  )
}
