import React from 'react'
import Title from 'components/Title'
import clsx from 'clsx'

type Props = {
  children?: React.ReactNode
  label?: string
  title?: string
  className?: string
}

export default ({ children, label, title, className }: Props) => (
  <main className={clsx('flex flex-col justify-center items-center pt-[6vh]', className)}>
    {label && <h2 className="px-16 text-2xl font-medium text-center text-primary mb-7">{label}</h2>}
    {title && <Title style={{ fontWeight: 400, maxWidth: 1008 }}>{title}</Title>}
    {children}
  </main>
)
