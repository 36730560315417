import React, { memo } from 'react'
import { Link } from '@reach/router'
import PermIdentity from '@material-ui/icons/PermIdentity'

import MenuSchool from 'components/dashboard/MenuSchool'
import MenuButtons from 'components/dashboard/MenuButtons'
import { isEducator } from 'students/helper'
import LogoClosegap from 'icons/LogoClosegap'

import type { User } from 'types'
import { featureEnabled } from 'featureFlags'

type Props = {
  currentUser: User
  minimal?: boolean | undefined
  propsMenuSchool?: {
    disabled?: boolean | undefined
    hidden?: boolean | undefined
    shoAllPremiumSchools?: boolean | undefined
    redirectOnChange?: boolean | undefined
    navigateOnChange?: boolean | undefined
    navigateOnChangeFn?: ((school: any) => string) | undefined
  }
}

const Header = ({ currentUser, propsMenuSchool, minimal }: Props) => {
  const profileLink = () => {
    if (minimal) {
      return (
        <span className="text-lg font-medium tracking-wide text-white">
          <PermIdentity className="text-primary !w-5 !h-5 relative top-[-2px]" />
          Hi {currentUser?.displayName}
        </span>
      )
    } else {
      return (
        <Link to="/dashboard/profile" className="text-lg font-medium tracking-wide text-white">
          <PermIdentity className="text-primary !w-5 !h-5 relative top-[-2px]" />
          {featureEnabled(currentUser, 'example_feature') ? 'Hello' : 'Hi'}{' '}
          {currentUser?.displayName}
        </Link>
      )
    }
  }
  return (
    <header
      data-cy="top-bar"
      className="grid items-center justify-between order-1 h-auto grid-cols-12 gap-4 p-5 bg-secondary md:pr-10 md:pl-10"
    >
      <div className="col-span-6 md:col-span-3">
        <div className="flex items-center">
          <Link to="/dashboard">
            <LogoClosegap htmlColor="white" fontSize="large" className="logo-to-print" />
          </Link>
          {isEducator(currentUser?.role) && <MenuSchool {...propsMenuSchool} />}
        </div>
      </div>
      <div className="order-3 col-span-12 hide-to-print md:col-span-6 md:order-2">
        {isEducator(currentUser?.role) && (
          <nav className="max-w-[450px] m-auto">
            {minimal ? null : <MenuButtons currentUser={currentUser} />}
          </nav>
        )}
      </div>
      <div className="order-2 col-span-6 hide-to-print md:col-span-3 md:order-3">
        <p className="text-right">{profileLink()}</p>
        <p className="text-right">
          <Link
            to="/sign-out?clear=true"
            className="text-xs tracking-tight uppercase text-primary sm:text-sm"
          >
            Log Out
          </Link>
        </p>
      </div>
    </header>
  )
}

export default memo(Header)
