import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import DemoThanks from './DemoThanks'

const ThanksForExploring: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    return <DemoThanks />
  }

  return null
}

export default ThanksForExploring
