import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'

const styles = (theme) => ({
  mobileButton: {
    display: 'block',
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
})

export class FilterMenu extends React.Component {
  state = {
    anchor: null,
  }

  onSelect = (value) => () => {
    this.props.onChange(value)
    this.setState({ anchor: null })
  }

  renderMenu = () => {
    const { buttons } = this.props

    return (
      <Menu
        id="simple-menu"
        anchorEl={this.state.anchor}
        open={Boolean(this.state.anchor)}
        onClose={() => this.setState({ anchor: null })}
      >
        {buttons.map((item) => (
          <MenuItem
            key={item.value}
            aria-label={`time-filter-menu-item-${item.label}`}
            onClick={this.onSelect(item.value)}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    )
  }

  render() {
    const { classes, buttons, selected } = this.props
    const btn = buttons.find((b) => b.value === selected)

    return (
      <>
        <Button
          aria-label="open-menu-button"
          aria-owns="simple-menu"
          aria-haspopup="true"
          className={classes.mobileButton}
          onClick={(evt) => this.setState({ anchor: evt.currentTarget })}
        >
          {selected ? btn.label : 'Filter by Time'}
        </Button>
        {this.renderMenu()}
      </>
    )
  }
}

export default withStyles(styles)(FilterMenu)
