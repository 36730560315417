import React, { useCallback, useEffect, useLayoutEffect } from 'react'
import { useCroods } from 'croods'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'

import Loading from 'components/Loading'
import { useCheckIn } from '../CheckInContext'
import type { RouteComponent } from 'types'
import { navigate } from '@reach/router'
import { isSchoolOnVacation, getPathBasedOnGrade } from 'auth/signIn/vacationHelper'
import { isNinthGradeOrOlder, isOlderTeen } from 'students/helper'
import { getQSData } from 'QueryStringController'

type Props = { studentId?: string }
const NineToTwelveCheckIn: RouteComponent<Props> = ({ studentId, children }) => {
  const { student, handleSetStudent } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  const [{ fetchingInfo }] = useCroods({
    fetchOnMount: true,
    name: 'students',
    id: studentId,
    afterSuccess: ({ data }) => {
      handleSetStudent(data)
    },
    after4xx: () => {
      navigate('/sign-out?clear=false')
    },
  })

  const handleVacationRedirects = useCallback(() => {
    // Enable check-in if the student is logged through adult account
    if (!currentUser?.student) return undefined

    // Enable check-in if the student grade is 11-HEd
    if (isOlderTeen(currentUser.student.grade)) return undefined

    const { student: studentData, school } = currentUser
    const isOnVacation = isSchoolOnVacation(school)
    if (!isOnVacation) return undefined

    const redirectPath = getPathBasedOnGrade(studentData)
    return navigate(redirectPath)
  }, [currentUser])

  const handleNonNineToTwelveRedirect = useCallback(() => {
    if (!!student && !isNinthGradeOrOlder(student.grade)) {
      navigate(`/check-in/${student.id}/welcome`)
    }
  }, [student])

  useEffect(() => {
    handleNonNineToTwelveRedirect()
  }, [handleNonNineToTwelveRedirect])

  useEffect(() => {
    handleVacationRedirects()
  }, [handleVacationRedirects])

  useLayoutEffect(() => {
    const { vibe } = getQSData()
    document.body.style.minHeight = '100vh'
    document.body.style.backgroundImage = `url('/images/moods/nineToTwelve/${
      vibe || 'neutral'
    }.png')`
    if (!!vibe) {
      // @ts-ignore
      document.body.style.backdropFilter = 'blur(100px)'
    }
    document.body.style.backgroundSize = 'cover'
    document.body.style.backgroundRepeat = 'no-repeat'
    document.body.style.backgroundPosition = 'center'
  }, [student, currentUser])

  if (fetchingInfo || !student) return <Loading />

  return <div>{children}</div>
}

export default NineToTwelveCheckIn
