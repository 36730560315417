import type { Student } from 'types'

const NON_READERS = [
  { value: 'PK', label: 'PK' },
  { value: 'K', label: 'K' },
  { value: '1', label: '1' },
  { value: '2', label: '2' },
] as const

const KIDS = [
  { value: '3', label: '3' },
  { value: '4', label: '4' },
] as const

const FIVE_TO_EIGHT = [
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
]

const NINE_TO_TWELVE = [
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
]

const TEENS = [
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
  { value: '11', label: '11' },
  { value: '12', label: '12' },
  { value: 'HEd', label: 'HEd' },
] as const

const GRADES = [...NON_READERS, ...KIDS, ...TEENS] as const

const ALL = GRADES

const getGradesByStudentType = (studentType: Student['studentType']) => {
  if (studentType === 'non-reader') return NON_READERS
  if (studentType === 'kid') return KIDS
  if (studentType === 'teen') return TEENS
  if (studentType === '3-4') return KIDS
  if (studentType === '5-8') return FIVE_TO_EIGHT
  if (studentType === '9-12') return NINE_TO_TWELVE
  return ALL
}

const getStudentTypeByGrade = (studentGrade = ''): Student['studentType'] => {
  const match = (grade: { value: string; label: string }) =>
    grade.value.toLowerCase() === String(studentGrade).toLowerCase()

  if (NON_READERS.find(match)) {
    return 'non-reader'
  }
  if (TEENS.find(match)) {
    return 'teen'
  }

  return 'kid'
}

type CheckInType = Student['studentType']
const isNonReader = (checkInType: CheckInType) => checkInType === 'non-reader'
const isKid = (checkInType: CheckInType) => checkInType === 'kid'
const isTeen = (checkInType: CheckInType) => checkInType === 'teen'

export { NON_READERS, KIDS, TEENS, ALL }
export { isNonReader, isKid, isTeen, getGradesByStudentType, getStudentTypeByGrade }
export default GRADES
