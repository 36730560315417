import React from 'react'
import { useScreenSize } from 'screenSize'
import { Container, DescriptionsContainer, ButtonsContainer, Button, Description } from './styles'

interface Props {
  intensity: number | null
  setIntensity: (value: number) => void
  isKid?: boolean
}

export const IntensitySelector = ({ isKid = false, intensity, setIntensity }: Props) => {
  const { isMobile } = useScreenSize()
  const intensities = [1, 2, 3, 4, 5]
  const onClick = (intensity: number) => {
    setIntensity(intensity)
  }

  const renderDescriptors = () => (
    <DescriptionsContainer>
      <Description>Not much at all.</Description>
      <Description>Medium.</Description>
      <Description>So much!!!</Description>
    </DescriptionsContainer>
  )

  const getColor = () => {
    if (isKid) return '#e9573f'
    return '#7B3CDB'
  }

  return (
    <Container bg={isKid ? '#0E071B' : 'none'}>
      {isMobile ? renderDescriptors() : null}
      <ButtonsContainer>
        {intensities.map((value) => (
          <Button
            key={value}
            color={getColor()}
            darkenAmount={10 - value}
            onClick={() => onClick(value)}
            selected={intensity === value}
          >
            {value}
          </Button>
        ))}
      </ButtonsContainer>
      {isMobile ? null : renderDescriptors()}
    </Container>
  )
}
