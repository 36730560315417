import React, { useCallback, useEffect, useRef, useState } from 'react'
import { MdClose } from 'react-icons/md'
import { VscTriangleDown } from 'react-icons/vsc'
import {
  Wrapper,
  DropdownContent,
  Options,
  Input,
  Label,
  ErrorMessage,
  InputContainer,
} from './styles'

export const DropdownStates = ({ label, options, onChange, required = false, error }: any) => {
  const [written, setWritten] = useState('')
  const [searchedOptions, setSearchedOptions] = useState(options)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const displayedLabel = required ? label + ' *' : label
  const [displayed, setDisplayed] = useState(displayedLabel)
  const wrapper = useRef(null)

  const autoScroll = useCallback(
    // @ts-ignore
    () => isOpen && wrapper.current.scrollIntoView({ behavior: 'smooth' }),
    [isOpen],
  )

  const onClick = () => {
    if (searchedOptions.length > 0) setIsOpen(!isOpen)
  }

  const handleSelectOption = (state: any) => {
    setWritten('')
    setIsSelected(true)
    setDisplayed(state.label)
    onChange(state.value)
  }

  useEffect(() => {
    const result = options.filter((option: any) =>
      option.label.toLowerCase().includes(written.toLowerCase()),
    )
    setSearchedOptions(result)
  }, [written]) // eslint-disable-line

  const handleOnChange = (e: any) => {
    setWritten(e.target.value)
    setIsOpen(searchedOptions.length > 0)
    onChange('')
  }

  useEffect(() => {
    autoScroll()
  }, [autoScroll, isOpen])

  return (
    <Wrapper onClick={onClick} ref={wrapper}>
      <InputContainer isOpen={isOpen} invalid={Boolean(error)}>
        <Input placeholder={displayed} value={written} type="text" onChange={handleOnChange} />
        {isOpen ? (
          <MdClose size={25} color="#9a9a9a" />
        ) : (
          <VscTriangleDown size={25} color="#9a9a9a" />
        )}
      </InputContainer>
      {isSelected || Boolean(written) ? (
        <Label invalid={Boolean(error)}>{displayedLabel}</Label>
      ) : null}
      {isOpen && (
        <DropdownContent required={required}>
          {searchedOptions.map((state: any) => (
            <Options
              key={state.value}
              value={state.value}
              onClick={() => handleSelectOption(state)}
            >
              {state.label}
            </Options>
          ))}
        </DropdownContent>
      )}
      {Boolean(error) ? <ErrorMessage>{error?.message}</ErrorMessage> : null}
    </Wrapper>
  )
}
