import React from 'react'
import Screen from 'checkIn/components/kid/Screen'
import ButtonNext from 'checkIn/components/ButtonNext'
import { ContentByActivity } from 'checkIn/steps/Activities/Kid'

export type Activity = keyof typeof ContentByActivity

type Props = {
  selectActivityFunction: (value: Activity) => void
  label: string
}

const Kid = ({ selectActivityFunction, label }: Props) => {
  const buttonTextClassNames = 'w-64 px-2 md:px-4 font-bold md:w-80'

  return (
    <Screen label={label} title="Which activity sounds good to you right now?">
      <div className="grid grid-cols-1 gap-8 py-10 md:py-16 md:gap-12 md:grid-cols-2">
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('breathing')}
        >
          Breathing!
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('let-it-go')}
        >
          Let it go!
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('shake-it-out')}
        >
          Shake it Out!
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('lets-make-a-list')}
        >
          Let's make a list!
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('power-pose')}
        >
          Power Pose
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('journaling')}
        >
          Journaling
        </ButtonNext>
      </div>
    </Screen>
  )
}

export default Kid
