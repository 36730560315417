import React, { useEffect } from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import Teen from 'checkIn/steps/EmotionConfirmation/Teen'
import Kid from 'checkIn/steps/EmotionConfirmation/Kid'
import NonReader from 'checkIn/steps/EmotionConfirmation/NonReader'
import { getQSData } from 'QueryStringController'
import { checkInTrack } from 'students/helper'
import kidPlanetList from 'checkIn/constants/kid/planetList'
import nonReaderPlanetList from 'checkIn/constants/nonReader/planetList'
import teenPlanetList from 'checkIn/constants/teen/planetList'
import fiveToEightPlanetList from 'checkIn/constants/fiveToEight/planetList'
import threeToFourPlanetList from 'checkIn/constants/threeToFour/planetList'
import { useMixpanel } from 'mixpanel'
import FiveToEight from './FiveToEight'
import { featureEnabled } from 'featureFlags'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
import ThreeToFour from './ThreeToFour'

const SCREEN_NAME = 'confirmEmotion'

const EmotionConfirmation: RouteComponent = () => {
  const mixpanel = useMixpanel()
  const { student } = useCheckIn()
  const [{ currentUser }] = useCurrentUser()

  const { feeling } = getQSData()

  useEffect(() => {
    if (student) {
      checkInTrack(mixpanel, 'Check-in: Confirm How are you feeling now?', student)
    }
  }, [student]) // eslint-disable-line

  if (student) {
    const { nextFunction } = FLOWS[student.studentType][SCREEN_NAME]

    switch (student.studentType) {
      case 'teen':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <FiveToEight
            feeling={feeling as typeof fiveToEightPlanetList[number]['feeling']}
            nextFunction={nextFunction}
          />
        ) : (
          <Teen
            feeling={feeling as typeof teenPlanetList[number]['feeling']}
            nextFunction={nextFunction}
          />
        )
      case 'kid':
        return featureEnabled(currentUser, 'new_teen_check_in') ? (
          <ThreeToFour
            feeling={feeling as typeof threeToFourPlanetList[number]['feeling']}
            nextFunction={nextFunction}
          />
        ) : (
          <Kid
            feeling={feeling as typeof kidPlanetList[number]['feeling']}
            nextFunction={nextFunction}
          />
        )
      case 'non-reader':
        return (
          <NonReader
            feeling={feeling as typeof nonReaderPlanetList[number]['feeling']}
            nextFunction={nextFunction}
          />
        )
    }
  }

  return null
}

export default EmotionConfirmation
