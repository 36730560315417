import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import { FUEL_INTRO_PAGE } from 'checkIn/audios'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const NonReader = ({ nextFunction }: Props) => {
  return (
    <Screen
      label="There is so much to explore!"
      title="Let’s see how much fuel we have in our spaceship."
      audios={FUEL_INTRO_PAGE}
    >
      <img
        className="max-h-[40vh] mt-5"
        src="/images/cadet_running.png"
        alt="Happy cadet"
      />

      <div className="absolute bottom-0 w-full">
        <BackAndNextButtons
          showBackButton
          audios={{
            buttonNext: FUEL_INTRO_PAGE.buttonNext,
            buttonBack: FUEL_INTRO_PAGE.buttonBack,
          }}
          nextButtonOnClick={() => nextFunction(window.location.search)}
        />
      </div>
    </Screen>
  )
}

export default NonReader
