import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import moment from 'moment'

const styles = (theme) => ({
  root: {
    padding: theme.spacing(1),
  },
})

export default withStyles(styles)(({ classes, active, payload }) => {
  if (active) {
    const item = payload[0].payload
    return (
      <Paper className={classes.root}>
        <Typography>{moment(item.createdAt).format('MM/DD/YYYY')}</Typography>
        <Typography color="textSecondary">{item.feeling}</Typography>
      </Paper>
    )
  }

  return null
})
