import React from 'react'
import { Container, Button } from '@material-ui/core'

import Breadcrumbs from 'components/Breadcrumbs'
import Card from 'components/Card'
import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'
import { useFlash } from 'seasoned-flash'

import EducatorList from 'Educators/ManageSchool/CreateClassrooms/StepEducators/EducatorList'
import { navigate } from '@reach/router'
import { useSchools } from 'Educators/SchoolsContext'
import { filter, isEmpty, some } from 'lodash'
import { useCroods } from 'croods'
import LinkInvite from 'Educators/ManageSchool/Classroom/LinkInvite'

const AddEducator = ({ currentUser, location, classroomId, selectedEducators = [] }) => {
  const { success, error } = useFlash()
  const { school, schoolId } = useSchools()
  const [educators, setEducators] = React.useState(selectedEducators)

  const urlRedirect = `/manage-school/classrooms/${classroomId}`

  const [{ list: listEducators }, { fetch: fetchEducators }] = useCroods({
    stateId: `class-${classroomId}`,
    name: 'educators',
  })

  const [{ saving }, { save }] = useCroods({
    stateId: `class-${classroomId}`,
    name: 'educators',
    customPath: `/classrooms/${classroomId}/add_educators`,
    afterSuccess() {
      success('Educator added successfully')
      navigate(urlRedirect)
    },
    afterFailure() {
      error("Failed, the educators weren't added")
    },
  })

  React.useEffect(() => {
    fetchEducators({
      customPath: `/schools/${schoolId}/educators`,
    })()
  }, [schoolId]) // eslint-disable-line

  const classroom = location?.state?.classroom

  React.useEffect(() => {
    if (!classroom) navigate(urlRedirect)
  }, [classroomId]) // eslint-disable-line

  const filteredEducators = React.useMemo(
    () =>
      filter(
        listEducators,
        (item) =>
          item?.schoolRole !== 'owner' &&
          !some(item?.classrooms, { id: parseInt(classroomId, 10) }),
      ),
    [classroomId, listEducators],
  )

  return (
    <DashboardScreen currentUser={currentUser}>
      <Container className="flex flex-col">
        <Breadcrumbs
          paths={[
            {
              children: `Manage ${school?.name}`,
              to: urlRedirect,
            },
          ]}
        >
          Add Educators to Classroom {classroom?.name}
        </Breadcrumbs>
        
        <Card title="EDUCATORS">
          {isEmpty(filteredEducators) ? (
            <LinkInvite
              text="Can't find an educator?"
              href="/manage-school"
              alt="invite educators"
              textLink="Invite more educators to my school!"
            />
          ) : (
            <EducatorList listEducators={filteredEducators} handleClassroom={setEducators} />
          )}
        </Card>
        <div className="flex justify-around py-6">
          <Button color="primary" onClick={() => navigate(urlRedirect)}>
            Cancel
          </Button>
          <Button
            disabled={saving || isEmpty(educators.educators)}
            variant="contained"
            color="primary"
            onClick={() => {
              save()(educators)
            }}
          >
            ADD TO CLASSROOM
          </Button>
        </div>
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(AddEducator)
