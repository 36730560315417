import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from '../../../checkIn/steps/EnergyLevelIntro/Teen'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'

const SCREEN_NAME = 'energyLevelIntro'

const EnergyLevelIntro: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const { nextFunction } = FLOWS['teen'][SCREEN_NAME]

    return <Teen nextFunction={nextFunction} />
  }

  return null
}

export default EnergyLevelIntro
