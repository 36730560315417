import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import {
  Bar,
  Tooltip,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList,
} from 'recharts'

import CustomTooltip from 'components/FollowUpChart/CustomTooltip'

const styles = (theme) => ({
  title: {
    marginBottom: theme.spacing(9),
    marginLeft: theme.spacing(4),
    fontSize: '14px',
  },
})

const CustomTopLabel = ({ x, y, width, fill, value, rotate }) => (
  <text
    x={x + width / 2}
    y={y}
    dy={-6}
    textAnchor="middle"
    fontSize="13"
    fontFamily="Roboto"
    fontWeight="400"
    fill={fill}
    rotate={rotate}
  >
    {value}%
  </text>
)

const FollowUpChart = withStyles(styles)(
  ({ classes, data, propsTooltip, width }) => (
    <>
      <Typography className={classes.title} color="textSecondary">
        FOLLOW UP QUESTIONS
      </Typography>

      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        <div
          style={{ display: 'flex', alignSelf: 'center', marginLeft: '-20px' }}
        >
          <BarChart
            data={data}
            labelLine={false}
            outerRadius={120}
            reverseStackOrder
            width={isWidthUp('md', width) ? 650 : 360}
            height={420}
          >
            <CartesianGrid vertical={false} horizontal={false} />
            <XAxis
              dataKey="name"
              angle={-45}
              textAnchor="end"
              interval={0}
              height={100}
              isAnimationActive={false}
            />
            <YAxis
              tickLine={false}
              ticks={[100]}
              unit="%"
              type="number"
              domain={[0, 'dataMax+5']}
              dataKey={(v) => parseFloat(v.someValue)}
              isAnimationActive={false}
            />
            <Tooltip
              content={<CustomTooltip {...propsTooltip} />}
              cursor={{ fill: 'transparent' }}
            />
            <Bar
              dataKey={() => 100}
              stackId="a"
              fill="#f6f6f6"
              isAnimationActive={false}
            />
            <Bar
              dataKey={({ answered, percentage }) =>
                parseFloat(answered) - parseFloat(percentage)
              }
              stackId="a"
              fill="#ebebeb"
              isAnimationActive={false}
            />
            <Bar
              dataKey="percentage"
              stackId="a"
              fill="#E9573F"
              isAnimationActive={false}
            >
              <LabelList
                fill="#E9573F"
                dataKey="percentage"
                content={<CustomTopLabel />}
              />
            </Bar>
          </BarChart>
        </div>
      </div>
    </>
  ),
)

export default withWidth()(FollowUpChart)
