export const STUDENT_OPTIONS = [
  { value: 'all', label: 'ALL' },
  { value: 'non-reader', label: 'PK-2' },
  { value: 'kid', label: '3-4' },
  { value: 'teen', label: '5-12' },
]

export const STUDENT_OPTIONS_V2 = [
  { value: 'all', label: 'ALL' },
  { value: 'non-reader', label: 'PK-2' },
  { value: '3-4', label: '3-4' },
  { value: '5-8', label: '5-8' },
  { value: '9-12', label: '9-12' },
]
