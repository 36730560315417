import React from 'react'
import Screen from 'checkIn/components/teen/Screen'
import ButtonNext from 'checkIn/components/teen/ButtonNext'
import { ContentByActivity } from 'checkIn/steps/Activities/Teen'

export type Activity = keyof typeof ContentByActivity

type Props = {
  selectActivityFunction: (value: Activity) => void
  label: string
}

const Teen = ({ selectActivityFunction, label }: Props) => {
  const buttonTextClassNames = 'w-[17rem] px-2 md:px-4 md:w-[22rem]'

  return (
    <Screen label={label} title="Which activity sounds good to you right now?">
      <div className="grid grid-cols-1 gap-8 py-10 md:py-16 md:gap-12 md:grid-cols-2">
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('breathing')}
        >
          Breathing!
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('let-it-go')}
        >
          Let it go!
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('safe-space')}
        >
          Safe Space
        </ButtonNext>

        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('the-ok-inventory')}
        >
          The OK Inventory
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('power-pose')}
        >
          Power Pose
        </ButtonNext>
        <ButtonNext
          className={buttonTextClassNames}
          onClick={() => selectActivityFunction('journaling')}
        >
          Journaling
        </ButtonNext>
      </div>
    </Screen>
  )
}

export default Teen
