import { useEffect } from 'react'
import { navigate } from '@reach/router'
import withMixpanel from 'mixpanel/withMixpanel'

const RedirectFromWantsToTalkToAdultEmail = ({ mixpanel, studentId }) => {
  useEffect(() => {
    mixpanel.track('Email alert link clicked', { studentId })
    navigate(`/dashboard/students/${studentId}/edit`)
  })
  return null
}

export default withMixpanel(RedirectFromWantsToTalkToAdultEmail)
