import React from 'react'
import { Link } from '@reach/router'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'

import withMixpanel from 'mixpanel/withMixpanel'

const MyDashboardLink = ({ mixpanel, origin, className }) => (
  <div className="flex items-center">
    <h4 className={`${className} tracking-tight mb-10 text-lg`}>
      <Link
        to="/dashboard"
        className="text-primary"
        onClick={() => {
          origin && mixpanel.track(`Return to Dash from ${origin} Clicked`)
        }}
      >
        <KeyboardArrowLeft className="text-2xl" /> MY DASHBOARD
      </Link>
    </h4>
  </div>
)

export default withMixpanel(MyDashboardLink)
