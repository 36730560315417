import React from 'react'
import { Chip, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import map from 'lodash/map'

const styles = (theme) => ({
  root: {
    marginBottom: 8,
    paddingLeft: 37,
  },
  questions: {
    fontSize: 12,
    color: theme.colors.redDark,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '14px',
    marginRight: theme.spacing(1),
  },
  chip: {
    backgroundColor: theme.colors.redDark,
    marginRight: theme.spacing(1),
  },
})

const Emotions = withStyles(styles)(({ classes, needs, ...props }) => (
  <Grid className={classes.root} {...props} container alignItems="center">
    {map(needs, (need) =>
      need === 'Wants to Talk' ? (
        <Chip
          data-testid="chip-component"
          key={need}
          className={classes.chip}
          label="Wants to Talk"
          color="primary"
          size="small"
        />
      ) : (
        <Typography key={need} className={classes.questions}>
          {need}
        </Typography>
      ),
    )}
  </Grid>
))

export default Emotions
