import React from 'react'
import Screen from 'checkIn/components/kid/Screen'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'
import styled from 'styled-components'
import { BackgroundColor, DarkFilter } from 'checkIn/components/EnergyButton'

const ButtonContainer = styled.div<{ height: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height};
  margin-bottom: 0.25rem;
  color: white;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.4);
`

const contentByFuel = {
  green: {
    title: 'Is your spaceship full of energy and ready for the rest of the day?',
    description: 'Full and Ready',
  },
  yellow: {
    title: 'Is your spaceship not totally full but OK for the rest of the day?',
    description: 'OK',
  },
  blue: {
    title: 'Is your spaceship low in energy and not ready for the rest of the day?',
    description: 'Low',
  },
  red: {
    title: 'Is your ship out of energy and not ready for the rest of the day?',
    description: 'Empty',
  },
} as const

const ENERGY = {
  red: { height: '15%', darkness: 'rgba(0, 0, 0, 0.8)' },
  blue: { height: '35%', darkness: 'rgba(0, 0, 0, 0.45)' },
  yellow: { height: '65%', darkness: 'none' },
  green: { height: '100%', darkness: 'rgba(255, 255, 255, 0.25)' },
} as const

type Props = {
  nextFunction: (value: string) => void
  value: 'red' | 'blue' | 'yellow' | 'green'
}

const Kid = ({ value, nextFunction }: Props) => (
  <Screen
    className="!justify-start h-screen pt-0 md:pt-16"
    label={`You picked ${contentByFuel[value].description}!`}
    title={contentByFuel[value].title}
  >
    <div className="z-10 flex h-[60vh] items-end flex-col-reverse my-0 mx-auto relative text-center bg-gray-300 border-l-8 border-gray-400 px-4 py-6 rounded-3xl max-w-[360px] w-[60%] sm:w-full">
      <ButtonContainer height={ENERGY[value].height}>
        <BackgroundColor />
        <DarkFilter filter={ENERGY[value].darkness}>{contentByFuel[value].description}</DarkFilter>
      </ButtonContainer>
    </div>

    <div className="relative bottom-0 z-0 w-full mt-5 md:mt-10 md:absolute">
      <BackAndNextButtons
        showBackButton
        nextButtonOnClick={() => nextFunction(window.location.search)}
      />
    </div>
  </Screen>
)

export default Kid
