import React, { useEffect } from 'react'

import Screen from '../components/screen'
import planetList from 'checkIn/constants/nineToTwelve/planetList'

import { getQSData } from 'QueryStringController'
import { xor } from 'lodash'
import { NextButton } from '../components/NextButton'

type Props = {
  handleNextFunction: () => void
  selectedSensations: string[]
  setSelectedSensations: (params: string[]) => void
}

const NineToTwelveBodySensations = ({
  handleNextFunction,
  selectedSensations,
  setSelectedSensations,
}: Props) => {
  const { feeling, bodySensations } = getQSData()

  const feelingContent = planetList.find(
    (planet) => planet.feeling === (feeling as typeof planetList[number]['feeling']),
  )

  const showNextButton = selectedSensations.length > 0

  const handleCheckboxChange = (item: string) => {
    setSelectedSensations(xor(selectedSensations, [item]))
  }

  useEffect(() => {
    if (bodySensations) {
      setSelectedSensations(bodySensations.split(','))
    }
  }, [bodySensations]) // eslint-disable-line

  return (
    <Screen
      title={'Take a moment to tune into your body. How are your emotions manifesting physically?'}
      currentStep={4}
      showBackButton={true}
    >
      <div className="flex flex-col gap-3 max-w-96 w-full py-4 sm:py-0">
        {feelingContent?.sensations.map((sensation) => (
          <div key={sensation} className="flex items-center">
            <input
              type="checkbox"
              onChange={() => handleCheckboxChange(sensation)}
              name={sensation}
              id={sensation}
              className="w-4 h-4 form-checkbox rounded-sm accent-white appearance-none checked:appearance-auto border-white border-2 "
              // className= " w-4 h-4 border-white border-2 rounded-sm accent-red checked:accent-black"
            />
            <label
              htmlFor={sensation}
              className="ml-4 text-gray-100 text-base font-bold font-['Roboto'] uppercase tracking-[3.20px]"
            >
              {sensation}
            </label>
          </div>
        ))}
      </div>
      <NextButton
        disabled={!showNextButton}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-auto sm:mt-20"
      />
    </Screen>
  )
}

export default NineToTwelveBodySensations
