import React from 'react'
import moment from 'moment'
import { withStyles } from '@material-ui/core/styles'
import withWidth from '@material-ui/core/withWidth'
import Typography from '@material-ui/core/Typography'
import {
  ResponsiveContainer,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Line,
  Tooltip,
} from 'recharts'

import { colors } from 'theme'
import { fuelToValue, timeFilterRange, getCalendarTime } from './transform'
import YAxisTick from './YAxisTick'
import TooltipContent from './Tooltip'

const styles = () => ({
  title: {
    marginLeft: 70,
    fontSize: '14px',
  },
})

export const formatXAxis = (timeStr) => getCalendarTime(timeStr, 'compact')

export const getXAxisDomain = (timeFilter) => {
  if (timeFilter) {
    const { start, end } = timeFilterRange(timeFilter)
    return [parseInt(start.format('X'), 10) * 1000, parseInt(end.format('X'), 10) * 1000]
  }

  return ['auto', 'auto']
}

const prepareData = (data) =>
  data.map((item) => ({
    ...item,
    createdAt: parseInt(moment(item.createdAt).format('X'), 10) * 1000,
    fuel: fuelToValue(item.fuel) - 0.5,
  }))

export default withWidth()(
  withStyles(styles)(({ width, classes, data, timeFilter }) => (
    <div>
      <Typography className={classes.title} color="textSecondary">
        ENERGY LEVEL
      </Typography>
      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={prepareData(data)}>
          <XAxis
            dataKey="createdAt"
            domain={getXAxisDomain(timeFilter)}
            type="number"
            tickFormatter={formatXAxis}
            tick={{ fill: colors.mardiGras, fontSize: 13 }}
          />
          <YAxis
            width={width === 'xs' ? 10 : 70}
            type="number"
            domain={[0, 5]}
            axisLine={false}
            ticks={[0, 1, 2, 3, 4, 5]}
            tick={<YAxisTick width={width} />}
            allowDataOverflow={true}
            tickMargin={0}
          />
          <Tooltip content={<TooltipContent />} />
          <CartesianGrid stroke={colors.mystic} strokeDasharray="5 5" />
          <Line type="monotone" dataKey="fuel" stroke={colors.cinnabar} activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )),
)
