import React, { useState, useEffect } from 'react'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import withMixpanel from 'mixpanel/withMixpanel'
import PersonIcon from '@material-ui/icons/Person'
import Date from 'Educators/ManageSchool/Vacations/Date'
import moment from 'moment'
import SelectList from 'Educators/students/insights/Filter/SelectList'
import { useCroods } from 'croods'
import { useCurrentUser } from 'dmcroods-auth'
import { useSchools } from 'Educators/SchoolsContext'
import map from 'lodash/map'
import { featureEnabled } from 'featureFlags'

import LIVING_SITUATION from 'livingSituation'
import RACE_ETHNICITY from 'raceEthnicity'
import { getGradesByStudentType } from 'grades'
import ButtonAccordion from 'Educators/students/insights/ButtonAccordion'
import { STUDENT_OPTIONS, STUDENT_OPTIONS_V2 } from 'Educators/students/insights/studentTypes'

const VALUE_ALL = { value: false, label: 'All' }

const Filter = ({ studentType = 'kid', setStudentType, handleFilter, mixpanel }) => {
  const [{ currentUser }] = useCurrentUser()
  const { schoolId } = useSchools()
  const today = moment().format('YYYY-MM-DD')
  const grades = getGradesByStudentType(studentType).map(({ value }) => value)
  const initialState = {
    from: today,
    to: today,
    grades,
    classroom: false,
    englishLanguageLearner: false,
    educationPlan504: false,
    individualizedEducationProgram: false,
    freeReducedPriceMeals: false,
    ethnicity: false,
    livingSituation: false,
  }
  const [filter, setFilter] = useState(initialState)

  const [{ list: classrooms, fetchingList: loadingClassrooms }, { fetch: fetchClassroom }] =
    useCroods({
      cache: true,
      stateId: schoolId,
      name: 'classrooms',
      customPath: `/schools/${schoolId}/classrooms`,
    })

  const setValue = (name, value) =>
    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }))

  const onChange = (event) => {
    const { name, value } = event.target
    mixpanel.track(`Insights Filters: "${name}" changed`, { value })
    setValue(name, value)
  }

  useEffect(() => {
    setValue('grades', grades)
  }, [studentType]) // eslint-disable-line

  useEffect(() => {
    handleFilter(filter)
  }, [filter]) // eslint-disable-line

  useEffect(() => {
    fetchClassroom()()
    setValue('classroom', false)
  }, [schoolId]) // eslint-disable-line

  return (
    <div>
      <div className="grid grid-cols-1 gap-5 mt-4 sm:gap-10 md:grid-cols-3">
        <div className="grid grid-cols-1 gap-5 min-w-min sm:grid-cols-2">
          <Date
            label="From"
            value={filter?.from ?? ''}
            ariaLabel="date-from"
            onChange={(item) =>
              onChange({
                target: { name: 'from', value: item.format('YYYY-MM-DD') },
              })
            }
            style={{ minWidth: 144 }}
          />
          <Date
            label="To"
            value={filter?.to ?? ''}
            ariaLabel="date-to"
            onChange={(item) =>
              onChange({
                target: { name: 'to', value: item.format('YYYY-MM-DD') },
              })
            }
            style={{ minWidth: 144 }}
          />
        </div>
        <SelectList
          label="Check-in*"
          options={
            featureEnabled(currentUser, 'nine_to_twelve_check_in')
              ? STUDENT_OPTIONS_V2
              : STUDENT_OPTIONS
          }
          value={studentType}
          onChange={(event) => setStudentType(event.target.value)}
        />
        <SelectList
          multiple
          label="Grades"
          options={grades}
          value={filter.grades}
          onChange={onChange}
          propsSelect={{
            renderValue: (selected) => selected.join(', '),
            'data-testid': 'select-grades',
            inputProps: {
              id: 'grades',
              name: 'grades',
            },
          }}
        />
      </div>
      <ButtonAccordion
        className="flex flex-col mt-4"
        textLess="Less filters"
        textMore="More filters"
      >
        <div className="flex flex-col gap-5 mt-4 sm:flex-row">
          <div className="w-full max-w-sm">
            <SelectList
              label="Classroom"
              options={[
                VALUE_ALL,
                ...map(classrooms, ({ id, name }) => ({
                  label: name,
                  value: id,
                })),
              ]}
              value={filter.classroom}
              loading={loadingClassrooms}
              onChange={onChange}
              propsSelect={{
                'data-testid': 'select-classroom',
                disabled: schoolId === 'all',
                inputProps: {
                  id: 'classroom',
                  name: 'classroom',
                },
              }}
            />
          </div>
        </div>
        <div className="mt-10 mb-4">
          <h1 className="flex items-center text-sm font-medium text-gray-500">
            <PersonIcon style={{ height: 20, marginRight: 8 }} />
            STUDENT DETAILS
          </h1>
          <div className="grid items-end grid-cols-1 gap-5 mt-4 sm:grid-cols-2 md:grid-cols-3">
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.englishLanguageLearner}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.englishLanguageLearner)
                  }}
                  name="englishLanguageLearner"
                  color="primary"
                />
              }
              label="ELL (English Language Learner)"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.individualizedEducationProgram}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.individualizedEducationProgram)
                  }}
                  name="individualizedEducationProgram"
                  color="primary"
                />
              }
              label="IEP (Individualized Education Program)"
            />
            <div className="order-last md:order-none">
              <SelectList
                label="Race/Ethnicity"
                options={[VALUE_ALL, ...RACE_ETHNICITY]}
                value={filter.ethnicity}
                onChange={onChange}
                propsSelect={{
                  'data-testid': 'select-race-ethnicity',
                  inputProps: {
                    id: 'ethnicity',
                    name: 'ethnicity',
                  },
                }}
              />
            </div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.educationPlan504}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.educationPlan504)
                  }}
                  name="educationPlan504"
                  color="primary"
                />
              }
              label="504 Plan"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={filter.freeReducedPriceMeals}
                  onChange={(event) => {
                    setValue(event.target.name, !filter.freeReducedPriceMeals)
                  }}
                  name="freeReducedPriceMeals"
                  color="primary"
                />
              }
              label="Free / Reduced Price Meals"
            />
            <div className="order-last md:order-none">
              <SelectList
                label="Living Situation"
                options={[VALUE_ALL, ...LIVING_SITUATION]}
                value={filter.livingSituation}
                onChange={onChange}
                propsSelect={{
                  'data-testid': 'select-living-situation',
                  inputProps: {
                    id: 'livingSituation',
                    name: 'livingSituation',
                  },
                }}
              />
            </div>
          </div>
        </div>
      </ButtonAccordion>
    </div>
  )
}

export default withMixpanel(Filter)
