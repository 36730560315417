import React from 'react'
import type { RouteComponent } from 'types'
import { setQSData, getQSData } from 'QueryStringController'
import NineToTwelve from './NineToTwelve'
import { navigate } from '@reach/router'

const Emotion: RouteComponent = () => {
  const nextFunction = (params: string) => navigate(`./secondary-emotions${params}`)
  const { feeling } = getQSData()
  const selectEmotionIntensity = (feeling_intensity: number) => {
    setQSData({ feeling_intensity })
    nextFunction(window.location.search)
  }

  return <NineToTwelve nextFunction={selectEmotionIntensity} feeling={feeling} />
}
export default Emotion
