import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { useMixpanel } from 'mixpanel'
import { useGlobal } from 'useGlobal'
import type { Student } from 'types'

type Props = {
  students: Student[]
}
export default ({ students }: Props) => {
  const [state, actions] = useGlobal()
  const mixpanel = useMixpanel()
  const checked = students.length === state.selectedStudents.length

  function onChange() {
    mixpanel.track('Select All Students Checkbox Clicked', {
      checked: !checked,
    })
    checked
      ? actions.clearSelectedStudents()
      : actions.addStudents(students.map(({ id }) => id))
  }

  return (
    <div className="flex items-center">
      <p className="text-sm font-medium text-gray-500">Select All</p>
      <Checkbox checked={checked} color="primary" onChange={onChange} />
    </div>
  )
}
