import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { SecondaryButton } from '../components/SecondaryButton'
import { navigateWithParams } from 'utils'
import { getQSData } from 'QueryStringController'

type Props = {
  handleNextFunction: () => void
}

const NegativeSummary = ({ handleNextFunction }: Props) => {
  const { cadet } = getQSData()
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        It sounds like you're having a pretty rough day. I'm sorry to hear that. We all feel down
        sometimes. Remember, you don't have to handle it all on your own.
      </div>
      <div className="max-w-6xl text-center text-gray-100 text-2xl font-light font-['Roboto']">
        Do you want to talk to someone?
      </div>
      {cadet === 'true' && (
        <div className="relative mt-10">
          <img className="" src="/images/nineToTwelve/cadet-uncomfy.png" alt="" />
          <div className="absolute top-1/2 left-1/2 -translate-x-1/2 bg-[url('/images/ninetotwelve/planet.png')] bg-center bg-cover bg-no-repeat bg-origin-border w-screen h-[100vw] rounded-full -z-10"></div>
        </div>
      )}
      <NextButton
        disabled={false}
        handleNextFunction={handleNextFunction}
        additionalClasses="mt-20"
      >
        yes, please
      </NextButton>
      <SecondaryButton
        handleNextFunction={() => {
          navigateWithParams('./thanks-for-sharing', { talkToAnAdult: false })
        }}
        additionalClasses="mt-4"
      >
        No, thanks
      </SecondaryButton>
    </Screen>
  )
}

export default NegativeSummary
