import React from 'react'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'
import { Popover } from '@headlessui/react'
import { CheckIn, Role, User } from 'types'
import { xor } from 'lodash'
import { useCroods } from 'croods'
import { XMarkIcon } from '@heroicons/react/16/solid'

type Props = {
  item: CheckIn
  checked: boolean
  onChange: () => void
  currentUser: User
  savingFollowUp: boolean
}

const educatorActions = [
  'In person',
  'By message',
  'I involved a parent/guardian',
  'Emergency services were used',
  'I involved a school psychologist',
  'I involved a school counselor',
  'I involved a school social worker',
  'I surfaced additional issues not shared in the check-in',
]

function getActions(role: Role): string[] {
  switch (role) {
    case 'school-psychologist':
      return educatorActions.filter((action) => action !== 'I involved a school psychologist')
    case 'school-counselor':
      return educatorActions.filter((action) => action !== 'I involved a school counselor')
    case 'school-social-worker':
      return educatorActions.filter((action) => action !== 'I involved a school social worker')
    default:
      return educatorActions
  }
}

const FollowUpActions = ({ item, checked, onChange, currentUser, savingFollowUp }: Props) => {
  const [selectedActions, setSelectedActions] = React.useState<string[]>([])
  const [saved, setSaved] = React.useState(false)
  const actions = getActions(currentUser.role || 'teacher')

  const showDoneButton = selectedActions.length > 0

  const handleCheckboxChange = (item: string) => {
    setSelectedActions(xor(selectedActions, [item]))
  }

  const [{ saving }, { save }] = useCroods({
    name: 'followUpActions',
    path: `check_ins/${item.id}/follow_up_actions`,
  })

  function onSubmit() {
    save({
      afterSuccess: () => {
        setSaved(true)
      },
    })({
      follow_up_action: {
        actions: selectedActions,
      },
    })
  }

  return (
    <Popover className="relative">
      {({ open, close }) => (
        <>
          <Popover.Button>
            <Tooltip title={item.followerFullName || 'Follow Up'}>
              <Checkbox
                checked={checked}
                disabled={
                  !!(
                    currentUser.role === 'student' ||
                    (item.followedUpId && item.followedUpId !== currentUser.id)
                  )
                }
                onChange={() => {
                  if (checked) {
                    close()
                  }
                  if (saved) {
                    setSaved(false)
                  }
                  onChange()
                }}
                color="primary"
              />
            </Tooltip>
          </Popover.Button>
          {open && !savingFollowUp && checked && (
            <Popover.Panel className="absolute top-full right-0 z-10 ">
              <div className="absolute -top-[11px] right-[13px] clip-bottom h-3 w-4 bg-gray-100"></div>
              <button
                onClick={() => {
                  close()
                }}
                className="absolute top-1 right-1"
              >
                <XMarkIcon className="text-zinc-600 h-3 w-3" />
              </button>
              {saved ? (
                <div className="p-4 bg-gray-100 rounded-lg flex flex-col w-[170px] gap-4 shadow-sm">
                  <img
                    src="/images/follow_up_action_success.png"
                    alt="success"
                    className="w-10 h-10 self-center"
                  />
                  <p className="text-center text-purple-950 text-base font-bold font-['Roboto'] leading-normal">
                    Thanks for sharing!
                  </p>
                  <p className=" text-zinc-500 text-xs font-normal font-['Roboto']">
                    Your input enables us to enhance Closegap for you and your students!
                  </p>
                </div>
              ) : (
                <div className="p-4 bg-gray-100 rounded-lg flex flex-col w-[170px] gap-4 shadow-sm">
                  <p className="text-center text-zinc-700 text-xs font-medium font-['Roboto'] leading-[14.40px]">
                    How did you follow up?
                  </p>
                  <div className="flex flex-col gap-2">
                    {actions.map((action) => {
                      return (
                        <div key={action} className="flex items-center">
                          <input
                            type="checkbox"
                            onChange={() => handleCheckboxChange(action)}
                            name={action}
                            id={action}
                            className="w-4 h-4 max-w-4 min-w-4 min-h-4 max-h-4 form-checkbox rounded-sm accent-red-500 appearance-none checked:appearance-auto border-zinc-600 checked:border-red-500 border-2 grow"
                          />
                          <label
                            htmlFor={action}
                            className="ml-4 text-zinc-600 text-[10px] font-normal font-['Roboto'] leading-3"
                          >
                            {action}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                  <button
                    onClick={() => {
                      onSubmit()
                    }}
                    data-disabled={!showDoneButton || saving}
                    disabled={!showDoneButton || saving}
                    className="uppercase self-center w-fit px-2.5 py-1 rounded border text-[13px] font-medium font-['Roboto'] leading-snug tracking-wide bg-red-500 text-gray-50 data-[disabled=true]:bg-neutral-300 data-[disabled=true]:text-neutral-400"
                  >
                    Done
                  </button>
                </div>
              )}
            </Popover.Panel>
          )}
        </>
      )}
    </Popover>
  )
}
export default FollowUpActions
