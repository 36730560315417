import styled from 'styled-components'
import imgBackground from '../assets/footer-image.png'

export const Title = styled.h1`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1.9rem;
  line-height: 160%;
  text-align: center;
  color: #250032;
  margin-top: 6rem;
  @media screen and (max-width: 700px) {
    margin-top: 1rem;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`

export const ImageContainer = styled.div`
  width: 100%;
  min-height: 200px;
  height: 20vh;
  max-width: 100%;
  position: absolute;
  bottom: 0;
  @media screen and (max-width: 700px) {
    max-width: none;
    min-height: auto;
    height: 10vh;
    background-size: cover;
    background-position: right top;
  }
`

export const FooterImage = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  background: url(${imgBackground});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right bottom;
  z-index: 1;
`
export const BottomLine = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  background: #998ec9;
  height: 0.5rem;
  width: 100%;
  z-index: 0;
`
