const HONORIFIC_TITLES = [
  { value: 'Ms.', label: 'Ms.' },
  { value: 'Miss', label: 'Miss' },
  { value: 'Mrs.', label: 'Mrs.' },
  { value: 'Mr.', label: 'Mr.' },
  { value: 'Dr.', label: 'Dr.' },
  { value: 'Prof', label: 'Prof' },
  { value: null, label: 'None' },
]

export const honorificTitles = {
  'Ms.': 'Ms.',
  Miss: 'Miss',
  'Mrs.': 'Mrs.',
  'Mr.': 'Mr.',
  'Dr.': 'Dr.',
  Prof: 'Prof',
  '': 'None',
}

export default HONORIFIC_TITLES
