import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { setQSData } from 'QueryStringController'
import NonReaderBodySensations from './NonReaderBodySensations'
import { MOCK_USER } from '../constants'

const SCREEN_NAME = 'bodySensations'

const BodySensations: RouteComponent = () => {
  const { student } = useCheckIn()
  const [selectedSensations, setSelectedSensations] = React.useState<string[]>([])

  if (student) {
    const { nextFunction } = FLOWS['non-reader'][SCREEN_NAME]

    const handleNextFunction = () => {
      if (selectedSensations?.length > 0)
        setQSData({ bodySensations: selectedSensations.join(',') })

      nextFunction(window.location.search, MOCK_USER)
    }

    return (
      <NonReaderBodySensations
        handleNextFunction={handleNextFunction}
        selectedSensations={selectedSensations}
        setSelectedSensations={setSelectedSensations}
        currentUser={MOCK_USER}
      />
    )
  }

  return null
}

export default BodySensations
