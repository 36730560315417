import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

import StudentsTypography from 'components/StudentsTypography'

const styles = ({ colors, breakpoints, checkinButton }) => ({
  contained: {
    ...checkinButton,

    [breakpoints.down('xs')]: {
      width: '200px',
      height: '51px',
      borderRadius: '17px',
    },
  },
  text: {
    fontSize: '36px',
    color: colors.white,
    textTransform: 'none',
    [breakpoints.down('xs')]: {
      fontSize: '23px',
    },
  },
})

export default withStyles(styles)(({ textProps, ...props }) => (
  <Button
    variant="contained"
    color="primary"
    className={props.classes.contained}
    {...props}
  >
    <StudentsTypography
      component="span"
      className={props.classes.text}
      {...textProps}
    >
      {props.children}
    </StudentsTypography>
  </Button>
))
