import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import NineToTwelve from './NineToTwelve'
import { navigateWithParams } from 'utils'

const SelectColor: RouteComponent = () => {
  const { student } = useCheckIn()

  if (!student) return null

  return <NineToTwelve nextFunction={() => navigateWithParams('./choose-emotion')} />
}

export default SelectColor
