import React, { Fragment, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { SecondaryButton } from '../components/SecondaryButton'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type Contact = {
  id?: number
  displayName: string
}

type Props = {
  handleNextFunction: (preferredContactId?: number) => void
  contacts: Contact[]
}

const NineToTwelve = ({ handleNextFunction, contacts }: Props) => {
  const [selected, setSelected] = useState<Contact>({ displayName: 'Preferred contact' })
  return (
    <Screen showSummary={false}>
      <div className="max-w-6xl text-center text-gray-100 text-[32px] leading-10 font-normal font-['Roboto'] mb-10">
        Who would you prefer to speak to?
      </div>
      <div className="max-w-6xl text-left text-gray-100 text-2xl font-light font-['Roboto']">
        We can't guarantee that your first choice will reach out, but we recommend choosing the
        person you feel most comfortable chatting with.{' '}
      </div>
      <ContactSelector selected={selected} contacts={contacts} onChange={setSelected} />
      <NextButton
        disabled={!selected.id}
        handleNextFunction={() => handleNextFunction(selected.id)}
        additionalClasses="mt-10"
      >
        confirm
      </NextButton>
      <SecondaryButton handleNextFunction={() => handleNextFunction()} additionalClasses="mt-4">
        No preference
      </SecondaryButton>
    </Screen>
  )
}

export default NineToTwelve

const ContactSelector = ({
  selected,
  contacts,
  onChange,
}: {
  selected: Contact
  contacts: Contact[]
  onChange: (arg0: Contact) => void
}) => {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative mt-8 max-w-96 w-full">
            <Listbox.Button className="relative w-full cursor-default rounded-md bg-transparent p-2 pr-10 text-left text-gray-100 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-cyan-500 sm:text-lg sm:leading-6">
              <span className="flex items-center">
                <span className="block truncate">{selected.displayName}</span>
              </span>
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronDownIcon className="h-5 w-5 text-gray-100" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-10 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {contacts.map((contact) => (
                  <Listbox.Option
                    key={contact.id}
                    className={
                      "relative text-zinc-600 text-lg font-light font-['Roboto'] leading-snug p-2"
                    }
                    value={contact}
                  >
                    {({ selected }) => (
                      <>
                        <div className="flex items-center">
                          <span
                            className={classNames(
                              selected ? 'font-normal' : 'font-light',
                              ' block truncate',
                            )}
                          >
                            {contact.displayName}
                          </span>
                        </div>

                        {selected ? (
                          <span
                            className={classNames(
                              'text-teal-500',
                              'absolute inset-y-0 right-0 flex items-center pr-4',
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}
