import { pick, compact, split } from 'lodash'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import { getQSData } from 'QueryStringController'
import { educatorRoles } from 'roles'

export const isTeenager = (gradeStudent) => {
  const grade = String(gradeStudent).toLowerCase()
  return grade >= 6 || grade === 'hed'
}

export const getStudentType = (grade) => {
  switch (grade) {
    case 'PK':
    case 'K':
    case '1':
    case '2':
      return 'non-reader'
    case '3':
    case '4':
      return '3-4'
    case '5':
    case '6':
    case '7':
    case '8':
      return '5-8'
    default:
      return '9-12'
  }
}

export const isPreTeen = (grade) => grade >= 6 && grade <= 10

export const isOlderTeen = (grade) => grade >= 11 || String(grade).toLowerCase() === 'hed'

export const isNinthGradeOrOlder = (grade) => grade >= 9 || String(grade).toLowerCase() === 'hed'

export const welcomePath = ({ id }) => {
  return `/check-in/${id}/welcome`
}

export const checkInStartPath = ({ id }) => {
  return `/check-in/${id}/`
}

const handleEventProps = (propsToTrack, data) => {
  const bodySensationsToTrack = compact(split(data.bodySensations, ','))
  const secondaryEmotionsToTrack = parseList(data.secondaryEmotions)
  let props = { ...propsToTrack }

  if (bodySensationsToTrack.length > 0) props = { ...props, bodySensations: bodySensationsToTrack }

  if (secondaryEmotionsToTrack.length > 0)
    props = { ...props, secondaryEmotions: secondaryEmotionsToTrack }

  return props
}

export const checkInTrack = (mixpanel, eventName, data = {}) => {
  const propsToTrack = {
    ...pick(data, [
      'feeling',
      'fuel',
      'bodySensations',
      'hasEaten',
      'hasSlept',
      'hurtOrSick',
      'okAtHome',
      'bulliedAtSchool',
      'feelingStressed',
      'feelingLonely',
      'overwhelmedByWorkload',
      'okInSocialLife',
      'feelingTired',
      'feelingWorthless',
      'talkToAnAdult',
      'intervention',
      'secondaryEmotions',
      'minutesOffset',
      'studentId',
      'grade',
      'feelingAfterIntervention',
      'talkToAdult',
      'feelingNow',
      'userId',
    ]),
    ...getQSData(),
  }

  const eventProps = handleEventProps(propsToTrack, data)
  const studentInfo = getStudentInfo()
  mixpanel.track(eventName, { ...eventProps, ...studentInfo })
}

export const setLocalStorageCheckIn = (key, value) => {
  const checkIn = JSON.parse(localStorage.getItem('checkIn'))
  localStorage.setItem('checkIn', JSON.stringify({ ...checkIn, [key]: value }))
}

export const getLocalStorageCheckIn = (key) => {
  const checkIn = localStorage.getItem('checkIn')
  return get(JSON.parse(checkIn), key, null)
}

export const resetCheckInIdempotencyKey = async () => {
  const newKey = uuidv4()
  sessionStorage.setItem('checkInIdempotencyKey', newKey)
  localStorage.setItem('checkInIdempotencyKey', newKey)
}

export const getCheckInIdempotencyKey = () => {
  const key =
    sessionStorage.getItem('checkInIdempotencyKey') || localStorage.getItem('checkInIdempotencyKey')
  if (isNil(key)) {
    const newKey = uuidv4()
    sessionStorage.setItem('checkInIdempotencyKey', newKey)
    localStorage.setItem('checkInIdempotencyKey', newKey)
    return newKey
  }
  return key
}

export const getStudentInfo = () => {
  try {
    const info = JSON.parse(sessionStorage.getItem('check-in_student_info'))
    return info || {}
  } catch (error) {
    return {}
  }
}

export const setStudentInfo = (newInfo) => {
  const oldInfo = getStudentInfo()
  sessionStorage.setItem('check-in_student_info', JSON.stringify({ ...oldInfo, ...newInfo }))
}

export const parseList = (value) => (value === 'null' ? [] : compact(split(value, ',')))

const isWeekend = (dateMoment) => dateMoment.day() % 6 === 0

export const isWeekendToday = () => isWeekend(moment())

export const isParent = (role) => role === 'parent-guardian'

export const isTherapist = (role) => role === 'clinician-therapist'

export const isEducator = (role) => Object.keys(educatorRoles).includes(role)

export const isStudent = (role) => role === 'student'

export const isPremium = (currentUser) => isSchoolPremium(currentUser?.school ?? false)

export const isSchoolPremium = (school) => school?.premium ?? false
