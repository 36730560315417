import React, { ChangeEvent, useState } from 'react'
import { TextField, makeStyles } from '@material-ui/core'

import { getLocalStorageCheckIn, setLocalStorageCheckIn } from 'students/helper'

import { CheckInContainer } from 'checkIn/components/teen/CheckInContainer'

import styled from 'styled-components'

type Props = {
  nextFunction: (impactMeasurement?: boolean) => Promise<void>
  save: (options: any) => (value: any) => void
  saving: boolean
}

const useStyles = makeStyles(() => ({
  cssUnderline: {
    color: 'white',
    borderBottom: '1px solid white',
  },
  cssFocused: {
    '&:after': {
      borderBottomColor: 'white',
    },
  },
  cssLabel: {
    left: 'initial',
    color: 'white',
    '&$cssFocused': {
      color: 'white',
    },
  },
}))

const ColorButton = styled.button<{ background: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 32px;
  font-weight: 700;
  min-height: 80px;
  width: 240px;
  background: ${({ background }) => background};
  border-radius: 16px;
  transition: all ease 0.25s;
  :hover {
    filter: brightness(1.2);
    outline-offset: 0.4rem;
  }
  :disabled {
    filter: brightness(0.5);
  }
  @media screen and (max-width: 600px) {
    min-height: 4rem;
    min-width: 10rem;
  }
`

const LinkButton = styled.button<{ color: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => color};
  font-size: 32px;
  font-weight: 700;
  min-height: 80px;
  width: 240px;
  background: none;
  border-radius: 16px;
  transition: all ease 0.25s;
  margin-top: 16px;
  :hover {
    filter: brightness(1.2);
    outline-offset: 0.4rem;
  }
  :disabled {
    filter: brightness(0.5);
  }
  @media screen and (max-width: 600px) {
    min-height: 4rem;
    min-width: 10rem;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  margin-top: 200px;
  margin-bottom: 200px;
  @media screen and (max-width: 600px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`

const CharacterCounter = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  left: initial;
  color: white;
`

const SubTitle = styled.p`
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
  letter-spacing: 0.5px;
`

const Title = styled.p`
  color: #fff;
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Roboto;
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 57.6px */
  letter-spacing: 0.5px;
`

const Kid = ({ nextFunction, save, saving }: Props) => {
  const characterLimit = 100
  const [goal, setGoal] = useState<string>('')
  const classes = useStyles()
  const color = getLocalStorageCheckIn('color') || '#03b2ad'

  const onChange = (e: ChangeEvent<HTMLTextAreaElement>) =>
    e.target.value.toString().length <= characterLimit ? setGoal(e.target.value) : null

  const onClick = () => {
    save({
      afterSuccess: () => {
        setLocalStorageCheckIn('impactQ1', null)
        setLocalStorageCheckIn('impactQ2', null)
        setLocalStorageCheckIn('impactQ3', null)
        setLocalStorageCheckIn('impactQ4', null)
        setLocalStorageCheckIn('impactQ5', null)
        nextFunction()
      },
    })({
      impactMeasurement: {
        question1: getLocalStorageCheckIn('impactQ1'),
        question2: getLocalStorageCheckIn('impactQ2'),
        question3: getLocalStorageCheckIn('impactQ3'),
        question4: getLocalStorageCheckIn('impactQ4'),
        question5: getLocalStorageCheckIn('impactQ5'),
        question6: goal,
      },
    })
  }

  return (
    <CheckInContainer bottom bg="">
      <Title>What would make you feel closer to the adults at your school?</Title>
      <SubTitle>Share any thoughts that you have. We can't wait to hear what you think!</SubTitle>
      <Content>
        <TextField
          autoFocus
          id="text-here"
          label="Type here"
          multiline
          rowsMax="5"
          value={goal}
          onChange={onChange}
          className="w-full"
          InputLabelProps={{ classes: { root: classes.cssLabel, focused: classes.cssFocused } }}
          InputProps={{
            classes: { focused: classes.cssFocused, underline: classes.cssUnderline },
            style: { fontSize: '1.1rem', lineHeight: '160%' },
          }}
        />
        <CharacterCounter>
          {String(goal).length} / {characterLimit}
        </CharacterCounter>
      </Content>
      <ColorButton
        background={color}
        disabled={String(goal).length < 1 || saving}
        onClick={() => onClick()}
      >
        Next
      </ColorButton>
      <LinkButton
        color={color}
        disabled={String(goal).length > 0 || saving}
        onClick={() => onClick()}
      >
        Skip
      </LinkButton>
    </CheckInContainer>
  )
}

export default Kid
