import React from 'react'
import MaskedInput from 'react-text-mask'
import { withStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'

const styles = () => ({
  formControl: {
    width: '100%',
  },
})

export const TextMaskCustom =
  (mask) =>
  ({ inputRef, ...props }) =>
    (
      <MaskedInput
        {...props}
        mask={mask}
        guide={false}
        placeholderChar={'\u2000'}
        showMask
        ref={(ref) => inputRef(ref ? ref.inputElement : null)}
      />
    )

class MaskedTextField extends React.Component {
  constructor(props) {
    super(props)

    this.MaskInput = TextMaskCustom(props.mask)
  }

  render() {
    const { touched, error } = this.props.meta

    return (
      <FormControl
        className={this.props.classes.formControl}
        error={touched && !!error}
      >
        <InputLabel htmlFor={this.props.id}>{this.props.label}</InputLabel>
        <Input
          data-testid="mask-input"
          data-cy={this?.props?.['data-cy'] ?? this.props.name}
          {...this.props.input}
          id={this.props.id}
          name={this.props.name}
          inputComponent={this.MaskInput}
        />

        <FormHelperText id={`${this.props.input.name}-text`}>
          {touched && error}
        </FormHelperText>
      </FormControl>
    )
  }
}

export default withStyles(styles)(MaskedTextField)
