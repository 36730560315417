import React from 'react'
import { Container, makeStyles, Typography } from '@material-ui/core'

import { DashboardScreen } from 'components/DashboardScreen'
import validateUserFields from 'auth/validateUserFields'

import type { User } from 'types'
import { PrimaryButton } from '../Components/buttons'
import { navigate } from '@reach/router'
import { useSchools } from 'Educators/SchoolsContext'

type Props = { currentUser: User }

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '16px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '40px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  video: {
    marginBottom: '40px',
  },
}))

const NewSchoolYearWelcome = ({ currentUser }: Props) => {
  const allSchoolData = useSchools()
  const classes = useStyles()

  const navigateToNextStep = () => {
    if (allSchoolData.role === 'owner') {
      if (allSchoolData.school.premium) {
        navigate('/manage-school/new-school-year/insights-school/set-up')
      } else {
        navigate('/manage-school/new-school-year/new-school/set-up')
      }
    } else {
      navigate('/manage-school/new-school-year/working-on-it')
    }
  }

  const onClick = () => {
    if (allSchoolData.schools.length > 1) {
      navigate('/manage-school/new-school-year/choose-school')
      return
    }
    navigateToNextStep()
  }

  return (
    <DashboardScreen currentUser={currentUser} minimal={true}>
      <Container className={classes.container}>
        <Typography className={classes.heading} noWrap>
          Welcome to Closegap!
        </Typography>
        <Typography className={classes.subTitle}>
          It’s great to see you! We hope you feel rested and refreshed after the break. Let’s get
          ready for the new school year together!
        </Typography>

        <div className={classes.video}>
          <iframe
            width="684"
            height="384"
            src={`https://www.youtube.com/embed/eo47ydGzNtM?autoplay=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
          />
        </div>
        <PrimaryButton disabled={false} onClick={() => onClick()}>
          Thanks, Continue
        </PrimaryButton>
      </Container>
    </DashboardScreen>
  )
}

export default validateUserFields(NewSchoolYearWelcome)
