import React from 'react'
import { MdSchool } from 'react-icons/md'

import { useScreenSize } from 'screenSize'
import { Layout } from '../components/Layout'
import { LogInHereMessage } from '../components/LogInHereMessage'
import { ShareClosegap } from '../components/ShareClosegap'
import {
  AlignLeft,
  ExternalLink,
  InternalLink,
  LinkMessageContainer,
  Text,
  Title,
} from '../components/styled'
import { CadetImage, IconContainer, Container, SubTitle, BackLinkContainer } from './style'
import { RouteComponent } from 'types'

export const SignUpStudent: RouteComponent = () => {
  const { isMobile } = useScreenSize()
  return (
    <Layout>
      {isMobile ? null : <CadetImage />}

      <IconContainer>
        <MdSchool size={isMobile ? 50 : 100} color="#998ec9" />
      </IconContainer>

      <Container>
        <BackLinkContainer>
          <InternalLink to="/sign-up">{`<-- Back`}</InternalLink>
        </BackLinkContainer>
        <Title>
          Hey there student!
          <br />
          We’re so glad you’re here!
        </Title>

        <LogInHereMessage />

        <AlignLeft>
          <Text>
            Students aren’t permitted to create accounts on their own just yet. Someone at your
            school has to create an account for you! Feel free to share Closegap with a teacher or
            your school counselor so you can start using it. We can’t wait to meet you!
          </Text>
        </AlignLeft>

        <SubTitle>Share Closegap with someone at your school!</SubTitle>

        <ShareClosegap />

        <LinkMessageContainer>
          <Text style={{ marginRight: '0.4rem' }}>Learn more about Closegap</Text>
          <ExternalLink href="https://www.closegap.org/students">here!</ExternalLink>
        </LinkMessageContainer>
      </Container>
    </Layout>
  )
}
