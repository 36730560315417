import React from 'react'
import { toast, ToastOptions } from 'react-toastify'

import { StyledToast } from './style'

const success = (message: string, options?: ToastOptions) => toast.success(message, options)

const error = (message: string, options?: ToastOptions) => toast.error(message, options)

const info = (message: string, options?: ToastOptions) => toast.info(message, options)

const warning = (message: string, options?: ToastOptions) =>
  toast.warning(message, { theme: 'light', ...options })

const Toast = () => (
  <StyledToast
    theme="colored"
    position={toast.POSITION.BOTTOM_LEFT}
    autoClose={10000}
    hideProgressBar
    newestOnTop
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    limit={5}
    // icon={false}
  />
)

export { success, error, info, warning, Toast }
