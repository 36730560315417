import React from 'react'
import { Fetch } from 'croods'
import map from 'lodash/map'
import fill from 'lodash/fill'

import Chart from 'Educators/students/insights/Chart'
import Loading from 'components/Loading'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

const Emotions = ({ filter, schoolId }) =>
  filter && (
    <Fetch
      name="emotionsChart"
      path={`/schools/${schoolId}/insights/emotions/chart?${map(
        pickBy(filter, identity),
        (value, key) => `${key}=${value}`,
      ).join('&')}`}
      renderLoading={() => <Loading />}
      render={(chart) => (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          className="div-chart-table-to-print"
        >
          {chart && chart.length === 0 && <p style={{
            textAlign: 'center',
            fontSize: '28px',
            margin: '100px 0',
            textTransform: 'uppercase',
            color: 'rgba(0, 0, 0, 0.54)',
          }}>No data to display</p>}
          {chart && chart.length > 0 && (
            <Chart
              list={chart}
              listFill={fill([...chart], '#998EC9')}
              propsXAxis={{
                angle: -45,
                textAnchor: 'end',
                interval: 0,
                height: 100,
                tick: {},
                tickLine: true,
              }}
              propsBarChart={{
                className: 'emotions-chart-to-print',
                width: 800,
                height: 420,
              }}
            />
          )}
        </div>
      )}
    />
  )

export default Emotions
