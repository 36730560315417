import styled from 'styled-components'

export const Subtitle = styled.h2<{ color?: string }>`
  /* font-family: 'Ramaraja', serif; */
  font-size: 2rem;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  color: ${({ color }) => (Boolean(color) ? color : '#fff')};
  line-height: 160%;
  text-shadow: -1px 1px 4px #000;
  @media screen and (max-width: 600px) {
    font-size: 1.5rem;
  }
`
