import React from 'react'
import Screen from 'checkIn/components/kid/Screen'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'
import { EnergyButton } from 'checkIn/components/EnergyButton'
import FUEL from 'fuel'

const listFuelLink = Object.values(FUEL)

type Props = {
  selectFuelFunction: (value: string) => void
}

const Kid = ({ selectFuelFunction }: Props) => (
  <Screen
    className="!justify-start h-screen pt-0 md:pt-16"
    label="Pick the color that matches your energy level."
    title="How much fuel is in your spaceship?"
  >
    <div className="z-10 flex h-[60vh] items-end flex-col my-0 mx-auto relative text-center mb-1 bg-gray-300 border-l-8 border-gray-400 px-4 py-6 rounded-3xl max-w-[360px] w-[60%] sm:w-full">
      {listFuelLink.map(({ value, label }) => (
        <EnergyButton key={value} value={value} onClick={selectFuelFunction}>
          {label}
        </EnergyButton>
      ))}
    </div>

    <div className="relative bottom-0 z-0 w-full mt-5 md:mt-10 md:absolute">
      <BackAndNextButtons showBackButton hiddenNextButton />
    </div>
  </Screen>
)

export default Kid
