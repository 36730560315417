import React from 'react'

type Props = {
  title: string
  text: string
  backgroundImage?: string
}

const CadetOnBottomScreen: React.FC<Props> = ({
  title = '',
  text = '',
  backgroundImage = "url('/images/cadet_on_the_moon.png')",
  children,
}) => (
  <main
    className="bg-left-bottom bg-no-repeat bg-fixed justify-center items-center h-[100vh] flex w-[100vw] bg-40%"
    style={{
      backgroundImage,
    }}
  >
    <div className="m-4 ballon-check-in">
      <h1 className="text-center mb-10 font-medium text-sm sm:text-lg text-[#616161]">
        {title}
      </h1>
      <h2 className="text-4xl text-center text-[#231D31] mb-10 pl-2 pr-2 max-w-[500px] font-normal sm:text-3xl">
        {text}
      </h2>
      {children}
    </div>
  </main>
)

export default CadetOnBottomScreen
