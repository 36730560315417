import React from 'react'
import { Title, P } from '../components/styled'

export const SchoolInfo = ({ school }: any) => (
  <div>
    <Title>
      {school.name} (id: {school.schoolId})
    </Title>
    <P>
      <strong>Address:</strong> {school.address}
    </P>
    <P>
      <strong>District: </strong>
      {Boolean(school.district)
        ? `${school.district?.name} (id: ${school.district?.id}, state: ${school.district?.state})`
        : "Don't have district"}
    </P>
    <P>
      <strong>Created at:</strong> {school.createdAt}
    </P>
    <P>
      <strong>NCES schools:</strong>{' '}
      {school.ncesSchools.length > 0 ? school.ncesSchools.join(', ') : 'N/A'}
    </P>
  </div>
)
