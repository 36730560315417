import React from 'react'
import Screen from 'checkIn/components/kid/Screen'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'

type Props = {
  flow: { nextFunction: (params: string) => Promise<void> }
}

const Kid = (props: Props) => {
  const { nextFunction } = props.flow

  const handleNextFunction = () => {
    nextFunction(window.location.search)
  }

  return (
    <Screen
      label="That's great"
      title="Yay! I'm happy to know that you're doing well!"
    >
      <img
        className="h-[35vh] mt-28"
        src="/images/happy_cadet_left_shadow.png"
        alt="Happy cadet"
      />

      <div className="absolute bottom-0 w-full">
        <BackAndNextButtons
          nextButtonText="OK!"
          nextButtonOnClick={handleNextFunction}
        />
      </div>
    </Screen>
  )
}

export default Kid
