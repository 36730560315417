import * as React from 'react'
// @ts-ignore
import { MixpanelConsumer } from 'react-mixpanel'

import type { Mixpanel } from './'

function withMixpanel(Component: React.JSXElementConstructor<any>) {
  return (props: Record<string, unknown>) => (
    <MixpanelConsumer>
      {(mixpanel: Mixpanel) => <Component {...props} mixpanel={mixpanel} />}
    </MixpanelConsumer>
  )
}

export default withMixpanel
