import React from 'react'
import styled from 'styled-components'

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-size: 1.3rem;
  color: #b00013;
`

export default ({ children, ...props }) =>
  children ? <ErrorContainer {...props}>{children}</ErrorContainer> : null
