import React from 'react'
import Screen from '../components/screen'
import { NextButton } from '../components/NextButton'
import { SelectableButton } from '../components/SelectableButton'
import { XMarkIcon } from '@heroicons/react/20/solid'

type Props = {
  handleOptionClick: (item: string) => void
  nextFunction: () => void
  selected: Array<string>
}

const content = {
  'emotional well-being': [
    'stress',
    'loneliness',
    'anxiety',
    'low self-worth',
    'gender identity',
    'belonging',
  ],
  'physical well-being': [
    'hunger',
    'hurt/sick',
    'tired',
    'body image',
    'sleep quality',
    'sexual health',
  ],
  'social and relationship well-being': [
    'family issues',
    'peer issues',
    'social life',
    'digital wellness',
    'romantic issues',
  ],
  'financial, academic and the future': [
    'financial issues',
    'future concerns',
    'homework',
    'academic pressure',
  ],
}

export const allNineToTwelveNeeds = Object.values(content).flat()

const NineToTwelve = ({ selected, nextFunction, handleOptionClick }: Props) => {
  return (
    <Screen
      currentStep={6}
      title="Are you struggling with any of these things?"
      showBackButton={true}
    >
      <div className="flex flex-col gap-16 max-w-6xl">
        {Object.entries(content).map(([title, needs]) => (
          <div key={title} className="flex flex-col gap-4">
            <div className="text-gray-100 text-xs font-bold font-['Roboto'] uppercase leading-none tracking-widest">
              <p>{title}</p>
            </div>
            <div className="flex gap-4 flex-wrap">
              {needs.map((need) => (
                <div key={need} className="relative">
                  <SelectableButton
                    selected={selected.includes(need)}
                    onClick={() => handleOptionClick(need)}
                  >
                    {need}
                  </SelectableButton>
                  {selected.includes(need) && (
                    <button
                      onClick={() => handleOptionClick(need)}
                      className="absolute top-0 right-0 translate-x-[25%] translate-y-[-35%] shadow-lg bg-gray-100 rounded-full h-6 w-6 "
                    >
                      <XMarkIcon className="text-zinc-600 h-5 w-5 mx-auto" />
                    </button>
                  )}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>

      <NextButton handleNextFunction={nextFunction} additionalClasses="mt-16" />
    </Screen>
  )
}

export default NineToTwelve
