import React, { useEffect } from 'react'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { withStyles } from '@material-ui/core/styles'
import { checkInTrack } from 'students/helper'

import CircularElements, {
  CircularItem,
} from 'checkIn/components/CircularElements'

import withMixpanel from 'mixpanel/withMixpanel'
import Image from 'components/ResponsiveImage'
import Screen from 'checkIn/components/teen/Screen'

import screenContent from 'checkIn/bodySensationsScreenContent'
import FeelingContent from 'checkIn/steps/BodySensations/TeenBodySensations/FeelingContent'

import { useCheckIn } from 'checkIn/CheckInContext'
import { getQSData } from 'QueryStringController'
import { xor } from 'lodash'
import BackAndNextButtons from 'checkIn/components/teen/BackAndNextButtons'

const styles = (theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '80vw',
    height: '60vh',
    [theme.breakpoints.down('xs')]: {
      width: '45vw',
      height: 'auto',
    },
  },
  image: {
    display: 'block',
    width: '200px',
    height: '200px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
})

const BodySensations = ({
  width,
  classes,
  mixpanel,
  handleNextFunction,
  selectedSensations,
  setSelectedSensations,
}) => {
  const [isConfirmationPage, setIsConfirmationPage] = React.useState(false)

  const { feeling, bodySensations } = getQSData()

  const feelingContent = screenContent[feeling] || { label: '', options: [] }

  const { student } = useCheckIn()

  const moonStyle = !isWidthUp('sm', width)
    ? {
        position: 'relative',
        transform: null,
        top: 0,
        left: 0,
      }
    : null
  const showNextButton = selectedSensations.length > 0

  const handleMoon = (item) => {
    setSelectedSensations((prevState) => xor(prevState, [item]))
  }

  useEffect(() => {
    checkInTrack(mixpanel, 'Check-in: How does that feel physically?', student)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (bodySensations) {
      setSelectedSensations(bodySensations.split(','))
    }
  }, [bodySensations]) // eslint-disable-line

  const label =
    selectedSensations?.length > 0
      ? 'Pick as many body sensations as you want!'
      : feelingContent.label

  const title = isConfirmationPage
    ? feelingContent.confirmationTitle
    : 'How does that feel physically?'

  return (
    <Screen
      label={isConfirmationPage ? 'Thanks for sharing your feelings.' : label}
      title={title}
    >
      <CircularElements
        startDeg={-20}
        endDeg={240}
        distance="220px"
        className={classes.container}
        style={{ position: 'relative' }}
      >
        <FeelingContent
          isConfirmationPage={isConfirmationPage}
          feelingContent={feelingContent}
          selectedSensations={selectedSensations}
          moonStyle={moonStyle}
          handleMoon={handleMoon}
        />
        <CircularItem selected>
          <div className={classes.image}>
            <Image src={feelingContent.image} alt={feeling} />
          </div>
        </CircularItem>
      </CircularElements>
      <BackAndNextButtons
        showBackButton
        hiddenNextButton={!showNextButton}
        nextButtonOnClick={() => {
          isConfirmationPage
            ? handleNextFunction()
            : setIsConfirmationPage(true)
        }}
      />
    </Screen>
  )
}

export default withMixpanel(withStyles(styles)(withWidth()(BodySensations)))
