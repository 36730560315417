import styled from 'styled-components'

export const Title = styled.h1`
  font-family: 'Roboto', serif;
  font-size: 2.5rem;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  font-weight: 500;
  color: #fff;
  line-height: 160%;
  text-shadow: -1px 1px 4px #000;

  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }

  &.fade-out {
    opacity: 0;
    transition: all linear 0.25s;
  }
`

export const Subtitle = styled.h2`
  /* font-family: 'Roboto', serif; */
  font-size: 18px;
  text-align: center;
  margin: 0;
  margin-bottom: 2rem;
  font-weight: 400;
  color: #fff;
  line-height: 160%;
  text-shadow: -1px 1px 4px #000;

  @media screen and (max-width: 600px) {
    font-size: 2rem;
  }

  &.fade-out {
    opacity: 0;
    transition: all linear 0.25s;
  }
`
