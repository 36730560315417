import React from 'react'
import Screen from 'checkIn/components/kid/Screen'
import BackAndNextButtons from 'checkIn/components/kid/BackAndNextButtons'

type Props = {
  nextFunction: (params: string) => Promise<void>
}

const Kid = ({ nextFunction }: Props) => {
  return (
    <Screen
      label="There is so much to explore!"
      title="Let’s see how much fuel we have in our spaceship."
    >
      <img
        className="max-h-[40vh] mt-5"
        src="/images/cadet_running.png"
        alt="Happy cadet"
      />

      <div className="absolute bottom-0 w-full">
        <BackAndNextButtons
          nextButtonOnClick={() => nextFunction(window.location.search)}
          showBackButton
        />
      </div>
    </Screen>
  )
}

export default Kid
