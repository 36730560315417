import React from 'react'
import Screen from 'checkIn/components/nonReader/Screen'
import BackAndNextButtons from 'checkIn/components/nonReader/BackAndNextButtons'
import { FUEL_CONCLUSION_PAGE } from 'checkIn/audios'

type Props = {
  flow: { nextFunction: (params: string) => Promise<void> }
}

const NonReader = (props: Props) => {
  const { nextFunction } = props.flow

  return (
    <Screen
      label="That's great"
      title="Yay! I'm happy to know that you're doing well!"
      audios={{ autoplay: FUEL_CONCLUSION_PAGE.isComfy }}
    >
      <img
        className="h-[35vh] mt-28"
        src="/images/happy_cadet_left_shadow.png"
        alt="Happy cadet"
      />

      <div className="absolute bottom-0 w-full">
        <BackAndNextButtons
          nextButtonText="OK!"
          audios={{
            buttonNext: FUEL_CONCLUSION_PAGE.buttonIsComfyNext,
          }}
          nextButtonOnClick={() => nextFunction(window.location.search)}
        />
      </div>
    </Screen>
  )
}

export default NonReader
