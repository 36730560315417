import * as React from 'react'
import flatten from 'lodash/flatten'
import map from 'lodash/map'
import Typography from '@material-ui/core/Typography'
import { ExpandMore, ExpandLess } from '@material-ui/icons'
import { roles } from 'roles'
import ButtonShare from 'Educators/students/edit/button-share'
import ButtonUnshare from 'Educators/students/edit/button-unshare'

import type { Adult, Student, User } from 'types'

type Props = {
  student: Student
  currentUser: User
  adults?: Student['adultsOnAccount']
}
function AdultsOnAccount({ student, adults, currentUser }: Props) {
  const [open, setOpen] = React.useState(true)

  const everyAdult = map(flatten(Object.values(adults ?? {})), 'id')

  if (!adults || everyAdult.length === 0) {
    return (
      <div className="mt-8">
        <ButtonShare currentUser={currentUser} student={student} />
      </div>
    )
  }

  return (
    <div className="flex flex-col items-center mt-8 -mb-4 sm:items-start">
      <div
        className="flex items-center w-56 mb-4 transition-all cursor-pointer"
        onClick={() => setOpen(!open)}
        aria-hidden="true"
      >
        <Typography variant="button" color="primary" className="hover:drop-shadow">
          ADULTS ON ACCOUNT
        </Typography>
        {open ? (
          <ExpandLess className="w-6 h-6 text-primary" />
        ) : (
          <ExpandMore className="w-6 h-6 text-primary" />
        )}
      </div>

      {open && (
        <div className="flex flex-col gap-4">
          <AdultsList title="SCHOOL LEVEL ACCESS:">
            {adults.schoolOwners?.map((adult) => (
              <AdultItem key={adult.id} adult={adult} />
            ))}
          </AdultsList>
          <AdultsList title="CLASSROOM LEVEL ACCESS:">
            {adults.classroomsViewersAndAdmins?.map((adult) => (
              <AdultItem key={adult.id} adult={adult} />
            ))}
          </AdultsList>
          <AdultsList title="SHARED WITH:">
            {adults.sharedWith?.map((adult) => (
              <AdultItem key={adult.id} adult={adult}>
                <ButtonUnshare currentUser={currentUser} student={student} adults={[adult]} />
              </AdultItem>
            ))}
          </AdultsList>
          <div className="sm:ml-56">
            <ButtonShare currentUser={currentUser} student={student} />
          </div>
        </div>
      )}
    </div>
  )
}

function AdultsList({ title, children }: { title: string; children?: React.ReactNode[] }) {
  if (!children?.length) return null

  return (
    <div className="flex flex-col items-center sm:items-start sm:flex-row">
      <h6 className="text-gray-500 flex flex-col items-center justify-end w-56 pr-2 !leading-relaxed text-center sm:text-right whitespace-nowrap sm:flex-row sm:items-start">
        {title}
      </h6>
      <div className="flex flex-col items-center text-center sm:items-start sm:text-left">
        {children}
      </div>
    </div>
  )
}

function AdultItem({ adult, children }: { adult: Adult; children?: React.ReactNode }) {
  return (
    <div className="flex flex-nowrap">
      <p className="flex gap-1 items-center mr-2 !leading-relaxed text-gray-700 whitespace-nowrap">
        {adult?.displayName}{' '}
        <span className="!leading-relaxed whitespace-nowrap text-purple-light">
          ({roles[adult.role!] ?? adult?.role})
        </span>
        {children}
      </p>
    </div>
  )
}

export default AdultsOnAccount
