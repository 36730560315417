export const planetList = [
  {
    src: '/images/teen/planet_happy.png',
    alt: 'Happy',
    label: 'Happy',
    feeling: 'happy',
  },
  {
    src: '/images/teen/planet_angry.png',
    alt: 'Angry',
    label: 'Angry',
    feeling: 'angry',
  },
  {
    src: '/images/teen/planet_sad.png',
    alt: 'Sad',
    label: 'Sad',
    feeling: 'sad',
  },
  {
    src: '/images/teen/planet_worried.png',
    alt: 'Worried',
    label: 'Worried',
    feeling: 'worried',
  },
  {
    src: '/images/teen/planet_insecure.png',
    alt: 'Insecure',
    label: 'Insecure',
    feeling: 'insecure',
  },
  {
    src: '/images/teen/planet_excited.png',
    alt: 'Excited',
    label: 'Excited',
    feeling: 'excited',
  },
  {
    src: '/images/teen/planet_relaxed.png',
    alt: 'Relaxed',
    label: 'Relaxed',
    feeling: 'relaxed',
  },
  {
    src: '/images/teen/planet_overwhelmed.png',
    alt: 'Overwhelmed',
    label: 'Overwhelmed',
    feeling: 'overwhelmed',
  },
] as const

export default planetList
