import React from 'react'
import { navigate } from '@reach/router'
import { makeStyles } from '@material-ui/core/styles'
import { ChevronRight, ChevronLeft } from '@material-ui/icons'

import ArrowButton from 'components/ArrowButton'

const useStyles = makeStyles((theme: any) => ({
  buttonsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      alignItems: 'baseline',
    },
  },
  button: {
    width: '85px',
    height: '45px',
    borderRadius: '10px',
    [theme.breakpoints.up('md')]: {
      borderRadius: '29px',
      width: '118px',
      height: '155px',
    },
  },
  buttonLabel: {
    fontSize: '14px',
    textTransform: 'none',
    display: 'inline',
    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      textTransform: 'none',
      display: 'block',
    },
  },
  outerButton: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    [theme.breakpoints.up('md')]: {
      top: 0,
      display: 'block',
      height: '100%',
    },
  },
  arrowRight: {
    color: theme.colors.white,
    fontSize: '58px',
    bottom: 0,
    marginRight: '-25px',
    marginLeft: '-10px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      fontSize: '100px',
    },
  },
  arrowLeft: {
    color: theme.colors.white,
    fontSize: '58px',
    bottom: 0,
    marginLeft: '-25px',
    marginRight: '-10px',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      fontSize: '100px',
    },
  },
  buttonLeft: {
    minWidth: 60,
    position: 'fixed',
    left: 0,
    zIndex: 500,
    top: '90%',
    [theme.breakpoints.up('md')]: {
      top: '50%',
      left: '0%',
    },
  },
  buttonRight: {
    minWidth: 60,
    position: 'fixed',
    right: 0,
    top: '90%',
    [theme.breakpoints.up('md')]: {
      top: '50%',
      right: '0%',
    },
  },
}))

export const goToUrl = (url: string) => () => navigate(url)
export const createGoBackFunction = (backUrl: string) => {
  if (backUrl) {
    return () => navigate(backUrl)
  }
  return () => window.history.go(-1)
}

type Props = {
  backUrl?: string
  showBackButton?: boolean
  nextUrl?: string
  hiddenNextButton?: boolean
  nextButtonOnClick?: () => void
}
const BackAndNextButtons = ({
  backUrl = '',
  showBackButton,
  nextUrl = '',
  hiddenNextButton,
  nextButtonOnClick,
}: Props) => {
  const goBackFunction = createGoBackFunction(backUrl)
  const showNextButton = !hiddenNextButton && (nextButtonOnClick || nextUrl)

  const classes = useStyles()

  return (
    <div className={classes.buttonsWrapper}>
      <div
        className={classes.buttonLeft}
        style={{ visibility: showBackButton ? 'visible' : 'hidden' }}
      >
        <ArrowButton variant="text" onClick={goBackFunction} aria-label="back-button">
          <ChevronLeft className={classes.arrowLeft} />
        </ArrowButton>
      </div>
      <div
        className={classes.buttonRight}
        style={{ visibility: showNextButton ? 'visible' : 'hidden' }}
      >
        <ArrowButton
          variant="text"
          onClick={nextButtonOnClick || goToUrl(nextUrl)}
          aria-label="next-button"
        >
          <ChevronRight className={classes.arrowRight} />
        </ArrowButton>
      </div>
    </div>
  )
}

export default BackAndNextButtons
