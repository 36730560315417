import React from 'react'
import PropTypes from 'prop-types'
import {
  FormHelperText,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core'
import isEmpty from 'lodash/isEmpty'
import { Fetch } from 'croods'

import AlertItem from './ListItem'

const AlertsList = ({ student = {} }) => {
  const theme = useTheme()

  return (
    <Paper style={{ padding: theme.spacing(5), marginTop: theme.spacing(5) }}>
      <Typography
        component="h2"
        variant="subtitle1"
        color="textSecondary"
        style={{ textTransform: 'uppercase', marginBottom: theme.spacing(3) }}
      >
        Student Alerts
      </Typography>

      <Grid container justifyContent="center">
        <Fetch
          cache
          stateId={student.id}
          name="alerts"
          path={`/students/${student.id}/alerts`}
          render={(alerts) => {
            if (isEmpty(alerts))
              return (
                <Typography color="textSecondary">No Alerts Yet</Typography>
              )

            return (
              <Grid
                container
                direction="column"
                component="ul"
                style={{
                  listStyleType: 'none',
                  padding: 0,
                  margin: 0,
                }}
              >
                {alerts.map((alert) => (
                  <AlertItem key={alert.id} alert={alert} student={student} />
                ))}
              </Grid>
            )
          }}
          renderError={() => (
            <FormHelperText error>Failed to load the alerts</FormHelperText>
          )}
        />
      </Grid>
    </Paper>
  )
}

AlertsList.propTypes = {
  student: PropTypes.object,
}

export default AlertsList
