export default {
  happy: {
    label: 'You are feeling happy!',
    confirmationTitle: 'We all feel happy sometimes, and that’s awesome!',
    image: '/images/planet_happy.png',
    options: ['Calm Breathing', 'Comfy or At Ease', 'Awake', 'Warm'],
  },
  angry: {
    label: 'You are feeling angry.',
    confirmationTitle: 'We all feel angry sometimes, and that’s okay.',
    image: '/images/planet_angry.png',
    options: ['Head Hurts', 'Hot or Red', 'Stomach Hurts', 'Breathing Hard'],
  },
  sad: {
    label: 'You are feeling sad.',
    confirmationTitle: 'We all feel sad sometimes, and that’s okay.',
    image: '/images/planet_sad.png',
    options: ['Head Hurts', 'Stomach Hurts', 'Tired or Weak', 'Sleepy'],
  },
  scared: {
    label: 'You are feeling scared.',
    confirmationTitle: 'We all feel scared sometimes, and that’s okay.',
    image: '/images/planet_upset.png',
    options: ['Shaky', 'Hot or Sweaty', 'Breathing Fast', 'Stomach hurts'],
  },
  calm: {
    label: 'You are feeling calm!',
    confirmationTitle: 'We all feel calm sometimes, and that’s awesome!',
    image: '/images/planet_good.png',
    options: ['Relaxed', 'Awake', 'Comfy or at Ease', 'Calm Breathing'],
  },
  tired: {
    label: 'You are feeling tired.',
    confirmationTitle: 'We all feel tired sometimes, and that’s okay.',
    image: '/images/planet_tired.png',
    options: ['Sleepy', 'Tired or Weak', 'Breathing Hard', 'Head Hurts'],
  },
} as const
