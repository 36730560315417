import React from 'react'
import PrintIcon from '@material-ui/icons/Print'
import { Link } from '@reach/router'

import { useSchools } from 'Educators/SchoolsContext'
import { welcomePath, isSchoolPremium, isPremium } from 'students/helper'
import AdultsOnAccount from 'components/adults-on-account'
import ButtonCSVExport from 'Educators/students/ButtonCSVExport'
import { identity, map, pickBy } from 'lodash'
import { useMixpanel } from 'mixpanel'

import type { User, Student } from 'types'
import Breadcrumbs from 'components/Breadcrumbs'
import { featureEnabled } from 'featureFlags'
import { MessagesIcon } from 'components/DashboardScreenV3'

type Props = {
  student: Student
  currentTab: string
  currentUser: User
  query: {
    from: string
    to: string
  }
}
const EditHeader = ({ student, currentUser, currentTab, query }: Props) => {
  const mixpanel = useMixpanel()
  const { school, schoolId } = useSchools()
  const handlePrint = () => window.print()

  const triggerMixpanelEvent = () =>
    mixpanel.track('Checking through educator student profile clicked', {
      studentId: student.id,
    })

  return (
    <div className="mb-10 -mx-6 sm:mx-0">
      <div className="flex flex-col items-start justify-between sm:items-center sm:flex-row">
        <Breadcrumbs paths={student.schoolId ? [] : [{ children: 'Dashboard', to: '/dashboard' }]}>
          {`${student.firstName} ${student.lastName}`}
        </Breadcrumbs>
        <div className="flex flex-col items-end self-end gap-4 print:hidden">
          <Link
            className="flex items-center gap-2 px-3 py-2 text-xs font-medium text-white uppercase rounded bg-primary whitespace-nowrap"
            to={welcomePath(student)}
            onClick={triggerMixpanelEvent}
          >
            Check In
            <img src="/images/white-rocket.png" alt="check in" className="h-4" />
          </Link>
          {featureEnabled(currentUser, 'message_center') && (
            <Link
              className="flex items-center gap-2 px-2 py-1 text-xs button-contained-light"
              to={`/v2/schools/${student.schoolId}/messages/${student.id}`}
              onClick={triggerMixpanelEvent}
            >
              Send a message
              <MessagesIcon style={{ height: 20, width: 20 }} />
            </Link>
          )}
          {currentTab === 'HISTORY' && isSchoolPremium(school) && isPremium(currentUser) && (
            <>
              <ButtonCSVExport
                className="px-2 py-1 text-xs button-contained-light"
                url={`${process.env.REACT_APP_API_URL}/schools/${schoolId}/students/${
                  student.id
                }/export-check-ins?${map(
                  pickBy(query, identity),
                  (value, key) => `${key}=${value}`,
                ).join('&')}`}
              />
              <button
                className="px-2 py-1 text-xs button-contained-light"
                onClick={handlePrint}
                type="button"
              >
                PRINT
                <PrintIcon fontSize="small" className="ml-2" />
              </button>
            </>
          )}
        </div>
      </div>
      <AdultsOnAccount
        student={student}
        currentUser={currentUser}
        adults={student.adultsOnAccount}
      />
    </div>
  )
}

export default EditHeader
