import * as React from 'react'
import { Skeleton } from '@material-ui/lab'

function StudentSkeleton() {
  return (
    <div data-testid="skeleton" style={{ marginTop: 40 }}>
      <Skeleton variant="rect" height={100} />
      <Skeleton variant="text" height={40} />
      <Skeleton variant="text" height={40} />
      <Skeleton variant="text" height={40} />
    </div>
  )
}

export default StudentSkeleton
