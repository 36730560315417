import { get } from 'lodash'

const signOutTeacher = (dispatch, currentUser, nextFunction) => () => {
  const userEmail = get(currentUser, 'email', null)
  dispatch({ type: 'ADD_USER_EMAIL', data: userEmail })

  return nextFunction()
}

export default signOutTeacher
