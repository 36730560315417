import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import NonReader from 'checkIn/steps/EnergyLevelIntro/NonReader'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'

const SCREEN_NAME = 'energyLevelIntro'

const EnergyLevelIntro: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const { nextFunction } = FLOWS['non-reader'][SCREEN_NAME]

    return <NonReader nextFunction={nextFunction} />
  }

  return null
}

export default EnergyLevelIntro
