import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from '../../../checkIn/steps/ChooseActivity/Teen'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'
import { getQSData, setQSData } from 'QueryStringController'
import isComfy from 'emotions'

const SCREEN_NAME = 'chooseActivity'

const ChooseActivity: RouteComponent = () => {
  const { student } = useCheckIn()

  const { energyLevel, feeling } = getQSData()

  const comfy = isComfy(feeling)

  const energizedAndComfy = ['green', 'yellow'].includes(energyLevel) && comfy

  const label = energizedAndComfy ? 'Choose an activity:' : 'In the meantime, choose an activity:'

  if (student) {
    const { nextFunction } = FLOWS['teen'][SCREEN_NAME]

    const selectActivityFunction = (value: string) => {
      setQSData({ activity: value })
      nextFunction(window.location.search, value)
    }

    return <Teen selectActivityFunction={selectActivityFunction} label={label} />
  }

  return null
}

export default ChooseActivity
