import React from 'react'
import { Grid, IconButton } from '@material-ui/core'
import { Remove as RemoveIcon, Add as AddIcon } from '@material-ui/icons'
import { Field } from 'redux-form'
import { required, email } from 'redux-form-validators'

import Input from 'components/form/Input'
import FormSubmitButton from 'components/FormSubmitButton'

const Fields = ({ valid, fields, submitting, submitError }) => (
  <>
    {fields.map((educator, index) => (
      <Grid container alignItems="center" key={index}>
        <Grid container alignItems="flex-end" item md={12}>
          <Grid item md={11} style={{ flex: '1 1 0%' }}>
            <Field
              component={Input}
              name={`${educator}.email`}
              label="Email address*"
              type="email"
              validate={[required(), email()]}
              helperText=" "
            />
          </Grid>
          <Grid item md={1} style={{ display: 'flex' }}>
            {index !== 0 && (
              <div>
                <IconButton
                  aria-label="remove invite"
                  onClick={() => fields.remove(index)}
                >
                  <RemoveIcon />
                </IconButton>
              </div>
            )}
          </Grid>
        </Grid>
      </Grid>
    ))}

    <Grid container justifyContent="space-between">
      <IconButton
        aria-label="add invite"
        color="primary"
        onClick={() => fields.push({})}
      >
        <AddIcon />
      </IconButton>
      <FormSubmitButton
        type="submit"
        color="primary"
        disabled={!valid}
        submitting={submitting}
        submitError={submitError}
        buttonText="Send Invite"
      />
    </Grid>
  </>
)

export default Fields
