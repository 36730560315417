import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import { getLocalStorageCheckIn } from 'students/helper'
import { lightenColor } from 'utils'

type Props = {
  children: React.ReactNode
  onClick: () => void
  className?: string
  disabled?: boolean
  style?: CSSProperties
}

const Button = styled.button<{ color: string }>`
  padding-left: 3rem;
  padding-right: 3rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
  color: white;
  font-weight: 500;
  letter-spacing: 0.025em;
  border-radius: 9999px;
  background-image: ${({ color }) =>
    `linear-gradient(to bottom, ${color}, ${lightenColor(color, -25)})`};
  text-shadow: -1px 1px 4px rgba(0, 0, 0, 0.25);
  :disabled {
    background-image: none;
    background-color: ${({ color }) => lightenColor(color, -25)};
    color: ${({ color }) => lightenColor(color, 0)};
    opacity: 0.5;
  }
  @media screen and (min-width: 960px) {
    padding-left: 6rem;
    padding-right: 6rem;
  }
  @media (min-width: 960px) {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  @media (min-width: 960px) {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
`

export const ButtonNext: React.FC<Props> = ({ children, onClick, disabled, style }: Props) => {
  const color = getLocalStorageCheckIn('color') || '#03b2ad'

  return (
    <Button style={style} type="button" color={color} disabled={disabled} onClick={onClick}>
      {children}
    </Button>
  )
}

export default ButtonNext
