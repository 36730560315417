import styled from 'styled-components'

export const Container = styled.div`
  display: inline-block;
  vertical-align: middle;
  padding: 12px;
  cursor: pointer;
  @media screen and (max-width: 700px) {
    padding: 0.8rem;
  }
`
export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`
export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`
export const StyledCheckbox = styled.div<{ checked: boolean; disabled?: boolean }>`
  display: inline-block;
  vertical-align: middle;
  width: 18.39px;
  height: 18.39px;
  background: ${(props) => (props.checked ? '#e9573e' : 'transparent')};
  border: ${(props) => (props.checked ? 'none' : '2px solid rgba(0, 0, 0, 0.54)')};

  border-radius: 3px;
  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
  ${Icon} {
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`
