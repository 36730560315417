import React from 'react'
import { Router } from '@reach/router'
import Root from './Root'
import Welcome from './Index'
import MiddleSchool from './fiveToEight/Router'
import ElementarySchool from './threeToFour/Router'
import HighSchool from './nineToTwelve/Router'
import NonReader from './nonReader/Router'

const DemoRouter = () => (
  <Router>
    <Root path="demo">
      <Welcome path="/" />
      <NonReader path="nonReader/*" />
      <MiddleSchool path="middleSchool/*" />
      <ElementarySchool path="elementarySchool/*" />
      <HighSchool path="highSchool/*" />
    </Root>
  </Router>
)

export default DemoRouter
