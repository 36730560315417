import makeStyles from '@material-ui/core/styles/makeStyles'
import React from 'react'

const useStyles = makeStyles((theme) => ({
  fab: {
    display: 'flex',
    flex: 'row',
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 10000,
  },
  speechBubble: {
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px 4px 0px 4px',
    background: '#fff',
    color: '#251F28',
    fontSize: '12px',
    textAlign: 'center',
    // marginRight: '60px',
  },
  pip: {
    float: 'right',
    // marginRight: '60px',
  },
  cadet: {
    // float: 'right',
    marginTop: '30px',
  },
  bubbleContainer: {
    marginRight: '10px',
  },
  link: {
    color: '#9E95C2',
    fontWeight: 500,
  },
}))

type Props = {
  customLink?: string
}

export default ({ customLink }: Props) => {
  const classes = useStyles()
  const authHrefWithRedirect = (customLink?: string) => {
    const redirectHref = customLink || 'https://closegaphelp.zendesk.com/hc/en-us';
    return `/sign-in/zendesk?return_to=${encodeURIComponent(redirectHref)}`
  }
  return (
    <div className={classes.fab}>
      <div className={classes.bubbleContainer}>
        <div className={classes.speechBubble}>
          <p>
            Hi!{' '}
            <a
              className={classes.link}
              href={authHrefWithRedirect(customLink)}
              target="_blank"
              rel="noopener noreferrer"
            >
              Click here
            </a>
            <br /> if you need help!
          </p>
        </div>
        <svg
          className={classes.pip}
          xmlns="http://www.w3.org/2000/svg"
          width="8"
          height="8"
          viewBox="0 0 8 8"
          fill="none"
        >
          <path d="M0 0H8V8L0 0Z" fill="white" />
        </svg>
      </div>
      <img className={classes.cadet} src="/images/cadet-help.png" alt="help cadet" />
    </div>
  )
}
