import React, { useEffect } from 'react'
import { useCurrentUser, useSignOut } from 'dmcroods-auth'
import { useCroods } from 'croods'

import withMixpanel from 'mixpanel/withMixpanel'
import Loading from 'components/AuthLoading'
import Title from 'components/AuthTitle'
import PageTitle from 'components/PageTitle'
import SecondaryLink from 'components/SecondaryLink'
import Screen from './Screen'

const secondaryLink = <SecondaryLink to="/sign-in" label="Already have an account?" />

const Block = ({ mixpanel }) => {
  useEffect(() => {
    mixpanel.track('Sign-up Block Student Message Page')
  }, []) // eslint-disable-line

  return (
    <Screen secondaryLink={secondaryLink}>
      <PageTitle text="Closegap Sign up" />
      <div className="text-center">
        <Title>All students must be registered by an adult.</Title>
        <h6 className="text-base font-medium text-purple-light md:text-xl">
          Please reach out to your teacher, school counselor, or parent/guardian if you want to use
          Closegap!
        </h6>
        <p className="!my-5 text-sm font-normal text-gray-500 md:!mt-10 md:!mb-0">
          Questions? Contact us at info@closegap.org and we’d be happy to help!
        </p>
      </div>
    </Screen>
  )
}

export default withMixpanel(({ mixpanel }) => {
  const [{ currentUser }] = useCurrentUser()
  const [{ signingOut }, signOut] = useSignOut()

  const [, { resetState }] = useCroods({ name: 'auth', stateId: 'signIn' })

  React.useEffect(() => {
    if (currentUser) signOut(() => resetState())
  }, [currentUser]) // eslint-disable-line

  if (signingOut) return <Loading />

  return <Block mixpanel={mixpanel} />
})
