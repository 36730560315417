import React from 'react'
import { map, some } from 'lodash'

import { CircularItem } from 'checkIn/components/CircularElements'
import Moon from 'checkIn/steps/BodySensations/TeenBodySensations/Moon'

const FeelingContent = ({
  feelingContent,
  selectedSensations,
  moonStyle,
  isConfirmationPage,
  handleMoon,
  ...props
}) =>
  map(feelingContent?.options, (option, index) => {
    const selected = some(
      selectedSensations,
      (item) => item.toLowerCase() === option.toLowerCase(),
    )

    const { startDeg, endDeg, distance } = props

    return (
      <CircularItem
        key={index}
        style={moonStyle}
        length={feelingContent?.options?.length}
        startDeg={startDeg}
        endDeg={endDeg}
        distance={distance}
        index={index}
      >
        <Moon
          key={index}
          handleMoon={handleMoon}
          hideUnselected={isConfirmationPage}
          selected={selected}
          option={option}
          isConfirmationPage={isConfirmationPage}
        />
      </CircularItem>
    )
  })

export default FeelingContent
