import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'

const styles = (theme) => ({
  image: {
    marginBottom: '45px',
    height: '30vh',
    [theme.breakpoints.up('xs')]: {
      height: '40vh',
    },
  },
})

const cadetImage = ({ classes, src, alt, className }) => (
  <img className={clsx(classes.image, className)} src={src} alt={alt} />
)

export default withStyles(styles)(cadetImage)
