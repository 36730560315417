import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from '../../../checkIn/steps/Activities/Teen'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'

const Activities: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const { nextFunction } = FLOWS['teen'].activities
    return <Teen nextFunction={nextFunction} />
  }

  return null
}

export default Activities
