import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Teen from '../../../checkIn/steps/ComfortableResult/Teen'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'

const SCREEN_NAME = 'comfortableResult'

const ComfortableResult: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const flow = FLOWS['teen'][SCREEN_NAME]

    return <Teen flow={flow} />
  }

  return null
}

export default ComfortableResult
