import React, { SelectHTMLAttributes } from 'react'
import styled from 'styled-components'
import { InputContainer, Label } from './styled'

const StyledSelect = styled.select`
  display: flex;
  height: 50px;
  width: 100%;
  background: #fefefe;
  border: none;
  box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 0.25);
  border-radius: 8px;
  padding: 0 1rem;
  border-radius: 8px;
  color: ${({ disabled }) => (disabled ? '#9a9a9a' : '#353535')};
  outline-color: transparent;
  :focus + label {
    font-size: 0.7rem;
    margin-top: -2rem;
  }
  :focus {
    box-shadow: 0px 0px 5px 1px rgba(37, 0, 50, 1);
  }
`
interface Props extends SelectHTMLAttributes<HTMLSelectElement> {
  label?: string
  options: Array<{
    value: string | number | null | undefined
    label: string
  }>
}

export const Select = ({ label, options, ...rest }: Props) => (
  <InputContainer>
    {label ? <Label>{label}</Label> : null}
    <StyledSelect {...rest}>
      <option disabled selected value="" style={{ display: 'none' }}>
        -- Select an option --
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value || undefined}>
          {option.label}
        </option>
      ))}
    </StyledSelect>
  </InputContainer>
)
