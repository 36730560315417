import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 8px;
  line-height: 1.5;
  font-weight: 500;
  font-size: 0.8rem;
  @media screen and (max-width: 700px) {
    margin-left: 0;
  }
`

export const NeedAnswer = styled.div<{ highlighted: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: ${({ highlighted }) => (highlighted ? '#b00013' : 'transparent')};
  border-radius: 1rem;
  color: ${({ highlighted }) => (highlighted ? '#ffffff' : '#b00013')};
  padding: ${({ highlighted }) => (highlighted ? '0px 0.5rem' : '0px')};
  margin: 0px 0.5rem;
  @media screen and (max-width: 700px) {
    margin-left: 0px;
    margin-right: 1rem;
  }
`
