import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'

import type { ButtonProps } from '@material-ui/core/Button'

type Props = ButtonProps & { submitting?: boolean }
function SubmitButton({ submitting, children, ...props }: Props) {
  return (
    <Button
      color="primary"
      type="submit"
      {...props}
      disabled={submitting || props.disabled}
    >
      {submitting ? (
        <CircularProgress role="progressbar" color="primary" size={20} />
      ) : (
        children
      )}
    </Button>
  )
}

export default SubmitButton
