import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import Screen from 'checkIn/components/teen/Screen'
import CadetImage from 'checkIn/components/teen/CadetImage'
import ButtonNext from 'checkIn/components/teen/ButtonNext'

const styles = (theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '70vw',
    flexDirection: 'row',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  image: {
    position: 'initial',
    height: 'auto',
    marginTop: 80,
    marginBottom: 80,
    [theme.breakpoints.down('sm')]: {
      height: 250,
      marginTop: 20,
      marginBottom: 20,
    },
  },
})

export default withStyles(styles)(({ classes, needTalkToAnAdult, nextFunction }) => {
  const content = needTalkToAnAdult
    ? {
        title: 'Thanks for sharing.',
        text: 'No one feels their best all of the time.<br />Help is on the way.',
        textButton: 'Okay, Thanks!',
      }
    : {
        title: 'Don’t forget that you can always talk to someone!',
        text: 'No one feels their best all the time.<br />Maybe I can help in the meantime...',
        textButton: 'Sure!',
      }

  const styleRoot = needTalkToAnAdult
    ? { height: '100vh', marginTop: 0 }
    : { height: '80vh', justifyContent: 'initial' }

  return (
    <Screen label={`Got it. ${content.title}`} title={content.text} contentWrapperStyle={styleRoot}>
      <div className={classes.wrapper}>
        <ButtonNext onClick={nextFunction}>
          <span className="font-medium whitespace-nowrap">{content.textButton}</span>
        </ButtonNext>
      </div>
      {!needTalkToAnAdult && (
        <CadetImage src="/images/cool_down_cadet.png" alt="Sad cadet" className={classes.image} />
      )}
    </Screen>
  )
})
