import styled from 'styled-components'
import CadetImg from '../assets/cadetUpsideDown.png'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
  align-items: center;
  @media screen and (max-width: 600px) {
    padding: 0px 1rem;
  }
`

export const SubTitle = styled.h2`
  margin-top: 1.5rem;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 160%;
  color: #353535;
  text-align: center;
  @media screen and (max-width: 700px) {
    margin-top: 1rem;
  }
`

export const InfoContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  gap: 8px;
  align-items: left;
`

export const InfoText = styled.p`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 160%;
  color: #353535;
  text-align: left;
`

export const BackLinkContainer = styled.div`
  display: flex;
  max-width: 600px;
  width: 100%;
  align-items: center;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`

export const CadetImage = styled.div`
  position: absolute;
  top: 0.5rem;
  left: 0.5rem;
  width: calc((90vw - 600px) / 2);
  height: 40%;
  background: url(${CadetImg});
  background-repeat: no-repeat;
  background-size: contain;
`
