import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import clsx from 'clsx'
import { find, inRange } from 'lodash'
import { TRIAGE_GROUPS } from 'Educators/triageGroups'

const styles = () => ({
  verticalLine: {
    height: '100%',
    border: 4,
    borderStyle: 'solid',
  },
  green: { borderColor: 'rgb(50,173,69)' },
  yellow: { borderColor: 'rgb(249,178,51)' },
  red: { borderColor: 'rgb(234,71,59)' },
})

const VerticalLine = withStyles(styles)(({ classes, helpScore }) => {
  const color = find(TRIAGE_GROUPS, (triage) =>
    inRange(helpScore ?? 0, triage.lowerBound, triage.upperBound + 1),
  )?.color
  return (
    <div data-testid="vertical-line" className={clsx([classes.verticalLine, classes[color]])} />
  )
})

export default VerticalLine
