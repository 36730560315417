import React from 'react'
import { Router } from '@reach/router'
import SignIn from 'auth/signIn/SignIn'
import { SignUpParent } from 'auth/signUpFlow/SignUpParent'
import { SignUpSchoolStaff } from 'auth/signUpFlow/SignUpSchoolStaff'
import { SignUpStudent } from 'auth/signUpFlow/SignUpStudent'
import { SignUpTherapist } from 'auth/signUpFlow/SignUpTherapist'
import { GetStarted } from 'auth/signUpFlow/GetStarted'
import EdlinkSignIn from 'auth/Edink'
import { RouteComponent } from 'types'
import ForgotPassword from 'auth/forgotPassword/ForgotPassword'
import EmailSentWithInstructions from 'auth/forgotPassword/EmailSentWithInstructions'

const UnauthenticatedRouter: RouteComponent = (props) => (
  <Router>
    <SignIn {...props} path="/sign-in" />
    <ForgotPassword {...props} path="/forgot-password" />
    <EmailSentWithInstructions {...props} path="/email-instructions" />
    <EdlinkSignIn {...props} path="/sign-in/edlink" />
    <GetStarted {...props} path="/sign-up" />
    <SignUpStudent {...props} path="/sign-up/student" />
    <SignUpSchoolStaff {...props} path="/sign-up/school-staff" />
    <SignUpTherapist {...props} path="/sign-up/therapist" />
    <SignUpParent {...props} path="/sign-up/parent" />
  </Router>
)

export default UnauthenticatedRouter
