import React from 'react'

import Screen from 'checkIn/components/teen/Screen'
import CadetImage from 'checkIn/components/teen/CadetImage'
import { isWeekendToday } from 'students/helper'
import ButtonNext from 'checkIn/components/teen/ButtonNext'
import { setQSData } from 'QueryStringController'

export const TalkToAnAdultTeen = ({ flow }) => {
  const { nextFunction } = flow
  const preferredContacts = [
    { id: 1, displayName: 'Teacher One' },
    { id: 2, displayName: 'Teacher Two' },
  ]

  return (
    <Screen label="I can go ask for help." title="Do you want to talk to someone?">
      {isWeekendToday() && (
        <p className="max-w-lg px-5 text-base font-medium leading-7 text-center text-white">
          Please keep in mind that an educator won&apos;t be able to speak with you until the next
          school day. Would you like to let them know you want to talk then?
        </p>
      )}
      <CadetImage
        className="!sticky my-10 !h-60 sm:!h-auto sm:my-20"
        src="/images/cool_down_cadet.png"
        alt="Sad cadet"
      />
      <div className="flex justify-evenly items-center w-[70vw] flex-col sm:flex-row gap-10">
        <ButtonNext
          className="w-full max-w-sm whitespace-nowrap"
          onClick={() => {
            setQSData({ talkToAnAdult: true })
            nextFunction(preferredContacts)
          }}
        >
          <span className="font-medium">Yes, please!</span>
        </ButtonNext>

        <ButtonNext
          className="w-full max-w-sm whitespace-nowrap"
          onClick={() => {
            setQSData({ talkToAnAdult: false })
            nextFunction()
          }}
        >
          <span className="font-medium">No, thanks.</span>
        </ButtonNext>
      </div>
    </Screen>
  )
}
