import React from 'react'
import MomentUtils from '@date-io/moment'
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'

const Date = ({ ariaLabel, ...props }) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <KeyboardDatePicker
      autoOk
      fullWidth
      format="MM/DD/YYYY"
      KeyboardButtonProps={{
        'aria-label': ariaLabel,
      }}
      {...props}
    />
  </MuiPickersUtilsProvider>
)

export default Date
