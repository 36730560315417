import styled from 'styled-components'
import { hexToRGB } from 'utils'

export const Container = styled.div<{ bg: string }>`
  display: flex;
  flex-direction: column;
  background: ${({ bg }) => bg};
  border-radius: 1rem;
  padding: 1.5rem;
  color: white;
  width: 100%;
  max-width: 700px;
  @media screen and (max-width: 700px) {
    flex-direction: row;
    width: auto;
    gap: 3rem;
    margin: 0 0 1rem 0;
  }
`

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }
`

export const Button = styled.button<{ color: string; darkenAmount: number; selected: boolean }>`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  font-size: 2rem;
  font-weight: 600;
  text-shadow: -1px 1px 2px rgba(0, 0, 0, 0.25);
  align-items: center;
  border-radius: 0.25rem;
  width: 60px;
  height: 60px;
  background: ${({ darkenAmount, selected }) =>
    selected ? 'none' : `hsl(264, 69%, calc(55% - ${darkenAmount * 5}%))`};
  transition: all ease 0.25s;
  border: ${({ color, selected }) => (selected ? `2px solid ${hexToRGB(color, 0.8)}` : 'none')};
  :hover {
    background: none;
    border: ${({ color }) => `2px solid ${hexToRGB(color, 0.8)}`};
  }
  @media screen and (max-width: 700px) {
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
  }
`
export const DescriptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  color: #f7f7f7;
  font-size: 1.2rem;
  font-weight: 500;
  @media screen and (max-width: 700px) {
    flex-direction: column;
    align-items: flex-end;
  }
`

export const Description = styled.div`
  @media screen and (min-width: 700px) {
    flex-basis: 0;
    flex-grow: 1;
    &:nth-of-type(2) {
      text-align: center;
    }
    &:nth-of-type(3) {
      text-align: right;
    }
  }
`
