import React from 'react'
import {
  Container,
  makeStyles,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { useCroods } from 'croods'

import { success, error } from 'components/Toast'
import { useSchools } from 'Educators/SchoolsContext'
import { PrimaryButton, SecondaryButton } from '../../Components/buttons'
import HelperCadet from '../../Components/helperCadet'
import { Student } from 'types'

const useStyles = makeStyles((theme) => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '16px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '80px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  video: {
    marginBottom: '40px',
  },
  button: {
    width: '100%',
    marginBottom: '8px',
    borderRadius: '8px',
    padding: '16px',
    fontSize: '24px',
    backgroundColor: '#6750A3',
    '&:hover': {
      backgroundColor: '#5d4893',
    },
    '&:focus': {
      backgroundColor: '#524082',
    },
  },
  clearButton: {
    width: '100%',
    marginBottom: '8px',
    borderRadius: '8px',
    color: '#6750A3',
    padding: '16px',
    fontSize: '24px',
    '&:hover': {
      color: '#5d4893',
    },
    '&:focus': {
      color: '#524082',
    },
  },
  checkbox: {
    letterSpacing: '0.5px',
    marginBottom: '34px',
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}))

const NewSchoolYearIncreaseGradeLevel = ({ nextStepFn }: { nextStepFn: Function }) => {
  const classes = useStyles()
  const { school } = useSchools()
  const [state, setState] = React.useState({
    removeGraduatedStudents: false,
    showRemoveGraduates: false,
  })
  const [open, setOpen] = React.useState(false)
  const [{ saving }, { save }] = useCroods({
    name: 'increaseStudentGrades',
    customPath: `schools/${school.id}/students/increase_grade`,
    afterResponse({ status }) {
      if (status === 200) {
        success('Student grades increased')
        nextStepFn()
      } else {
        error('Error')
      }
    },
  })
  const [{ fetchingList: fetchingStudents }, { fetch: fetchStudents }] = useCroods({
    name: 'students',
    afterResponse({ status, data }: { status: number; data: Student[] }) {
      if (status === 200) {
        if (data.some((student: Student) => student.grade === '12')) {
          setState({ ...state, showRemoveGraduates: true, removeGraduatedStudents: true })
        }
      }
    },
  })
  React.useEffect(() => {
    if (school.id) {
      fetchStudents({ customPath: `schools/${school.id}/students` })()
    }
    // eslint-disable-next-line
  }, [school.id])

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (skipStep: boolean) => {
    setOpen(false)
    if (skipStep) {
      nextStepFn()
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.name]: event.target.checked })
  }

  return (
    <Container className={classes.container}>
      <HelperCadet></HelperCadet>
      <Typography className={classes.heading}>
        Do you want to increase the grade level of all of your students?
      </Typography>
      <Typography className={classes.subTitle}>We don’t recommend doing it manually.</Typography>

      {state.showRemoveGraduates && (
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={state.removeGraduatedStudents}
              onChange={handleChange}
              name="removeGraduatedStudents"
            />
          }
          label="Please remove students who have graduated. "
        />
      )}
      <PrimaryButton
        disabled={saving || fetchingStudents}
        loading={saving}
        onClick={() => save({ method: 'PUT' })({ removeGraduates: state.removeGraduatedStudents })}
      >
        YES, PLEASE
      </PrimaryButton>
      <SecondaryButton onClick={handleClickOpen} disabled={saving || fetchingStudents}>
        Nope, I want to do it manually, one by one.
      </SecondaryButton>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            If you do not do this now, you'll have to do this manually later.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            NO, I WANT TO GO BACK.
          </Button>
          <Button onClick={() => handleClose(true)} variant="contained" color="secondary" autoFocus>
            I'LL DO THIS MANUALLY.
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  )
}

export default NewSchoolYearIncreaseGradeLevel
