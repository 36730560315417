import React from 'react'
import { compact } from 'lodash'

import parseValueToLabel from 'Educators/students/edit/alerts/options'
import { getFrequency } from 'constants/frequencies'
import { getLivingSituation } from 'livingSituation'
import { getEthnicity } from 'raceEthnicity'
import ButtonDelete from 'Educators/students/insights/Alerts/List/ButtonDelete'

const renderTextKind = (kind, condition) => {
  const text = parseValueToLabel(condition)
  switch (kind) {
    case 'emotion':
      return `feeling ${text}`
    case 'energy_level':
      return `${text} energy level`
    default:
      return text
  }
}

const Alert = ({ alert }) => {
  const {
    id,
    kind,
    condition,
    frequency,
    createdBy,
    grades,
    classroomName,
    ethnicity,
    englishLanguageLearner,
    individualizedEducationProgram,
    educationPlan504,
    freeReducedPriceMeals,
    livingSituation,
  } = alert
  const textFrequency = getFrequency(frequency)?.label?.toLowerCase() ?? ''
  const textGrades = `grades ${grades.join(', ').replace(/, ([^,]*)$/, ' and $1')}`
  const textClassroom = classroomName ? `${classroomName} classroom` : null
  const textEthnicity = getEthnicity(ethnicity)?.label ?? null
  const textELL = englishLanguageLearner ? 'ELL' : null
  const textIEP = individualizedEducationProgram ? 'IEP' : null
  const text504 = educationPlan504 ? '504' : null
  const textFreeReducedPriceMeals = freeReducedPriceMeals ? 'Free / Reduced Price Meals' : null
  const textLivingSituation = getLivingSituation(livingSituation)?.label ?? null

  const texts = compact([
    textGrades,
    textClassroom,
    textEthnicity,
    textELL,
    textIEP,
    text504,
    textFreeReducedPriceMeals,
    textLivingSituation,
  ]).join(', ')

  return (
    <div className="flex">
      <div className="flex-1">
        <p className="text-sm font-bold text-gray-500">
          Notify staff when, <b className="text-black">{texts}</b> students report{' '}
          <b className="text-black">{renderTextKind(kind, condition)}</b>{' '}
          <b className="text-black">{textFrequency}</b>
        </p>
        <p className="mt-1 text-sm font-bold text-gray-500">Created by {createdBy}</p>
      </div>
      <div className="ml-10">
        <ButtonDelete alertId={id} />
      </div>
    </div>
  )
}

export default Alert
