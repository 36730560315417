import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Image from 'components/ResponsiveImage'
import { HOW_ARE_YOU_FEELING_PAGE } from 'checkIn/audios'
import AudioPlayer from 'checkIn/AudioPlayer'

const styles = (theme) => ({
  container: {
    padding: '20px',
    flex: '1',
    [theme.breakpoints.up('sm')]: {
      minWidth: '100px',
    },
  },
  imageSelected: {
    marginBottom: '10px',
    maxWidth: '132px',
    display: 'block',
  },
  image: {
    marginBottom: '10px',
    maxWidth: '47px',
    display: 'block',
    [theme.breakpoints.up('md')]: {
      maxWidth: '92px',
    },
  },
  typography: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
    fontSize: '20px',
    textAlign: 'center',
    letterSpacing: '0.5px',
    color: theme.colors.textSelectedPlanet,
  },
})

const ImagePlanet = ({ classes, src, alt, label, selected, feeling }) => {
  if (selected)
    return (
      <div className={classes.container}>
        <AudioPlayer
          className="justify-around"
          styleButton={{ marginLeft: '-50%', marginBottom: 0 }}
          importAudio={HOW_ARE_YOU_FEELING_PAGE[feeling]}
        >
          <Image
            src={src}
            alt={alt || ''}
            className={classes.imageSelected}
          />
        </AudioPlayer>
        <Typography className={classes.typography}>{label}</Typography>
      </div>
    )

  return (
    <div className={classes.container}>
      <Image src={src} alt={alt || ''} className={classes.image} />
    </div>
  )
}

export default withStyles(styles)(ImagePlanet)
