import findKey from 'lodash/findKey'
import moment from 'moment'

const fuels = {
  red: 1,
  blue: 2,
  yellow: 3,
  green: 4,
  orange: 5,
}

export const fuelToValue = (fuel) => fuels[fuel] || ''

export const valueToFuel = (value) => findKey(fuels, (i) => i === value)

export const timeFilterRange = (timeFilter) => {
  const parts = timeFilter.split('-')
  let start = moment().startOf('day')
  let end = moment().endOf('day')

  switch (timeFilter) {
    case 'week':
      start = moment().startOf('week')
      end = moment().endOf('week')
      break

    case 'month':
      start = moment().startOf('month')
      end = moment().endOf('month')
      break

    case '3-months':
    case '6-months':
    case '12-months':
      start = moment().subtract(parts[0], parts[1]).startOf('day')
      break

    default:
  }

  return { start, end }
}

export const getCalendarTime = (time, type) => {
  const calendarOptions =
    type === 'fullTime'
      ? {
          sameDay: '[Today] (MM/DD/YYYY)',
          nextDay: '[Tomorrow] (MM/DD/YYYY)',
          nextWeek: 'dddd (MM/DD/YYYY)',
          lastDay: '[Yesterday] (MM/DD/YYYY)',
          lastWeek: 'dddd (MM/DD/YYYY)',
          sameElse: 'MM/DD/YYYY',
        }
      : {
          sameDay: '[Today] (MM/DD)',
          nextDay: 'MM/DD',
          nextWeek: 'dddd (MM/DD)',
          lastDay: '[Yesterday] (MM/DD)',
          lastWeek: 'dddd (MM/DD)',
          sameElse: 'MM/DD/YYYY',
        }

  return moment(time).calendar(null, calendarOptions)
}
