import React, { useState } from 'react'

import Screen from '../components/screen'

import { NextButton } from '../components/NextButton'
import { FUELV2 } from 'fuel'
import { SelectableButton } from '../components/SelectableButton'

type Props = {
  handleNextFunction: (fuel: string) => void
}

export const listFuelLink = Object.values(FUELV2)

const NineToTwelve = ({ handleNextFunction }: Props) => {
  const [fuel, setFuel] = useState<string>()

  const showNextButton = !!fuel

  return (
    <Screen
      title={'How much energy do you have right now?'}
      currentStep={5}
      showBackButton={true}
      showCadet={true}
    >
      <div className="flex w-full max-w-screen-lg gap-4 mb-8">
        <img src="/images/energyLevels/9to12/electric_bolt.svg" alt="" />
        <div className="w-full bg-white/10 p-2 rounded-full">
          <div
            data-fuel={fuel}
            className="transition-all duration-300 w-0 data-[fuel=green]:w-full  data-[fuel=red]:w-1/12 data-[fuel=blue]:w-1/3 data-[fuel=orange]:w-full data-[fuel=yellow]:w-2/3 data-[fuel=green]:rounded-r-full data-[fuel=orange]:rounded-r-full h-10 bg-gradient-to-r from-cyan-500 to-cyan-500/5 data-[fuel=green]:to-cyan-500/50 data-[fuel=orange]:to-teal-100 rounded-l-full"
          ></div>
        </div>
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        {listFuelLink.map(({ value, nineToTwelveLabel }) => (
          <SelectableButton selected={fuel === value} key={value} onClick={() => setFuel(value)}>
            {nineToTwelveLabel}
          </SelectableButton>
        ))}
      </div>

      <NextButton
        disabled={!showNextButton}
        handleNextFunction={() => handleNextFunction(fuel || 'orange')}
        additionalClasses="mt-20"
      />
    </Screen>
  )
}

export default NineToTwelve
