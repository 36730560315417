import type { Student } from 'types'
import { find, groupBy, inRange, orderBy } from 'lodash'

type TRIAGE = {
  lowerBound: number
  upperBound: number
  color: string
  title: string
}
// upperBound included
const TRIAGE_GROUPS: TRIAGE[] = [
  { lowerBound: 0, upperBound: 30, color: 'green', title: 'GOOD TO GO' },
  {
    lowerBound: 31,
    upperBound: 60,
    color: 'yellow',
    title: 'COULD BENEFIT FROM SUPPORT',
  },
  { lowerBound: 61, upperBound: Infinity, color: 'red', title: 'URGENT' },
]

const doesItNeedFollowUp = (checkIn: { helpScore: number }): boolean =>
  checkIn?.helpScore >= TRIAGE_GROUPS[1].lowerBound

const spreadTriageGroup = (list: Student[]) => {
  const sortedGroups = orderBy(list, 'latestCheckIn.helpScore', ['desc'])
  const groups = groupBy(
    sortedGroups,
    (student: Student) =>
      find(TRIAGE_GROUPS, (triage: TRIAGE) =>
        inRange(
          student?.latestCheckIn?.helpScore ?? 0,
          triage.lowerBound,
          triage.upperBound + 1, // inRange end is not included
        ),
      )?.color,
  )

  return groups
}

export { TRIAGE_GROUPS, doesItNeedFollowUp, spreadTriageGroup }
