import React, { memo } from 'react'
import { Wrapper, NeedAnswer } from './styles'

interface INeedsList {
  needs: string[]
}

export const NeedsList = memo(({ needs }: INeedsList) => (
  <Wrapper>
    {needs.map((need, i: React.Key) => (
      <NeedAnswer key={i} highlighted={need === 'Wants to Talk'}>
        {need}
      </NeedAnswer>
    ))}
  </Wrapper>
))
