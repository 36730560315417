import React from 'react'
import { SubTitle, P } from '../components/styled'

export const EducatorInfo = ({ school }: any) => (
  <div>
    <SubTitle>Requester</SubTitle>
    <P>
      {school.educator?.name} ({school.educator?.email})
    </P>
    <P>Educator id: {school.educator?.educatorId}</P>
    <P>User id: {school.educator?.userId}</P>
    <P>Schools ids: {school.educator?.schoolsIds.join(', ')}</P>
  </div>
)
