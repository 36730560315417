import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import type { RouteComponent } from 'types'
import { setQSData } from 'QueryStringController'
import NineToTwelve from './NineToTwelve'
import { navigate } from '@reach/router'

const Emotion: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const nextFunction = (params: string) => navigate(`./emotion-intensity${params}`)

    const selectEmotionFunction = (feeling: string) => {
      setQSData({ feeling })
      nextFunction(window.location.search)
    }

    return <NineToTwelve nextFunction={selectEmotionFunction} />
  }

  return null
}
export default Emotion
