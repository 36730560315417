import React from 'react'
import { Grid } from '@material-ui/core'
import Button from 'components/Button'
import { welcomePath } from 'students/helper'
import { navigate } from '@reach/router'
import rocket from './rocket.png'

const CheckInButton = ({ student }) => (
  <Button
    style={{ width: 'auto', height: 'auto', padding: '15px 25px' }}
    textProps={{ style: { fontSize: 18 } }}
    onClick={() => navigate(welcomePath(student))}
  >
    <Grid container alignItems="center" justifyContent="center">
      <div style={{ textTransform: 'none', lineHeight: 'normal' }}>
        Ready to
        <br />
        Check-in
      </div>
      <img src={rocket} alt="rocket" height="30" style={{ marginLeft: 10 }} />
    </Grid>
  </Button>
)

export default CheckInButton
