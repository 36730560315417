import React from 'react'
import { Fetch } from 'croods'
import map from 'lodash/map'
import fill from 'lodash/fill'

import Chart from 'Educators/students/insights/Chart'
import Loading from 'components/Loading'
import pickBy from 'lodash/pickBy'
import identity from 'lodash/identity'

const FollowUpQuestions = ({ filter, schoolId }) =>
  filter && (
    <Fetch
      name="followUpQuestionChart"
      path={`/schools/${schoolId}/insights/questions/chart?${map(
        pickBy(filter, identity),
        (value, key) => `${key}=${value}`,
      ).join('&')}`}
      renderLoading={Loading}
      render={(chart) => (
        <div
          style={{ display: 'flex', flexDirection: 'column', width: '100%' }}
          className="div-chart-table-to-print"
        >
          {chart && (
            <Chart
              list={chart}
              listFill={fill([...chart], '#250032')}
              propsXAxis={{
                angle: -45,
                textAnchor: 'end',
                interval: 0,
                height: 100,
                tick: {},
                tickLine: true,
              }}
              propsBarChart={{
                className: 'follow-up-questions-chart-to-print',
                width: 800,
                height: 420,
              }}
            />
          )}
        </div>
      )}
    />
  )

export default FollowUpQuestions
