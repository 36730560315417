import React from 'react'
import {
  Container,
  makeStyles,
  Typography,
  Paper,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { DataGrid, GridColumns, GridRenderCellParams, GridRowsProp } from '@mui/x-data-grid'
import DateInput from 'Educators/ManageSchool/Vacations/Date'
import { PrimaryButton, SecondaryButton } from '../../Components/buttons'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import ClearIcon from '@material-ui/icons/Clear'
import MomentUtils from '@date-io/moment'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import AddVacation from './addVacation'
import { useSchools } from 'Educators/SchoolsContext'
import { success, error } from 'components/Toast'
import { useCroods } from 'croods'
import HelperCadet from '../../Components/helperCadet'
import { School } from 'types'

const useStyles = makeStyles(() => ({
  heading: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '40px',
    lineHeight: 'normal',
    color: '#250032',
    textAlign: 'center',
    marginBottom: '40px',
  },
  subTitle: {
    color: '#4E4856',
    textAlign: 'center',
    fontFamily: 'Roboto',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal',
    letterSpacing: '0.5px',
    marginBottom: '40px',
  },
  container: {
    flex: 'column',
    maxWidth: '684px',
    padding: 0,
  },
  paper: {
    padding: '24px',
    marginBottom: '24px',
  },
  formHeader: {
    fontSize: '24px',
    marginBottom: '16px',
    color: '#250032',
  },
  button: {
    width: '100%',
  },
  datePicker: {
    width: '45%',
  },
  addVacation: {
    textAlign: 'center',
  },
}))

function addYears(date: Date, years: number) {
  date.setFullYear(date.getFullYear() + years)

  return date
}

export const CalendarSettings = ({ mode = 'newSchoolYear', nextStepFn, school }: { mode?: string, nextStepFn: Function, school: School }) => {
  const classes = useStyles()
  
  const [{ saving: savingCompleteSetUp }, { save: saveCompleteSetUp }] = useCroods({
    name: 'completedSetUp',
    customPath: `schools/${school.id}`,
    afterResponse({ status }) {
      if (status === 200) {
        nextStepFn()
      } else {
        error('Error')
      }
    },
  })
  const [{ list: listVacations }, { fetch: fetchVacations, destroy: destroyVacation }] = useCroods({
    stateId: school.id,
    name: 'vacations',
    path: `/schools/${school.id}/school_vacations`,
  })
  const [{ saving }, { save }] = useCroods({
    name: 'setSchoolYearStartAndEnd',
    customPath: `schools/${school.id}`,
    afterResponse({ status }) {
      if (status === 200) {
        success('School calendar saved')
        mode === 'newSchoolYear' ? saveCompleteSetUp({ method: 'PUT' })({
          school: {
            completed_onboarding: true,
          },
        }) : nextStepFn()
      } else {
        error('Error')
      }
    },
  })
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (skipStep: boolean) => {
    setOpen(false)
    if (skipStep) {
      saveCompleteSetUp({ method: 'PUT' })({
        school: {
          completed_onboarding: true,
        },
      })
    }
  }
  const dateToday = new Date()
  const dateYearToday = addYears(new Date(), 1)
  const schoolYearStartDate = !!school.yearStartDate ? new Date(`${school.yearStartDate}T00:00:00`) : dateToday
  const schoolYearEndDate = !!school.yearEndDate ? new Date(`${school.yearEndDate}T00:00:00`) : dateYearToday
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(schoolYearStartDate)
  const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(schoolYearEndDate)

  const handleDateChange = (date: MaterialUiPickersDate, _value?: string | null | undefined) => {
    if (date !== null) {
      setSelectedDate(date?.toDate())
    }
  }

  const handleEndDateChange = (date: MaterialUiPickersDate, _value?: string | null | undefined) => {
    if (date !== null) {
      setSelectedEndDate(date?.toDate())
    }
  }

  const saveDates = () => {
    save({ method: 'PUT' })({
      school: {
        year_start_date: selectedDate,
        year_end_date: selectedEndDate,
      },
    })
  }

  React.useEffect(() => {
    school.id && fetchVacations()()
  }, [school.id]) // eslint-disable-line

  const rows: GridRowsProp = listVacations
    ? listVacations.map((vacation) => {
        return {
          id: vacation.id,
          name: vacation.name,
          from: vacation.startsAt,
          to: vacation.endsAt,
        }
      })
    : []
  const columns: GridColumns = [
    { field: 'name', headerName: 'Vacation', flex: 1 },
    { field: 'from', type: 'date', flex: 0.5 },
    { field: 'to', type: 'date', flex: 0.5 },
    {
      field: 'action',
      type: 'action',
      flex: 0.2,
      disableColumnMenu: true,
      renderHeader: () => (" "),
      renderCell: (params: GridRenderCellParams) => {
        return (
          <button
          type="button"
          aria-label="vacation remove"
          className="text-primary"
          onClick={() => destroyVacation({
            id: params.id,
            afterSuccess: () => {
              success('Vacation successfully removed!')
            },
          })()}
        >
          <ClearIcon />
        </button>
      )},
    },
  ]

  return (
    <Container className={classes.container}>
      <HelperCadet customLink="https://closegaphelp.zendesk.com/hc/en-us/articles/17748762726683-Why-is-it-important-to-set-my-calendar-for-the-school-year-ahead-" />
      {mode === 'newSchoolYear' && (<Typography className={classes.heading}>
        Let's set your calendar for the school year ahead!{' '}
      </Typography>)}
      <Paper className={classes.paper}>
        <Typography className={classes.formHeader}>School Year</Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <Grid container justifyContent="space-between">
            <DateInput
              className={classes.datePicker}
              label="First day of school*"
              value={selectedDate}
              onChange={(date: MaterialUiPickersDate) => handleDateChange(date)}
              ariaLabel="First day of school*"
            />
            <DateInput
              className={classes.datePicker}
              label="Last day of school*"
              value={selectedEndDate}
              onChange={(date: MaterialUiPickersDate) => handleEndDateChange(date)}
              ariaLabel="Last day of school*"
              minDate={selectedDate}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </Paper>
      <Paper className={classes.paper}>
        <Typography className={classes.formHeader}>Vacations</Typography>
        {rows.length > 0 && (
          <div style={{ height: 300, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} />
          </div>
        )}
        <div className={classes.addVacation}>
          <AddVacation />
        </div>
      </Paper>

      <PrimaryButton
        disabled={saving || savingCompleteSetUp}
        loading={saving}
        onClick={() => saveDates()}
      >
        SAVE
      </PrimaryButton>
      {mode === 'newSchoolYear' && (<><SecondaryButton onClick={handleClickOpen} disabled={saving || savingCompleteSetUp}>
        Skip this for now.
      </SecondaryButton>
      <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Are you sure?'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Sharing your school calendar with us is important for a few reasons!
            <br />
            <br />
            We will send out welcome emails to your students on the First day of school. Otherwise,
            they will be sent out immediately. We will shut the check-in off for summer break on the
            Last day of school.
            <br />
            <br />
            During the vacation periods you set, grades PK-10 will not be able to check-in. Grades
            11-12 can check-in but will not have the ability to request to speak to an adult.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)} variant="outlined" color="secondary">
            NO, I WANT TO GO BACK.
          </Button>
          <Button onClick={() => handleClose(true)} variant="contained" color="secondary" autoFocus>
            I'LL DO THIS MANUALLY.
          </Button>
        </DialogActions>
      </Dialog></>)}
    </Container>
  )
}

const NewSchoolYearCalendarSettings = ({nextStepFn}:{nextStepFn: Function,}) => {
  const { school }:{school?: School} = useSchools()
  return !!school && !!school.id ? (<CalendarSettings mode='newSchoolYear' nextStepFn={nextStepFn} school={school}  />) : (<>"Loading"</>)
} 


export default NewSchoolYearCalendarSettings
