import React from 'react'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import ListItemText from '@material-ui/core/ListItemText'
import { InputLabel } from '@material-ui/core'

export default ({
  input,
  meta = {},
  options,
  listItemProps = {},
  helperText = '',
  hideLabel = false,
  ...props
}) => {
  const { touched, error } = meta

  return (
    <FormControl
      error={touched && !!error}
      aria-describedby={`${input.name}-text-id`}
      fullWidth
    >
      <InputLabel
        id={`${input.name}-text-id`}
        style={
          (hideLabel && {
            display: 'none',
          }) ||
          {}
        }
      >
        {props.label}
      </InputLabel>
      <Select
        id={`${input.name}-text-id`}
        className="mt-4"
        {...input}
        {...props}
        error={touched && !!error}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            <ListItemText
              primary={option.label}
              secondary={option.description}
              {...listItemProps}
              style={{
                marginTop: 0,
                marginBottom: 0,
              }}
            />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText id={`${input.name}-text`}>
        {(touched && error) || helperText}
      </FormHelperText>
    </FormControl>
  )
}
