import React from 'react'

import Screen from 'checkIn/components/kid/Screen'
import { setQSData } from 'QueryStringController'

import CheckInButtonGroup from 'checkIn/steps/Questions/Kid/CheckInButtonGroup'
import contentByQuestion from 'checkIn/constants/kid/contentByQuestion'

const Kid = ({ nextFunction, questionKey, school }) => {
  const question = contentByQuestion[questionKey]
  const { image } = question

  return (
    <Screen title={question.title} className="h-screen mt-0">
      <img src={image.src} alt={image.alt} className="mt-10 mb-20" />
      <CheckInButtonGroup
        leftButtonText={question.leftButtonText}
        handleLeftButton={() => {
          setQSData({ [questionKey]: true })
          nextFunction(window.location.search, school)
        }}
        rightButtonText={question.rightButtonText}
        handleRightButton={() => {
          setQSData({ [questionKey]: false })
          nextFunction(window.location.search, school)
        }}
      />
    </Screen>
  )
}

export default Kid
