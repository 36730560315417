import React, { memo } from 'react'
import { startCase } from 'lodash'

import { ICheckInData, IFuelValues } from './types'
import { getNeeds } from './NeedsList/helper'
import { NeedsList } from './NeedsList'
import { Wrapper, Answer, Text, Needs } from './styles'

const FUEL_VALUES: IFuelValues = {
  orange: 'Way too much!',
  green: 'Full and Ready!',
  yellow: 'OK',
  blue: 'Low',
  red: 'Empty',
} as const

export const CheckInAnswers = memo(({ data }: { data: ICheckInData }) => {
  const needs = getNeeds(data)

  const answers = [
    { label: 'Emotion', text: startCase(data.feeling) },
    { label: 'Emotion intensity', text: data.feelingIntensity && String(data.feelingIntensity) },
    { label: 'Energy level', text: FUEL_VALUES[data.fuel] },
    { label: 'Activity', text: startCase(data.intervention) },
    { label: 'After check-in', text: startCase(data.feelingNow) },
    { label: 'Preferred contact', text: data.preferredContact?.displayName },
  ]

  return (
    <Wrapper>
      {!!needs && (
        <Needs>
          Needs
          <NeedsList needs={needs as string[]} />
        </Needs>
      )}

      {answers.map((answer, i) =>
        answer.text ? (
          <Answer key={i}>
            {answer.label}
            <Text>{answer.text}</Text>
          </Answer>
        ) : null,
      )}
    </Wrapper>
  )
})
