import React from 'react'
import { ExternalLink, LinkMessageContainer, Text } from './styled'

export const AgreementMessage = () => {
  return (
    <LinkMessageContainer>
      <Text style={{ padding: '0' }}>
        By signing up, you agree to Closegap’s
        <ExternalLink
          style={{ margin: '0px 0.3rem', padding: '0' }}
          href="https://www.closegap.org/user-agreement"
        >
          User Agreement
        </ExternalLink>
        {`and `}
        <ExternalLink
          style={{ margin: '1rem 0', padding: '0' }}
          href="https://www.closegap.org/privacypolicy"
        >
          Privacy Policy
        </ExternalLink>
        .
      </Text>
    </LinkMessageContainer>
  )
}
