import React, { useMemo, useState } from 'react'
import { useCroods } from 'croods'
import { error, success } from 'components/Toast'
import { AdminScreen } from './components/AdminScreen'
import { EmailInput } from './components/EmailInput'
import { NumberInput } from './components/NumberInput'
import { Button, P } from './components/styled'
import { A } from './components/A'
import { METABASE } from './constants'

export const AddSchoolOwner = (props: any) => {
  const [email, setEmail] = useState<string>('')
  const [schoolId, setSchoolId] = useState<number | string>('')

  const [, { save: addSchoolOwner }] = useCroods({
    name: 'admin-add-school-owner',
    customPath: 'admin/add_school_owner',
    afterSuccess: () => success('Educator added to school as owner successfully'),
    afterFailure: ({ message }) => error(message),
  })

  const disabled = useMemo(
    () => email === '' || schoolId === '' || schoolId === 0,
    [email, schoolId],
  )

  return (
    <AdminScreen title="Add educator to school as owner" {...props}>
      <div style={{ alignSelf: 'center', maxWidth: '600px' }}>
        <P>To add an educator to a specific school as a school owner do the following steps:</P>
        <P>1. Add the educator email address in the email field.</P>
        <P>
          2. Search for the school ID in <A name="metabase" href={`${METABASE}/231`} /> by adding
          the school name and state code.
        </P>
        <P>3. Add the target school id in the field.</P>

        <br />
        <EmailInput label="Educator email *" value={email} setValue={setEmail} />
        <NumberInput label="School Id" name="schoolId" value={schoolId} setValue={setSchoolId} />

        <Button
          disabled={disabled}
          variant="add"
          onClick={() => addSchoolOwner()({ email, schoolId })}
        >
          ADD EDUCATOR TO SCHOOL AS OWNER
        </Button>
      </div>
    </AdminScreen>
  )
}
