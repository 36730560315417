import React from 'react'
import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { map } from 'lodash'
import { navigate } from '@reach/router'

const ClassroomFilter = ({
  showOptionAll = false,
  classrooms,
  classroomId = null,
  loading = false,
  selectProps = {},
}) => {
  if (loading)
    return (
      <div data-testid="skeleton" style={{ marginTop: 40 }}>
        <Skeleton variant="text" height={40} width={220} />
      </div>
    )

  return (
    <FormControl style={{ marginTop: 40 }}>
      <InputLabel id="filter-by-classroom">Filter by classroom</InputLabel>
      <Select
        style={{ width: 220 }}
        labelId="filter-by-classroom"
        value={classroomId || ''}
        onChange={(prop) => {
          const newClassroomId = prop.target.value
          navigate(`/manage-school/classrooms/${newClassroomId}`, {
            replace: true,
          })
        }}
        {...selectProps}
      >
        {showOptionAll && <MenuItem value={-1}>Select all</MenuItem>}
        {map(classrooms, ({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default ClassroomFilter
