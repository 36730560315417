import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Typography, Avatar, Grid, useTheme } from '@material-ui/core'
import { Edit as EditIcon } from '@material-ui/icons'

import StyledDialog from 'components/StyledDialog'
import FormSubmitButton from 'components/FormSubmitButton'

import ActionUpdateSelect from 'Educators/ManageSchool/ManageEducators/edit/ActionUpdateSelect'
import { useFlash } from 'seasoned-flash'

function getInitials({ firstName, lastName }) {
  return `${firstName[0]}${lastName[0]}`
}

const EditEducatorAccess = ({
  educator,
  mode = 'school',
  removingMessage,
  loading,
  error,
  destroy,
  save,
}) => {
  const { email } = educator

  const [isOpen, setIsOpen] = React.useState(false)
  const onOpen = () => setIsOpen(true)
  const onClose = () => setIsOpen(false)

  const initialValue = mode === 'classroom' ? 'remove' : educator[`${mode}Role`]
  const [selectValue, setSelectValue] = React.useState(initialValue)

  const noChange = selectValue === initialValue
  const isRemoving = selectValue === 'remove'

  const { success } = useFlash()

  function handleSubmit(e) {
    e.preventDefault()

    if (mode === 'school' && noChange) {
      return onClose()
    }

    if (isRemoving) {
      return destroy({
        id: educator.id,
        afterSuccess: () => {
          onClose()
          success('Educator access removed successfully')
        },
      })()
    }

    return save({
      id: educator.id,
      afterSuccess: () => {
        onClose()
        success('Educator access updated successfully')
      },
    })({ schoolRole: selectValue })
  }

  const theme = useTheme()

  return (
    <>
      <IconButton
        color="primary"
        onClick={onOpen}
        aria-label="open edit educator"
        style={{ alignSelf: 'center' }}
      >
        <EditIcon />
      </IconButton>
      {isOpen && (
        <StyledDialog
          open={isOpen}
          onClose={onClose}
          maxWidth="md"
          title={
            <Typography style={{ fontSize: 'inherit', lineHeight: 1.16 }} color="secondary">
              Edit Access
            </Typography>
          }
        >
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <Grid container style={{ textAlign: 'left' }} wrap="nowrap">
              <Avatar alt="educator initials">{getInitials(educator)}</Avatar>
              <div style={{ marginLeft: theme.spacing(3) }}>
                <Typography variant="subtitle2">{educator.displayName}</Typography>
                <Typography variant="body2">{email}</Typography>
              </div>
              <ActionUpdateSelect
                value={selectValue}
                onChange={(e) => setSelectValue(e.target.value)}
                mode={mode}
              />
            </Grid>

            {isRemoving && (
              <Typography
                variant="body2"
                color="textSecondary"
                style={{ marginTop: theme.spacing(3) }}
              >
                {removingMessage}
              </Typography>
            )}

            <FormSubmitButton
              buttonText={mode === 'school' && noChange ? 'Close' : 'Confirm'}
              style={{ textAlign: 'center', marginTop: theme.spacing(5) }}
              submitting={loading}
              submitError={error}
            />
          </form>
        </StyledDialog>
      )}
    </>
  )
}

EditEducatorAccess.propTypes = {
  educator: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    displayName: PropTypes.string,
    schoolRole: PropTypes.oneOf(['owner', 'viewer']),
    classroomRole: PropTypes.oneOf(['admin', 'viewer']),
    schoolId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    educatorId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  mode: PropTypes.oneOf(['school', 'classroom']).isRequired,
  removingMessage: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  error: PropTypes.string,
  destroy: PropTypes.func,
  save: PropTypes.func,
}

export default EditEducatorAccess
