import React from 'react'
// @ts-ignore
import { useCurrentUser } from 'dmcroods-auth'
// @ts-ignore
import { useCroods } from 'croods'
import { navigate } from '@reach/router'
import validateUserFields from 'auth/validateUserFields'
import { DashboardScreen } from 'components/DashboardScreen'
import { Content, Title, Text, Button, CancelButton } from './style'

export const ConfirmSchool = validateUserFields((props: any) => {
  const school = props.location.state
  const [, setCurrentUser] = useCurrentUser()

  const [{ saveError }, { save: saveSchoolRequest }] = useCroods({
    name: 'auth.currentUser',
    afterSuccess: ({ data: { data } }) => {
      setCurrentUser({ ...data, firstSchoolAccessPending: true }, true)
      navigate('/dashboard')
    },
  })

  const create = saveSchoolRequest({
    method: 'post',
    customPath: `/schools/${school.id}/access_requests`,
  })

  const handleOnSubmit = (e: any) => {
    e.preventDefault()
    return create()
  }

  return (
    <DashboardScreen {...props} propsHeader={{ propsMenuSchool: { hidden: true } }}>
      <Content>
        <Title>Do you work at this school?</Title>

        <div
          style={{
            background: '#fefefe',
            borderRadius: '8px',
            padding: '1rem',
            margin: '1rem 0',
            width: '100%',
            boxShadow: '0px 0px 5px 1px rgba(37, 0, 50, 0.25)',
          }}
        >
          <Text style={{ padding: '0 0 0.5rem 0', fontWeight: '600' }}>School Preview:</Text>
          <Text style={{ padding: '0' }}>{school.name}</Text>
          <Text style={{ padding: '0', fontSize: '0.9rem' }}>{school.address}</Text>
        </div>

        <form onSubmit={handleOnSubmit} style={{ width: '100%' }}>
          <Button type="submit">YES, THIS IS MY SCHOOL AND I WANT TO REQUEST ACCESS!</Button>
        </form>

        <CancelButton onClick={() => navigate('/search-school', { replace: true })}>
          NO, THIS IS NOT MY SCHOOL
        </CancelButton>
        <br />

        {Boolean(saveError) ? (
          <Text style={{ color: 'red', width: '100%', textAlign: 'center' }}>{saveError}</Text>
        ) : null}

      </Content>
    </DashboardScreen>
  )
})
