import React from 'react'
import { Field } from 'redux-form'
import { required, email, length, date } from 'redux-form-validators'
import Grid from '@material-ui/core/Grid'

import { educatorRolesList, therapistRoleList, parentRoleList, studentRolesList } from 'roles'
import Input from 'components/form/Input'
import Select from 'components/form/Select'
import MaskedTextField from 'components/MaskedTextField'
import present from 'components/form/validations/present'
import HONORIFIC_TITLES from 'honorificTitles'
import { isEducator, isParent, isTherapist } from 'students/helper'

const phoneMask = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/,
]

const getRoles = (userRole) => {
  if (isEducator(userRole)) return educatorRolesList
  if (isTherapist(userRole)) return therapistRoleList
  if (isParent(userRole)) return parentRoleList
  return studentRolesList
}

const fields = [
  {
    component: Select,
    name: 'honorificTitle',
    label: 'e.g. Ms.',
    validate: [],
    options: HONORIFIC_TITLES,
    props: { grid: { sm: 12 } },
  },
  {
    component: Input,
    name: 'firstName',
    label: 'First Name',
    type: 'text',
    validate: [required()],
  },
  {
    component: Input,
    name: 'lastName',
    label: 'Last Name',
    type: 'text',
    validate: [required()],
  },
  {
    component: Input,
    name: 'email',
    label: 'School Email Address',
    type: 'text',
    validate: [required(), email()],
    disabled: true,
  },
  {
    component: Input,
    name: 'password',
    label: 'Update password',
    helperText: "Leave blank if you don't want to change",
    type: 'password',
    validate: [length({ min: 8, if: present })],
  },
  {
    label: 'Phone Number',
    name: 'phoneNumber',
    component: MaskedTextField,
    type: 'tel',
    props: { mask: phoneMask },
    // validate: [format({ with: /^\([0-9]{3}\) [0-9]{3}-[0-9]{4}$/i })],
  },
  {
    component: Select,
    name: 'role',
    label: 'Role',
    validate: [required()],
  },
  {
    component: MaskedTextField,
    name: 'birthdate',
    label: 'Birthdate',
    type: 'text',
    validate: (value) => (Boolean(value) ? date({ format: 'mm/dd/yyyy' })(value) : false),
    props: { mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/] },
  },
]

const Fields = ({ userRole }) =>
  fields.map((field) => (
    <Grid item xs={12} sm={6} key={field.name} {...field?.props?.grid}>
      {field.name === 'role' ? (
        <Field {...field} options={getRoles(userRole)} disabled={!isEducator(userRole)} />
      ) : (
        <Field {...field} />
      )}
    </Grid>
  ))

export { educatorRolesList as educatorRoles }
export default Fields
