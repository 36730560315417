import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import StudentsTypography from 'components/StudentsTypography'

const styles = (theme) => ({
  text: {
    color: theme.colors.cinnabar,
    marginBottom: '30px',
    padding: '0 70px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '18px',
      marginBottom: '15px',
      marginTop: '20px',
      padding: '0 42px',
    },
  },
})

export default withStyles(styles)(({ children, style, classes }) => (
  <StudentsTypography
    variant="h2"
    style={{
      ...style,
    }}
    className={classes.text}
  >
    <div
      dangerouslySetInnerHTML={{
        __html: children,
      }}
    />
  </StudentsTypography>
))
