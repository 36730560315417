import React from 'react'
import { useCroods } from 'croods'
import Tooltip from '@material-ui/core/Tooltip'
import Checkbox from '@material-ui/core/Checkbox'

import withMixpanel from 'mixpanel/withMixpanel'
import { featureEnabled } from 'featureFlags'
import FollowUpActions from './FollowUpActions'

export const onHandleChange =
  ({ update, checked, fetchListStudents, studentId, mixpanel }) =>
  async () => {
    await update()
    mixpanel.track('Follow Up Checkbox Clicked', {
      studentId,
      checked: !checked,
    })
    fetchListStudents && fetchListStudents()
  }

const TooltipComponent = ({ item, checked, onChange, currentUser = {} }) => (
  <Tooltip title={item.followerFullName || 'Follow Up'}>
    <div>
      <Checkbox
        checked={checked}
        disabled={
          currentUser.role === 'student' ||
          (item.followedUpId && item.followedUpId !== currentUser.id)
        }
        color="primary"
        onChange={onChange}
      />
    </div>
  </Tooltip>
)

export default withMixpanel((props) => {
  const { currentUser, item, fetchListStudents, mixpanel } = props
  const [{ saving }, { save }] = useCroods({
    name: 'checkIns',
    stateId: item.studentId,
    id: item.id,
  })

  const checked = !!item.followedUpId

  if (featureEnabled(currentUser, 'follow_up_actions')) {
    return (
      <FollowUpActions
        onChange={onHandleChange({
          update: () => save()({ followedUp: !item.followedUpId }),
          checked,
          fetchListStudents,
          studentId: item.studentId,
          mixpanel,
        })}
        checked={checked}
        currentUser={currentUser}
        item={item}
        savingFollowUp={saving}
      />
    )
  }

  return (
    <TooltipComponent
      onChange={onHandleChange({
        update: () => save()({ followedUp: !item.followedUpId }),
        checked,
        fetchListStudents,
        studentId: item.studentId,
        mixpanel,
      })}
      checked={checked}
      currentUser={currentUser}
      item={item}
    />
  )
})
