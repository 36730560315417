import React from 'react'
import { reduxForm, FieldArray } from 'redux-form'

import Fields from 'Educators/educators/import/Fields'

const Form = reduxForm({
  form: 'inviteEducators',
  initialValues: { educators: [{}] },
})(({ handleSubmit, onSubmit, valid, submitting, error }) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    <FieldArray
      name="educators"
      component={Fields}
      valid={valid}
      submitting={submitting}
      submitError={error}
    />
  </form>
))

export default Form
