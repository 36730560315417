import React from 'react'
import { useCurrentUser } from 'dmcroods-auth'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { Typography, makeStyles } from '@material-ui/core'
import { Fetch } from 'croods'
import { find, map } from 'lodash'
import { useSchools } from 'Educators/SchoolsContext'
import { navigate } from '@reach/router'

const useStyles = makeStyles((theme) => {
  const color = theme.colors.white
  return {
    root: {
      color,
      ...theme.typography.h5,
      maxWidth: 184,
      [theme.breakpoints.down('xs')]: {
        maxWidth: 100,
      },
    },
    icon: { color },
    message: {
      color,
      marginLeft: theme.spacing(2),
      maxWidth: 160,
      [theme.breakpoints.down('xs')]: {
        marginLeft: theme.spacing(1),
        fontSize: '0.8rem',
      },
    },
  }
})

const MenuSchool = ({
  disabled = false,
  hidden = false,
  redirectOnChange = false,
  shoAllPremiumSchools = false,
  navigateOnChange = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  navigateOnChangeFn = (_school) => {
    return '/dashboard'
  },
}) => {
  const classes = useStyles()
  const { schoolId, handleSchool, showAllPremiumSchools, getSchoolIdOfLocalStorage, setSchools } =
    useSchools()
  const [{ currentUser }] = useCurrentUser()

  const handleChange = (_, { props: { school } }) => {
    handleSchool(school)
    if (redirectOnChange) {
      navigate('/dashboard')
    }
    if (navigateOnChange) {
      navigate(navigateOnChangeFn(school.school.id))
    }
  }

  const itemAllPremiumSchools = {
    id: 0,
    role: '',
    school: {
      id: 'all',
      name: 'All Premium Schools',
      premium: true,
    },
  }

  return (
    <Fetch
      name="educatorSchools"
      renderLoading={() => null}
      renderError={() => null}
      render={(schools) => {
        const path = window.location.pathname
        if (
          path === '/search-school' ||
          path === '/confirm-school' ||
          path === '/register-school' ||
          path === '/add-school-info'
        )
          return null

        if (!currentUser?.firstSchoolAccessPending && !schoolId && schools?.length === 0)
          return <Typography className={classes.message}>School registration in process</Typography>

        if (currentUser?.firstSchoolAccessPending && !schoolId)
          return (
            <Typography className={classes.message}>
              Still waiting to hear back from your school
            </Typography>
          )

        if (!schools?.length > 0 || hidden) return null

        return (
          <div style={{ marginLeft: 16 }}>
            <Select
              data-testid="select-school"
              disabled={disabled}
              className="menu-school-to-print"
              classes={{ ...classes, message: undefined }}
              disableUnderline
              value={schoolId}
              onChange={handleChange}
            >
              {(showAllPremiumSchools || shoAllPremiumSchools) && (
                <MenuItem
                  key={itemAllPremiumSchools.school.id}
                  value={itemAllPremiumSchools.school.id}
                  school={itemAllPremiumSchools}
                  component="li"
                >
                  <b>{itemAllPremiumSchools.school.name}</b>
                </MenuItem>
              )}
              {map(schools, (item) => (
                <MenuItem key={item.school.id} value={item.school.id} school={item}>
                  {item.school.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        )
      }}
      afterSuccess={({ data: schools }) => {
        setSchools(schools)
        if (!schoolId) {
          handleSchool(schools[0])
        } else {
          const educatorSchools = find(schools, [
            'school.id',
            schoolId === 'all' ? getSchoolIdOfLocalStorage() : schoolId,
          ])
          if (educatorSchools) handleSchool(educatorSchools)
        }
      }}
    />
  )
}

export default MenuSchool
