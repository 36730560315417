import React, { useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { TextField } from '@material-ui/core'
import { setLocalStorageCheckIn } from 'students/helper'
import Screen from 'checkIn/components/teen/Screen'
import { ButtonNext } from 'checkIn/components/teen/ButtonNext'
import SecondaryEmotionButton from 'checkIn/components/teen/SecondaryEmotionButton'

const styles = (theme) => ({
  cssUnderline: {
    color: theme.colors.white,
    borderBottom: `1px solid ${theme.colors.white}`,
  },
  cssFocused: {
    '&:after': {
      borderBottomColor: theme.colors.white,
    },
  },
  cssLabel: {
    left: 'initial',
    color: theme.colors.white,
    '&$cssFocused': {
      color: theme.colors.white,
    },
  },
})

const Notes = withStyles(styles)(({ classes, flow }) => {
  const [text, setText] = useState('')
  const { nextFunction } = flow
  const characterLimit = 600

  const onClickButton = (value = null) => {
    setLocalStorageCheckIn('notes', value)

    nextFunction(window.location.search)
  }

  const onChange = (e) => {
    if (e.target.value.toString().length <= characterLimit) setText(e.target.value)
  }

  return (
    <Screen
      contentWrapperClassName="h-screen !mt-0"
      label="Do you want to share anything else?"
      title="You can provide more information about your check-in, 
          express how you're feeling in your own words, 
          or describe what you need help with."
    >
      <div className="flex flex-col items-center mx-10 mt-[12vh]">
        <TextField
          autoFocus
          id="text-here"
          label="Text here"
          multiline
          rowsMax="5"
          value={text}
          onChange={onChange}
          className="w-screen max-w-xs sm:max-w-sm"
          InputLabelProps={{
            classes: {
              root: classes.cssLabel,
              focused: classes.cssFocused,
            },
          }}
          InputProps={{
            maxLength: 5,
            classes: {
              focused: classes.cssFocused,
              underline: classes.cssUnderline,
            },
            style: {
              fontSize: '1.2rem',
              lineHeight: '160%',
            },
          }}
        />
        <div
          style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
          className={classes.cssLabel}
        >
          {text.toString().length} / {characterLimit}
        </div>
        <ButtonNext
          className="w-full mt-10"
          disabled={text.length < 1}
          onClick={() => onClickButton(text)}
        >
          <span className="font-medium">Send</span>
        </ButtonNext>
        <SecondaryEmotionButton
          onClick={() => onClickButton(null)}
          style={{ width: 240, marginTop: '2.5rem' }}
        >
          <span className="mx-10 font-medium">No, thanks.</span>
        </SecondaryEmotionButton>
      </div>
    </Screen>
  )
})

export default Notes
