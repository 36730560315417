import React from 'react'
import { useCheckIn } from 'checkIn/CheckInContext'
import Kid from './Kid'
import type { RouteComponent } from 'types'
import FLOWS from 'checkIn/flows'

const Activities: RouteComponent = () => {
  const { student } = useCheckIn()

  if (student) {
    const { nextFunction } = FLOWS['kid'].activities
    return <Kid nextFunction={nextFunction} />
  }

  return null
}

export default Activities
